const defaultConfig = {
    current: 1,
    pageSize: 20,
}

const validUrl = [
    'dateFields',
    'startDate',
    'endDate',
    'wordFields',
    'keyword',
    'searchType',
    'current',
    'pageSize',
    'total',
    'orderFields',
    'whereFields',
]

export const initUrl = (searchParams) => {
    for (const [key, value] of searchParams.entries()) {
        if (!validUrl.includes(key)) {
            searchParams.delete(key)
        } else {
            if (key === 'orderFields' || key === 'whereFields') {
                searchParams.delete(key)  
            }
        }
    }
    // Object.keys(defaultConfig).map((item, index) => {
    //     searchParams.set(item, defaultConfig[item])
    // })
}

// Helping searchBar params render to url 
export const urlParamsConvert = (searchParams) => {
    return [
        {
            key: 'dateFields',
            excute: (value) => {
                if (Array.isArray(value) && value.length > 0) {
                    searchParams.set('dateFields', encodeURIComponent(JSON.stringify(value)))
                } else {
                    searchParams.delete('dateFields')
                }
            },
            parse: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        },
        {
            key: 'startDate',
            excute: (value) => {
                if (value) {
                    searchParams.set('startDate', encodeURIComponent(value))
                } else {
                    searchParams.delete('startDate')
                }
            },
            parse: (value) => {
                return decodeURIComponent(value)
            }
        }, {
            key: 'endDate',
            excute: (value) => {
                if (value) {
                    searchParams.set('endDate', encodeURIComponent(value))
                } else {
                    searchParams.delete('endDate')
                }
            },
            parse: (value) => {
                return decodeURIComponent(value)
            }
        },
        {
            key: 'wordFields',
            excute: (value) => {
                if (Array.isArray(value) && value.length > 0) {
                    searchParams.set('wordFields', encodeURIComponent(JSON.stringify(value)))
                } else {
                    searchParams.delete('wordFields')
                }
            },
            parse: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        }, {
            key: 'keyword',
            excute: (value) => {
                if (value) {
                    searchParams.set('keyword', encodeURIComponent(value))
                } else {
                    searchParams.delete('keyword')
                }
            },
            parse: (value) => {
                return decodeURIComponent(value)
            }
        }, {
            key: 'searchType',
            excute: (value) => {
                if (value !== null && value !== undefined) {
                    searchParams.set('searchType', encodeURIComponent(value))
                } else {
                    searchParams.delete('searchType')
                }
            },
            parse: (value) => {
                return parseInt(decodeURIComponent(value))
            }
        }, {
            key: 'current',
            excute: (value) => {
                if (!isNaN(value) && value > 0) {
                    searchParams.set('current', encodeURIComponent(value))
                } else {
                    searchParams.delete('current')
                }
            },
            parse: (value) => {
                return parseInt(decodeURIComponent(value))
            }
        }, {
            key: 'pageSize',
            excute: (value) => {
                if (!isNaN(value) && value > 0) {
                    searchParams.set('pageSize', encodeURIComponent(value))
                } else {
                    searchParams.delete('pageSize')
                }
            },
            parse: (value) => {
                return parseInt(decodeURIComponent(value))
            }
        }, {
            key: 'total',
            excute: (value) => {
                if (!isNaN(value) && value > 0) {
                    searchParams.set('total', encodeURIComponent(value))
                } else {
                    searchParams.delete('total')
                }
            },
            parse: (value) => {
                return parseInt(decodeURIComponent(value))
            }
        }, {
            key: 'orderFields',
            excute: (value) => {
                if (Array.isArray(value) && value.length > 0) {
                    searchParams.set('orderFields', encodeURIComponent(JSON.stringify(value)))
                } else {
                    searchParams.delete('orderFields')
                }
            },
            parse: (value) => {
                let obj = JSON.parse(decodeURIComponent(value))
                let target = []
                target[0] = {}
                target[0].key = obj[0].key
                target[0].value = obj[0].order === "ascend" ? 1 : 2
                return target
            }
        }, {
            key: 'whereFields',
            excute: (value) => {
                if (Array.isArray(value) && value.length > 0) {
                    searchParams.set('whereFields', encodeURIComponent(JSON.stringify(value)))
                } else {
                    searchParams.delete('whereFields')
                }
            },
            parse: (value) => {
                let obj = JSON.parse(decodeURIComponent(value))
                return obj
            }
        }
    ]
}

export const analyzeParams = () => {
    return [
        {
            key: 'dateFields',
            condition: (value) => {
                if (value.indexOf(',') > -1) {
                    let array = value.split(',')
                    return array
                } else {
                    return [value]
                }
            }
        }, {
            key: 'startDate',
            condition: (value) => {
                return value
            },
        }, {
            key: 'endDate',
            condition: (value) => {
                return value
            }
        }, {
            key: 'wordFields',
            condition: (value) => {
                if (value.indexOf(',') > -1) {
                    let array = value.split(',')
                    return array
                } else {
                    return [value]
                }
            }
        }, {
            key: 'keyword',
            condition: (value) => {
                return value
            }
        }, {
            key: 'searchType',
            condition: (value) => {
                return Number(value)
            }
        }, {
            key: 'whereFields',
            condition: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        }, {
            key: 'orderFields',
            condition: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        }
    ]
}

export const generateConfig = (page, pageSize) => {
    if (pageSize) {
        const obj = { offset: 0, limit: 0 }
        if (page === 0) {
            page = 1
        }
        if (!pageSize) {
            obj['offset'] = (page - 1) * defaultConfig.limit
            obj['limit'] = defaultConfig.limit
        } else {
            obj['offset'] = (page - 1) * pageSize
            obj['limit'] = pageSize
        }
        return obj
    } else {
        return { offset: 0, limit: defaultConfig.limit }
    }
}

export const validateUrl = () => {
    return ['dateFields', 'startDate', 'endDate', 'wordFields', 'keyword', 'searchType', 'whereFields', 'orderFields']
}