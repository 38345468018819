import { defineMessages } from 'react-intl'

const messages = defineMessages({

    'message.info.delete_success': {
        id: "app.ui.manager_analyst.message.info.delete_success",
        defaultMessage: "削除成功しました。",
    },
    'message.error.delete_fail': {
        id: "app.ui.manager_analyst.message.error.delete_fail",
        defaultMessage: "削除失敗しました。",
    },
})

export default messages