const ParamsKey = {
    search: "search",
    sorter: "sorter",
    filters: "filters",
    clientId: "clientId",
    filterErrorRecord: "filterErrorRecord",
    pagination: "pagination"
}

const PageSize = 20;

const converSearchParmasToConditions = (searchParams) => {
    let conditions = searchParams.get(ParamsKey.search)
    conditions = conditions ? JSON.parse(decodeURIComponent(conditions)) : {}
    let filters = searchParams.get(ParamsKey.filters);

    if (filters) {
        try {
            conditions["whereFields"] = JSON.parse(decodeURIComponent(filters))
        } catch {
        }
    }
    let sorter = searchParams.get(ParamsKey.sorter);
    if (sorter) {
        try {
            conditions["orderFields"] = JSON.parse(decodeURIComponent(sorter))
        } catch {
        }
    }
    let clientId = searchParams.get(ParamsKey.clientId)
    if (clientId) {
        conditions.clientId = clientId;
    }

    let noticeId = searchParams.get("noticeId")
    if (noticeId) {
        conditions.noticeId = noticeId;
    }

    let filterErrorRecord = searchParams.get(ParamsKey.filterErrorRecord)
    if (filterErrorRecord !== null && filterErrorRecord !== undefined) {
        if (filterErrorRecord === "0" || filterErrorRecord === "false") {
            conditions.filterErrorRecord = 0;
        } else {
            conditions.filterErrorRecord = 1;
        }
    }

    let pagination = searchParams.get(ParamsKey.pagination);
    if (pagination) {
        try {
            pagination = JSON.parse(decodeURIComponent(pagination))
            conditions.offset = (pagination.current - 1) * pagination.pageSize
        } catch {
            conditions.offset = 0
        }
    } else {
        conditions.offset = 0;
    }
    conditions.limit = pagination ? pagination.pageSize : 20; // limit 20
    return conditions
}


const _obj = { converSearchParmasToConditions, ParamsKey, PageSize };
export default _obj;