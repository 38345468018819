import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.info.update_success": {
        id: "app.ui.manager_analyst_detail.message.info.update_success",
        defaultMessage: "情報を更新しました。",
    },
    "message.error.update_fail": {
        id: "app.ui.manager_analyst_detail.message.error.update_fail",
        defaultMessage: "情報の更新は失敗しました。",
    },


    "message.error.name_fail": {
        id: "app.ui.manager_analyst_detail.message.error.name_fail",
        defaultMessage: "名前を30文字以内で入力してください。"
    },
    "message.error.role_fail": {
        id: "app.ui.manager_analyst_detail.message.error.role_fail",
        defaultMessage: "ロールを選んでください。"
    },
    "message.error.tel_fail": {
        id: "app.ui.manager_analyst_detail.message.error.tel_fail",
        defaultMessage: "正しい電話番号を入力してください。"
    },
    "message.error.remarks_fail": {
        id: "app.ui.manager_analyst_detail.message.error.remarks_fail",
        defaultMessage: "500字以内の備考を入力ください。",
    }
})

export default messages