export const InfringementCommonCategories = [
    {
        id: 1,
        name:{
            ja:"非侵害",
            en:"Non infringement"
        }
    },
    {
        id: 2,
        name:{
            ja:"商標権侵害",
            en:"Trademark Infringement (ad)"
        }
    },
    {
        id: 3,
        name:{
            ja:"商標権侵害（ニセモノ）",
            en:"Trademark Infringement (counterfeit)"
        }
    },
    {
        id: 4,
        name:{
            ja:"著作権侵害",
            en:"Copyright Infringement"
        }
    },
    {
        id: 5,
        name:{
            ja:"その他法令",
            en:"Other Law Infringement"
        }
    },
    {
        id: 6,
        name:{
            ja:"規約違反",
            en:"Policy Violation"
        }
    },
    {
        id: 7,
        name:{
            ja:"その他知的財産権",
            en:"Other IP Infringement"
        }
    },
    {
        id: 8,
        name:{
            ja:"参考情報",
            en:"Reference"
        }
    },
    {
        id: 9,
        name:{
            ja:"要確認",
            en:"Need confirmation"
        }
    },
    {
        id: 10,
        name:{
            ja:"販売終了",
            en:"Need Sold(Ended)"
        }
    },
    {
        id: 11,
        name:{
            ja:"旧パッケージ",
            en:"Old Package"
        }
    },

    {
        id: 12,
        name:{
            ja:"意匠権侵害:1",
            en:"Design right:1"
        }
    },
    {
        id: 13,
        name:{
            ja:"意匠権侵害:2",
            en:"Design right:2"
        }
    },
    {
        id: 14,
        name:{
            ja:"意匠権侵害:3",
            en:"Design right:3"
        }
    },
    {
        id: 15,
        name:{
            ja:"意匠権侵害:4",
            en:"Design right:4"
        }
    },
    {
        id: 16,
        name:{
            ja:"意匠権侵害:5",
            en:"Design right:5"
        }
    },
    {
        id: 17,
        name:{
            ja:"意匠権侵害:6",
            en:"Design right:6"
        }
    }
]