import "./index.less"
import React, { Component } from "react"
import { Table, Space, Button, Form } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import { ModalForm, } from '@ant-design/pro-form'
import { injectIntl, FormattedMessage } from "react-intl"

class EFCMasterTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editRowDictionary: {}
        }
        this.formRef = React.createRef()
        this.modalFormRef = React.createRef()
        this.columns = this.createColumns()
        console.log(this.columns)
    }

    createColumns = () => {
        const originColumns = [...this.props.columns]
        const newColumns = originColumns.map((column) => {
            if (column.editCell) {
                column["render"] = (text, record) => {
                    if (this.isEditRecord(record)) {
                        return (
                            column.editCell(text, record)
                        )
                    } else {
                        if (column.renderText) {
                            return column.renderText(record)
                        } else {
                            return text
                        }
                    }
                }
            }
            return column
        })
        if (this.props.noAction) {
            return newColumns
        } else {
            return [
                ...newColumns,
                {
                    title: <FormattedMessage id="app.common.action"/>,
                    key: 'action',
                    align: 'center',
                    render: (text, record) => {
                        if (this.isEditRecord(record)) {
                            return (
                                <Space size="small">
                                    <Button type="link" onClick={() => this.onSaveClick(record)}>{<FormattedMessage id="app.common.action.save"/>}</Button>
                                    <Button type="link" onClick={() => this.onRemoveClick(record)}>{<FormattedMessage id="app.common.action.delete"/>}</Button>
                                    <Button type="link" onClick={() => this.onCancelClick(record)}>{<FormattedMessage id="app.common.action.cancel"/>}</Button>  
                                </Space>
                            )    

                        } else {
                            return (
                                <Space size="small">
                                    <Button type="link" onClick={() => this.onEditClick(record)} >{<FormattedMessage id="app.common.action.edit"/>}</Button>   
                                </Space>
                            )
                        }
                    },
                },
            ]
        }
    }

    isEditRecord = (record) => {
        return this.state.editRowDictionary[record[this.props.rowkey ? this.props.rowkey : "id"]] ? true : false
    }

    addEditRecord = (record) => {
        let editRow = {}
        editRow[record[this.props.rowkey ? this.props.rowkey : "id"]] = record
        let dict = Object.assign({}, this.state.editRowDictionary, editRow)
        this.setState({ editRowDictionary: dict })
    }

    removeEditRecord = (record) => {
        let newDict = Object.assign({}, this.state.editRowDictionary)
        delete newDict[record[this.props.rowkey ? this.props.rowkey : "id"]]
        this.setState({ editRowDictionary: newDict })
    }

    onSaveClick = (record) => {
        this.removeEditRecord(record)
        this.formRef.current.validateFields().then((values) => {
            this.props.onSaveClick(record, values)
        }).catch(errorInfo => {
            /*
            errorInfo:
              {
                values: {
                  username: 'username',
                  password: 'password',
                },
                errorFields: [
                  { name: ['password'], errors: ['Please input your Password!'] },
                ],
                outOfDate: false,
              }
            */
        })
    }

    onCancelClick = (record) => {
        this.removeEditRecord(record)
    }

    onRemoveClick = (record) => {
        this.removeEditRecord(record)
        if (this.props.onRemoveClick) {
            this.props.onRemoveClick(record)
        }
    }

    onEditClick = (record) => {
        this.addEditRecord(record)
        if (this.props.getEditFieldsValue) {
            let row = this.props.getEditFieldsValue(record)
            this.formRef.current.setFieldsValue(
                {
                    ...row
                })
        }
    }

    render () {
        return (
            <Form
                ref={this.formRef}
                component={false}
            >
                <div>
                    <ModalForm
                        className="ef-modalform-single-col"
                        formRef={this.modalFormRef}
                        title={<p className="ef-text-bold">{this.props.modalTitle}</p>}
                        trigger={
                            <Button style={{ float:'right',marginBottom: '16px' }} icon={<PlusOutlined />}></Button>
                        }
                        modalProps={{
                            onCancel: () => {
                                this.modalFormRef.current?.resetFields()
                            },
                        }}
                        onFinish={(values) => {
                            this.props.onModalFinished(values)
                            return true
                        }}
                        submitter={{
                            // 配置按钮文本
                            searchConfig: {
                                resetText: this.props.intl.formatMessage({id:'app.common.action.cancel'}), 
                                submitText: this.props.intl.formatMessage({id:'app.common.action.confirm'}),
                            },
                        }}
                    >
                        {this.props.modalItems}
                    </ModalForm >
                </div>
                <Table
                    // title={()=>{ return "aaa"}}
                    className="ef-table"
                    columns={this.createColumns()}
                    dataSource={this.props.dataSource}
                    size="small"
                    // scroll={{
                    //     x: "max-content"
                    // }}
                    pagination={false}
                    {...this.props.tableProps}
                />
            </Form>
        )
    }
}


export default injectIntl(EFCMasterTable)
