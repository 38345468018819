// notice center intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({

    'notice.title': {
        id: "app.ui.notification_center.title",
        defaultMessage: "Notice",
    },
    'notice_type.locale_key': {
        id: "app.config.locale.name_locale_key",
        defaultMessage: "en",
    }
})

export default messages