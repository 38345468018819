import { Button, Form, Row, Col, Input, Select } from 'antd'
import './index.less'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
const EFBoxList = (props) => {
    const mode = useRef(true)
    const height = useRef('auto')
    const titleBarColor = useRef('#1880DE')
    const title = useRef(props.title)
    const lineWidth = useRef(300)
    const updateTrigger = useRef(false)
    const currentParams = useRef({})

    const [Loading, setLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const formRef = useRef()
    const formType = useRef([])
    const keys = useRef([])
    const values = useRef([])

    useEffect(() => {
        let active = 0
        if (typeof props.basicInfo === 'object' && Object.keys(props.basicInfo).length > 0) {
            if (props.updateTrigger !== undefined && props.updateTrigger !== updateTrigger.current) {
                updateTrigger.current = props.updateTrigger
                onHandleUpdate()
                active = 1
            } else {
                if (Object.keys(currentParams.current).length > 0) {
                    // 替换掉元素更新的那部分,这里用于标记需要联动更新的部分值
                    active = 1
                } else {
                    formRef.current.setFieldsValue(props.basicInfo)
                    keys.current = Object.keys(props.basicInfo)
                    values.current = Object.values(props.basicInfo)
                    active = 1
                }
            }
        }
        if (Array.isArray(props.infoType) && props.infoType.length > 0) {
            formType.current = props.infoType
            active = 1
        }
        if (typeof props.mode === 'boolean' && props.mode !== mode.current) {
            mode.current = props.mode
            active = 1
        }
        if ((typeof props.height === 'number' && props.height !== height.current) || (props.height === '100%')) {
            if (typeof props.height === 'number') {
                height.current = props.height + 'px'
            } else if (props.height === '100%') {
                height.current = props.height
            }
            active = 1
        }
        if (typeof props.titleBarColor === 'string' && props.titleBarColor !== titleBarColor.current) {
            let hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
            let rgbColorRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
            if (hexColorRegex.test(props.titleBarColor) || rgbColorRegex.test(props.titleBarColor)) {
                titleBarColor.current = props.titleBarColor
                active = 1
            }
        }
        if (props.lineWidth && typeof props.lineWidth === 'number' && props.lineWidth !== lineWidth.current) {
            lineWidth.current = props.lineWidth
            active = 1
        }
        if (props.state !== undefined && currentState !== props.state) {
            if (props.state === false) {
                let obj = {}
                keys.current.map((item, index) => {
                    obj[item] = values.current[index]
                })
                formRef.current.setFieldsValue(obj)
                currentParams.current = {}
                setCurrentState(false)
            } else if (props.state === true) {
                // currentParams.current = formRef.current.getFieldValue()
                setCurrentState(true)
            }
            active = 1
        }

        if (active === 1) {
            toggleLoading()
        }
    }, [props])

    const [currentState, setCurrentState] = useState()

    const onHandleState = (value) => {
        if (value === false) {
            let obj = {}
            keys.current.map((item, index) => {
                obj[item] = values.current[index]
            })
            formRef.current.setFieldsValue(obj)
        }
        setCurrentState(value)
    }

    const onHandleUpdate = (value) => {
        if (Object.keys(currentParams.current).length === 0) {
            currentParams.current = formRef.current.getFieldValue()
        }
        if (props.onHandleChange) {
            props.onHandleChange(currentParams.current)
            currentParams.current = {}
        }
    }

    const onHandleChange = (value, item) => {
        currentParams.current = formRef.current.getFieldValue()
        if (props.onHandleParams) {
            props.onHandleParams(value, item)
            currentParams.current = {}
        }
    }

    const renderItem = () => {
        if (keys.current.length > 0) {
            let keyLength = keys.current.length
            return (
                <div className='ef-box-section'>
                    <div className='box-section'>
                        <div className='item-label'>
                            {keys.current.map((item, index) => {
                                if (currentState === true && formType.current[index]) {
                                    return (
                                        <div className="item-label-container edit">
                                            <p><FormattedMessage id={formType.current[index].label} /></p>
                                        </div>
                                    )
                                } else if (formType.current[index]) {
                                    return (
                                        <div className="item-label-container">
                                            <p><FormattedMessage id={formType.current[index].label} /></p>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className='item-value'>
                            {keys.current.map((item, index) => {
                                if (currentState === true && formType.current[index] && formType.current[index].type === 'input') {
                                    return (
                                        <div className="item-value-container edit">
                                            <Form.Item noStyle={true} name={item} rules={[
                                                {
                                                    type: "string",
                                                    max: 50,
                                                },
                                            ]}>
                                                <Input style={{ maxWidth: lineWidth.current + 'px' }} onChange={(e) => { onHandleChange(e.target.value, item) }} disabled={formType.current[index].permission === false} className="input-text" />
                                            </Form.Item>
                                        </div>
                                    )
                                } else if (currentState === true && formType.current[index] && formType.current[index].type === 'textarea') {
                                    return (
                                        <div className="item-value-container edit">
                                            <Form.Item noStyle={true} name={item}>
                                                <Input.TextArea className="input-textarea" autoSize={true} style={{ maxWidth: lineWidth.current + 'px' }} onChange={(e) => { onHandleChange(e.target.value, item) }} disabled={formType.current[index].permission === false} />
                                            </Form.Item>
                                        </div>
                                    )
                                } else if (currentState === true && formType.current[index] && formType.current[index].type === 'select') {
                                    return (
                                        <div className="item-value-container edit">
                                            <Form.Item noStyle={true} name={item}>
                                                <Select className='item-value-select' style={{ maxWidth: lineWidth.current + 'px', display: 'flex' }} options={Array.isArray(formType.current[index].params) ? formType.current[index].params : []} onChange={(value) => { onHandleChange(value, item) }} disabled={formType.current[index].permission === false} />
                                            </Form.Item>
                                        </div>
                                    )
                                } else if (formType.current[index]) {
                                    let value = ''
                                    if (formType.current[index].type === 'select') {
                                        let current = formType.current[index].params.find(item => item.value === values.current[index])
                                        if (current) {
                                            value = current.label
                                        }
                                    } else {
                                        value = values.current[index]
                                    }
                                    return (
                                        <div className="item-value-container">
                                            <Form.Item noStyle={true} name={item} rules={[
                                                {
                                                    type: "string",
                                                    max: 50,
                                                },
                                            ]}>
                                                <p className='item-text'>{value}</p>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>)
        } else {
            return <div></div>
        }
    }

    return (
        <div className='ef-box-list' style={{
            width: mode.current ? '100%' : 'auto',
            height: height.current ? height.current : 'auto'
        }}>
            <Form className='box-list-form' noStyle={true} ref={formRef}>
                <div className='ef-box-list-title'>
                    <span className='box-list-bar' style={{ background: titleBarColor.current ? titleBarColor.current : '#1880DE' }}></span>
                    <span className='box-list-title ef-title-regular'>{title.current}</span>
                </div>
                {renderItem()}
                {props.children}
            </Form>
        </div>
    )
}
export default EFBoxList