import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

export const getUserlist = () => {
    return [
        {
            title: <FormattedMessage id="app.ui.user_list.user_id" />,
            dataIndex: 'user_id',
            key: 'user_id',
            fixed: true,
            width: 80,
            sorter: (a, b) => compareNumber(a.user_id, b.user_id),
            showSorterTooltip: false,
        },
        {
            title:  <FormattedMessage id="app.ui.user_list.user_name" />,
            dataIndex: 'user_name',
            key: 'user_name',
            width: 150,
            sorter: (a, b) => compareString(a.user_name, b.user_name),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_mail" />,
            dataIndex: 'user_mail',
            key: 'user_mail',
            width: 200,
            sorter: (a, b) => compareString(a.user_mail, b.user_mail),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_tel" />,
            dataIndex: 'user_tel',
            key: 'user_tel',
            width: 150,
            sorter: (a, b) => compareString(a.user_tel, b.user_tel),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_department" />,
            dataIndex: 'user_department',
            key: 'user_department',
            width: 150,
            sorter: (a, b) => compareString(a.user_department, b.user_department),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_last_used_date" />,
            dataIndex: 'user_last_used_date',
            key: 'user_last_used_date',
            render: (text, record) => {
                if (text) {
                    return moment(text).format("YYYY-MM-DD")
                } else {
                    return ""
                }
            },
            width: 180,
            sorter: (a, b) => {
                if (a.user_last_used_date) {
                    if (b.user_last_used_date) {
                        return compareDate(a.user_last_used_date, b.user_last_used_date)
                    } else {
                        return 1
                    }
                } else {
                    if (b.user_last_used_date) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_create_at" />,
            dataIndex: 'user_create_at',
            key: 'user_create_at',
            render: (text, record) => (
                moment(text).format("YYYY-MM-DD")
            ),
            width: 150,
            sorter: (a, b) => {
                if (a.user_create_at) {
                    if (b.user_create_at) {
                        return compareDate(a.user_create_at, b.user_create_at)
                    } else {
                        return 1
                    }
                } else {
                    if (b.user_create_at) {
                        return -1
                    } else {
                        return 0
                    }
                }
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.user_list.user_remarks" />,
            dataIndex: 'user_remarks',
            key: 'user_remarks',
            width: 400,
            sorter: (a, b) => compareString(a.user_remarks, b.user_remarks),
            showSorterTooltip: false,
        }
    ]
}