import './index.less'
import React, { useRef, useState, useEffect } from 'react'
import { Select, DatePicker, Input, Button, Form } from 'antd'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { start } from 'nprogress'
const { Option } = Select
const { RangePicker } = DatePicker
const EFCConditionBar = (props) => {
  const formRef = useRef()
  const describe = props.describe
  const date = props.date
  // Init data

  // initial Render
  const initRender = () => {
    formRef.current.setFieldsValue({
      dateType: 1,
    })

    const currentYear = moment().format('YYYY')
    formRef.current.setFieldsValue({
      dateRange: [moment(`${currentYear}-01-01`), moment(`${currentYear}-12-31`)],
    })
    props.onHandleChange({ dateType: 1, date: [`${currentYear}-01-01`, `${currentYear}-12-31`] })
  }

  // url render to page
  const urlRender = (params) => {
    if (params['dateType']) {
      formRef.current.setFieldsValue({
        dateType: Number(params['dateType']),
      })
    }

    if (params['fromDate'] && params['toDate']) {
      formRef.current.setFieldsValue({
        dateRange: [moment(params['fromDate']), moment(params['toDate'])],
      })
    }

    if (params['clientId']) {
      formRef.current.setFieldsValue({
        clientId: Number(params['clientId'])
      })
    }
  }

  useEffect(() => {
    let params = props.onInit()
    if (Object.keys(params).length === 0) {
      initRender()
    } else {
      urlRender(params)
    }
  }, [])

  const handleChange = (value, params) => {
    if (params === 'clientId') {
      props.onHandleChange({ clientId: value })
    } else if (params === 'dateType') {
      props.onHandleChange({ dateType: value })
    }
  }

  const showClientSelector = () => {
    if (props.role & 2) {
      if (props.clients) {
        return (
          <div
            className="search-item-container"
            style={{
              marginRight: '32px',
            }}>
            <span className="search-item-title search-item-son" style={{ marginRight: '12px' }}>
              {(describe && describe[0]) && <FormattedMessage id={describe[0]} />}
            </span>
            <Form.Item
              className='search-item-son'
              noStyle={true}
              name="clientId"
              rules={[
                {
                  required: true,
                  message: '開始期間を入力ください。',
                },
              ]}>
              <Select allowClear style={{ width: 240 }} size="large"
                onChange={(value) => { handleChange(value, 'clientId') }}>
                {props.clients.map((client) => {
                  return (
                    <Select.Option
                      key={`${client.client_id}`}
                      value={client.client_id}>
                      {client.client_name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        )
      }

      return (
        <div className="search-item-container">
          <span className="search-item-title">
            <FormattedMessage id="app.ui.dashboard.search.brand" />
          </span>
          <Select allowClear style={{ width: 200 }}></Select>
        </div>
      )
    }
  }

  const showDateSelector = () => {
    return (
      <div
        className="search-item-container"
        style={{
          marginRight: '32px',
        }}>
        <span className="search-item-title search-item-son" style={{ marginRight: '12px' }}>
          {(describe && describe[1]) && <FormattedMessage id={describe[1]} />}
        </span>
        <Form.Item
          className='search-item-son'
          name="dateType"
          noStyle={true}
          rules={[
            {
              required: true,
              message: '開始日付を入力ください。',
            },
          ]}>
          <Select style={{ width: 240 }} size="large"
            onChange={(value) => { handleChange(value, 'dateType') }}>
            {date.map((Item, index) => {
              return (
                <Select.Option value={index + 1}>
                  <FormattedMessage id={Item} />
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </div>
    )
  }

  const showPeriod = () => {
    return (
      <div
        className="search-item-container"
        style={{
          marginRight: '32px',
        }}>
        <span className="search-item-title search-item-son" style={{ marginRight: '12px' }}>
          {(describe && describe[2]) && <FormattedMessage id={describe[2]} />}
        </span>
        <Form.Item
          className='search-item-son'
          noStyle={true}
          rules={[
            {
              required: true,
              message: '開始日付を入力ください。',
            },
          ]}
          name="dateRange">
          <RangePicker
            size="large"
            onChange={(val) => {
              updateRangePickerValue(val)
            }}
          />
        </Form.Item>
      </div>
    )
  }

  const updateRangePickerValue = (dates) => {
    if (dates === null) {
      props.onHandleChange({ date: null })
      return
    }
    let startTime = moment(dates[0]).format('YYYY-MM-DD')
    let endTime = moment(dates[1]).format('YYYY-MM-DD')
    formRef.current.setFieldsValue({
      dateRange: [moment(startTime), moment(endTime)],
    })
    props.onHandleChange({ date: [startTime, endTime] })
  }

  const onReset = () => {
    formRef.current.resetFields()
    props.onReset()
  }

  const onFinish = (value) => {
    const { dateRange, clientId, dateType } = value
    props.onSearch({
      client_Id: clientId,
      date_Type: dateType,
      start_Time: dateRange[0],
      end_Time: dateRange[1],
    })
  }

  const onFinishFaild = () => { }

  return (
    <Form
      // initialValues={{ dateType: this.dateType }}
      onFinish={onFinish}
      onFinishFailed={onFinishFaild}
      ref={formRef}>
      <div
        className="ef-conditionbar">
        {showClientSelector()}
        {showDateSelector()}
        {showPeriod()}
        <div className='search-item-container'>
          <Form.Item noStyle={true}>
            <Button
              htmlType="submit"
              className="ef-button-color"
              style={{ marginRight: '16px' }}>
              {(describe && describe[3]) && <FormattedMessage id={describe[3]} />}
            </Button>
          </Form.Item>

          <Form.Item noStyle={true}>
            <Button
              htmlType="reset"
              className="ef-button-linear"
              onClick={onReset}>
              {(describe && describe[4]) && <FormattedMessage id={describe[4]} />}
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
export default EFCConditionBar
