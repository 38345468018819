import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store"
import React, { useEffect, useRef, useState, useCallback } from "react"
import { Form, Input, Button, Row, Col, Select, message, Modal } from "antd"
import { useHistory, useLocation } from 'react-router-dom'
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqGetUser, reqUpdateUser, reqRemoveUser } from '../../../api/admin-user'
import EFAdminResetPassword from "../reset-password"
import moment from 'moment'
import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages"
import { isValidTelNo } from "../../../utils/ef-re"

import EFCContainer from '../../../components/ef-c-container'
import EFBox from '../../../components/ef-c-box'

const ManagerDetail = (props) => {
    const intl = useIntl()
    // 路由跳转
    const history = useHistory()
    const location = useLocation()

    const analystId = useRef(parseInt(location.pathname.split("/")[3]))
    const basicInfo = useRef([])
    const loginInfo = useRef([])
    const updateTrigger = useRef(false)
    const currentState = useRef(false)

    const analyst = useRef()

    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const onValidate = (values) => {
        if (!values.name || values.name.length > 30) {
            message.error(intl.formatMessage(messages['message.error.name_fail']))
            return false
        }
        if (!values.role || values.role.length === 0) {
            message.error(intl.formatMessage(messages['message.error.role_fail']))
            return false
        }
        if (values.tel && !isValidTelNo(values.tel)) {
            message.error(intl.formatMessage(messages['message.error.tel_fail']))
            return false
        }
        if (values.remarks && values.remarks.length > 500) {
            message.error(intl.formatMessage(messages['message.error.remarks_fail']))
            return false
        }
        return true
    }

    const onHandleChange = (values) => {
        const { name, tel, remarks, role } = values

        let body = {
            userId: analystId.current,
            name,
            tel,
            remarks,
        }

        if (role) {
            let newRole = role.reduce((preRole, role) => {
                return parseInt(preRole) + parseInt(role)
            }, 0)
            body.role = parseInt(newRole)
        }

        if (onValidate(values)) {
            reqUpdateUser(body).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(messages['message.info.update_success']))
                    renderUser()
                }
            }).catch((error) => {
                message.error(intl.formatMessage(messages['message.error.update_fail']))
            })
        } else {

        }
    }

    const onHandleRemove = (id) => {
        reqRemoveUser({
            userId: id
        }).then((response) => {
            history.push("/manage/analyst")
        }).catch((e) => [

        ])
    }

    const onHandleState = (order) => {
        currentState.current = order
        toggleLoading()
    }

    const onHandleUpdate = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderTrigger = () => {
        if (currentState.current === true) {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.common.action.cancel' /></Button>
                <Button className='ef-button-color-small' style={{ marginLeft: '8px' }} onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.common.action.update' /></Button>
            </div>
        } else {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.common.action.edit' /></Button>
            </div>
        }
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-analyst-list"))
        store.dispatch(setContentContainerHeaderAction([
            {
                name: <FormattedMessage id='app.common.action.delete' />,
                onClick: () => {
                    return Modal.confirm({
                        title: intl.formatMessage({ id: 'app.common.warning.delete' }),
                        className: 'ef-modal',
                        okText: intl.formatMessage({ id: "app.common.action.confirm" }),
                        cancelText: intl.formatMessage({ id: "app.common.action.cancel" }),
                        onOk: (close) => {
                            onHandleRemove(analystId.current)
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })

                }
            },
            {
                item: <EFAdminResetPassword userId={analystId.current} />
            }
        ]))

        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id='app.breadcrumbs.home' />,
                path: "/",
            },
            {
                name: <FormattedMessage id='app.breadcrumbs.manager_list' />,
                path: "/manage/analyst",
            },
            {
                name: <FormattedMessage id='app.breadcrumbs.detail' />,
            }
        ]))
    }

    const renderUser = async () => {
        await reqGetUser({ userId: analystId.current, roles: [4, 2] }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                if (data.data.user) {
                    analyst.current = data.data.user
                    // this.setFormFields(data.data.user)
                }
            } else {

            }
        }).catch((error) => {

        })

        let roles = [4, 2].filter((role) => {
            return parseInt(role) & analyst.current.user_role
        })

        basicInfo.current = [
            {
                label: 'app.ui.manager_analyst_detail.box.basic_id',
                name: 'id',
                value: analyst.current.user_id,
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.manager_analyst_detail.box.basic_name',
                name: 'name',
                value: analyst.current.user_name,
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.manager_analyst_detail.box.basic_mail',
                name: 'mail',
                value: analyst.current.user_mail,
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.manager_analyst_detail.box.basic_role',
                name: 'role',
                value: roles,
                type: 'select',
                mode: "multiple",
                params: [
                    {
                        label: intl.formatMessage({ id: 'app.user.manager' }),
                        value: 4,
                    }, {
                        label: intl.formatMessage({ id: 'app.user.analyst' }),
                        value: 2
                    }
                ],
                permission: true
            }, {
                label: 'app.ui.manager_analyst_detail.box.basic_tel',
                name: 'tel',
                value: analyst.current.user_tel,
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.manager_analyst_detail.box.basic_remarks',
                name: 'remarks',
                value: analyst.current.user_remarks,
                type: 'textarea',
                permission: true
            }
        ]

        loginInfo.current = [
            {
                label: 'app.ui.manager_analyst_detail.box.login_record_startDate',
                name: 'startDate',
                value: moment(analyst.current.user_create_at),
                type: 'date',
                permission: false
            }, {
                label: 'app.ui.manager_analyst_detail.box.login_record_endDate',
                name: 'endDate',
                value: moment(analyst.current.user_last_used_date),
                type: 'date',
                permission: false
            }
        ]
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderUser()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.manager_analyst_detail.title' })
    return <EFCContainer overspread={true} style={{ padding: '32px' }}>
        <Row gutter={[24]}>
            <Col span={12}>
                <EFBox
                    titleBarColor={'#1880DE'}
                    title={<FormattedMessage id="app.ui.manager_analyst_detail.box.basic_title" />}
                    mode={'single'}
                    data={basicInfo.current}
                    itemClass={'ef-form-element-block'}
                    state={currentState.current}
                    updateTrigger={updateTrigger.current}
                    onHandleChange={(value) => { onHandleChange(value) }}>
                </EFBox>
            </Col>
            <Col span={12}>
                <EFBox
                    titleBarColor={'#1880DE'}
                    title={<FormattedMessage id="app.ui.manager_analyst_detail.box.login_record_title" />}
                    mode={'single'}
                    data={loginInfo.current}
                    itemClass={'ef-form-element-block'}
                    state={currentState.current}
                    updateTrigger={updateTrigger.current}
                    onHandleChange={(value) => { }}>
                </EFBox>
            </Col>
        </Row>
        {renderTrigger()}
    </EFCContainer>
}
export default ManagerDetail