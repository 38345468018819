import "./index.less"
import React, { Component, useState } from "react"
import { Drawer, Table, Tooltip, Tag } from "antd"
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import { ECCrawlerStatus } from "../common/master-data"
import moment from 'moment'

import { FormattedMessage, injectIntl } from "react-intl"
import common_messages from "../../../locales/messages"

const ECCrawlerSubtasksList = (props) => {

    const [dataSource, setDataSource] = useState([])
    
    const getBasicColumns = () => {
        const columns = [
            {
                title: <FormattedMessage id="app.ui.ec_task.table.status" />,
                dataIndex: 'status',
                key: 'status',
                width: 170,
                ellipsis: true,
                render: (value) => {
                    if (value === ECCrawlerStatus.scraying.value) {
                        return (<Tag className="ef-tab" icon={<SyncOutlined spin />} color="processing">{ECCrawlerStatus.scraying.text}</Tag>)
                    }

                    if (value === ECCrawlerStatus.error.value) {
                        return (<Tag className="ef-tab" icon={<CheckCircleOutlined />} color="success">{ECCrawlerStatus.error.text}</Tag>)
                    }

                    if (value === ECCrawlerStatus.finished.value) {
                        return (<Tag className="ef-tab" icon={<CheckCircleOutlined />} color="success">{ECCrawlerStatus.finished.text}</Tag>)
                    }

                    if (value === ECCrawlerStatus.pendding.value) {
                        return (<Tag className="ef-tab" icon={<ClockCircleOutlined />} color="geekblue">{ECCrawlerStatus.pendding.text}</Tag>)
                    }
                    return (<Tag className="ef-tab" color="default">{ECCrawlerStatus.new.text}</Tag>)
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.site" />,
                dataIndex: 'site',
                key: 'site',
                width: 170,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.keyword" />,
                dataIndex: 'keyword',
                key: 'keyword',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.startUrl" />,
                dataIndex: 'startUrl',
                key: 'startUrl',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.recordCount" />,
                dataIndex: 'recordCount',
                key: 'recordCount',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            // {
            //     title: '備考',
            //     dataIndex: 'remarks',
            //     key: 'remarks',
            //     width: 300,
            //     ellipsis: true,
            //     render: value => {
            //         return <Tooltip placement="topLeft" title={value}>
            //             {value}
            //         </Tooltip>
            //     }
            // },

            // {
            //     title: '動作',
            //     dataIndex: 'actions',
            //     key: 'actions',
            //     width: 120,
            //     fixed: "right",
            //     ellipsis: true,
            //     render: value => {

            //     }
            // },
        ]
        return columns
    }

    const onHandleClose = () => {
        if (props.onClose) {
            props.onClose()
        }
    }

    return (
        <Drawer
            className="ef-drawer"
            placement="right"
            closable={false}
            onClose={onHandleClose}
            open={props.visible}
        >
            <Table
                className="ef-table"
                columns={getBasicColumns()}
                rowKey={record => record.id}
                dataSource={props.task ? props.task.subtasks : []}
                pagination={false}
                scroll={{
                    x: 'max-content',
                }}
            >
            </Table>
        </Drawer>
    )
}

export default ECCrawlerSubtasksList