import { Button, Form, Input, Select, DatePicker, Checkbox } from 'antd'
import './index.less'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
// 导入 Moment.js
import moment from 'moment'
import { dataTool } from 'echarts'
const { RangePicker } = DatePicker
const EFBox = (props) => {
    const mode = useRef(true)
    const width = useRef('auto')
    const height = useRef('auto')
    const titleBarColor = useRef('#1880DE')
    const title = useRef(props.title)
    const lineWidth = useRef(300)
    const itemClass = useRef('default-item')
    const updateTrigger = useRef(props.updateTrigger)
    const currentParams = useRef({})

    const [Loading, setLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const formRef = useRef()
    const formData = useRef([])
    const keys = useRef([])
    const values = useRef([])

    useEffect(() => {
        let active = 0
        if (Array.isArray(props.data) === true && props.data.length > 0) {
            if (props.updateTrigger !== undefined && props.updateTrigger !== updateTrigger.current) {
                updateTrigger.current = props.updateTrigger
                onHandleUpdate()
                active = 1
            } else {
                if (Object.keys(currentParams.current).length > 0) {
                    // 替换掉元素更新的那部分,这里用于标记需要联动更新的部分值
                    active = 1
                } else {
                    let obj = {}

                    props.data.map((item, index) => {
                        if (item.name) {
                            obj[item.name] = item.value
                        }
                    })
                    formData.current = props.data
                    formRef.current.setFieldsValue(obj)
                    keys.current = Object.keys(obj)
                    values.current = Object.values(obj)
                    active = 1
                }
            }
        }
        if (typeof props.mode === 'boolean' && props.mode !== mode.current) {
            mode.current = props.mode
            active = 1
        }
        if ((typeof props.height === 'number' && props.height !== height.current) || (props.height === '100%')) {
            if (typeof props.height === 'number') {
                height.current = props.height + 'px'
            } else if (props.height === '100%') {
                height.current = props.height
            }
            active = 1
        }
        if ((typeof props.width === 'number' && props.width !== width.current) || (props.width === '100%')) {
            if (typeof props.width === 'number') {
                width.current = props.width + 'px'
            } else if (props.width === '100%') {
                width.current = props.width
            }
            active = 1
        }
        if (typeof props.titleBarColor === 'string' && props.titleBarColor !== titleBarColor.current) {
            let hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
            let rgbColorRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
            if (hexColorRegex.test(props.titleBarColor) || rgbColorRegex.test(props.titleBarColor)) {
                titleBarColor.current = props.titleBarColor
                active = 1
            }
        }
        if (props.itemWidth && typeof props.itemWidth === 'number' && props.itemWidth !== lineWidth.current) {
            lineWidth.current = props.itemWidth
            active = 1
        }
        if (props.itemClass) {
            itemClass.current = props.itemClass
        }
        if (props.state !== undefined && currentState !== props.state) {
            if (props.state === false) {
                // formRef.current.resetFieldsValue()
                console.log(formRef.current.resetFields())
                let obj = {}
                keys.current.map((item, index) => {
                    obj[item] = values.current[index]
                })
                formRef.current.setFieldsValue(obj)
                currentParams.current = {}
                setCurrentState(false)
            } else if (props.state === true) {
                // currentParams.current = formRef.current.getFieldValue()
                setCurrentState(true)
            }
            active = 1
        }

        if (active === 1) {
            toggleLoading()
        }
    }, [props])

    const [currentState, setCurrentState] = useState()

    const convertMutipleData = (data) => {
        let obj = {}
        keys.current.map((item, index) => {
            let key = index + '__'
            obj[item] = {}
            Object.keys(data).map((item1, index1) => {
                if (item1.includes(key)) {
                    let label = item1.split(key)[1]
                    if (Array.isArray(data[item1]) && data[item1].length === 2) {
                        label = label.split('_')
                        if (moment.isMoment(data[item1][0])) {
                            obj[item][label[0]] = data[item1][0].format('YYYY-MM-DD')
                        } else {
                            obj[item][label[0]] = ''
                        }
                        if (moment.isMoment(data[item1][1])) {
                            obj[item][label[1]] = data[item1][1].format('YYYY-MM-DD')
                        } else {
                            obj[item][label[1]] = ''
                        }
                    } else {
                        obj[item][label] = data[item1]
                    }
                }
            })
        })
        return obj
    }

    const convertSingleData = (data) => {
        let obj = {}
        Object.keys(data).map((item, index) => {
            if (item.includes(',')) {
                let name = item.split(',')
                if (data[item] && moment.isMoment(data[item][0]) && moment.isMoment(data[item][1])) {
                    obj[name[0]] = data[item][0].format('YYYY-MM-DD')
                    obj[name[1]] = data[item][1].format('YYYY-MM-DD')
                }
            } else {
                obj[item] = data[item]
            }
        })
        return obj
    }

    const onHandleUpdate = (value) => {
        if (Object.keys(currentParams.current).length === 0) {
            currentParams.current = formRef.current.getFieldValue()
        }
        if (props.onHandleChange) {
            if (props.mode === 'mutiple') {
                currentParams.current = convertMutipleData(currentParams.current)
            } else if (props.mode === 'single' || !props.mode) {
                currentParams.current = convertSingleData(currentParams.current)
            }
            props.onHandleChange(currentParams.current)
            currentParams.current = {}
        }
    }

    const onHandleChange = (value, index, item) => {
        currentParams.current = formRef.current.getFieldValue()
        if (props.onHandleParams) {
            let converValue
            // 考虑时间RangePicker的name属性需要分开,以及mutiple模式下的二层联机
            if (Array.isArray(item) && item.length === 2) {
                converValue = []
                converValue[0] = value[0].format('YYYY-MM-DD')
                converValue[1] = value[1].format('YYYY-MM-DD')
            } else {
                converValue = value
            }

            let converName
            if (props.mode === 'mutiple') {
                converName = { name: undefined, subName: undefined }
                converName.name = formData.current[index].name
                converName.subName = item
            } else {
                converName = item
            }
            props.onHandleParams(converValue, converName)
            currentParams.current = {}
        }
    }

    const renderStaticValue = (item, index, element) => {
        let value
        if (element.type === 'select') {
            if (element.params && element.params.length > 0) {
                if (!element.mode || element.mode === 'single') {
                    let obj
                    obj = element.params.find(item1 =>
                        item1.value === element.value
                    )
                    if (obj) {
                        value = obj.label
                    }
                } else if (element.mode === 'multiple') {
                    let obj
                    const foundItems = element.params.filter(item => element.value.includes(item.value))
                    value = foundItems.map(item => { return item.label })
                    value = value.join('，')
                }
            }
        } else if (element.type === 'datePicker') {
            if (element.value && Array.isArray(element.value)) {
                if (element.value[0] && element.value[1]) {
                    value = moment(element.value[0]).format('YYYY-MM-DD') + ' ~ ' + moment(element.value[1]).format('YYYY-MM-DD')
                } else {
                    value = ''
                }
            }
        } else if (element.type === 'input-select') {
            if (element.value && Array.isArray(element.value)) {
                let select = element.params.find(current => current.value === element.value[1])
                if (select) {
                    value = element.value[0] + ' ' + select.label
                }
            }
        } else if (element.type === 'checkbox') {
            return renderCheckBox(item, index, element)
        } else if (element.type === 'date') {
            value = element.value.format('YYYY-MM-DD')
        } else {
            value = element.value
        }
        return (
            <div className="item-value-container">
                <Form.Item noStyle={true} name={item} rules={[
                    {
                        type: "string",
                        max: 50,
                    },
                ]}>
                    <p
                        className={`${itemClass.current ? itemClass.current + ' default-overflow' : 'default-item default-overflow'} ${element.type === 'textarea' ? 'item-textarea' : ''}`}
                        style={{
                            maxWidth: lineWidth.current + 'px',
                            whiteSpace: element.type === 'textarea' ? 'normal' : 'nowrap'
                        }}
                    >{value}</p>
                </Form.Item>
            </div>
        )
    }

    const renderCheckBox = (name, index, element) => {
        if (props.mode === 'single' || !props.mode) {
            return (
                <div className="item-value-container edit">
                    <Form.Item valuePropName="checked" noStyle={true} name={name}>
                        <Checkbox
                            style={{ maxWidth: lineWidth.current + 'px', display: 'flex', marginRight: '8px' }}
                            onChange={(e) => { onHandleChange(e.target.checked, index, name) }}
                            disabled={!currentState || element.permission === false}
                            className={`item-value-checkbox ${itemClass.current ? itemClass.current : ''}`}>{element.tip ? <FormattedMessage id={element.tip} /> : ''}</Checkbox>
                    </Form.Item>
                </div>
            )
        } else if (props.mode === 'mutiple') {
            let obj = {}
            let currentName = index + '__' + name
            if (!formRef.current.getFieldValue()[currentName]) {
                obj[currentName] = element.value
                formRef.current.setFieldsValue(obj)
            }
            return (
                <div className="item-value-container edit">
                    <Form.Item valuePropName="checked" noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name : name}>
                        <Checkbox
                            style={{ maxWidth: lineWidth.current + 'px', display: 'flex', marginRight: '8px' }}
                            onChange={(e) => { onHandleChange(e.target.checked, index, name) }}
                            disabled={!currentState || element.permission === false}
                            className={`item-value-checkbox ${itemClass.current ? itemClass.current : ''}`}>{element.tip ? <FormattedMessage id={element.tip} /> : ''}</Checkbox>
                    </Form.Item>
                </div>
            )
        }
    }

    const renderInputSelect = (name, index, element) => {
        let inputWidth = parseInt(lineWidth.current * 0.7) - 8
        let selectWidth = parseInt(lineWidth.current * 0.3)
        if (props.mode === 'single' || !props.mode) {
            let obj = {}
            let splitName = name.split(',')
            if (!formRef.current.getFieldsValue()[splitName[0]] && !formRef.current.getFieldsValue()[splitName[1]]) {
                if (element.value && Array.isArray(element.value)) {
                    obj[splitName[0]] = element.value[0]
                    obj[splitName[1]] = element.value[1]
                    formRef.current.setFieldsValue(obj)
                }
            }
            return (
                <div className="item-value-container edit" style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Item noStyle={true} name={splitName[0]}>
                        <Input
                            style={{ maxWidth: inputWidth + 'px', display: 'flex', marginRight: '8px' }}
                            onChange={(e) => { onHandleChange(e.target.value, index, splitName[0]) }}
                            disabled={element.permission === false}
                            className={`input-text ${itemClass.current ? itemClass.current : ''}`} />
                    </Form.Item>
                    <Form.Item noStyle={true} name={splitName[1]}>
                        <Select
                            showArrow
                            mode={element.mode ? element.mode : 'combobox'}
                            style={{ maxWidth: selectWidth + 'px', display: 'flex', width: '100%' }}
                            className={`item-value-select ${itemClass.current ? itemClass.current : ''}`}
                            options={Array.isArray(element.params) ? element.params : []}
                            onChange={(value) => { onHandleChange(value, index, splitName[1]) }}
                            disabled={element.permission === false} />
                    </Form.Item>
                </div>
            )
        } else if (props.mode === 'mutiple') {
            let obj = {}
            let currentName1 = index + '__' + name[0]
            let currentName2 = index + '__' + name[1]
            if (!formRef.current.getFieldValue()[currentName1] || !formRef.current.getFieldValue()[currentName2]) {
                if (element.value && Array.isArray(element.value)) {
                    obj[currentName1] = element.value[0]
                    obj[currentName2] = element.value[1]
                    formRef.current.setFieldsValue(obj)
                }
            }
            return (
                <div className="item-value-container edit" style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name[0] : name[0]}>
                        <Input
                            style={{ maxWidth: inputWidth + 'px', display: 'flex', marginRight: '8px' }}
                            onChange={(e) => { onHandleChange(e.target.value, index, name[0]) }}
                            disabled={element.permission === false}
                            className={`input-text ${itemClass.current ? itemClass.current : ''}`} />
                    </Form.Item>
                    <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name[1] : name[1]}>
                        <Select
                            showArrow
                            mode={element.mode ? element.mode : 'combobox'}
                            style={{ maxWidth: selectWidth + 'px', display: 'flex', width: '100%' }}
                            className={`item-value-select ${itemClass.current ? itemClass.current : ''}`}
                            options={Array.isArray(element.params) ? element.params : []}
                            onChange={(value) => { onHandleChange(value, index, name[1]) }}
                            disabled={element.permission === false} />
                    </Form.Item>
                </div>
            )
        }
    }

    const renderDatePicker = (name, index, element) => {
        if (props.mode === 'single' || !props.mode) {
            let obj = {}
            obj[name] = []
            let splitName = name.split(',')
            if (!moment.isMoment(formRef.current.getFieldValue()[name][0])) {
                if (element.value && Array.isArray(element.value)) {
                    if (element.value[0] && element.value[1]) {
                        obj[name][0] = moment(element.value[0])
                        obj[name][1] = moment(element.value[1])
                    } else {
                        obj[name][0] = ''
                        obj[name][1] = ''
                    }
                    formRef.current.setFieldsValue(obj)
                }
            }
            return (
                <div className="item-value-container edit">
                    <Form.Item noStyle={true} name={name}>
                        <RangePicker
                            style={{ maxWidth: lineWidth.current + 'px', display: 'flex' }}
                            className={`item-value-datepicker ${itemClass.current ? itemClass.current : ''}`}
                            onChange={(value) => {
                                onHandleChange(value, index, splitName)
                            }}
                            disabled={element.permission === false}
                        />
                    </Form.Item>
                </div>
            )
        } else if (props.mode === 'mutiple') {
            let obj = {}
            let currentName = index + '__' + name[0] + '_' + name[1]
            obj[currentName] = []
            if (!formRef.current.getFieldValue()[currentName]) {
                if (element.value && Array.isArray(element.value)) {
                    if (element.value[0] && element.value[1]) {
                        obj[currentName][0] = moment(element.value[0])
                        obj[currentName][1] = moment(element.value[1])
                    } else {
                        obj[currentName][0] = ''
                        obj[currentName][1] = ''
                    }
                    formRef.current.setFieldsValue(obj)
                }
            }
            return (
                <div className="item-value-container edit">
                    <Form.Item noStyle={true} name={index + '__' + name[0] + '_' + name[1]}>
                        <RangePicker
                            style={{ maxWidth: lineWidth.current + 'px', display: 'flex' }}
                            className={`item-value-datepicker ${itemClass.current ? itemClass.current : ''}`}
                            onChange={(value) => {
                                onHandleChange(value, index, name)
                            }}
                            disabled={element.permission === false}
                        />
                    </Form.Item>
                </div>
            )
        }
    }

    const renderDate = (name, index, element) => {
        if (props.mode === 'single' || !props.mode) {
            return (
                <div className="item-value-container edit">
                    <Form.Item noStyle={true} name={name}>
                        <DatePicker
                            style={{ maxWidth: lineWidth.current + 'px', display: 'flex', marginRight: '8px' }}
                            onChange={(date) => { onHandleChange(moment(date).format('YYYY-MM-DD'), index, name) }}
                            disabled={!currentState || element.permission === false}
                            className={`item-value-checkbox ${itemClass.current ? itemClass.current : ''}`}>{element.tip ? <FormattedMessage id={element.tip} /> : ''}</DatePicker>
                    </Form.Item>
                </div>
            )
        }
    }

    const renderSelect = (name, index, element) => {
        return (
            <div className="item-value-container edit">
                <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name : name}>
                    <Select
                        showSearch
                        showArrow
                        allowClear
                        mode={element.mode ? element.mode : 'combobox'}
                        optionFilterProp="label"
                        className={`item-value-select ${itemClass.current ? itemClass.current : ''}`}
                        maxTagCount='responsive'
                        style={{ maxWidth: lineWidth.current + 'px', display: 'flex' }}
                        options={Array.isArray(element.params) ? element.params : []}
                        onChange={(value) => { onHandleChange(value, index, name) }}
                        disabled={element.permission === false} />
                </Form.Item>
            </div>
        )
    }

    const renderTextarea = (name, index, element) => {
        return (
            <div className="item-value-container edit">
                <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name : name}>
                    <Input.TextArea
                        className={`input-textarea ${itemClass.current ? itemClass.current : ''}`}
                        autoSize={true}
                        style={{ maxWidth: lineWidth.current + 'px' }}
                        onChange={(e) => { onHandleChange(e.target.value, index, name) }}
                        disabled={element.permission === false} />
                </Form.Item>
            </div>
        )
    }

    const renderInput = (name, index, element) => {
        if (element.mode && element.mode === 'password') {
            return (
                <div className="item-value-container edit">
                    <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name : name}>
                        <Input.Password
                            style={{ maxWidth: lineWidth.current + 'px' }}
                            onChange={(e) => { onHandleChange(e.target.value, index, name) }}
                            disabled={element.permission === false}
                            className={`input-text ${itemClass.current ? itemClass.current : ''}`} />
                    </Form.Item>
                </div>
            )
        } else {
            return (
                <div className="item-value-container edit">
                    <Form.Item noStyle={true} name={props.mode === 'mutiple' ? index + '__' + name : name}>
                        <Input
                            style={{ maxWidth: lineWidth.current + 'px' }}
                            onChange={(e) => { onHandleChange(e.target.value, index, name) }}
                            disabled={element.permission === false}
                            className={`input-text ${itemClass.current ? itemClass.current : ''}`} />
                    </Form.Item>
                </div>
            )
        }   
    }

    const renderLabel = (item, index, element) => {
        if (currentState === true && element) {
            return (
                <div className='item-label-container edit'>
                    <p className={`${itemClass.current ? itemClass.current : ''}`}><FormattedMessage id={element.label} /></p>
                </div>
            )
        } else if (element) {
            return (
                <div className='item-label-container'>
                    <p className={`${itemClass.current ? itemClass.current : 'default-item'}`}><FormattedMessage id={element.label} /></p>
                </div>
            )
        }
    }

    const renderSubtitle = (item, index, element) => {
        return <p className={`item-subtitle-container ${itemClass.current !== 'default-item' ? itemClass.current : 'item-subtitle-default'}`}>{element.subTitle}</p>
    }

    const renderModeSingle = () => {
        if (keys.current.length > 0) {
            let keyLength = keys.current.length
            return (
                <div className='ef-box-section'>
                    <div className='box-section'>
                        <div className='item-label'>
                            {keys.current.map((item, index) => {
                                return renderLabel(item, index, formData.current[index])
                            })}
                        </div>
                        <div className='item-value'>
                            {keys.current.map((item, index) => {
                                if (currentState === true && formData.current[index]) {
                                    if (formData.current[index].type === 'input') {
                                        return renderInput(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'textarea') {
                                        return renderTextarea(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'select') {
                                        return renderSelect(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'datePicker') {
                                        return renderDatePicker(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'input-select') {
                                        return renderInputSelect(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'checkbox') {
                                        return renderCheckBox(item, index, formData.current[index])
                                    } else if (formData.current[index].type === 'date') {
                                        return renderDate(item, index, formData.current[index])
                                    }
                                } else if (formData.current[index]) {
                                    return renderStaticValue(item, index, formData.current[index])
                                } else {
                                    return ''
                                }
                                // if (currentState === true && formData.current[index] && formData.current[index].type === 'input') {
                                //     return renderInput(item, index, formData.current[index])
                                // } else if (currentState === true && formData.current[index] && formData.current[index].type === 'textarea') {
                                //     return renderTextarea(item, index, formData.current[index])
                                // } else if (currentState === true && formData.current[index] && formData.current[index].type === 'select') {
                                //     return renderSelect(item, index, formData.current[index])
                                // } else if (currentState === true && formData.current[index] && formData.current[index].type === 'datePicker') {
                                //     return renderDatePicker(item, index, formData.current[index])
                                // } else if (currentState === true && formData.current[index] && formData.current[index].type === 'input-select') {
                                //     return renderInputSelect(item, index, formData.current[index])
                                // } else if (currentState === true && formData.current[index] && formData.current[index].type === 'checkbox') {
                                //     return renderCheckBox(item, index, formData.current[index])
                                // } else if (formData.current[index]) {
                                //     return renderStaticValue(item, index, formData.current[index])
                                // }
                            })}
                        </div>
                    </div>
                </div>)
        } else {
            return <div></div>
        }
    }

    const renderMutiple = () => {
        if (keys.current.length > 0) {
            let keyLength = keys.current.length
            return (<div className='ef-box-section'>
                {/* <div className='box-section'> */}
                {keys.current.map((item, index) => {
                    return <div className='box-section mutiple-wrap'>
                        <div className='item-subtitle'>
                            {renderSubtitle(item, index, formData.current[index])}
                        </div>
                        <div className='item-label'>
                            {Array.isArray(formData.current[index].item) ?
                                formData.current[index].item.map((item1, index1) => {
                                    return renderLabel(item, index, item1)
                                }) : ""
                            }
                        </div>
                        <div className='item-value'>
                            {Array.isArray(formData.current[index].item) ?
                                formData.current[index].item.map((item1, index1) => {
                                    if (currentState === true && formData.current[index].item[index1]) {
                                        if (formData.current[index].item[index1].type === 'input') {
                                            return renderInput(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        } else if (formData.current[index].item[index1].type === 'textarea') {
                                            return renderTextarea(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        } else if (formData.current[index].item[index1].type === 'select') {
                                            return renderSelect(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        } else if (formData.current[index].item[index1].type === 'datePicker') {
                                            return renderDatePicker(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        } else if (formData.current[index].item[index1].type === 'input-select') {
                                            return renderInputSelect(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        } else if (formData.current[index].item[index1].type === 'checkbox') {
                                            return renderCheckBox(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                        }
                                    } else if (formData.current[index].item[index1]) {
                                        return renderStaticValue(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    } else {
                                        return ''
                                    }
                                    // if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'input') {
                                    //     return renderInput(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'textarea') {
                                    //     return renderTextarea(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'select') {
                                    //     return renderSelect(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'datePicker') {
                                    //     return renderDatePicker(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'input-select') {
                                    //     return renderInputSelect(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (currentState === true && formData.current[index].item[index1] && formData.current[index].item[index1].type === 'checkbox') {
                                    //     return renderCheckBox(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // } else if (formData.current[index].item[index1]) {
                                    //     return renderStaticValue(formData.current[index].item[index1].name, index, formData.current[index].item[index1])
                                    // }
                                }) : ""
                            }
                        </div>
                    </div>
                })}
                {/* </div> */}
            </div>)
        } else {
            return <div></div>
        }
    }

    const renderItem = () => {
        if (props.mode && props.mode === 'mutiple') {
            return renderMutiple()
        } else if (!props.mode || props.mode === 'single') {
            return renderModeSingle()
        } else {
            return <div></div>
        }
    }

    const renderTitle = () => {
        if (title.current) {
            return <div className='ef-box-list-title'>
                <span className='box-list-bar' style={{ background: titleBarColor.current ? titleBarColor.current : '#1880DE' }}></span>
                <span className='box-list-title ef-title-regular'>{title.current}</span>
            </div>
        } else {
            return ''
        }
    }

    return (
        <div className={`ef-box ${props.className ? props.className : ''}`} style={{
            width: width.current ? width.current : 'auto',
            height: height.current ? height.current : 'auto',
            ...props.style
        }}>
            <Form className='box-list-form' noStyle={true} ref={formRef}>

                {renderTitle()}
                {renderItem()}
                {props.children}
            </Form>
        </div>
    )
}
export default EFBox