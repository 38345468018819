import "./index.less"
import store from "@/store"
import React, { useEffect, useRef, useCallback, useState } from "react"
import { Collapse, Button, Table, Image, Space, Tooltip, message, Modal } from "antd"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqSearchUsers, reqRemoveUser } from '../../../api/admin-user'
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment'
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useHistory } from 'react-router-dom'
import messages from './messages'
import { initUrl, urlParamsConvert } from "./manager-list-url"

import EFCContainer from '../../../components/ef-c-container'
import EFCSearchBar from '../../../components/ef-c-search-condition'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Panel } = Collapse
const EFManagerList = () => {
    const location = useLocation()
    const history = useHistory()
    const dataSource = useRef()
    const intl = useIntl()
    const total = useRef(0)
    const [collapse, setcollapse] = useState(false)
    const dateSearchItems = useRef([
        {
            name: "最終ログイン日付",
            value: "user_last_used_date"
        },
        {
            name: "新規日付",
            value: "user_create_at"
        }
    ])
    const keywordSearchItems = useRef([
        {
            name: "名前",
            value: "user_name"
        },
        {
            name: "メール",
            value: "user_mail"
        },
        {
            name: "備考",
            value: "user_remarks"
        },
    ])
    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])
    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }
    const pageSize = useRef(20)
    const pageCurrent = useRef(1)

    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const onUrlRender = () => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        return params
    }

    const onHandleChange = (value) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        Object.keys(value).map((item, index) => {
            let operation = convertObj.find(current => current.key === item)
            if (operation) {
                operation.excute(value[item])
            }
        })
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    const onHandleSearch = (extraParams) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        if (extraParams && Object.keys(extraParams).length > 0) {
            Object.keys(extraParams).forEach(key => {
                params[key] = extraParams[key]
            })
        }
        renderDataSource(params)
    }

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(location.search)
        history.push(searchParams)
    }

    const onRemove = (record) => {
        reqRemoveUser({
            userId: record.user_id
        }).then((response) => {
            message.success(intl.formatMessage(messages['message.info.delete_success']))
            onHandleSearch()
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.delete_fail']))
        })
    }

    const onHandleRemove = (record) => {
        return Modal.confirm({
            title: intl.formatMessage({ id: 'app.common.warning.delete' }),
            className: 'ef-modal',
            okText: intl.formatMessage({ id: "app.common.action.confirm" }),
            cancelText: intl.formatMessage({ id: "app.common.action.cancel" }),
            onOk: (close) => {
                onRemove(record)
                close()
            },
            onCancel: (close) => {
                close()
            }
        })
    }

    const onHandleTable = (pagination, filters, sorter, extra) => {
        pageCurrent.current = pagination.current
        pageSize.current = pagination.pageSize
        onHandleChange({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order }] : null
        })
        onHandleSearch({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order === "ascend" ? 1 : 2 }] : null
        })
    }

    const renderTableColumns = () => {
        return [
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_id" />,
                dataIndex: 'user_id',
                key: 'user_id',
                fixed: true,
                width: 80,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_name" />,
                dataIndex: 'user_name',
                key: 'user_name',
                width: 150,
                sorter: true,
                // sorter: (a, b) => compareString(a.user_name, b.user_name),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_role" />,
                dataIndex: 'user_role',
                key: 'user_role',
                width: 150,
                render: (text, record) => {
                    let role = []
                    if (record.user_role & 4) {
                        role.push(intl.formatMessage({ id: 'app.user.manager' }))
                    }
                    if (record.user_role & 2) {
                        role.push(intl.formatMessage({ id: 'app.user.analyst' }))
                    }
                    if (record.user_role & 1) {
                        role.push(intl.formatMessage({ id: 'app.user.client' }))
                    }
                    return role.join(",")
                },
                sorter: true,
                // sorter: (a, b) => compareNumber(a.user_role, b.user_role),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_mail" />,
                dataIndex: 'user_mail',
                key: 'user_mail',
                width: 250,
                sorter: true,
                // sorter: (a, b) => compareString(a.user_mail, b.user_mail),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_tel" />,
                dataIndex: 'user_tel',
                key: 'user_tel',
                width: 150,
                sorter: true,
                // sorter: (a, b) => compareString(a.user_tel, b.user_tel),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_remarks" />,
                dataIndex: 'user_remarks',
                key: 'user_remarks',
                width: 400,
                sorter: true,
                // sorter: (a, b) => compareString(a.user_remarks, b.user_remarks),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_last_used_date" />,
                dataIndex: 'user_last_used_date',
                key: 'user_last_used_date',
                width: 180,
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return moment(text).format("YYYY-MM-DD")
                    } else {
                        return ""
                    }
                },
                // sorter: (a, b) => {
                //     if (a.user_last_used_date) {
                //         if (b.user_last_used_date) {
                //             return compareDate(a.user_last_used_date, b.user_last_used_date)
                //         } else {
                //             return 1
                //         }
                //     } else {
                //         if (b.user_last_used_date) {
                //             return -1
                //         } else {
                //             return 0
                //         }
                //     }
                // },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.manager_analyst.table.user_create_at" />,
                dataIndex: 'user_create_at',
                key: 'user_create_at',
                render: (text, record) => (
                    moment(text).format("YYYY-MM-DD")
                ),
                width: 180,
                sorter: false,
                // sorter: (a, b) => {
                //     if (a.user_create_at) {
                //         if (b.user_create_at) {
                //             return compareDate(a.user_create_at, b.user_create_at)
                //         } else {
                //             return 1
                //         }
                //     } else {
                //         if (b.user_create_at) {
                //             return -1
                //         } else {
                //             return 0
                //         }
                //     }
                // },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                width: 160,
                align: 'center',
                render: (text, record) => (
                    <div>
                        <Space size="middle">
                            <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                                <Button className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing"/>} href={"/manage/analyst/" + record.user_id}></Button>
                            </Tooltip>
                            <Tooltip placement="top" title={<FormattedMessage id="app.common.action.delete" />} arrow={true}>
                                <Button className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu"/>} onClick={() => { onHandleRemove(record) }} ></Button>
                            </Tooltip>
                        </Space>
                    </div>
                ),
                fixed: "right"
            }
        ]
    }

    const renderInitUrl = () => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('current')) {
            pageCurrent.current = parseInt(decodeURIComponent(searchParams.get('current')))
        } else {
            pageCurrent.current = 1
        }
        if (searchParams.has('pageSize')) {
            pageSize.current = parseInt(decodeURIComponent(searchParams.get('pageSize')))
        } else {
            pageSize.current = 20
        }

        initUrl(searchParams)
        console.log(searchParams.toString())
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-analyst-list"))
        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.common.action.add" />,
            path: "/manage/analyst/new",
        }]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.manager_list" />,
                path: "/manage/analyst",
            }
        ]))
    }

    const renderDataSource = (conditions = {}) => {
        reqSearchUsers({ role: 6, ...conditions }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let dateSource = data.data.users.map((user) => {
                    let userInfo = { key: user.user_id, ...user }
                    return userInfo
                })
                dataSource.current = dateSource
                total.current = dateSource.length
                toggleLoading()
            }
        }).catch((error) => {

        })
    }

    useEffect(() => {
        renderInitUrl()
        renderBread()
        onHandleSearch()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.manager_analyst.title' })
    return (<div className="ef-container-father">
        <EFCContainer>
            <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                <Panel header={<FormattedMessage id="app.collapse.title" />} key="1">
                    <EFCSearchBar
                        date={dateSearchItems.current}
                        search={keywordSearchItems.current}
                        locale={searchBarDescribe.current}
                        showItem={['date', 'search']}
                        onUrlRender={onUrlRender}
                        onHandleChange={(value) => { onHandleChange(value) }}
                        onHandleReset={onHandleReset}
                        onHandleSearch={(value) => { onHandleSearch(value) }}
                        height={140}
                    ></EFCSearchBar>
                </Panel>
            </Collapse>
        </EFCContainer>
        <EFCContainer overspread={collapse ? 42 : 202} style={{ marginTop: '20px', padding: '24px' }}>
            <Table
                className="ef-table"
                columns={renderTableColumns()}
                dataSource={dataSource.current}
                size="default"
                scroll={{
                    x: 'max-content',
                }}
                onChange={onHandleTable}
                pagination={{
                    showQuickJumper: true,
                    total: total.current,
                    current: pageCurrent.current,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    pageSize: pageSize.current,
                    showSizeChanger: true,
                    locale: customLocale,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current)
                }}
            />
        </EFCContainer>
    </div>)
}
export default EFManagerList