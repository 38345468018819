import "./index.less"

import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store"
import React, { useState, useRef, useEffect, useCallback } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Select, DatePicker, message, Checkbox } from "antd"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { isValidTelNo } from "../../../utils/ef-re"
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'

import { reqCreateClient } from '../../../api/admin-client'
import { reqSearchUsers } from '../../../api/admin-user'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFCSearchBar from '../../../components/ef-c-search-condition'
import EFBox from '../../../components/ef-c-box'

const ClientNew = () => {

    const intl = useIntl()

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()

    const basicInfo = useRef([])
    const contractInfo = useRef([])
    const analyst = useRef([])
    const queueList = useRef({ basic: false, contract: false })
    const condition = useRef({})

    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    })

    const updateTrigger = useRef()

    const checkService = (startDate, endDate, fee, currency) => {
        if (startDate && endDate && fee && currency) {
            return true
        } else {
            return false
        }
    }

    const onValidate = (data) => {
        if (!data['name'] || data['name'].length > 30) {
            message.error(intl.formatMessage(
                messages['message.error.name_error']
            ))
            return false
        }
        if (data['address'] && data['address'].length > 100) {
            message.error(intl.formatMessage(
                messages['message.error.address_error']
            ))
            return false
        }
        if (data.tel && !isValidTelNo(data.tel)) {
            message.error(intl.formatMessage(messages['message.error.tel_error']))
            return false
        }
        if (data['remarks'] && data['remarks'].length > 500) {
            message.error(intl.formatMessage(
                messages['message.error.remarks_error']
            ))
            return false
        }
        return true
    }

    const onHandleCreate = () => {
        if (updateTrigger.current === undefined) {
            updateTrigger.current = false
        }
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const onHandleChange = (value, order) => {
        queueList.current[order] = !queueList.current[order]
        condition.current = { ...value, ...condition.current }
        for (let key in queueList.current) {
            if (queueList.current.hasOwnProperty(key)) {
                if (queueList.current[key] === false) {
                    return
                }
            }
        }
        let body = {
            name: condition.current['name'],
            tel: condition.current['tel'],
            address: condition.current['address'],
            remarks: condition.current['remarks']
        }
        body['managerIds'] = condition.current['managers'] ? condition.current['managers'].map((managerId) => {
            return parseInt(managerId)
        }) : []
        if (checkService(value['ec'].startDate, value['ec'].endDate, value['ec'].fee, value['ec'].currency)) {
            body["ec"] = {
                startDate: value['ec'].startDate,
                endDate: value['ec'].endDate,
                fee: parseFloat(value['ec'].fee),
                currency: parseInt(value['ec'].currency),
                remindMail: value['ec'].remindMail
            }
        }

        if (checkService(value['sns'].startDate, value['sns'].endDate, value['sns'].fee, value['sns'].currency)) {
            body["sns"] = {
                startDate: value['sns'].startDate,
                endDate: value['sns'].endDate,
                fee: parseFloat(value['sns'].fee),
                currency: parseInt(value['sns'].currency),
                remindMail: value['sns'].remindMail
            }
        }

        if (checkService(value['ws'].startDate, value['ws'].endDate, value['ws'].fee, value['ws'].currency)) {
            body["ws"] = {
                startDate: value['ws'].startDate,
                endDate: value['ws'].endDate,
                fee: parseFloat(value['ws'].fee),
                currency: parseInt(value['ws'].currency),
                remindMail: value['ws'].remindMail
            }
        }

        if (checkService(value['other'].startDate, value['other'].endDate, value['other'].fee, value['other'].currency)) {
            body["other"] = {
                startDate: value['other'].startDate,
                endDate: value['other'].endDate,
                fee: parseFloat(value['other'].fee),
                currency: parseInt(value['other'].currency),
                remindMail: value['other'].remindMail
            }
        }
        if (onValidate(body)) {
            reqCreateClient(body).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    history.push("/manage/client")
                }
            }).catch((error) => {
                message.error(intl.formatMessage(
                    messages['message.error.create_fail']
                ))
            })
            condition.current = {}
            queueList.current = { basic: false, contract: false }
        } else {
            condition.current = {}
            queueList.current = { basic: false, contract: false }
        }
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-client-list"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.client_list" />,
                path: "/manage/client",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.new" />,
            }
        ]))
    }

    const renderAnalyst = async () => {
        await reqSearchUsers({
            "role": 4
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let analysts = data.data.users
                analyst.current = analysts
            }
        }).catch((e) => {
            message.error(intl.formatMessage((
                messages['message.error.fetch_user_fail']
            )))   
        })

        basicInfo.current = [
            {
                name: 'name',
                value: '',
                label: 'app.ui.brand_reg_new.name',
                type: 'input',
                permission: true,
            }, {
                name: 'tel',
                value: '',
                label: 'app.ui.brand_reg_new.tel',
                type: 'input',
                permission: true
            }, {
                name: 'address',
                value: '',
                label: 'app.ui.brand_reg_new.address',
                type: 'input',
                permission: true
            }, {
                name: 'managers',
                value: undefined,
                label: 'app.ui.brand_reg_new.managers',
                type: 'select',
                mode: 'multiple',
                params: analyst.current.map((item, index) => {
                    return {
                        label: item.user_name,
                        value: item.user_id
                    }
                }),
                permission: true
            }, {
                name: 'remarks',
                value: '',
                label: 'app.ui.brand_reg_new.remarks',
                type: 'textarea',
                permission: true
            }
        ]
        contractInfo.current = [
            {
                name: 'ec',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.ec_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: '',
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: true
                    }, {
                        name: ['fee', 'currency'],
                        value: '',
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                        permission: true
                    }, {
                        name: 'remindMail',
                        value: false,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: true
                    }
                ]
            }, {
                name: 'sns',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.sns_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: '',
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: true
                    }, {
                        name: ['fee', 'currency'],
                        value: '',
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        permission: true,
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                    }, {
                        name: 'remindMail',
                        value: false,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: true
                    }
                ]
            }, {
                name: 'ws',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.ws_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: '',
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: true
                    }, {
                        name: ['fee', 'currency'],
                        value: '',
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        permission: true,
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                    }, {
                        name: 'remindMail',
                        value: false,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: true
                    }
                ]
            }, {
                name: 'other',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.other_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: '',
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: true
                    }, {
                        name: ['fee', 'currency'],
                        value: '',
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        permission: true,
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                    }, {
                        name: 'remindMail',
                        value: false,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: true
                    }
                ]
            }
        ]
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderAnalyst()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.brand_reg_new.title' })
    return <EFCContainer
        overspread={0}
        style={{ paddingTop: '32px', paddingBottom: '72px' }}>
        <div className="client-new-container-content">
            <Row gutter={[40, 20]}>
                <Col span={12}>
                    <EFBox
                        mode={'single'}
                        title={<p className="ef-title-normal"><FormattedMessage id="app.ui.brand_reg_new.basicinfo.title" /></p>}
                        titleBarColor={'#1880DE'}
                        data={basicInfo.current}
                        itemWidth={300}
                        itemClass={'ef-form-element-block'}
                        state={true}
                        updateTrigger={updateTrigger.current}
                        onHandleChange={(value) => { onHandleChange(value, 'basic') }}
                    ></EFBox>
                </Col>
                <Col span={12}>
                    <EFBox
                        mode={'mutiple'}
                        title={<p className="ef-title-normal"><FormattedMessage id="app.ui.brand_reg_new.contract.title" /></p>}
                        titleBarColor={'#1880DE'}
                        data={contractInfo.current}
                        itemWidth={300}
                        itemClass={'ef-form-element-block'}
                        state={true}
                        updateTrigger={updateTrigger.current}
                        onHandleChange={(value) => { onHandleChange(value, 'contract') }}
                    ></EFBox>
                </Col>
            </Row>
        </div>
        <div className="client-new-container-footer">
            <Button className="ef-button-color" onClick={() => { onHandleCreate() }}>{<FormattedMessage id="app.common.action.add" />}</Button>
        </div>
    </EFCContainer>
}
export default ClientNew