import "./index.less"

import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { connect } from "react-redux";
import { reqResetPassword } from "@/api/user.js"
import EFCCodeButton from "@/components/ef-c-code-button"
import { isValidPassword } from "../../utils/ef-re";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import Password from "antd/lib/input/Password";

import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.state = {
            resetCode: false,
            isloading: false
        }
    }

    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("profile"));
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.profile" />,
                path: "/profile",
            },
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.password" />,
                path: "/reset-password",
            }
        ]));
    }

    onFinish = (values) => {
        const { passwordOld, password, password2, code } = values;
        if (!isValidPassword(password)) {
            message.error(this.props.intl.formatMessage(messages["message.error.password_character"]))
            return
        }
        if (password !== password2) {
            message.error(this.props.intl.formatMessage(messages["message.error.confirm_password_same"]))
            return
        }

        let body = {
            passwordOld,
            password,
            password2,
            code
        }
        this.setState({ isloading: true })
        reqResetPassword(body).then((response) => {
            let { data } = response
            if (data.code === 0) {
                this.setState({ resetCode: true, isloading: false })
                message.success(this.props.intl.formatMessage(messages["message.success.password_reset_success"]))
            }
        }).catch((error) => {
            this.setState({ resetCode: true, isloading: false })
            message.error(this.props.intl.formatMessage(messages["message.error.password_reset_failed"]))
        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }

    getMail = () => {
        let errors = this.formRef.current.getFieldsError(["email"])[0]["errors"];
        if (errors && errors.length > 0) {
            this.setState({ error: errors[0] })
            return null
        } else {
            let mail = this.props.mail;
            return mail
        }
    }

    shouldReset = () => {
        let shouldReset = this.state.resetCode

        if (shouldReset) {
            this.setState({
                resetCode: false
            })
        }
        return shouldReset;
    }


    render() {
        return (
            <div className="setting-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.password_reset.title" />}>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.password_reset.old_password" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="passwordOld" rules={[
                                            {
                                                type: "string",
                                                max: 20,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_current_password"]),
                                            },
                                        ]}>
                                            <Input.Password className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.password_reset.new_password" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="password" rules={[
                                            {
                                                type: "string",
                                                max: 20,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_new_password"]),
                                            },
                                        ]}>
                                            <Input.Password className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.password_reset.new_password_confirm" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="password2" rules={[
                                            {
                                                type: "string",
                                                max: 20,
                                                required: true,
                                                message:this.props.intl.formatMessage(messages["message.error.no_confirm_new_password"]),
                                            },
                                        ]}>
                                            <Input.Password className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.password_reset.code" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="code" rules={[
                                            {
                                                type: "string",
                                                max: 20,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_code"]),
                                            },
                                        ]}>
                                            <Input className="code-text" />
                                        </Form.Item>
                                        <EFCCodeButton getMail={this.getMail} shouldReset={this.shouldReset} />
                                    </div>
                                </div>
                            </EFSimpleContainer>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="acition-container">
                                <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>
                                    <FormattedMessage id="app.ui.profile.password_reset.reset_button" />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(injectIntl(ResetPassword))