import React, { Component, useRef } from "react"
import { Layout } from "antd"
import EFHeader from "./header"
import EFSider from "./sider"
import EFContent from "./content"
import EFHead from "./head"
import { connect } from "react-redux"
import commonMessage from '../../locales/messages'
import { injectIntl } from "react-intl"

import "./index.less"

class EFMainLayout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            IsMenuCollapsed: false
        }
    }

    componentDidMount = () => {
        const handleResize = () => {
            const screenWidth = window.innerWidth
            const threshold = 1800 // 根据你的需求设定阈值
            if(this.state.IsMenuCollapsed !== (screenWidth < threshold)) {
                this.setState({ IsMenuCollapsed : screenWidth < threshold })
            }
        }
        // 监听窗口尺寸变化
        window.addEventListener('resize', handleResize)
        handleResize()
    }

    render () {
        const contentClassName = this.props.isCollapsed ? "content-container-small" : "content-container-normal"
        const nameIntlKey = this.props.intl.formatMessage(
            commonMessage['locale.name_locale_key']
        )
        let fontFamily = nameIntlKey === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'
        return (
            <Layout className={`container ${fontFamily}`} hasSider>
                <EFHead />
                <EFSider collapsed={this.state.IsMenuCollapsed}/>
                <Layout className={contentClassName}>
                    {/* <EFHeader /> */}
                    <EFContent />
                </Layout>
            </Layout>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.mainMenu,
    }
}


export default connect(mapStateToProps)(injectIntl(EFMainLayout))
