import "./index.less"
import React, { Component, useEffect, useRef, useState } from "react"
import { Table, Space, Button, Image, Tooltip } from "antd"
import { reqFetchClients } from '../../../api/analyst-client'
import { getCompanyInformation } from './ec-analysis'
import { FormattedMessage } from "react-intl"

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const AnalystClientListBackup = () => {
    const [dataSource, setDataSource] = useState([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const columns = useRef([])

    useEffect(() => {
        columns.current = getCompanyInformation()
        columns.current.push({
            title: <FormattedMessage id="app.common.action"/>,  
            key: 'action',
            fixed: "right",
            align: 'center',
            width: '110px',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                        <Button type="link" className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing"/>} href={"/manage/client/" + record.client_id}></Button>
                    </Tooltip>
                </Space>
            ),
        })

        reqFetchClients().then((response) => {
            const { data } = response
            if (data.code === 0) {
                let dataSource = data.data.client.map((client) => {
                    let clientInfo = { key: client.client_id, ...client }
                    return clientInfo
                })
                setDataSource(dataSource)
            }
        }).catch((error) => {

        })
    }, [])

    return (
        <div className="analyst-client-container">
            <div>
                <Table
                    className="ef-table"
                    columns={columns.current}
                    dataSource={dataSource}
                    scroll={{
                        x: 'max-content'
                    }}
                    pagination={false}
                />
            </div>
        </div>
    )
}
export default AnalystClientListBackup
