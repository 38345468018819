import * as types from "../action-types";

export const setMenuActiveKey = (activeKey) => {
    return {
        type: types.Menu_ACTIVE_KEY,
        activeKey:activeKey
    };
};

export const setMenuCollapsed = (isCollapsed) => {
    return {
        type: types.Menu_Collapsed,
        isCollapsed:isCollapsed
    };
};

