import React, { Component, useRef, useState, useCallback, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Input, Form, Button, Image } from "antd"
import { useSelector } from "react-redux"
import { login } from "@/store/actions/auth"

import EFCCodeButton from "@/components/ef-c-code-button"
import { setCookieLocale } from "@/utils/cookie"
import store from "@/store"
import "../index.less"
import { changeLocale } from '../../../store/actions/setting'

import { reqCode } from "@/api/login"
import { FormattedMessage, useIntl } from "react-intl"
import messages from "../messages"
import Title from "antd/lib/skeleton/Title"

const LoginView = () => {

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }

    // 国际化配置
    const intl = useIntl()
    const [language, setLanguage] = useState(intl.locale)
    const formRef = useRef()
    const errorState = useRef({
        message: '',
        show: false
    })

    const [disable, setDisable] = useState(true)

    const verification = useRef(true)
    const codeState = useRef({ email: false, password: false })
    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(prevLoading => !prevLoading)
    }, [])

    // 计时器
    const [seconds, setSeconds] = useState(0)
    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [seconds])

    const handleLanguageChangeClick = (e) => {
        setLanguage(e.key)
        console.log(reduxProps)
        store.dispatch(changeLocale(e.key, reduxProps.id))
    }

    const items = [
        {
            key: 'en',
            label: 'English',
            onClick: (e) => {
                handleLanguageChangeClick(e)
            }
        },
        {
            key: 'ja',
            label: '日本語',
            onClick: (e) => {
                handleLanguageChangeClick(e)
            }
        },
    ]

    // <FormattedMessage id="app.layout.login.language" />

    const onHandleState = (value) => {
        if (verification.current !== value) {
            if (errorState.current.show === true) {
                errorState.current.show = false
                errorState.current.message = ''
            }
            verification.current = value
            toggleLoading()
        } else if (errorState.current.show === true) {
            errorState.current.show = false
            errorState.current.message = ''
            toggleLoading()
        }
    }

    const onValueChange = (fieldsValue) => {
        Object.keys(fieldsValue).map((item, index) => {
            if (item !== 'verification') {
                if (fieldsValue[item] !== '') {
                    codeState.current[item] = true
                } else {
                    codeState.current[item] = false
                }
            }
        })
        let active = false
        Object.keys(codeState.current).map((item, index) => {
            if (codeState.current[item] === false) {
                active = true
            }
        })
        onHandleState(active)
    }

    const onHandleGetCode = () => {
        if (seconds > 0) {
            return
        }
        let errors = formRef.current.getFieldsError(["email"])[0]["errors"]
        if (errors && errors.length > 0) {
            errorState.current.message = errors[0]
            errorState.current.show = true
            toggleLoading()
        } else {
            let mail = formRef.current.getFieldValue('email')
            if (seconds <= 0) {
                reqCode(mail)
                    .then((response) => {
                        let { data } = response
                        if (data.code === 0) {
                            errorState.current.message = ''
                            errorState.current.show = false
                            setSeconds(180)
                        } else {
                        }
                    })
                    .catch((error) => {
                        errorState.current.message = intl.formatMessage(messages['message.error.mail_format'])
                        errorState.current.show = true
                        toggleLoading()
                    })
            }
        }
    }

    const handleLogin = (email, password, verification) => {
        let action = { login }
        let currentAction = action.login
        errorState.current.show = false
        store.dispatch(currentAction(email, password, verification)).catch((error) => {
            errorState.current.message = intl.formatMessage(messages['message.error.login_failed'])
            errorState.current.show = true
            toggleLoading()
        })
    }

    const onFinish = (fieldsValue) => {
        const { email, password, verification } = fieldsValue
        handleLogin(email, password, verification)
    }

    const onFinishFailed = (values) => {
        const { errorFields } = values
        const { errors } = errorFields[0]
        if (errors && errors.length > 0) {
            errorState.current.show = true
            errorState.current.message = errors[0]
            toggleLoading()
        }
    }

    const renderError = () => {
        if (errorState.current.show === true) {
            return (
                <div className="error">
                    <img className="error-tips" src='/images/sigh.png' alt="error"></img>
                    <p className="error-desc">{errorState.current.message}</p>
                </div>
            )
        } else {
            return ''
        }
    }

    if (reduxProps.token) {
        return <Redirect to="/" />
    } else {
        return (
            <div className={`initContain ${language === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'}`}>
                <Image className="imageBackground" src="/images/login-background.png" width={'100%'} height={'100%'} preview={false}/>
                <div className="login-tabs">
                    <Dropdown menu={{ items }} trigger={["hover"]}>
                        <div className='login-language'>
                            <div className="login-language-text">{<FormattedMessage id="app.layout.header.language" />}</div><div className="login-language-icon"><DownOutlined /></div>
                        </div>
                    </Dropdown>
                </div>
                <div className="input-section">
                    <div className="header">
                        <img className="logo" src="ipf-logo.png" alt="logo" />
                    </div>
                    <div className="body">
                        {renderError()}
                        <Form ref={formRef} onFinish={onFinish} onFinishFailed={onFinishFailed} onValuesChange={onValueChange}>
                            <div className="input-item">
                                {/* <p className="input-title"><FormattedMessage id="app.ui.login.label.mail" /></p> */}
                                <Form.Item noStyle={true} name='email' rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_mail"]),
                                    },
                                    {
                                        type: "email",
                                        message: intl.formatMessage(messages["message.error.mail_format"])
                                    }
                                ]}>
                                    <Input
                                        readOnly={disable}
                                        onMouseDown={() => { setDisable(false) }}
                                        className="input-content"
                                        placeholder={intl.formatMessage(messages["message.error.no_mail"])}>
                                    </Input>
                                </Form.Item>
                            </div>
                            <div className="input-item">
                                {/* <p className="input-title"><FormattedMessage id="app.ui.login.label.password" /></p> */}
                                <Form.Item noStyle={true} name='password' rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_password"]),
                                    },
                                ]}>
                                    <Input.Password
                                        className="input-content"
                                        onMouseDown={() => { setDisable(false) }}
                                        placeholder={intl.formatMessage(messages["message.error.no_password"])}>
                                    </Input.Password>
                                </Form.Item>
                            </div>
                            <div className="input-item">
                                {/* <p className="input-title"><FormattedMessage id="app.ui.login.label.verification_code" /></p> */}
                                <Form.Item noStyle={true} name='verification' rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_code"]),
                                    },
                                ]}>
                                    <Input
                                        disabled={verification.current}
                                        className="input-content"
                                        onMouseDown={() => { setDisable(false) }}
                                        placeholder={intl.formatMessage(messages["message.error.no_verification_code"])}
                                        suffix={
                                            <p
                                                className="input-content-suffix"
                                                style={{
                                                    display: verification.current ? 'none' : 'block',
                                                    pointerEvents: seconds > 0 ? 'none' : 'auto',
                                                }}
                                                onClick={() => { onHandleGetCode() }}
                                            >{seconds > 0 ? seconds + 's' : <FormattedMessage id="app.ui.login.button.get_verification" />}</p>
                                        }
                                    >
                                    </Input>
                                </Form.Item>
                            </div>
                            <Form.Item className="login-up">
                                <Button htmlType="submit" className="input-button"><FormattedMessage id="app.ui.login.button.to_login" /></Button>
                            </Form.Item>
                            <div className="login-other">
                                <a className="login-other-reset" href="/forget-pass"><FormattedMessage id="app.ui.login.button.forget_password" /></a>
                                <a className="login-other-sign" href="/sign-up"><FormattedMessage id="app.ui.login.button.to_setup" /></a>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="footer">
                    © IP FORWARD All rights Reserved.
                </div>
            </div>
        )
    }
}
export default LoginView
