import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.clients_fetch_failed": {
        id: "app.ui.ec_task.message.error.clients_fetch_failed",
        defaultMessage: "クライアント情報が取得失敗になりました。ネットワークを確認してください。",
    },
    "message.error.task_fetch_failed": {
        id: "app.ui.ec_task.message.error.task_fetch_failed",
        defaultMessage: "taskの検索に失敗しました。",
    },

    "message.info.scraping_success": {
        id: "app.ui.ec_task.message.info.scraping_success",
        defaultMessage: "スクレイピングを開始しました。",
    },
    "message.error.scraping_fail": {
        id: "app.ui.ec_task.message.error.scraping_fail",
        defaultMessage: "失敗になりました。",
    },

    "message.info.delete_success": {
        id: "app.ui.ec_task.message.info.delete_success",
        defaultMessage: '削除成功しました。'
    },
    "message.error.delete_fail": {
        id: "app.ui.ec_task.message.error.delete_fail",
        defaultMessage: '削除失敗しました。'
    },

    "message.info.stop_success": {
        id: "app.ui.ec_task.message.info.stop_success",
        defaultMessage: 'スクレイピングを停止しました。'
    },
    "message.error.stop_fail": {
        id: "app.ui.ec_task.message.error.stop_fail",
        defaultMessage: '失敗になりました。'
    },

    "message.info.new_success": {
        id: "app.ui.ec_task.message.info.new_success",
        defaultMessage: '新しいタスクを作りました。'
    },
    "message.error.new_fail": {
        id: "app.ui.ec_task.message.error.new_fail",
        defaultMessage: '失敗になりました。'
    },

    "message.info.search_success": {
        id: "app.ui.ec_task.message.info.search_success",
        defaultMessage: 'データ検索に成功しました。'
    },
    "message.error.search_fail": {
        id: "app.ui.ec_task.message.error.search_fail",
        defaultMessage: 'データ検索に失敗しました。'
    }
})

export default messages
