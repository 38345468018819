import request from '@/utils/request'

export function reqCreateSpecialUrl(data){
    return request({
        url: '/api/analyst/create-special-url',
        method: 'post',
        data: data
    })
}

export function reqRemoveSpecialUrl(data){
    return request({
        url: '/api/analyst/remove-special-url',
        method: 'post',
        data: data
    })
}

export function reqUpdateSpecialUrl(data){
    return request({
        url: '/api/analyst/update-special-url',
        method: 'post',
        data: data
    })
}

export function reqFetchSpecialUrls(data){
    return request({
        url: '/api/analyst/fetch-special-urls',
        method: 'post',
        data: data
    })
}