import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from 'react-redux'
import { Table, Space, Button, Collapse, Image, Tooltip, message, Drawer, Modal } from "antd"
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqSearchTrademarks, reqRemoveTrademark } from "@/api/trademark.js"
import { reqFetchClients } from '@/api/analyst-client'
// import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import { CountryConfig } from "@/config/country.js"
import { ProductTrademarkType } from "@/config/product-type.js"

import moment from 'moment'
import { FormattedMessage, useIntl } from "react-intl"
import messages from "../messages"
import common_messages from "../../../locales/messages"
import { getCountryConfigDict } from "../../../config/country"

import { initUrl, urlParamsConvert } from "./trademark-list-url"

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFCSearchBar from '../../../components/ef-c-search-condition'
import { useHistory, useLocation } from 'react-router-dom'
import { deepCopy } from '@/utils/deepClone'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Panel } = Collapse

const TrademarkList = () => {

    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
    }

    const location = useLocation()
    const history = useHistory()
    const intl = useIntl()

    const dataSource = useRef()
    const clientId = useRef(reduxProps.role & 1 ? reduxProps.companyInfo.client_id : '')

    const clients = useRef([])
    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])
    const total = useRef()
    const pageCurrent = useRef()
    const pageSize = useRef()
    const [collapse, setcollapse] = useState(false)

    const filter = useRef(false)
    const filterItems = useRef([])
    const preFilterItems = useRef([])
    const filterColumns = useRef([])

    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    const setWhereFields = (data) => {
        let content = []
        data.map((item, index) => {
            let arr = []
            item.map((item1, index1) => {
                if (item1 === true) {
                    if (filterColumns.current) {
                        arr.push(filterColumns.current[index].items[index1].value)
                    }
                }
            })
            if (arr.length > 0) {
                content.push({ key: filterColumns.current[index].key, values: arr })
            }
        })
        onHandleChange({ whereFields: content })
    }

    const onUrlRender = () => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        return params
    }

    const onRemove = (record) => {
        reqRemoveTrademark({
            "trademarkId": record.trademark_id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // this.searchWithConditions(this.conditions)
                onHandleSearch()
                message.success(intl.formatMessage(messages['message.info.remove_success']))
            }
        }).catch((error) => {
            message.error(intl.formatMessage(messages['message.error.remove_error']))
        })
    }

    const onHandleChange = (value) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        Object.keys(value).map((item, index) => {
            let operation = convertObj.find(current => current.key === item)
            if (operation) {
                operation.excute(value[item])
            }
        })
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    const onHandlePageChange = (pagination, filters, sorter, extra) => {
        pageCurrent.current = pagination.current
        pageSize.current = pagination.pageSize
        onHandleChange({
            current: pagination.current,
            pageSize: pagination.pageSize,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order }] : null
        })
        onHandleSearch({
            current: pagination.current,
            pageSize: pagination.pageSize,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order === "ascend" ? 1 : 2 }] : null
        })
    }

    const onHandleSearch = (extraParams) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        if (extraParams && Object.keys(extraParams).length > 0) {
            Object.keys(extraParams).forEach(key => {
                params[key] = extraParams[key]
            })
        }
        renderTrademarks(params)
    }

    const onHandleFilterClick = (index1, index2) => {
        filterItems.current[index1][index2] = !filterItems.current[index1][index2]
        setWhereFields(filterItems.current)
        toggleLoading()
    }

    const onHandleFliterOpen = () => {
        filter.current = true
        toggleLoading()
    }

    const onHandleFilterClose = () => {
        filterItems.current = deepCopy(preFilterItems.current)
        preFilterItems.current = []
        filter.current = false
        setWhereFields(filterItems.current)
        toggleLoading()
    }

    const onHandleFilterCheck = () => {
        preFilterItems.current = []
        onHandleSearch()
        filter.current = false
        toggleLoading()
    }

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(location.search)
        history.push(searchParams)
    }

    const onHandleDelete = (record) => {
        return Modal.confirm({
            title: intl.formatMessage({ id: 'app.common.warning.delete' }),
            className: 'ef-modal',
            okText: intl.formatMessage({ id: "app.common.confirm.delete" }),
            cancelText: intl.formatMessage({ id: "app.common.cancel.delete" }),
            onOk: (close) => {
                onRemove(record)
                close()
            },
            onCancel: (close) => {
                close()
            }
        })
    }

    const getKeywordSearchItems = () => {
        return [
            // {
            //     name: "製品ジャンル",
            //     value: "trademark_type"
            // },
            {
                name: intl.formatMessage(messages["table.trademark_word"]),

                value: "trademark_text"
            },
            {
                name: intl.formatMessage(messages["table.trademark_right_holder"]),
                value: "trademark_belongsto"
            },
            {
                name: intl.formatMessage(messages["table.description_ja"]),
                value: "trademark_description_ja"
            },
            {
                name: intl.formatMessage(messages["table.description_en"]),
                value: "trademark_description_en"
            },
            {
                name: intl.formatMessage(messages["table.description_cn"]),
                value: "trademark_description_cn"
            },
        ]
    }

    const getDateSearchItems = () => {
        return [
            {
                name: intl.formatMessage(messages["table.trademark_registration_date"]),
                value: "trademark_register_date"
            },
            {
                name: intl.formatMessage(messages["table.trademark_expire_date"]),
                value: "trademark_invalid_date"
            },
        ]
    }

    const getRegionFilters = () => {
        if (dataSource.current) {
            let regionFilters = []
            let regionDict = {}
            let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            let localeDict = getCountryConfigDict()
            dataSource.current.every((trademark) => {
                if (!regionDict[trademark.trademark_region]) {
                    regionDict[trademark.trademark_region] = trademark
                    let localeInfo = localeDict[trademark.trademark_region]
                    let region = localeInfo ? localeInfo.name[localeKey] : trademark.trademark_region
                    regionFilters.push({
                        name: region,
                        value: trademark.trademark_region
                    })
                }
                return true
            })
            return regionFilters
        } else {
            return []
        }
    }

    const getProductTypeFilters = () => {
        let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
        return ProductTrademarkType.map(trademark => {
            return { name: trademark.name[localeKey], value: trademark.value }
        })
    }

    const getStatusFilters = () => {
        return [
            {
                name: <FormattedMessage id="app.common.label.blank" />,
                value: 0
            },
            {
                name: <FormattedMessage id="app.trademark_registration_status.register" />,
                value: 1
            },
            {
                name: <FormattedMessage id="app.trademark_registration_status.applying" />,
                value: 2
            },
            {
                name: <FormattedMessage id="app.trademark_registration_status.rejection" />,
                value: 3
            },
        ]
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("trademark"))
        if (reduxProps.role & 1) {
            store.dispatch(setContentContainerHeaderAction([
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_registration" />,
                    path: "/trademark/new",
                },
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_request_trademark" />,
                    path: "/trademark/registration",
                }
            ]))
        } else {
            store.dispatch(setContentContainerHeaderAction([
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_registration" />,
                    path: "/trademark/new",
                }
            ]))
        }
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.legal_services.tm_registration" />,
                path: "/trademark",
            },
        ]))
    }

    const renderTrademarks = (params) => {
        if (clientId.current) {
            params['clientId'] = clientId.current
        }
        reqSearchTrademarks(params).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // data.data.trademarks.map((trademark) => {
                //     this.keywordDict[trademark.trademark_id] = brand.keywords;
                //     this.modelDict[brand.brand_id] = brand.models
                //     return brand;
                // });
                total.current = data.data.trademarks.length
                dataSource.current = data.data.trademarks
                toggleLoading()
            }
        }).catch((error) => {

        })
    }

    const renderClientsAndFilter = async () => {
        await reqFetchClients().then((response) => {
            const { data } = response
            if (data.code === 0) {
                // this.setState({ clients: data.data.client })
                clients.current = data.data.client
                toggleLoading()
            }
        }).catch((error) => {

        })

        let obj = {}
        if (clientId.current) {
            obj['clientId'] = clientId.current
        }
        await reqSearchTrademarks(obj).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // data.data.trademarks.map((trademark) => {
                //     this.keywordDict[trademark.trademark_id] = brand.keywords;
                //     this.modelDict[brand.brand_id] = brand.models
                //     return brand;
                // });
                total.current = data.data.trademarks.length

                dataSource.current = data.data.trademarks
                toggleLoading()
            }
        }).catch((error) => {
            console.log(error)
        })
        renderFilter()
    }

    const renderFilter = () => {
        filterColumns.current = []
        filterColumns.current = [
            {
                title: <FormattedMessage id="app.ui.trademark.table.client" />,
                key: 'trademark_client_id',
                items: clients.current.map((item, index) => {
                    return {
                        name: item.client_name,
                        value: item.client_id
                    }
                })
            }
        ]
        filterColumns.current.push(
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_region" />,
                key: 'trademark_region',
                items: getRegionFilters()
            }
        )
        filterColumns.current.push(
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_product_genre" />,
                key: 'trademark_type',
                items: getProductTypeFilters()
            }
        )
        filterColumns.current.push(
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_registration_status" />,
                key: 'trademark_status',
                items: getStatusFilters()
            }
        )

        filterItems.current = filterColumns.current.map((item, index) => {
            let array = []
            item.items.map((item, index) => {
                array[index] = false
            })
            return array
        })
    }

    const renderTableColumns = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.trademark.table.id" />,
                dataIndex: 'trademark_id',
                key: 'trademark_id',
                fixed: true,
                width: 80,
                // sorter: (a, b) => compareNumber(a.trademark_id, b.trademark_id),
                sorter: true,
                showSorterTooltip: false,
            },
        ]
        if (reduxProps.role & 1) {

        } else {
            columns.push({
                title: <FormattedMessage id="app.ui.trademark.table.client" />,
                dataIndex: 'trademark_client_id',
                key: 'trademark_client_id',
                render: (text, record) => {
                    if (clients.current && clients.current.length > 0) {
                        let client = clients.current.find((client) => {
                            return client.client_id === record.trademark_client_id
                        })
                        if (client) {
                            return client.client_name
                        } else {
                            return record.trademark_client_id
                        }
                    } else {
                        return record.trademark_client_id
                    }
                },
                // sorter: (a, b) => compareNumber(a.trademark_client_id, b.trademark_client_id),
                sorter: true,
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_client_id) {
                        return value === record.trademark_client_id
                    } else {
                        return value === 0
                    }
                },
                // filters: this.getClientFilters(),
                // filterMultiple: true,
            })
        }

        let elseColumns = [
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_no" />,
                dataIndex: 'trademark_no',
                key: 'trademark_no',
                // sorter: (a, b) => compareString(a.trademark_no, b.trademark_no),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_region" />,
                dataIndex: 'trademark_region',
                key: 'trademark_region',
                // sorter: (a, b) => compareString(a.trademark_region, b.trademark_region),
                sorter: true,
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_region) {
                        return value === record.trademark_region
                    } else {
                        return value === 0
                    }
                },
                // filters: this.getRegionFilters(),
                // filterMultiple: true,
                render: (text, record) => {
                    let info = CountryConfig.find(country => {
                        return country.code === text
                    })
                    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                    return info ? info.name[localeKey] : ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_product_genre" />,
                dataIndex: 'trademark_type',
                key: 'trademark_type',
                // sorter: (a, b) => compareString(a.trademark_type, b.trademark_type),
                sorter: true,
                showSorterTooltip: false,
                render: (text, record) => {
                    let info = ProductTrademarkType.find(trademark => {
                        return trademark.value === text
                    })
                    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                    return info ? info.name[localeKey] : ""
                },
                // filters: this.getProductTypeFilters(),
                // filterMultiple: true,
                onFilter: (value, record) => {
                    if (record.trademark_type) {
                        return value === record.trademark_type
                    } else {
                        return value === 0
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_word" />,
                dataIndex: 'trademark_text',
                key: 'trademark_text',
                // sorter: (a, b) => compareString(a.trademark_text, b.trademark_text),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_registration_date" />,
                dataIndex: 'trademark_register_date',
                key: 'trademark_register_date',
                render: (text, record) => {
                    if (record.trademark_register_date) {
                        return moment(record.trademark_register_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                // sorter: (a, b) => {
                //     if (a.trademark_register_date) {
                //         if (b.trademark_register_date) {
                //             return compareDate(a.trademark_register_date, b.trademark_register_date)
                //         } else {
                //             return 1
                //         }
                //     } else {
                //         if (b.trademark_register_date) {
                //             return -1
                //         } else {
                //             return 0
                //         }
                //     }
                // },
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_expire_date" />,
                dataIndex: 'trademark_invalid_date',
                key: 'trademark_invalid_date',
                render: (text, record) => {
                    if (record.trademark_invalid_date) {
                        return moment(record.trademark_invalid_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                // sorter: (a, b) => {
                //     if (a.trademark_invalid_date) {
                //         if (b.trademark_invalid_date) {
                //             return compareDate(a.trademark_invalid_date, b.trademark_invalid_date)
                //         } else {
                //             return 1
                //         }
                //     } else {
                //         if (b.trademark_invalid_date) {
                //             return -1
                //         } else {
                //             return 0
                //         }
                //     }
                // },
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.remind_mail_flg" />,
                dataIndex: 'trademark_remind_flag',
                key: 'trademark_remind_flag',
                render: (text, record) => {
                    if (record.trademark_remind_flag) {
                        return <FormattedMessage id="app.common.label.yes" />
                    } else {
                        return <FormattedMessage id="app.common.label.no" />
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_right_holder" />,
                dataIndex: 'trademark_belongsto',
                key: 'trademark_belongsto',
                // sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_ja" />,
                dataIndex: 'trademark_description_ja',
                key: 'trademark_description_ja',
                width: 400,
                // sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_en" />,
                dataIndex: 'trademark_description_en',
                key: 'trademark_description_en',
                width: 400,
                // sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_cn" />,
                dataIndex: 'trademark_description_cn',
                key: 'trademark_description_cn',
                width: 400,
                // sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_registration_status" />,
                dataIndex: 'trademark_status',
                key: 'trademark_status',
                render: (text, record) => {
                    if (record.trademark_status === 1) {
                        return <FormattedMessage id="app.trademark_registration_status.register" />
                    } else if (record.trademark_status === 2) {
                        return <FormattedMessage id="app.trademark_registration_status.applying" />
                    } else if (record.trademark_status === 3) {
                        return <FormattedMessage id="app.trademark_registration_status.rejection" />
                    }
                    return ""
                },
                // sorter: (a, b) => compareNumber(a.trademark_status, b.trademark_status),
                sorter: true,
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_status) {
                        return value === record.trademark_status
                    } else {
                        return value === 0
                    }
                },
                // filters: [
                //     {
                //         text: <FormattedMessage id="app.common.label.blank" />,
                //         value: 0
                //     },
                //     {
                //         text: <FormattedMessage id="app.trademark_registration_status.register" />,
                //         value: 1
                //     },
                //     {
                //         text: <FormattedMessage id="app.trademark_registration_status.applying" />,
                //         value: 2
                //     },
                //     {
                //         text: <FormattedMessage id="app.trademark_registration_status.rejection" />,
                //         value: 3
                //     },
                // ],
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_remarks" />,
                dataIndex: 'trademark_remarks',
                key: 'trademark_remarks',
                width: 400,
                // sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                            <Button className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />} href={"/trademark/" + record.trademark_id}>
                                {/* <FormattedMessage id="app.common.action.detail"/> */}
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={<FormattedMessage id="app.common.action.delete" />} arrow={true}>
                            <Button className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu" />} onClick={() => { onHandleDelete(record) }}>
                                {/* <FormattedMessage id="app.common.action.delete" /> */}
                            </Button>
                        </Tooltip>
                    </Space>
                ),
                fixed: "right",
                width: 150
            },
        ]
        return columns.concat(elseColumns)
    }

    const renderFilterSection = () => {
        return filterColumns.current.map((item, index1) => {
            return <div className="ef-filter-flat">
                <p className="ef-filter-title ef-title-describe">{item.title}</p>
                <div className="ef-filter-section-one">
                    {item.items.map((params, index2) => {
                        return <div className={`ef-filter-item ef-text-normal ${filterItems.current[index1][index2] ? 'ef-filter-item-active' : ''}`}
                            onClick={() => { onHandleFilterClick(index1, index2) }}>{params.name}</div>
                    })}
                </div>
            </div>
        })
    }

    const renderDrawerFliter = () => {
        if (preFilterItems.current.length === 0) {
            preFilterItems.current = deepCopy(filterItems.current)
        }
        return <Drawer
            closable={false}
            title={<p className="ef-title-regular"><FormattedMessage id='app.ui.ec_site.drawer.title' /></p>}
            placement="right"
            onClose={onHandleFilterClose}
            open={filter.current}
            width={784}
            footer={
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button className='ef-button-linear' style={{ display: 'inline-block' }} onClick={onHandleFilterClose}>{<FormattedMessage id="app.components.data_filter.cancel" />}</Button>
                    <Button className='ef-button-color' style={{ marginLeft: '16px', display: 'inline-block' }} onClick={onHandleFilterCheck}>{<FormattedMessage id="app.components.data_filter.confirm" />}</Button>
                </div>
            }
        >
            {renderFilterSection()}
        </Drawer>
    }

    const renderInitUrl = () => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('current')) {
            pageCurrent.current = parseInt(decodeURIComponent(searchParams.get('current')))
        } else {
            pageCurrent.current = 1
        }
        if (searchParams.has('pageSize')) {
            pageSize.current = parseInt(decodeURIComponent(searchParams.get('pageSize')))
        } else {
            pageSize.current = 20
        }

        initUrl(searchParams)
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    useEffect(() => {
        renderInitUrl()
        renderBread()
        renderClientsAndFilter()
    }, [])

    return <div className="ef-container-father">
        <EFCContainer>
            <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                <Panel header={<FormattedMessage id="app.collapse.title" />} key="1">
                    <EFCSearchBar
                        date={getDateSearchItems()}
                        search={getKeywordSearchItems()}
                        locale={searchBarDescribe.current}
                        showItem={['date', 'search']}
                        onUrlRender={onUrlRender}
                        onHandleChange={(value) => { onHandleChange(value) }}
                        onHandleReset={onHandleReset}
                        onHandleSearch={(value) => { onHandleSearch() }}
                        height={140}
                    ></EFCSearchBar>
                </Panel>
            </Collapse>
        </EFCContainer>
        <EFCContainer overspread={collapse ? 42 : 202} style={{ padding: '16px 24px', marginTop: '20px' }}>
            <div className="trademark-table-header">
                <Tooltip placement="top" title={<FormattedMessage id="app.ui.eclist.tooltip.filter" />} arrow={true}>
                    <Button
                        className="ef-sidefun"
                        icon={<IconFont className="iconfont iconfont-wait" type="icon-shaixuan" />}
                        onClick={() => { onHandleFliterOpen() }} />
                </Tooltip>
            </div>
            <Table
                className="ef-table"
                scroll={{
                    x: 'max-content'
                }}
                columns={renderTableColumns()}
                dataSource={dataSource.current}
                onChange={onHandlePageChange}
                pagination={{
                    size: 'default',
                    total: total.current,
                    current: pageCurrent.current,
                    pageSize: pageSize.current,
                    showQuickJumper: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    showSizeChanger: true,
                    locale: customLocale,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current)
                }}
            ></Table>
        </EFCContainer>
        {renderDrawerFliter()}
    </div>
}
export default TrademarkList