import request from '@/utils/request'

export async function reqFetchTestPurchase(data) {
    return request({
        url: '/api/test-purchase/fetch',
        method: 'post',
        data: data
    })
}

export function reqAddTestPurchase(data) {
    return request({
        url: '/api/test-purchase/add',
        method: 'post',
        data: data
    })
}

export function reqRemoveTestPurchase(data) {
    return request({
        url: '/api/test-purchase/remove',
        method: 'post',
        data: data
    })
}

export function reqUpdateTestPurchase(data) {
    return request({
        url: '/api/test-purchase/update',
        method: 'post',
        data: data
    })
}