import React, { Component } from 'react'
import { Layout, Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from '@ant-design/icons';

import { logout } from "@/store/actions/auth";
import { changeLocale } from '../../../store/actions/setting';

import { connect } from "react-redux";
import store from "@/store";

import "./index.less"
import messages from './messages';
import { FormattedMessage } from 'react-intl';

const { Header } = Layout;

class MainHeader extends Component {

    handleLanguageChangeClick = (e) => {
        store.dispatch(changeLocale(e.key,this.props.userId));;
    }

    languageSelectMenu = (
        <Menu
            onClick={this.handleLanguageChangeClick}
            items={[
                {
                    key: 'en',
                    label: 'English',
                },
                {
                    key: 'ja',
                    label: '日本語',
                },
            ]}
        />
    );


    handleLogout = () => {
        store.dispatch(logout(this.props.token));;
    }

    render() {
        return (
            <Header className="ef-header-container">
                <Space className="ef-header-right-container">
                    {this.props.name}
                    <Dropdown overlay={this.languageSelectMenu} placement="bottomLeft" trigger={["click"]} >
                        <Button className="ef-header-logout-button" type="primary" ghost>
                            <Space>
                                <FormattedMessage id="app.layout.header.language" /><DownOutlined />
                            </Space>
                        </Button>

                        {/* <a onClick={(e) => e.preventDefault()}>
                        <Space>
                        <FormattedMessage id="app.layout.header.language" /><DownOutlined />
                        </Space>
                    </a> */}
                    </Dropdown>

                    <Button className="ef-header-logout-button" onClick={this.handleLogout}><FormattedMessage id="app.layout.header.logout" /></Button>
                </Space>
            </Header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
        ...state.setting,
    };
};

export default connect(mapStateToProps, { logout })(MainHeader);
