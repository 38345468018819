import React, { useState, useRef, useCallback, useEffect } from "react"
import { useSelector } from 'react-redux'
import EFSimpleContainer from "@/components/ef-simple-container"
import { TrademarkSites } from "@/config/trademark-site.js"
import { CountryConfig } from "@/config/country.js"
import { ProductTrademarkType } from "@/config/product-type.js"

import { Button, Row, Col, Select, Form, Input, DatePicker, message, Checkbox } from "antd"
import { useHistory, useLocation } from 'react-router-dom'
import { reqCreateTrademark } from "@/api/trademark.js"
import { reqFetchClients } from '@/api/analyst-client'
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import TradeMarkPictureUploader from "../common/image-uploader"

import { FormattedMessage, useIntl } from "react-intl"
import messages from "../messages"
import common_messages from "../../../locales/messages"
import { getCountryConfigDict } from "../../../config/country"

import EFCContainer from '../../../components/ef-c-container'
import EFBox from '../../../components/ef-c-box'

import '../index.less'

const TrademarkNew = () => {

    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
    }

    const intl = useIntl()
    const history = useHistory()
    const formRef = useRef()

    const selectTradeMarkSite = useRef()
    const clients = useRef([])
    const isPictureUploading = useRef(false)
    const imageName = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const onHandlePictureRemove = () => {
        imageName.current = null
    }

    const onHandlePictureUpload = (data) => {
        if (data && data.name) {
            imageName.current = data.name
        }
        isPictureUploading.current = false
    }

    const onHandleBeforePictureUpload = () => {
        isPictureUploading.current = true
    }

    const onHandleTrademarkSites = (value, option) => {
        selectTradeMarkSite.current = option ? option.value : ""
    }

    const onHandleGoToTradeMarkSite = () => {
        if (selectTradeMarkSite.current && selectTradeMarkSite.current.length > 0) {
            window.open(selectTradeMarkSite.current)
        } else {
            message.error(intl.formatMessage(messages["message.error.no_search_db"]))
        }
    }

    const onHandleUpdate = (values) => {
        if (isPictureUploading.current) {
            message.warning(intl.formatMessage(messages["message.info.image_uploading"]))
            return
        }

        let clientId
        if (reduxProps.role & 2) {
            clientId = values.trademark_client
        } else {
            clientId = reduxProps.companyInfo.client_id
        }

        let parameter = {}
        if (imageName.current) {
            parameter.image = imageName.current
        }

        parameter = {
            clientId: clientId,
            "no": values.trademark_no,
            "region": values.trademark_region,
            "type": values.trademark_type,
            "text": values.trademark_text,
            // "registerDate": values.trademark_register_date,
            // "invalidDate": values.trademark_invalid_date,
            "belongsto": values.trademark_belongsto,
            "descriptionJP": values.trademark_description_ja,
            "descriptionEN": values.trademark_description_en,
            "descriptionCN": values.trademark_description_cn,
            "status": values.trademark_status,
            "remarks": values.trademark_remarks,
            "remindFlag": values.trademark_remind_flag && values.trademark_remind_flag[0] === "1" ? true : false,
            ...parameter
        }

        if (values.trademark_register_date) {
            parameter.registerDate = values.trademark_register_date.format("YYYY-MM-DD")
        }
        if (values.trademark_invalid_date) {
            parameter.invalidDate = values.trademark_invalid_date.format("YYYY-MM-DD")
        }

        reqCreateTrademark(parameter).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success(intl.formatMessage(messages["message.info.trademark_added"]))
                history.push("/trademark")
            }
        }).catch((error) => {

        })
    }

    const onHandleUpdateFail = (values) => {
        const { errorFields } = values
        const { errors } = errorFields[0]
        message.error(errors[0])
    }

    const renderClientsForAnalyst = () => {
        if (reduxProps.role & 2) {
            return (
                <Row className="edit-item">
                    <Col span={6}>
                        <p className="ef-form-element-normal item-label"><FormattedMessage id="app.ui.trademark.table.client" /></p>
                    </Col>
                    <Col span={18}>
                        <Form.Item noStyle={true} name="trademark_client" rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages["message.error.no_client"]),
                            },
                        ]}>
                            <Select className="ef-form-element-normal" style={{ width: '100%' }}>
                                {clients.current.map((client) => {
                                    return <Select.Option value={client.client_id} key={client.client_id}>{client.client_name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )
        }
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("trademark"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.legal_services.tm_registration" />,
                path: "/trademark",
            },
            {
                name: <FormattedMessage id="app.common.action.add" />,
            },
        ]))
    }

    const renderClients = () => {
        if (reduxProps.role & 2) {
            reqFetchClients().then((response) => {
                const { data } = response
                if (data.code === 0) {
                    clients.current = data.data.client
                    toggleLoading()
                }
            }).catch((error) => {

            })
        }
    }

    useEffect(() => {
        renderBread()
        renderClients()
    }, [])

    document.title = intl.formatMessage(messages["registration_add_title"])
    return <EFCContainer className="ef-trademark-detail-container" overspread={0} style={{ padding: '32px' }}>
        <div className="ef-edit-section">
            <div className="ef-edit-section-title">
                <p className="color-bar"></p>
                <div className="describe ef-title-normal"><FormattedMessage id="app.ui.trademark.add.container_search.title" /></div>
            </div>
            <Select className="ef-form-element-normal" style={{ width: '320px', marginRight: '12px' }}
                onChange={onHandleTrademarkSites}>
                {TrademarkSites.map((trademarkSite) => {
                    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                    return <Select.Option value={trademarkSite.url}>{trademarkSite.name[localeKey]}</Select.Option>
                })}
            </Select>
            <Button className="ef-button-color-small" onClick={onHandleGoToTradeMarkSite}><FormattedMessage id="app.ui.trademark.add.container_search.button" /></Button>
        </div>
        <div className="ef-edit-section">
            <div className="ef-edit-section-title">
                <p className="color-bar"></p>
                <div className="describe ef-title-normal"><FormattedMessage id="app.ui.trademark.add.container_body.title" /></div>
            </div>
            <Form ref={formRef} onFinish={onHandleUpdate} onFinishFailed={onHandleUpdateFail}>
                <Row>
                    <Col span={12} className="ef-edit-section-column-left">
                        {renderClientsForAnalyst()}
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.label.trademark_image" /></p>
                            </Col>
                            <Col span={18}>
                                <TradeMarkPictureUploader
                                    onRemove={onHandlePictureRemove}
                                    onFinishUpload={onHandlePictureUpload}
                                    beforeUpload={onHandleBeforePictureUpload}
                                    clientId={reduxProps.companyInfo && reduxProps.companyInfo.client_id ? reduxProps.companyInfo.client_id : null} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} className="ef-edit-section-column-left">
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.table.trademark_region" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    noStyle={true}
                                    name="trademark_region"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage(messages["message.error.no_region"]),
                                        },
                                    ]}>
                                    <Select allowClear className="ef-form-element-normal"
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={(inputValue, option) => {
                                            return option.key.indexOf(inputValue) > -1 ? true : false
                                        }}>

                                        {CountryConfig.map((country) => {
                                            let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                                            return <Select.Option
                                                key={`${country.code}${country.name.ja}${country.name.en}`}
                                                value={country.code}>
                                                {country.name[localeKey]}
                                            </Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.table.trademark_no" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_no" rules={[
                                    {
                                        type: "string",
                                        max: 200,
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_trademark_no"]),
                                    },
                                ]}>
                                    <Input className="ef-form-element-normal" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.table.trademark_product_genre" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_type" rules={[
                                    {
                                        type: "string",
                                        max: 200,
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_product_genre"]),
                                    },
                                ]}>
                                    <Select className="ef-form-element-normal"
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={(inputValue, option) => {
                                            return option.key.indexOf(inputValue) > -1 ? true : false
                                        }}>
                                        {ProductTrademarkType.map(typeInfo => {
                                            let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                                            return <Select.Option
                                                key={`${typeInfo.value}${typeInfo.name.ja}${typeInfo.name.en}`}
                                                value={typeInfo.value}>
                                                <FormattedMessage id="app.ui.trademark.add.product_genre_prefix" values={{ number: typeInfo.value }} />
                                                {`${typeInfo.name[localeKey]}`}
                                            </Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.table.trademark_word" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_text" rules={[
                                    {
                                        type: "string",
                                        max: 200,
                                        required: true,
                                        message: intl.formatMessage(messages["message.error.no_word_mark"]),
                                    },
                                ]}>
                                    <Input className="ef-form-element-normal" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="ef-edit-section-column-right">
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal">
                                    <FormattedMessage id="app.ui.trademark.table.trademark_registration_date" />
                                </p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_register_date">
                                    <DatePicker className="ef-form-element-normal" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal">
                                    <FormattedMessage id="app.ui.trademark.table.trademark_expire_date" />
                                </p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_invalid_date">
                                    <DatePicker className="ef-form-element-normal" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.table.remind_mail_flg" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item valuePropName="checked" noStyle={true} name="trademark_remind_flag">
                                    <Checkbox className="ef-form-element-normal item-value" options={[
                                        {
                                            label: intl.formatMessage(messages["add.remind_message"]),
                                            value: "1"
                                        }
                                    ]}>{intl.formatMessage(messages["add.remind_message"])}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.table.trademark_right_holder" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_belongsto">
                                    <Input className="ef-form-element-normal item-value" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="edit-item">
                            <Col span={6}>
                                <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.table.trademark_registration_status" /></p>
                            </Col>
                            <Col span={18}>
                                <Form.Item noStyle={true} name="trademark_status">
                                    <Select className="ef-form-element-normal" style={{ width: '100%' }}>
                                        <Select.Option value={1}>
                                            <FormattedMessage id="app.trademark_registration_status.register" />
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            <FormattedMessage id="app.trademark_registration_status.applying" />
                                        </Select.Option>
                                        <Select.Option value={3}>
                                            <FormattedMessage id="app.trademark_registration_status.rejection" />
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div className="item-long-label-container">
                            <p className="ef-form-element-normal">
                                <FormattedMessage id="app.ui.trademark.table.description_ja" />
                            </p>
                        </div>
                        <Form.Item noStyle={true} name="trademark_description_ja">
                            <Input.TextArea className="ef-form-element-normal description-text" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="item-long-label-container">
                            <p className="ef-form-element-normal">
                                <FormattedMessage id="app.ui.trademark.table.description_en" />
                            </p>
                        </div>
                        <Form.Item noStyle={true} name="trademark_description_en">
                            <Input.TextArea className="ef-form-element-normal description-text" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="item-long-label-container">
                            <p className="ef-form-element-normal">
                                <FormattedMessage id="app.ui.trademark.table.description_cn" />
                            </p>
                        </div>
                        <Form.Item noStyle={true} name="trademark_description_cn">
                            <Input.TextArea className="ef-form-element-normal description-text" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="item-long-label-container">
                            <p className="ef-form-element-normal">
                                <FormattedMessage id="app.ui.trademark.table.trademark_remarks" />
                            </p>
                        </div>
                        <Form.Item noStyle={true} name="trademark_remarks">
                            <Input.TextArea className="ef-form-element-normal description-text" />
                        </Form.Item>
                    </Col>
                </Row>
                <Button htmlType="submit" className="ef-button-color-small update-button">
                    <FormattedMessage id="app.common.action.add" />
                </Button>
            </Form>
        </div>
    </EFCContainer>
}
export default TrademarkNew