import { ECColumnInfo } from '../../../config/column'
import { FormattedMessage } from 'react-intl'

import { InfringementCommonCategories } from '../../../config/infringement-common-category.js'
import moment from 'moment'

export const getHistoryColumns = (nameIntlKey) => {
    return [
        {
            defaultShow: true,
            title: <FormattedMessage id='app.ui.eclist_history.table.edited_by' />,
            dataIndex: 'updateByUserName',
            key: 'updateByUserName',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
        },{
            defaultShow: true,
            title: <FormattedMessage id='app.ui.eclist_history.table.edited_date' />,
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    return moment(value).format("YYYY-MM-DD")
                }
                return ""
            },
        },{
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_common" />,
            dataIndex: 'ec_category_common_type_id',
            key: 'ec_category_common_type_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, name) => {
                let commonCategory = InfringementCommonCategories.find((commonCategory) => {
                    return commonCategory.id === value;
                })
                return commonCategory ? commonCategory.name[nameIntlKey] : ""
            }
        },{
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
            dataIndex: 'ec_categorize_remarks',
            key: 'ec_categorize_remarks',
            width: 150,
            ellipsis: true,
            sorter: true,
        }
    ]
}