import React, { useRef,useEffect } from "react";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import store from "@/store";
import { Image } from 'antd';
import { useIntl,FormattedMessage } from 'react-intl'
import commonMessage from '../../locales/messages'
import './404.less'

// component reference dependency
import EFCContainer from '../../components/ef-c-container'

const Page404 = () => {

    // 国际化配置
    const intl = useIntl()
    const nameIntlKey = useRef()
    nameIntlKey.current = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )

    const fontFamily = useRef()
    fontFamily.current = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

    const localeEmpty = useRef()
    localeEmpty.current = <div className="ef-404-redirect">
        <Image width={180} height={140} src={'/images/404/404.png'} preview={false} />
        <text className='ef-title-regular ef-description1'><FormattedMessage id="app.ui.404.description1" /></text>
        <text className='ef-title-regular ef-description2'><FormattedMessage id="app.ui.404.description2" /></text>
        <text className='ef-text-block ef-404-redirect-email'>{<FormattedMessage id="app.ui.404.contact"/>}ipf@ip-fw.com</text>
    </div>

    const renderBread = () => {
        // store.dispatch(setMenuActiveKey("analyst-online-dashboard"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.404" />,
            },
        ]));
        document.title = intl.formatMessage({ id: "app.breadcrumbs.404" })
    }

    useEffect(() => {
        renderBread()
    },[])

    return <EFCContainer
        overspread={0}
        className={fontFamily.current}
        isEmpty={true}
        localeEmpty={localeEmpty.current}>
    </EFCContainer>
}
export default Page404