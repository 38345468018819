import './index.less'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { Select, DatePicker, Input, Button, Form } from 'antd'
import moment from 'moment'
import { start } from 'nprogress'
import { FormattedMessage, injectIntl } from 'react-intl'
import messages from './message'
import { useIntl } from 'react-intl'
import { options } from 'less'
const { Option } = Select
const { RangePicker } = DatePicker
const EFCSearchBar = (props) => {
    // 国际化配置
    const intl = useIntl()
    const formRef = useRef()
    const matchTypes = useRef([
        {
            intlKey: 'app.components.ef_search_bar.search_tpye_fuzzy_match',
            name: '曖昧検索',
            value: 3,
        },
        {
            intlKey: 'app.components.ef_search_bar.search_tpye_exactly_match',
            name: '完全一致',
            value: 4,
        },
        {
            intlKey: 'app.components.ef_search_bar.search_tpye_previous_match',
            name: '前一致',
            value: 1,
        },
        {
            intlKey: 'app.components.ef_search_bar.search_tpye_post_match',
            name: '後一致',
            value: 2,
        },
    ])

    let clients = []
    if (Array.isArray(props.clients)) {
        clients = props.clients
    }
    let search = []
    if (Array.isArray(props.search)) {
        search = props.search
    }
    let dateType = []
    if (Array.isArray(props.date)) {
        dateType = props.date
    }
    let showItem = []
    if (Array.isArray(props.showItem)) {
        showItem = props.showItem
    }

    const describe = props.locale
    const [componentSize, setComponentSize] = useState('default')
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size)
    }

    const handleChange = (value, params) => {
        if (props.onHandleChange) {
            if (params === 'clientId') {
                props.onHandleChange({ clientId: value })
            } else if (params === 'dateFields') {
                props.onHandleChange({ dateFields: value })
            } else if (params === 'dateRange') {
                props.onHandleChange({ dateRange: value })
            } else if (params === 'wordFields') {
                props.onHandleChange({ wordFields: value })
            } else if (params === 'searchType') {
                props.onHandleChange({ searchType: value })
            }
        }
    }

    const onChange = (value) => {
        if (props.onHandleChange) {
            props.onHandleChange({ keyword: value.target.value })
        }
    }

    const updateRangePickerValue = (dates) => {
        if (dates !== null) {
            let startTime = moment(dates[0]).format('YYYY-MM-DD')
            let endTime = moment(dates[1]).format('YYYY-MM-DD')
            formRef.current.setFieldsValue({
                dateRange: [moment(startTime), moment(endTime)],
            })
            if (props.onHandleChange) {
                props.onHandleChange({ startDate: startTime, endDate: endTime })
            }
        } else {
            if (props.onHandleChange) {
                props.onHandleChange({ startDate: '', endDate: '' })
            }
        }
    }

    const showClientSelector = () => {
        return (
            <Form.Item
                className='ef-search-container-item'>
                <Form.Item
                    name="clientId"
                    className='ef-search-edit-item'
                >
                    <Select allowClear className='ef-search-condition-item' style={{ width: 240 }} size="large"
                        onChange={(value) => { handleChange(value, 'clientId') }}>
                        {clients.map((client) => {
                            return (
                                <Select.Option
                                    key={`${client.client_id}`}
                                    value={client.client_id}>
                                    {client.client_name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        marginLeft: '32px',
                        display: (showItem.includes('search') || showItem.includes('date')) ? 'none' : 'inline-block'
                    }}>
                    <Button className='ef-button-color' htmlType='submit'>{(describe && describe[3]) && <FormattedMessage id={describe[3]} />}</Button>
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        marginLeft: '16px',
                        display: (showItem.includes('search') || showItem.includes('date')) ? 'none' : 'inline-block'
                    }}>
                    <Button className='ef-button-linear' onClick={onReset}>{(describe && describe[4]) && <FormattedMessage id={describe[4]} />}</Button>
                </Form.Item>
            </Form.Item>
        )
    }

    const showDateSelector = () => {
        return (
            <Form.Item
                className='ef-search-container-item'>
                <Form.Item
                    name="dateFields"
                    layout="inline"
                    className='ef-search-edit-item'
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: 240 }}
                        size="large"
                        maxTagCount='responsive'
                        dropdownStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                        onChange={(value) => { handleChange(value, 'dateFields') }}>
                        {dateType.map((item) => {
                            return (
                                <Select.Option
                                    key={`${item.value}`}
                                    value={item.value}>
                                    {item.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="dateRange"
                    layout="inline"
                    style={{
                        display: 'inline-block',
                        marginLeft: '8px',
                    }}>
                    <RangePicker
                        size="large"
                        style={{
                            width: '488px', lineHeight: 40 + 'px',
                        }}
                        onChange={(val) => {
                            updateRangePickerValue(val)
                        }}
                    />
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        marginLeft: '32px',
                        display: showItem.includes('search') ? 'none' : 'inline-block'
                    }}>
                    <Button className='ef-button-color' htmlType='submit'>{(describe && describe[3]) && <FormattedMessage id={describe[3]} />}</Button>
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        marginLeft: '16px',
                        display: showItem.includes('search') ? 'none' : 'inline-block'
                    }}>
                    <Button className='ef-button-linear' onClick={onReset}>{(describe && describe[4]) && <FormattedMessage id={describe[4]} />}</Button>
                </Form.Item>
            </Form.Item>
        )
    }

    const showSearchbySelector = () => {
        return (
            <Form.Item
                layout="inline"
                className='ef-search-container-item'>
                <Form.Item
                    name="wordFields"
                    style={{
                        display: 'inline-block',
                        width: '240px',
                        marginBottom: '0px'
                    }}
                >
                    <Select
                        mode="multiple"
                        maxTagCount='responsive'
                        allowClear 
                        className='ef-search-edit-item' size="large"
                        onChange={(value) => { handleChange(value, 'wordFields') }}>
                        {search.map((item) => {
                            return (
                                <Select.Option
                                    key={`${item.value}`}
                                    value={item.value}>
                                    {item.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="keyword"
                    className='ef-search-edit-item'>
                    <Input placeholder={intl.formatMessage(messages['search_hint'])} size='large' style={{ height: 40 + 'px' }} onChange={onChange} />
                </Form.Item>

                <Form.Item
                    name="searchType"
                    className='ef-search-edit-item'>
                    <Select allowClear className="search-type" size='large' onChange={(value) => { handleChange(value, 'searchType') }}>
                        {createSelectItems(matchTypes.current)}
                    </Select>
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        display: 'inline-block',
                        marginLeft: '32px',
                    }}>
                    <Button className='ef-button-color' htmlType='submit'>{(describe && describe[3]) && <FormattedMessage id={describe[3]} />}</Button>
                </Form.Item>

                <Form.Item
                    layout="inline"
                    style={{
                        display: 'inline-block',
                        marginLeft: '16px',
                    }}>
                    <Button className='ef-button-linear' onClick={onReset}>{(describe && describe[4]) && <FormattedMessage id={describe[4]} />}</Button>
                </Form.Item>
            </Form.Item>
        )
    }

    const createSelectItems = (items) => {
        return items.map((item) => {
            return (
                <Option value={item.value} key={item.value}>
                    {item.intlKey ? <FormattedMessage id={item.intlKey} /> : item.name}
                </Option>
            )
        })
    }

    const initRender = () => {
    }

    const urlRender = (params) => {
        if (params['dateFields']) {
            if (typeof params['dateFields'] === 'string') {
                let array = params['dateFields'].split(',')
                formRef.current.setFieldsValue({
                    dateFields: array,
                })
            } else if(Array.isArray(params['dateFields'])){
                formRef.current.setFieldsValue({
                    dateFields: params['dateFields'],
                })
            }
        }

        if (params['startDate'] && params['endDate']) {
            formRef.current.setFieldsValue({
                dateRange: [moment(params['startDate']), moment(params['endDate'])],
            })
        }

        if (params['clientId']) {
            formRef.current.setFieldsValue({
                clientId: Number(params['clientId'])
            })
        }
        
        if (params['wordFields']) {
            if (typeof params['wordFields'] === 'string') {
                let array = params['wordFields'].split(',')
                formRef.current.setFieldsValue({
                    wordFields: array,
                })
            } else if(Array.isArray(params['wordFields'])){
                formRef.current.setFieldsValue({
                    wordFields: params['wordFields'],
                })
            }
        }

        if (params['searchType']) {
            formRef.current.setFieldsValue({
                searchType: Number(params['searchType'])
            })
        }

        if (params['keyword']) {
            formRef.current.setFieldsValue({
                keyword: params['keyword']
            })
        }
    }

    useEffect(() => {
        let params = {}
        if (props.onUrlRender) {
            params = props.onUrlRender()
        }
        if (params && Object.keys(params).length === 0) {
            initRender()
        } else if (params) {
            urlRender(params)
        }
    }, [])

    const onFinish = (value) => {
        if (value['dateRange'] && value['dateRange'][0] && value['dateRange'][1]) {
            value['startDate'] = value['dateRange'][0].format('YYYY-MM-DD')
            value['endDate'] = value['dateRange'][1].format('YYYY-MM-DD')
        }
        if (props.onHandleSearch) {
            props.onHandleSearch(value)
        }
    }

    const onReset = () => {
        formRef.current.resetFields()
        if (props.onHandleReset) {
            props.onHandleReset()
        }
    }

    const onFinishFaild = () => {
    }

    return (
        <div>
            <Form
                className='ef-search-container'
                layout="horizontal"
                initialValues={{
                    size: componentSize,
                }}
                ref={formRef}
                colon={false}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                onFinish={onFinish}
                onFinishFailed={onFinishFaild}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div className='ef-search-container-label' style={{ height: props.height + 'px'}}>
                        {(showItem.includes('clients')) ? <p className='ef-search-container-item'>{(describe && describe[0]) && <FormattedMessage id={describe[0]} />}</p> : ''}
                        {showItem.includes('date') ? <p className='ef-search-container-item'>{(describe && describe[1]) && <FormattedMessage id={describe[1]} />}</p> : ''}
                        {showItem.includes('search') ? <p className='ef-search-container-item'>{(describe && describe[2]) && <FormattedMessage id={describe[2]} />}</p> : ''}
                    </div>
                    <div className='ef-search-container-edit' style={{ height: props.height + 'px' }}>
                        {(showItem.includes('clients')) ? showClientSelector() : ''}
                        {showItem.includes('date') ? showDateSelector() : ''}
                        {showItem.includes('search') ? showSearchbySelector() : ''}
                    </div>
                </div>
                <div>
                </div>
            </Form>
        </div>

    )
}
export default EFCSearchBar