import React, { Component } from 'react'
import EFRouter from "@/router"
import "@/styles/index.less"
import { Provider } from 'react-redux'
import efstore from "./store"
import { ConfigProvider, theme } from 'antd'
import { IntlProvider } from 'react-intl'
// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
import { connect } from "react-redux"
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

class App extends Component {
	render () {
		return (
			<IntlProvider locale={this.props.intl.locale} messages={this.props.intl.messages}>
				<ConfigProvider locale={this.props.intl.antdLocale}>
					<EFRouter />
				</ConfigProvider>
			</IntlProvider>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		...state.setting,
	}
}

export default connect(mapStateToProps)(App)
