import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import Lottie from "lottie-react"
import { useLottie } from "lottie-react"
import waterBall from './data.json'

const EFCChartBall = (props) => {
  const title = props.chartTitle
  const desc = props.chartDesc
  const [progress, setProgress] = useState(0)
  const lottieRef = useRef(null)
  const chartData = props.chartData

  const categorizedCountRef = useRef()
  const infringmentCountRef = useRef()

  const handleEnterFrame = useCallback((event, percent) => {
    const animationProgress = event.currentTime / event.totalTime
    textAnimation(animationProgress)
    if (animationProgress >= percent) {
      lottieRef.current.pause()
      event.currentTime = 0
    }
  }, [])

  const textAnimation = (animationProgress) => {
    if (infringmentCountRef.current) {
      infringmentCountRef.current.style.bottom = 18 + Math.round(animationProgress * 64) / 2 + '%'
    }
    if (categorizedCountRef.current) {
      categorizedCountRef.current.style.bottom = 50 + Math.round(animationProgress * 68) / 2 + '%'
    }
  }

  const Animation = () => {
    let percent = 0
    if (chartData['categorizedCount'] !== 0) {
      percent = chartData['infringementCount'] / chartData['categorizedCount']
    }

    if (percent !== 0 && percent !== progress) {
      setProgress(percent)
      if (lottieRef.current) {
        lottieRef.current.goToAndPlay(0) // 重置到第0帧
        lottieRef.current.play()
      }
    } else if (percent === 0) {
      if (lottieRef.current) {
        lottieRef.current.goToAndPlay(0) // 重置到第0帧
        lottieRef.current.play()
      }
    }
    return <Lottie animationData={waterBall}
      loop={false}
      speed={1}
      onEnterFrame={(event) => handleEnterFrame(event, percent)}
      renderer="svg"
      lottieRef={lottieRef}
    />
  }

  const renderContent = () => {
    if (chartData && Object.keys(chartData).length > 0) {
      const keys = Object.keys(chartData)
      return (
        <div style={{ width: '100%', height: '100%' }} className='ef-chart-content-ball-section'>
          <div className='ef-chart-content-left'>
            <div className='ef-chart-content-left-section' style={{ width: '199px', height: '199px' }}>
              <div className='ef-chart-ball-animation'>
                {Animation()}
              </div>
              <div className='ef-chart-ball-categorized' ref={categorizedCountRef}>
                <div className='ef-title-normal ef-chart-ball-number'>{chartData[keys[0]]}</div>   
                <div className='ef-text-describe'>{(desc && desc[0]) ? <FormattedMessage id={desc[0]} />:''}</div>
              </div>
              <div className='ef-chart-ball-infrigement' ref={infringmentCountRef}>
                <div className='ef-title-normal ef-chart-ball-number'>{chartData[keys[1]]}</div>
                <div className='ef-text-describe'>{(desc && desc[1]) ? <FormattedMessage id={desc[1]} />:''}</div>
              </div>
            </div>
          </div>
          <div className='ef-chart-content-right'>
            <div className='ef-chart-content-right-content'>
              {keys.map((item, index) => {
                return (
                  <div className='ef-chart-content-right-item'>
                    <p className='ef-text-normal'>{(desc && desc[index]) ? <FormattedMessage id={desc[index]} />:''}</p><p className='ef-title-describe ef-chart-ball-number'>{chartData[item]}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  useEffect(() => {
    return () => {
      if (lottieRef.current) {
        lottieRef.current.pause()
      }
    }
  }, [])

  return (
    <div
      className="ef-chart-ball"
      style={{
        height: props.height ? props.height + 'px' : '312px',
      }}>
      <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
      <div className="ef-chart-content">
        <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px', width: '100%' }} >
          {renderContent()}
        </div>
      </div>
    </div>
  )
}
export default EFCChartBall
