import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.fetch_user_fail": {
        id: "app.ui.brand_reg_new.message.error.fetch_user_fail",
        defaultMessage: "Usersの取得に失敗しました。",
    },
    "message.error.create_fail": {
        id: "app.ui.brand_reg_new.message.error.create_fail",
        defaultMessage: "作成に失敗しました。",
    },
    "message.error.tel_error": {
        id: "app.ui.brand_reg_new.message.error.tel_error",
        defaultMessage: "正しい電話番号を入力してください。",
    },
    "message.error.name_error": {
        id: "app.ui.brand_reg_new.message.error.name_error",
        defaultMessage: "会社名を入力ください，30文字以内。",
    },
    "message.error.address_error": {
        id: "app.ui.brand_reg_new.message.error.address_error",
        defaultMessage: "正しい住所を入力ください，100文字以内。",
    },
    "message.error.remarks_error": {
        id: "app.ui.brand_reg_new.message.error.remarks_error",
        defaultMessage: "正しいアナリストを入力ください，500文字以内。",
    },
})

export default messages