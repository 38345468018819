
import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store"
import React, { useEffect, useRef, useState, useCallback } from "react"
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Select, message } from "antd"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqGetUser, reqUpdateUser, reqRemoveUser } from '../../../api/admin-user'
import { reqSearchClients } from '../../../api/admin-client'
import EFAdminResetPassword from "../reset-password"
import moment from 'moment'
import { isValidTelNo } from "../../../utils/ef-re"

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFBoxList from '../../../components/ef-c-messageBox'

// react语言处理
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'
const UserDetailBackup = () => {

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }
    const intl = useIntl()

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()

    const analystId = useRef(parseInt(location.pathname.split("/")[3]))
    const companies = useRef([])
    const analyst = useRef({})
    const selectCompany = useRef({})
    const company = useRef()
    const rowRef = useRef()
    const [rowHeight, setRowHeight] = useState(500)

    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])
    const [currentState, setCurrentState] = useState()
    const updateTrigger = useRef(false)

    const setFormFields = (userInfo) => {
        basicInformationRef.current = {}
        basicInformationRef.current = {
            id: analyst.current.user_id,
            name: userInfo.user_name,
            mail: analyst.current.user_mail,
            tel: userInfo.user_tel,
            department: userInfo.user_department,
            remarks: userInfo.user_remarks,
        }
        basicTypeRef.current = []
        basicTypeRef.current = [
            {
                label: 'app.ui.user_list_detail.user_id',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.user_list_detail.name',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.user_mail',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.user_list_detail.tel',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.department',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.remarks',
                type: 'textarea',
                permission: true
            }
        ]
    }

    const setFormFieldsCompany = () => {
        companyInformationRef.current = {}
        companyInformationRef.current['company'] = company.current
        let allCompany = companies.current.map((item, index) => {
            return { label: item.client_name, value: item.client_id }
        })

        companyInformationRef.current['client_tel'] = selectCompany.current ? selectCompany.current.client_tel : ''
        companyInformationRef.current['client_address'] = selectCompany.current ? selectCompany.current.client_address : ''
        companyInformationRef.current['client_remarks'] = selectCompany.current ? selectCompany.current.client_remarks : ''
        companyTypeRef.current = []
        companyTypeRef.current = [
            {
                label: 'app.ui.profile.section.company.name',
                type: 'select',
                params: allCompany,
                permission: true
            }, {
                label: 'app.ui.profile.section.company.tel',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.profile.section.company.address',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.profile.section.company.remarks',
                type: 'textarea',
                permission: false
            }
        ]
    }

    const setFormFieldsMoment = () => {
        momentTypeRef.current = []
        momentTypeRef.current = [
            {
                label: 'app.ui.profile.section.moment.lastlogintime',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.profile.section.moment.setuptime',
                type: 'input',
                permission: false
            }
        ]
        momentInformationRef.current = {}
        momentInformationRef.current = {
            user_last_used_date: analyst.current.user_last_used_date ? moment(analyst.current.user_last_used_date).format("YYYY-MM-DD") : "",
            user_create_at: analyst.current.user_create_at ? moment(analyst.current.user_create_at).format("YYYY-MM-DD") : ""
        }
    }

    const basicInformationRef = useRef({})
    const basicTypeRef = useRef([])
    const companyInformationRef = useRef({})
    const companyTypeRef = useRef([])
    const momentInformationRef = useRef({})
    const momentTypeRef = useRef([])
    const queueList = useRef({ basic: false, company: false })
    const condition = useRef({})

    const getData = async () => {
        await reqGetUser({ userId: analystId.current, roles: [1] }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                if (data.data.user) {
                    analyst.current = data.data.user
                    selectCompany.current = data.data.user.companyInfo
                    setFormFields(data.data.user)
                    if((data.data.user.companyInfo)) {
                        company.current = data.data.user.companyInfo.client_id
                    }
                    setFormFieldsMoment()
                }
            } else {
                toggleLoading()
            }
        }).catch((error) => {
            console.log(error)
        })

        await reqSearchClients().then((response) => {
            const { data } = response
            if (data.code === 0) {
                companies.current = data.data.client
                setFormFieldsCompany()
                toggleLoading()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        store.dispatch(setMenuActiveKey("manage-user-list"))
        store.dispatch(setContentContainerHeaderAction([
            {
                name: <FormattedMessage id="app.ui.user_list_detail.delete" />,
                onClick: () => {
                    reqRemoveUser({
                        userId: analystId.current
                    }).then((response) => {
                        history.push("/manage/user")
                    }).catch((e) => [

                    ])
                }
            },
            {
                item: <EFAdminResetPassword userId={analystId.current} />
            }
        ]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.ui.user_list_detail.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.user_list_detail.userlist" />,
                path: "/manage/user",
            },
            {
                name: <FormattedMessage id="app.ui.user_list_detail.detail" />,
            }
        ]))
        document.title = intl.formatMessage({id:'app.ui.user_list_detail.title'})
        
        getData()
    }, [])

    const reRender = (obj) => {
        basicInformationRef.current = {
            id: obj.user_id,
            name: obj.user_name,
            email: obj.user_mail,
            tel: obj.user_tel,
            department: obj.user_department,
            remarks: obj.user_remarks,
        }
        companyInformationRef.current = {
            company: obj.companyInfo.client_id,
            client_tel: obj.companyInfo.client_tel,
            client_address: obj.companyInfo.client_address,
            client_remarks: obj.companyInfo.client_remarks
        }

    }

    const reClear = () => {
        Object.keys(queueList.current).map((item, index) => {
            queueList.current[item] = false
        })
        condition.current = {}
    }

    const onHandleParams = (value, item) => {
        if (item === 'company') {
            companyInformationRef.current['company'] = value
            selectCompany.current = companies.current.find((item) => value === item.client_id)
            companyInformationRef.current['client_tel'] = selectCompany.current ? selectCompany.current.client_tel : ''
            companyInformationRef.current['client_address'] = selectCompany.current ? selectCompany.current.client_address : ''
            companyInformationRef.current['client_remarks'] = selectCompany.current ? selectCompany.current.client_remarks : ''
            toggleLoading()
        }
    }

    const onRuleControl = (name, tel, department, remarks) => {
        if (!name) {
            message.error(intl.formatMessage(
                messages['message.error.name_empty']
            ))
            return true
        }
        if (tel && !isValidTelNo(tel)) {
            message.error(intl.formatMessage(
                messages['message.error.phone_failed']
            ))
            return true
        }
        if (!department) {
            message.error(intl.formatMessage(
                messages['message.error.section_failed']
            ))
            return true
        }
        if (remarks && remarks.length > 500) {
            message.error(intl.formatMessage(
                messages['message.error.remarks_failed']
            ))
            return true
        }
        return false
    }

    const onHandleChange = (value, order) => {
        queueList.current[order] = !queueList.current[order]
        condition.current = { ...value, ...condition.current }
        for (let key in queueList.current) {
            if (queueList.current.hasOwnProperty(key)) {
                if (queueList.current[key] === false) {
                    return
                }
            }
        }
        const { name, tel, remarks, department, company } = condition.current

        if (onRuleControl(name, tel, department, remarks)) {
            reClear()
            return
        }

        let body = {
            userId: analystId.current,
            name,
            tel,
            department,
            remarks,
        }
        if (company) {
            body.companyId = parseInt(company)
        }
        reqUpdateUser(body).then((response) => {
            const { data } = response
            if (data.code === 0) {
                reRender(data.data.user)
                reClear()
                toggleLoading()
                message.success(intl.formatMessage(
                    messages['message.info.update_success']
                ))
            }
        }).catch((error) => {
            toggleLoading()
            message.error(intl.formatMessage(
                messages['message.error.update_failed']
            ))
        })
    }

    const onHandleState = (value) => {
        setCurrentState(value)
        if (value === false) {
            getData()
        }
    }

    const onHandleUpdate = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderTrigger = () => {
        if (currentState === true) {
            return <div className='ef-box-footer'>
                <Button className='ef-button-secondary-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.ui.profile.section.basicinfo.cancel' /></Button>
                <Form.Item>
                    <Button className='ef-button-color-small' style={{ marginLeft: '12px' }} onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.ui.profile.section.basicinfo.update' /></Button>
                </Form.Item>
            </div>
        } else {
            return <div className='ef-box-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.ui.profile.section.basicinfo.edit' /></Button>
            </div>
        }
    }

    const isUpdate = () => {
        return updateTrigger.current
    }

    return <EFCContainer
        overspread={0}
        style={{ padding: '32px'}}
    >
        <Row gutter={[20, 40]} ref={rowRef}>
            <Col span={12}>
                <EFBoxList
                    mode={true}
                    height={'100%'}
                    title={<FormattedMessage id="app.ui.profile.section.basicinfo.title" />}
                    titleBarColor={'#1880DE'}
                    basicInfo={basicInformationRef.current}
                    infoType={basicTypeRef.current}
                    lineWidth={300}
                    onHandleChange={(value) => { onHandleChange(value, 'basic') }}
                    state={currentState}
                    updateTrigger={isUpdate()}
                ></EFBoxList>
            </Col>
            <Col span={12}>
                <EFBoxList
                    mode={true}
                    height={'100%'}
                    title={<FormattedMessage id="app.ui.profile.section.company.title" />}
                    titleBarColor={'#1880DE'}
                    basicInfo={companyInformationRef.current}
                    infoType={companyTypeRef.current}
                    lineWidth={300}
                    onHandleParams={(value, item) => { onHandleParams(value, item) }}
                    onHandleChange={(value) => { onHandleChange(value, 'company') }}
                    state={currentState}
                    updateTrigger={isUpdate()}
                ></EFBoxList>
            </Col>
            <Col span={12}>
                <EFBoxList
                    mode={true}
                    height={'100%'}
                    title={<FormattedMessage id="app.ui.profile.section.moment.title" />}
                    titleBarColor={'#1880DE'}
                    basicInfo={momentInformationRef.current}
                    infoType={momentTypeRef.current}
                    lineWidth={300}
                    onHandleChange={(value) => { }}
                    state={currentState}
                    updateTrigger={isUpdate()}
                ></EFBoxList>
            </Col>
        </Row>
        {renderTrigger()}
    </EFCContainer>

}
export default UserDetailBackup