import react, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'

// antd组件引用
import { Row, Col, message, Button, Form } from 'antd'

// redux
import store from '@/store'
import { setMenuActiveKey } from '@/store/actions/menu.js'
import {
    setContentContainerHeaderAction,
    setBreadcrumbs,
} from '@/store/actions/content-header.js'
import { setUserInfo } from '@/store/actions/user.js'

// react语言处理
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'

// component
import EFSimpleContainer from '@/components/ef-simple-container'
import EFAnalystClientList from '@/views/analyst/client-list'

// api接口
import { reqUpdateUserInfo } from '@/api/user.js'

// component reference dependency
import EFCContainer from '../../components/ef-c-container'
import EFBoxList from '../../components/ef-c-messageBox'
import EFBoxTable from './box/ef-box-table'

// 插件类函数
import moment from 'moment'
import { getCurrencyName } from '@/utils/currency.js'

const UpdateUserInfo = () => {
    // 记录国际化配置
    const intl = useIntl()
    // 记录权限名
    const role = useRef([])
    // dom元素实例
    const formRef = useRef()
    // 是否加载中
    const [Loading, setLoading] = useState(true)
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }

    const [currentState, setCurrentState] = useState()
    const updateTrigger = useRef()

    const basicInformationRef = useRef({})
    const basicTypeRef = useRef([])

    const companyInformationRef = useRef({})
    const companyTypeRef = useRef([])

    // 权限函数
    const getAuth = () => {
        // 组件的JSX代码
        role.current = []
        if (reduxProps.role & 4) {
            role.current.push('管理者')
        }
        if (reduxProps.role & 2) {
            role.current.push('アナリスト')
        }
        if (reduxProps.role & 1) {
            role.current.push('クライアント')
        }
    }

    // 获得基本信息
    const getBasicInformation = () => {
        if (reduxProps.role & 2) {
            basicInformationRef.current = {
                name: reduxProps.name,
                mail: reduxProps.mail,
                role: role.current.join("、"),
                tel: reduxProps.tel,
            }
            basicTypeRef.current = []
            basicTypeRef.current = [
                {
                    label: 'app.ui.profile.section.basicinfo.name',
                    type: 'input',
                    permission: true
                }, {
                    label: 'app.ui.profile.section.basicinfo.mail',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.basicinfo.role',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.basicinfo.tel',
                    type: 'input',
                    permission: true
                }
            ]
        } else {
            basicInformationRef.current = {
                name: reduxProps.name,
                mail: reduxProps.mail,
                tel: reduxProps.tel,
            }
            companyInformationRef.current = {
                client_name: reduxProps.companyInfo.client_name,
                client_tel: reduxProps.companyInfo.client_tel,
                client_address: reduxProps.companyInfo.client_address,
                client_remarks: reduxProps.companyInfo.client_remarks
            }
            basicTypeRef.current = []
            companyTypeRef.current = []
            basicTypeRef.current = [
                {
                    label: 'app.ui.profile.section.basicinfo.name',
                    type: 'input',
                    permission: true
                }, {
                    label: 'app.ui.profile.section.basicinfo.mail',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.basicinfo.tel',
                    type: 'input',
                    permission: true
                }
            ]
            companyTypeRef.current = [
                {
                    label: 'app.ui.profile.section.company.name',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.company.tel',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.company.address',
                    type: 'input',
                    permission: false
                }, {
                    label: 'app.ui.profile.section.company.remarks',
                    type: 'input',
                    permission: false
                }
            ]
        }
    }

    useEffect(() => {
        getAuth()
        getBasicInformation()
        store.dispatch(setMenuActiveKey("profile"))

        if (formRef.current) {
            formRef.current.setFieldsValue({
                name: reduxProps.name,
                tel: reduxProps.tel,
            })
        }
        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.ui.profile.action.reset_password" />,
            path: "/profile/reset-password",
        }]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.profile" />,
                path: "/profile",
            },
        ]))
    }, [])

    const onHandleChange = (value) => {
        const { name, tel } = value
        let body = {
            name,
            tel,
        }
        reqUpdateUserInfo(body).then((response) => {
            let { data } = response
            if (data.code === 0) {
                store.dispatch(setUserInfo(data.data.user))
                if (reduxProps.role & 2) {
                    basicInformationRef.current = {
                        name: data.data.user.user_name,
                        mail: reduxProps.mail,
                        role: role.current.join("、"),
                        tel: data.data.user.user_tel,
                    }
                } else {
                    basicInformationRef.current = {
                        name: data.data.user.user_name,
                        mail: reduxProps.mail,
                        tel: data.data.user.user_tel,
                    }
                }
                toggleLoading()
                message.success(intl.formatMessage(messages["message.success.update_success"]))
            }
        }).catch((error) => {
            toggleLoading()
            message.error(intl.formatMessage(messages["message.error.update_failed"]))
        })
    }

    const getContractInfo = () => {
        return [
            {
                name: "EC Protection",
                from: reduxProps.companyInfo.service_ec_start_date,
                to: reduxProps.companyInfo.service_ec_end_date,
                fee: reduxProps.companyInfo.service_ec_fee,
                currency: reduxProps.companyInfo.service_ec_currency
            },
            {
                name: "SNS Protection",
                from: reduxProps.companyInfo.service_sns_start_date,
                to: reduxProps.companyInfo.service_sns_end_date,
                fee: reduxProps.companyInfo.service_sns_fee,
                currency: reduxProps.companyInfo.service_sns_currency
            },
            {
                name: "WS Protection",
                from: reduxProps.companyInfo.service_ws_start_date,
                to: reduxProps.companyInfo.service_ws_end_date,
                fee: reduxProps.companyInfo.service_ws_fee,
                currency: reduxProps.companyInfo.service_ws_currency
            },
            {
                name: "Other Protection",
                from: reduxProps.companyInfo.service_other_start_date,
                to: reduxProps.companyInfo.service_other_end_date,
                fee: reduxProps.companyInfo.service_other_fee,
                currency: reduxProps.companyInfo.service_other_currency
            }
        ]
    }

    const isUpdate = () => {
        return updateTrigger.current
    }

    const onHandleUpdate = () => {
        if (updateTrigger.current === undefined) {
            updateTrigger.current = false
        }
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const onHandleState = (value) => {
        setCurrentState(value)
    }

    const renderTrigger = () => {
        if (currentState === true) {
            return <div className='ef-box-footer'>
                <Button className='ef-button-secondary-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.ui.profile.section.basicinfo.cancel' /></Button>
                <Form.Item>
                    <Button className='ef-button-color-small' style={{ marginLeft: '12px' }} onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.ui.profile.section.basicinfo.update' /></Button>
                </Form.Item>
            </div>
        } else {
            return <div className='ef-box-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.ui.profile.section.basicinfo.edit' /></Button>
            </div>
        }
    }

    const renderList = () => {
        if (reduxProps.role & 2) {
            return (
                <Row gutter={[20, 0]}>
                    <Col span={24}>
                        <EFBoxList
                            mode={true}
                            basicInfo={basicInformationRef.current}
                            infoType={basicTypeRef.current}
                            title={<FormattedMessage id="app.ui.profile.section.basicinfo.title" />}
                            titleBarColor={'#1880DE'}
                            lineWidth={400}
                            onHandleChange={(value) => { onHandleChange(value) }}
                            state={currentState}
                            updateTrigger={isUpdate()}
                        ></EFBoxList>
                    </Col>
                    <div style={{ marginLeft: '10px' }}>{renderTrigger()}</div>
                </Row>
            )
        } else {
            return (
                <Row gutter={[20, 8]}>
                    <Col span={12}>
                        <EFBoxList
                            mode={true}
                            basicInfo={basicInformationRef.current}
                            infoType={basicTypeRef.current}
                            title={<FormattedMessage id="app.ui.profile.section.basicinfo.title" />}
                            titleBarColor={'#1880DE'}
                            lineWidth={400}
                            onHandleChange={(value) => { onHandleChange(value) }}
                            state={currentState}
                            updateTrigger={isUpdate()}
                        ></EFBoxList>
                        <div>{renderTrigger()}</div>
                    </Col>
                    <Col span={12}>
                        <EFBoxList
                            mode={true}
                            basicInfo={companyInformationRef.current}
                            infoType={companyTypeRef.current}
                            title={<FormattedMessage id="app.ui.profile.section.company.title" />}
                            titleBarColor={'#1880DE'}
                            lineWidth={400}
                            onHandleChange={() => { }}
                        ></EFBoxList>
                    </Col>
                </Row>
            )
        }
    }

    const renderTable = () => {
        if (reduxProps.role & 2) {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Row gutter={[20, 8]}>
                        <Col span={24}>
                            <EFBoxList
                                mode={true}
                                basicInfo={[]}
                                infoType={[]}
                                title={<FormattedMessage id="app.ui.profile.section.company.title" />}
                                titleBarColor={'#1880DE'}
                            >
                                <EFAnalystClientList />
                            </EFBoxList>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            let columns1 = [
                {
                    title: <FormattedMessage id="app.ui.profile.section.analyst.table.name" />,
                    dataIndex: 'user_name',
                    key: 'user_name',
                    width: 150,
                },
                {
                    title: <FormattedMessage id="app.ui.profile.section.analyst.table.mail" />,
                    dataIndex: 'user_mail',
                    key: 'user_mail',
                    width: 250,

                },
                {
                    title: <FormattedMessage id="app.ui.profile.section.analyst.table.tel" />,
                    dataIndex: 'user_tel',
                    key: 'user_tel',
                    width: 150,
                },
            ]
            let columns2 = [
                {
                    title: <FormattedMessage id="app.ui.profile.section.contract.table.name" />,
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,

                },
                {
                    title: <FormattedMessage id="app.ui.profile.section.contract.table.duration_from" />,
                    dataIndex: 'from',
                    key: 'from',
                    width: 150,
                    render: (value, record) => {
                        if (value) {
                            return moment(value).format("YYYY-MM-DD")
                        }
                    },

                },
                {
                    title: <FormattedMessage id="app.ui.profile.section.contract.table.duration_to" />,
                    dataIndex: 'to',
                    key: 'to',
                    width: 150,
                    render: (value, record) => {
                        if (value) {
                            return moment(value).format("YYYY-MM-DD")
                        }
                    },
                },
                {
                    title: <FormattedMessage id="app.ui.profile.section.contract.table.fee" />,
                    dataIndex: 'fee',
                    key: 'fee',
                    width: 150,
                    render: (value, record) => {
                        return value ? value + " " + getCurrencyName(record.currency) : ""
                    }
                },
            ]
            return (
                <div style={{ marginTop: '40px' }}>
                    <Row gutter={[20, 8]}>
                        <Col span={12}>
                            <EFBoxTable
                                className='ef-table'
                                mode={true}
                                // height={348}
                                title={<FormattedMessage id="app.ui.profile.section.analyst.title" />}
                                titleBarColor={'#1880DE'}
                                column={columns1}
                                dataSource={reduxProps.analystInfo}
                                pagination={false}
                            ></EFBoxTable>
                        </Col>
                        <Col span={12}>
                            <EFBoxTable
                                className='ef-table'
                                mode={true}
                                // height={348}
                                title={<FormattedMessage id="app.ui.profile.section.contract.title" />}
                                titleBarColor={'#1880DE'}
                                column={columns2}
                                dataSource={getContractInfo()}
                                pagination={false}
                            ></EFBoxTable>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    document.title = intl.formatMessage({ id: 'app.ui.profile.title' })
    return (
        <EFCContainer
            style={{ padding: '34px 32px' }}
            overspread={0}
        >
            {renderList()}
            {renderTable()}
        </EFCContainer >
    )
}
export default UpdateUserInfo