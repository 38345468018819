import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import ReactEcharts from 'echarts-for-react'
import messages from '../../messages'
const EFCChartOverall = (props) => {  
    // 国际化配置
    const intl = useIntl()
    const title = props.chartTitle
    const desc = props.chartDesc
    // 配置项

    const chartData = props.chartData

    const renderContent = () => {
        let keys = Object.keys(chartData)
        if (keys.length > 0) {
            const option = {
                // 其他配置项...
                grid: {
                    top: '7%',
                    left: '1.5%',
                    right: '1.5%',
                    bottom: '20%',
                    containLabel: true,
                    borderColor: '#EBEDF2',
                    show: false, // 是否显示网格线，默认值为 false
                },
                legend: {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#595C72'
                    },
                    bottom: 0,
                    itemGap: 60,
                    itemWidth: 14,
                    itemheight: 10
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        minValueSpan: 0,
                        top: '82.5%',
                        orient: 'horizontal',
                    }
                ],
                xAxis: {
                    type: 'category',
                    data: chartData.xAxis.map((item, index) => { return item }), // x 轴数据
                    offset: 3,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 1.5,
                            color: '#DBDCE0'
                        }
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            var date = new Date(value)
                            var month = date.getMonth() + 1
                            if (index === 0 || month === 1) {
                                return value
                            } else {
                                return month.toString().padStart(2, '0')
                            }
                        },
                        color: '#595C72',
                        fontSize: 10
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#EBEDF2',
                            width: 1,
                            type: 'solid'
                        }
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: intl.formatMessage(
                            messages['overall_categorization_status.left_y_axis_name']), // 第一个柱形图的名称
                        nameLocation: 'end',
                        offset: 10,
                        nameTextStyle: {
                            padding: [0, 10, 0, -27]
                        },
                        position: 'left',
                        axisLine: {
                            // show: true
                        },
                        splitLine: {
                            // show: true
                        },
                        axisLabel: {
                            show: true,
                            inside: false,
                            formatter: '{value}',
                            fontSize: 10
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#EBEDF2',
                                type: 'dotted',
                                width: 2
                            }
                        },
                    },
                    {
                        // type: 'value',
                        // name: '柱形图2', // 第二个柱形图的名称
                        // min: 0, 
                        // max: 60,
                        // interval: 10
                    },
                    {
                        type: 'value',
                        name: intl.formatMessage(
                            messages['overall_categorization_status.right_y_axis_name']
                          ), // 折线图的名称
                        nameLocation: 'end',
                        // 刻度线与内容区域的偏移
                        offset: 15,
                        // name偏移
                        nameTextStyle: {
                            padding: [0, -10, 0, 18]
                        },
                        position: 'right',
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        axisLabel: {
                            fontSize: 10
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#EBEDF2',
                                type: 'dotted',
                                width: 2
                            }
                        },
                    },
                ],

                series: [
                    {
                        name: 'Categorized',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: chartData.xAxis.map((item, index) => { return chartData.yAxis[item].categorizedCount }), // 第一个柱形图的数据
                        // 设置柱子的颜色
                        itemStyle: {
                            color: "#1880DE"
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: 'Infringement',
                        type: 'bar',
                        data: chartData.xAxis.map((item, index) => { return chartData.yAxis[item].infringementCount }), // 第二个柱形图的数据
                        // 设置柱子的颜色
                        itemStyle: {
                            color: "#A3CCF2"
                        },
                        yAxisIndex: 0,
                        // 控制同一类目下的柱条间距
                        barCategoryGap: '64%',
                        // 控制不同类目下的柱条间距
                        barGap: '0%'
                    },
                    {
                        name: 'Infringement%',
                        type: 'line',
                        yAxisIndex: 2,
                        data: chartData.xAxis.map((item, index) => { return (parseInt(chartData.yAxis[item].infringementCount / chartData.yAxis[item].categorizedCount * 100)) }), // 折线图的数据
                        itemStyle: {
                            color: '#FAAC58'
                        },
                        label: {
                            show: true,
                            position: 'top',
                            distance: 7,
                            formatter: (params) => {
                                const { name, value } = params;
                                return `${value}` + '%';
                            }
                        },
                    },
                ],
            }
            return <ReactEcharts option={option} echarts={echarts} notMerge={true} lazyUpdate={true} style={{ width: '100%', height: '100%' }} onChartReady={chart => {
                // 在图表准备好后回调函数中可以执行一些操作，例如重绘图表
                chart.resize() // 重绘图表
            }}></ReactEcharts>
        } else {
            return <div></div>
        }

    }

    /*
     * 映射规则
     * 第一个层级Region
     *   只需要控制第二层UI的展示
     */
    return (<div
        className='ef-chart-overall'
        style={{
            height: props.height ? props.height + 'px' : '391px',
        }}>
        <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
        <div className="ef-chart-content">
            <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                {renderContent()}
            </div>
        </div>
    </div>
    )
}
export default EFCChartOverall