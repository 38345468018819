import { Button, Form, Row, Col, Input, Table } from 'antd'
import './index.less'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
const EFBoxTable = (props) => {
    const mode = useRef(true)
    const height = useRef()
    const titleBarColor = useRef('#1880DE')
    const title = useRef(props.title)
    const pagination = useRef(false)

    const [Loading, setLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const column = useRef([])
    const dataSource = useRef([])

    useEffect(() => {
        let active = 0
        if (Array.isArray(props.dataSource) && props.dataSource.length > 0) {
            dataSource.current = props.dataSource
            active = 1
        }
        if (Array.isArray(props.column) && props.column.length > 0) {
            column.current = props.column
            active = 1
        }
        if (typeof props.mode === 'boolean' && props.mode !== mode.current) {
            mode.current = props.mode
            active = 1
        }
        if (typeof props.height === 'number' && props.height !== height.current) {
            height.current = props.height
            active = 1
        }
        if (typeof props.titleBarColor === 'string' && props.titleBarColor !== titleBarColor.current) {
            let hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
            let rgbColorRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/
            if (hexColorRegex.test(props.titleBarColor) || rgbColorRegex.test(props.titleBarColor)) {
                titleBarColor.current = props.titleBarColor
                active = 1
            }
        }
        if (typeof props.pagination === 'boolean' && props.pagination !== pagination.current) {
            pagination.current = props.pagination
            active = 1
        }
        if (active === 1) {
            toggleLoading()
        }
    }, [props])

    const renderItem = () => {
        return (
            <Table
                className='ef-table'
                columns={column.current}
                dataSource={dataSource.current}
                size="small"
                pagination={pagination.current}
                scroll={{
                    x: 'max-content',
                }}
            ></Table>
        )
    }

    return (
        <div className='ef-box-table' style={{
            width: mode.current ? '100%' : 'auto',
            height: height.current ? height.current + 'px' : 'auto'
        }}>
            <div className='ef-box-list-title'>
                <span className='box-list-bar' style={{ background: titleBarColor.current ? titleBarColor.current : '#1880DE' }}></span>
                <span className='box-list-title'>{title.current}</span>
            </div>
            <div className='ef-box-section ef-table'>
                {renderItem()}
            </div>
        </div>
    )
}
export default EFBoxTable 