import "./index.less"
import React, { Component } from "react";
import { Drawer, Table, Tooltip, Tag } from "antd";
import { ECCrawlerStatus } from "../common/master-data";
import moment from 'moment';

import { FormattedMessage, injectIntl } from "react-intl"
import common_messages from "../../../locales/messages";

class ECCrawlerSubtasksList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: []
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        return props
    }

    getBasicColumns = (config) => {

        // let { currentSearchClientId, allProductsInfo,
        //     categorysInfo, allTrademarksInfo, currentSelectClientId, } = config;
        const columns = [
            {
                title: '状態',
                dataIndex: 'status',
                key: 'status',
                width: 170,
                ellipsis: true,
                render: (value) => {
                    if (value === ECCrawlerStatus.scraying.value) {
                        return (<Tag color="processing">{ECCrawlerStatus.scraying.text}</Tag>)
                    }

                    if (value === ECCrawlerStatus.error.value) {
                        return (<Tag color="error">{ECCrawlerStatus.error.text}</Tag>)
                    }

                    if (value === ECCrawlerStatus.finished.value) {
                        return (<Tag color="success">{ECCrawlerStatus.finished.text}</Tag>)
                    }

                    if ( value === ECCrawlerStatus.pendding.value){
                        return (<Tag color="success">{ECCrawlerStatus.pendding.text}</Tag>)
                    }
                    return (<Tag color="default">{ECCrawlerStatus.new.text}</Tag>)
                }
            },
            {
                title: 'サイト',
                dataIndex: 'site',
                key: 'site',
                width: 170,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: 'キーワード',
                dataIndex: 'keyword',
                key: 'keyword',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: '開始URL',
                dataIndex: 'startUrl',
                key: 'startUrl',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            {
                title: '取得件数',
                dataIndex: 'recordCount',
                key: 'recordCount',
                width: 200,
                ellipsis: true,
                render: value => {
                    return <Tooltip placement="topLeft" title={value}>
                        {value}
                    </Tooltip>
                }
            },
            // {
            //     title: '備考',
            //     dataIndex: 'remarks',
            //     key: 'remarks',
            //     width: 300,
            //     ellipsis: true,
            //     render: value => {
            //         return <Tooltip placement="topLeft" title={value}>
            //             {value}
            //         </Tooltip>
            //     }
            // },

            // {
            //     title: '動作',
            //     dataIndex: 'actions',
            //     key: 'actions',
            //     width: 120,
            //     fixed: "right",
            //     ellipsis: true,
            //     render: value => {

            //     }
            // },
        ];
        return columns
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }
    render() {
        return (
            <Drawer
                width={800}
                placement="right"
                closable={false}
                onClose={this.onClose}
                open={this.props.visible}
            >
                <Table
                    columns={this.getBasicColumns(this.props.config)}
                    rowKey={record => record.id}
                    dataSource={this.props.task ? this.props.task.subtasks : []}
                    pagination={false}
                >
                </Table>
            </Drawer>
        )
    }
}

export default injectIntl(ECCrawlerSubtasksList);
