import "./index.less"
import React, { useEffect, useState, useCallback, useRef } from "react"
import { Button, Image, Table, Space, Drawer, Modal, Tooltip } from "antd"
import { reqFetchECSites, reqRemoveECSite, } from '@/api/analyst-site'
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'
import { CountryConfig } from "@/config/country.js"

import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { compareString, compareNumber } from "@/utils/compare.js"

import commonMessage from '../../../../locales/messages'
import { FormattedMessage, useIntl } from 'react-intl'

// component reference dependency
import EFCContainer from '../../../../components/ef-c-container'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const AnalystECSiteList = (props) => {

    // 国际化配置
    const intl = useIntl()
    const intlRef = useRef()
    useEffect(() => {
        intlRef.current = intl
    }, [intl])
    const nameIntlKey = useRef()
    nameIntlKey.current = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const fontFamily = useRef()
    fontFamily.current = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

    const [dataSource, setDataSource] = useState([])
    const [categories, setCategories] = useState([])

    const filter = useRef(false)
    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])
    const columns = useRef([])
    const pageSize = useRef(10)

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    const getRegionFilters = () => {
        const regionFilter = []
        const regionDict = {}
        dataSource.every((site) => {
            if (!regionDict[site.region]) {
                regionDict[site.region] = site
                regionFilter.push({ text: site.region, value: site.region })
            }
            return true
        })
        return regionFilter
    }

    const getCategoryFilters = () => {
        const categoryFilter = []
        if (dataSource) {
            const categoryDict = {}
            dataSource.every((site) => {
                if (site.infrigementCategoryId && categories && categories.length > 0) {
                    let result = categories.filter((category) => {
                        return category.id === site.infrigementCategoryId
                    })
                    if (result.length > 0 && !categoryDict[site.infrigementCategoryId]) {
                        categoryDict[site.infrigementCategoryId] = site
                        categoryFilter.push({ text: result[0].name, value: site.infrigementCategoryId })
                    }
                }
                return true
            })
        }
        return categoryFilter
    }

    const onHandlePageSize = (current, size) => {
        pageSize.current = size
        toggleLoading()
    }

    const onRemoveClick = (record) => {
        reqRemoveECSite({ siteId: record.id }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                setDataSource(data.data.sites)
            }
        }).catch((e) => {
        })
    }

    const onDetailClick = (record) => {
        props.history.push({
            pathname: "/analyst/ec-site/detail/" + record.id,
        })
    }

    const onComfirmDelete = (record) => {
        Modal.confirm({
            className: 'ef-modal',
            title: intlRef.current.formatMessage({ id: "app.ui.ec_site.modal.title" }),
            okText: intlRef.current.formatMessage({ id: "app.ui.ec_site.modal.ok" }),
            cancelText: intlRef.current.formatMessage({ id: "app.ui.ec_site.modal.no" }),
            onOk: (close) => {
                onRemoveClick(record)
                close()
            },
            onCancel: (close) => {
                close()
            }
        })
    }

    const onHandleFilterCheck = () => {
    }

    const onHandleFilterClose = () => {
        filter.current = false
        toggleLoading()
    }

    const onHandleFilter = () => {
        filter.current = !filter.current
        toggleLoading()
    }

    const renderFilter = () => {
        return <div>
            {columns.current.map((item, index) => {
                if (item.filters && item.filters.length > 0) {
                    return <div></div>
                } else {
                    return <div></div>
                }
            })}
        </div>
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("analyst-ec-site"))
        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.common.action.add" />,
            path: "/analyst/ec-site/new"
        }]))

        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.register" />,
                path: "/analyst/ec-site",
            },
        ]))
        document.title = intl.formatMessage({ id: "app.breadcrumbs.register" })
    }

    const renderData = () => {
        reqFetchECSites().then((response) => {
            const { data } = response
            if (data.code === 0) {
                setDataSource(data.data.sites)
            }
        }).catch((error) => {
        })

        reqFetchInfringementCategories().then((response) => {
            const { data } = response
            if (data.code === 0) {
                setCategories(data.data.categories)
            }
        }).catch((error) => {
        })
    }

    const renderColumns = () => {
        columns.current = [
            {
                title: <FormattedMessage id="app.ui.ec_site.table.id" />,
                dataIndex: 'id',
                key: 'id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.id, b.id),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.ec_site.table.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => compareString(a.name, b.name),
                showSorterTooltip: false,
                filterSearch: true,
                filters: dataSource.map((item) => {
                    return { text: item.name, value: item.name }
                }),
                onFilter: (value, record) => record.name.includes(value),
            },
            {
                title: <FormattedMessage id="app.ui.ec_site.table.url" />,
                dataIndex: 'url',
                key: 'url',
                sorter: (a, b) => compareString(a.url, b.url),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.ec_site.table.region" />,
                dataIndex: 'region',
                key: 'region',
                render: (text, record) => {
                    if (record.region) {
                        let country = CountryConfig.find(country => country.code === record.region)
                        if (country) {
                            return country.name.ja
                        } else {
                            return ""
                        }
                    }
                    return ""
                },
                sorter: (a, b) => compareString(a.region, b.region),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.region) {
                        return value === record.region
                    } else {
                        return value === 0
                    }
                },
                filters: getRegionFilters(),
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.ui.ec_site.table.infrigementcategoryId" />,
                dataIndex: 'infrigementCategoryId',
                key: 'infringementCategoryId',
                render: (text, record) => {
                    if (record.infrigementCategoryId) {
                        for (let category of categories) {
                            if (category.id === record.infrigementCategoryId) {
                                return category.name
                            }
                        }
                    }
                    return ""
                },
                sorter: (a, b) => compareNumber(a.infrigementCategoryId, b.infrigementCategoryId),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.infrigementCategoryId) {
                        return value === record.infrigementCategoryId
                    } else {
                        return value === 0
                    }
                },
                filters: getCategoryFilters(),
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.ui.ec_site.table.hascrawler" />,
                dataIndex: 'hasCrawler',
                key: 'hasCrawler',
                render: (text, record) => {
                    if (record.hasCrawler === true) {
                        return <FormattedMessage id="app.common.option.yes" />
                    } else {
                        return <FormattedMessage id="app.common.option.no" />
                    }
                },
                sorter: (a, b) => compareNumber(a.hasCrawler, b.hasCrawler),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.hasCrawler) {
                        return value && record.hasCrawler
                    } else {
                        return !value
                    }
                },
                filters: [
                    {
                        text: <FormattedMessage id="app.common.option.yes" />,
                        value: 1,
                    },
                    {
                        text: <FormattedMessage id="app.common.option.no" />,
                        value: 0,
                    },
                ],
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                dataIndex: "action",
                key: "action",
                fixed: "right",
                align: 'center',
                showSorterTooltip: false,
                render: (text, record) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                            <Button
                                className="ef-sidefun"
                                type="link"
                                onClick={() => {
                                    onDetailClick(record)
                                }} icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />}>
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={<FormattedMessage id="app.common.action.delete" />} arrow={true}>
                            <Button
                                className="ef-sidefun"
                                type="link"
                                onClick={() => {
                                    onComfirmDelete(record)
                                }} icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu" />}>
                            </Button>
                        </Tooltip>
                    </Space>
                ),
            }
        ]
    }

    useEffect(() => {
        renderBread()
        renderData()
    }, [])

    useEffect(() => {
        if (dataSource.length > 0 && categories.length > 0) {
            renderColumns()
            toggleLoading()
        }
    }, [dataSource, categories])

    return (
        <EFCContainer
            overspread={0}
            style={{ padding: '32px 32px 0px 32px' }}
            className={fontFamily.current}
        >
            <div className="ef-ecsite-header">
                <div className="ef-ecsite-title">
                    <span className="ef-bar-active ef-ecsite-bar"></span>
                    <p className="ef-title-regular ef-ecsite-title">{<FormattedMessage id="app.ui.ec_site.table.title" />}</p>
                </div>
                <div className="ef-ecsite-funtion">
                    <Tooltip placement="top" title={<FormattedMessage id="app.ui.eclist.tooltip.filter" />} arrow={true}>
                        <Button className="ef-sidefun"
                            onClick={() => { onHandleFilter() }}
                            icon={<IconFont className="iconfont iconfont-wait" type="icon-shaixuan" />}
                        ></Button>
                    </Tooltip>
                </div>
            </div>
            <Table
                className="ef-table"
                columns={columns.current}
                dataSource={dataSource}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current),
                    locale: customLocale,
                    pageSize: pageSize.current,
                    onShowSizeChange: onHandlePageSize
                }}>
            </Table>
            <Drawer
                closable={false}
                title={<p className="ef-title-regular"><FormattedMessage id='app.ui.ec_site.drawer.title' /></p>}
                placement="right"
                onClose={onHandleFilterClose}
                open={filter.current}
                width={784}
                footer={
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button className='ef-button-linear' style={{ display: 'inline-block' }} onClick={onHandleFilterClose}>{<FormattedMessage id="app.components.data_filter.cancel" />}</Button>
                        <Button className='ef-button-color' style={{ marginLeft: '16px', display: 'inline-block' }} onClick={onHandleFilterCheck}>{<FormattedMessage id="app.components.data_filter.confirm" />}</Button>
                    </div>
                }
            >
                {renderFilter()}
            </Drawer>
        </EFCContainer>
    )
}
export default AnalystECSiteList
