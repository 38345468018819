import "./index.less"
import React, { Component } from 'react'
import { Button, Form } from "antd";
import { reqCode } from "@/api/login"
import { FormattedMessage } from "react-intl"

class EFCCodeButton extends Component {

    constructor(props) {
        super(props)
        this.state = {
            countDown: -1
        }
        this.countDownTimer = null
    }

    componentWillUnmount = () => {
        if (this.countDownTimer) {
            clearInterval(this.countDownTimer);
        }
    }

    doCountDown() {
        this.setState(function (state, props) {
            let countDown = state.countDown - 1;
            if (countDown < 0) {
                clearInterval(this.countDownTimer);
                this.countDownTimer = null;
            }
            return {
                countDown: countDown
            };
        });

    }

    getCode = (event) => {
        let mail = this.props.getMail()
        if (!mail) {
            return;
        }

        if (this.countDownTimer) {
            return;
        }
        this.setState({
            countDown: 180
        })
        this.countDownTimer = setInterval(this.doCountDown.bind(this), 1000)

        reqCode(mail)
            .then((response) => {
                let { data } = response;
                if (data.code === 0) {
                } else {
                    this.setState({ countDown: -1 })
                }
            })
            .catch((error) => {
                this.setState({ countDown: -1 })
            });
    }

    render() {
        if (this.props.shouldReset && this.props.shouldReset()) {
            this.setState({ countDown: -1 })
            return (
                <Button type="link" className="linkButton" onClick={this.getCode}>
                    <FormattedMessage id="app.components.ef_code_button.title" />
                </Button>
            );
        }
        if (this.state.countDown <= 0) {
            return (
                <Button type="link" className="linkButton" onClick={this.getCode}>
                    <FormattedMessage id="app.components.ef_code_button.title" />
                </Button>
            );
        } else {
            return (
                <Button type="link" disabled={true}>
                    <FormattedMessage id="app.components.ef_code_button.count_down" values={{ countDown: this.state.countDown }} />
                </Button>
            )
        }
    }
}

export default EFCCodeButton
