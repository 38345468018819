import { Row, Col, message, Pagination } from "antd"
import React, { useState, useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { reqFetchIPFNews } from '@/api/ipf-news.js'
import './index.less'

// 导入 Moment.js
import moment from 'moment'

// component reference dependency
import EFCContainer from '../../components/ef-c-container'

const ECIPFNews = (props) => {

    const intl = useIntl()

    const [news, setNews] = useState([])
    const showNews = useRef([])
    const fetchParams = useRef({ page: 1, pageSize: 1000 })
    const pageSize = useRef(10)
    const [currentPage, setCurrentPage] = useState(1)

    const onChange = (page) => {
        setCurrentPage(page)
    }

    const renderListItem = (item) => {
        let parts = item.title.split('｜')
        let date
        let message
        if (item.publicTime) {
            date = moment(item.publicTime).format('YYYY-MM-DD')
        }
        if (parts[1]) {
            message = parts[1].trim()
        } else {
            message = parts[0].trim()
        }
        return <div className="news-item">
            <a className="ef-title-describe news-item-title"
                target="_blank" rel="noreferrer" href={item.url}>{message}</a>
            <text className="ef-text-normal news-item-text">{date}</text>
        </div>
       
    }

    const renderBread = () => {
        document.title = intl.formatMessage({id:'app.ui.news.title'})
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.news" />,
                path: "/news",
            },
        ]))
    }

    const renderIPFNews = () => {
        reqFetchIPFNews(fetchParams.current).then((response) => {
            const { data } = response
            if (data.code === 0) {
                setNews(data.data.news)
            } else {
                
            }
        }).catch(e => {
            message.error(intl.formatMessage({ id:'app.ui.news.error.fetch_failed' }))
        })
    }

    useEffect(() => {
        renderBread()
        renderIPFNews()
    }, [])

    showNews.current = news.slice((pageSize.current * 2 * (currentPage - 1)), (pageSize.current * 2 * currentPage))

    return <EFCContainer
        overspread={0}
        style={{ padding: '32px 32px 0px 32px' }}>
        <div className="ef-ecsite-header">
            <div className="ef-ecsite-title">
                <span className="ef-bar-active ef-ecsite-bar"></span>
                <p className="ef-title-regular ef-ecsite-title">{<FormattedMessage id="app.ui.news.title" />}</p>
            </div>
        </div>
        <Row gutter={[40, 20]}>
            <Col span={12}>
                <div className="news-left" style={{ width: '100%' }}>
                    {showNews.current.map((item, index) => {
                        if (index % 2 === 0) {
                            return renderListItem(item)
                        }
                    })}
                </div>
            </Col>
            <Col span={12}>
                <div className="news-right" style={{ width: '100%' }}>
                    {showNews.current.map((item, index) => {
                        if (index % 2 === 1) {
                           return renderListItem(item)
                        }
                    })}
                </div>
            </Col>
        </Row>
        <Pagination
            className="news-pagination"
            total={news.length}
            pageSize={pageSize.current * 2}
            current={currentPage}
            onChange={onChange}
            defaultCurrent={10} />
    </EFCContainer>
}
export default ECIPFNews