import request from '@/utils/request'

export function reqCreateProductCategory(data){
    return request({
        url: '/api/analyst/create-product-category',
        method: 'post',
        data: data
    })
}

export function reqRemoveProductCategory(data){
    return request({
        url: '/api/analyst/remove-product-category',
        method: 'post',
        data: data
    })
}

export function reqUpdateProductCategory(data){
    return request({
        url: '/api/analyst/update-product-category',
        method: 'post',
        data: data
    })
}

export function reqFetchProductCategories(data){
    return request({
        url: '/api/analyst/fetch-product-categories',
        method: 'post',
        data: data
    })
}