// antd引入
import {
    Image,
} from 'antd'
import { FormattedMessage } from 'react-intl'
export const warningType = () => {
    return [
        {
            type: 'contentEmpty',
            src: '/images/content-empty.png',
            title: <FormattedMessage id="app.emptycontent.title" />,  
            text: ''
            // <FormattedMessage id="app.emptycontent.text" />
        }, {
            type: 'serverError',
            src: '/images/server-error.png',
            title: <FormattedMessage id="app.serverError.title" />,
            text: <FormattedMessage id="app.serverError.text" />,
        }, {
            type: 'networdError',
            src: '/images/network-error.png',
            title: <FormattedMessage id="app.networdError.title" />,
            text: <FormattedMessage id="app.networdError.text" />
        }, {
            type: 'permissionLimit',
            src: '/images/permit-limit.png',
            title: <FormattedMessage id="app.permissionLimit.title" />,
            text: <FormattedMessage id="app.permissionLimit.text" />
        }
    ]
}

export const emptyDisplay = (src, title, text) => {
    return (
        <div className='ef-empty-content'>
            <Image src={src} preview={false} width={136} height={106}></Image>
            <p className='ef-empty-content-title'>{title}</p>
            <text className='ef-empty-content-text'>{text}</text>
        </div>
    )
}