import "./index.less"
import React, { Component } from "react";
import { Table, Space, Button } from "antd";
import { reqFetchClients } from '../../../api/analyst-client'
import { getCurrencyName } from "@/utils/currency.js"
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment';

class AnalystClientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateSource: [],
            page: 1,
            count: 0,
        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'client_id',
                key: 'client_id',
                width: 80,
                fixed: true,
                sorter: (a, b) => compareNumber(a.client_id, b.client_id),
                showSorterTooltip: false,
            },
            {
                title: '会社名',
                dataIndex: 'client_name',
                key: 'client_name',
                sorter: (a, b) => compareString(a.client_name, b.client_name),
                showSorterTooltip: false,
            },
            {
                title: '連絡先',
                dataIndex: 'client_tel',
                key: 'client_tel',
                sorter: (a, b) => compareString(a.client_tel, b.client_tel),
                showSorterTooltip: false,
            },
            {
                title: '住所',
                dataIndex: 'client_address',
                key: 'client_address',
                sorter: (a, b) => compareString(a.client_address, b.client_address),
                showSorterTooltip: false,
            },
            {
                title: '備考',
                dataIndex: 'client_remarks',
                key: 'client_remarks',
                sorter: (a, b) => compareString(a.client_remarks, b.client_remarks),
                showSorterTooltip: false,
            },
            {
                title: 'EC開始期間',
                dataIndex: 'ec_startDate',
                key: 'ec_startDate',
                render: (text, record) => {
                    if (record.service_ec_start_date) {
                        return moment(record.service_ec_start_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_ec_start_date, b.service_ec_start_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'EC終了期間',
                dataIndex: 'ec_endDate',
                key: 'ec_endDate',
                render: (text, record) => {
                    if (record.service_ec_end_date) {
                        return moment(record.service_ec_end_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_ec_end_date, b.service_ec_end_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'EC金額',
                dataIndex: 'ec_fee',
                key: 'ec_fee',
                render: (text, record) => {
                    if (record.service_ec_fee) {
                        return record.service_ec_fee + getCurrencyName(record.service_ec_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareNumber(a.service_ec_fee, b.service_ec_fee)
                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS開始期間',
                dataIndex: 'sns_startDate',
                key: 'sns_startDate',
                render: (text, record) => {
                    if (record.service_sns_start_date) {
                        return moment(record.service_sns_start_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_sns_start_date, b.service_sns_start_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS終了期間',
                dataIndex: 'sns_endDate',
                key: 'sns_endDate',
                render: (text, record) => {
                    if (record.service_sns_end_date) {
                        return moment(record.service_sns_end_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_sns_end_date, b.service_sns_end_date)

                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS金額',
                dataIndex: 'sns_fee',
                key: 'sns_fee',
                render: (text, record) => {
                    if (record.service_sns_fee) {
                        return record.service_sns_fee + getCurrencyName(record.service_sns_currency)
                    }
                    return ""

                },
                sorter: (a, b) => {
                    return compareNumber(a.service_sns_fee, b.service_sns_fee)
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS開始期間',
                dataIndex: 'ws_startDate',
                key: 'ws_startDate',
                render: (text, record) => {
                    if (record.service_ws_start_date) {
                        return moment(record.service_ws_start_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_ws_start_date, b.service_ws_start_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS終了期間',
                dataIndex: 'ws_endDate',
                key: 'ws_endDate',
                render: (text, record) => {
                    if (record.service_ws_end_date) {
                        return moment(record.service_ws_end_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_ws_end_date, b.service_ws_end_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS金額',
                dataIndex: 'ws_fee',
                key: 'ws_fee',
                render: (text, record) => {
                    if (record.service_ws_fee) {
                        return record.service_ws_fee + getCurrencyName(record.service_ws_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareNumber(a.service_ws_fee, b.service_ws_fee)

                },
                showSorterTooltip: false,
            },
            {
                title: 'Other開始期間',
                dataIndex: 'other_startDate',
                key: 'other_startDate',
                render: (text, record) => {
                    if (record.service_other_start_date) {
                        return moment(record.service_other_start_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_other_start_date, b.service_other_start_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'Other終了期間',
                dataIndex: 'other_endDate',
                key: 'other_endDate',
                render: (text, record) => {
                    if (record.service_other_end_date) {
                        return moment(record.service_other_end_date).format("YYYY-MM-DD")
                    }

                    return ""
                },
                sorter: (a, b) => {
                    return compareDate(a.service_other_end_date, b.service_other_end_date)
                },
                showSorterTooltip: false,
            },
            {
                title: 'Other金額',
                dataIndex: 'other_fee',
                key: 'other_fee',
                render: (text, record) => {
                    if (record.service_other_fee) {
                        return record.service_other_fee + getCurrencyName(record.service_other_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    return compareNumber(a.service_other_fee, b.service_other_fee)
                },
                showSorterTooltip: false,
            },

            {
                title: '動作',
                key: 'action',
                fixed: "right",
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" href={"/manage/client/" + record.client_id}>詳細</Button>
                    </Space>
                ),
            },
        ];

        reqFetchClients().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                // this.setState({originClients:data.client})
                let dateSource = data.data.client.map((client) => {
                    let clientInfo = { key: client.client_id, ...client }
                    return clientInfo;
                })
                this.setState({ dateSource });
            }
        }).catch((error) => {

        })
    }

    componentDidMount() {
        // store.dispatch(setMenuActiveKey("analyst-client-list"));
        // store.dispatch(setContentContainerHeaderAction([]));
        // store.dispatch(setBreadcrumbs([
        //     {
        //         name: "Home",
        //         path: "/",
        //     },
        //     {
        //         name: "担当クラインアント一覧",
        //         path: "/manager/client",
        //     },
        // ]));
    }

    render() {
        // document.title="担当クラインアント一覧"
        return (
            <div className="analyst-client-list-container">
                <div>
                    <Table
                        columns={this.columns}
                        dataSource={this.state.dateSource}
                        scroll={{
                            x: 'max-content'
                        }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}


export default AnalystClientList