import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Select, Tag } from 'antd'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import commonMessage from '../../../../locales/messages'
import messages from '../../messages'
const EFCChartTakedown = (props) => {
    // 国际化配置
    const intl = useIntl()
    let nameIntlKey = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const [selectItems, setSelectItems] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const chartData = props.chartData
    const Item = useRef()

    Item.current = useMemo(() => {
        if (props.selectDesc && props.selectDesc.length > 0) {
            let arr = []
            let str = ''
            props.selectDesc.map((item, index) => {
                arr.push({ value: item.value, label: item.name[nameIntlKey] })
                str += item.vlaue
            })
            setSelectItems(str)
            return arr
        } else {
            setSelectItems('')
        }
    }, [props])

    let filteredOptions
    if (Item.current && Item.current.length > 0) {
        filteredOptions = Item.current.filter((o) => !selectedItems.includes(o))
    }

    const title = props.chartTitle
    const desc = props.chartDesc

    const handleChange = (value) => {
        setSelectedItems(value)
        props.onSelectClick(value)
    }

    const renderSelect = () => {
        return <div className='ef-chart-title-dropdown'>
            <div>
                <p className='ef-title-tip ef-chart-dropdown-describe'>{intl.formatMessage(
                          messages['filter.locale_region_filtering.title']
                        )}</p>
                <Select
                    maxTagCount='responsive'
                    className='ef-chart-dropdown-items'
                    mode="tags"
                    value={selectedItems}
                    onChange={(value) => { handleChange(value) }}
                    style={{
                        width: 240,
                    }}
                    options={(filteredOptions && filteredOptions.length > 0) ? filteredOptions.map((item) => ({
                        value: item.value,
                        label: item.label,
                    })) : []}
                >
                </Select>
            </div>
        </div>
    }

    useEffect(() => {
        if (props.selectDesc && props.selectDesc.length > 0) {
            handleChange(props.selectDesc.map((item, index) => {
                return item.value
            }))
        } else {
            handleChange([])
        }
    }, [selectItems])

    const renderContent = () => {
        if (chartData && chartData.length > 0) {
            var data = [[]]
            chartData.map((item, index) => {
                let obj = {}
                obj['name'] = item.name
                obj['value'] = item.value
                data[0].push(item)
            })
            var option = {
                color: [
                    "#1880DE", '#2F8CE1', '#4699E5', '#5DA6E7', '#74B3EB', '#8BBFEE', '#A3CCF2', '#B9D8F5', '#D1E6F8', '#E7F2FB'
                ],
                title: {
                    left: 'center',
                    textStyle: {
                        color: '#999',
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item',
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    extraCssText: 'text-align: center;',
                    formatter: function (params) {
                        const data = params
                        return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
                                <div style="font-weight: normal;font-size: 16px;color:#000000;font-family:'Bebas';letter-spacing:1px">${data['value']}(${data['percent']}%)</div>`
                    },
                },
                series: data.map(function (data, idx) {
                    var top = idx * 33.3
                    return {
                        name: 'No Action',
                        type: 'pie',
                        radius: [38, 72],
                        top: top + '%',
                        height: '100%',
                        left: 'center',
                        width: '64%',
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            // alignTo: 'edge',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            }
                        },
                        labelLine: {
                            length: 20,
                            length2: 25,
                            maxSurfaceAngle: 80
                        },
                        data: data
                    }
                })
            }
        } else {
            return <div></div>
        }
        return <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }}></ReactEcharts>
    }

    return (<div
        className='ef-chart-rank'
        style={{
            height: props.height ? props.height + 'px' : '391px',
        }}>
        <div className="ef-title-regular ef-chart-title">
            <p className='ef-chart-title-text'>{title && <FormattedMessage id={title} />}</p>
            <div className='ef-chart-title-right'>
                {renderSelect()}
            </div>
        </div>
        <div className="ef-chart-content">
            <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                {renderContent()}
            </div>
        </div>
    </div>)
}
export default EFCChartTakedown