// ec-list util 

export const isErrorRecord = (record) => {
    if (record.ec_process1_result && !record.ec_process1_date) {
        return true;
    }

    if (!record.ec_process1_result && record.ec_process1_date) {
        return true;
    }

    if (record.ec_process2_result && !record.ec_process2_date) {
        return true;
    }

    if (!record.ec_process2_result && record.ec_process2_date) {
        return true;
    }

    if (record.ec_process2_result && record.ec_process2_date && !record.ec_process1_result && !record.ec_process1_date) {
        return true;
    }

    if (record.ec_process3_result && !record.ec_process3_date) {
        return true;
    }

    if (!record.ec_process3_result && record.ec_process3_date) {
        return true;
    }

    if (record.ec_process3_result && record.ec_process3_date && !record.ec_process2_result && !record.ec_process2_date) {
        return true;
    }

    if (record.ec_process_result && !record.ec_process_confirm_date) {
        return true;
    }

    if (!record.ec_process_result && record.ec_process_confirm_date) {
        return true;
    }

    if (record.ec_process_result && record.ec_process_confirm_date &&
        !record.ec_process3_result && !record.ec_process3_date &&
        !record.ec_process2_result && !record.ec_process2_date &&
        !record.ec_process1_result && !record.ec_process1_date) {
        return true;
    }
    return false;
}

