import { Table, Form, Select, Input, Space, Button, message } from "antd"
import { ModalForm } from '@ant-design/pro-form'
import { PlusOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useCallback } from "react"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import commonMessage from '../../locales/messages'
import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages"
import store from "@/store"
import './index.less'
import EFCMasterTable from "@/components/ef-c-master-table"
import { useRef } from "react"
import { ProFormText, ProFormSelect, } from '@ant-design/pro-form'
import { reqFetchECSites } from '../../api/analyst-site'
import { reqFetchClients } from '@/api/analyst-client'
import { reqFetchTestPurchase, reqAddTestPurchase, reqRemoveTestPurchase, reqUpdateTestPurchase } from '@/api/test-purchase'

// component reference dependency
import EFCContainer from '../../components/ef-c-container'

import { CountryConfig } from "@/config/country.js"

const TestPurchase = () => {

    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setLoading(preState => !preState)
    }, [])

    // 国际化配置
    const intl = useIntl()
    const nameIntlKey = useRef()
    nameIntlKey.current = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const fontFamily = useRef()
    fontFamily.current = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    const formRef = useRef()
    const modalFormRef = useRef()
    const formField = useRef([])
    const sites = useRef([])
    const clients = useRef([])
    const items = useRef({})
    const pageSize = useRef(10)

    const onSave = (record, values) => {
        let client = values["client_" + record.id]
        let region = values["region_" + record.id]
        let site = values["site_" + record.id]
        let count = parseInt(values["count_" + record.id])
        reqUpdateTestPurchase({
            id: record.id, client, region, site, count
        }).then(response => {
            renderDataSource()
            items.current[record.id] = false
            message.success(intl.formatMessage(
                messages['message.info.update_client_success']
            ))
        }).catch(e => {
            message.error(intl.formatMessage(
                messages['message.error.update_client_failed']
            ))
        })
    }

    const onHandlePageSize = (current, size) => {
        pageSize.current = size
        toggleLoading()
    }

    const onHandleCreateAccount = (values) => {
        let { client, region, site, count } = values
        count = parseInt(count)
        reqAddTestPurchase({
            client, region, site, count
        }).then(response => {
            renderDataSource()
            message.success(intl.formatMessage(
                messages['message.info.create_client_success']
            ))
        }).catch(e => {
            message.error(intl.formatMessage(
                messages['message.error.create_client_failed']
            ))
            toggleLoading()
        })
    }

    const onHandleSave = (record) => {
        formRef.current.validateFields().then((values) => {
            onSave(record, values)
        }).catch(errorInfo => { message.error(errorInfo.errorFields[0].errors[0]) })
    }

    const onHandleRemove = (record) => {
        reqRemoveTestPurchase({ id: record.id }).then((response) => {
            renderDataSource()
        }).catch(e => {

        })
    }

    const onHandleCancel = (record) => {
        items.current[record.id] = false
        toggleLoading()
    }

    const onHandleEdit = (record) => {
        let obj = {}
        formField.current.map((item, index) => {
            if (record[item] !== undefined) {
                obj[item + '_' + record.id] = record[item]
            }
        })
        if (formRef.current) {
            formRef.current.setFieldsValue(obj)
        }
        items.current[record.id] = true
        toggleLoading()
    }

    const renderModalItems = () => {
        return (
            <>
                <ProFormSelect
                    className="ef-form-element-normal"
                    showSearch
                    options={
                        clients.current.map((client) => {
                            return {
                                value: client.client_id,
                                label: client.client_name,
                                key: client.client_id
                            }
                        })
                    }
                    name="client"
                    label={<FormattedMessage id="app.ui.test_purchase.table.client" />}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.client_empty']
                            )
                        },
                    ]}
                />
                <ProFormSelect
                    className="ef-form-element-normal"
                    options={
                        CountryConfig.map((country) => {
                            return {
                                key: `${country.code}${country.name.ja}${country.name.en}`,
                                value: country.code,
                                label: country.name.ja
                            }
                        })
                    }
                    name="region"
                    label={<FormattedMessage id="app.ui.test_purchase.table.region" />}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.region_empty']
                            )
                        },
                    ]}
                />

                <ProFormSelect
                    className="ef-form-element-normal"
                    showSearch
                    options={
                        sites.current.map((site) => {
                            return {
                                value: site.name,
                                label: site.name,
                                key: site.name
                            }
                        })
                    }
                    name="site"
                    label={<FormattedMessage id="app.ui.test_purchase.table.site" />}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.site_empty']
                            )
                        },
                    ]}
                />
                <ProFormText
                    className="ef-form-element-normal"
                    name="count"
                    label={<FormattedMessage id="app.ui.test_purchase.table.count" />}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.count_empty']
                            )
                        },
                    ]} />
            </>
        )
    }

    const renderColumns = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.test_purchase.table.id" />,
                dataIndex: 'id',
                key: 'id',
                fixed: true,
                width: 100,
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.test_purchase.table.client" />,
                dataIndex: 'client',
                key: 'client',
                width: 280,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"client_" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.client_empty']
                                        )
                                    }
                                ]}>
                                <Select className="ef-form-element-normal test-purchase-form-item">
                                    {
                                        clients.current.map(client => {
                                            return (
                                                <Select.Option value={client.client_id} key={client.client_id}>
                                                    {client.client_name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </ Form.Item >
                        )
                    } else {
                        if (record.client) {
                            let client = clients.current.find(client => client.client_id === record.client)
                            if (client) {
                                return client.client_name
                            } else {
                                return ""
                            }
                        }
                        return ""
                    }
                },
                showSorterTooltip: false,
                filterSearch: true,
            },
            {
                title: <FormattedMessage id="app.ui.test_purchase.table.region" />,
                dataIndex: 'region',
                key: 'region',
                width: 280,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"region_" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.region_empty']
                                        )
                                    }
                                ]}>
                                <Select
                                    className="ef-form-element-normal test-purchase-form-item"
                                    allowClear style={{ width: 240 }}
                                    showSearch
                                    filterOption={(inputValue, option) => {
                                        return option.key.indexOf(inputValue) > -1 ? true : false
                                    }}>
                                    {CountryConfig.map((country) => {
                                        let value
                                        if (nameIntlKey.current === 'ja') {
                                            value = country.name.ja
                                        } else if (nameIntlKey.current === 'en') {
                                            value = country.name.en
                                        }
                                        return <Select.Option key={`${country.code}${country.name.ja}${country.name.en}`} value={country.code}>{value}</Select.Option>
                                    })}
                                </Select>
                            </ Form.Item >
                        )
                    } else {
                        if (record.region) {
                            let country = CountryConfig.find(country => country.code === record.region)
                            if (country) {
                                if (nameIntlKey.current === 'ja') {
                                    return country.name.ja
                                } else if (nameIntlKey.current === 'en') {
                                    return country.name.en
                                }
                            } else {
                                return ""
                            }
                        }
                        return ""
                    }
                },
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.region) {
                        return value === record.region
                    } else {
                        return value === 0
                    }
                },
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.ui.test_purchase.table.site" />,
                dataIndex: 'site',
                key: 'site',
                width: 280,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"site_" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.site_empty']
                                        )
                                    }
                                ]}>
                                <Select className="ef-form-element-normal test-purchase-form-item">
                                    {
                                        sites.current.map(site => {
                                            return (
                                                <Select.Option value={site.name} key={site.name}>
                                                    {site.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>

                            </ Form.Item >
                        )
                    } else {
                        if (record.site) {
                            let site = sites.current.find(site => site.name === record.site)
                            if (site) {
                                return site.name
                            } else {
                                return ""
                            }
                        }
                        return ""
                    }
                },
                showSorterTooltip: false,
                filterSearch: true,
            },
            {
                title: <FormattedMessage id="app.ui.test_purchase.table.count" />,
                dataIndex: 'count',
                key: 'count',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"count_" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.count_empty']
                                        )
                                    }
                                ]}>
                                <Input className="ef-form-element-normal test-purchase-form-item"></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                },
                showSorterTooltip: false,
            }, {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleSave(record)} >{<FormattedMessage id="app.common.action.save" />}</Button>
                                <Button type="link" onClick={() => onHandleRemove(record)}>{<FormattedMessage id="app.common.action.delete" />}</Button>
                                <Button type="link" onClick={() => onHandleCancel(record)}>{<FormattedMessage id="app.common.action.cancel" />}</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleEdit(record)} >{<FormattedMessage id="app.common.action.edit" />}</Button>
                            </Space>
                        )
                    }
                },
            }
        ]
        columns.push({
            title: (
                <ModalForm
                    className="ef-modalform-single-col test-purchase-modal"
                    formRef={modalFormRef}
                    title={<div className="ef-text-bold"><FormattedMessage id="app.ui.test_purchase.modal.site_add" /></div>}
                    trigger={
                        <Button icon={<PlusOutlined />}></Button>
                    }
                    modalProps={{
                        onCancel: () => {
                            modalFormRef.current?.resetFields()
                        },
                    }}
                    onFinish={(values) => {
                        onHandleCreateAccount(values)
                        return true
                    }}
                    submitter={{
                        searchConfig: {
                            resetText: <FormattedMessage id="app.components.modal.reset" />,
                            submitText: <FormattedMessage id="app.components.modal.submit" />,
                        },
                    }}
                >
                    {renderModalItems()}
                </ModalForm >
            ),
            key: 'new',
            width: '100px',
        })
        formField.current = columns.map((item, index) => {
            return item.key
        })
        return columns
    }

    const renderDataSource = async () => {
        await reqFetchTestPurchase().then(response => {
            const { data } = response
            if (data.code === 0) {
                setDataSource(data.data.testPurchase)
                data.data.testPurchase.map((item, index) => {
                    items.current[item.id] = false
                })
            }
        }).catch(e => {

        })
    }

    const renderBread = () => {
        document.title = intl.formatMessage({ id: 'app.ui.test_purchase.title' })
        // store.dispatch(setMenuActiveKey("analyst-ec-list"));
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.test_purchase" />,
                path: "/test-purchase",
            },
        ]))
    }

    const renderInitParams = async () => {
        await renderDataSource()
        await reqFetchECSites().then(response => {
            const { data } = response
            if (data.code === 0) {
                sites.current = data.data.sites
            }
        }).catch(e => {

        })
        await reqFetchClients().then((response) => {
            const { data } = response
            if (data.code === 0) {
                clients.current = data.data.client
            }
        }).catch((error) => {

        })
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderInitParams()
    }, [])

    return <EFCContainer
        overspread={0}
        style={{ padding: '32px 32px 0px 32px' }}
        className={fontFamily.current}>
        <div className="test-purchase-header">
            <div className="test-purchase-title">
                <span className="ef-bar-active test-purchase-bar"></span>
                <p className="ef-title-regular test-purchase-title">{<FormattedMessage id="app.ui.test_purchase.title" />}</p>
            </div>
        </div>
        <Form
            ref={formRef}
            component={false}>
            <Table
                className="ef-table"
                columns={renderColumns()}
                dataSource={dataSource}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current),
                    locale: customLocale,
                    pageSize: pageSize.current,
                    onShowSizeChange: onHandlePageSize
                }}>
            </Table>
        </Form>
    </EFCContainer>
}
export default TestPurchase