import './index.less'
import React, { useEffect } from 'react'
import { Spin, Space } from 'antd'
const loading = (props) => {
  return (
    <div
      className="iframe-section"
      style={{
        width: props.width,
        height: props.height,
      }}>
      <Space
        direction="vertical"
        className="Loading"
        style={{
          opacity: props.isLoading ? '1' : '0',
          zIndex: props.isLoading ? '1' : '0',
        }}>
        <div className="example">
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </div>
      </Space>
      <div
        className="Loaded"
        style={{
          opacity: props.isLoading ? '0' : '1',
          zIndex: props.isLoading ? '0' : '1',
        }}>
        {props.children}
      </div>
    </div>
  )
}
export default loading
