import * as types from "../action-types";
import { reqUserInfo } from "@/api/user";
import { resetUser } from "./auth"
import { changeLocale } from "./setting";

export const getUserInfo = (userid) => (dispatch) => {
	return new Promise((resolve, reject) => {
		reqUserInfo(userid)
			.then((response) => {
				const { data } = response;
				if (data.code === 0) {
					const userInfo = data.data.user;
					dispatch(setUserInfo(userInfo));
					resolve(data);
				} else {
					const msg = data.message;
					reject(msg);
				}
			})
			.catch((error) => {
				dispatch(resetUser())
				reject(error);
			});
	});
};

export const setUserInfo = (userInfo) => {
	return {
		type: types.USER_SET_USER_INFO,
		...userInfo,
	};
};

