
export const getKeywordSearchItems = () => {
    return [
        {
            name: "ID",
            intlKey:"app.ui.eclist.table.id",
            value: "id"
        },
        {
            name: "No.",
            intlKey:"app.ui.eclist.table.ec_no",            
            value: "no"
        },
        {
            name: "Query",
            intlKey:"app.ui.eclist.table.ec_search_keyword",
            value: "searchKeyword"
        },
        {
            name: "Search engine",
            intlKey:"app.ui.wslist.table.search_engine",
            value: "searchEngine"
        },
        {
            name: "Domain",
            intlKey:"app.ui.wslist.table.page_domain",
            value: "pageDomain"
        },
        {
            name: "URL",
            intlKey:"app.ui.wslist.table.page_url",
            value: "pageURL"
        },
        {
            name: "Country/Area",
            intlKey:"app.ui.wslist.table.domain_region",
            value: "domainRegion"
        },
        {
            name: "Contact",
            intlKey:"app.ui.wslist.table.domain_contact",
            value: "domainContact"
        },
        {
            name: "Redirect",
            intlKey:"app.ui.wslist.table.redirect_url",
            value: "redirectURL"
        },
        {
            name: "Whois",
            intlKey:"app.ui.wslist.table.whois",
            value: "whois"
        },
        {
            name: "IPF Comment",
            intlKey:"app.ui.eclist.table.ec_categorize_remarks",
            value: "remarks"
        },
        {
            name: "Client's Comment",
            intlKey:"app.ui.eclist.table.ec_response_reason",
            value: "responseRemarks"
        },
        {
            name: "Remarks",
            intlKey:"app.ui.eclist.table.ec_process_remarks",
            value: "processRemarks"
        },
    ]
}


export const getDateSearchItems = () => {
    return [
        {
            name: "Scan Date",
            intlKey:"app.ui.eclist.table.ec_scan_date",
            value: "scanDate"
        },
        {
            name: "Domain Created",
            intlKey:"app.ui.wslist.table.domain_created_at",
            value: "domainCreatedAt"
        },
        {
            name: "Domain Expire",
            intlKey:"app.ui.wslist.table.domain_expire_at",
            value: "domainExpireAt"
        },
        {
            name: "Domain Updated",
            intlKey:"app.ui.wslist.table.domain_updated_at",
            value: "domainUpdatedAt"
        },
        {
            name: "Report Date",
            intlKey:"app.ui.eclist.table.ec_categorize_date",
            value: "categorizeDate"
        },
        {
            name: "Response date",
            intlKey:"app.ui.eclist.table.ec_response_date",
            value: "responseDate"
        },
        {
            name: "Report Date(1st)",
            intlKey:"app.ui.eclist.table.ec_process1_date",
            value: "process1Date"
        },
        {
            name: "Confirmation Date(1st)",
            intlKey:"app.ui.eclist.table.ec_process1_confirm_date",
            value: "process1ConfirmDate"
        },
        {
            name: "Report Date(2nd)",
            intlKey:"app.ui.eclist.table.ec_process2_date",
            value: "process2Date"
        },
        {
            name: "Confirmation Date(2nd)",
            intlKey:"app.ui.eclist.table.ec_process2_confirm_date",
            value: "process2ConfirmDate"
        },
        {
            name: "Report Date(3rd)",
            intlKey:"app.ui.eclist.table.ec_process3_date",
            value: "process3Date"
        },
        {
            name: "Confirmation Date(3rd)",
            intlKey:"app.ui.eclist.table.ec_process3_confirm_date",
            value: "process3ConfirmDate"
        },
        {
            name: "Confirmation Date(Final)",
            intlKey:"app.ui.eclist.table.ec_process_confirm_date",
            value: "processConfirmDate"
        },
       
    ]
}
