import "./index.less"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Space, Button, message, Image, Drawer, Collapse, Tooltip } from "antd"
import { reqSearchClients, reqRemoveClient } from '../../../api/admin-client'
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { getCurrencyName } from "@/utils/currency.js"
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import { deepCopy } from '@/utils/deepClone'
import moment from 'moment'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFCSearchBar from '../../../components/ef-c-search-condition'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Panel } = Collapse
const ClientList = () => {

    const intl = useIntl()
    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }

    const date = useRef([])
    const search = useRef([])
    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])
    const columns = useRef([])

    const [dataSource, setDataSource] = useState([])
    const dataSourceLocal = useRef([])
    const analystFilters = useRef([])
    const conditions = useRef({})

    const total = useRef(0)
    const pageCurrent = useRef(1)
    const pageSize = useRef(10)

    const filter = useRef(false)
    const filterItems = useRef([])
    const preFilterItems = useRef([])
    const filterColumns = useRef([])

    const [collapse, setcollapse] = useState(false)

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()
    const locationSearch = useRef()
    useEffect(() => {
        locationSearch.current = location.search
    }, [location])

    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    const filterLocal = (dataSource) => {
        let filter = []
        filterItems.current.map((item, index) => {
            let array = []
            let active = false
            item.map((current, index1) => {
                if (current === true) {
                    array.push(filterColumns.current[index].items[index1].value)
                    active = true
                }
            })
            if (active) {
                filter.push({ key: filterColumns.current[index].key, values: array })
            }
        })
        let array = dataSource
        if (Array.isArray(array)) {
            filter.map((item, index) => {
                if (item.key === 'client_analyst') {
                    array = array.filter((element) => item.values.some((value) => {
                        if (element['analystInfo']) {
                            let id = element['analystInfo'].map(user => { return user.user_id })
                            if (id) {
                                return id.includes(value)
                            } else {
                                return false
                            }
                        } else {
                            if (value === 0) {
                                return true
                            } else {
                                return false
                            }
                        }
                    }))
                }
            })
            return array
        } else {
            return []
        }
    }

    const onHandleFliterOpen = () => {
        filter.current = true
        toggleLoading()
    }

    const onHandleFilterClose = () => {
        filterItems.current = deepCopy(preFilterItems.current)
        preFilterItems.current = []
        filter.current = false
        toggleLoading()
    }

    const onHandleFilterCheck = () => {
        preFilterItems.current = []
        filter.current = false
        dataSourceLocal.current = filterLocal(dataSource)
        toggleLoading()
    }

    const onHandleDelete = (record) => {
        reqRemoveClient({
            clientId: record.client_id
        }).then((response) => {
            renderTableData(conditions.current)
        }).catch((e) => {
            message.error(intl.formatMessage(
                messages['message.error.delete_failed']
            ))
        })
    }

    const onHandleFilterClick = (index1, index2) => {
        filterItems.current[index1][index2] = !filterItems.current[index1][index2]
        toggleLoading()
    }

    const onUrlRender = () => {
        const searchParams = new URLSearchParams(location.search)
        const params = {}
        for (const [key, value] of searchParams.entries()) {
            params[key] = JSON.parse(decodeURIComponent(value))
        }
        return params
    }

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(locationSearch.current)
        history.push(searchParams)
    }

    const onHandleSearch = () => {
        const searchParams = new URLSearchParams(location.search)
        const params = {}
        for (const [key, value] of searchParams.entries()) {
            params[key] = JSON.parse(decodeURIComponent(value))
        }
        renderTableData(params)
    }

    const onHandlePageChange = (page, pagesize) => {
        pageCurrent.current = page
        pageSize.current = pagesize
        toggleLoading()
    }

    const onHandleChange = (value) => {
        let searchParams = new URLSearchParams(locationSearch.current)
        Object.keys(value).map((item, index) => {
            if (!value[item] || (Array.isArray(value[item]) && value[item].length === 0)) {
                searchParams.delete(item)
            } else {
                searchParams.set(item, encodeURIComponent(JSON.stringify(value[item])))
            }
        })
        history.push({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`
        })
    }

    const renderFilterSection = () => {
        return filterColumns.current.map((item, index1) => {
            return <div className="ef-filter-flat">
                <p className="ef-filter-title ef-title-describe">{item.title}</p>
                <div className="ef-filter-section-one">
                    {item.items.map((params, index2) => {
                        return <div className={`ef-filter-item ef-text-normal ${filterItems.current[index1][index2] ? 'ef-filter-item-active' : ''}`}
                            onClick={() => { onHandleFilterClick(index1, index2) }}>{params.name}</div>
                    })}
                </div>
            </div>
        })
    }

    const renderDrawerFliter = () => {
        if (preFilterItems.current.length === 0) {
            preFilterItems.current = deepCopy(filterItems.current)
        }
        return <Drawer
            closable={false}
            title={<p className="ef-title-regular"><FormattedMessage id='app.ui.ec_site.drawer.title' /></p>}
            placement="right"
            onClose={onHandleFilterClose}
            open={filter.current}
            width={784}
            footer={
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button className='ef-button-linear' style={{ display: 'inline-block' }} onClick={onHandleFilterClose}>{<FormattedMessage id="app.components.data_filter.cancel" />}</Button>
                    <Button className='ef-button-color' style={{ marginLeft: '16px', display: 'inline-block' }} onClick={onHandleFilterCheck}>{<FormattedMessage id="app.components.data_filter.confirm" />}</Button>
                </div>
            }
        >
            {renderFilterSection()}
        </Drawer>
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-client-list"))
        if (reduxProps.role & 4) {
            store.dispatch(setContentContainerHeaderAction([{
                name: <FormattedMessage id="app.common.action.add" />,
                path: "/manage/client/new",
            }]))
        } else {
            store.dispatch(setContentContainerHeaderAction([]))
        }
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.client_list" />,
                path: "/manage/client",
            },
        ]))
    }

    const renderTableData = async (condition = {}) => {
        conditions.current = condition
        await reqSearchClients({ ...condition }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let analystIds = {}
                let analystFilter = []
                let dateSource = data.data.client.map((client) => {
                    let clientInfo = { key: client.client_id, ...client }
                    if (clientInfo.analystInfo) {
                        clientInfo.analystInfo.every((analyst) => {
                            if (!analystIds[analyst.user_id]) {
                                analystIds[analyst.user_id] = analyst
                                analystFilter.push({ text: analyst.user_name, value: analyst.user_id })
                            }
                            return true
                        })
                    }
                    return clientInfo
                })
                analystFilter.push({ text: "空白", value: 0 })
                analystFilters.current = analystFilter
                dataSourceLocal.current = dateSource
                setDataSource(dateSource)
            }
        }).catch((error) => {
            message.error(intl.formatMessage(
                messages['message.error.fetch_failed']
            ))
        })
        columns.current = [
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_id' />,
                dataIndex: 'client_id',
                key: 'client_id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.client_id, b.client_id),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_name' />,
                dataIndex: 'client_name',
                key: 'client_name',
                sorter: (a, b) => compareString(a.client_name, b.client_name),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_tel' />,
                dataIndex: 'client_tel',
                key: 'client_tel',
                sorter: (a, b) => compareString(a.client_tel, b.client_tel),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_address' />,
                dataIndex: 'client_address',
                key: 'client_address',
                sorter: (a, b) => compareString(a.client_address, b.client_address),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_analyst' />,
                dataIndex: 'client_analyst',
                key: 'client_analyst',
                render: (text, record) => {
                    if (record.analystInfo) {
                        let analysts = record.analystInfo.map((analyst) => {
                            return analyst.user_name
                        })
                        return analysts.join("、")
                    }
                    return ""
                },
                onFilter: (value, record) => {
                    if (record.analystInfo) {
                        return record.analystInfo.some((analyst) => {
                            return analyst.user_id === value
                        })
                    } else {
                        return value === 0
                    }
                },
                filters: [...analystFilters.current],
                filterMultiple: true,
                sorter: (a, b) => {
                    if (a.analystInfo) {
                        let a_analysts = a.analystInfo.map((analyst) => {
                            return analyst.user_name
                        })
                        let a_analysts_string = a_analysts.join("、")
                        if (b.analystInfo) {
                            let b_analysts = b.analystInfo.map((analyst) => {
                                return analyst.user_name
                            })
                            let b_analysts_string = b_analysts.join("、")
                            return compareString(a_analysts_string, b_analysts_string)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.analystInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_remarks' />,
                dataIndex: 'client_remarks',
                key: 'client_remarks',
                width: 400,
                sorter: (a, b) => compareString(a.client_remarks, b.client_remarks),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ec_startDate' />,
                dataIndex: 'service_ec_start_date',
                key: 'service_ec_start_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_ec_start_date) {
                            return moment(record.service_ec_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_ec_start_date, b.service_ec_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }

                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ec_endDate' />,
                dataIndex: 'service_ec_end_date',
                key: 'service_ec_end_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_ec_end_date) {
                            return moment(record.service_ec_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_ec_end_date, b.service_ec_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ec_fee' />,
                dataIndex: 'service_ec_fee',
                key: 'service_ec_fee',
                render: (text, record) => {
                    if (record && record.service_ec_fee) {
                        return record.service_ec_fee + getCurrencyName(record.service_ec_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareNumber(a.service_ec_fee, b.service_ec_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.sns_startDate' />,
                dataIndex: 'service_sns_start_date',
                key: 'service_sns_start_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_sns_start_date) {
                            return moment(record.service_sns_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_sns_start_date, b.service_sns_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.sns_endDate' />,
                dataIndex: 'service_sns_end_date',
                key: 'service_sns_end_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_sns_end_date) {
                            return moment(record.service_sns_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_sns_end_date, b.service_sns_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.sns_fee' />,
                dataIndex: 'service_sns_fee',
                key: 'service_sns_fee',
                render: (text, record) => {
                    if (record && record.service_sns_fee) {
                        return record.service_sns_fee + getCurrencyName(record.service_sns_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareNumber(a.service_sns_fee, b.service_sns_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ws_startDate' />,
                dataIndex: 'service_ws_start_date',
                key: 'service_ws_start_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_ws_start_date) {
                            return moment(record.service_ws_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_ws_start_date, b.service_ws_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ws_endDate' />,
                dataIndex: 'service_ws_end_date',
                key: 'service_ws_end_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_ws_end_date) {
                            return moment(record.service_ws_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_ws_end_date, b.service_ws_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.ws_fee' />,
                dataIndex: 'service_ws_fee',
                key: 'service_ws_fee',
                render: (text, record) => {
                    if (record && record.service_ws_fee) {
                        return record.service_ws_fee + getCurrencyName(record.service_ws_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareNumber(a.service_ws_fee, b.service_ws_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.other_startDate' />,
                dataIndex: 'service_other_start_date',
                key: 'service_other_start_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_other_start_date) {
                            return moment(record.service_other_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_other_start_date, b.service_other_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.other_endDate' />,
                dataIndex: 'service_other_end_date',
                key: 'service_other_end_date',
                render: (text, record) => {
                    if (record) {
                        if (record.service_other_end_date) {
                            return moment(record.service_other_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareDate(a.service_other_end_date, b.service_other_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.ui.brand_reg.other_fee' />,
                dataIndex: 'service_other_fee',
                key: 'service_other_fee',
                render: (text, record) => {
                    if (record && record.service_other_fee) {
                        return record.service_other_fee + getCurrencyName(record.service_other_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a) {
                        if (b) {
                            return compareNumber(a.service_other_fee, b.service_other_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id='app.common.action' />,
                key: 'action',
                align: 'center',
                width: 120,
                render: (text, record) => {
                    if (reduxProps.role & 4) {
                        return (
                            <Space size="middle">
                                <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                                    <Button className="ef-sidefun" type="link" icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />} href={"/manage/client/" + record.client_id}></Button>
                                </Tooltip>
                                <Tooltip placement="top" title={<FormattedMessage id="app.common.action.delete" />} arrow={true}>
                                    <Button className="ef-sidefun" type="link" icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu" />} onClick={() => { onHandleDelete(record) }} client={record.client_id}></Button>
                                </Tooltip>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="middle">
                                <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                                    <Button type="link" className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu" />} href={"/manage/client/" + record.client_id}></Button>
                                </Tooltip>
                            </Space>
                        )
                    }
                },
                fixed: "right",
            },
        ]
        filterColumns.current = [
            {
                title: <FormattedMessage id='app.ui.brand_reg.client_analyst' />,
                key: 'client_analyst',
                items: analystFilters.current.map((item) => {
                    return {
                        name: item.text,
                        value: item.value
                    }
                })
            }
        ]
        filterItems.current = filterColumns.current.map((item, index) => {
            let array = []
            item.items.map((item, index) => {
                array[index] = false
            })
            return array
        })
        preFilterItems.current = []
        toggleLoading()
    }

    const initStaticParams = () => {
        date.current = [
            {
                name: <FormattedMessage id="app.ui.brand_reg.ec_startDate" />,
                value: "service_ec_start_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.ec_endDate" />,
                value: "service_ec_end_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.sns_startDate" />,
                value: "service_sns_start_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.sns_endDate" />,
                value: "service_sns_end_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.ws_startDate" />,
                value: "service_ws_start_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.ws_endDate" />,
                value: "service_ws_end_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.other_startDate" />,
                value: "service_other_start_date"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.other_endDate" />,
                value: "service_other_end_date"
            },
        ]
        search.current = [
            {
                name: <FormattedMessage id="app.ui.brand_reg.company_name" />,
                value: "client_name"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.address" />,
                value: "client_address"
            },
            {
                name: <FormattedMessage id="app.ui.brand_reg.remarks" />,
                value: "client_remarks"
            },
        ]
    }

    useEffect(() => {
        initStaticParams()
        renderBread()
        renderTableData()
    }, [])
    document.title = intl.formatMessage({ id: 'app.ui.brand_reg.title' })
    return <div className="client-list-container ef-container-father">
        <EFCContainer>
            <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                <Panel header={<FormattedMessage id="app.collapse.title" />} key="1">
                    <EFCSearchBar
                        date={date.current}
                        search={search.current}
                        locale={searchBarDescribe.current}
                        showItem={['date', 'search']}
                        onUrlRender={onUrlRender}
                        onHandleChange={(value) => { onHandleChange(value) }}
                        onHandleReset={onHandleReset}
                        onHandleSearch={(value) => { onHandleSearch(value) }}
                        height={140}
                    ></EFCSearchBar>
                </Panel>
            </Collapse>
        </EFCContainer>
        <EFCContainer
            overspread={collapse ? 42 : 202}
            style={{ marginTop: '20px', padding: '24px' }}>
            <div className="client-list-table-header">
                <Tooltip placement="top" title={<FormattedMessage id="app.ui.eclist.tooltip.filter" />} arrow={true}>
                    <Button
                        className="ef-sidefun"
                        icon={<IconFont className="iconfont iconfont-wait" type="icon-shaixuan" />}
                        onClick={() => { onHandleFliterOpen() }} />
                </Tooltip>
            </div>
            <Table
                className="ef-table"
                scroll={{
                    x: 'max-content'
                }}
                columns={columns.current}
                dataSource={dataSourceLocal.current}
                pagination={{
                    size: 'default',
                    total: total.current,
                    current: pageCurrent.current,
                    pageSize: pageSize.current,
                    showQuickJumper: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    showSizeChanger: true,
                    locale: customLocale,
                    onChange: onHandlePageChange,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current)
                }}
            ></Table>
        </EFCContainer>
        {renderDrawerFliter()}
    </div>
}
export default ClientList