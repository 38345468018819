import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.info.fetch_success": {
        id: "app.ui.brand_reg.message.info.fetch_success",
        defaultMessage: "brandの取得に成功しました。",
    },
    "message.error.fetch_failed": {
        id: "app.ui.brand_reg.message.error.fetch_failed",
        defaultMessage: "brandの取得に失敗しました，検索パラメータまたはネットワークを確認してください。",
    },
    "message.error.delete_failed": {
        id: "app.ui.brand_reg.message.error.delete_failed",
        defaultMessage: "削除に失敗しました。",
    }
})

export default messages