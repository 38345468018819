export const NoticeType = [
    {
        name:{
            ja:"EC record updated",
            en:"EC record updated",
        },
        value:1
    },
    {
        name:{
            ja:"EC record batch updated",
            en:"EC record batch updated",
        },
        value:2
    },
    {
        name:{
            ja:"EC Report uploaded",
            en:"EC Report uploaded",
        },
        value:3
    }
]

export const UrlParams = () => {
    return {
        offset: undefined,
        limit: undefined,
        orderFields: undefined,  // array
        whereFields: undefined,  // array
        dateFields: undefined,   // array
        startDate: undefined,           
        endDate: undefined,
        wordFields: undefined,   // array
        keyword: undefined,
        searchType: undefined
    }
}
