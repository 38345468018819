// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.client_empty": {
        id: "app.ui.test_purchase.error.client_empty",
        defaultMessage: "クライアントを入力ください。",
    },
    "message.error.region_empty": {
        id: "app.ui.test_purchase.error.region_empty",
        defaultMessage: "国・地区を入力ください。",
    },
    "message.error.site_empty": {
        id: "app.ui.test_purchase.error.site_empty",
        defaultMessage: "サイト名を入力ください。",
    },
    "message.error.count_empty": {
        id: "app.ui.test_purchase.error.count_empty",
        defaultMessage: "件数を入力ください。",
    },
    "message.info.create_client_success": {
        id: "app.ui.test_purchase.info.create_client_success",
        defaultMessage: "作成に成功しました。",
    },
    "message.error.create_client_failed": {
        id: "app.ui.test_purchase.error.create_client_failed",
        defaultMessage: "作成に失敗しました。",
    },
    "message.info.update_client_success": {
        id: "app.ui.test_purchase.info.update_client_success",
        defaultMessage: "変更に成功しました。",
    },
    "message.error.update_client_failed": {
        id: "app.ui.test_purchase.error.update_client_failed",
        defaultMessage: "変更に失敗しました。",
    },
})

export default messages