import React, { Component } from "react";
import { connect } from "react-redux";
import EFSimpleContainer from "@/components/ef-simple-container"

import { Button, Row, Col, Form, Input, message, Upload } from "antd";
import { reqTrademarkRegistration } from "@/api/trademark.js"
import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { PlusOutlined } from '@ant-design/icons';
import { isValidTelNo } from "../../../utils/ef-re";
import { FormattedMessage, injectIntl } from "react-intl";

import './index.less'
import messages from "../messages";

class TrademarkRegistration extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            fileList: []
        }
    }

    componentDidMount() {
        store.dispatch(setMenuActiveKey("trademark"));
        store.dispatch(setContentContainerHeaderAction([
            {
                name: <FormattedMessage id="app.ui.trademark.button.to_trademark_list" />,
                path: "/trademark",
            }
        ]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.trademark.register_title" />,
                path: "/trademark/registration",
            }
        ]));
    }

    handleChange = ({ fileList }) => {
        if (fileList && fileList.length > 0) {
            this.selectedFile = fileList[0].originFileObj
        } else {
            this.selectedFile = null
        }
        this.setState({ fileList })
    }

    onFinish = (values) => {
        let parameter = {}
        console.log(values)
        console.log(this.selectedFile)
        if (!values.trademark_title && !this.selectedFile) {
            message.error(this.props.intl.formatMessage(messages["message.error.no_word_or_image_mark"]))
            return
        }

        if (values.tel && !isValidTelNo(values.tel)) {
            message.error(this.props.intl.formatMessage(messages["message.error.tel_format"]))
            return
        }

        parameter = {
            "trademark_detail": values.trademark_detail,
            "company": values.company,
            "name": values.name,
            "mail": values.mail,
            "tel": values.tel,
        }

        if (values.trademark_title) {
            parameter["trademark_title"] = values.trademark_title;
        }

        if (this.selectedFile) {
            parameter["file"] = this.selectedFile;
        }

        if (values.fax) {
            parameter["fax"] = values.fax
        }
        if (values.remarks) {
            parameter["remarks"] = values.remarks
        }


        reqTrademarkRegistration(parameter).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                message.success(this.props.intl.formatMessage(messages["message.success.mail_success"]));
                // this.props.history.push("/trademark")
                this.formRef.current.resetFields()
                this.setState({ fileList: [] })
                this.selectedFile = null
            }
        }).catch((error) => {

        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }


    render() {
        document.title = this.props.intl.formatMessage(messages["register_title"]);
        return (
            <div className="trademark-registraion-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={20}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.trademark.container.trademark_info.title" />}>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_word" />
                                            </p>

                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="trademark_title">
                                            <Input></Input>
                                        </Form.Item>

                                        <p className="description">
                                            <FormattedMessage id="app.ui.trademark.label.trademark_word_description" />
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_image" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Upload
                                            accept=".png,.jpg,.jpeg,.bmp,.gif"
                                            listType="picture-card"
                                            maxCount={1}
                                            beforeUpload={() => false}
                                            onChange={this.handleChange}
                                            fileList={this.state.fileList}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        </Upload>
                                        <div>
                                            <p className="description">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_image_description" />
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_detail" />
                                            </p>
                                            <p className="description">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_detail_description" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="trademark_detail" rules={[
                                            {
                                                type: "string",
                                                max: 200,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_trademark_discraption"]),
                                            },
                                        ]}>
                                            <Input.TextArea></Input.TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_owner" />
                                            </p>
                                            <p className="description">
                                                <FormattedMessage id="app.ui.trademark.label.trademark_owner_description" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="company" rules={[
                                            {
                                                type: "string",
                                                max: 200,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_owner"]),
                                            },
                                        ]}>
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={20}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.trademark.container.contact_info.title" />}>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.contact_name" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string",
                                                max: 200,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_contact_person"]),
                                            },
                                        ]}>
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.contact_mail" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="mail" rules={[
                                            {
                                                type: "string",
                                                max: 200,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_contact_mail"]),
                                            },
                                            {
                                                type: "email",
                                                message: "正しいメールを入力ください。"
                                            }
                                        ]}>
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p>
                                                <FormattedMessage id="app.ui.trademark.label.contact_tel" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                max: 200,
                                                required: true,
                                                message: this.props.intl.formatMessage(messages["message.error.no_contact_tel"]),
                                            },
                                        ]}>
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.contact_fax" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="fax">
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={6}>
                                        <div className="item-label-container">
                                            <p className="content-title">
                                                <FormattedMessage id="app.ui.trademark.label.remarks" />
                                            </p>
                                            <p className="description">
                                                <FormattedMessage id="app.ui.trademark.label.remarks_description" />
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item noStyle={true} name="remarks">
                                            <Input.TextArea></Input.TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit" className="update-button">
                                <FormattedMessage id="app.ui.trademark.button.send_mail" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(TrademarkRegistration));
