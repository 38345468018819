import request from '@/utils/request'

export function reqCreateCustomColumn(data){
    return request({
        url: '/api/analyst/create-custom-column',
        method: 'post',
        data: data
    })
}

export function reqRemoveCustomColumn(data){
    return request({
        url: '/api/analyst/remove-custom-column',
        method: 'post',
        data: data
    })
}

export function reqUpdateCustomColumn(data){
    return request({
        url: '/api/analyst/update-custom-column',
        method: 'post',
        data: data
    })
}

export function reqFetchCustomColumns(data){
    return request({
        url: '/api/analyst/fetch-custom-columns',
        method: 'post',
        data: data
    })
}
