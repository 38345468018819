import { Button, Form, message, Table, Input, Space } from "antd"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { PlusOutlined } from '@ant-design/icons'
import { ModalForm } from '@ant-design/pro-form'
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { ProFormText, ProFormTextArea } from "@ant-design/pro-form"
import { reqUpdateECSite, reqGetECSite } from '@/api/analyst-site'
import { reqCreateSiteAccount, reqRemoveSiteAccount, reqFetchSiteAccounts, reqUpdateSiteAccount } from '@/api/site-account'
import { CountryConfig } from "@/config/country.js"
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'

import commonMessage from '../../../../locales/messages'
import messages from './messages'
import { FormattedMessage, useIntl } from 'react-intl'

// component reference dependency
import EFCContainer from '../../../../components/ef-c-container'
import EFBox from '../../../../components/ef-c-box'

const EFECSiteDetailView = (props) => {

    // 国际化配置
    const intl = useIntl()
    const nameIntlKey = useRef()
    nameIntlKey.current = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )

    const fontFamily = useRef()
    fontFamily.current = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const [currentState, setCurrentState] = useState()
    const updateTrigger = useRef(false)
    const boxData = useRef({})

    const formRef = useRef()
    const modalFormRef = useRef()
    const formField = useRef([])

    const site = useRef({})
    const accounts = useRef([])
    const items = useRef({})
    const categories = useRef([])

    const updateState = (data) => {
        boxData.current = [
            {
                name: 'name',
                value: data.site.name,
                label: 'app.ui.ec_site_detail.form.name',
                type: 'input',
                permission: true
            }, {
                name: 'url',
                value: data.site.url,
                label: 'app.ui.ec_site_detail.form.url',
                type: 'input',
                permission: true
            }, {
                name: 'region',
                value: data.site.region,
                label: 'app.ui.ec_site_detail.form.region',
                type: 'select',
                permission: true,
                params: CountryConfig.map((item, index) => {
                    return { value: item.code, label: item.name[nameIntlKey.current] }
                })
            }, {
                name: 'infrigementCategoryId',
                value: data.site.infrigementCategoryId,
                label: 'app.ui.ec_site_detail.form.category',
                type: 'select',
                permission: true,
                params: categories.current.map((item, index) => {
                    return { value: item.id, label: item.name }
                })
            }, {
                name: 'hasCrawler',
                value: data.site.hasCrawler ? 1 : 0,
                label: 'app.ui.ec_site_detail.form.scrape_possible',
                type: 'select',
                permission: true,
                params: [
                    {
                        value: 1,
                        label: <FormattedMessage id="app.common.option.yes" />,
                    }, {
                        value: 0,
                        label: <FormattedMessage id="app.common.option.no" />,
                    }
                ]
            }, {
                name: 'remarks',
                value: data.site.remarks ? data.site.remarks : "",
                label: 'app.ui.ec_site_detail.form.remarks',
                type: 'textarea',
                permission: true
            }
        ]
        site.current = data.site
        accounts.current = data.accounts
        data.accounts.map((item, index) => {
            if (items.current[item.id] && items.current[item.id] === true) {
            } else {
                items.current[item.id] = false
            }
        })
        toggleLoading()
    }

    const onHandleChange = (values) => {
        const params = {}
        if (values["name"] && site.current.name !== values["name"]) {
            params["siteName"] = values["name"]
        }
        if (values["url"] && site.current.url !== values["url"]) {
            params["siteURL"] = values["url"]
        }
        if (values["region"] && site.current.region !== values["region"]) {
            params["region"] = values["region"]
        }
        if (values["infrigementCategoryId"] && site.current.infrigementCategoryId !== values["infrigementCategoryId"]) {
            params["categoryId"] = values["infrigementCategoryId"]
        }
        if (values["hasCrawler"] && site.current.hasCrawler !== values["hasCrawler"]) {
            params["hasCrawler"] = values["hasCrawler"]
        }

        if (values["remarks"] && site.current.remarks !== values["remarks"]) {
            params["remarks"] = values["remarks"]
        }
        if (Object.keys(params).length > 0) {
            reqUpdateECSite({ ...params, siteId: site.current.id }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(
                        messages['message.info.update_account_success']
                    ))
                    renderData(site.current.id)
                }
            }).catch((e) => {
                message.error(intl.formatMessage(
                    messages['message.error.update_account_failed']
                ))
            })
        }
    }

    const onHandleState = (value) => {
        setCurrentState(value)
        if (value === false) {
            renderData()
        }
    }

    const onHandleUpdate = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const onHandleRemove = (record) => {
        if (record) {
            reqRemoveSiteAccount({
                id: record.id
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(
                        messages['message.info.update_account_success']
                    ))
                    reqFetchSiteAccounts({
                        siteId: props.match.params.id
                    }).then((response) => {
                        accounts.current = response.data.data.account
                        toggleLoading()
                    })
                }
            }).catch(e => {

            })
        }
    }

    const onSave = (record, values) => {
        const detail = {}
        if (record.account !== values["account" + record.id]) {
            detail["account"] = values["account" + record.id]
        }
        if (record.password !== values["password" + record.id]) {
            detail["password"] = values["password" + record.id]
        }
        if (record.phone !== values["phone" + record.id]) {
            detail["phone"] = values["phone" + record.id]
        }
        if (record.freeze_seconds !== values["freeze_seconds" + record.id]) {
            detail["freeze_seconds"] = parseInt(values["freeze_seconds" + record.id])
        }
        if (record.headers !== values["headers" + record.id]) {
            detail["headers"] = values["headers" + record.id]
        }
        if (record.cookies !== values["cookies" + record.id]) {
            detail["cookies"] = values["cookies" + record.id]
        }
        if (record.remarks !== values["remarks" + record.id]) {
            detail["remarks"] = values["remarks" + record.id]
        }
        if (Object.keys(detail).length > 0) {
            reqUpdateSiteAccount({
                id: record.id,
                ...detail
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(
                        messages['message.info.update_account_success']
                    ))
                    reqFetchSiteAccounts({
                        siteId: props.match.params.id
                    }).then((response) => {
                        items.current[record.id] = false
                        accounts.current = response.data.data.account
                        toggleLoading()
                    })
                }
            })
        }
    }

    const onHandleCreateAccount = (values) => {
        reqCreateSiteAccount({
            siteId: props.match.params.id,
            ...values
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success(intl.formatMessage(
                    messages['message.info.update_account_success']
                ))
                // updateState(data.data.task)
                reqFetchSiteAccounts({
                    siteId: props.match.params.id
                }).then((response) => {
                    accounts.current = response.data.data.account
                    toggleLoading()
                })
            }
        })
    }

    const onHandleSave = (record) => {
        formRef.current.validateFields().then((values) => {
            onSave(record, values)
        }).catch(errorInfo => { message.error(errorInfo.errorFields[0].errors[0]) })
    }

    const onHandleCancel = (record) => {
        items.current[record.id] = false
        toggleLoading()
    }

    const onHandleEdit = (record) => {
        let obj = {}
        formField.current.map((item, index) => {
            if (record[item] !== undefined) {
                obj[item + record.id] = record[item]
            }
        })
        if (formRef.current) {
            formRef.current.setFieldsValue(obj)
        }
        items.current[record.id] = true
        toggleLoading()
    }

    const renderTrigger = () => {
        if (currentState === true) {
            return <div>
                <Button className='ef-button-secondary-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.ui.profile.section.basicinfo.cancel' /></Button>
                <Button className='ef-button-color-small' style={{ marginLeft: '12px' }} onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.ui.profile.section.basicinfo.update' /></Button>
            </div>
        } else {
            return <div>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.ui.profile.section.basicinfo.edit' /></Button>
            </div>
        }
    }

    const renderBread = () => {
        document.title = intl.formatMessage({ id: "app.ec_site_detail.title" })
        store.dispatch(setMenuActiveKey("analyst-ec-site"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.register" />,
                path: "/analyst/ec-site",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.detail" />,
            },
        ]))
    }

    const renderData = async (id) => {
        let dataSource
        await reqGetECSite({
            siteId: id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                dataSource = data.data
            }
        })
        await reqFetchInfringementCategories().then((response) => {
            const { data } = response
            if (data.code === 0) {
                categories.current = data.data.categories
            }
        }).catch((error) => {

        })
        updateState(dataSource)
    }

    const createdModalItems = () => {
        return (
            <>
                <ProFormText
                    width="100%" name="account"
                    label={<FormattedMessage id="app.ui.ec_site_detail.table.account" />} rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.account_empty']
                            ),
                        },
                    ]}
                />
                <ProFormText
                    width="100%" name="password" label={<FormattedMessage id="app.ui.ec_site_detail.table.password" />} rules={[
                        {
                            required: true,
                            message: intl.formatMessage(
                                messages['message.error.password_empty']
                            ),
                        },
                    ]}
                />
                <ProFormText
                    width="100%" name="phone" label={<FormattedMessage id="app.ui.ec_site_detail.table.phone" />} rules={[
                        {
                            required: false,
                            message: intl.formatMessage(
                                messages['message.error.tel_empty']
                            ),
                        },
                    ]}
                />
                <ProFormText
                    width="100%" name="freeze_seconds" label={<FormattedMessage id="app.ui.ec_site_detail.table.freeze_seconds" />} rules={[
                        {
                            required: false,
                            message: intl.formatMessage(
                                messages['message.error.free_time_empty']
                            ),
                        },
                    ]}
                />
                <ProFormTextArea
                    width="100%" name="headers" label={<FormattedMessage id="app.ui.ec_site_detail.table.headers" />} rules={[
                        {
                            required: false,
                            message: intl.formatMessage(
                                messages['message.error.headers_empty']
                            ),
                        },
                    ]}
                />
                <ProFormTextArea
                    width="100%" name="cookies" label={<FormattedMessage id="app.ui.ec_site_detail.table.cookies" />} rules={[
                        {
                            required: false,
                            message: intl.formatMessage(
                                messages['message.error.cookies_empty']
                            ),
                        },
                    ]}
                />
                <ProFormTextArea
                    width="100%" name="remarks" label={<FormattedMessage id="app.ui.ec_site_detail.table.remarks" />} rules={[
                        {
                            required: false,
                            message: intl.formatMessage(
                                messages['message.error.remarks_empty']
                            ),
                        },
                    ]}
                />
            </>
        )
    }

    const createAccountColumns = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.account" />,
                dataIndex: 'account',
                key: 'account',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"account" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.account_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.password" />,
                dataIndex: 'password',
                key: 'password',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"password" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.password_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.phone" />,
                dataIndex: 'phone',
                key: 'phone',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"phone" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.tel_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.freeze_seconds" />,
                dataIndex: 'freeze_seconds',
                key: 'freeze_seconds',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"freeze_seconds" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.free_time_empty']
                                        ),
                                        // type: "number"
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.headers" />,
                dataIndex: 'headers',
                key: 'headers',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"headers" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.headers_empty']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.cookies" />,
                dataIndex: 'cookies',
                key: 'cookies',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"cookies" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.cookies_empty']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.remarks" />,
                dataIndex: 'remarks',
                key: 'remarks',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"remarks" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.remarks_tip']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            }, {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleSave(record)} >{<FormattedMessage id="app.common.action.save" />}</Button>
                                <Button type="link" onClick={() => onHandleRemove(record)}>{<FormattedMessage id="app.common.action.delete" />}</Button>
                                <Button type="link" onClick={() => onHandleCancel(record)}>{<FormattedMessage id="app.common.action.cancel" />}</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleEdit(record)} >{<FormattedMessage id="app.common.action.edit" />}</Button>
                            </Space>
                        )
                    }
                },
            }
        ]
        columns.push({
            title: (
                <ModalForm
                    className="ef-modalform-twin-col ec-site-detail-modal"
                    formRef={modalFormRef}
                    title={<div className="ef-text-bold"><FormattedMessage id="app.ui.ec_site_detail.modal.url_add" /></div>}
                    trigger={
                        <Button icon={<PlusOutlined />}></Button>
                    }
                    modalProps={{
                        onCancel: () => {
                            modalFormRef.current?.resetFields()
                        },
                    }}
                    onFinish={(values) => {
                        onHandleCreateAccount(values)
                        return true
                    }}
                    submitter={{
                        searchConfig: {
                            resetText: <FormattedMessage id="app.components.modal.reset" />,
                            submitText: <FormattedMessage id="app.components.modal.submit" />,
                        },
                    }}

                >
                    {createdModalItems()}
                </ModalForm >
            ),
            key: 'new',
            width: '100px',
        })
        formField.current = columns.map((item, index) => {
            return item.key
        })
        return columns
    }

    const renderTable = () => {
        return <Form
            ref={formRef}
            component={false}>
            <Table
                className="ef-table ef-ec-site-table"
                columns={createAccountColumns()}
                dataSource={accounts.current}
                scroll={{
                    x: "max-content"
                }}
                pagination={false}
            >
            </Table>
        </Form>
    }

    useEffect(() => {
        const id = props.match.params.id
        renderBread()
        renderData(id)
    }, [])

    return <EFCContainer
        overspread={0}
        style={{ padding: '32px 32px 16px 32px' }}
        className={fontFamily.current}>
        <EFBox
            title={<FormattedMessage id="app.ui.ec_site_detail.box_title" />}
            titleBarColor={'#1880DE'}
            data={boxData.current}
            itemWidth={300}
            itemClass={'ef-form-element-block'}
            state={currentState}
            updateTrigger={updateTrigger.current}
            onHandleChange={(value) => { onHandleChange(value) }}
        >
        </EFBox>
        {renderTrigger()}
        {renderTable()}
    </EFCContainer>
}

export default EFECSiteDetailView