import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import Lottie from "lottie-react"

const EFChartBox = (props) => {
    let chartTitle = props.chartTitle
    let chartHeight = props.height
    let number = props.number
    let chartDesc = props.chartDesc
    let chartColor = props.chartColor

    return (
        <div className='ef-home-chart-box-container'
            style={{ height: chartHeight ? chartHeight + 'px' : 'auto', width: '100%' }}>
            <div className='ef-chart-title-section'><p className='ef-chart-title'>{chartTitle}</p></div>
            <div className='ef-chart-content'>
                <div className='ef-chart-bar-light'
                    style={{background: chartColor ? chartColor : '#6FDD9A'}}></div>
                <div className='ef-chart-bar-data'>
                    <div className='ef-chart-num'>
                        <p className='ef-chart-number'>{number}</p>
                        <p className='ef-chart-unit'>{chartDesc && chartDesc.unit ? <FormattedMessage id={chartDesc.unit} /> : ''}</p>
                    </div>
                    <p className='ef-chart-desc'>{chartDesc && chartDesc.desc ? <FormattedMessage id={chartDesc.desc} /> : ''}</p>
                </div>
            </div>
        </div>
    )
}
export default EFChartBox