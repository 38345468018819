import React, { Component, useCallback, useEffect, useRef, useState } from "react"
import { Button, Row, Col, Form, Input, message, Upload, Select } from "antd"
import { reqTrademarkRegistration } from "@/api/trademark.js"
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { PlusOutlined } from '@ant-design/icons'
import { isValidTelNo } from "../../../utils/ef-re"
import { FormattedMessage, useIntl } from "react-intl"

import EFCContainer from '../../../components/ef-c-container'
import EFBox from '../../../components/ef-c-box'

import messages from "../messages"

import '../index.less'

const TrademarkRegistration = () => {

    const formRef = useRef()
    const fileLists = useRef()
    const selectedFile = useRef()
    const intl = useIntl()
    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const onHandleChange = ({ fileList }) => {
        if (fileList && fileList.length > 0) {
            selectedFile.current = fileList[0].originFileObj
        } else {
            selectedFile.current = null
        }
        fileLists.current = fileList
        toggleLoading()
    }

    const onHandleCreate = (values) => {
        let parameter = {}
        console.log(selectedFile.current)
        if (!values.trademark_title && !selectedFile.current) {
            message.error(intl.formatMessage(messages["message.error.no_word_or_image_mark"]))
            return
        }
        if (values.tel && !isValidTelNo(values.tel)) {
            message.error(intl.formatMessage(messages["message.error.tel_format"]))
            return
        }
        parameter = {
            "trademark_detail": values.trademark_detail,
            "company": values.company,
            "name": values.name,
            "mail": values.mail,
            "tel": values.tel,
        }

        if (values.trademark_title) {
            parameter["trademark_title"] = values.trademark_title
        }

        if (selectedFile.current) {
            parameter["file"] = selectedFile.current
        }

        if (values.fax) {
            parameter["fax"] = values.fax
        }
        if (values.remarks) {
            parameter["remarks"] = values.remarks
        }

        reqTrademarkRegistration(parameter).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success(intl.formatMessage(messages["message.success.mail_success"]))
                // this.props.history.push("/trademark")
                formRef.current.resetFields()
                fileLists.current = []
                selectedFile.current = null
                toggleLoading()
            }
        }).catch((error) => {

        })
    }

    const onHandleCreateFail = (values) => {
        const { errorFields } = values
        const { errors } = errorFields[0]
        message.error(errors[0])
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("trademark"))
        store.dispatch(setContentContainerHeaderAction([
            {
                name: <FormattedMessage id="app.ui.trademark.button.to_trademark_list" />,
                path: "/trademark",
            }
        ]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.trademark.register_title" />,
                path: "/trademark/registration",
            }
        ]))
    }

    useEffect(() => {
        renderBread()
    }, [])

    document.title = intl.formatMessage(messages["register_title"])
    return <EFCContainer className="ef-trademark-detail-container" overspread={0} style={{ padding: '32px' }}>
        <Form className="ef-edit-section" onFinish={onHandleCreate} onFinishFailed={onHandleCreateFail}>
            <Row gutter={[32]}>
                <Col span={12}>
                    <div className="ef-edit-section-title ef-title-border">
                        <p className="color-bar"></p>
                        <div className="describe ef-title-normal"><FormattedMessage id="app.ui.trademark.container.trademark_info.title" /></div>
                    </div>
                    <Row style={{ marginTop: '18px' }}>
                        <Col span={6}>
                            <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.label.trademark_word" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="trademark_title">
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                            <p className="description">
                                <FormattedMessage id="app.ui.trademark.label.trademark_word_description" />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.label.trademark_image" /></p>
                        </Col>
                        <Col span={18}>
                            <Upload
                                className="avatar-uploader"
                                accept=".png,.jpg,.jpeg,.bmp,.gif"
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={() => false}
                                onChange={onHandleChange}
                                fileList={fileLists.current}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}><FormattedMessage id="app.common.action.upload" /></div>
                                </div>
                            </Upload>
                            <p className="description">
                                <FormattedMessage id="app.ui.trademark.label.trademark_image_description" />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.label.trademark_detail" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="trademark_detail" rules={[
                                {
                                    type: "string",
                                    max: 200,
                                    required: true,
                                    message: intl.formatMessage(messages["message.error.no_trademark_discraption"]),
                                },
                            ]}>
                                <Input.TextArea className="ef-form-element-normal description-text" style={{ width: '100%' }}></Input.TextArea>
                            </Form.Item>
                            <p className="description">
                                <FormattedMessage id="app.ui.trademark.label.trademark_detail_description" />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.label.trademark_owner" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="company" rules={[
                                {
                                    type: "string",
                                    max: 200,
                                    required: true,
                                    message: intl.formatMessage(messages["message.error.no_owner"]),
                                },
                            ]}>
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                            <p className="description">
                                <FormattedMessage id="app.ui.trademark.label.trademark_owner_description" />
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <div className="ef-edit-section-title ef-title-border">
                        <p className="color-bar"></p>
                        <div className="describe ef-title-normal"><FormattedMessage id="app.ui.trademark.container.contact_info.title" /></div>
                    </div>
                    <Row className="edit-item" style={{ marginTop: '18px' }}>
                        <Col span={6}>
                            <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.label.contact_name" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="name" rules={[
                                {
                                    type: "string",
                                    max: 200,
                                    required: true,
                                    message: intl.formatMessage(messages["message.error.no_contact_person"]),
                                },
                            ]}>
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="edit-item">
                        <Col span={6}>
                            <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.label.contact_mail" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="mail" rules={[
                                {
                                    type: "string",
                                    max: 200,
                                    required: true,
                                    message: intl.formatMessage(messages["message.error.no_contact_mail"]),
                                },
                                {
                                    type: "email",
                                    message: "正しいメールを入力ください。"
                                }
                            ]}>
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="edit-item">
                        <Col span={6}>
                            <p className="ef-form-element-normal"><span className="marks">*</span><FormattedMessage id="app.ui.trademark.label.contact_tel" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="tel" rules={[
                                {
                                    type: "string",
                                    max: 200,
                                    required: true,
                                    message: intl.formatMessage(messages["message.error.no_contact_tel"]),
                                },
                            ]}>
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="edit-item">
                        <Col span={6}>
                            <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.label.contact_fax" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="fax">
                                <Input className="ef-form-element-normal" style={{ width: '100%' }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="edit-item">
                        <Col span={6}>
                            <p className="ef-form-element-normal"><FormattedMessage id="app.ui.trademark.label.remarks" /></p>
                        </Col>
                        <Col span={18}>
                            <Form.Item noStyle={true} name="remarks">
                                <Input.TextArea className="ef-form-element-normal description-text" style={{ width: '100%' }}></Input.TextArea>
                            </Form.Item>
                            <p className="description">
                                <FormattedMessage id="app.ui.trademark.label.remarks_description" />
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Button htmlType="submit" className="ef-button-color-small update-button">
                <FormattedMessage id="app.ui.trademark.button.send_mail" />
            </Button>
        </Form>
    </EFCContainer>
}
export default TrademarkRegistration