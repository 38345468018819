// extra= { currentDataSource: [], action: paginate | sort | filter }

export const filter_prefix = "filter_"

export const updateSearchParamWithTableOnChangeInfo = (originSearchParam, pageInfo) => {
    const { pagination, filters, sorter, extra } = pageInfo
    const newFilters = Object.keys(filters).reduce((pre, key) => {
        const newKey = filter_prefix + key
        pre[newKey] = filters[key]
        return pre
    }, {})

    const info = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        sortField: sorter.field,
        order: sorter.order,
        ...newFilters
    }

    let searchParams = new URLSearchParams(originSearchParam);
    Object.keys(info).forEach(key => {
        const value = info[key]
        if (value) {
            searchParams.set(key, value)
        } else {
            searchParams.delete(key)
        }
    })
    // console.log(searchParams.toString())
    return searchParams
}

export const getAllFiltersFromSearchParam = (searchParam) => {
    let params = new URLSearchParams(searchParam);
    let result = []
    const startIndex = filter_prefix.length
    params.forEach((value, key) => {
        if (key.startsWith(filter_prefix)) {
            const convertKey = key.substring(startIndex)
            let item = {
                key: convertKey,
                values: value ? value.split(',') : null
            }
            result.push(item)
        }
    })
    return result
}

export const updateSearchParamWithSearchInfo = (originSearchParam, searchConditions) => {
    // console.log(originSearchParam, searchConditions)
    let searchParams = new URLSearchParams(originSearchParam);
    const searchKeys = ["dateFields", "startDate", "endDate", "keyword", "wordFields", "searchType"]
    searchKeys.forEach(key => {
        const value = searchConditions[key]
        if (value) {
            searchParams.set(key, value)
        } else {
            searchParams.delete(key)
        }
    })
    return searchParams
}