import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import { Button, Image } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const EFCChartRate = (props) => {
  const title = props.chartTitle
  const desc = props.chartDesc

  return (
    <div
      className="ef-chart-rate"
      style={{
        height: props.height ? props.height + 'px' : '312px',
      }}>
      <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
      <div
        className="ef-chart-content"
        style={{
          height: props.height ? props.height - 72 + 'px' : '240px',
        }}>
        {desc.map((item, index) => {
          return (
            <div className="ef-chart-item">
              <div className="ef-chart-item-ele">
                <Image
                  width={40}
                  preview={false}
                  src={index===0?"/images/usd.png":(index===1?'/images/rate.png':(index===2?'/images/jpy.png':''))}></Image>
              </div>
              <div className="ef-title-regular ef-chart-item-ele">
                <p className='ef-chart-ball-number'>{(props.chartData && props.chartData[index] && props.chartData[index].value)? props.chartData[index].value:0}</p>
              </div>
              <div className="ef-text-block ef-chart-item-ele">
                <p>{(desc && desc[index]) && <FormattedMessage id={desc[index]} />}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default EFCChartRate
