import "./index.less"

import store from "@/store"
import React, { useState, useRef, useEffect, useCallback } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Row, Col, Select, message, Tabs, Modal } from "antd"
import { FormattedMessage, useIntl } from 'react-intl'
import messages from './messages'
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import { reqUpdateClient, reqRemoveClient, reqGetClient } from '../../../api/admin-client'
import { reqSearchUsers } from '../../../api/admin-user'

import EFMasterBrand from "../../analyst/ec-master/ec-master-brand"
import EFMasterProductCategory from "../../analyst/ec-master/ec-master-product-category"
import EFMasterCustomColumn from "../../analyst/ec-master/ec-master-custom-column"
import EFMasterSpecailURL from "../../analyst/ec-master/ec-master-special-url"
import EFMasterSpecailWord from "../../analyst/ec-master/ec-master-special-word"
import EFMasterAttorneyLetter from "../../analyst/ec-master/master-attorney-letter"
import EFMasterTrademarkCountry from "../../analyst/ec-master/master-trademark-country"
import EFMasterOtherSetting from "../../analyst/ec-master/ec-master-other"
import EFMasterECReport from "../../analyst/ec-master/ec-master-report"
import EFMasterWSReport from "../../analyst/ec-master/ws-master-report"

import { isValidTelNo } from "../../../utils/ef-re"

import moment from 'moment'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFBox from '../../../components/ef-c-box'

const ClientDetail = () => {

    const intl = useIntl()

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()

    const clientId = useRef(parseInt(location.pathname.split("/")[3]))
    const clientInfo = useRef({})
    const analysts = useRef([])
    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const [tabCurrent, setTabCurrent] = useState(0)
    const [dataSetTab, setDataSetTab] = useState(0)

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }

    const tabs = useRef({
        item: [
            <FormattedMessage id="app.ui.brand_reg_new.basicinfo.title" />,
            <FormattedMessage id="app.ui.brand_reg_new.contract.title" />,
            <FormattedMessage id="app.ui.brand_reg_detail.setting.title" />,
        ],
        disable: [],
        mode: 'card'
    })

    const isManagerForClient = useRef(false)
    const isAdmin = useRef(reduxProps.role & 4 ? true : false)

    const basicInfo = useRef([])
    const serviceInfo = useRef([])
    const updateTrigger = useRef(false)
    const currentState = useRef(false)

    const removeClient = () => {
        reqRemoveClient({ clientId: clientId.current }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                history.push("/manage/client")
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.delete_failed']))
        })
    }

    const setupManagerOptionsDefaultValue = (analystInfo) => {
        if (analystInfo) {
            let strManagerIds = analystInfo.map((analyst) => {
                return analyst.user_id.toString()
            })

            let user = analystInfo.find((analyst) => {
                return analyst.user_id === reduxProps.id
            })

            isManagerForClient.current = user ? true : false
            if (!isManagerForClient.current) {
                tabs.current.disable.push(2)
            }
        }
    }

    const updateUIByClientInfo = (data) => {
        basicInfo.current = [
            {
                name: 'name',
                value: data.client_name,
                label: 'app.ui.brand_reg_new.name',
                type: 'input',
                permission: false,
            }, {
                name: 'tel',
                value: data.client_tel,
                label: 'app.ui.brand_reg_new.tel',
                type: 'input',
                permission: true
            }, {
                name: 'address',
                value: data.client_address,
                label: 'app.ui.brand_reg_new.address',
                type: 'input',
                permission: true
            }, {
                name: 'managers',
                value: data.analystInfo.map((item) => {
                    return item.user_id
                }),
                label: 'app.ui.brand_reg_new.managers',
                type: 'select',
                mode: 'multiple',
                params: analysts.current.map((item, index) => {
                    return {
                        label: item.user_name,
                        value: item.user_id
                    }
                }),
                permission: true
            }, {
                name: 'remarks',
                value: data.client_remarks,
                label: 'app.ui.brand_reg_new.remarks',
                type: 'textarea',
                permission: true
            }
        ]
        serviceInfo.current = [
            {
                name: 'ec',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.ec_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: [data.service_ec_start_date, data.service_ec_end_date],
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: isAdmin
                    }, {
                        name: ['fee', 'currency'],
                        value: [data.service_ec_fee, data.service_ec_currency],
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                        permission: isAdmin
                    }, {
                        name: 'remindMail',
                        value: data.service_ec_remind_mail,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: isAdmin
                    }
                ]
            }, {
                name: 'sns',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.sns_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: [data.service_sns_start_date, data.service_sns_end_date],
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: isAdmin
                    }, {
                        name: ['fee', 'currency'],
                        value: [data.service_sns_fee, data.service_sns_currency],
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                        permission: isAdmin
                    }, {
                        name: 'remindMail',
                        value: data.service_sns_remind_mail,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: isAdmin
                    }
                ]
            }, {
                name: 'ws',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.ws_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: [data.service_ws_start_date, data.service_ws_end_date],
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: isAdmin
                    }, {
                        name: ['fee', 'currency'],
                        value: [data.service_ws_fee, data.service_ws_currency],
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                        permission: isAdmin
                    }, {
                        name: 'remindMail',
                        value: data.service_ws_remind_mail,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: isAdmin
                    }
                ]
            }, {
                name: 'other',
                subTitle: <p className="ef-title-tip"><FormattedMessage id="app.ui.brand_reg_new.other_protection" /></p>,
                item: [
                    {
                        name: ['startDate', 'endDate'],
                        value: [data.service_other_start_date, data.service_other_end_date],
                        label: 'app.ui.brand_reg_new.contract_time',
                        type: 'datePicker',
                        permission: isAdmin
                    }, {
                        name: ['fee', 'currency'],
                        value: [data.service_other_fee, data.service_other_currency],
                        label: 'app.ui.brand_reg_new.fee',
                        type: 'input-select',
                        params: [
                            {
                                label: 'USD',
                                value: 1,
                            }, {
                                label: 'JPY',
                                value: 2,
                            }, {
                                label: 'CNY',
                                value: 3,
                            }
                        ],
                        permission: isAdmin
                    }, {
                        name: 'remindMail',
                        value: data.service_other_remind_mail,
                        type: 'checkbox',
                        label: 'app.ui.brand_reg_new.mail',
                        tip: 'app.ui.brand_reg_new.tips',
                        permission: isAdmin
                    }
                ]
            }
        ]
        setupManagerOptionsDefaultValue(data.analystInfo)
    }

    const checkService = (startDate, endDate, fee) => {
        if (startDate && endDate && fee) {
            return true
        } else {
            return false
        }
    }

    const onValidate = (data) => {
        if (data.tel && !isValidTelNo(data.tel)) {
            message.error(intl.formatMessage(messages['message.error.tel_error']))
            return false
        }
        if (data.name && data.name.length > 30) {
            message.error(intl.formatMessage(messages['message.error.name_error']))
            return false
        }
        if (data.address && data.address.length > 100) {
            message.error(intl.formatMessage(messages['message.error.address_error']))
            return false
        }
        if (data.remarks && data.remarks.length > 500) {
            message.error(intl.formatMessage(messages['message.error.remarks_error']))
            return false
        }
        return true
    }

    const onHandleTabClick = (index) => {
        currentState.current = false
        setTabCurrent(index)
    }

    const onHandleBasicChange = (value) => {
        if (Object.keys(value).length === 0) {
            return
        }
        const { name, tel, address, managers, remarks } = value

        let body = {
            clientId: clientId.current,
            name,
            tel,
            address,
            remarks,
        }

        if (managers) {
            let intMangerIds = managers.map((managerId) => {
                return parseInt(managerId)
            })
            body.managerIds = intMangerIds
        }

        if (onValidate(body)) {
            reqUpdateClient(body).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    let clientInfo = data.data.client
                    updateUIByClientInfo(clientInfo)
                    message.success(intl.formatMessage(messages['message.info.client_update']))
                } else {
                    message.error(intl.formatMessage(messages['message.error.client_failed']))
                }
            }).catch((error) => {
                message.error(intl.formatMessage(messages['message.error.client_failed']))
            })
        } else {

        }
    }

    const onHandleAppointChange = (value) => {
        let body = {
            clientId: clientId.current,
        }
        if (Object.keys(value).length === 0) {
            return
        }
        if (value['ec'] && checkService(value['ec'].startDate, value['ec'].endDate, value['ec'].fee)) {
            body["ec"] = {
                startDate: value['ec'].startDate,
                endDate: value['ec'].endDate,
                fee: parseFloat(value['ec'].fee),
                currency: parseInt(value['ec'].currency),
                remindMail: value['ec'].remindMail && value['ec'].remindMail ? true : false,
            }
        }


        if (value['sns'] && checkService(value['sns'].startDate, value['sns'].endDate, value['sns'].fee)) {
            body["sns"] = {
                startDate: value['sns'].startDate,
                endDate: value['sns'].endDate,
                fee: parseFloat(value['sns'].fee),
                currency: parseInt(value['sns'].currency),
                remindMail: value['sns'].remindMail && value['sns'].remindMail ? true : false,
            }
        }

        if (value['ws'] && checkService(value['ws'].startDate, value['ws'].endDate, value['ws'].fee)) {
            body["ws"] = {
                startDate: value['ws'].startDate,
                endDate: value['ws'].endDate,
                fee: parseFloat(value['ws'].fee),
                currency: parseInt(value['ws'].currency),
                remindMail: value['ws'].remindMail && value['ws'].remindMail ? true : false,
            }
        }

        if (value['other'] && checkService(value['other'].startDate, value['other'].endDate, value['other'].fee)) {
            body["other"] = {
                startDate: value['other'].startDate,
                endDate: value['other'].endDate,
                fee: parseFloat(value['other'].fee),
                currency: parseInt(value['other'].currency),
                remindMail: value['other'].remindMail && value['other'].remindMail ? true : false,
            }
        }
        reqUpdateClient(body).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let clientInfo = data.data.client
                updateUIByClientInfo(clientInfo)
                message.success(intl.formatMessage(messages['message.info.client_update']))
            } else {
                message.error(intl.formatMessage(messages['message.error.client_failed']))
            }
        }).catch((error) => {
            message.error(intl.formatMessage(messages['message.error.client_failed']))
        })
    }

    const onHandleState = () => {
        currentState.current = !currentState.current
        toggleLoading()
    }

    const onHandleUpdate = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderDeleteIntercept = () => {
        Modal.confirm({
            title: intl.formatMessage(messages['message.error.delete_warning']),
            className: 'ef-modal',
            okText: intl.formatMessage({id: "app.common.action.confirm"}),
            cancelText: intl.formatMessage({id: "app.common.action.cancel"}),
            onOk: (close) => {
                removeClient()
                close()
            },
            onCancel: (close) => {
                close()
            }
          });
    }

    const renderTrigger = () => {
        if (currentState.current === true && tabCurrent < 2) {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.common.action.cancel'/></Button>
                <Button className='ef-button-color-small' style={{ marginLeft: '8px' }} onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.common.action.update'/></Button>
            </div>
        } else if (tabCurrent < 2) {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.common.action.edit'/></Button>
            </div>    
        } else {
            return <div></div>
        }
    }

    const renderBasicInfo = () => {
        if (tabCurrent === 0) {
            return <div>
                <EFBox
                    // titleBarColor={'#1880DE'}
                    // title="Basic Information"
                    mode={'single'}
                    data={basicInfo.current}
                    lineWidth={300}
                    itemClass={'ef-form-element-block'}
                    state={currentState.current}
                    updateTrigger={updateTrigger.current}
                    onHandleChange={(value) => { onHandleBasicChange(value) }}
                ></EFBox>
            </div>
        } else {
            return ''
        }
    }

    const renderAppointInfo = () => {
        if (tabCurrent === 1) {
            return <div>
                <EFBox
                    // titleBarColor={'#1880DE'}
                    // title="Basic Information"
                    mode={'mutiple'}
                    data={serviceInfo.current}
                    lineWidth={300}
                    itemClass={'ef-form-element-block'}
                    state={currentState.current}
                    updateTrigger={updateTrigger.current}
                    onHandleChange={(value) => { onHandleAppointChange(value) }}
                ></EFBox>
            </div>
        } else {
            return ''
        }
    }

    const renderECMainInfo = () => {
        return <div style={{ flex: 1 }}>
            <Tabs className="ef-tabs-card" style={{ marginTop: '20px' }} type="card" tabPosition="left">
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.brand.title' })} key="1">
                    <EFMasterBrand clientId={clientId.current} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.product.title' })} key="2">
                    <EFMasterProductCategory clientId={clientId.current} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.additional_item.title' })} key="3">
                    <EFMasterCustomColumn clientId={clientId.current} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.list_black.title' })} key="4">
                    <EFMasterSpecailURL clientId={clientId.current} urlType={2} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.list_white.title' })} key="5">
                    <EFMasterSpecailURL clientId={clientId.current} urlType={1} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.compatible_notation.title' })} key="6">
                    <EFMasterSpecailWord clientId={clientId.current} wordType={1} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.product_category.genuine_notation.title' })} key="7">
                    <EFMasterSpecailWord clientId={clientId.current} wordType={2} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    }

    const renderDataSettingContent = () => {
        switch (dataSetTab) {
            case 0:
                return <EFMasterECReport clientId={clientId.current} key="EFMasterECReport" />
            case 1:
                return renderECMainInfo()
            case 2:
                return <EFMasterWSReport clientId={clientId.current} key="EFMasterWSReport" />
            case 3:
                return <EFMasterAttorneyLetter clientId={clientId.current} key="EFMasterAttorneyLetter" />
            case 4:
                return <EFMasterTrademarkCountry clientId={clientId.current} key="7EFMasterTrademarkCountry" />
            case 5:
                return <EFMasterOtherSetting clientId={clientId.current} key="EFMasterOtherSetting" />
        }
    }

    const renderDataSettings = () => {
        if (tabCurrent === 2) {
            return <div>
                <div className="client-detail-meau">
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 0 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(0) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.ec_report" /></div>
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 1 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(1) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.ec_master_information" /></div>
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 2 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(2) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.ws_report" /></div>
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 3 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(3) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.proxy" /></div>
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 4 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(4) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.trademark_registration" /></div>
                    <div className={`client-detail-meau-item ef-text-normal ${dataSetTab === 5 ? 'meau-item-active' : ''}`} onClick={() => { setDataSetTab(5) }}><FormattedMessage id="app.ui.brand_reg_detail.setting.other" /></div>
                </div>
                {renderDataSettingContent()}
            </div>
        } else {
            return ''
        }
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-client-list"))
        if (isAdmin.current) {
            store.dispatch(setContentContainerHeaderAction([{
                name: <FormattedMessage id="app.common.action.delete" />,
                onClick: () => { return renderDeleteIntercept() }  
            }]))
        } else {
            store.dispatch(setContentContainerHeaderAction([]))
        }

        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.client_list" />,
                path: "/manage/client",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.detail" />,
            }
        ]))
    }

    const renderClientData = async () => {
        let clientInfo
        await reqGetClient({
            "clientId": clientId.current
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                clientInfo = data.data.client
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.client_fetch_failed']))
        })

        await reqSearchUsers({
            "role": reduxProps.role
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                analysts.current = data.data.users
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.user_fetch_failed']))
        })

        updateUIByClientInfo(clientInfo)
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderClientData()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.brand_reg_detail.title' })
    return <EFCContainer
        overspread={0}
        style={{ padding: '20px 24px' }}
        renderTab={tabs.current}
        onTabClick={(index) => {
            onHandleTabClick(index)
        }}
    >
        {renderBasicInfo()}
        {renderAppointInfo()}
        {renderDataSettings()}
        {renderTrigger()}
    </EFCContainer>
}
export default ClientDetail