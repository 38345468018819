import './index.less'
import React, { Component } from 'react'
import {
  Form,
  Checkbox,
  Table,
  Select,
  Button,
  List,
  Modal,
  message,
  Spin,
  Collapse,
} from 'antd'

import { reqFetchClients } from '@/api/analyst-client'

import { InfringementCommonCategories } from '@/config/infringement-common-category.js'

import EFCSearchBar from '@/components/ef-c-searchbar'
import {
  setContentContainerHeaderAction,
  setBreadcrumbs,
} from '@/store/actions/content-header.js'
import { setMenuActiveKey } from '@/store/actions/menu.js'
import store from '@/store'
import { connect } from 'react-redux'
import {
  reqSearchECRecords,
  reqFetchReports,
  reqGetECRecordHistory,
  reqBatchUpdateCategory,
  reqBatchUpdateAction,
} from '@/api/ec.js'
import { reqFetchCustomColumns } from '../../../api/analyst-custom-column'
import { reqSearchTrademarks } from '../../../api/trademark'
import { reqFetchInfringementCategories } from '../../../api/analyst-infringement-category'
import { reqFetchProductCategories } from '../../../api/analyst-product-category'
import EFCTableHeader from '../../../components/ef-c-tableheader'
import getECListBasicColumns from './ec-list-columns'
import { getKeywordSearchItems, getDateSearchItems } from './ec-list-const.js'
import ECListCommon from '../common/ec-list-common.js'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ECRecordHistory from './ec-history'
import { isErrorRecord } from './ec-list-helper'

import { FormattedMessage, injectIntl } from 'react-intl'
import messages from './ec-list-messages'
import { TakedownRequest } from '../../../config/ec-report-select-items'
import EFResizableTable from '../../../components/ef-c-resizable-table'
const { Panel } = Collapse

class ECRecordList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      dateSource: [],
      page: 1,
      count: 0,
      total: 0,
      clients: [],
      showColumns: this.getBasicColumns()
        .filter((column) => column.defaultShow === true)
        .map((column) => column.key),
      shouldShowColumnModal: false,
      selectRowKeys: [],
      tableScrollY: `calc(100vh - 613px)`,
      collapse: false,
    }
    this.total = 0
    this.changeColumnFormRef = React.createRef()
    this.customColumnDic = {}
    this.trademarksInfo = {}
    this.productsInfo = {}
    this.reportsInfo = {}
    this.showColumnsInfo = {}
    this.filterErrorRecord = 0
    this.columnWithDic = {}
  }

  onCollapseChange(value) {
    if (value.length > 0) {
      this.setState({
        tableScrollY: `calc(100vh - 613px)`,
      })
    } else {
      this.setState({
        tableScrollY: `calc(100vh - 353px)`,
      })
    }
  }

  getInitData = async () => {
    let clients = null
    if (this.props.role & 2) {
      await reqFetchClients()
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            clients = data.data.client
          }
        })
        .catch((error) => {})
    }
    let categorysInfo = null
    await reqFetchInfringementCategories()
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          categorysInfo = data.data.categories
        }
      })
      .catch((error) => {})
    return { clients, categorysInfo }
  }

  initSearchComditionsByURLSearchParams = (searchParams) => {
    try {
      this.currentSearchConditions = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.search))
      )
    } catch (e) {}
    try {
      this.currentSorter = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.sorter))
      )
    } catch (e) {}
    try {
      this.currentFilters = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.filters))
      )
    } catch (e) {}

    try {
      this.noticeId = JSON.parse(
        decodeURIComponent(searchParams.get('notice_id'))
      )
    } catch (e) {}
    if (this.props.role & 2) {
      this.currentSelectClientId = searchParams.get('clientId')
      if (this.currentSelectClientId) {
        this.handleClientChanged(parseInt(this.currentSelectClientId))
      }
    } else {
      this.currentSelectClientId = this.props.companyInfo.client_id

      if (this.currentSelectClientId) {
        this.handleClientChanged(parseInt(this.currentSelectClientId))
      }
    }
  }

  getBasicColumns = () => {
    let columns = getECListBasicColumns({
      currentSearchClientId: this.currentSearchClientId,
      allReportsInfo: this.reportsInfo,
      currentSearchConditions: this.currentSearchConditions,
      allProductsInfo: this.productsInfo,
      categorysInfo: this.state ? this.state.categorysInfo : null,
      allTrademarksInfo: this.trademarksInfo,
      currentSelectClientId: this.currentSelectClientId,
      searchWords: this.currentSearchConditions
        ? [this.currentSearchConditions.keyword]
        : [],
      searchColumns: this.currentSearchConditions
        ? this.currentSearchConditions.wordFields
        : [],
      allSearchableCoulumns: getKeywordSearchItems(this.props.intl),
      onDetailClick: this.onDetailClick,
      onHistoryClick: this.onHistoryClick,
      filters: this.currentFilters,
      intl: this.props.intl,
    })
   
    return columns.map((column, index) => {
      let width = column.width
      if (this.columnWithDic && this.columnWithDic[column.key]) {
        width = this.columnWithDic[column.key]
      } else {
      }
      return {
        ...column,
        // width: width,
        onHeaderCell: (column) => ({
          width: column.width,
        }),
      }
    })
  }

  onDetailClick = (record) => {
    let searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set('num', record.num)
    searchParams.set('total', this.total)
    this.props.history.push({
      pathname: '/ec-data/' + this.currentSearchClientId + '/' + record.ec_id,
      search: `?${searchParams.toString()}`,
    })
  }

  onHistoryClick = (record) => {
    this.setState({
      historyVisible: true,
      currentHistory: [],
      historyLoading: true,
    })
    this.currentHistoryRecord = record
    reqGetECRecordHistory({
      clientId: this.currentSearchClientId,
      recordId: record.ec_id,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          this.setState({
            currentHistory: data.data.history,
            historyLoading: false,
          })
        }
      })
      .catch((e) => {
        this.setState({
          currentHistory: [],
          historyLoading: false,
        })
      })
  }

  onHistoryClose = () => {
    this.setState({ historyVisible: false })
  }

  onSearchClick = (conditions) => {
    // let searchParams = new URLSearchParams(this.props.location.search);
    let searchParams = new URLSearchParams({})
    if (conditions) {
      searchParams.set(
        ECListCommon.ParamsKey.search,
        encodeURIComponent(JSON.stringify(conditions))
      )
    }
    let finalConditions =
      ECListCommon.converSearchParmasToConditions(searchParams)
    this.handleSearch(finalConditions)
    this.setState({ selectRowKeys: [] })
  }

  handleSearch = (conditions) => {
    
    let searchConditions = { ...conditions }
    let clientId = searchConditions.clientId
    let noticeId = searchConditions.noticeId
    this.currentSearchClientId = clientId
      ? clientId
      : this.currentSelectClientId
    this.currentSearchConditions = searchConditions
    if (!this.currentSearchClientId) {
      return
    }
    if (
      this.currentSearchConditions[ECListCommon.ParamsKey.filterErrorRecord] ===
        null ||
      this.currentSearchConditions[ECListCommon.ParamsKey.filterErrorRecord] ===
        undefined
    ) {
      let searchParams = new URLSearchParams(this.props.location.search)
      searchParams.set(
        ECListCommon.ParamsKey.filterErrorRecord,
        this.filterErrorRecord ? 1 : 0
      )
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?${searchParams.toString()}`,
      })
    }

    console.log(searchConditions)
    reqSearchECRecords({
      clientId: this.currentSearchClientId,
      filterErrorRecord: this.filterErrorRecord,
      ...searchConditions,
      noticeId,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          this.total = data.data.total
          this.errorRecordCount = data.data.errorRecordCount
          console.log(data.data.records)
          this.setState({
            dateSource: data.data.records,
            total: data.data.total,
            offset: data.data.offset,
            errorRecordCount: data.data.errorRecordCount,
          })
          
        }
      })
      .catch((e) => {})
    this.resetColumns(this.currentSearchClientId)
  }

  onTableValueChange = (pagination, filters, sorter, extraInfo) => {
    let searchParams = new URLSearchParams(this.props.location.search)
    if (pagination) {
      searchParams.set(
        ECListCommon.ParamsKey.pagination,
        encodeURIComponent(JSON.stringify(pagination))
      )
    }
    if (filters && extraInfo.action === 'filter') {
      let effectFilterKeys = Object.keys(filters).filter((key) => {
        return filters[key]
      })
      let effectFilters = effectFilterKeys.map((key) => {
        let result = {}
        result.key = key
        result.values = filters[key]
        return result
      })
      if (effectFilters && effectFilters.length > 0) {
        searchParams.set(
          ECListCommon.ParamsKey.filters,
          encodeURIComponent(JSON.stringify(effectFilters))
        )
      } else {
        searchParams.set(ECListCommon.ParamsKey.filters, null)
      }
    }

    if (sorter && sorter.field) {
      if (sorter.order) {
        let sortInfo = {}
        sortInfo['key'] = sorter.field
        sortInfo.value = sorter.order === 'ascend' ? 1 : 2
        searchParams.set(
          ECListCommon.ParamsKey.sorter,
          encodeURIComponent(JSON.stringify([sortInfo]))
        )
      } else {
        searchParams.delete(ECListCommon.ParamsKey.sorter)
      }
    }

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${searchParams.toString()}`,
    })

    let conditions = ECListCommon.converSearchParmasToConditions(searchParams)
    this.handleSearch(conditions)
  }

  handleReset = () => {
    let searchParams = new URLSearchParams(this.props.location.search)
    searchParams.delete('noticeId')
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search)
    this.initSearchComditionsByURLSearchParams(searchParams)

    let conditions = ECListCommon.converSearchParmasToConditions(searchParams)
    this.handleSearch(conditions)
    this.getInitData().then((data) => {
      this.setState({
        categorysInfo: data.categorysInfo,
        clients: data.clients,
        isLoading: false,
      })
    })
    if (this.props.role & 2) {
      store.dispatch(setMenuActiveKey('analyst-ec-list'))
    } else {
      store.dispatch(setMenuActiveKey('client-ec-list'))
    }
    store.dispatch(
      setContentContainerHeaderAction([
        {
          name: <FormattedMessage id="app.ui.eclist.to_trademark_list" />,
          path: '/trademark',
        },
      ])
    )
    store.dispatch(
      setBreadcrumbs([
        {
          name: <FormattedMessage id="app.layout.menu.home" />,
          path: '/',
        },
        {
          name: (
            <FormattedMessage id="app.layout.menu.online_protection.ec_record_list" />
          ),
          path: '/ec-list',
        },
      ])
    )
  }

  resetColumns = (clientId) => {
    if (clientId) {
      let customItems = this.customColumnDic['customItemsInfo_' + clientId]
      if (customItems) {
        customItems = customItems.sort((a, b) => {
          if (a.column_order < b.column_order) {
            return 1
          } else {
            return -1
          }
        })
        let newColumns = customItems.reduce((pre, item) => {
          let newColumn = {
            title: item.column_name,
            dataIndex: 'custom_info',
            key: 'custom_info_' + item.column_name,
            width: 150,
            render: (value, record) => {
              try {
                let obj = JSON.parse(value)
                return obj[item.column_name]
              } catch (e) {
                return value
              }
            },
          }
          let index = pre.findIndex((column) => {
            return item.column_position === column.key
          })
          let newColumns = pre.slice(0, index + 1).concat([newColumn])

          if (newColumns.length <= pre.length) {
            newColumns = newColumns.concat(pre.slice(index + 1))
          }
          return newColumns
        }, this.getBasicColumns())

        if (this.showColumnsInfo[clientId]) {
          this.setState({ showColumns: this.showColumnsInfo[clientId] })
        } else {
          this.showColumnsInfo[clientId] = newColumns
            .filter((column) => column.defaultShow)
            .map((column) => column.key)
          this.setState({ showColumns: this.showColumnsInfo[clientId] })
        }
      } else {
        if (this.showColumnsInfo[clientId]) {
          this.setState({ showColumns: this.showColumnsInfo[clientId] })
        } else {
          this.showColumnsInfo[clientId] = this.getBasicColumns()
            .filter((column) => column.defaultShow)
            .map((column) => column.key)
          this.setState({ showColumns: this.showColumnsInfo[clientId] })
        }
      }
    }
  }

  getBasicColumnsWithCustom = (clientId) => {
    if (clientId) {
      let customItems = this.customColumnDic['customItemsInfo_' + clientId]

      if (customItems) {
        customItems = customItems.sort((a, b) => {
          if (a.column_order < b.column_order) {
            return 1
          } else {
            return -1
          }
        })
        let newColumns = customItems.reduce((pre, item) => {
          let newColumn = {
            title: item.column_name,
            dataIndex: 'custom_info',
            key: 'custom_info_' + item.column_name,
            width: 150,
            render: (value, record) => {
              try {
                let obj = JSON.parse(value)
                return obj[item.column_name]
              } catch (e) {
                return value
              }
            },
          }
          let index = pre.findIndex((column) => {
            return item.column_position === column.key
          })
          let newColumns = pre.slice(0, index + 1).concat([newColumn])
          if (newColumns.length <= pre.length) {
            newColumns = newColumns.concat(pre.slice(index + 1))
          }
          return newColumns
        }, this.getBasicColumns())
        return newColumns
      } else {
        return this.getBasicColumns()
      }
    } else {
      return this.getBasicColumns()
    }
  }

  handleClientChanged = (value) => {
    this.currentSelectClientId = value
    let searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set(ECListCommon.ParamsKey.clientId, value)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${searchParams.toString()}`,
    })

    if (
      !this.customColumnDic['customItemsInfo_' + this.currentSelectClientId]
    ) {
      reqFetchCustomColumns({
        clientId: this.currentSelectClientId,
      })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            data.data.columns.sort((item1, item2) => {
              if (item1.column_position === item2.column_position) {
                return item1.column_order > item2.column_order
              } else {
                return item1.column_position > item2.column_position
              }
            })
            this.customColumnDic['customItemsInfo_' + value] = data.data.columns
            this.resetColumns(this.currentSearchClientId)
          }
        })
        .catch((error) => {})
    }

    if (!this.trademarksInfo['trademarksInfo_' + this.currentSelectClientId]) {
      reqSearchTrademarks({ clientId: this.currentSelectClientId })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            this.trademarksInfo['trademarksInfo_' + value] =
              data.data.trademarks
          }
        })
        .catch((error) => {})
    }

    if (!this.productsInfo['productsInfo_' + this.currentSelectClientId]) {
      reqFetchProductCategories({
        clientId: this.currentSelectClientId,
      })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            this.productsInfo['productsInfo_' + value] = data.data.categories
          }
        })
        .catch((error) => {})
    }

    if (!this.reportsInfo['reportsInfo_' + this.currentSelectClientId]) {
      reqFetchReports({ clientId: this.currentSelectClientId })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            this.reportsInfo['reportsInfo_' + value] = data.data.reports
          }
        })
        .catch((error) => {})
    }
  }

  handleSearchErrorRecordChanged = (e) => {
    this.filterErrorRecord = e.target.checked
    let searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set(
      ECListCommon.ParamsKey.filterErrorRecord,
      this.filterErrorRecord ? 1 : 0
    )
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }

  showClientSelector = () => {
    if (this.props.role & 2) {
      let searchParams = new URLSearchParams(this.props.location.search)
      let filterErrorRecord = searchParams.get(
        ECListCommon.ParamsKey.filterErrorRecord
      )
      return (
        <>
          <div className="client-selector-container">
            <p className="client-title">
              <FormattedMessage id="app.ui.dashboard.search.brand" />
            </p>
            <Select
              allowClear
              style={{ width: 200 }}
              onChange={this.handleClientChanged}
              defaultValue={this.currentSelectClientId}>
              {this.state.clients.map((client) => {
                return (
                  <Select.Option
                    value={client.client_id}
                    key={client.client_id}>
                    {client.client_name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <div className="client-selector-container">
            <Checkbox
              onChange={this.handleSearchErrorRecordChanged}
              defaultChecked={filterErrorRecord === '1' ? true : false}>
              異常レコード検索
            </Checkbox>
          </div>
        </>
      )
    } else {
    }
  }

  onFilterCheckedRecord = () => {
    // if (this.state.selectRowKeys && this.state.selectRowKeys.length > 0) {
    //     let searchParams = new URLSearchParams(this.props.location.search);
    //     let currentFilters = JSON.parse(decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.filters)))
    //     if (currentFilters) {
    //         currentFilters.push({
    //             key: "ec_id",
    //             values: this.state.selectRowKeys
    //         })
    //     } else {
    //         currentFilters = [{
    //             key: "ec_id",
    //             values: this.state.selectRowKeys
    //         }]
    //     }
    //     searchParams.set(ECListCommon.ParamsKey.filters, encodeURIComponent(JSON.stringify(currentFilters)));
    //     window.open(`${this.props.location.pathname}?${searchParams.toString()}`);
    // }
  }

  onExportTable = () => {
    if (!this.currentSearchClientId) {
      return
    }
    let dateFields = this.currentSearchConditions.dateFields
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.dateFields)
        )
      : null //["xxxx","xxxx"]
    let startDate = this.currentSearchConditions.startDate
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.startDate)
        )
      : null // yyyy-MM-dd
    let endDate = this.currentSearchConditions.endDate
      ? encodeURIComponent(JSON.stringify(this.currentSearchConditions.endDate))
      : null // yyyy-MM-dd
    let wordFields = this.currentSearchConditions.wordFields
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.wordFields)
        )
      : null //["xxx","xxxx"]
    let keyword = this.currentSearchConditions.keyword
      ? encodeURIComponent(JSON.stringify(this.currentSearchConditions.keyword))
      : null // xxxx
    let searchType = this.currentSearchConditions.searchType
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.searchType)
        )
      : null // 1,2,3
    let whereFields = this.currentSearchConditions.whereFields
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.whereFields)
        )
      : null //[{key:xxx,values:[xxxx]}]
    let orderFields = this.currentSearchConditions.orderFields
      ? encodeURIComponent(
          JSON.stringify(this.currentSearchConditions.orderFields)
        )
      : null //[{key:xxx,values:xxx}]

    var searchParams = new URLSearchParams('?')
    if (dateFields) {
      searchParams.set('dateFields', dateFields)
    }

    if (startDate) {
      searchParams.set('startDate', startDate)
    }

    if (endDate) {
      searchParams.set('endDate', endDate)
    }

    if (wordFields) {
      searchParams.set('wordFields', wordFields)
    }

    if (keyword) {
      searchParams.set('keyword', keyword)
    }

    if (searchType) {
      searchParams.set('searchType', searchType)
    }

    if (whereFields) {
      searchParams.set('whereFields', whereFields)
    }

    if (orderFields) {
      searchParams.set('orderFields', orderFields)
    }

    if (this.currentSearchClientId) {
      searchParams.set('clientId', this.currentSearchClientId)
    }
    if (this.currentSearchConditions.noticeId) {
      searchParams.set('noticeId', this.currentSearchConditions.noticeId)
    }

    window.open(
      `${
        process.env.REACT_APP_BASE_API
      }/api/ec-data/export-search-result?${searchParams.toString()}`
    )
  }

  showColumnList = () => {
    this.updateSelectedShowColumns(
      this.showColumnsInfo[this.currentSearchClientId]
    )
    this.setState({ shouldShowColumnModal: true })
  }

  onBatchUpdate = (value) => {
    if (!this.state.selectRowKeys || this.state.selectRowKeys.length === 0) {
      message.error('選択されたレコードがありません。')
    } else {
      if (value !== undefined && value !== null) {
        const hide = message.loading('更新中', 0)
        if (this.props.role & 2) {
          reqBatchUpdateCategory({
            clientId: this.currentSearchClientId,
            recordIds: this.state.selectRowKeys,
            commoncategoryId: value,
          })
            .then((response) => {
              const { data } = response
              // console.log(data)
              if (data.code === 0) {
                if (hide) {
                  hide()
                }
                message.success(
                  `${data.data.updateCount}アイテム情報を更新しました。`
                )
                let searchParams = new URLSearchParams(
                  this.props.location.search
                )
                let conditions =
                  ECListCommon.converSearchParmasToConditions(searchParams)
                this.handleSearch(conditions)
                this.setState({ selectRowKeys: [] })
              } else {
                message.error('更新失敗しました。')
              }
            })
            .catch((e) => {
              if (hide) {
                hide()
              }
              message.error('更新失敗しました。')
            })
        } else {
          reqBatchUpdateAction({
            clientId: this.currentSearchClientId,
            recordIds: this.state.selectRowKeys,
            action: value,
          })
            .then((response) => {
              const { data } = response
              // console.log(data)
              if (data.code === 0) {
                if (hide) {
                  hide()
                }
                message.success(
                  `${data.data.updateCount}アイテム情報を更新しました。`
                )
                let searchParams = new URLSearchParams(
                  this.props.location.search
                )
                let conditions =
                  ECListCommon.converSearchParmasToConditions(searchParams)
                this.handleSearch(conditions)
                this.setState({ selectRowKeys: [] })
              } else {
                message.error('更新失敗しました。')
              }
            })
            .catch((e) => {
              if (hide) {
                hide()
              }
              message.error('更新失敗しました。')
            })
        }
        // Dismiss manually and asynchronouslÏy
        setTimeout(hide, 10 * 1000)
      } else {
        message.error('アップデータ内容を選択してください。')
      }
    }
  }

  // change display columns
  commitChangeShowColumns = () => {
    let keys = this.changeColumnFormRef.current.getFieldValue('showColumns')
    this.showColumnsInfo[this.currentSearchClientId] = keys
    this.setState({ showColumns: keys, shouldShowColumnModal: false })
  }

  onCheckAllChange = (e) => {
    if (e.target.checked) {
      this.updateSelectedShowColumns(
        this.getBasicColumnsWithCustom(this.currentSearchClientId).map(
          (item) => item.key
        )
      )
    } else {
      this.updateSelectedShowColumns([])
    }
  }

  onCheckBoxChange = (values) => {
    this.updateCheckAllBoxStateBySelectColumns(values)
  }

  cancelChangeShowColumns = () => {
    this.setState({ shouldShowColumnModal: false })
  }

  updateSelectedShowColumns = (showColumns) => {
    if (this.changeColumnFormRef.current && showColumns) {
      this.changeColumnFormRef.current.setFieldsValue({
        showColumns: showColumns,
      })
      this.updateCheckAllBoxStateBySelectColumns(showColumns)
    }
  }

  updateCheckAllBoxStateBySelectColumns = (values) => {
    if (values) {
      if (
        values.length ===
        this.getBasicColumnsWithCustom(this.currentSearchClientId).length
      ) {
        this.setState({ indeterminate: false, isCheckedAll: true })
      } else if (values.length === 0) {
        this.setState({ indeterminate: false, isCheckedAll: false })
      } else {
        this.setState({ indeterminate: true, isCheckedAll: false })
      }
    }
  }

  onRowSelect = (record, selected, selectedRows, nativeEvent) => {
    // console.log(record, selected)
    let newSelectRowKeys = this.state.selectRowKeys
      ? [...this.state.selectRowKeys]
      : []
    if (selected) {
      newSelectRowKeys.push(record.ec_id)
      this.setState({ selectRowKeys: newSelectRowKeys })
    } else {
      let unselectIndex = newSelectRowKeys.findIndex(
        (item) => item === record.ec_id
      )
      newSelectRowKeys = newSelectRowKeys
        .slice(0, unselectIndex)
        .concat(newSelectRowKeys.slice(unselectIndex + 1))
      this.setState({ selectRowKeys: newSelectRowKeys })
    }
  }

  onRowSelectAll = (selected, selectedRows, changeRows) => {
    let ec_ids = changeRows.map((record) => {
      return record.ec_id
    })
    let newSelectRowKeys = this.state.selectRowKeys
      ? [...this.state.selectRowKeys]
      : []
    if (selected) {
      newSelectRowKeys = [...new Set(newSelectRowKeys.concat(ec_ids))]
      this.setState({ selectRowKeys: newSelectRowKeys })
    } else {
      newSelectRowKeys = newSelectRowKeys.filter((ecId) => {
        return ec_ids.findIndex((id) => id === ecId) === -1
      })
      this.setState({ selectRowKeys: newSelectRowKeys })
    }
  }

  getBatchUpdateItems = () => {
    if (this.props.role & 2) {
      return InfringementCommonCategories.map((item) => {
        return {
          label: item.name.ja,
          value: item.id,
          key: item.id,
        }
      })
    } else {
      return TakedownRequest.map((item) => {
        return {
          label: item.name.en,
          value: item.value,
          key: item.value,
        }
      })
    }
  }

  render() {
    document.title = this.props.intl.formatMessage(messages['eclist.title'])
    const columns = this.getBasicColumnsWithCustom(
      this.currentSearchClientId
    ).filter((column) => {
      return this.state.showColumns.some((key) => {
        return column.key === key
      })
    })

    let mainUI = (
      <>
        <div className="ec-list-container">
          <div className="collapse-container">
            <Collapse
              bordered={true}
              defaultActiveKey={1}
              onChange={(value) => this.onCollapseChange(value)}>
              <Panel
                header={<FormattedMessage id="app.ui.wslist.search.collapse" />}
                key="1">
                {this.showClientSelector()}
                <EFCSearchBar
                  onSearch={(conditions) => this.onSearchClick(conditions)}
                  onReset={this.handleReset}
                  dateItems={getDateSearchItems(this.props.intl)}
                  keywordItems={getKeywordSearchItems(this.props.intl)}
                  defaultValues={this.currentSearchConditions}
                />
              </Panel>
            </Collapse>
          </div>

          <div className="result-table" id="ecTableHeader">
            <EFCTableHeader
              key="ecTableHeader"
              onSaveClick={this.onExportTable}
              onShowColumnListClick={this.showColumnList}
              total={this.state.total}
              errorCount={this.props.role & 2 ? this.errorRecordCount : null}
              onFilterCheckedRecord={this.onFilterCheckedRecord}
              showBatchContainer={true}
              onBatchUpdate={this.onBatchUpdate}
              batchValueOptions={this.getBatchUpdateItems()}
            />
            <EFResizableTable
              key="ecListTable"
              bordered
              tableLayout="fixed"
              rowSelection={{
                type: 'checkbox',
                onChange: this.handleRowSelection,
                onSelect: this.onRowSelect,
                onSelectAll: this.onRowSelectAll,
                getCheckboxProps: (record) => ({
                  name: record.ec_id,
                }),
                selectedRowKeys: this.state.selectRowKeys,
              }}
              rowKey={(record) => {
                return record.ec_id
              }}
              rowClassName={(record) => {
                if (this.props.role & 2 && isErrorRecord(record)) {
                  return 'ec-list-error'
                } else {
                  return ''
                }
              }}
              columns={columns}
              dataSource={this.state.dateSource}
              onChange={this.onTableValueChange}
              size="small"
              pagination={{
                showQuickJumper: true,
                total: this.state.total,
                pageSizeOptions: [10, 20, 50, 100, 200, 500],
                defaultPageSize: 20,
                showSizeChanger: true,
                itemRender: (page, type, originalElement) => {
                  if (type === 'prev') {
                    // eslint-disable-next-line jsx-a11y/no-access-key
                    return (
                      <Button
                        type="button"
                        className="ant-pagination-item-link"
                        icon={<LeftOutlined />}
                        accesskey="P"></Button>
                    )
                  } else if (type === 'next') {
                    // eslint-disable-next-line jsx-a11y/no-access-key
                    return (
                      <Button
                        type="link"
                        className="ant-pagination-item-link"
                        icon={<RightOutlined />}
                        accesskey="N"></Button>
                    )
                  } else {
                    return originalElement
                  }
                },
              }}
              scroll={{
                x: 'max-content',
                y: this.state.tableScrollY,
              }}
            />
          </div>

          <Form noStyle={true} ref={this.changeColumnFormRef}>
            <Modal
              title={<FormattedMessage id="app.ui.eclist.column_modal.title" />}
              visible={this.state.shouldShowColumnModal}
              onOk={this.commitChangeShowColumns}
              onCancel={this.cancelChangeShowColumns}
              footer={
                <div>
                  <Checkbox
                    indeterminate={this.state.indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={this.state.isCheckedAll}>
                    <FormattedMessage id="app.components.data_filter.check_all" />
                  </Checkbox>
                  <Button key="back" onClick={this.cancelChangeShowColumns}>
                    <FormattedMessage id="app.components.data_filter.cancel" />
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={this.commitChangeShowColumns}>
                    <FormattedMessage id="app.components.data_filter.submit" />
                  </Button>
                </div>
              }>
              <div className="ec-list-view-listContainer">
                <Form.Item name="showColumns" noStyle={true}>
                  <Checkbox.Group>
                    <List
                      dataSource={this.getBasicColumnsWithCustom(
                        this.currentSearchClientId
                      )}
                      split={false}
                      size="small"
                      onChange={this.onCheckBoxChange}
                      renderItem={(item) => {
                        return (
                          <List.Item key={item.key} className="list-item">
                            <Checkbox value={item.key}>{item.title}</Checkbox>
                          </List.Item>
                        )
                      }}></List>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Modal>
          </Form>
        </div>
        <ECRecordHistory
          onClose={this.onHistoryClose}
          visible={this.state.historyVisible}
          history={this.state.currentHistory}
          historyLoading={this.state.historyLoading}
          clientId={this.currentSearchClientId}
          recordId={
            this.currentHistoryRecord ? this.currentHistoryRecord.ec_id : null
          }
          config={{
            currentSearchClientId: this.currentSearchClientId,
            allProductsInfo: this.productsInfo,
            categorysInfo: this.state ? this.state.categorysInfo : null,
            allTrademarksInfo: this.trademarksInfo,
            currentSelectClientId: this.currentSelectClientId,
          }}></ECRecordHistory>
      </>
    )

    if (this.state.isLoading) {
      return <Spin>{mainUI}</Spin>
    } else {
      return mainUI
    }
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.user,
  }
}

export default connect(mapStateToProps)(injectIntl(ECRecordList))
