// ec-list intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    language: {
        id: "app.layout.header.language",
        defaultMessage: "English",
        description: 'locale setting',

    },
})

export default messages