
import React, { useCallback, useEffect, useRef, useState } from "react"

import { Button, Upload, Modal, Image } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import { reqUploadTrademarkImage, reqRemoveTrademarkImage } from "@/api/trademark.js"
import '../index.less'
import { FormattedMessage } from "react-intl"

const KEY_DELETED = "_deleted"

const TradeMarkPictureUploader = (props) => {

    const [imageName, setImageName] = useState()

    const previewEvidencePicture = useRef()
    const previewVisible = useRef()
    const previewTitle = useRef()
    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const uploadImage = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {
        if (props.beforeUpload) {
            props.beforeUpload()
        }
        const formData = new FormData()
        if (props.clientId) {
            formData.append("clientId", props.clientId)
        }
        if (props.trademarkId) {
            formData.append("trademarkId", props.trademarkId)
        }
        formData.append("file", file)

        reqUploadTrademarkImage(
            formData,
            (total, loaded) => {
                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    onSuccess(response, file)
                    // this.setState({ imageName: data.data.name });
                    setImageName(data.data.name)
                }
                if (props.onFinishUpload) {
                    props.onFinishUpload(data.data)
                }
            }).catch((error) => {
                onError(error)
                if (props.onFinishUpload) {
                    props.onFinishUpload({})
                }
            })
    }

    const onHandlePreview = async file => {
        if (!file.url && !file.preview) {
            return
        }
        // this.setState({
        //     previewEvidencePicture: file.url || file.preview,
        //     previewVisible: true,
        //     previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        // })
        previewEvidencePicture.current = file.url || file.preview
        previewVisible.current = true
        previewTitle.current = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        toggleLoading()
    }

    const onHandleChange = (values) => {
        if (values.file.status === "removed") {
            // values.file.uid
            // this.setState({ imageName: KEY_DELETED })
            setImageName(KEY_DELETED)
            let parameter = {}
            if (props.trademarkId) {
                parameter.trademarkId = props.trademarkId
            }
            if (props.clientId) {
                parameter.clientId = props.clientId
            }
            reqRemoveTrademarkImage({
                name: values.file.uid,
                ...parameter
            }).then((response) => {
                let { data } = response
                if (data.code === 0) {
                    if (props.onRemove) {
                        props.onRemove(data.data)
                    }
                }
            }).catch((e) => {

            })
        }
    }

    const onHandleCloseModal = () => {
        previewVisible.current = false
        toggleLoading()
    }

    const onHandleDownloadPic = () => {
        window.open(previewEvidencePicture.current)
        previewVisible.current = false
        toggleLoading()
    }

    useEffect(() => {
        if (props.imageName) {
            setImageName(props.imageName)
        }
    }, [props])

    let fileList = []
    let imageNameCurrent = imageName ? imageName : props.imageName
    if (imageNameCurrent && imageNameCurrent !== KEY_DELETED) {
        let url = `${process.env.REACT_APP_BASE_API}/api/trademark/get-trademark-image?name=${imageName}`
        if (props.clientId) {
            url = `${process.env.REACT_APP_BASE_API}/api/trademark/get-trademark-image?clientId=${props.clientId}&name=${imageName}`
        }
        fileList = [
            {
                uid: imageNameCurrent,
                name: imageNameCurrent,
                status: 'done',
                url: url
            }
        ]
    }
    return <>
        <Upload
            className="avatar-uploader"
            accept=".png,.jpg,.jpeg"
            listType="picture-card"
            fileList={fileList}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: true }}
            customRequest={uploadImage}
            onPreview={onHandlePreview}
            onChange={onHandleChange}
            maxCount={1}
        >
            <div>
                <PlusOutlined />
                <p className="ef-title-normal ef-upload-desc"><FormattedMessage id="app.common.action.upload" /></p>
            </div>
            <Modal
                visible={previewVisible.current}
                title={previewTitle.current}
                onCancel={onHandleCloseModal}
                onOk={onHandleCloseModal}
                destroyOnClose={true}
                footer={[
                    <Button key="downloadimg" onClick={onHandleDownloadPic}>
                        <FormattedMessage id="app.ui.trademark.imageupload.download" />
                    </Button>,
                    <Button key="submit" type="primary" onClick={onHandleCloseModal}>
                        <FormattedMessage id="app.ui.trademark.imageupload.submit" />
                    </Button>,
                ]}
            >
                <Image style={{ width: '100%' }} src={previewEvidencePicture.current} />
            </Modal>
        </Upload>
    </>
}
export default TradeMarkPictureUploader