import "./index.less"
import React, { Component, useCallback, useEffect, useRef, useState } from "react"
import { Row, Col, Input, Card, Form, Select, message } from "antd"
import { ModalForm } from '@ant-design/pro-form'

import { reqCreateInfringementCategory, reqCreateInfringementSubcategory, reqFetchInfringementCategories, reqRemoveInfringementCategory, reqRemoveInfringementSubcategory, reqUpdateInfringementCategory, reqUpdateInfringementSubcategory } from '../../../api/analyst-infringement-category'
import store from "@/store"
import EFCMasterTable from "@/components/ef-c-master-table"
import { InfringementCommonCategories } from "@/config/infringement-common-category.js"

import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import { ProFormText, ProFormSelect } from '@ant-design/pro-form'
import EFCContainer from '../../../components/ef-c-container'
import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages"

const AnalystInfringementCategory = () => {

    const intl = useIntl()

    const subCategories = useRef([])
    const [selectRow, setSelectRow] = useState()
    const mainCategories = useRef([])
    const mainCateState = useRef({})
    const mainCateEditValue = useRef({ name: '', name_en: '' })
    const FormRef = useRef()

    const editRowDictionary = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const getSubTableEditFieldsValue = (record) => {
        let row = {}
        row["name_" + record.id] = record.name
        row["name_en_" + record.id] = record.name_en
        row["code_" + record.id] = record.code
        row["commonCategory_" + record.id] = record.commonCategory
        return row
    }

    const getSubTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    const onHandleSubRemoveMainCategory = (record) => {
        if (selectRow !== null && selectRow !== undefined) {
            reqRemoveInfringementSubcategory({
                categoryId: selectRow,
                subcategoryId: record.id,
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    // let temp = [...this.state.mainCategories]
                    let temp = [...mainCategories.current]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories
                    subCategories.current = data.data.categories
                    mainCategories.current = temp
                    message.success(intl.formatMessage(messages['message.info.sub_category.delete_success']))
                    toggleLoading()
                }
            }).catch((e) => {
                message.error(intl.formatMessage(messages['message.error.sub_category.delete_fail']))
            })
        }
    }

    const onHandleSubUpdateMainCategory = (record, values) => {
        if (selectRow !== null && selectRow !== undefined) {
            reqUpdateInfringementSubcategory({
                categoryId: selectRow,
                subcategoryId: record.id,
                subcategoryName: values["name_" + record.id],
                subcategoryNameEn: values["name_en_" + record.id],
                subcategoryCode: values["code_" + record.id],
                commonCategory: parseInt(values["commonCategory_" + record.id]),
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    let temp = [...mainCategories.current]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories
                    subCategories.current = data.data.categories
                    mainCategories.current = temp
                    message.success(intl.formatMessage(messages['message.info.sub_category.update_success']))
                    toggleLoading()
                }
            }).catch((e) => {
                message.error(intl.formatMessage(messages['message.error.sub_category.update_fail']))
            })
        }
    }

    const onHandleSubCreateMainCategory = (values) => {
        if (selectRow !== null && selectRow !== undefined) {
            reqCreateInfringementSubcategory({
                subcategoryName: values.name,
                subcategoryNameEn: values.name_en,
                subcategoryCode: values.code,
                commonCategory: parseInt(values.commonCategory),
                categoryId: selectRow,
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    let temp = [...mainCategories.current]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories

                    subCategories.current = data.data.categories
                    mainCategories.current = temp
                    message.success(intl.formatMessage(messages['message.info.sub_category.create_success']))
                    toggleLoading()
                }
            }).catch((e) => {
                message.error(intl.formatMessage(messages['message.error.sub_category.create_fail']))
            })

        }
    }

    const onHandleMainCate = (item) => {
        if (item.id !== selectRow) {
            mainCategories.current.map((item) => {
                mainCateState.current[item.id] = false
            })
            subCategories.current = item.subcategories
            setSelectRow(item.id)
        }
    }

    const onHandleCreateSubCate = (values) => {
        reqCreateInfringementCategory({
            name: values.name,
            nameEn: values.name_en,
            code: values.code
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                mainCategories.current = data.data.categories
                message.success(intl.formatMessage(messages['message.info.main_category.create_success']))
                toggleLoading()
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.info.main_category.create_fail']))
        })
    }

    const onHandleEditMainCate = (item) => {
        mainCateState.current[item.id] = !mainCateState.current[item.id]
        toggleLoading()
    }

    const onHandleUpdateMainCate = (item, values) => {
        reqUpdateInfringementCategory({
            categoryId: item.id,
            name: values['name'],
            nameEn: values["name_en"],
            // code: values["code_" + record.id]
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                mainCategories.current = data.data.categories
                mainCategories.current.map((item) => {
                    mainCateState.current[item.id] = false
                })
                mainCateEditValue.current = { name: '', name_en: '' }
                message.success(intl.formatMessage(messages['message.info.main_category.update_success']))
                toggleLoading()
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.main_category.update_fail']))
        })
    }

    const onHandleRemoveMainCate = (item) => {
        reqRemoveInfringementCategory({
            categoryId: item.id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                mainCategories.current = data.data.categories
                toggleLoading()
                message.success(intl.formatMessage(messages['message.info.main_category.delete_success']))
            }
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.main_category.delete_fail']))
        })
    }

    const onHandleCancelMainCate = (item) => {
        mainCateState.current[item.id] = !mainCateState.current[item.id]
        toggleLoading()
    }

    const renderMainCategoryColumns = () => {
        return [
            {
                title: <FormattedMessage id="app.ui.infringement_category.name" />,
                dataIndex: 'name',
                key: 'name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.main_category.name_empty']),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            }, {
                title: <FormattedMessage id="app.ui.infringement_category.name_en" />,
                dataIndex: 'name_en',
                key: 'name_en',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.main_category.name_en_empty']),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
            // {
            //     title: 'コード',
            //     dataIndex: 'code',
            //     key: 'code',
            //     editCell: (text, record) => {
            //         return (
            //             <Form.Item
            //                 noStyle={true}
            //                 name={"code_" + record.id}
            //                 rules={[
            //                     {
            //                         type: "string",
            //                         required: true,
            //                         message: "コードを入力ください",
            //                     }
            //                 ]}>
            //                 <Input></Input>
            //             </ Form.Item >
            //         )
            //     }
            // },
        ]
    }

    const renderSubCategoryColumns = () => {
        return [
            {
                title: <FormattedMessage id="app.ui.infringement_category.subcate.name" />,
                dataIndex: 'name',
                key: 'name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.sub_category.name_empty']),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: <FormattedMessage id="app.ui.infringement_category.subcate.name_en" />,
                dataIndex: 'name_en',
                key: 'name_en',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.sub_category.name_en_empty'])
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: <FormattedMessage id="app.ui.infringement_category.subcate.code" />,
                width: 100,
                dataIndex: 'code',
                key: 'code',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"code_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.sub_category.code_empty']),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.infringement_category.subcate.commonCategory" />,
                dataIndex: 'commonCategory',
                key: 'commonCategory',
                width: 200,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"commonCategory_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.sub_category.commoncategory_empty']),
                                }
                            ]}>
                            <Select allowClear
                                style={{ width: 200 }}
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false
                                }}>
                                {
                                    InfringementCommonCategories.map((category) => {
                                        return <Select.Option key={`${category.name.ja}${category.name.en}`} value={category.id}>{category.name.ja}</Select.Option>
                                    })
                                }
                            </Select>
                        </ Form.Item >
                    )
                },
                renderText: (record) => {
                    if (record.commonCategory) {
                        let category = InfringementCommonCategories.find(category => category.id === record.commonCategory)
                        return category ? category.name.ja : ""
                    }
                    return ""

                }
            },

        ]
    }

    const renderSubModalItems = () => {
        return (
            <>
                <ProFormText name="name" label={<FormattedMessage id="app.ui.infringement_category.subcate.name" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.sub_category.name_empty']),
                    },
                ]}
                />
                <ProFormText name="name_en" label={<FormattedMessage id="app.ui.infringement_category.subcate.name_en" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.sub_category.name_en_empty']),
                    },
                ]}
                />
                <ProFormText name="code" label={<FormattedMessage id="app.ui.infringement_category.subcate.code" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.sub_category.code_empty']),
                    },
                ]}
                />
                <ProFormSelect
                    showSearch
                    fieldProps={{
                        optionFilterProp: "children",
                        filterOption: (inputValue, option) => {
                            return option.key.indexOf(inputValue) > -1 ? true : false
                        }
                    }}

                    name="commonCategory"
                    options={
                        InfringementCommonCategories.map((category) => {
                            return {
                                value: category.id,
                                label: category.name.ja,
                                key: `${category.name.ja}${category.name.en}`,
                            }
                        })
                    }
                    label={<FormattedMessage id="app.ui.infringement_category.subcate.commonCategory" />}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage(messages['message.error.sub_category.commoncategory_empty']),
                        },
                    ]}
                />
            </>
        )
    }

    const renderMainModalItems = () => {
        return (
            <>
                <ProFormText name="name" label={<FormattedMessage id="app.ui.infringement_category.name" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.main_category.name_empty'])
                    },
                ]}
                />
                <ProFormText name="name_en" label={<FormattedMessage id="app.ui.infringement_category.name_en" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.main_category.name_en_empty']),
                    },
                ]}
                />
                {/* <ProFormText width="md" name="code" label="コード" rules={[
                    {
                        required: true,
                        message: 'コードを入力ください。',
                    },
                ]}
                /> */}
            </>
        )
    }

    const renderTable = () => {
        return <div className="master-brand-content" style={{ width: '100%' }}>
            <div className="master-brand-wrap-content" style={{ width: '100%', padding: '20px' }}>
                {/* <Row>
                    <Col span={24}> */}
                <EFCMasterTable
                    columns={renderSubCategoryColumns()}
                    dataSource={subCategories.current}
                    tableProps={getSubTableProps()}
                    modalTitle={<FormattedMessage id="app.ui.infringement_category.subcate.modal.title" />}
                    onRemoveClick={onHandleSubRemoveMainCategory}
                    getEditFieldsValue={getSubTableEditFieldsValue}
                    onSaveClick={onHandleSubUpdateMainCategory}
                    onModalFinished={onHandleSubCreateMainCategory}
                    modalItems={renderSubModalItems()}
                />
                {/* </Col>
                </Row> */}
            </div>
        </div>
    }

    const renderBrandAdd = () => {
        return <div className="master-brand-menu-add">
            <ModalForm
                className="ef-modalform-single-col"
                formRef={FormRef}
                title={<p className="ef-text-bold"><FormattedMessage id="app.ui.infringement_category.modal.title" /></p>}
                trigger={
                    <p><FormattedMessage id="app.common.action.add" /></p>
                }
                modalProps={{
                    onCancel: () => {
                        FormRef.current?.resetFields()
                    },
                }}
                onFinish={(values) => {
                    onHandleCreateSubCate(values)
                    return true
                }}
                submitter={{
                    // 配置按钮文本
                    searchConfig: {
                        resetText: intl.formatMessage({ id: 'app.common.action.cancel' }),
                        submitText: intl.formatMessage({ id: 'app.common.action.confirm' }),
                    },
                }}
            >
                {renderMainModalItems()}
            </ModalForm >
        </div>
    }

    const renderBrandMenu = () => {
        if (mainCategories.current.length === 0) {
            return <div className="empty-content">
                <FormattedMessage id="app.ui.infringement_category.content_empty" />
            </div>
        }
        return mainCategories.current.map((item, index) => {
            if (item.id === selectRow) {
                if (!mainCateState.current[item.id]) {
                    return <div className="master-brand-menu-item active">
                        <div className="flex-center">
                            <p className="menu-title">{item.name}</p>
                            <p className="menu-subtitle">{item.name_en}</p>
                        </div>
                        <div className="master-brand-item-trigger">
                            <p className="trigger-item" onClick={() => { onHandleEditMainCate(item) }}><FormattedMessage id="app.common.action.edit" /></p>
                        </div>
                    </div>
                } else {
                    mainCateEditValue.current['name'] = item.name
                    mainCateEditValue.current['name_en'] = item.name_en
                    return <div className="master-brand-item-edit">
                        <p className="ef-text-normal edit-item"><FormattedMessage id="app.ui.infringement_category.name" /></p>
                        <Input defaultValue={item.name} onChange={(e) => { mainCateEditValue.current['name'] = e.target.value }} className="ef-form-element-normal edit-input-item" />
                        <p className="ef-text-normal edit-item"><FormattedMessage id="app.ui.infringement_category.name_en" /></p>
                        <Input defaultValue={item.name_en} onChange={(e) => { mainCateEditValue.current['name_en'] = e.target.value }} className="ef-form-element-normal edit-input-item" />
                        <div className="master-brand-item-trigger">
                            <p className="trigger-item" onClick={() => { onHandleUpdateMainCate(item, mainCateEditValue.current) }}><FormattedMessage id="app.common.action.save" /></p>
                            <p className="trigger-item" onClick={() => { onHandleRemoveMainCate(item) }}><FormattedMessage id="app.common.action.delete" /></p>
                            <p className="trigger-item" onClick={() => { onHandleCancelMainCate(item) }}><FormattedMessage id="app.common.action.cancel" /></p>
                        </div>
                    </div>
                }
            } else {
                return <div className="master-brand-menu-item flex-center" onClick={() => { onHandleMainCate(item) }}>
                    <p className="menu-title">{item.name}</p>
                    <p className="menu-subtitle">{item.name_en}</p>
                </div>
            }
        })
    }

    const renderTitle = () => {
        return <div className="ef-infringement-category-title">
            <span className="ef-title-colorbar"></span>
            <p className="ef-title-normal ef-title-desc"><FormattedMessage id="app.ui.infringement_category.name" /></p>
        </div>
    }

    const renderContent = () => {
        return <div className="ef-infringement-category-content">
            <div className="ec-menu ef-text-normal">
                {renderBrandMenu()}
                {renderBrandAdd()}
            </div>
            <div className="ec-content">
                {renderTable()}
            </div>
        </div>

    }

    const renderInfringementCategory = async () => {
        await reqFetchInfringementCategories().then((response) => {
            const { data } = response
            if (data.code === 0) {
                mainCategories.current = data.data.categories
            }
        }).catch((error) => {

        })
        if (mainCategories.current.length > 0 && mainCategories.current[0].id) {
            subCategories.current = mainCategories.current[0].subcategories
            setSelectRow(mainCategories.current[0].id)
        }
        toggleLoading()
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("analyst-infringement-category"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.category_standard" />,
                path: "/analyst/infringement-category",
            },
        ]))
    }

    useEffect(() => {
        renderBread()
        renderInfringementCategory()
    }, [])

    document.title = intl.formatMessage({ id: "app.ui.infringement_category.title" })
    return (
        <EFCContainer
            overspread={0}
            style={{ padding: '32px' }}
            className="ef-infringement-category-container">
            {renderTitle()}
            {renderContent()}
        </EFCContainer>
    )

}
export default AnalystInfringementCategory
