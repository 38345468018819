import { Button, Popconfirm, Space, Tag, Popover, Dropdown, Modal } from "antd";
import { DownOutlined } from '@ant-design/icons';

import { ECCrawlerPurpose, ECCrawlerStatus } from "../common/master-data.js"
import moment from 'moment';

import ExportButton from "./export-button.js"

export const getColumns = (config) => {

    const genItemsWithRecord = (record) => {
        const items = [
            {
                key: '1',
                label: <a>削除</a>,
                disabled: record.status === ECCrawlerStatus.scraying.value,
                onClick: () => {
                    Modal.confirm({
                        title: "削除しますか？",
                        okText: "はい",
                        cancelText: "いいえ",
                        onOk: (close) => {
                            if (config && config.onDeleteClick) {
                                config.onDeleteClick(record)
                            }
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })
                }
            },
            {
                key: '2',
                label: <a>スタート</a>,
                disabled: record.status !== ECCrawlerStatus.new.value && record.status !== ECCrawlerStatus.finished.value,
                onClick: () => {
                    if (config && config.onStartClick) {
                        config.onStartClick(record)
                    }
                }
            },
            {
                key: '3',
                label: <a>停止</a>,
                onClick: () => {
                    Modal.confirm({
                        title: "停止しますか？",
                        okText: "はい",
                        cancelText: "いいえ",
                        onOk: (close) => {
                            if (config && config.onStopClick) {
                                config.onStopClick(record)
                            }
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })

                }
            },
            {
                key: '4', label: <a>ダウンロード</a>,
                disabled: record.status !== ECCrawlerStatus.finished.value,
                children: Object.keys(ECCrawlerPurpose).map(key => {
                    return {
                        key: key,
                        label: ECCrawlerPurpose[key].text,
                        value: ECCrawlerPurpose[key].value,
                        onClick: () => {
                            if (config && config.onDownloadClick) {
                                config.onDownloadClick(record, ECCrawlerPurpose[key].value)
                            }
                        }
                    }
                }),
            },
            {
                key: '5', label: <a>クローン</a>,
                onClick: () => {
                    if (config && config.onCloneTask) {
                        config.onCloneTask(record)
                    }
                }

            }
        ]
        return items
    }

    return [
        {
            title: "状態",
            dataIndex: "status",
            key: "status",
            width: 180,
            fixed: true,
            showSorterTooltip: true,
            // sorter: true,
            // filters: Object.keys(ECCrawlerStatus).map(key => {
            //     return {
            //         text: ECCrawlerStatus[key].text,
            //         value: ECCrawlerStatus[key].value,
            //     }
            // }),
            // filterMultiple: true,
            render: (text, record) => {
                if (text === ECCrawlerStatus.scraying.value) {
                    return (<Tag color="processing">{ECCrawlerStatus.scraying.text}</Tag>)
                } else if (text === ECCrawlerStatus.finished.value) {
                    return (<Tag color="success">{ECCrawlerStatus.finished.text}</Tag>)
                } else if (text === ECCrawlerStatus.error.value) {
                    return (<Tag color="error">{ECCrawlerStatus.error.text}</Tag>)
                } else if (text === ECCrawlerStatus.pendding.value) {
                    return (<Tag color="processing">{ECCrawlerStatus.pendding.text}</Tag>)
                } else {
                    return (<Tag color="default">{ECCrawlerStatus.new.text}</Tag>)
                }
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_id.name, intl),
            title: "タスクID",
            dataIndex: "id",
            key: "taskid",
            width: 100,
            fixed: false,
            showSorterTooltip: false,
            sorter: true,
        },
        {
            title: "クライアント",
            dataIndex: "clientId",
            key: "clientId",
            width: 220,
            fixed: false,
            showSorterTooltip: false,
            sorter: true,
            filters: config && config["clients"] ? config["clients"] : null,
            filterMultiple: true,
            render: (text, record) => {
                const clients = config["clients"]
                if (clients) {
                    const obj = clients.find((client) => {
                        return client.value === text
                    })
                    return obj ? obj.text : text
                } else {
                    return text
                }
            }
        },
        {
            title: "タイトル",
            dataIndex: "title",
            key: "title",
            width: 180,
            fixed: false,
            showSorterTooltip: false,
            sorter: true,
        },
        {
            title: "タイプ",
            dataIndex: "type",
            key: "type",
            width: 240,
            fixed: false,
            filters: Object.keys(ECCrawlerPurpose).map(key => {
                return {
                    text: ECCrawlerPurpose[key].text,
                    value: ECCrawlerPurpose[key].value,
                }
            }),
            filterMultiple: true,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text === ECCrawlerPurpose.dashboard.value) {
                    return "情報抽出(ダッシュボード表示型)"
                } else if (text === ECCrawlerPurpose.monitor.value) {
                    return "情報抽出(プライスモニタリング形式)"
                } else {
                    return "情報抽出(ダウンロード型)"
                }
            }
        },
        {
            title: "開始日",
            dataIndex: "planStartDate",
            key: "planStartDate",
            width: 160,
            fixed: false,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text) {
                    return moment(text).format("YYYY-MM-DD")
                } else {
                    return ""
                }
            }
        },
        // {
        //     title: "完了日",
        //     dataIndex: "finishDate",
        //     key: "finishDate",
        //     width: 160,
        //     fixed: false,
        //     showSorterTooltip: false,
        //     sorter: true,
        //     render: (text, record) => {
        //         if (text) {
        //             return moment(text).format("YYYY-MM-DD")
        //         } else {
        //             return ""
        //         }
        //     }
        // },
        {
            title: "取得件数",
            dataIndex: "total",
            key: "total",
            width: 100,
            fixed: false,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text) {
                    return text
                } else {
                    if (record.subtasks) {
                        return record.subtasks.reduce((pre, subtask) => {
                            return pre + subtask.recordCount
                        }, 0)
                    } else {
                        return 0
                    }
                }
            }
        },
        {
            title: "備考",
            dataIndex: "remarks",
            key: "remarks",
            width: 300,
            fixed: false,
            showSorterTooltip: false,
            // sorter: true,
        },
        {
            title: "動作",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            showSorterTooltip: false,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {
                        if (config && config.onDetailClick) {
                            config.onDetailClick(record)
                        }
                    }
                    }>
                        詳細
                    </Button>
                    <Button type="link"
                        onClick={() => {
                            if (config && config.onSubtasks) {
                                config.onSubtasks(record)
                            }
                        }}
                    >
                        状態
                    </Button>
                    <Dropdown arrow={true} menu={{ items: genItemsWithRecord(record) }}>
                        <a>
                            動作 <DownOutlined />
                        </a>
                    </Dropdown>
                </Space>
            ),
        },
    ]
}
