import "./index.less"
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Table, message } from "antd";
import { connect } from "react-redux";
import { reqUpdateUserInfo } from "@/api/user.js"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import EFAnalystClientList from "@/views/analyst/client-list/backup.jsx"

import { setUserInfo } from "@/store/actions/user.js"
import moment from 'moment'
import { getCurrencyName } from "@/utils/currency.js"
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

class UpdateUserInfoBackup extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.role = []
        if (this.props.role & 4) {
            this.role.push("管理者")
        }
        if (this.props.role & 2) {
            this.role.push("アナリスト")
        }
        if (this.props.role & 1) {
            this.role.push("クライアント")
        }
        this.state = {
            isloading: false
        }
    }
    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("profile"));
        this.formRef.current.setFieldsValue({
            name: this.props.name,
            tel: this.props.tel,
        });

        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.ui.profile.action.reset_password" />,
            path: "/profile/reset-password",
        }]));

        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.profile.breadcrumb.profile" />,
                path: "/profile",
            },
        ]));
    }


    onFinish = (values) => {
        const { name, tel } = values;

        let body = {
            name,
            tel,
        }
        this.setState({ isloading: true });
        reqUpdateUserInfo(body).then((response) => {
            let { data } = response
            if (data.code === 0) {
                store.dispatch(setUserInfo(data.data.user))
                this.setState({ isloading: false });
                message.success(this.props.intl.formatMessage(messages["message.success.update_success"]));
            }
        }).catch((error) => {
            this.setState({ isloading: false });
            message.error(this.props.intl.formatMessage(messages["message.error.update_failed"]));
        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        if (messages[errors[0]]) {
            message.error(this.props.intl.formatMessage(messages[errors[0]]))
        } else {
            message.error(errors[0])
        }

    }

    getRoleUI = () => {
        if (this.props.role & 4 || this.props.role & 2) {
            return (
                <div className="item-wrapper">
                    <div className="item-label-container">
                        <p>ロール</p>
                    </div>
                    <div className="item-content-container">
                        <p>{this.role.join("、")}</p>
                    </div>
                </div>
            )
        }
    }

    showCompanyInfo = () => {
        if (this.props.companyInfo) {
            return (
                <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.section.company.title" />}>
                    <Row className="item-wrapper">
                        <Col>
                            <div className="item-label-container">
                                <p><FormattedMessage id="app.ui.profile.section.company.name" /></p>
                            </div>
                        </Col>
                        <Col>
                            <div className="item-content-container">{this.props.companyInfo.client_name}</div>
                        </Col>
                    </Row>
                    <Row className="item-wrapper">
                        <Col>
                            <div className="item-label-container">
                                <p><FormattedMessage id="app.ui.profile.section.company.tel" /></p>
                            </div>
                        </Col>
                        <Col>
                            <div className="item-content-container">{this.props.companyInfo.client_tel}</div>
                        </Col>
                    </Row>

                    <Row className="item-wrapper">
                        <Col>
                            <div className="item-label-container">
                                <p><FormattedMessage id="app.ui.profile.section.company.address" /></p>
                            </div>

                        </Col>
                        <Col>
                            <div className="item-content-container">{this.props.companyInfo.client_address}</div>
                        </Col>
                    </Row>
                    <Row className="item-wrapper">
                        <Col>
                            <div className="item-label-container">
                                <p><FormattedMessage id="app.ui.profile.section.company.remarks" /></p>
                            </div>
                        </Col>
                        <Col>
                            <div className="item-content-container">{this.props.companyInfo.client_remarks}</div>
                        </Col>
                    </Row>
                </EFSimpleContainer>
            )
        }
    }

    showAnalystInfo = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.profile.section.analyst.table.name" />,
                dataIndex: 'user_name',
                key: 'user_name',
                width: 150,
            },
            {
                title: <FormattedMessage id="app.ui.profile.section.analyst.table.mail" />,
                dataIndex: 'user_mail',
                key: 'user_mail',
                width: 250,

            },
            {
                title: <FormattedMessage id="app.ui.profile.section.analyst.table.tel" />,
                dataIndex: 'user_tel',
                key: 'user_tel',
                width: 150,
            },
        ]
        if (this.props.role & 1) {
            return (
                <Col span={24}>
                    <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.section.analyst.title" />}>
                        <Table
                            columns={columns}
                            dataSource={this.props.analystInfo}
                            size="small"
                            pagination={false}
                            scroll={{
                                x: 'max-content',
                            }}
                        />
                    </EFSimpleContainer>
                </Col>
            )
        }
    }

    getDateRange = (fromDate, toDate) => {
        if (fromDate && toDate) {
            let fromDateStr = moment(fromDate).format("YYYY-MM-DD")
            let toDateStr = moment(toDate).format("YYYY-MM-DD")
            return fromDateStr + "〜" + toDateStr

        }
        else {
            return ""
        }
    }

    getContractInfo = () => {
        return [
            {
                name: "EC Protection",
                from: this.props.companyInfo.service_ec_start_date,
                to: this.props.companyInfo.service_ec_end_date,
                fee: this.props.companyInfo.service_ec_fee,
                currency: this.props.companyInfo.service_ec_currency
            },
            {
                name: "SNS Protection",
                from: this.props.companyInfo.service_sns_start_date,
                to: this.props.companyInfo.service_sns_end_date,
                fee: this.props.companyInfo.service_sns_fee,
                currency: this.props.companyInfo.service_sns_currency
            },
            {
                name: "WS Protection",
                from: this.props.companyInfo.service_ws_start_date,
                to: this.props.companyInfo.service_ws_end_date,
                fee: this.props.companyInfo.service_ws_fee,
                currency: this.props.companyInfo.service_ws_currency
            },
            {
                name: "Other Protection",
                from: this.props.companyInfo.service_other_start_date,
                to: this.props.companyInfo.service_other_end_date,
                fee: this.props.companyInfo.service_other_fee,
                currency: this.props.companyInfo.service_other_currency
            }
        ]
    }
    
    showServiceInfoTable = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.profile.section.contract.table.name" />,
                dataIndex: 'name',
                key: 'name',
                width: 150,

            },
            {
                title: <FormattedMessage id="app.ui.profile.section.contract.table.duration_from" />,
                dataIndex: 'from',
                key: 'from',
                width: 150,
                render: (value, record) => {
                    if(value){
                        return moment(value).format("YYYY-MM-DD")
                    }
                },

            },
            {
                title: <FormattedMessage id="app.ui.profile.section.contract.table.duration_to" />,
                dataIndex: 'to',
                key: 'to',
                width: 150,
                render: (value, record) => {
                    if(value){
                        return moment(value).format("YYYY-MM-DD")
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.profile.section.contract.table.fee" />,
                dataIndex: 'fee',
                key: 'fee',
                width: 150,
                render:(value,record)=>{
                    return value ? value + " " + getCurrencyName(record.currency) : ""
                }
            },
        ]
        if (this.props.role & 1) {
            return <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.section.contract.title" />}>
                <Table
                    columns={columns}
                    dataSource={this.getContractInfo()}
                    size="small"
                    pagination={false}
                    scroll={{
                        x: 'max-content',
                    }}
                />
            </EFSimpleContainer>

        }
    }

    // showServiceInfo = () => {
    //     if (this.props.role & 1) {
    //         return (
    //             <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.section.contract.title" />}>
    //                 <div className="item-wrapper">
    //                     <Row className="date-row">
    //                         <Col span={4}>
    //                             EC Protection
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.duration" /></Col>
    //                         <Col span={12}>
    //                             <div className="item-content-container">
    //                                 <p>{this.getDateRange(this.props.companyInfo.service_ec_start_date, this.props.companyInfo.service_ec_end_date)}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col span={4}>
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.fee" /></Col>
    //                         <Col span={4}>
    //                             <div className="item-content-container">
    //                                 <p>{this.props.companyInfo.service_ec_fee ? this.props.companyInfo.service_ec_fee + " " + getCurrencyName(this.props.companyInfo.service_ec_currency) : ""}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                 </div>
    //                 <div className="item-wrapper">
    //                     <Row className="date-row">
    //                         <Col span={4}>
    //                             SNS Protection
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.duration" /></Col>
    //                         <Col span={12}>
    //                             <div className="item-content-container">
    //                                 <p>{this.getDateRange(this.props.companyInfo.service_sns_start_date, this.props.companyInfo.service_sns_end_date)}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col span={4}>
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.fee" /></Col>
    //                         <Col span={4}>
    //                             <div className="item-content-container">
    //                                 <p>{this.props.companyInfo.service_sns_fee ? this.props.companyInfo.service_sns_fee + " " + getCurrencyName(this.props.companyInfo.service_sns_currency) : ""}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                 </div>
    //                 <div className="item-wrapper">
    //                     <Row className="date-row">
    //                         <Col span={4}>
    //                             WS Protection
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.duration" /></Col>
    //                         <Col span={12}>
    //                             <div className="item-content-container">
    //                                 <p>{this.getDateRange(this.props.companyInfo.service_ws_start_date, this.props.companyInfo.service_ws_end_date)}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col span={4}>
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.fee" /></Col>
    //                         <Col span={4}>
    //                             <div className="item-content-container">
    //                                 <p>{this.props.companyInfo.service_ws_fee ? this.props.companyInfo.service_ws_fee + " " + getCurrencyName(this.props.companyInfo.service_ws_currency) : ""}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                 </div>
    //                 <div className="item-wrapper">
    //                     <Row className="date-row">
    //                         <Col span={4}>
    //                             Other Protection
    //                         </Col>
    //                         <Col span={4}><FormattedMessage id="app.ui.profile.section.contract.duration" /></Col>
    //                         <Col span={12}>
    //                             <div className="item-content-container">
    //                                 <p>{this.getDateRange(this.props.companyInfo.service_other_start_date, this.props.companyInfo.service_other_end_date)}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col span={4}>
    //                         </Col>
    //                         <Col span={4}>
    //                             <FormattedMessage id="app.ui.profile.section.contract.fee" />
    //                         </Col>
    //                         <Col span={4}>
    //                             <div className="item-content-container">
    //                                 <p>{this.props.companyInfo.service_other_fee ? this.props.companyInfo.service_other_fee + " " + getCurrencyName(this.props.companyInfo.service_other_currency) : ""}</p>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                 </div>
    //             </EFSimpleContainer>
    //         )
    //     }
    // }

    render() {
        return (
            <div className="setting-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.profile.section.basicinfo.title" />}>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.section.basicinfo.name" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string", 
                                                max: 50,
                                                required: true,
                                                message: "basicinfo.name.error", // messages id
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.section.basicinfo.mail" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.props.mail}</p>
                                    </div>
                                </div>
                                {this.getRoleUI()}
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p><FormattedMessage id="app.ui.profile.section.basicinfo.tel" /></p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                max: 50,
                                                message: "basicinfo.tel.error", // messages id
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </EFSimpleContainer>
                        </Col>
                        <Col span={12}>
                            {this.showCompanyInfo()}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="acition-container">
                                <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>
                                    <FormattedMessage id="app.ui.profile.section.basicinfo.update" />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {this.showAnalystInfo()}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {this.showServiceInfoTable()}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {this.props.role & 2 ? <EFAnalystClientList /> : <></>}
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(injectIntl(UpdateUserInfoBackup))