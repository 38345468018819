/* eslint-disable jsx-a11y/no-access-key */
import React, { Component } from "react";
import "./index.less";
import { Form, Input, Button, Row, Col, message, Steps, DatePicker, Select, Pagination, Divider } from "antd";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { connect } from "react-redux";
import { reqSearchWSRecords, reqUpdateWSRecord } from "@/api/ws.js"
import EFSimpleContainer from "@/components/ef-simple-container"
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { reqSearchTrademarks } from "../../../api/trademark";

import SectionColumns from './ws-detail-section-columns.js'

import ECListCommon from "@/views/ec/common/ec-list-common.js"

import moment from 'moment';
import messages from "./messages";
import { injectIntl, FormattedMessage } from "react-intl";

const { Step } = Steps;

class WSRecordDeital extends Component {
    constructor(props) {
        super(props)

        this.recordId = parseInt(this.props.match.params.rid);
        if (this.props.role & 2) {
            this.clientId = parseInt(this.props.match.params.cid);
        } else {
            this.clientId = this.props.companyInfo.client_id;
        }

        this.searchParams = new URLSearchParams(this.props.location.search);

        let current = this.searchParams.get("num");
        current = current ? parseInt(current) : 0
        if (current === "NaN") {
            current = 0;
        }

        let total = this.searchParams.get("total");
        total = total ? parseInt(total) : 0
        if (total === "NaN") {
            total = 0;
        }

        this.formRef = React.createRef();
        this.originCustomColumnInfo = {}
        this.state = {
            record: null,
            total: total,
            offset: current,
            isUpdateing: false,
            trademarks: null,
            selectResultStep: 0,
            scrapeColumns: SectionColumns.getBaseScrapeColumns(this.props.intl),
            categorizeColumns: SectionColumns.getBaseCategorizeColumns(this.props.role, this.props.intl),
            responseColumns: SectionColumns.getBaseResponseColumns(this.props.intl, null),
            result1Columns: SectionColumns.getBaseResult1Columns(this.props.role, this.props.intl),
            result2Columns: SectionColumns.getBaseResult2Columns(this.props.role, this.props.intl),
            result3Columns: SectionColumns.getBaseResult3Columns(this.props.role, this.props.intl),
            resultColumns: SectionColumns.getBaseResultColumns(this.props.role, this.props.intl),
        }
    }

    componentDidMount() {
        reqSearchTrademarks({ clientId: this.clientId }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({
                    trademarks: data.data.trademarks,
                    responseColumns: SectionColumns.getBaseResponseColumns(this.props.intl, data.data.trademarks),
                })
            }
        }).catch((error) => {

        })

        this.onPageChange(this.state.offset + 1)

        this.originSearchParams = new URLSearchParams(this.props.location.search);
        if (this.props.role & 2) {
            store.dispatch(setMenuActiveKey("analyst-ws-list"));
        } else {
            store.dispatch(setMenuActiveKey("client-ws-list"));
        }
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.online_protection.ws_record_list" />,
                path: `/ws-list?${this.originSearchParams.toString()}`,
            },
            {
                name: <FormattedMessage id="app.ui.ws_detail.title" />,
            },
        ]));
    }

    setFormValues = (record) => {
        this.formRef.current.setFieldsValue({ "categoryId": record["categoryId"] });
        this.formRef.current.setFieldsValue({ "remarks": record["remarks"] })
        if (record["categorizeDate"]) {
            let fieldValue = {}
            fieldValue["categorizeDate"] = moment(record["categorizeDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

        this.formRef.current.setFieldsValue({ "action": record["action"] })
        // this.formRef.current.setFieldsValue({ "responseRemarks": record["responseRemarks"] })
        this.formRef.current.setFieldsValue({ "trademarkId": record["trademarkId"] })
        this.formRef.current.setFieldsValue({ "responseRemarks": record["responseRemarks"] })
        if (record["responseDate"]) {
            let fieldValue = {}
            fieldValue["responseDate"] = moment(record["responseDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

        this.formRef.current.setFieldsValue({ "process1ResultId": record["process1ResultId"] })
        if (record["process1Date"]) {
            let fieldValue = {}
            fieldValue["process1Date"] = moment(record["process1Date"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }
        if (record["process1ConfirmDate"]) {
            let fieldValue = {}
            fieldValue["process1ConfirmDate"] = moment(record["process1ConfirmDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

        this.formRef.current.setFieldsValue({ "process2ResultId": record["process2ResultId"] })
        if (record["process2Date"]) {
            let fieldValue = {}
            fieldValue["process2Date"] = moment(record["process2Date"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }
        if (record["process2ConfirmDate"]) {
            let fieldValue = {}
            fieldValue["process2ConfirmDate"] = moment(record["process2ConfirmDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

        this.formRef.current.setFieldsValue({ "process3ResultId": record["process3ResultId"] })
        if (record["process3Date"]) {
            let fieldValue = {}
            fieldValue["process3Date"] = moment(record["process3Date"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }
        if (record["process3ConfirmDate"]) {
            let fieldValue = {}
            fieldValue["process3ConfirmDate"] = moment(record["process3ConfirmDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

        this.formRef.current.setFieldsValue({ "processResultId": record["processResultId"] })
        this.formRef.current.setFieldsValue({ "processRemarks": record["processRemarks"] })
        if (record["processConfirmDate"]) {
            let fieldValue = {}
            fieldValue["processConfirmDate"] = moment(record["processConfirmDate"], "YYYY-MM-DD")
            this.formRef.current.setFieldsValue(fieldValue);
        }

    }



    getFormValue = (key) => {
        if (this.formRef && this.formRef.current && this.formRef.current.getFieldValue(key)) {
            return this.formRef.current.getFieldValue(key)
        } else {
            if (this.state.record) {
                return this.state.record[key]
            }
        }
        return null
    }


    renderResultInfo = () => {

        let process1Result = this.getFormValue("process1ResultId")
        let process1Date = this.getFormValue("process1Date")
        let step1Status = (process1Result && process1Date) ? "finish" : "wait";
        let step1ClassName = "process-container-hide"

        let process2Result = this.getFormValue("process2ResultId")
        let process2Date = this.getFormValue("process2Date")
        let step2Status = (process2Result && process2Date) ? "finish" : "wait";
        let step2ClassName = "process-container-hide"

        let process3Result = this.getFormValue("process3ResultId")
        let process3Date = this.getFormValue("process3Date")
        let step3Status = (process3Result && process3Date) ? "finish" : "wait";
        let step3ClassName = "process-container-hide"

        let processResult = this.getFormValue("processResultId")
        let processConfirmDate = this.getFormValue("processConfirmDate")
        let stepStatus = (processResult && processConfirmDate) ? "finish" : "wait";
        let stepClassName = "process-container-hide"

        let process1ResultNode = this.renderColumnsInfo(this.state.result1Columns)
        let process2ResultNode = this.renderColumnsInfo(this.state.result2Columns)
        let process3ResultNode = this.renderColumnsInfo(this.state.result3Columns)
        let processResultNode = this.renderColumnsInfo(this.state.resultColumns)

        if (this.state.selectResultStep === 0) {
            step1Status = "process"
            step1ClassName = "process-container"
        } else if (this.state.selectResultStep === 1) {
            step2Status = "process"
            step2ClassName = "process-container"
        } else if (this.state.selectResultStep === 2) {
            step3Status = "process"
            step3ClassName = "process-container"
        } else {
            stepStatus = "process"
            stepClassName = "process-container"
        }

        return (
            <div>
                <Steps current={this.state.selectResultStep} onChange={(current) => {
                    this.setState({ selectResultStep: current })

                }}>
                    <Step title={<FormattedMessage id="app.ui.ec_detail.step.1" />} status={step1Status} />
                    <Step title={<FormattedMessage id="app.ui.ec_detail.step.2" />} status={step2Status} />
                    <Step title={<FormattedMessage id="app.ui.ec_detail.step.3" />} status={step3Status} />
                    <Step title={<FormattedMessage id="app.ui.ec_detail.step.4" />} status={stepStatus} />
                </Steps>
                <div className={step1ClassName}>
                    {process1ResultNode}
                </div>

                <div className={step2ClassName}>
                    {process2ResultNode}
                </div>
                <div className={step3ClassName}>
                    {process3ResultNode}
                </div>

                <div className={stepClassName}>
                    {processResultNode}
                </div>
            </div >
        )
    }

    renderInfoRow = (column1, column2) => {
        if (this.state.record) {
            let node1, node2
            if (column1) {
                node1 = (
                    <>
                        <Col span={4}>
                            <p className="scrape-info-title">{column1.title}</p>
                        </Col>
                        <Col span={8}>
                            {column1.renderValue(this.state.record[column1.dataIndex])}
                        </Col>
                    </>
                )
            }
            if (column2) {
                node2 = (
                    <>
                        <Col span={4}>
                            <p className="scrape-info-title">{column2.title}</p>
                        </Col>
                        <Col span={8}>
                            {column2.renderValue(this.state.record[column2.dataIndex])}
                        </Col>
                    </>
                )
            }
            return (
                <Row className="scrape-info-row">
                    {node1}
                    {node2}
                </Row>
            )
        }
    }

    renderColumnsInfo = (columns) => {

        let nodes = []
        for (let i = 0; i < columns.length; i = i + 2) {
            let node


            if (i + 1 < columns.length) {
                node = this.renderInfoRow(columns[i], columns[i + 1])

            } else {
                node = this.renderInfoRow(columns[i], null)
            }

            if (node) {
                nodes.push(node)
            }
        }

        return nodes;
    }

    renderColumnsInfoWithWhois = (columns) => {
        let nodes = this.renderColumnsInfo(columns)
        nodes.push(this.renderWhois())
        return nodes;
    }

    renderWhois = () => {
        if (this.state.record) {
            return (
                <Row className="scrape-info-row">
                    <Col span={4}>
                        <p className="scrape-info-title"><FormattedMessage id="app.ui.wslist.table.whois" /></p>
                    </Col>
                    <Col span={20}>
                        <p>{this.state.record["whois"]}</p>
                    </Col>
                </Row>
            )

        }
    }

    onFinish = (values) => {

        if (this.props.role & 2) {
            if (!values.categorizeDate) {
                message.error("レポート提出日を入力してください。")
                return
            }

            if (values.process1ResultId && !values.process1Date) {
                message.error("申告実施月(1回目)を入力してください。")
                return
            }

            if (!values.process1ResultId && values.process1Date) {
                message.error("1回目申告結果を入力してください。")
                return
            }

            if (values.process2ResultId && !values.process2Date) {
                message.error("申告実施月(2回目)を入力してください。")
                return
            }

            if (!values.process2ResultId && values.process2Date) {
                message.error("2回目申告結果を入力してください。")
                return
            }

            if (values.process2ResultId && values.process2Date && !values.process1ResultId && !values.process1Date) {
                message.error("申告情報(1回目)を入力してください。")
                return
            }


            if (values.process3ResultId && !values.process3Date) {
                message.error("申告実施月(3回目)を入力してください。")
                return
            }

            if (!values.process3ResultId && values.process3Date) {
                message.error("3回目申告結果を入力してください。")
                return
            }

            if (values.process3ResultId && values.process3Date && !values.process2ResultId && !values.process2Date) {
                message.error("申告情報(2回目)を入力してください。")
                return
            }


            if (values.processResultId && !values.processConfirmDate) {
                message.error("最終結果確認日を入力してください。")
                return
            }

            if (!values.processResultId && values.processConfirmDate) {
                message.error("最終結果を入力してください。")
                return
            }

            if (values.processResultId && values.processConfirmDate &&
                !values.process3ResultId && !values.process3Date &&
                !values.process2ResultId && !values.process2Date &&
                !values.process1ResultId && !values.process1Date) {
                message.error("申告情報をご確認してください。")
                return
            }
        }
        this.setState({ isUpdateing: true })

        let parameter = {
            clientId: this.clientId,
            recordId: this.recordId,

            categoryId: values.categoryId,
            categorizeDate: values.categorizeDate ? values.categorizeDate.format("YYYY-MM-DD") : null,
            remarks: values.remarks,

            process1Result: values.process1ResultId,
            process1Date: values.process1Date ? values.process1Date.format("YYYY-MM-DD") : null,
            // process1Content: values.ec_process1_content,
            process1ConfirmDate: values.process1ConfirmDate ? values.process1ConfirmDate.format("YYYY-MM-DD") : null,
            // process1Remarks: values.ec_process1_remarks,

            process2Result: values.process2ResultId,
            process2Date: values.process2Date ? values.process2Date.format("YYYY-MM-DD") : null,
            // process2Content: values.ec_process2_content,
            process2ConfirmDate: values.process2ConfirmDate ? values.process2ConfirmDate.format("YYYY-MM-DD") : null,
            // process2Remarks: values.ec_process2_remarks,

            process3Result: values.process3ResultId,
            process3Date: values.process3Date ? values.process3Date.format("YYYY-MM-DD") : null,
            // process3Content: values.ec_process3_content,
            process3ConfirmDate: values.process3ConfirmDate ? values.process3ConfirmDate.format("YYYY-MM-DD") : null,
            // process3Remarks: values.ec_process3_remarks,

            processResult: values.processResultId,
            // processDate: values.processConfirmDate,
            processConfirmDate: values.processConfirmDate ? values.processConfirmDate.format("YYYY-MM-DD") : null,
            processRemarks: values.processRemarks,

            action: values.action,
            // reason: values.ec_response_reason,
            trademarkId: values.trademarkId,
            responseDate: values.responseDate ? values.responseDate.format("YYYY-MM-DD") : null,
            responseRemarks: values.responseRemarks,

        }

        reqUpdateWSRecord(parameter).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setFormValues(data.data.record);
                this.setState({ record: data.data.record });
                this.setState({ isUpdateing: false })
                message.success(this.props.intl.formatMessage(messages["message.info.update_success"]))

            }
        }).catch((error) => {
            this.setState({ isUpdateing: false })
            message.error(this.props.intl.formatMessage(messages["message.error.update_failed"]))
        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }

    onPageChange = (value) => {
        try {
            let finalConditions = ECListCommon.converSearchParmasToConditions(this.searchParams)
            reqSearchWSRecords({
                clientId: this.clientId,
                ...finalConditions,
                limit: 1,
                offset: value - 1
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    let records = data.data.records;
                    if (records.length > 0) {
                        this.recordId = data.data.records[0].id


                        this.setFormValues(data.data.records[0]);
                        this.setState({
                            record: data.data.records[0],
                            offset: data.data.offset, total: data.data.total
                        });
                    }
                }
            })
        } catch (e) {
        }
    }

    render() {
        document.title = this.props.intl.formatMessage(messages["ec_detail.title"])
        return (
            <div className="ec-detail-container" tabIndex={0}>
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row className="section-container">
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.ws_detail.section.scrape_info" />}>
                                {this.renderColumnsInfoWithWhois(this.state.scrapeColumns)}
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row className="section-container">
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.ec_detail.tab.categorized_info" />}>
                                {this.renderColumnsInfo(this.state.categorizeColumns)}
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row className="section-container">
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.ec_detail.tab.client_response" />}>
                                {this.renderColumnsInfo(this.state.responseColumns)}
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row className="section-container">
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.ec_detail.tab.ipf_report_info" />}>
                                {this.renderResultInfo()}
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div>
                                <Divider></Divider>
                                <Form.Item noStyle={true}>
                                    <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isUpdateing} accesskey="S">
                                        <FormattedMessage id="app.common.action.update" />
                                    </Button>
                                </Form.Item>
                                <Button type="link" className="update-button" href={`/ws-list?${this.originSearchParams}`} accesskey="L">
                                    <FormattedMessage id="app.ui.ec_detail.button.back" />
                                </Button>
                                <div className="pagination-container" >
                                    <Pagination showQuickJumper
                                        showSizeChanger={false}
                                        onChange={this.onPageChange}
                                        total={this.state.total}
                                        current={this.state.offset + 1}
                                        pageSize={1}
                                        itemRender={(page, type, originalElement) => {
                                            if (type === "prev") {
                                                // eslint-disable-next-line jsx-a11y/no-access-key
                                                return <Button type="button" className="ant-pagination-item-link" icon={<LeftOutlined />} accesskey="P"></Button>
                                            } else if (type === "next") {
                                                // eslint-disable-next-line jsx-a11y/no-access-key
                                                return <Button type="link" className="ant-pagination-item-link" icon={<RightOutlined />} accesskey="N"></Button>
                                            } else {
                                                return originalElement
                                            }
                                        }
                                        } />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};
export default connect(mapStateToProps)(injectIntl(WSRecordDeital));