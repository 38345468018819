import React, { Component } from 'react'
import { Layout, Button, Dropdown, Menu, Space, Image } from "antd"
import { DownOutlined } from '@ant-design/icons'

import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import { logout } from "@/store/actions/auth"
import { changeLocale } from '../../../store/actions/setting'

import store from "@/store"

import "./index.less"
import messages from './messages'
import { FormattedMessage,useIntl } from 'react-intl'

const { Header } = Layout

const EFHead = () => {

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
        ...reduxState.setting,
    }

    const logoClass = "logo-img"
    const logoImage = "/ipf-logo.png"

    const intl = useIntl()
    const [language, setLanguage] = useState(intl.locale)


    const handleLanguageChangeClick = (e) => {
        setLanguage(e.key)
        store.dispatch(changeLocale(e.key, reduxProps.id));
    }

    const handleLogout = () => {
        store.dispatch(logout(reduxProps.token));;
    }

    const languageSelectMenu = () => {
        return (
            <Menu
                onClick={handleLanguageChangeClick}
                items={[
                    {
                        key: 'en',
                        label: 'English',
                    },
                    {
                        key: 'ja',
                        label: '日本語',
                    },
                ]}
            />
        )
    }



    const userSideFun = () => {
        return (
            <Menu
                onClick={handleLogout}
                items={[
                    {
                        key: 'logout',
                        label: <FormattedMessage id="app.layout.header.logout" />,
                    },
                ]}
            />
        )
    }

    return (
        <Header className="ef-header-top-container">
            <Space className='ef-header-left-container'>
                <div
                    className="logo-wrapper"
                >
                    <img src={logoImage} alt="logo" className={logoClass} ></img>
                </div>
            </Space>
            <Space className="ef-header-right-container">
                {/* <p className='ef-username'><FormattedMessage id="app.layout.header.usename" /></p> */}
                <Image preview={false} src='/images/header/username.svg'></Image>
                <Dropdown overlay={userSideFun} placement="bottomLeft" trigger={["hover"]}>
                    <Space className={`ef-user ${language === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'}`}>
                        {reduxProps.name}<DownOutlined className='ef-language-select-icon' />
                        {/* <p className='ef-username'>{reduxProps.name}</p><div className='ef-language-select-icon'><DownOutlined /></div> */}
                    </Space>
                </Dropdown>

                <Image preview={false} style={{ background: '#B8DAF2', borderRadius: '50%' }} src='/images/header/language.svg'></Image>
                <Dropdown overlay={languageSelectMenu} placement="bottomLeft" trigger={["hover"]}>
                    <Space className={`ef-language ${language === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'}`}>
                        <FormattedMessage id="app.layout.header.language" /><DownOutlined className='ef-language-select-icon' />
                        {/* <FormattedMessage id="app.layout.header.language" /><div className='ef-language-select-icon'><DownOutlined /></div> */}
                    </Space>
                </Dropdown>
            </Space>
        </Header>
    )
}
export default EFHead
