import request from '@/utils/request'

export function reqCreateUser(data) {
    return request({
        url: '/api/admin/create-user',
        method: 'post',
        data: data
    })
}

export function reqRemoveUser(data) {
    return request({
        url: '/api/admin/remove-user',
        method: 'post',
        data: data
    })
}

export function reqUpdateUser(data) {
    return request({
        url: '/api/admin/update-user',
        method: 'post',
        data: data
    })
}

export function reqSearchUsers(data) {
    return request({
        url: '/api/admin/search-users',
        method: 'post',
        data: data
    })
}

export function reqGetUser(data) {
    return request({
        url: '/api/admin/get-user',
        method: 'post',
        data: data
    })
}

