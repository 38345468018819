import * as types from "../action-types";

// {
//     123:{// clientId
//         customColumnInfo,
//         productCategoryInfo
//     }
// }

const initECMasterData = {
};

export default function ecMasterData(state = initECMasterData, action) {
    switch (action.type) {
        case types.MASTER_DATA_CUSTOM_COLUMN_CHANGED: {
            let newState = Object.assign({}, state)
            if (!newState[action.clientId]) {
                newState[action.clientId] = {}
                newState[action.clientId].customColumnInfo = action.data;
            } else {
                newState[action.clientId].customColumnInfo = action.data;
            }

            return newState;
        }
        case types.MASTER_DATA_PRODUCT_CATEGORY_CHANGED: {
            let newState = Object.assign({}, state)
            if (!newState[action.clientId]) {
                newState[action.clientId] = {}
                newState[action.clientId].productCategoryInfo = action.data;
            } else {
                newState[action.clientId].productCategoryInfo = action.data;
            }
            return newState;
        }
        default:
            return state;
    }
}