import moment from 'moment';

const intRe = /^[0-9]*$/
export const matchInt = (str) => {
    return intRe.test(str)
}

const floatRe = /^\d+(\.\d+)?$/
export const matchFloat = (str) => {
    return floatRe.test(str)
}

const mailRe = /^\d{4}-\d{1,2}-\d{1,2}$/
export const matchMail = (str) => {
    return mailRe.test(str);
}

const urlRe = /[a-zA-z]+:\/\/[^\s]*/
export const matchURL = (str) => {
    return urlRe.test(str);
}

// const dateRe = /^\d{4}-\d{1,2}-\d{1,2}$/
export const matchDate = (str, format = "YYYY-MM-DD") => {
    return moment(str, format).isValid();
}

const domainRe = /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
export const matchDomain = (str) => {
    return domainRe.test(str)
}

export const isValidMail = function (mail) {
    if (mail) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(mail).toLowerCase());
    } else {
        return false;
    }
};

export const isValidPassword = function (password) {
    if (password) {
        let re = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,20}$/;
        return re.test(String(password));
    } else {
        return false;
    }
}

export const isValidUserName = function (userName) {
    if (userName && userName.trim().length > 0 && userName.trim().length < 21) {
        return true;
    } else {
        return false;
    }
}

export const isValidRemarks = function (remarks) {
    return true
}

export const isValidTelNo = function (telNo) {
    if (telNo) {
        let re = /(\d|[\+\-\(\)]){3,20}$/;
        return re.test(String(telNo));
    } else {
        return false;
    }
}