export const WSTakedownResult = [
    {
        text: "審査中",
        name: {
            en: "In review",
            ja: "審査中"
        },

        value: 1,
    },
    {
        text: "完了",
        name: {
            en: "Takedown",
            ja: "完了"
        },
        value: 2,

    },
    {
        text: "対応なし",
        name: {
            en: "No action",
            ja: "対応なし"
        },
        value: 3,
    },
];

export const WSTakedownRequest = [
    {
        text: "Takedown",
        name: {
            en: "Takedown",
            ja: "申告実施"
        },
        value: 1,
    },
    {
        text: "Pending",
        name: {
            en: "Pending",
            ja: "保留"
        },
        value: 2,
    },
    {
        text: "No Action",
        name: {
            en: "No Action",
            ja: "措置不要"
        },
        value: 3,
    },
];

export const WSCategoryType = [
    {
        text: "Official",
        name: {
            en: "Official",
            ja: "正規サイト"
        },
        input: "◎",
        value: 1,
    },
    {
        text: "Non-related",
        name: {
            en: "Non-related",
            ja: "関連なしサイト"
        },
        input: "○",
        value: 2,
    },
    {
        text: "Suspicious",
        name: {
            en: "Suspicious",
            ja: "不審サイト"
        },
        input: "×",
        value: 3,
    },
    {
        text: "Need confirmation",
        name: {
            en: "Need confirmation",
            ja: "確認サイト"
        },
        input: "△",
        value: 4,
    },
    {
        text: "Parking site",
        name: {
            en: "Parking site",
            ja: "パーキングサイト"
        },
        input: "☆",
        value: 5,
    },
    {
        text: "No content",
        name: {
            en: "No content",
            ja: "内容なし"
        },
        input: "◇",
        value: 6,
    },
    {
        text: "Not displayed",
        name: {
            en: "Not displayed",
            ja: "表示されない"
        },
        input: "＃",
        value: 7,
    },
    {
        text: "Domain selling",
        name: {
            en: "Domain selling",
            ja: "販売中"
        },
        input: "@",
        value: 8,
    },
    {
        text: "Blocked",
        name: {
            en: "Blocked",
            ja: "アクセス不可"
        },
        input: "？",
        value: 9,
    },
    {
        text: "Dangerous site",
        name: {
            en: "Dangerous site",
            ja: "危険サイト"
        },
        input: "！",
        value: 10,
    },
    {
        text: "Phishing",
        name: {
            en: "Phishing",
            ja: "詐欺サイト"
        },
        input: "※",
        value: 11,
    },
];
