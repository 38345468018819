// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "ec_detail.title": {
        id: "app.ui.ec_detail.title",
        defaultMessage: "EC Record Detail",
    },
    "message.info.update_success": {
        id: "app.ui.ec_detail.message.info.update_success",
        defaultMessage: "Update successful.",
    },
    "message.error.update_failed": {
        id: "app.ui.ec_detail.message.error.update_failed",
        defaultMessage: "Failed to update.",
    }
})

export default messages
