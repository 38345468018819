import request from '@/utils/request'

export function reqCreateSiteAccount(data){
    return request({
        url: '/api/analyst/ec-account/add',
        method: 'post',
        data: data
    })
}

export function reqRemoveSiteAccount(data){
    return request({
        url: '/api/analyst/ec-account/remove',
        method: 'post',
        data: data
    })
}

export function reqUpdateSiteAccount(data){
    return request({
        url: '/api/analyst/ec-account/update',
        method: 'post',
        data: data
    })
}

export function reqFetchSiteAccounts(data){
    return request({
        url: '/api/analyst/ec-account/fetch',
        method: 'post',
        data: data
    })
}