import request from '@/utils/request'

export function reqLogin(mail,password,code) {
  return request({
    url: '/api/login',
    method: 'post',
    data:{
      mail,
      password,
      code
    }
  })
}

export function reqLogout(token) {
  return request({
    url: '/api/logout',
    method: 'post',
    data:{
      token
    }
  })
}

export function reqCode(mail){
  return request({
    url:'/api/requestcode',
    method:'post',
    data:{
      mail
    }
  })
}

export function reqSignup(name,mail,password,password2,code){
  return request({
    url:'/api/signup',
    method:'post',
    data:{
      name,
      mail,
      password,
      password2,
      code
    }
  })
}

export function reqResetPassword(mail,password,password2,code){
  return request({
    url:'/api/resetpassword',
    method:'post',
    data:{
      mail,
      password,
      password2,
      code
    }
  })
}
