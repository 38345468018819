import "./index.less"
import React, { Component } from "react"
import { Button, Upload, Table, Checkbox, Space, message, Popconfirm, Col, Row, Pagination } from "antd"
import { reqUploadECReport, reqFetchReports, reqRemoveECReport, reqInheritPreECReport, reqDeleteRepeatRecords, reqPostReport, reqBatchUpdateECReport } from '../../../api/ec'
import ConvertWorker from "../../../worker/covertECExceltoJson.worker.js"
import { reqFetchCustomColumns } from '../../../api/analyst-custom-column'
import { reqSearchTrademarks } from '../../../api/trademark'
import { reqFetchInfringementCategories } from '../../../api/analyst-infringement-category'
import { reqFetchProductCategories } from '../../../api/analyst-product-category'
import { reqFetchECSites } from '../../../api/analyst-site'
import moment from 'moment'
import { withRouter } from "react-router-dom"
import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'
import { connect } from "react-redux"
import { setCustomColumnAction, setProductCategoryAction } from "@/store/actions/ec-master.js"
import EFBox from '../../../components/ef-c-box'
import store from "@/store"

const FormData = require('form-data')

class AnalystClientECReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            createUploadFileList: [],
            updateUploadFileList: [],
            createState: true,
            createErrorMessage: '',
            updateState: true,
            updateErrorMessage: '',
            pageSize: 5,
            page: 1,
        }
        this.sendUncategorizRecords = false
        this.totalNum = 0
        this.customLocale = {
            items_per_page: this.props.intl.formatMessage({ id: 'app.pagination.per_page' }),
            jump_to: this.props.intl.formatMessage({ id: 'app.pagination.jump_to' }),
            page: this.props.intl.formatMessage({ id: 'app.pagination.page' }),
            showTotal: (total, current) => {
                return <FormattedMessage
                    id="app.pagination.show_total"
                    values={
                        {
                            count: Math.ceil(total / current)
                        }
                    }
                />
            }
        }
    }

    componentDidMount = () => {
        if (this.props.clientId) {
            reqFetchReports({ clientId: this.props.clientId }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    this.totalNum = data.data.reports.length
                    this.setState({ reports: data.data.reports })
                }
            }).catch((e) => {

            })
        }
        this.covertWorker = new ConvertWorker()
        // sitesInfo, productsInfo, categorysInfo, trademarksInfo,customItemsInfo
        if (this.props.clientId) {
            reqFetchCustomColumns({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    // this.setState({ customItemsInfo: data.data.columns });
                    store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns))
                }
            }).catch((error) => {

            })
        }

        reqSearchTrademarks({ clientId: this.props.clientId }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ trademarksInfo: data.data.trademarks })
            }
        }).catch((error) => {

        })

        reqFetchInfringementCategories().then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ categorysInfo: data.data.categories })
            }
        }).catch((error) => {

        })

        reqFetchProductCategories({
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // this.setState({ productsInfo: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories))
            }
        }).catch((error) => {

        })

        reqFetchECSites().then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ sitesInfo: data.data.sites })
            }
        }).catch((error) => {

        })
    }

    componentWillUnmount = () => {
        if (this.covertWorker) {
            this.covertWorker.terminate()
        }
    }

    static getDerivedStateFromProps (props, current_state) {
        if (props.ecmaster) {
            if (props.ecmaster[props.clientId]) {
                current_state.productsInfo = props.ecmaster[props.clientId].productCategoryInfo
                current_state.customItemsInfo = props.ecmaster[props.clientId].customColumnInfo
            }
        }
        return props
    }

    onDetail = (record) => {
        // let effectFilters = effectFilterKeys.map((key) => {
        //     let result = {}
        //     result.key = key;
        //     result.values = filters[key];
        //     return result;
        // })
        // if (effectFilters && effectFilters.length > 0) {
        //
        //     searchParams.set("filters", encodeURIComponent(JSON.stringify(effectFilters)))
        // } else {
        //     searchParams.set("filters", null)
        // }

        let result = {}
        result.key = "report_id"
        result.values = [record.report_id]
        this.props.history.push(`/ec-list?clientId=${this.props.clientId}&filters=${encodeURIComponent(JSON.stringify([result]))}`)
    }

    onDeleteReport = (record) => {
        reqRemoveECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.totalNum = data.data.reports.length
                this.setState({ reports: data.data.reports })
            }
            message.success(record.report_name + this.props.intl.formatMessage(messages["messages.info.delete_success"]))
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages["messages.error.delete_fail"]))
        })
    }

    onDownloadReport = (record) => {
        window.open(`${process.env.REACT_APP_BASE_API}/api/ec-report/download-report?clientId=${this.props.clientId}&reportId=${record.report_id}`)
        // reqDownloadECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
        //     const { data } = response;
        //     if (data.code === 0) {

        //     }
        // }).catch((e) => {
        //     message.error("ダウンロードは失敗しました。")
        // })
    }

    onInheritReport = (record) => {
        reqInheritPreECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.totalNum = data.data.reports.length
                this.setState({ reports: data.data.reports })
                message.success(record.report_name + this.props.intl.formatMessage(messages["messages.info.renewal_of_contract_success"]))
            }
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages["messages.error.renewal_of_contract_fail"]))
        })
    }

    onDeleteRepeatReport = (record) => {
        reqDeleteRepeatRecords({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.totalNum = data.data.reports.length
                this.setState({ reports: data.data.reports })
                message.success(record.report_name + this.props.intl.formatMessage(messages["messages.info.remove_duplicate_success"]))

            }
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages["messages.error.renewal_of_contract_fail"]))
        })
    }

    onPostReport = (record) => {
        // sendUncategorizRecords
        reqPostReport({ clientId: this.props.clientId, reportId: record.report_id, sendUncategorizRecords: this.sendUncategorizRecords ? true : false }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.totalNum = data.data.reports.length
                this.setState({ reports: data.data.reports })
                message.success(record.report_name + this.props.intl.formatMessage(messages["messages.info.open_success"]))
            }
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages["messages.error.open_fail"]))
        })
    }

    // createColumns = () => {
    //     return [
    //         {
    //             title: '番号',
    //             dataIndex: 'report_id',
    //             key: 'report_id',
    //             fixed: "left",
    //             width: 80,
    //         },
    //         {
    //             title: 'レポート名',
    //             dataIndex: 'report_name',
    //             key: 'report_name',
    //         },

    //         {
    //             title: 'アップロード日',
    //             dataIndex: 'report_upload_date',
    //             key: 'report_upload_date',
    //             render: (value, record, index) => {
    //                 if (value) {
    //                     return moment(value).format("YYYY-MM-DD")
    //                 }
    //                 return ""
    //             },
    //         },
    //         {
    //             title: 'Scrape総数',
    //             dataIndex: 'report_scrape_count',
    //             key: 'report_scrape_count',
    //         },
    //         {
    //             title: '重複数',
    //             dataIndex: 'report_repeat_count',
    //             key: 'report_repeat_count',
    //         },
    //         {
    //             title: '公開日',
    //             dataIndex: 'report_send_date',
    //             key: 'report_send_date',
    //             render: (value, record, index) => {
    //                 if (value) {
    //                     return moment(value).format("YYYY-MM-DD")
    //                 }
    //                 return ""
    //             },
    //         },
    //         {
    //             title: 'カテゴライズ数',
    //             dataIndex: "report_uncategrize_count",
    //             key: "report_uncategrize_count",
    //             render: (value, record, index) => {
    //                 return record.report_scrape_count - value
    //             },
    //         },
    //         {
    //             title: '未カテゴライズ数',
    //             dataIndex: "report_uncategrize_count",
    //             key: "report_uncategrize_count"
    //         },
    //         {
    //             title: '回答日',
    //             dataIndex: 'report_response_date',
    //             key: 'report_response_date',
    //             render: (value, record, index) => {
    //                 if (value) {
    //                     return moment(value).format("YYYY-MM-DD")
    //                 }
    //                 return ""
    //             },
    //         },
    //         {
    //             title: '回答数',
    //             dataIndex: 'report_response_count',
    //             key: 'report_response_count',
    //         },

    //         {
    //             title: '動作',
    //             key: 'action',
    //             fixed: "right",
    //             render: (text, record, index) => (
    //                 <Space size="middle" key="actions">
    //                     <Button key="detail" type="link" onClick={() => { this.onDetail(record) }}>詳細</Button>

    //                     <Popconfirm
    //                         key="delete"
    //                         title="削除しますか？"
    //                         onConfirm={() => { this.onDeleteReport(record) }}
    //                         okText="はい"
    //                         cancelText="いいえ"
    //                     >
    //                         <Button key="delete" type="link" >削除</Button>
    //                     </Popconfirm>
    //                     <Button type="link" onClick={() => { this.onDownloadReport(record) }}>ダウンロード</Button>
    //                     <Popconfirm
    //                         key="inherit"
    //                         title="引継しますか？"
    //                         onConfirm={() => { this.onInheritReport(record) }}
    //                         okText="はい"
    //                         cancelText="いいえ"
    //                     >

    //                         <Button key="inherit" type="link">引継</Button>
    //                     </Popconfirm>
    //                     <Popconfirm
    //                         key="removeDuplicate"
    //                         title="重複のレコードを削除しますか？"
    //                         onConfirm={() => { this.onDeleteRepeatReport(record) }}
    //                         okText="はい"
    //                         cancelText="いいえ"
    //                     >
    //                         <Button key="removeDuplicate" type="link">重複削除</Button>
    //                     </Popconfirm>
    //                     <Popconfirm
    //                         key="send"
    //                         title={<Checkbox onClick={(value) => this.sendUncategorizRecords = value}>カテゴライズなしのレコードをクライアントに表示します。</Checkbox>}
    //                         onConfirm={() => { this.onPostReport(record) }}
    //                         onCancel={() => this.sendUncategorizRecords = false}
    //                         okText="公開する"
    //                         cancelText="やめる"
    //                     >
    //                         <Button key="send" type="link">公開</Button>
    //                     </Popconfirm>

    //                 </Space>
    //             ),
    //         },
    //     ]
    // }

    onBatchUpdateUpload = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

        this.covertWorker.postMessage({
            records: file,
            sitesInfo: this.state.sitesInfo,
            productsInfo: this.state.productsInfo,
            categorysInfo: this.state.categorysInfo,
            trademarksInfo: this.state.trademarksInfo,
            customItemsInfo: this.state.customItemsInfo,
            isUpdate: true
        })

        this.setState({ updateState: true })

        this.covertWorker.onmessage = (event) => {
            // if (event.data.errorMsg) {
            //     onError(event.data.errorMsg)
            //     message.error(event.data.errorMsg);
            //     return
            // }
            if (event.data.errors && event.data.errors.length > 0) {
                const errorNodes = event.data.errors.map((error) => {
                    // return <Row><Col>{error.errorMsg}</Col></Row>
                    return error.errorMsg
                })
                onError(errorNodes)
                // message.error(errorNodes, 20)
                this.setState({
                    updateState: false,
                    updateErrorMessage: errorNodes
                })
            }

            const formData = new FormData()
            formData.append('clientId', this.props.clientId)
            formData.append('report', event.data.records)

            reqBatchUpdateECReport(
                formData,
                (total, loaded) => {
                    onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
                }).then((response) => {
                    const { data } = response
                    if (data.code === 0) {
                        onSuccess(response, file)
                        this.totalNum = data.data.reports.length
                        this.setState({ reports: data.data.reports })
                    } else {
                        onError(data.message)
                        this.setState({
                            updateState: false,
                            updateErrorMessage: data.message
                        })
                    }
                }).catch((error) => {
                    onError(error)
                    this.setState({
                        updateState: false,
                        updateErrorMessage: error
                    })
                })
        }
    }

    onUploadECRecord = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {
        this.covertWorker.postMessage({
            records: file,
            sitesInfo: this.state.sitesInfo,
            productsInfo: this.state.productsInfo,
            categorysInfo: this.state.categorysInfo,
            trademarksInfo: this.state.trademarksInfo,
            customItemsInfo: this.state.customItemsInfo
        })

        this.setState({ createState: true })
        this.covertWorker.onmessage = (event) => {
            // if (event.data.errorMsg) {
            //     onError(event.data.errorMsg)
            //     message.error(event.data.errorMsg);
            //     return
            // }

            // console.log(event.data.errors)
            if (event.data.errors && event.data.errors.length > 0) {
                const errorNodes = event.data.errors.map((error) => {
                    // return <Row><Col>{error.errorMsg}</Col></Row>
                    return error.errorMsg
                })

                onError(errorNodes)

                this.setState({
                    createState: false,
                    createErrorMessage: errorNodes
                })
                // message.error(errorNodes, 10)
            } else {
                const formData = new FormData()
                formData.append('clientId', this.props.clientId)
                formData.append('report', event.data.records)

                reqUploadECReport(
                    formData,
                    (total, loaded) => {
                        onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
                    }).then((response) => {
                        const { data } = response
                        if (data.code === 0) {
                            onSuccess(response, file)
                            this.totalNum = data.data.reports.length
                            this.setState({ reports: data.data.reports })
                        } else {
                            onError(data.message)
                            this.setState({
                                createState: false,
                                createErrorMessage: data.message
                            })
                        }
                    }).catch((error) => {
                        onError(error)
                        this.setState({
                            createState: false,
                            createErrorMessage: error
                        })
                    })
            }
        }
    }

    handleCreateFileListChange = (info) => {
        let fileList = [...info.fileList]
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2)

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error
            return file
        })
        this.setState({ createUploadFileList: fileList })
    }

    handleUpdateFileListChange = (info) => {
        let fileList = [...info.fileList]

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2)

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error
            return file
        })
        this.setState({ updateUploadFileList: fileList })
    }

    renderFooter = (record) => {
        return <Space size="middle" key="actions" className="master-report-footer">
            <Button className="master-report-footer-operation ef-text-normal" key="detail" type="link" onClick={() => { this.onDetail(record) }}><FormattedMessage id="app.common.action.detail" /></Button>
            <Popconfirm
                key="delete"
                title={this.props.intl.formatMessage(messages["operation.warn.delete_warn"])}
                onConfirm={() => { this.onDeleteReport(record) }}
                okText={this.props.intl.formatMessage(messages["operation.info.confirm"])}
                cancelText={this.props.intl.formatMessage(messages["operation.info.cancel"])}
            >
                <Button className="master-report-footer-operation ef-text-normal" key="delete" type="link" ><FormattedMessage id="app.common.action.delete" /></Button>
            </Popconfirm>
            <Button className="master-report-footer-operation ef-text-normal" type="link" onClick={() => { this.onDownloadReport(record) }}><FormattedMessage id="app.common.action.download" /></Button>
            {/* <Popconfirm
                key="inherit"
                title={this.props.intl.formatMessage(messages["operation.warn.inherit_warn"])}
                onConfirm={() => { this.onInheritReport(record) }}
                okText={this.props.intl.formatMessage(messages["operation.info.confirm"])}
                cancelText={this.props.intl.formatMessage(messages["operation.info.cancel"])}
            >

                <Button className="master-report-footer-operation ef-text-normal" key="inherit" type="link"><FormattedMessage id="app.common.action.inherit" /></Button>
            </Popconfirm> */}
            <Popconfirm
                key="removeDuplicate"
                title={this.props.intl.formatMessage(messages["operation.warn.delete_repeat_record_warn"])}
                onConfirm={() => { this.onDeleteRepeatReport(record) }}
                okText={this.props.intl.formatMessage(messages["operation.info.confirm"])}
                cancelText={this.props.intl.formatMessage(messages["operation.info.cancel"])}
            >
                <Button className="master-report-footer-operation ef-text-normal" key="removeDuplicate" type="link"><FormattedMessage id="app.common.action.remove_duplicate" /></Button>
            </Popconfirm>
            <Popconfirm
                key="send"
                title={<Checkbox onClick={(value) => this.sendUncategorizRecords = value}>{this.props.intl.formatMessage(messages["operation.warn.show_warn"])}</Checkbox>}
                onConfirm={() => { this.onPostReport(record) }}
                onCancel={() => this.sendUncategorizRecords = false}
                okText={this.props.intl.formatMessage(messages["operation.info.open_confirm"])}
                cancelText={this.props.intl.formatMessage(messages["operation.info.open_cancel"])}
            >
                <Button className="master-report-footer-operation ef-text-normal" key="send" type="link"><FormattedMessage id="app.common.action.open" /></Button>
            </Popconfirm>

        </Space>
    }

    renderList = (item) => {
        const getDate = (item, name) => {
            if (item[name]) {
                return moment(item[name]).format("YYYY-MM-DD")
            }
            return ""
        }
        const forwardData = [
            {
                name: 'report_id',
                label: "app.ui.brand_reg_detail.report.list.report_id",
                value: item.report_id,
                type: 'input',
                permission: false,
            }, {
                name: 'report_name',
                label: "app.ui.brand_reg_detail.report.list.report_name",
                value: item.report_name,
                type: 'input',
                permission: false,
            }, {
                name: 'report_upload_date',
                label: "app.ui.brand_reg_detail.report.list.report_upload_date",
                value: getDate(item, 'report_upload_date'),
                type: 'input',
                permission: false,
            }, {
                name: 'report_scrape_count',
                label: "app.ui.brand_reg_detail.report.list.report_scrape_count",
                value: item.report_scrape_count,
                type: 'input',
                permission: false,
            }, {
                name: 'report_repeat_count',
                label: "app.ui.brand_reg_detail.report.list.report_repeat_count",
                value: item.report_repeat_count,
                type: 'input',
                permission: false,
            }
        ]

        const backData = [
            {
                name: "report_send_date",
                label: "app.ui.brand_reg_detail.report.list.report_send_date",
                value: getDate(item, 'report_send_date'),
                type: 'input',
                permission: false
            }, {
                name: "report_uncategrize_count",
                label: "app.ui.brand_reg_detail.report.list.report_uncategrize_count",
                value: item.report_scrape_count - item.report_uncategrize_count,
                type: 'input',
                permission: false
            }, {
                name: 'report_uncategrize_count1',
                label: "app.ui.brand_reg_detail.report.list.report_uncategrize_count1",
                value: item.report_uncategrize_count,
                type: 'input',
                permission: false
            }, {
                name: 'report_response_date',
                label: "app.ui.brand_reg_detail.report.list.report_response_date",
                value: getDate(item, 'report_response_date'),
                type: 'input',
                permission: false
            }, {
                name: 'report_response_count',
                label: "app.ui.brand_reg_detail.report.list.report_response_count",
                value: item.report_response_count,
                type: 'input',
                permission: false
            }
        ]
        return <Row className={'master-report-box'}>
            <Col span={12}>
                <EFBox
                    mode={'single'}
                    data={forwardData}
                    lineWidth={300}
                    state={false}>
                </EFBox>
            </Col>
            <Col span={12}>
                <EFBox
                    mode={'single'}
                    data={backData}
                    lineWidth={300}
                    state={false}>
                </EFBox>
            </Col>
            {this.renderFooter(item)}
        </Row>

    }

    onHandleRemoveNew = () => {
        this.setState({
            createState: true,
            createErrorMessage: ''
        })
    }

    onHandleRemoveUpdate = () => {
        this.setState({
            updateState: true,
            updateErrorMessage: ''
        })
    }

    handlePageChange = (page, size) => {
        this.setState({ page: page, pageSize: size })
    }

    renderNewErrorMessage = () => {
        if (!this.state.createState) {
            return <p className="upload-error">{this.state.createErrorMessage}</p>
        }
    }

    renderUpdateErrorMessage = () => {
        if (!this.state.updateState) {
            return <p className="upload-error">{this.state.updateErrorMessage}</p>
        }
    }

    renderPagination = () => {
        if (this.totalNum > 0) {
            return <Pagination
                pageSize={this.state.pageSize}
                showQuickJumper
                current={this.state.page}
                total={this.totalNum}
                onChange={this.handlePageChange}
                style={{ float: 'right' }}
                showTotal={(total) => this.customLocale.showTotal(total, this.state.pageSize)}
                locale={this.customLocale}
            />
        } else {
            return <div></div>
        }
    }

    render () {
        let isUploadable = !!this.state.sitesInfo && !!this.state.productsInfo && !!this.state.categorysInfo && !!this.state.trademarksInfo && !!this.state.customItemsInfo

        return (
            <div className="master-report-container">
                <div>
                    <div className="report-upload-wrapper">
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onUploadECRecord}
                                disabled={!isUploadable}
                                onChange={this.handleCreateFileListChange}
                                fileList={this.state.createUploadFileList}
                                onRemove={this.onHandleRemoveNew}
                            >
                                <Button className="upload-wrapper-button ef-text-normal">{<FormattedMessage id="app.common.action.new_upload" />}</Button>
                            </Upload>
                            {this.renderNewErrorMessage()}
                        </div>
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onBatchUpdateUpload}
                                disabled={!isUploadable}
                                onChange={this.handleUpdateFileListChange}
                                fileList={this.state.updateUploadFileList}
                                onRemove={this.onHandleRemoveUpdate}
                            >
                                <Button className="upload-wrapper-button ef-text-normal">{<FormattedMessage id="app.common.action.update_upload" />}</Button>
                            </Upload>
                            {this.renderUpdateErrorMessage()}
                        </div>
                    </div>
                </div>
                {/* <Table
                    key="table"
                    columns={this.createColumns()}
                    dataSource={this.state.reports}
                    scroll={{
                        x: "max-content"
                    }}
                /> */}
                {this.state.reports.map((item, index) => {
                    if (index >= (this.state.page - 1) * this.state.pageSize && index < this.state.page * this.state.pageSize) {
                        return this.renderList(item)
                    } else {
                        return ''
                    }
                })}
                {this.renderPagination()}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.user,
        ecmaster: state.ecMasterData
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(AnalystClientECReport)))
