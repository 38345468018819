import request from '@/utils/request'

export function reqCreateAttorneyLetter(data){
    return request({
        url: '/api/analyst/create-attorney-letter',
        method: 'post',
        data: data
    })
}

export function reqRemoveAttorneyLetter(data){
    return request({
        url: '/api/analyst/remove-attorney-letter',
        method: 'post',
        data: data
    })
}

export function reqUpdateAttorneyLetter(data){
    return request({
        url: '/api/analyst/update-attorney-letter',
        method: 'post',
        data: data
    })
}

export function reqFetchAttorneyLetters(data){
    return request({
        url: '/api/analyst/fetch-attorney-letters',
        method: 'post',
        data: data
    })
}
