// PieData:   [{name: 'xxxxx', value:xxxxx}],
// rightYAxisData: [xxxx,xxxx],
// seriesBarData: [xxxx,xxxx]
const genOverallInfringementCountry = (config) => {
    let { echarts, title, xAxisData, yAxisName, yAxisData,
        seriesBarName, seriesBarData, seriesPieName, seriesPieData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let chart = echarts.init(document.getElementById(elementId));
    let option = {
        title: {
            text: title
        },
        tooltip: {
        },
        grid: {
            width: "75%",
            containLabel: false,
            left: 75
        },
        xAxis: {
            type: 'value',
            axisLabel: { rotate: 30 },
            data: xAxisData
        },
        yAxis: [{
            type: 'category',
            name: yAxisName,
            position: "left",
            axisLabel: {
                align: "left",
                inside: false,
                width: 70,
                margin: 70,
                overflow: "truncate",
                ellipsis: "..."
            },
            data: yAxisData,
        }],
        series: [{
            itemStyle: {
                color: new echarts.graphic.LinearGradient(
                    1, 0, 0, 0,
                    [
                        { offset: 0, color: '#83bff6' },
                        { offset: 0.5, color: '#188df0' },
                        { offset: 1, color: '#188df0' }
                    ]
                )
            },
            label: {
                show: true,
                position: 'right',
            },
            name: seriesBarName,
            type: "bar",
            yAxisIndex: 0,
            data: seriesBarData
        }, {
            name: seriesPieName,
            type: "pie",
            center: ['70%', '35%'],
            radius: '50%',
            z: 100,
            data: seriesPieData,
        }]
    };
    chart.setOption(option);
    chart.resize();
    window.addEventListener("resize", function () {
        chart.resize();
    });
}

export default genOverallInfringementCountry;