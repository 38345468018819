import { reqLogin, reqLogout,reqCode } from "@/api/login";
import { setToken, removeToken } from "@/utils/cookie";
import * as types from "../action-types";

export const login = (email, password, code) => (dispatch) => {
  return new Promise((resolve, reject) => {
    reqLogin(email, password, code)
      .then((response) => {
        const { data } = response;
        if (data.code === 0) {
          let token = data.data.token;
          let userid = data.data.userid;

          dispatch(setUserToken(token,userid));
          setToken(token);
          resolve(data);
        } else {
          let msg = data.message;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    reqLogout(token)
      .then((response) => {
        let { data } = response;
        if (data.code === 0) {
          dispatch(resetUser());
          removeToken();
          resolve(data);
        } else {
          let msg = data.message;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setUserToken = (token,userid) => {
  return {
    type: types.AUTH_LOGIN,
    token,
    userid,
  };
};

export const resetUser = () => {
  return {
    type: types.AUTH_LOGOUT,
  };
};

export const getCode = (mail) => (dispatch) =>{
  return new Promise((resolve, reject) => {
    reqCode(mail)
      .then((response) => {
        let { data } = response;
        if (data.code === 0) {
          resolve(data);
        } else {
          let msg = data.message;
          reject(msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}


