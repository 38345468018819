import "./index.less";

import React, { Component } from "react";

import { Divider } from "antd"

class ChartContainer extends Component {
    render() {
        return (
            <div className="chart-container">
                <div className="header">
                    <div className="header-hint"></div>
                    <p>{this.props.title}</p>
                </div>
                <Divider className="divider" />
                <div className="chart-container-body">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default ChartContainer