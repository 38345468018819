import react, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'

// 国际化语言处理
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import commonMessage from '../../../locales/messages'

// redux导入
import store from '@/store'
import { setMenuActiveKey } from '@/store/actions/menu.js'
import {
  setContentContainerHeaderAction,
  setBreadcrumbs,
} from '@/store/actions/content-header.js'

// api接口
import {
  reqSearchECRecords,
  reqUpdateECRecord,
  reqUploadImage,
  reqRemoveUploadImage,
} from '@/api/ec.js'
import { reqFetchECSites } from '../../../api/analyst-site'
import { reqFetchInfringementCategories } from '../../../api/analyst-infringement-category'
import { reqFetchProductCategories } from '../../../api/analyst-product-category'
import { reqSearchTrademarks } from '../../../api/trademark'
import { reqFetchCustomColumns } from '../../../api/analyst-custom-column'
import { reqGetECRecord } from '../../../api/ec'

import {
  LoadingOutlined
} from '@ant-design/icons'

// 工具类
import moment from 'moment'
import common_messages from '../../../locales/messages'
import { getCountryConfigDict } from '../../../config/country'
import messages from './messages'

// 公共配置数据
import ECListCommon from '../common/ec-list-common.js'
import { InfringementCommonCategories } from '../../../config/infringement-common-category'
import {
  TakedownRequest,
  TakedownResult,
} from '../../../config/ec-report-select-items'

// antd导入
import {
  Form,
  Input,
  Row,
  Col,
  Steps,
  DatePicker,
  Select,
  Button,
  message,
  Popover,
  Image,
  Upload,
  Spin
} from 'antd'
import {
  PlusOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons'

// component导入
import EFSimpleContainer from '@/components/ef-simple-container'
import Loading from '@/components/ef-c-loading/loading'
import { useEnsuredForwardedRef } from 'react-use'

import {
  getBasicScrapeColumns,
  getCategorizeColumns,
  getCategorizeTrigger,
  getClientResponse,
  getClientTrigger,
  getFinalResult,
  keyConversion
} from './ec-columns-analysis.js'
import {
  onError
} from './ec-error.js'

import EFCContainer from '../../../components/ef-c-container'


// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
  scriptUrl: '/iconfont/iconfont.js',
})

const { Step } = Steps

const EFECTestDataDetailBackup = () => {
  // 国际化配置
  const intl = useIntl()
  let nameIntlKey = useRef()
  nameIntlKey.current = intl.formatMessage(
    commonMessage['infirgment_common_cateogry.name_locale_key']
  )
  let fontFamily = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

  // 路由跳转
  const history = useHistory()
  // 获取位置信息
  const location = useLocation()
  const locationSearch = useRef()

  useState(() => {
    locationSearch.current = location.search
  }, [location])

  const match = useRouteMatch()
  // 访问全局redux状态数据
  const reduxState = useSelector((state) => state)
  // const reduxState = store.getState()
  // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
  let reduxProps = {
    ...reduxState.user,
  }
  const initialization = (order) => {
    if (order === 'recordId') {
      return parseInt(match.params.rid)
    }
    if (order === 'clientId') {
      if (reduxProps.role & 2) {
        return parseInt(match.params.cid)
      } else {
        return reduxProps.companyInfo.client_id
      }
    }
    let searchParams = new URLSearchParams(locationSearch.current)
    let current = searchParams.get('num')
    current = current ? parseInt(current) : 0
    if (isNaN(current)) {
      current = 0
    }
    let total = searchParams.get('total')
    total = total ? parseInt(total) : 0
    if (isNaN(total)) {
      total = 0
    }
    let offset = searchParams.get('offset')
    offset = offset ? parseInt(offset) : 0
    if (isNaN(offset)) {
      offset = 0
    }

    if (order === 'total') {
      return total
    } else if (order === 'current') {
      return current
    } else if (order === 'offset') {
      return offset
    }
  }
  // ec-list传过来的参数
  const clientId = useRef(initialization('clientId'))
  const recordId = useRef(initialization('recordId'))
  const totalNum = useRef(initialization('total'))
  const offset = useRef(initialization('offset'))
  const num = useRef(initialization('num'))

  const sites = useRef([])
  const categories = useRef([])
  const productCategories = useRef([])
  const trademarks = useRef([])
  const [customColumn, setCustomColumn] = useState([])
  const originSearchParams = useRef()
  const [commonCategory, setCommonCategory] = useState()
  const [record, setRecord] = useState({})

  const [modeSlide, setModeSlide] = useState(null)
  const [stepState, setStepState] = useState([true, false, false, false])
  const [uploadedImageNames, setUploadedImageNames] = useState()
  const [isUpdating, setIsUpdating] = useState(false)

  const formRef = useRef()
  const categoryRef = useRef()
  const clientResponseRef = useRef()
  const reportInformationRef = useRef()
  const reportInformationTem = useRef()
  const customInfoRef = useRef([])

  const [imageError, setImageError] = useState(false)
  const handleImageError = () => {
    setImageError(true)
  }

  const cateTrigger = useRef(
    {
      // 计算方法
      compute: getCategorizeTrigger().compute(),
      // 渲染个数
      item: 0,
      // 图标状态
      state: undefined,
      // 图标渲染
      render: getCategorizeTrigger().render()
    }
  )

  const clientTrigger = useRef(
    {
      compute: getClientTrigger().compute(),
      item: 0,
      state: undefined,
      render: getClientTrigger().render()
    }
  )

  const [scrapeTrigger, setScrapeTrigger] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading(prevLoading => !prevLoading)
  }, [])

  const [selectResultStep, setSelectResultStep] = useState(0)

  const setCustomColumnFormValues = (customCoulumns, record) => {
    if (customCoulumns && record) {
      customInfoRef.current = []
      customCoulumns.forEach((column) => {
        const fieldName = column.column_name
        let fieldValue = {}
        try {
          let obj = {}
          obj = JSON.parse(record['custom_info'])
          fieldValue['name'] = column.column_name
          fieldValue['category'] = column.column_category
          fieldValue['value'] = obj[column.column_name]
        } catch (e) { }
        customInfoRef.current.push(fieldValue)
      })
    }
  }

  const setFormValues = (record) => {
    getCategorizeColumns(reduxProps.role, intl).map((item, index) => {
      if (item.initData) {
        categoryRef.current.setFieldsValue(item.initData(record))
      }
    })
    getClientResponse(reduxProps.role, intl).map((item, index) => {
      if (item.initData) {
        clientResponseRef.current.setFieldsValue(item.initData(record))
      }
    })
    getFinalResult(reduxProps.role, intl).map((item, index) => {
      if (item.initData) {
        reportInformationRef.current.setFieldsValue(item.initData(record))
        let obj = {}
        obj[item.key] = record[item.key]
        reportInformationTem.current = { ...reportInformationTem.current, ...obj }
      }
    })
  }

  useEffect(() => {
    reqFetchECSites()
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          sites.current = data.data.sites
        }
      })
      .catch((error) => { })

    reqFetchInfringementCategories({ clientId: clientId.current })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          categories.current = data.data.categories
        }
      })
      .catch((error) => { })

    reqFetchProductCategories({
      clientId: clientId.current,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          productCategories.current = data.data.categories
        }
      })
      .catch((error) => { })

    // trademarks
    reqSearchTrademarks({ clientId: clientId.current })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          trademarks.current = data.data.trademarks
        }
      })
      .catch((error) => { })

    reqFetchCustomColumns({
      clientId: clientId.current,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          setCustomColumn(data.data.columns)
        }
      })
      .catch((error) => { })

    originSearchParams.current = new URLSearchParams(location.search)
    if (reduxProps.role & 2) {
      store.dispatch(setMenuActiveKey('analyst-ec-list'))
    } else {
      store.dispatch(setMenuActiveKey('client-ec-list'))
    }
    store.dispatch(setContentContainerHeaderAction([]))
    store.dispatch(
      setBreadcrumbs([
        {
          name: <FormattedMessage id="app.layout.menu.home" />,
          path: '/',
        },
        {
          name: (
            <FormattedMessage id="app.layout.menu.online_protection.ec_record_list" />
          ),
          path: `/ec-list?${originSearchParams.current.toString()}`,
        },
        {
          name: <FormattedMessage id="app.ui.ec_detail.title" />,
        },
      ])
    )

    reqGetECRecord({
      clientId: clientId.current,
      recordId: recordId.current
    }).then((response) => {
      const { data } = response
      if (data.code === 0) {
        let commonCategory = InfringementCommonCategories.find(
          (commonCategory) => {
            return (
              commonCategory.id ===
              data.data.record['ec_category_common_type_id']
            )
          }
        )
        setRecord(data.data.record)
        setFormValues(data.data.record)
        if (commonCategory) {
          setCommonCategory(commonCategory.id)
        }
        setUploadedImageNames(data.data.record.ec_upload_image_names)
      }
    })

  }, [])

  useEffect(() => {
    if (cateTrigger.current.state === undefined) {
      cateTrigger.current.item = cateTrigger.current.compute(true)
      cateTrigger.current.state = false
    }
    if (clientTrigger.current.state === undefined) {
      clientTrigger.current.item = clientTrigger.current.compute(true)
      clientTrigger.current.state = false
    }

    setCustomColumnFormValues(
      customColumn,
      record
    )
    toggleLoading()
  }, [record, customColumn])

  const onHandleFormValue = (key, value, name) => {
    // 实时更新commomCategory
    if (name === 'ec_category_id') {
      let indx = categories.current.findIndex((item) => {
        return record['ec_category_rule_id'] === item.id
      })
      if (indx > -1) {
        let indx1 = categories.current[indx].subcategories.findIndex((item1) => {
          return item1.id === value
        })
        setCommonCategory(categories.current[indx].subcategories[indx1].commonCategory)
      }
    }
    // 更新定制元素

    let customIndex = customInfoRef.current.findIndex((item) => {
      return name === item.name
    })
    if (customIndex > -1) {
      customInfoRef.current[customIndex].value = value
      return
    }

    if (key === 'categoryInfo') {
      let obj = {}
      obj[name] = value
      categoryRef.current.setFieldsValue(obj)
    } else if (key === 'clientResponse') {
      let obj = {}
      obj[name] = value
      clientResponseRef.current.setFieldsValue(obj)
    } else if (key === 'finalResult') {
      let obj = {}
      obj[name] = value
      reportInformationRef.current.setFieldsValue(obj)
      reportInformationTem.current[name] = value
    }
  }

  const onHandleUpdate = () => {
    let obj = {}
    Object.keys(categoryRef.current.getFieldValue()).map((item, index) => {
      obj[item] = categoryRef.current.getFieldValue(item)
    })
    Object.keys(clientResponseRef.current.getFieldValue()).map((item, index) => {
      obj[item] = clientResponseRef.current.getFieldValue(item)
    })
    Object.keys(reportInformationTem.current).map((item, index) => {
      obj[item] = reportInformationTem.current[item]
    })

    let cate = categoryRef.current.getFieldValue()
    if (cate['ec_categorize_date']) {
      obj['ec_categorize_date'] = cate['ec_categorize_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_categorize_date'] = null
    }

    let client = clientResponseRef.current.getFieldValue()
    if (client['ec_response_date']) {
      obj['ec_response_date'] = client['ec_response_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_response_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process1_date'] === 'string' && reportInformationTem.current['ec_process1_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process1_date'])
      obj['ec_process1_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process1_date'] !== null && reportInformationTem.current['ec_process1_date'] !== undefined && reportInformationTem.current['ec_process1_date'] !== '') {
      obj['ec_process1_date'] = reportInformationTem.current['ec_process1_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process1_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process1_confirm_date'] === 'string' && reportInformationTem.current['ec_process1_confirm_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process1_confirm_date'])
      obj['ec_process1_confirm_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process1_confirm_date'] !== null && reportInformationTem.current['ec_process1_confirm_date'] !== undefined && reportInformationTem.current['ec_process1_confirm_date'] !== '') {
      obj['ec_process1_confirm_date'] = reportInformationTem.current['ec_process1_confirm_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process1_confirm_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process2_date'] === 'string' && reportInformationTem.current['ec_process2_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process2_date'])
      obj['ec_process2_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process2_date'] !== null && reportInformationTem.current['ec_process2_date'] !== undefined && reportInformationTem.current['ec_process2_date'] !== '') {
      obj['ec_process2_date'] = reportInformationTem.current['ec_process2_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process2_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process2_confirm_date'] === 'string' && reportInformationTem.current['ec_process2_confirm_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process2_confirm_date'])
      obj['ec_process2_confirm_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process2_confirm_date'] !== null && reportInformationTem.current['ec_process2_confirm_date'] !== undefined && reportInformationTem.current['ec_process2_confirm_date'] !== '') {
      obj['ec_process2_confirm_date'] = reportInformationTem.current['ec_process2_confirm_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process2_confirm_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process3_date'] === 'string' && reportInformationTem.current['ec_process3_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process3_date'])
      obj['ec_process3_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process3_date'] !== null && reportInformationTem.current['ec_process3_date'] !== undefined && reportInformationTem.current['ec_process3_date'] !== '') {
      obj['ec_process3_date'] = reportInformationTem.current['ec_process3_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process3_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process3_confirm_date'] === 'string' && reportInformationTem.current['ec_process3_confirm_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process3_confirm_date'])
      obj['ec_process3_confirm_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process3_confirm_date'] !== null && reportInformationTem.current['ec_process3_confirm_date'] !== undefined && reportInformationTem.current['ec_process3_confirm_date'] !== '') {
      obj['ec_process3_confirm_date'] = reportInformationTem.current['ec_process3_confirm_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process3_confirm_date'] = null
    }

    if (typeof reportInformationTem.current['ec_process_confirm_date'] === 'string' && reportInformationTem.current['ec_process_confirm_date'] !== '') {
      const dateObject = new Date(reportInformationTem.current['ec_process_confirm_date'])
      obj['ec_process_confirm_date'] = dateObject.toISOString().split("T")[0]
    } else if (reportInformationTem.current['ec_process_confirm_date'] !== null && reportInformationTem.current['ec_process_confirm_date'] !== undefined && reportInformationTem.current['ec_process_confirm_date'] !== '') {
      obj['ec_process_confirm_date'] = reportInformationTem.current['ec_process_confirm_date'].format('YYYY-MM-DD')
    } else {
      obj['ec_process_confirm_date'] = null
    }

    let warn = onError(obj, reduxProps.role)
    if (warn) {
      message.error(warn)
      return
    }

    let keyConver = keyConversion()
    let newObj = {}
    Object.keys(obj).map((item, index) => {
      if (keyConver[item]) {
        let key = keyConver[item]
        newObj[key] = obj[item]
      }
    })

    let customObj = {}
    customInfoRef.current.forEach((item, index) => {
      if (item.value !== undefined) {
        customObj[item.name] = item.value
      } else {
        customObj[item.name] = ''
      }
    })

    newObj['customInfo'] = JSON.stringify(customObj)
    newObj['clientId'] = clientId.current
    newObj['recordId'] = record.ec_id

    setIsUpdating(true)
    reqUpdateECRecord(newObj)
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          setRecord(data.data.record)
          setFormValues(data.data.record)
          setIsUpdating(false)
          setUploadedImageNames(data.data.record.ec_upload_image_names)
          message.success(
            intl.formatMessage(
              messages['message.info.update_success']
            )
          )
        }
      })
      .catch((error) => {
        setIsUpdating(false)
        message.error(
          intl.formatMessage(messages['message.error.update_failed'])
        )
      })
  }

  const onHandleGoBackList = () => {
    let search = {}
    if (originSearchParams.current.has('dateFields')) {
      search['dateFields'] = originSearchParams.current.get('dateFields').split(',')
      originSearchParams.current.delete('dateFields')
    }
    if (originSearchParams.current.has('startDate')) {
      search['startDate'] = originSearchParams.current.get('startDate')
      originSearchParams.current.delete('startDate')
    }
    if (originSearchParams.current.has('endDate')) {
      search['endDate'] = originSearchParams.current.get('endDate')
      originSearchParams.current.delete('endDate')
    }
    if (originSearchParams.current.has('wordFields')) {
      search['wordFields'] = originSearchParams.current.get('wordFields').split(',')
      originSearchParams.current.delete('wordFields')
    }
    if (originSearchParams.current.has('keyword')) {
      search['keyword'] = originSearchParams.current.get('keyword')
      originSearchParams.current.delete('keyword')
    }
    if (originSearchParams.current.has('searchType')) {
      search['searchType'] = parseInt(originSearchParams.current.get('searchType'))
      originSearchParams.current.delete('searchType')
    }
    if (originSearchParams.current.has('whereFields')) {
      let filters = originSearchParams.current.get('whereFields')
      originSearchParams.current.delete('whereFields')
      originSearchParams.current.set('filters', filters)
    }

    originSearchParams.current.set('search', JSON.stringify(search))
    history.push(`/ec-list?${originSearchParams.current}`)
  }

  const onHandleImage = (values) => {
    if (values.file.status === 'removed') {
      // values.file.uid
      const imageNames = values.fileList
        .map((info) => {
          return info.uid
        })
        .join(',')

      setUploadedImageNames(imageNames)
      reqRemoveUploadImage({
        clientId: clientId.current,
        recordId: recordId.current,
        name: values.file.uid,
      })
        .then((response) => {
          let { data } = response
          if (data.code === 0) {
          }
        })
        .catch((e) => { })
    }
  }

  const onHanldeUploadImage = ({
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const formData = new FormData()
    formData.append('clientId', clientId.current)
    formData.append('recordId', recordId.current)
    formData.append('file', file)

    reqUploadImage(formData, (total, loaded) => {
      onProgress(
        { percent: Math.round((loaded / total) * 100).toFixed(2) },
        file
      )
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          onSuccess(response, file)
          setUploadedImageNames(data.data.names)
        }
      })
      .catch((error) => {
        onError(error)
      })
  }

  const onCollapse = (order) => {
    if (order === 'categoryInfo') {
      cateTrigger.current.state = !cateTrigger.current.state
      cateTrigger.current.item = cateTrigger.current.compute()
    } else if (order === 'clientRespnse') {
      clientTrigger.current.state = !clientTrigger.current.state
      clientTrigger.current.item = clientTrigger.current.compute()
    }
    toggleLoading()
  }

  const initSlideValue = (item) => {
    if (modeSlide === null && record[item]) {
      setModeSlide(parseInt(record[item]))
    } else {

    }
  }

  const initSelectValue = (item, record) => {
    if (!Array.isArray(item)) {
      if (item === 'categories') {
        let indx = categories.current.findIndex((item) => {
          return item.id === record['ec_category_rule_id']
        })
        if (indx > -1) {
          let array = []
          categories.current[indx].subcategories.map((item, index) => {
            if (nameIntlKey.current === 'en') {
              array.push({ value: item.id, label: item.name_en })
            } else if (nameIntlKey.current === 'ja') {
              array.push({ value: item.id, label: item.name })
            }
          })
          return array
        } else {
          return []
        }
      } else if (item === 'productType') {
        if (productCategories.current.length > 0) {
          let array = []
          productCategories.current.map((item, index) => {
            if (nameIntlKey.current === 'en') {
              array.push({ value: item.id, label: item.name_en })
            } else if (nameIntlKey.current === 'ja') {
              array.push({ value: item.id, label: item.name })
            }
          })
          return array
        } else {
          return []
        }
      } else if (item === 'trademark') {
        if (trademarks.current.length > 0) {
          let array = []
          trademarks.current.map((item, index) => {
            array.push({ value: item.trademark_id, label: item.trademark_no })
          })
          return array
        } else {
          return []
        }
      } else if (item === 'takedownResult') {
        if (TakedownResult.length > 0) {
          let array = []
          TakedownResult.map((item, index) => {
            array.push({ value: item.value, label: item.name[nameIntlKey.current] })
          })
          return array
        } else {
          return []
        }
      }
    } else {
      return []
    }
  }

  const initTextValue = (item, record) => {
    if (item.renderParams.array === 'categoryRule') {
      let indx = categories.current.findIndex((current) => {
        return current.id === record[item.key]
      })
      if (indx > -1) {
        if (nameIntlKey.current === 'en') {
          return categories.current[indx].name_en
        } else if (nameIntlKey.current === 'ja') {
          return categories.current[indx].name
        }
      } else {
        return ''
      }
    } else if (item.renderParams.array === 'commonCategory') {
      let indx = InfringementCommonCategories.findIndex((current) => {
        return current.id === record[item.key]
      })
      if (indx > -1) {
        return InfringementCommonCategories[indx].name[nameIntlKey.current]
      } else {
        return ''
      }
    } else {
      return record[item.key]
    }
  }

  const renderPopoverContent = (items, record) => {
    return <div style={{ padding: '10px' }}>
      {items.map((item, index) => {
        let value
        if (item.key === 'ec_category_common_type_id') {
          let indx = InfringementCommonCategories.findIndex((item1) => {
            return item1.id === commonCategory
          })
          if (indx > -1) {
            value = InfringementCommonCategories[indx].name[nameIntlKey.current]
          }
        } else if (item.key === 'ec_category_rule_id') {
          let indx = categories.current.findIndex((item1) => {
            return item1.id === record['ec_category_rule_id']
          })
          if (indx > -1) {
            if (nameIntlKey.current === 'en') {
              value = categories.current[indx].name_en
            } else if (nameIntlKey.current === 'ja') {
              value = categories.current[indx].name
            }
          }
        }
        return <div className='ef-popover-item'>
          <div className='ef-popover-item-title'>{item.title} :</div>
          <div className='ef-popover-item-value'>{value}</div>
        </div>
      })}
    </div>
  }

  const renderPopover = (item, record) => {
    return <Popover placement="rightTop" trigger="hover" content={renderPopoverContent(item.items, record)}>{item.render}</Popover>
  }

  const renderValue = (key, type, obj, record) => {
    if (type === 'time') {
      if (obj.isOpera === true) {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Form.Item name={obj.dataIndex}>
            <DatePicker size='large'
              allowClear
              className='ef-detail-category-item-action'
              style={{ width: '100%' }}
              onChange={(dateString) => { dateString ? onHandleFormValue(key, moment(dateString), obj.dataIndex) : onHandleFormValue(key, '', obj.dataIndex) }} />
          </Form.Item>
        </div>
      } else {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Input size='large'
            disabled={true}
            value={record[obj.dataIndex] ? moment(record[obj.dataIndex]).format('YYYY-MM-DD') : ''}
            className='ef-detail-category-item-action'
            style={{ width: '100%' }} ></Input>
        </div>
      }
    } else if (type === 'select') {
      let array = initSelectValue(obj.renderParams.array, record)
      if (obj.isOpera === true) {
        return <div>
          <p className='ef-detail-category-item-title'
            style={{ display: (obj.renderPopover && obj.renderPopover.renderState && obj.renderPopover.renderState === true) ? 'inline-block' : 'block' }}
          >{obj.title}</p>
          {obj.renderPopover && obj.renderPopover.renderState && obj.renderPopover.renderState === true ? renderPopover(obj.renderPopover, record) : ''}
          <Form.Item name={obj.dataIndex}>
            <Select size='large'
              options={array}
              className='ef-detail-category-item-action'
              onChange={(value) => { onHandleFormValue(key, value, obj.dataIndex) }} />
          </Form.Item>
        </div>
      } else {
        return <div>
          <p className='ef-detail-category-item-title'
            style={{ display: (obj.renderPopover && obj.renderPopover.renderState && obj.renderPopover.renderState === true) ? 'inline-block' : 'block' }}
          >{obj.title}</p>
          {obj.renderPopover && obj.renderPopover.renderState && obj.renderPopover.renderState === true ? renderPopover(obj.renderPopover, record) : ''}
          <Form.Item name={obj.dataIndex}>
            <Select size='large'
              disabled={true}
              options={array}
              className='ef-detail-category-item-action'
            />
          </Form.Item>
        </div>
      }
    } else if (type === 'input') {
      if (obj.isOpera === true) {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Form.Item name={obj.dataIndex}>
            <Input size='large' value={record[obj.dataIndex] !== undefined ? record[obj.dataIndex] : ''}
              className='ef-detail-category-item-action'
              style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}
              onChange={(value) => { onHandleFormValue(key, value.target.value, obj.dataIndex) }} />
          </Form.Item>
        </div>
      } else {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Form.Item name={obj.dataIndex}>
            <Input size='large' value={record[obj.dataIndex] !== undefined ? record[obj.dataIndex] : ''}
              disabled={true}
              className='ef-detail-category-item-action'
              style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}
              onChange={(value) => { onHandleFormValue(key, value.target.value, obj.dataIndex) }} />
          </Form.Item>
        </div>
      }
    } else if (type === 'textarea') {
      if (obj.isOpera === true) {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Form.Item name={obj.dataIndex}>
            <Input.TextArea size='large'
              className='ef-detail-category-item-action'
              onChange={(value) => { onHandleFormValue(key, value.target.value, obj.dataIndex) }}
              style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }} />
          </Form.Item>
        </div>
      } else {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Form.Item name={obj.dataIndex}>
            <Input.TextArea size='large'
              className='ef-detail-category-item-action'
              disabled={true}
              onChange={(value) => { onHandleFormValue(key, value.target.value, obj.dataIndex) }}
              style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }} />
          </Form.Item>
        </div>
      }
    } else if (type === 'mode') {
      { initSlideValue(obj.dataIndex) }
      return <div>
        <p className='ef-detail-category-item-title'>{obj.title}</p>
        <Form.Item name={obj.dataIndex}>
          <div className='ef-detail-category-item-action  ef-detail-category-item-slide' style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}>
            {obj.renderParams.array.map((item, index) => {
              return (
                <div className='ef-detail-slide-item-section'>
                  <div className='ef-detail-slide-item-one'>
                    <div
                      className={`${item.value === modeSlide ? 'ef-detail-slide-active' : 'ef-detail-slide-wait'}`}
                      onClick={() => { onHandleFormValue(key, index + 1, obj.dataIndex); setModeSlide(index + 1) }}
                    >{item.name[nameIntlKey.current]}
                    </div>
                    {index !== obj.renderParams.array.length - 1 ? <div className='ef-detail-slide-gap'>&#124;</div> : ''}
                  </div>
                </div>
              )
            })}
          </div>
        </Form.Item>
      </div>
    } else if (type === 'state') {
      return <div>
        <p className='ef-detail-category-item-title'>{obj.title}</p>
        <div className='ef-detail-category-item-state' style={{ minHeight: obj.renderParams.height ? obj.renderParams.height : 40 }}>
          {obj.dataIndex.map((item, index) => {
            return <div className='ef-detail-state-item'>
              <p className='ef-detail-state-desc'>{obj.name[index]}</p>
              {record[obj.key[index]] === true ? <div className='ef-detail-state-true'></div> : <div className='ef-detail-state-false'></div>}
            </div>
          })}
        </div>
      </div>
    } else if (type === 'text') {
      return <div>
        <p className='ef-detail-category-item-title'>{obj.title}</p>
        <Form.Item name={obj.dataIndex}>
          <Input size='large' value={record[obj.dataIndex] !== undefined ? record[obj.dataIndex] : ''}
            className='ef-detail-category-item-action'
            style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}
            readOnly />
        </Form.Item>
      </div>
    } else if (type === 'custom') {
      let custom = customInfoRef.current.find(item => item.name === obj.dataIndex)
      if (obj.isOpera === true) {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Input size='large'
            className='ef-detail-category-item-action'
            defaultValue={custom.value}
            style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}
            onChange={(event) => { onHandleFormValue(key, event.target.value, obj.dataIndex) }}
          />
        </div>
      } else {
        return <div>
          <p className='ef-detail-category-item-title'>{obj.title}</p>
          <Input size='large'
            className='ef-detail-category-item-action'
            defaultValue={custom.value}
            style={{ height: obj.renderParams.height ? obj.renderParams.height : 40 }}
            disabled={true}
          />
        </div>
      }
    }
  }

  const renderFinalResult = () => {
    return <Steps
      className='ef-detail-category-item-title'
      current={selectResultStep}
      onChange={(current) => {
        setSelectResultStep(current)
        let state = stepState
        state = state.map((item, index) => {
          return false
        })
        state[current] = !state[current]
        setStepState(state)
      }}>
      <Step
        title={<FormattedMessage id="app.ui.ec_detail.step.1" />}
        status={stepState[0] ? 'finish' : 'wait'}
      />
      <Step
        title={<FormattedMessage id="app.ui.ec_detail.step.2" />}
        status={stepState[1] ? 'finish' : 'wait'}
      />
      <Step
        title={<FormattedMessage id="app.ui.ec_detail.step.3" />}
        status={stepState[2] ? 'finish' : 'wait'}
      />
      <Step
        title={<FormattedMessage id="app.ui.ec_detail.step.4" />}
        status={stepState[3] ? 'finish' : 'wait'}
      />
    </Steps>
  }

  const renderUploadButton = () => {
    return (<div className='ef-detail-upload-add'>
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        <FormattedMessage id="app.common.action.upload" />
      </div>
    </div>
    )
  }

  const renderUpload = () => {
    if (reduxProps.role & 2) {
      return <div className='ef-detail-screenshot'>
        <p className='ef-detail-screenshot-title'><FormattedMessage id="app.ui.ec_detail.tab.screenshot" /></p>
        <div className='ef-detail-screenshot-upload'>
          <Upload
            accept=".png,.jpg,.jpeg"
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={
              uploadedImageNames
                ? uploadedImageNames.split(',').map((name) => {
                  return {
                    uid: name,
                    name: name,
                    status: 'done',
                    url: `${process.env.REACT_APP_BASE_API}/api/ec-data/get-upload-pic?recordId=${recordId.current}&clientId=${clientId.current}&name=${name}`,
                  }
                })
                : []
            }
            showUploadList={{
              showPreviewIcon: true,
              showRemoveIcon: true,
              showDownloadIcon: true,
            }}
            onChange={onHandleImage}
            customRequest={onHanldeUploadImage}
          >
            {renderUploadButton()}
          </Upload>
        </div>
      </div>
    } else if (reduxProps.role & 1) {
      if (uploadedImageNames) {
        return (
          <div className='ef-detail-screenshot-readOnly'>
            <Image.PreviewGroup>
              {uploadedImageNames.split(',').map((name) => {
                return (
                  <div className='ef-detail-screenshot-readOnly-item'>
                    <Image
                      width={100}
                      src={`${process.env.REACT_APP_BASE_API}/api/ec-data/get-upload-pic?recordId=${recordId.current}&clientId=${clientId.current}&name=${name}`}
                    />
                  </div>
                )
              })}
            </Image.PreviewGroup>
          </div>
        )
      } else {
        return <></>
      }
    }
  }

  const renderOther = (order) => {
    if (order === false) {
      return <Col span={24}>
        <div
          onClick={() => { setScrapeTrigger(true) }}
          className='ef-scrape-extend'>
          <DownOutlined />
        </div>
      </Col >
    } else {
      return <Col span={24}>
        <div className='ef-detail-other'>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.id" />:&nbsp;</p><p>{record['ec_id']}</p></div>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.ec_scan_date" />:&nbsp;</p><p>{record['ec_scan_date'] !== undefined ? record['ec_scan_date'] : ''}</p></div>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.ec_search_keyword" />:&nbsp;</p><p>{record['ec_search_keyword']}</p></div>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.ec_no" />:&nbsp;</p><p>{record['ec_no']}</p></div>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.ec_product_no" />:&nbsp;</p><p>{record['ec_product_no']}</p></div>
          <div className='ef-detail-desc'><p><FormattedMessage id="app.ui.eclist.table.ec_product_brand" />:&nbsp;</p><p>{record['ec_product_brand']}</p></div>
          {customInfoRef.current.map((item, index) => {
            if (item.category === 1) {
              return <div className='ef-detail-desc'><p>{item.name}:&nbsp;</p><p>{item.value}</p></div>
            }
          })}
        </div>
        <div
          onClick={() => { setScrapeTrigger(false) }}
          className='ef-scrape-shrink'>
          <UpOutlined />
        </div>
      </Col >
    }
  }

  const renderScrapeColumns = () => {
    return <div className='ef-detail-scrape-section'>
      {imageError ? (
        <div className='ef-detail-image-error'>
          <Image width={150} height={240} preview={false} src='/images/image-error.svg' />
        </div>
      ) : (
        <Image
          width={480}
          height={480}
          preview={false}
          src={record['ec_product_image_url'] ? record['ec_product_image_url'] : 'error'}
          // placeholder={<LoadingOutlined style={{ color: '#e9e9e9', fontSize: 80, marginLeft: 47.5, marginTop: 47.5 }} />}
          onError={() => { handleImageError() }}
        />)}
      <div className='ef-detail-scrape'>
        <p className='ef-detail-describe'>{record['ec_product_title']}</p>
        <div className='ef-detail-sold-detail'>
          <p className='ef-detail-currency'>{record['ec_product_currency']}</p>
          <p className='ef-detail-price'>&nbsp;{record['ec_product_price']}</p>
        </div>
        <div>
          <div className='ef-detail-sold' >
            <p className='ef-detail-sold-inventory'><FormattedMessage id="app.ui.eclist.table.ec_product_onsell_count" />:&nbsp;{record['ec_product_onsell_count']}</p>
          </div>
          <div className='ef-detail-sold'>
            <p className='ef-detail-sold-number'>|&nbsp;&nbsp;<FormattedMessage id="app.ui.eclist.table.ec_product_sold_count" />:&nbsp;{record['ec_product_sold_count']}</p>
          </div>
        </div>

        <div className='ec-detail-region'>
          <div className='ef-detail-region-item'>
            <div className='ef-detail-site'>
              <div className='ef-detail-region-icon'><IconFont className='iconfont-light' type='icon-wangzhan' /></div>
              <div className='ef-detail-region-text'>{record['ec_site']}</div>
            </div>
          </div>
          <div className='ef-detail-region-item ef-detail-country'>
            <div className='ef-detail-region-icon'><IconFont className='iconfont-light' type='icon-diqu' /></div>
            <div className='ef-detail-region-gap'>{record['ec_product_region']}</div>
            {record['ec_product_city'] ? <div className='ef-detail-region-text'>
              <div>-{record['ec_product_city']}</div>
            </div> : ''}
          </div>
          <div className='ef-detail-region-item'>
            <div className='ef-detail-seller'>
              <div className='ef-detail-region-icon'><IconFont className='iconfont-light' type='icon-shangjia' /></div>
              <a className='ef-detail-region-text' target='_blank' href={record['ec_product_seller_url']}
                style={{ pointerEvents: record['ec_product_seller_url'] ? 'auto' : 'none' }} rel="noreferrer">{record['ec_product_seller']}</a>
            </div>
          </div>
        </div>

        <div className='ef-detail-url'>
          <a className='ef-detail-link' href={record['ec_product_url'] ? record['ec_product_url'] : ''}
            target='_blank'
            style={{ pointerEvents: record['ec_product_url'] ? 'auto' : 'none' }} rel="noreferrer"><FormattedMessage id="app.ui.ec_detail.orginal_url" />&nbsp;&nbsp; &gt;</a>
          <a className='ef-detail-link' href={record['ec_product_image_url'] ? record['ec_product_image_url'] : ''}
            target='_blank'
            style={{ pointerEvents: record['ec_product_image_url'] ? 'auto' : 'none' }} rel="noreferrer"><FormattedMessage id="app.ui.ec_detail.image_url" />&nbsp;&nbsp; &gt;</a>
        </div>
        {renderUpload()}
        {scrapeTrigger === false ? renderOther(false) : renderOther(true)}
      </div>
    </div>
  }

  const renderData = () => {
    let categoryInfo = getCategorizeColumns(reduxProps.role, intl)
    let clientResponse = getClientResponse(reduxProps.role, intl)
    let finalResult = getFinalResult(reduxProps.role, intl)

    customInfoRef.current.map((item, index) => {
      if (item.category === 2) {
        let obj = {
          renderState: true,
          layout: 24,
          height: 40,
          renderType: 'custom',
        }
        let initData = () => { return 12 }
        let isOpera = reduxProps.role & 2 ? true : false
        categoryInfo.push({ title: item.name, key: item.name, dataIndex: item.name, renderParams: obj, initData: initData, isOpera: isOpera })
      } else if (item.category === 3) {
        let obj = {
          renderState: true,
          layout: 24,
          height: 40,
          renderType: 'custom',
        }
        let initData = () => { return 12 }
        let isOpera = reduxProps.role & 2 ? true : false
        clientResponse.push({ title: item.name, key: item.name, dataIndex: item.name, renderParams: obj, initData: initData, isOpera: isOpera })
      } else if (item.category === 4) {
        let obj = {
          renderState: true,
          layout: 24,
          height: 40,
          renderType: 'custom',
        }
        let initData = () => { return 12 }
        let isOpera = reduxProps.role & 2 ? true : false
        finalResult.push({ title: item.name, key: item.name, dataIndex: item.name, renderParams: obj, initData: initData, isOpera: isOpera })
      }
    })
    return { categoryInfo, clientResponse, finalResult }
  }

  let { categoryInfo, clientResponse, finalResult } = renderData()

  let category = 1
  let ClientResponse = 1

  return (
    <div style={{ position: 'relative' }}>
      <div className={`ef-detail-container ${fontFamily}`}>
        <EFCContainer
          style={{ padding: '32px 32px 0px 32px' }}>
          <div className='ef-detail-content'>
            <div>
              <Form
                onFinish={() => { }}
                onFinishFailed={() => { }}
                ref={formRef}
              >
                {renderScrapeColumns(reduxProps.role, intl)}
              </Form>
            </div>
            <div className='ef-detail-information'>
              <div className='ef-detail-Categorized'>
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <div className='ef-detail-title'>
                      <div className='ef-detail-title-bar'></div>
                      <p className='ef-detail-title-desc'>{<FormattedMessage id="app.ui.ec_detail.tab.categorized_info" />}</p>
                    </div>
                    <div className='ef-detail-content-category'>
                      <Form
                        onFinish={() => { }}
                        onFinishFailed={() => { }}
                        ref={categoryRef}>
                        <Row gutter={16}>
                          {categoryInfo.map((item, index) => {
                            if (item.renderParams && item.renderParams.renderState && item.renderParams.renderState === true) {
                              if (category <= cateTrigger.current.item) {
                                category += 1
                                return <Col span={item.renderParams.layout}>{renderValue('categoryInfo', item.renderParams.renderType, item, record)}</Col>
                              } else {
                                return <div className='ef-detail-item-hide'>{renderValue('categoryInfo', item.renderParams.renderType, item, record)}</div>
                              }
                            }
                          })}
                          <Col span={24} >
                            {<div onClick={() => { onCollapse('categoryInfo') }}>{cateTrigger.current.render(cateTrigger.current.state)}</div>}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='ef-detail-title'>
                      <div className='ef-detail-title-bar'></div>
                      <p className='ef-detail-title-desc'>{<FormattedMessage id="app.ui.ec_detail.tab.client_response" />}</p>
                    </div>
                    <div className='ef-detail-content-category'>
                      <Form
                        onFinish={() => { }}
                        onFinishFailed={() => { }}
                        ref={clientResponseRef}>
                        <Row gutter={16}>
                          {clientResponse.map((item, index) => {
                            if (item.renderParams && item.renderParams.renderState && item.renderParams.renderState === true) {
                              if (ClientResponse <= clientTrigger.current.item) {
                                ClientResponse += 1
                                return <Col span={item.renderParams.layout}>{renderValue('clientResponse', item.renderParams.renderType, item, record)}</Col>
                              } else {
                                return <div className='ef-detail-item-hide'>{renderValue('clientResponse', item.renderParams.renderType, item, record)}</div>
                              }
                            }
                          })}
                          <Col span={24} >
                            {<div onClick={() => { onCollapse('clientRespnse') }}>{clientTrigger.current.render(clientTrigger.current.state)}</div>}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                <div className='ef-detail-title'>
                  <div className='ef-detail-title-bar'></div>
                  <p className='ef-detail-title-desc'>{<FormattedMessage id="app.ui.ec_detail.tab.ipf_report_info" />}</p>
                </div>
                <div className='ef-detail-content-category'>
                  <Form
                    onFinish={() => { }}
                    onFinishFailed={() => { }}
                    ref={reportInformationRef}>
                    <Row gutter={[32, 0]}>
                      <Col span={24}>
                        {renderFinalResult()}
                      </Col>
                      {finalResult.map((item, index) => {
                        return item.renderParams && item.renderParams.renderState && item.renderParams.renderState === true && item.renderParams.matchState === selectResultStep ?
                          <Col span={item.renderParams.layout}>
                            {renderValue('finalResult', item.renderParams.renderType, item, record)}
                          </Col> : ''
                      })}
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className='ef-detail-footer'>
            <div className='ef-detail-footer-control'>
              <Button className='ef-button-linear ef-detail-footer-reset' onClick={() => { onHandleGoBackList() }}>{<FormattedMessage id="app.ui.ec_detail.button.back" />}</Button>
              <Button className='ef-button-color' htmlType='submit' onClick={() => { onHandleUpdate() }}>{<FormattedMessage id="app.common.action.update" />}</Button>
            </div>
          </div>
        </EFCContainer >
      </div >
      <div className='ef-detail-cover' style={{ display: isUpdating ? 'block' : 'none' }}></div>
      <Spin className='ef-detail-loading' size='large' spinning={isUpdating}></Spin>
    </div>
  )
}
export default EFECTestDataDetailBackup    