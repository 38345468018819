import React, { Component } from 'react'
import { Layout } from 'antd'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import EFCBreadcrumb from '@/components/ef-c-breadcrumb'
import EFUpdateUserInfoView from '@/views/setting/update-userinfo'
import EFUpdateUserInfoViewBackup from '@/views/setting/update-userinfo-backup.js'
import EFResetPasswordView from '@/views/setting/update-password.js'
import EFClientList from '../../admin/client-list'
import EFClientListBackup from '../../admin/client-list/backup'
import EFClientNew from '../../../views/admin/client-new'
import EFClientNewBackup from '../../../views/admin/client-new/backup'
import EFClientDetail from '../../../views/admin/client-detail'
import EFClientDetailBackup from '../../../views/admin/client-detail/backup'
import EFAnalystList from '../../admin/manager-list'
import EFAnalystListBackup from '../../admin/manager-list/backup'
import EFAnalystNew from '../../../views/admin/manager-new'
import EFAnalystNewBackup from '../../../views/admin/manager-new/backup'
import EFAnalystDetail from '../../admin/manager-detail'
import EFAnalystDetailBackup from '../../admin/manager-detail/backup'
import EFAdminResetPassword from '../../../views/admin/reset-password'

import EFUserList from '../../admin/user-list'
import EFUserListBackup from '../../admin/user-list/backup'
import EFUserNew from '../../admin/user-new'
import EFUserNewBackup from '../../admin/user-new/backup'
import EFUserDetail from '../../admin/user-detail'
import EFUserDetailBackup from '../../admin/user-detail/backup'

import EFTrademarkList from '@/views/trademark/trademark-list'
import EFTrademarkListBackup from '@/views/trademark/trademark-list/backup'
import EFTrademarkDetail from '@/views/trademark/trademark-detail'
import EFTrademarkDetailBackup from '@/views/trademark/trademark-detail/backup'
import EFTrademarkNew from '@/views/trademark/trademark-new'
import EFTrademarkNewBackup from '@/views/trademark/trademark-new/backup'
import EFTrademarkRegistration from '@/views/trademark/trademark-registration'
import EFTrademarkRegistrationBackup from '@/views/trademark/trademark-registration/backup'

import EFAnalystECSite from '@/views/analyst/ec-site/ec-site-list'
import EFAnalystECSiteBackup from '@/views/analyst/ec-site/ec-site-list/backup'
import EFCreateECSiteView from '@/views/analyst/ec-site/ec-site-create'
import EFCreateECSiteViewBackup from '@/views/analyst/ec-site/ec-site-create/backup'
import EFDetailECSiteView from '@/views/analyst/ec-site/ec-site-detail'
import EFDetailECSiteViewBackup from '@/views/analyst/ec-site/ec-site-detail/backup'

import EFAnalystInfringementCategory from '../../analyst/ec-master/infringement-category'
import EFAnalystInfringementCategoryBackup from '../../analyst/ec-master/infringement-category-backup'

import EFECDataListBackup from '@/views/ec/ec-list/backup'
import EFECDataList from '@/views/ec/ec-list'
import EFECTestDataList from '@/views/ec/ec-list/test'

import EFECDataDetailBackup from '@/views/ec/ec-detail/backup'
import EFECTestDataDetail from '@/views/ec/ec-detail/test'
import EFECDataDetail from '@/views/ec/ec-detail/index'

import EFDashboard from '@/views/dashboard'
import EFDashboardbackup from '@/views/dashboard/backup'
import EFHome from '@/views/home'
import EFHomeBackup from '@/views/home/backup'
import EFNoticeCenterView from '@/views/notice-center'
import EFNoticeCenterViewBackup from '@/views/notice-center/backup'

import EFWSDataList from '@/views/ws/ws-list'
import EFWSDataDetail from '@/views/ws/ws-detail'

import EFECTaskList from '@/views/ec-task/list-view'
import EFECTaskListBackup from '@/views/ec-task/list-view/backup'
import EFCreateECTaskView from '@/views/ec-task/create-view'
import EFCreateECTaskViewBackup from '@/views/ec-task/create-view/backup'
import EFECTaskDetailView from '@/views/ec-task/detail-view'
import EFECTaskDetailViewBackup from '@/views/ec-task/detail-view/backup'

import EFIPFNews from '@/views/news'
import EFIPFNewsBackup from '@/views/news/backup'
import EFTestPurchase from '@/views/test-purchase'
import EFTestPurchaseBackup from '@/views/test-purchase/backup'

import Page404 from '../../error-page/404'

import { connect } from 'react-redux'

import './index.less'

const { Content } = Layout

class EFContent extends Component {
  redirctToDefaultPage () {
    return
  }

  commonRoute () {
    return [
      <Route
        exact
        component={EFUpdateUserInfoView}
        key="userinfo"
        path="/profile"
      />,
      <Route
        exact
        component={EFUpdateUserInfoViewBackup}
        key="userinfoBackup"
        path="/profile-backup"
      />,
      <Route
        exact
        component={EFResetPasswordView}
        key="reset-password"
        path="/profile/reset-password"
      />,
    ]
  }

  adminRoute () {
    if (this.props.role & 4) {
      return [
        <Route
          component={EFAdminResetPassword}
          key="admin-client-new"
          path="/manage/reset-password/:id"
        />,
        <Route
          component={EFClientNew}
          key="admin-client-new"
          path="/manage/client/new"
        />,
        <Route
          component={EFClientNewBackup}
          key="admin-client-new-backup"
          path="/manage/client/new-backup"
        />,
        <Route
          component={EFClientDetail}
          key="admin-client-detail"
          path="/manage/client/:id"
        />,
        <Route
          component={EFClientDetailBackup}
          key="admin-client-detail-backup"
          path="/manage/client-backup/:id"
        />,
        <Route
          component={EFClientList}
          key="admin-client-list"
          path="/manage/client"
        />,
        <Route
          component={EFClientListBackup}
          key="admin-client-list-backup"
          path="/manage/client-backup"
        />,
        <Route
          component={EFAnalystNew}
          key="admin-analyst-new"
          path="/manage/analyst/new"
        />,
        <Route
          component={EFAnalystNewBackup}
          key="admin-analyst-new-backup"
          path="/manage/analyst/new-backup"
        />,
        <Route
          component={EFAnalystDetail}
          key="admin-analyst-detail"
          path="/manage/analyst/:id"
        />,
        <Route
          component={EFAnalystDetailBackup}
          key="admin-analyst-detail-backup"
          path="/manage/analyst-backup/:id"
        />,
        <Route
          component={EFAnalystList}
          key="admin-analyst-list"
          path="/manage/analyst"
        />,
        <Route
          component={EFAnalystListBackup}
          key="admin-analyst-list-backup"
          path="/manage/analyst-backup"
        />,
        <Route
          component={EFUserNew}
          key="admin-user-new"
          path="/manage/user/new"
        />,
        <Route
          component={EFUserNewBackup}
          key="admin-user-new-backup"
          path="/manage/user/new-backup"
        />,
        <Route
          component={EFUserDetail}
          key="admin-user-detail"
          path="/manage/user/:id"
        />,
        <Route
          component={EFUserDetailBackup}
          key="admin-user-detail-backup"
          path="/manage/user-backup/:id"
        />,
        <Route
          component={EFUserList}
          key="admin-user-list"
          path="/manage/user"
        />,
        <Route
          component={EFUserListBackup}
          key="admin-user-list-backup"
          path="/manage/user-backup"
        />,
        <Route component={EFHome} key="admin-home" path="/home" />,
        <Route component={EFHomeBackup} key="admin-home-backup" path="/home-backup" />,
        <Route
          component={EFNoticeCenterView}
          key="notice-center"
          path="/notice"
        />,
        <Route
          component={EFNoticeCenterViewBackup}
          key="notice-center-backup"
          path='/notice-backup'
        />,
        <Route component={EFIPFNews} key="ipf-news" path="/news" />,
        <Route component={EFIPFNewsBackup} key="ipf-news-backup" path="/news-backup" />
      ]
    }
  }

  analystRoute () {
    if (this.props.role & 2) {
      return [
        <Route
          component={EFCreateECSiteView}
          key="analyst-ec-site-new"
          path="/analyst/ec-site/new"
        />,
        <Route
          component={EFCreateECSiteViewBackup}
          key="analyst-ec-site-new-backup"
          path="/analyst/ec-site/new-backup"
        />,
        <Route
          component={EFDetailECSiteView}
          key="analyst-ec-site-detail"
          path="/analyst/ec-site/detail/:id"
        />,
        <Route
          component={EFDetailECSiteViewBackup}
          key="analyst-ec-site-detail-backup"
          path="/analyst/ec-site/detail-backup/:id"
        />,
        <Route
          component={EFAnalystECSite}
          key="analyst-ec-site"
          path="/analyst/ec-site"
        />,
        <Route
          component={EFAnalystECSiteBackup}
          key="analyst-ec-site-backup"
          path="/analyst/ec-site-backup"
        />,
        <Route
          component={EFAnalystInfringementCategory}
          key="analyst-infringement-category"
          path="/analyst/infringement-category"
        />,
        <Route
          component={EFAnalystInfringementCategoryBackup}
          key="analyst-infringement-category-backup"
          path="/analyst/infringement-category-backup"
        />,
        <Route
          component={EFTrademarkNew}
          key="trademark-new"
          path="/trademark/new"
        />,
        <Route
          component={EFTrademarkNewBackup}
          key="trademark-new-backup"
          path="/trademark-backup/new"
        />,
        <Route
          component={EFTrademarkDetail}
          key="trademark-detail"
          path="/trademark/:id"
        />,
        <Route
          component={EFTrademarkDetailBackup}
          key="trademark-detail-backup"
          path="/trademark-backup/:id"
        />,
        <Route
          component={EFTrademarkList}
          key="trademark-list"
          path="/trademark"
        />,
        <Route
          component={EFTrademarkListBackup}
          key="trademark-list-backup"
          path="/trademark-backup"
        />,
        <Route
          component={EFECDataDetail}
          key="analyst-ec-data-detail"
          path="/ec-data/:cid/:rid"
        />,
        <Route
          component={EFECDataDetailBackup}
          key="analyst-ec-data-detail"
          path="/ec-data-backup/:cid/:rid"
        />,
        <Route
          component={EFECTestDataDetail}
          key="analyst-ec-test-data-detail"
          path="/ec-data-test/:cid/:rid"
        />,
        <Route
          component={EFECDataListBackup}
          key="analyst-ec-data"
          path="/ec-list-backup"
        />,
        <Route
          component={EFECDataList}
          key="analyst-ec-data"
          path='/ec-list'
        />,
        <Route
          component={EFECTestDataList}
          key="analyst-ec-data"
          path="/ec-list-test"
        />,
        <Route
          component={EFWSDataList}
          key="analyst-ws-data"
          path="/ws-list"
        />,
        <Route
          component={EFWSDataDetail}
          key="analyst-ws-data-detail"
          path="/ws-data/:cid/:rid"
        />,
        <Route
          component={EFDashboard}
          key="online-dashboard"
          path="/online-dashboard"
        />,
        <Route
          component={EFDashboardbackup}
          key="online-dashboard-backup"
          path="/online-dashboard-backup"
        />,
        <Route
          component={EFClientDetail}
          key="admin-client-detail"
          path="/manage/client/:id"
        />,
        <Route
          component={EFClientDetailBackup}
          key="admin-client-detail-backup"
          path="/manage/client-backup/:id"
        />,
        <Route
          component={EFClientList}
          key="admin-client-list"
          path="/manage/client"
        />,
        <Route
          component={EFClientListBackup}
          key="admin-client-list-backup"
          path="/manage/client-backup"
        />,
        <Route component={EFHome} key="analyst-home" path="/home" />,
        <Route component={EFHomeBackup} key="analyst-home-backup" path="/home-backup" />,
        <Route
          component={EFNoticeCenterView}
          key="notice-center"
          path="/notice"
        />,
        <Route
          component={EFNoticeCenterViewBackup}
          key="notice-center-backup"
          path='/notice-backup'
        />,
        <Route
          component={EFECTaskList}
          key="ec-crawler-task"
          path="/task/ec-crawler-task"
        />,
        <Route
          component={EFECTaskListBackup}
          key="ec-crawler-task-backup"
          path="/task/ec-crawler-task-backup"
        />,
        <Route
          component={EFCreateECTaskView}
          key="new-ec-crawler-task"
          path="/task/new-ec-scraping-task"
        />,
        <Route
          component={EFCreateECTaskViewBackup}
          key="new-ec-crawler-task-backup"
          path="/task/new-ec-scraping-task-backup"
        />,
        <Route
          component={EFECTaskDetailView}
          key="ec-scraping-task-detail"
          path="/task/ec-scraping-task-detail/:id"
        />,
        <Route
          component={EFECTaskDetailViewBackup}
          key="ec-scraping-task-detail-backup"
          path="/task/ec-scraping-task-detail-backup/:id"
        />,
        <Route component={EFIPFNews} key="ipf-news" path="/news" />,
        <Route component={EFIPFNewsBackup} key="ipf-news-backup" path="/news-backup" />,
        <Route
          component={EFTestPurchase}
          key="test-purchase"
          path="/test-purchase"
        />,
        <Route
          component={EFTestPurchaseBackup}
          key="test-purchase-backup"
          path="/test-purchase-backup"
        />
      ]
    }
  }

  clientRoute () {
    if (this.props.role & 1) {
      return [
        <Route
          component={EFTrademarkRegistration}
          key="trademark-registration"
          path="/trademark/registration"
        />,
        <Route
          component={EFTrademarkRegistrationBackup}
          key="trademark-registration-backup"
          path="/trademark/registration-backup"
        />,
        <Route
          component={EFTrademarkNew}
          key="trademark-new"
          path="/trademark/new"
        />,
        <Route
          component={EFTrademarkNewBackup}
          key="trademark-new-backup"
          path="/trademark-backup/new"
        />,
        <Route
          component={EFTrademarkDetail}
          key="trademark-detail"
          path="/trademark/:id"
        />,
        <Route
          component={EFTrademarkDetailBackup}
          key="trademark-detail-backup"
          path="/trademark-backup/:id"
        />,
        <Route
          component={EFTrademarkList}
          key="trademark-list"
          path="/trademark"
        />,
        <Route
          component={EFTrademarkListBackup}
          key="trademark-list-backup"
          path="/trademark-backup"
        />,
        <Route
          component={EFECDataDetail}
          key="analyst-ec-data-detail"
          path="/ec-data/:cid/:rid"
        />,
        <Route
          component={EFECDataDetailBackup}
          key="analyst-ec-data-detail"
          path="/ec-data-backup/:cid/:rid"
        />,
        <Route
          component={EFECTestDataDetail}
          key="analyst-ec-test-data-detail"
          path="/ec-data-test/:cid/:cid"
        />,
        <Route
          component={EFECDataListBackup}
          key="ec-data"
          path="/ec-list-backup" />,
        <Route
          component={EFECDataList}
          key="ec-data"
          path='/ec-list'
        />,
        <Route
          component={EFECTestDataList}
          key="ec-data"
          path="/ec-list-test" />,
        <Route
          component={EFWSDataList}
          key="analyst-ws-data"
          path="/ws-list"
        />,
        <Route
          component={EFWSDataDetail}
          key="analyst-ws-data-detail"
          path="/ws-data/:cid/:rid"
        />,
        <Route
          component={EFDashboard}
          key="online-dashboard"
          path="/online-dashboard"
        />,
        <Route
          component={EFDashboardbackup}
          key="online-dashboard-backup"
          path="/online-dashboard-backup"
        />,
        <Route component={EFHome} key="client-home" path="/home" />,
        <Route component={EFHomeBackup} key="client-home-backup" path="/home-backup" />,
        <Route
          component={EFNoticeCenterView}
          key="notice-center"
          path="/notice"
        />,
        <Route
          component={EFNoticeCenterViewBackup}
          key="notice-center-backup"
          path='/notice-backup'
        />,
        <Route component={EFIPFNews} key="ipf-news" path="/news" />,
        <Route component={EFIPFNewsBackup} key="ipf-news-backup" path="/news-backup" />
      ]
    }
  }

  render () {
    let bread = <EFCBreadcrumb></EFCBreadcrumb>
    if (this.props.location.pathname === '/home') {
      bread = null
    }
    return (
      <Content
        className="ef-content-container"
      // style={{ margin: '24px 16px 0', overflow: 'initial' }}
      >
        {bread}
        <Switch>
          <Redirect exact from="/" to="profile" />
          {this.adminRoute()}
          {this.commonRoute()}
          {this.analystRoute()}
          {this.clientRoute()}
          <Route
            component={Page404}
            key="404"
          // path="/404"
          />
          ,
        </Switch>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.user,
    ...state.auth,
  }
}

export default connect(mapStateToProps)(withRouter(EFContent))
