// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.account_empty": {
        id: "app.ui.ec_site_detail.error.account_empty",
        defaultMessage: "アカウントを入力ください。",
    },
    "message.error.password_empty": {
        id: "app.ui.ec_site_detail.error.password_empty",
        defaultMessage: "パスワードを入力ください。",
    },
    "message.error.tel_empty": {
        id: "app.ui.ec_site_detail.error.tel_empty",
        defaultMessage: "携帯電話を入力ください。",
    },
    "message.error.free_time_empty": {
        id: "app.ui.ec_site_detail.error.free_time_empty",
        defaultMessage: "冷却時間(秒)を入力ください。",
    },
    "message.error.headers_empty": {
        id: "app.ui.ec_site_detail.error.headers_empty",
        defaultMessage: "ヘッダーを入力ください。",
    },
    "message.error.cookies_empty": {
        id: "app.ui.ec_site_detail.error.cookies_empty",
        defaultMessage: "クッキーを入力ください。",
    },
    "message.error.remarks_empty": {
        id: "app.ui.ec_site_detail.error.remarks_empty",
        defaultMessage: "備考を入力ください。",
    },
    "message.error.remarks_tip": {
        id: "app.ui.ec_site_detail.error.remarks_tip",
        defaultMessage: "希望件数を入力ください。",
    },
    "message.info.update_account_success": {
        id: "app.ui.ec_site_detail.info.update_account_success",
        defaultMessage: "成功しました。",
    },
    "message.error.update_account_failed": {
        id: "app.ui.ec_site_detail.error.update_account_failed",
        defaultMessage: "失敗しました。",
    },
})

export default messages