import { message, Table, Tooltip, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { FormattedMessage } from 'react-intl'
import EFCSearchBar from "@/components/ef-c-searchbar";
import { getDateSearchItems, getKeywordSearchItems } from "../util/search-bar-config.js";
import store from "@/store";
import { getColumns } from "../util/data-table-config.js";
import './index.less'
import { reqFetchECCrawlerTask, reqRemoveECCrawlerTask, reqStartTask, reqDownloadTask, reqStopTask,reqCloneECCrawlerTask } from "../../../api/analyst-ec-task.js"
import { updateSearchParamWithTableOnChangeInfo, updateSearchParamWithSearchInfo, getAllFiltersFromSearchParam } from "../../common/list-search-param-helper.js"
import { useLocation } from 'react-router-dom'
import { reqFetchClients } from '@/api/analyst-client';
import { ECCrawlerStatus } from "../common/master-data.js";
import ECCrawlerSubtasksList from "./subtasks.jsx"

function ECCrawlerTaskListBackup(props) {

    useEffect(() => {
        document.title = "ECスクレイピングタスク"
        // store.dispatch(setMenuActiveKey("analyst-ec-list"));
        store.dispatch(setContentContainerHeaderAction([{
            name: "新規", 
            // onClick: _handleNew
            path: "/task/new-ec-scraping-task"
        }]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: "ECスクレイピング",
                path: "/task/ec-crawler-task",
            },
        ]));
    }, [])

    const location = useLocation()
    const [tasks, setTasks] = useState([])
    const [total, setTotal] = useState(0)

    const fetchECCrawlerTaskInfo = (searchParams) => {
        const dateFields = searchParams.get("dateFields") ? searchParams.get("dateFields").split(",") : null;
        const startDate = searchParams.get("startDate") || null;
        const endDate = searchParams.get("endDate") || null;
        const wordFields = searchParams.get("wordFields") ? searchParams.get("wordFields").split(",") : null;
        const keyword = searchParams.get("keyword") || null;
        const searchType = searchParams.get("searchType") || null;
        const sortField = searchParams.get("sortField") || null;
        const order = searchParams.get("order") || null;
        let current = searchParams.get("current") || 0;
        current = parseInt(current)
        let pageSize = searchParams.get("pageSize") || 20;
        pageSize = parseInt(pageSize)
        const filters = getAllFiltersFromSearchParam(searchParams)
        const orderFields = sortField && order ? [
            {
                key: sortField,
                value: order === "ascend" ? 1 : 2
            }
        ] : null
        reqFetchECCrawlerTask({
            dateFields, startDate, endDate, wordFields, keyword, searchType, orderFields, current, pageSize, filters
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setTasks(data.data.tasks)
                setTotal(data.data.total)
            }
        }).catch((error) => {

        })
    }

    const [clients, setClients] = useState([])
    useEffect(() => {
        reqFetchClients({ all: true }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                const clientsInfo = data.data.client.map((client) => {
                    return {
                        value: client.client_id,
                        text: client.client_name,
                    }
                })
                setClients(clientsInfo);
            }
        }).catch(e => {

        })
    }, [])

    const [searchBarDefaultValues, setSearchBarDefaultValues] = useState()
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        fetchECCrawlerTaskInfo(searchParams)
        setSearchBarDefaultValues({
            dateFields: searchParams.get("dateFields") ? searchParams.get("dateFields").split(",") : null,
            startDate: searchParams.get("startDate") || null,
            endDate: searchParams.get("endDate") || null,
            wordFields: searchParams.get("wordFields") ? searchParams.get("wordFields").split(",") : null,
            keyword: searchParams.get("keyword") || null,
            searchType: searchParams.get("searchType") ? parseInt(searchParams.get("searchType")) : null,
        })
    }, [location])


    // extra= { currentDataSource: [], action: paginate | sort | filter }
    const tableOnChange = (pagination, filters, sorter, extra) => {
        const searchParam = updateSearchParamWithTableOnChangeInfo(props.location.search, { pagination, filters, sorter, extra })
        props.history.push({
            pathname: props.location.pathname,
            search: `?${searchParam.toString()}`
        })
        // console.log(pagination)
        // console.log(filters)
        // console.log(extra)
        // console.log(searchParam)
        // console.log(searchParam.get('order'))
        //current -> pagination 
        //pageSize -> pagination
        //total -> Pagination
        //
    }

    const handleSearch = (conditions) => {
        const searchParam = updateSearchParamWithSearchInfo(props.location.search, conditions)
        props.history.push({
            pathname: props.location.pathname,
            search: `?${searchParam.toString()}`
        })
    };

    const handleReset = () => {

    };

    // record action ----------------------------------

    const onDetailClick = (record) => {
        props.history.push({
            pathname: "/task/ec-scraping-task-detail/" + record.id,
        })
    }

    const onDeleteClick = (record) => {
        if (record.status != ECCrawlerStatus.scraying.value) {
            reqRemoveECCrawlerTask({
                taskId: record.id
            }).then((response) => {
                if (response.data.code === 0) {
                    const searchParams = new URLSearchParams(props.location.search)
                    fetchECCrawlerTaskInfo(searchParams)
                    message.success("削除成功しました。")
                } else {
                    message.error("削除失敗しました。")
                }
            })
        }
    }

    const onStartClick = (record) => {
        // console.log(record)
        // if (record.status === ECCrawlerStatus.new.value) {
        const subtaskIds = record.subtasks.map(subtask => {
            return subtask.id
        })
        reqStartTask({
            taskId: record.id,
            subtaskIds: subtaskIds
        }).then((response) => {
            if (response.data.code === 0) {
                const searchParams = new URLSearchParams(props.location.search)
                fetchECCrawlerTaskInfo(searchParams)
                message.success("スクレイピングを開始しました。")
            } else {
                message.error("失敗になりました。")
            }
        }).catch(e => {
            message.error("失敗になりました。")
        })
        // }
    }

    const onStopClick = (record) => {
        // if (record.status !== ECCrawlerStatus.new.value && record.status !== ECCrawlerStatus.finished.value) {
        if (record.subtasks) {
            const subtaskIds = record.subtasks.map(subtask => {
                return subtask.id
            })
            reqStopTask({
                taskId: record.id,
                subtaskIds: subtaskIds
            }).then((response) => {
                if (response.data.code === 0) {
                    const searchParams = new URLSearchParams(props.location.search)
                    fetchECCrawlerTaskInfo(searchParams)
                    message.success("スクレイピングを停止しました。")
                } else {
                    message.error("失敗になりました。")
                }
            }).catch(e => {
                message.error("失敗になりました。")
            })
        }
        // }
    }

    const onDownloadClick = (record, type) => {

        if (record.status === ECCrawlerStatus.finished.value) {
            const subtaskIds = record.subtasks.map(subtask => {
                return subtask.id
            })

            const params = subtaskIds.reduce((pre, item) => {
                return pre + "subtaskIds=" + item + "&"
            }, "&")

            window.open(`${process.env.REACT_APP_BASE_API}/api/ec-crawler-command/download?exportType=${type}&taskId=${record.id}${params}`);
            // reqDownloadTask({
            //     taskId: record.id,
            //     subtaskIds: subtaskIds
            // }).then((response) => {
            //     // to new window
            // })
        }
    }

    const onCloneTask = (record) =>{
        reqCloneECCrawlerTask({
            taskId: record.id,
        }).then((response) => {
            if (response.data.code === 0) {
                const searchParams = new URLSearchParams(props.location.search)
                fetchECCrawlerTaskInfo(searchParams)
                message.success("新しいタスクを作りました。")
            } else {
                message.error("失敗になりました。")
            }
        }).catch(e => {
            message.error("失敗になりました。")
        })
    }

    const [subtasksOpen, setSubtasksOpen] = useState(false)
    const [showTasks, setShowTask] = useState()
    
    const onSubtasks = (record) => {
        setSubtasksOpen(!subtasksOpen)
        setShowTask(record)
    }

    const onSubtasksListClose = () => {
        setSubtasksOpen(false)
    }

    return (
        <>
            <div>
                <div className="ec-crawler-task-search-container">
                    <EFCSearchBar
                        dateItems={getDateSearchItems()}
                        keywordItems={getKeywordSearchItems()}
                        onSearch={handleSearch}
                        onReset={handleReset}
                        defaultValues={searchBarDefaultValues}
                    />
                </div>
                <div className="ec-crawler-task-list-container">
                    <Table
                        key="ec_task_ListTable"
                        bordered
                        tableLayout="fixed"
                        rowKey={(record) => {
                            return record.id
                        }}
                        columns={getColumns({
                            clients,
                            onDetailClick,
                            onDeleteClick,                          
                            onStartClick,
                            onStopClick,
                            onDownloadClick,
                            onSubtasks,
                            onCloneTask
                        })}
                        dataSource={tasks}
                        size="small"
                        onChange={tableOnChange}
                        pagination={{
                            showQuickJumper: true,
                            total: total,
                            pageSizeOptions: [10, 20, 50, 100, 200, 500],
                            defaultPageSize: 20,
                            showSizeChanger: true,
                        }}
                        scroll={{
                            x: 'max-content',
                        }}
                    />
                </div>
            </div>
            <ECCrawlerSubtasksList task={showTasks} visible={subtasksOpen} onClose={onSubtasksListClose}>
            </ECCrawlerSubtasksList>
        </>
    )
}

export default ECCrawlerTaskListBackup