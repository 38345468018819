import { combineReducers } from "redux";
import user from "./user";
import auth from "./auth";
import contentContainerHeader from "./content-header";
import mainMenu from "./menu"
import ecMasterData from "./ec-master";
import setting from "./setting"

export default combineReducers({
  user,
  auth,
  contentContainerHeader,
  mainMenu,
  ecMasterData,
  setting
});
