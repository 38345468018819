import "./index.less"
import React, { Component } from "react"
import { Button, Form, Space, Table, Checkbox, Row, Col, Modal, message } from "antd"

import { reqGetTrademarkCountries, reqSetTrademarkCountries } from '../../../api/analyst-trademark-country'
import { reqSearchTrademarks } from "@/api/trademark.js"
import { withRouter } from "react-router-dom"

import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'

import { CountryConfig } from "@/config/country"
import moment from 'moment'

class AnalystClientTrademarkCountry extends Component {

    constructor(props) {
        super(props)
        this.state = {
            regions: [],
            isloading: false,
            trademarksDict: {},
            selectRegionTrademarks: [],
        }
        this.formRef = React.createRef()
        if (this.props.clientId) {
            reqGetTrademarkCountries({
                clientId: this.props.clientId,
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    let regions = data.data.regions.regions.split(",")
                    this.formRef.current.setFieldsValue({ regions: regions })
                }
            }).catch((error) => {

            })
            reqSearchTrademarks({ clientId: this.props.clientId }).then((response) => {
                const { data } = response
                let trademarkRegionDict = {}
                if (data.code === 0) {
                    data.data.trademarks.forEach(trademark => {
                        if (trademarkRegionDict.hasOwnProperty(trademark.trademark_region)) {
                            let trademarks = trademarkRegionDict[trademark.trademark_region]
                            trademarks.push(trademark)
                        } else {
                            trademarkRegionDict[trademark.trademark_region] = [trademark]
                        }
                    })
                    this.setState({ trademarksDict: trademarkRegionDict })
                }
            }).catch((error) => {

            })
        }

        let sortC = CountryConfig.sort((a, b) => {
            var nameA = a.name.en.toUpperCase() // ignore upper and lowercase
            var nameB = b.name.en.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
        this.trademarkCountryDictionary = sortC.reduce((result, country) => {
            if (country.region) {
                if (result[country.region]) {
                    result[country.region].push(country)
                } else {
                    result[country.region] = []
                    result[country.region].push(country)
                }
            }
            return result
        }, {})

        this.columns = [
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_id' }),
                dataIndex: 'trademark_id',
                key: 'trademark_id',
                fixed: true,
                width: 80,
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_client_id' }),
                dataIndex: 'trademark_client_id',
                key: 'trademark_client_id',
                render: (text, record) => {
                    if (this.state.clients && this.state.clients.length > 0) {
                        let client = this.state.clients.find((client) => {
                            return client.client_id === record.trademark_client_id
                        })
                        if (client) {
                            return client.client_name
                        } else {
                            return record.trademark_client_id
                        }
                    } else {
                        return record.trademark_client_id
                    }
                }
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_no' }),
                dataIndex: 'trademark_no',
                key: 'trademark_no',
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_region' }),
                dataIndex: 'trademark_region',
                key: 'trademark_region',
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_type' }),
                dataIndex: 'trademark_type',
                key: 'trademark_type',
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_text' }),
                dataIndex: 'trademark_text',
                key: 'trademark_text',
            },

            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_register_date' }),
                dataIndex: 'trademark_register_date',
                key: 'trademark_register_date',
                render: (text, record) => {
                    if (record.trademark_register_date) {
                        return moment(record.trademark_register_date).format("YYYY-MM-DD")
                    }
                    return ""
                }
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_invalid_date' }),
                dataIndex: 'trademark_invalid_date',
                key: 'trademark_invalid_date',
                render: (text, record) => {
                    if (record.trademark_invalid_date) {
                        return moment(record.trademark_invalid_date).format("YYYY-MM-DD")
                    }
                    return ""
                }
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_belongsto' }),
                dataIndex: 'trademark_belongsto',
                key: 'trademark_belongsto',
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_description_ja' }),
                dataIndex: 'trademark_description_ja',
                key: 'trademark_description_ja',
                width: 240,
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_description_en' }),
                dataIndex: 'trademark_description_en',
                key: 'trademark_description_en',
                width: 240
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_description_cn' }),
                dataIndex: 'trademark_description_cn',
                key: 'trademark_description_cn',
                width: 240
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_status' }),
                dataIndex: 'trademark_status',
                key: 'trademark_status',
                render: (text, record) => {
                    if (record.trademark_status === 1) {
                        return this.props.intl.formatMessage({ id: 'app.trademark_registration_status.register' })
                    } else if (record.trademark_status === 2) {
                        return this.props.intl.formatMessage({ id: 'app.trademark_registration_status.applying' })
                    } else if (record.trademark_status === 3) {
                        return this.props.intl.formatMessage({ id: 'app.trademark_registration_status.rejection' })
                    }
                    return ""
                }
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.trademark_country.trademark_remarks' }),
                dataIndex: 'trademark_remarks',
                key: 'trademark_remarks',
                width: 240
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" href={"/trademark/" + record.trademark_id}><FormattedMessage id="app.common.action.detail" /></Button>
                        {/* <Button type="link" onClick={() => { this.handleDelete(record) }} client={record.trademark_id}>削除</Button> */}
                    </Space>
                ),
                fixed: "right",
                width: 100
            },
        ]
    }

    onFinish = (values) => {
        this.setState({ isloading: true })
        reqSetTrademarkCountries({
            clientId: this.props.clientId,
            regions: values["regions"]
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let regions = data.data.regions.regions.split(",")
                this.formRef.current.setFieldsValue({ regions: regions })
                this.setState({ isloading: false })
                message.success(this.props.intl.formatMessage(messages['message.info.trademark_country.update_success']))
            }
        }).catch((e) => {
            this.setState({ isloading: false })
            message.success(this.props.intl.formatMessage(messages['message.error.trademark_country.update_fail']))
        })
    }

    onFinishFailed = (values) => {

    }

    onGoToTrademark = () => {
        this.props.history.push("/trademark")
    }

    genCountry = () => {
        return (
            <Form.Item name="regions">
                <Checkbox.Group className="check-group">
                    {
                        Object.keys(this.trademarkCountryDictionary).map((key) => {
                            let children = []
                            let rowCount = 6
                            children.push(<div className="area-title">{key}</div>)
                            children.push(
                                <div className="area-content">
                                    {this.getArea(this.trademarkCountryDictionary[key], rowCount)}
                                </div>
                            )
                            return children
                        })
                    }
                </Checkbox.Group>
            </Form.Item>
        )
    }

    getArea = (array, rowCount) => {
        let children = []
        for (let i = 0; i < array.length / rowCount; i++) {
            let j = i * rowCount
            children.push(
                <Row>
                    {this.getCountryByIndex(array, j, j + rowCount, 24 / rowCount)}
                </Row>
            )
        }
        return children
    }

    onTrademarkCountClick = (value) => {
        let selectRegionTrademarks = this.state.trademarksDict[value]
        this.setState({ selectRegionTrademarks: selectRegionTrademarks })
    }

    getTrademarkCount = (country) => {
        if (this.state.trademarksDict.hasOwnProperty(country.code)) {
            return <Button size="small" className="trademark-count-container" onClick={() => { this.onTrademarkCountClick(country.code) }} value={country.code}>{this.state.trademarksDict[country.code].length}</Button>
        }
    }

    getCountryByIndex = (array, beginIndex, toIndex, span) => {
        let children = []
        for (let i = beginIndex; i < toIndex; i++) {
            if (array.length > i) {
                let country = array[i]
                children.push(
                    <Col span={span}>
                        <Checkbox value={country.code}>{country.name.ja}</Checkbox>
                        {this.getTrademarkCount(country)}
                    </Col>
                )
            } else {
                break
            }
        }
        return children
    }

    render () {
        return (
            <div className="master-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <div>
                        {this.genCountry()}
                    </div>
                    <div className="acition-container">
                        <Button htmlType="submit" className="ef-button-color-small ef-text-normal" loading={this.state.isloading}>{<FormattedMessage id="app.common.action.save" />}</Button>
                        <Button className="ef-button-linear-small ef-text-normal goto-button" onClick={this.onGoToTrademark}>{<FormattedMessage id="app.ui.brand_reg_detail.trademark_country.status" />}</Button>
                    </div>
                </Form>
                <Modal
                    title={<p className="ef-text-bold">{<FormattedMessage id="app.ui.brand_reg_detail.trademark_country.modal.title" />}</p>}
                    className="ef-modal"
                    visible={this.state.selectRegionTrademarks.length}
                    width={1000}
                    onOk={() => { this.setState({ selectRegionTrademarks: [] }) }}
                    onCancel={() => { this.setState({ selectRegionTrademarks: [] }) }}
                    footer={
                        [
                            <Button type="primary" onClick={() => { this.setState({ selectRegionTrademarks: [] }) }}>{<FormattedMessage id="app.common.action.confirm" />}</Button>
                        ]
                    }
                >
                    <Table
                        className="ef-table"
                        dataSource={this.state.selectRegionTrademarks}
                        columns={this.columns}
                        size="small"
                        scroll={{
                            x: 'max-content',
                        }}
                        pagination={false}
                    />
                </Modal>
            </div>
        )
    }
}

export default withRouter(injectIntl(AnalystClientTrademarkCountry))