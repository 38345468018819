import request from '@/utils/request'

export function reqSetTrademarkCountries(data){
    return request({
        url: '/api/analyst/set-trademark-countries',
        method: 'post',
        data: data
    })
}

export function reqGetTrademarkCountries(data){
    return request({
        url: '/api/analyst/get-trademark-countries',
        method: 'post',
        data: data
    })
}
