import { Button, Input, Modal, Select, Table, Row, Col, DatePicker, Form, message } from "antd"
import React, { useState, useEffect, useCallback, useRef } from "react"
import { useHistory } from 'react-router-dom'
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { connect } from "react-redux"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table"
import { ProFormDatePicker, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form"
import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages.js"
import { reqCreateECCrawlerTask } from "../../../api/analyst-ec-task.js"
import { reqFetchClients } from '@/api/analyst-client'
import { reqFetchECSites } from "../../../api/analyst-site.js"
import { ECCrawlerPurpose, ECCrawlerStatus } from '../common/master-data.js'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container/index.jsx'
import EFBox from '../../../components/ef-c-box/index.jsx'
import "./index.less"
import subtasks from "../list-view/subtasks.jsx"

const EFECScrapyingTaskCreateView = () => {

    const [isLoading, setIsLoading] = useState(false)
    const basicInfo = useRef([])

    const intl = useIntl()
    const history = useHistory()

    const sites = useRef([])
    const clients = useRef([])
    const subTasks = useRef([])
    const currentState = useRef(true)
    const updateTrigger = useRef()
    const subtaskLastNumber = useRef(0)
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const onHandleBasicChange = (values) => {
        const params = { ...values, subtasksInfo: subTasks.current }
        reqCreateECCrawlerTask(params).then((response) => {
            message.success(intl.formatMessage(messages['message.info.create_success']))
            history.push("/task/ec-crawler-task")
        }).catch((e) => {
            message.error(intl.formatMessage(messages['message.error.create_fail']))  
        })
    }

    const getEditSubtaskFieldsValue = (record, index) => {
        const editInfo = {}
        editInfo["site" + record["key"]] = record["site"]
        editInfo["keyword" + record["key"]] = record["keyword"]
        editInfo["startUrl" + record["key"]] = record["startUrl"]
        editInfo["targetCount" + record["key"]] = record["targetCount"]
        return editInfo
    }

    const onHandleRemove = (record) => {
        const index = subTasks.current.findIndex((obj) => {
            return obj.key === record.key
        })
        if (index !== -1) {
            let newTasksDetails = [...subTasks.current]
            newTasksDetails.splice(index, 1)
            subTasks.current = newTasksDetails
            toggleLoading()
        }
    }

    const onHandleUpdate = (record, values) => {
        const index = subTasks.current.findIndex((obj) => {
            return obj.key === record.key
        })
        if (index !== -1) {
            let newTasksDetails = [...subTasks.current]
            let newDetail = { ...record }
            newDetail["site"] = values["site" + record.key]
            newDetail["startUrl"] = values["startUrl" + record.key]
            newDetail["targetCount"] = values["targetCount" + record.key]
            newDetail["keyword"] = values["keyword" + record.key]
            newTasksDetails[index] = newDetail
            subTasks.current = newTasksDetails
            toggleLoading()
        }
    }

    const onHandleCreate = (values) => {
        subtaskLastNumber.current = subtaskLastNumber.current + 1
        const key = "key" + subtaskLastNumber.current
        const newSubtask = { key: key, ...values }
        subTasks.current = [...subTasks.current, newSubtask]
        toggleLoading()
    }

    const onHandleUpdateForm = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderColumns = () => {
        return [
            {
                title: <FormattedMessage id="app.ui.ec_task.table.site" />,
                dataIndex: 'site',
                key: 'site',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"site" + record["key"]}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.site_empty']),   
                                }
                            ]}>
                            <Select
                                className="ef-form-element-normal"
                                style={{ width: 200 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false
                                }}
                            >
                                {sites.current.map((site) => {
                                    return <Select.Option value={site.name} key={site.url} >{site.name}</Select.Option>
                                })}
                            </Select>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.keyword" />,
                dataIndex: 'keyword',
                key: 'keyword',
                width: 300,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"keyword" + record["key"]}
                            rules={[
                                {
                                    required: true,  
                                    message: intl.formatMessage(messages['message.error.keyword_empty']),  
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.startUrl" />,
                dataIndex: 'startUrl',
                key: 'startUrl',
                width: 500,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"startUrl" + record["key"]}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.start_url_empty']),  
                                }
                            ]}>
                            <Input.TextArea className="ef-form-element-normal"></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.targetCount" />,
                dataIndex: 'targetCount',
                key: 'targetCount',
                width: 100,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"targetCount" + record["key"]}
                            rules={[
                                {
                                    required: false,
                                    message: intl.formatMessage(messages['message.error.target_count_empty']),  
                                    type: "number"
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
        ]
    }

    const renderModalItems = () => {
        return (
            <>
                <ProFormSelect width="100%" name="site" label={<FormattedMessage id="app.ui.ec_task.table.site" />} showSearch rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.site_empty']),
                    },
                ]}
                    options={
                        sites.current.map(site => {
                            return {
                                value: site.name,
                                label: site.name,
                                key: site.name
                            }
                        })
                    }
                />
                <ProFormText width="100%" name="keyword" label={<FormattedMessage id="app.ui.ec_task.table.keyword" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.keyword_empty']),   
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="startUrl" label={<FormattedMessage id="app.ui.ec_task.table.startUrl" />} rules={[
                    {
                        required: true,
                        type: "url",
                        message: intl.formatMessage(messages['message.error.start_url_empty']),   
                    },
                ]}
                />
                <ProFormText width="100%" name="targetCount" label={<FormattedMessage id="app.ui.ec_task.table.targetCount" />} rules={[
                    {
                        required: false,
                        message: intl.formatMessage(messages['message.error.target_count_empty']),  
                        // type: 'number'
                    },
                ]}
                />
            </>
        )
    }

    const renderTrigger = () => {
        return <div className='client-detail-container-footer'>
            <Button className='ef-button-color-small' onClick={() => { onHandleUpdateForm() }}><FormattedMessage id='app.common.action.add' /></Button>
        </div>
    }

    const renderLatestTask = () => {
        basicInfo.current = [
            {
                name: 'planStartDate',
                value: '',
                label: 'app.ui.ec_task.table.planStartDate',
                type: 'date',
                permission: true,
            }, {
                name: 'clientId',
                value: '',
                label: 'app.ui.ec_task.table.clientId',
                type: 'select',
                permission: true,
                params: clients.current.map((item, index) => {
                    return { label: item.client_name, value: item.client_id }
                })
            }, {
                name: 'title',
                value: '',
                label: 'app.ui.ec_task.table.title',
                type: 'input',
                permission: true,
            }, {
                name: 'type',
                value: '',
                label: 'app.ui.ec_task.table.type',
                type: 'select',
                permission: true,
                params: [
                    {
                        label: ECCrawlerPurpose.normal.text,
                        value: ECCrawlerPurpose.normal.value
                    }, {
                        label: ECCrawlerPurpose.dashboard.text,
                        value: ECCrawlerPurpose.dashboard.value
                    }, {
                        label: ECCrawlerPurpose.monitor.text,
                        value: ECCrawlerPurpose.monitor.value
                    }
                ]
            }, {
                name: 'remarks',
                value: '',
                label: 'app.ui.ec_task.table.remarks',
                type: 'textarea',
                permission: true,
            }
        ]
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("task-ec"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.ec_crawler_task" />,
                path: "/task/ec-crawler-task",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.add" />,
                // path: "/ec-list",
            },
        ]))
    }

    const renderSiteAndClients = async () => {
        await reqFetchECSites().then((response) => {
            const { data } = response
            if (data.code === 0) {
                sites.current = data.data.sites.filter((site) => {
                    return site.hasCrawler
                })
            }
        }).catch(e => {

        })

        await reqFetchClients({ all: true }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                clients.current = data.data.client
            }
        }).catch(e => {

        })
        renderLatestTask()
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderSiteAndClients()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.ec_task_new.title' })
    return <EFCContainer
        overspread={0}
        style={{ padding: '32px' }}>
        <EFBox
            titleBarColor={'#1880DE'}
            title={<FormattedMessage id={'app.ui.ec_task.add.title'} />}
            mode={'single'}
            data={basicInfo.current}
            lineWidth={300}
            itemClass={'ef-form-element-block'}
            state={currentState.current}
            updateTrigger={updateTrigger.current}
            onHandleChange={(value) => { onHandleBasicChange(value) }}
        ></EFBox>
        {renderTrigger()}
        <EFCMasterTable
            modalTitle={intl.formatMessage({ id: 'app.ui.ec_task.table.modal_title' })}
            rowkey="key"
            columns={renderColumns()}
            dataSource={subTasks.current}
            getEditFieldsValue={getEditSubtaskFieldsValue}
            modalItems={renderModalItems()}
            onRemoveClick={onHandleRemove}
            onSaveClick={onHandleUpdate}
            onModalFinished={onHandleCreate}
        >
        </EFCMasterTable>
    </EFCContainer>
}
export default EFECScrapyingTaskCreateView