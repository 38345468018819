const defaultConfig = {
    offset: 0,
    limit: 10,
}

const allConfig = {
    offset: 0,
    limit: 10,
    filterErrorRecord: 0,
    clientId: '',
    dateFields: '',
    startDate: '',
    endDate: '',
    wordFields: '',
    keyword: '',
    searchType: '',
    whereFields: [],
    orderFields: []
}

// Helping searchBar params render to url 
export const getSearchBar = (searchParams) => {
    return [
        {
            key: 'offset',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined) {
                    newParams.offset = value
                    return newParams
                } else {
                    searchParams.delete('offset')
                    return newParams
                }
            }
        },
        {
            key: 'limit',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined) {
                    newParams.limit = value
                    return newParams
                } else {
                    searchParams.delete('limit')
                    return newParams
                }
            }
        },
        {
            key: 'clientId',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined) {
                    newParams.clientId = value
                    return newParams
                } else {
                    searchParams.delete('clientId')
                    return newParams
                }
            }
        }, {
            key: 'dateFields',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined && value.length > 0) {
                    newParams.dateFields = value
                    return newParams
                } else {
                    searchParams.delete('dateFields')
                    return newParams
                }
            }
        },
        // {
        //     key: 'date',
        //     condition: (newParams, value) => {
        //         if (value !== null && value !== undefined && value.length === 2) {
        //             newParams.startDate = value[0]
        //             newParams.endDate = value[1]
        //             return newParams
        //         } else {
        //             searchParams.delete('startDate')
        //             searchParams.delete('endDate')
        //             return newParams
        //         }
        //     }
        // }, 
        {
            key: 'startDate',
            condition: (newParams, value) => {
                if (value) {
                    newParams.startDate = value
                    return newParams
                } else {
                    searchParams.delete('startDate')
                    return newParams
                }
            }
        }, {
            key: 'endDate',
            condition: (newParams, value) => {
                if (value) {
                    newParams.endDate = value
                    return newParams
                } else {
                    searchParams.delete('endDate')
                    return newParams
                }
            }
        },
        {
            key: 'wordFields',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined && value.length > 0) {
                    newParams.wordFields = value
                    return newParams
                } else {
                    searchParams.delete('wordFields')
                    return newParams
                }
            }
        }, {
            key: 'keyword',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined && value !== '') {
                    newParams.keyword = value
                    return newParams
                } else {
                    searchParams.delete('keyword')
                    return newParams
                }
            }
        }, {
            key: 'searchType',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined) {
                    newParams.searchType = value
                    return newParams
                } else {
                    searchParams.delete('searchType')
                    return newParams
                }
            }
        }, {
            key: 'filterErrorRecord',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined) {
                    newParams.filterErrorRecord = value
                    return newParams
                } else {
                    searchParams.delete('filterErrorRecord')
                    return newParams
                }
            }
        }, {
            key: 'whereFields',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined && Object.keys(value).length > 0) {
                    newParams.whereFields = encodeURIComponent(JSON.stringify(value))
                    return newParams
                } else {
                    searchParams.delete('whereFields')
                    return newParams
                }
            }
        }, {
            key: 'orderFields',
            condition: (newParams, value) => {
                if (value !== null && value !== undefined && value.length > 0) {
                    newParams.orderFields = encodeURIComponent(JSON.stringify(value))
                    return newParams
                } else {
                    searchParams.delete('orderFields')
                    return newParams
                }
            }
        }
    ]
}

export const normalize = () => {
    return [
        {
            key: 'offset',
            condition: (value) => {
                return parseInt(value)
            }
        },
        {
            key: 'limit',
            condition: (value) => {
                return parseInt(value)
            }
        },
        {
            key: 'clientId',
            condition: (value) => {
                return value
            }
        }, {
            key: 'dateFields',
            condition: (value) => {
                if (value.indexOf(',') > -1) {
                    let array = value.split(',')
                    return array
                } else {
                    return [value]
                }
            }
        }, {
            key: 'startDate',
            condition: (value) => {
                return value
            },
        }, {
            key: 'endDate',
            condition: (value) => {
                return value
            }
        }, {
            key: 'wordFields',
            condition: (value) => {
                if (value.indexOf(',') > -1) {
                    let array = value.split(',')
                    return array
                } else {
                    return [value]
                }
            }
        }, {
            key: 'keyword',
            condition: (value) => {
                return value
            }
        }, {
            key: 'searchType',
            condition: (value) => {
                return Number(value)
            }
        }, {
            key: 'filterErrorRecord',
            condition: (value) => {
                return Number(value)
            }
        }, {
            key: 'whereFields',
            condition: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        }, {
            key: 'orderFields',
            condition: (value) => {
                return JSON.parse(decodeURIComponent(value))
            }
        }
    ]
}

export const generateConfig = (page, pageSize) => {
    if (pageSize) {
        const obj = { offset: 0, limit: 0 }
        if (page === 0) {
            page = 1
        }
        if (!pageSize) {
            obj['offset'] = (page - 1) * defaultConfig.limit
            obj['limit'] = defaultConfig.limit
        } else {
            obj['offset'] = (page - 1) * pageSize
            obj['limit'] = pageSize
        }
        return obj
    } else {
        return { offset: 0, limit: defaultConfig.limit }
    }
}

export const validParams = () => {
    return ['offset', 'limit', 'clientId', 'dateFields', 'startDate', 'endDate', 'wordFields', 'keyword', 'searchType', 'filterErrorRecord', 'whereFields', 'orderFields']
}