import request from '@/utils/request'

export function reqGetECRecord(data) {
    return request({
        url: '/api/ec-data/get-record',
        method: 'post',
        data: data
    })
}

export function reqGetECRecordHistory(data) {
    return request({
        url: '/api/ec-data/get-record-history',
        method: 'post',
        data: data
    })
}

export function reqUpdateECRecord(data) {
    return request({
        url: '/api/ec-data/update-record',
        method: 'post',
        data: data
    })
}

export function reqSearchECRecords(data) {
    return request({
        url: '/api/ec-data/search-records',
        method: 'post',
        data: data
    })
}

export function reqGetECFilters(data) {
    return request({
        url: '/api/ec-data/get-filters',
        method: 'post',
        data: data
    })
}

export function reqExportSearchResult(data) {
    return request({
        url: '/api/ec-data/export-search-result',
        method: 'post',
        data: data
    })
}

export function reqUploadImage(data, onProgress) {
    return request({
        url: `/api/ec-data/upload-image`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}

export function reqRemoveUploadImage(data) {
    return request({
        url: `/api/ec-data/delete-upload-pic`,
        method: 'post',
        data: data,
    })
}

export function reqBatchUpdateCategory(data) {
    return request({
        url: `/api/ec-data/batch-update-category`,
        method: 'post',
        data: data,
    })
}

export function reqBatchUpdateAction(data) {
    return request({
        url: `/api/ec-data/batch-update-action`,
        method: 'post',
        data: data,
    })
}

// ec report

export function reqUploadECReport(formData, onProgress) {
    return request({
        url: '/api/ec-report/upload-report',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}

export function reqBatchUpdateECReport(formData, onProgress) {
    return request({
        url: '/api/ec-report/batch-update',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}


export function reqRemoveECReport(data) {
    return request({
        url: '/api/ec-report/remove-report',
        method: 'post',
        data: data,
    })
}

export function reqDownloadECReport(data) {
    return request({
        url: '/api/ec-report/download-report',
        method: 'post',
        data: data,
    })
}

export function reqInheritPreECReport(data) {
    return request({
        url: '/api/ec-report/inherit-records',
        method: 'post',
        data: data,
    })
}

export function reqDeleteRepeatRecords(data) {
    return request({
        url: '/api/ec-report/delete-repeat-records',
        method: 'post',
        data: data,
    })
}

export function reqPostReport(data) {
    return request({
        url: '/api/ec-report/post-report',
        method: 'post',
        data: data
    })
}


export function reqFetchReports(data) {
    return request({
        url: '/api/ec-report/fetch-reports',
        method: 'post',
        data: data
    })
}

export function reqResponseReport(data) {
    return request({
        url: '/api/ec-report/response-report',
        method: 'post',
        data: data
    })
}



// ec dashboard
export function reqFetchDashboardInfo(data) {
    return request({
        url: '/api/ec-dashboard/fetch-info',
        method: 'post',
        data: data
    })
}

// ec home
export function reqFetchHomeInfo(data) {
    return request({
        url: '/api/home-dashboard',
        method: 'post',
        data: data

    })
}