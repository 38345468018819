export const TrademarkSites = [
    {
        "url": "https://www.j-platpat.inpit.go.jp/",
        "name": {
            ja:"日本",
            en:"Japan"
        }
    },
    {
        "url": "http://www.wipo.int/madrid/monitor/en/",
        "name": {
            ja:"WIPO（国際登録）",
            en:"WIPO"
        }
    },
    {
        "url": "http://www.wipo.int/branddb/en/",
        "name": {
            ja:"WIPO（Global Brand Database）",
            en:"WIPO（Global Brand Database）"
        }
    },
    {
        "url": "https://euipo.europa.eu/eSearch/",
        "name": {
            ja:"EU（EUTM）",
            en:"EU（EUTM）"
        }
    },
    {
        "url": "https://www.tmdn.org/tmview/welcome",
        "name": {
            ja:"EU（TMview）",
            en:"EU（TMview）"
        }
    },
    {
        "url": "http://tmclass.tmdn.org/ec2/",
        "name": {
            ja:"EU（TMclass）",
            en:"EU（TMclass）"
        }
    },
    {
        "url": "http://www.asean-tmview.org/tmview/welcome.html",
        "name": {
            ja:"ASEAN TMview",
            en:"ASEAN TMview"
        }
    },
    {
        "url": "https://www.foreignsearch.jpo.go.jp/tm_bangousyoukai.php",
        "name": {
            ja:"FOPISER (外国特許情報サービス)",
            en:"FOPISER"
        }
    },
    {
        "url": "http://prosur.org/tramitacion/buscador-de-marcas/",
        "name": {
            ja:"PROSUR (南米知的財産庁間協力プロジェクト)",
            en:"PROSUR"
        }
    },
    {
        "url": "http://regionalip.aripo.org/wopublish-search/public/home?2",
        "name": {
            ja:"ARIPO (アフリカ広域知的財産機関)",
            en:"ARIPO"
        }
    },
    {
        "url": "http://www.els.is/merki/leit/",
        "name": {
            ja:"アイスランド",
            en:"Iceland"
        }
    },
    {
        "url": "https://eregister.patentsoffice.ie/query/TMQuery.aspx",
        "name": {
            ja:"アイルランド",
            en:"Ireland"
        }
    },
    {
        "url": "http://tmsearch.uspto.gov/",
        "name": {
            ja:"アメリカ",
            en:"United States"
        }
    },
    {
        "url": "https://portaltramites.inpi.gob.ar/Clasico/Docs/FormulariosConsultas/MarConsultaAvanzada.asp",
        "name": {
            ja:"アルゼンチン",
            en:"Argentine"
        }

    },
    {
        "url": "https://www.aipa.am/en/national-procedure-reg/",
        "name": {
            ja:"アルメニア",
            en:"Armenia"
        }
    },
    {
        "url": "http://www.ompa.ad/en/trademark-registry-search",
        "name": {
            ja:"アンドラ",
            en:"Andorra"
        }
    },
    {
        "url": "http://www.ipo.gov.uk/types/tm/t-os/t-find.htm",
        "name": {
            ja:"イギリス",
            en:"UK"
        }

    },
    {
        "url": "http://www.trademarks.justice.gov.il/",
        "name": {
            ja:"イスラエル",
            en:"Israel"
        }
    },
    {
        "url": "http://www.uibm.gov.it/bancadati/search.php",
        "name": {
            ja:"イタリア",
            en:"Italy"
        }
    },
    {
        "url": "http://ip.ssaa.ir/BSigns/search.aspx",
        "name": {
            ja:"イラン",
            en:"Iran"
        }
    },
    {
        "url": "http://ipindiaonline.gov.in/eregister/eregister.aspx",
        "name": {
            ja:"インド１",
            en:"India 1"
        }
    },
    {
        "url": "http://ipindiaonline.gov.in/tmrpublicsearch/frmmain.aspx",
        "name": {
            ja:"インド２",
            en:"India 2"
        }

    },
    {
        "url": "https://pdki-indonesia.dgip.go.id/",
        "name": {
            ja:"インドネシア",
            en:"Indonesia"
        }
    },
    {
        "url": "https://sis.ukrpatent.org/en/search/advanced/",
        "name": {
            ja:"ウクライナ",
            en:"Ukraine"
        }
    },
    {
        "url": "https://rad.miem.gub.uy/pamp/mark/index",
        "name": {
            ja:"ウルグアイ",
            en:"Uruguay"
        }
    },
    {
        "url": "http://andmebaas.epa.ee/avalik/#/trademarks",
        "name": {
            ja:"エストニア",
            en:"Estonia"
        }
    },
    {
        "url": "https://search.ipaustralia.gov.au/trademarks/search/advanced",
        "name": {
            ja:"オーストラリア",
            en:"Australia"
        }
    },
    {
        "url": "http://see-ip.patentamt.at/",
        "name": {
            ja:"オーストリア",
            en:"Austria"
        }
    },
    {
        "url": "https://gosreestr.kazpatent.kz/",
        "name": {
            ja:"カザフスタン",
            en:"Kazakhstan"
        }
    },
    {
        "url": "http://www.ic.gc.ca/app/opiccipo/trdmrks/srch/tmSrch.do?lang=eng",
        "name": {
            ja:"カナダ",
            en:"Canada"
        }
    },
    {
        "url": "http://www.wipo.int/branddb/kh/en/",
        "name": {
            ja:"カンボジア",
            en:"Cambodia"
        }
    },
    {
        "url": "http://www.dziv.hr/en/e-services/on-line-databasesearch/trademarks/",
        "name": {
            ja:"クロアチア",
            en:"Croatia"
        }
    },
    {
        "url": "http://sipi.sic.gov.co/sipi/Extra/IP/TM/Qbe.aspx?sid=636579050192116033",
        "name": {
            ja:"コロンビア",
            en:"Columbia"
        }
    },
    {
        "url": "http://91.187.97.74/wopublishsearch/public/trademarks",
        "name": {
            ja:"コソボ",
            en:"Kosovo"
        }
    },
    {
        "url": "http://www.sakpatenti.org.ge/index.php?sec_id=46&lang_id=ENG&cat=3&search=Search",
        "name": {
            ja:"ジョージア（グルジア）",
            en:"Georgia"
        }
    },
    {
        "url": "https://www.ip2.sg/RPS/WP/CM/SearchSimpleP.aspx?SearchCategory=TM",
        "name": {
            ja:"シンガポール",
            en:"Singapore"
        }
    },
    {
        "url": "https://www.bip.sx/trademarkregistration/searching-the-register",
        "name": {
            ja:"シント・マールテン島",
            en:"Sint Maarten"
        }
    },
    {
        "url": "https://www.swissreg.ch/srclient/faces/jsp/start.jsp",
        "name": {
            ja:"スイス",
            en:"Swiss"
        }
    },
    {
        "url": "https://was.prv.se/VarumarkesDb/searchMain.jsp",
        "name": {
            ja:"スウェーデン",
            en:"Sweden"
        }
    },
    {
        "url": "http://www.oepm.es/es/signos_distintivos/avanzadas.html",
        "name": {
            ja:"スペイン",
            en:"Spain"
        }
    },
    {
        "url": "http://registre.indprop.gov.sk/registre/searchForm.do?lang=en&register=oz",
        "name": {
            ja:"スロバキア",
            en:"Slovak"
        }
    },
    {
        "url": "http://www2.uil-sipo.si/dse.htm",
        "name": {
            ja:"スロベニア",
            en:"Slovenia"
        }
    },
    {
        "url": "http://www.zis.gov.rs/databases/databases.533.html",
        "name": {
            ja:"セルビア",
            en:"Serbia"
        }
    },
    {
        "url": "http://www.ipthailand.go.th/th/e-service/ตรวจสอบขอมล เครืองหมายการค้าสําหรับประชาชน.html",
        "name": {
            ja:"タイ",
            en:"Thailand"
        }
    },
    {
        "url": "http://isdv.upv.cz/portal/pls/portal/portlets.ozs.frm?plan=English",
        "name": {
            ja:"チェコ",
            en:"Czech"
        }
    },
    {
        "url": "https://ion.inapi.cl/Marca/BuscarMarca.aspx",
        "name": {
            ja:"チリ",
            en:"Chile"
        }
    },
    {
        "url": "http://onlineweb.dkpto.dk/pvsonline/Varemaerke?action=201&subAction=",
        "name": {
            ja:"デンマーク",
            en:"Denmark"
        }
    },
    {
        "url": "https://register.dpma.de/DPMAregister/marke/einsteiger",
        "name": {
            ja:"ドイツ",
            en:"Germany"
        }
    },
    {
        "url": "http://www.ipo.gov.tt/online-services/online-trademarksearch.html",
        "name": {
            ja:"トリニダード・トバゴ",
            en:"Trinidad and Tobago"
        }
    },
    {
        "url": "http://online.turkpatent.gov.tr/trademarksearch/pub/trademark_search?lang=en",
        "name": {
            ja:"トルコ",
            en:"Turkey"
        }
    },
    {
        "url": "https://www.iponz.govt.nz/manage-ip",
        "name": {
            ja:"ニュージーランド",
            en:"New Zealand"
        }
    },
    {
        "url": "https://dbsearch2.patentstyret.no/AdvancedSearch.aspx?Category=Mark",
        "name": {
            ja:"ノルウェー",
            en:"Norway"
        }
    },
    {
        "url": "http://servicios.dinapi.gov.py/",
        "name": {
            ja:"パラグアイ",
            en:"Paraguay"
        }
    },
    {
        "url": "http://epub.hpo.hu/e-kutatas/?lang=EN",
        "name": {
            ja:"ハンガリー",
            en:"Hungary"
        }
    },
    {
        "url": "http://www.wipo.int/branddb/ph/en/",
        "name": {
            ja:"フィリピン",
            en:"Philippines"
        }
    },
    {
        "url": "https://epalvelut.prh.fi/en/web/tietopalvelu/haku",
        "name": {
            ja:"フィンランド",
            en:"Finland"
        }
    },
    {
        "url": "https://prtmfiling.f1hst.com/TrademarkSearch/Search",
        "name": {
            ja:"プエルトリコ",
            en:"Puerto Rico"
        }
    },
    {
        "url": "https://gru.inpi.gov.br/pePI/jsp/marcas/Pesquisa_classe_basica.jsp",
        "name": {
            ja:"ブラジル",
            en:"Brazil"
        }
    },
    {
        "url": "https://basesmarques.inpi.fr/Typo3_INPI_Marques/marques_recherche_avancee.html",
        "name": {
            ja:"フランス",
            en:"France"
        }
    },
    {
        "url": "https://portal.bpo.bg/bpo_online?p_p_id=bposervicesportlet_WAR_bposervicesportlet&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&_bposervicesportlet_WAR_bposervicesportlet__facesViewIdRender=%2Fmark%2FsearchForm.xhtml",
        "name": {
            ja:"ブルガリア",
            en:"Bulgaria"
        }
    },
    {
        "url": "http://www.eregistry.agc.gov.bn/WorkAreaTrademark/WorkFunctionSearch/WebViewSearch/DoTrademarkSearch.aspx",
        "name": {
            ja:"ブルネイ",
            en:"Brunei Darussalam"
        }
    },
    {
        "url": "http://iplib.noip.gov.vn/WebUI/WSearch.php",
        "name": {
            ja:"ベトナム",
            en:"Viet Nam"
        }
    },
    {
        "url": "https://register.boip.int/bmbonline/intro/show.do",
        "name": {
            ja:"ベネルクス",
            en:"Benelux"
        }
    },
    {
        "url": "http://www.belgospatent.org.by/database/index.php?pref=tz&lng=ru&page=1",
        "name": {
            ja:"ベラルーシ",
            en:"Belarus"
        }
    },
    {
        "url": "http://sistemas.indecopi.gob.pe/OsdConsultasPublicas/",
        "name": {
            ja:"ペルー",
            en:"Peru"
        }
    },
    {
        "url": "http://regservtd.uprp.pl/register/advancedSearch?lng=en",
        "name": {
            ja:"ポーランド",
            en:"Poland"
        }
    },
    {
        "url": "http://servicosonline.inpi.pt/pesquisas/main/marcas.jsp?lang=EN",
        "name": {
            ja:"ポルトガル",
            en:"Portuguese"
        }
    },
    {
        "url": "https://www.economia.gov.mo/en_US/web/public/Pg_ES_AE_QE_TRADEMARK?_refresh=true",
        "name": {
            ja:"マカオ",
            en:"Macao"
        }
    },
    {
        "url": "https://ips.gov.mt/NR/",
        "name": {
            ja:"マルタ",
            en:"Malta"
        }
    },
    {
        "url": "http://www.ippo.gov.mk/Search/TrademarkSearch.aspx",
        "name": {
            ja:"マケドニア",
            en:"Macedonia"
        }
    },
    {
        "url": "https://estatus.myipo.gov.my/estatus/web/index.php?r=estatus",
        "name": {
            ja:"マレーシア",
            en:"Malaysia"
        }
    },
    {
        "url": "http://marcanet.impi.gob.mx/marcanet/",
        "name": {
            ja:"メキシコ",
            en:"Mexico"
        }
    },
    {
        "url": "http://www.db.agepi.md/trademarks/",
        "name": {
            ja:"モルドバ",
            en:"Moldova"
        }
    },
    {
        "url": "http://www.ompic.ma/en/content/trademarks",
        "name": {
            ja:"モロッコ",
            en:"Morocco"
        }
    },
    {
        "url": "http://www.ipom.mn:8080/system/search/ISIDKeywordTrademarkScreenCmd.do?countType=40",
        "name": {
            ja:"モンゴル",
            en:"Mongol"
        }
    },
    {
        "url": "http://databases.lrpv.gov.lv/databases/en/trademark/search",
        "name": {
            ja:"ラトビア",
            en:"Latvia"
        }
    },
    {
        "url": "http://www.vpb.lt/index.php?n=300&l=en",
        "name": {
            ja:"リトアニア",
            en:"Lithuania"
        }
    },
    {
        "url": "http://api.osim.ro:18080/marci/cautareb.jsp",
        "name": {
            ja:"ルーマニア",
            en:"Romania"
        }
    },
    {
        "url": "http://engdtj.kipris.or.kr/engdtj/searchLogina.do?method=loginTM",
        "name": {
            ja:"韓国",
            en:"Korea"
        }
    },
    {
        "url": "http://ipsearch.ipd.gov.hk/trademark/jsp/index.html",
        "name": {
            ja:"香港",
            en:"Hong Kong"
        }
    },
    {
        "url": "https://twtmsearch.tipo.gov.tw/OS0/OS0101.jsp?l6=en_US&isReadBulletinzh_TW=true",
        "name": {
            ja:"台湾",
            en:"Taiwan"
        }
    },
    {
        "url": "http://wcjs.sbj.cnipa.gov.cn/txnT01.do",
        "name": {
            ja:"中国",
            en:"China"
        }
    }
]