import * as types from "../action-types";

export const setProductCategoryAction = (clientId,productCategoryInfo) => {
    return {
        type: types.MASTER_DATA_PRODUCT_CATEGORY_CHANGED,
        clientId,
        data:productCategoryInfo
    };
};

export const setCustomColumnAction = (clientId,customColumnInfo) => {
    return {
        type: types.MASTER_DATA_CUSTOM_COLUMN_CHANGED,
        clientId,
        data:customColumnInfo
    }
}