import request from '@/utils/request'


export function reqGetWSRecord(data) {
    return request({
        url: '/api/ws-data/get-record',
        method: 'post',
        data: data
    })
}

export function reqGetWSRecordHistory(data) {
    return request({
        url: '/api/ws-data/get-record-history',
        method: 'post',
        data: data
    })
}

export function reqUpdateWSRecord(data) {
    return request({
        url: '/api/ws-data/update-record',
        method: 'post',
        data: data
    })
}

export function reqSearchWSRecords(data) {
    return request({
        url: '/api/ws-data/search-records',
        method: 'post',
        data: data
    })
}

export function reqGetWSFilters(data) {
    return request({
        url: '/api/ws-data/get-filters',
        method: 'post',
        data: data
    })
}

export function reqExportSearchResult(data) {
    return request({
        url: '/api/ws-data/export-search-result',
        method: 'get',
        data: data
    })
}

export function reqBatchUpdateCategory(data) {
    return request({
        url: `/api/ws-data/batch-update-category`,
        method: 'post',
        data: data,
    })
}

export function reqBatchUpdateAction(data) {
    return request({
        url: `/api/ws-data/batch-update-action`,
        method: 'post',
        data: data,
    })
}

// ws report

export function reqUploadWSReport(formData, onProgress) {
    return request({
        url: '/api/ws-report/upload-report',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}

export function reqBatchUpdateWSReport(formData, onProgress) {
    return request({
        url: '/api/ws-report/batch-update',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}

export function reqRemoveWSReport(data) {
    return request({
        url: '/api/ws-report/remove-report',
        method: 'post',
        data: data,
    })
}

export function reqDownloadWSReport(data) {
    return request({
        url: '/api/ws-report/download-report',
        method: 'post',
        data: data,
    })
}


export function reqPostReport(data) {
    return request({
        url: '/api/ws-report/post-report',
        method: 'post',
        data: data
    })
}


export function reqFetchReports(data) {
    return request({
        url: '/api/ws-report/fetch-reports',
        method: 'post',
        data: data
    })
}

export function reqResponseReport(data) {
    return request({
        url: '/api/ws-report/response-report',
        method: 'post',
        data: data
    })
}
