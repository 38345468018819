export const ProductTrademarkType = [{ name: { ja: "化学品", en: "Chemicals" }, value: "1" },
{ name: { ja: "塗料・着色料", en: "Paints" }, value: "2" },
{ name: { ja: "洗浄剤、化粧品", en: "Cleaning Substances" }, value: "3" },
{ name: { ja: "オイル・燃料", en: "Industrial Oils" }, value: "4" },
{ name: { ja: "薬剤", en: "Pharmaceuticals" }, value: "5" },
{ name: { ja: "卑金属、卑金属製品", en: "Base Metals" }, value: "6" },
{ name: { ja: "工作機械、原動機", en: "Machines" }, value: "7" },
{ name: { ja: "手動工具", en: "Hand Tools" }, value: "8" },
{ name: { ja: "電気制御用の機械器具", en: "Computers and Scientific Devices" }, value: "9" },
{ name: { ja: "医療器具", en: "Medical Supplies" }, value: "10" },
{ name: { ja: "環境装置(照明・加熱・給水)", en: "Appliances" }, value: "11" },
{ name: { ja: "乗物", en: "Vehicles" }, value: "12" },
{ name: { ja: "火器", en: "Firearms" }, value: "13" },
{ name: { ja: "貴金属、宝飾品、時計", en: "Precious Metals" }, value: "14" },
{ name: { ja: "楽器", en: "Musical Instruments" }, value: "15" },
{ name: { ja: "紙、事務用品", en: "Paper Goods" }, value: "16" },
{ name: { ja: "プラスチック材料", en: "Rubber Products" }, value: "17" },
{ name: { ja: "革製品・旅行品", en: "Leather Goods" }, value: "18" },
{ name: { ja: "金属以外の建築材料", en: "Building Materials" }, value: "19" },
{ name: { ja: "家具、プラスチック製品", en: "Furniture" }, value: "20" },
{ name: { ja: "台所用品、ガラス・陶器製品", en: "Household Utensils" }, value: "21" },
{ name: { ja: "繊維、ひも、網", en: "Ropes and Textile Products" }, value: "22" },
{ name: { ja: "糸", en: "Yarns and Threads" }, value: "23" },
{ name: { ja: "織物、カバー", en: "Textiles" }, value: "24" },
{ name: { ja: "服、履物", en: "Clothing" }, value: "25" },
{ name: { ja: "裁縫用品", en: "Lace and Embroidery" }, value: "26" },
{ name: { ja: "床敷物、壁掛け", en: "Carpets" }, value: "27" },
{ name: { ja: "おもちゃ、遊戯・運動用具", en: "Games and Sporting Goods" }, value: "28" },
{ name: { ja: "動物性・農産物性食品", en: "Meat, Fish, Poultry" }, value: "29" },
{ name: { ja: "コーヒー、調味料、菓子", en: "Coffee, Flour, Rice" }, value: "30" },
{ name: { ja: "穀物、魚、果実、種", en: "Grains, Agriculture" }, value: "31" },
{ name: { ja: "飲料、ビール", en: "Beers and Beverages" }, value: "32" },
{ name: { ja: "酒類", en: "Alcoholic Beverages" }, value: "33" },
{ name: { ja: "たばこ、喫煙用具", en: "Tobacco Products" }, value: "34" },
{ name: { ja: "広告、事業、卸売", en: "Advertising and Business Services" }, value: "35" },
{ name: { ja: "金融、保険、不動産", en: "Insurance and Finance Services" }, value: "36" },
{ name: { ja: "建設、工事、修理", en: "Construction and Repair Services" }, value: "37" },
{ name: { ja: "電気通信", en: "Telecommunications Services" }, value: "38" },
{ name: { ja: "輸送、保管、旅行の手配", en: "Shipping and Travel Services" }, value: "39" },
{ name: { ja: "物品の加工、処理", en: "Material Treatment Services" }, value: "40" },
{ name: { ja: "教育、娯楽、スポーツ、文化", en: "Education and Entertainment Services" }, value: "41" },
{ name: { ja: "調査、分析、設計、開発", en: "Science and Technology Services" }, value: "42" },
{ name: { ja: "飲食、宿泊", en: "Food Services" }, value: "43" },
{ name: { ja: "医療、美容、農林", en: "Medical and Vet Services" }, value: "44" },
{ name: { ja: "冠婚葬祭、警備、法律事務", en: "Legal and Security Services" }, value: "45" }]