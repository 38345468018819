// css reference
import './index.less'
import ECChartContainer from '../common/chart-container'
import ChartDataFilter from './chart-data-filter'
import SellerList from './seller-list'

// react Hook reference dependency
import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// react plugin reference dependency
import moment from 'moment'

// internationalization processing dependency
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import messages from './messages.js'

// antd referece dependency
import { Col, Row, Slider } from 'antd'

// redux reference dependency
import store from '@/store'
import { setMenuActiveKey } from '@/store/actions/menu.js'
import {
  setContentContainerHeaderAction,
  setBreadcrumbs,
} from '@/store/actions/content-header.js'

// api reference
import { reqFetchClients } from '@/api/analyst-client'
import { reqFetchDashboardInfo } from '@/api/ec.js'

// component reference dependency
import EFCContainer from '../../components/ef-c-container'
import EFCConditionBar from '../../components/ef-c-conditionbar'
import EFCChartRate from './chart/ef-c-chart-rate'
import EFCChartBall from './chart/ef-c-chart-ball'
import EFCChartAnnular from './chart/ef-c-chart-annular'
import EFCChartMap from './chart/ef-c-chart-map'
import EFCChartHbar from './chart/ef-c-chart-horizontal-bar'
import EFCChartOverall from './chart/ef-c-chart-overall'
import EFCChartStacking from './chart/ef-c-chart-stacking'
import EFCChartRank from './chart/ef-c-chart-rank'
import EFCChartTakedown from './chart/ef-c-chart-takedown'
import EFCChartTakedownBar from './chart/ef-c-chart-takedown-bar'

// config about overall categories
import { InfringementCommonCategories } from '../../config/infringement-common-category.js'
import ECListCommon from '../ec/common/ec-list-common.js'
import commonMessage from '../../locales/messages'
import { TakedownRequest, TakedownResult } from '../../config/ec-report-select-items'
import { getCountryConfigDict } from '../../config/country'
import {
  WSCategoryType,
  WSTakedownResult,
} from '../../master-data/ws-master-data'
import { render } from 'less'

const EFDashboardTest = () => {
  const [clients, setClients] = useState([])
  const [tabActiveKey, setTabActiveKey] = useState('ec-dashboard')

  const [renderChartData, setRenderChartData] = useState({})

  const describe = [
    'app.ui.dashboard.search.brand',
    'app.ui.dashboard.search.date_column',
    'app.ui.dashboard.search.date_from',
    'app.ui.dashboard.search.find',
    'app.ui.dashboard.search.reset'
  ]
  const date = ['app.ui.eclist.table.ec_scan_month', 'app.ui.eclist.table.ec_categorize_date']

  const chartRateTitle = 'app.ui.dashboard.status.impact_revenue'
  const chartRateDesc = [
    'app.ui.dashboard.status.impact_revenue.usd',
    'app.ui.dashboard.status.impact_revenue.rate',
    'app.ui.dashboard.status.impact_revenue.jpy'
  ]
  const chartRateData = useRef([])

  const chartBallTitle = 'app.ui.dashboard.status.impact_trademark'
  const chartBallDesc = [
    'app.ui.dashboard.status.impact_trademark.categorizedcount',
    'app.ui.dashboard.status.impact_trademark.infringementcount',
    'app.ui.dashboard.status.impact_trademark.infringementcount_percent'
  ]
  const chartBallData = useRef([])

  const chartAnnularTitle = 'app.ui.dashboard.chart.overall_categorization_status.title'
  const chartAnnularDesc = [
    'app.ui.dashboard.chart.overall_categorization_status.non_infringement',
    'app.ui.dashboard.chart.overall_categorization_status.copyright_infringement',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_ad',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_counterfeit',
    'app.ui.dashboard.chart.overall_categorization_status.need_sold_ended',
    'app.ui.dashboard.chart.overall_categorization_status.need_confrmation',
  ]

  const chartAnnularData = useRef()

  const chartMapTitle = 'app.ui.dashboard.map.overall_infringement_country.title'

  const chartMapDesc = []
  const chartMapData = useRef()

  const chartHbarTitle = 'app.ui.dashboard.chart.ec_infringement_categorization.title'
  const chartHbarDesc = []
  const chartHbarData = useRef()

  const chartOverallTitle = 'app.ui.dashboard.chart.overall_infringement.title'
  const chartOverallDesc = [
    'app.ui.dashboard.chart.overall_infringement.legend.categorized',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment_percent'
  ]
  const chartOverallData = useRef({})

  const chartStackingTitle = 'app.ui.dashboard.table.categorization_status.title'
  const chartStackingDesc = [
    'app.ui.dashboard.chart.overall_categorization_status.non_infringement',
    'app.ui.dashboard.chart.overall_categorization_status.copyright_infringement',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_ad',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_counterfeit',
    'app.ui.dashboard.chart.overall_categorization_status.need_sold_ended',
    'app.ui.dashboard.chart.overall_categorization_status.need_confrmation',
    'app.ui.dashboard.chart.overall_categorization_status.other_law_infringement',
  ]
  const chartStackingData = useRef({})
  const selectData = useRef([])

  const chartRankTitle = 'app.ui.dashboard.list.top_infringers.title'
  const chartRankDesc = []
  const chartRankSelect = useRef()
  const [selectRankItem, setSelectRankItem] = useState()

  const chartTakedownTitle = 'app.ui.dashboard.chart.takedown.title'
  const chartTakedownDesc = []
  const chartTakedownSelect = useRef([])
  const [selectTakedownItem, setSelectTakedownItem] = useState()

  const chartWebTakedownTitle = 'app.ui.dashboard.chart.ws_takedown.title'
  const chartWebTakedownDesc = []
  const chartWebTakedownData = useRef()

  const chartWebCategoryTitle = 'app.ui.dashboard.chart.ws_category.title'
  const chartWebCategoryDesc = []
  const chartWebCategoryData = useRef()

  const analyst = useRef()
  const clientId = useRef()

  // Tab
  const tabs = useRef({ item: ['E-commerce', 'Web Site'], mode: 'card' })

  const users = useRef({
    label: 'Analyst',
    items: [
      // 'Chicago',
      // 'Yoshihisa Tsuchiya',
      // 'Megumi Sugita',
      // 'Yukako Segawa',
      // 'Kohei Segawa',
      // 'Guo Fan',
    ],
  })
  const [searchTrigger, setSearchTrigger] = useState(0)
  const [page, setPage] = useState(0)

  // component instance
  // const mapContainer = useRef()
  // const map = useRef()
  // const formRef = useRef()

  // 国际化配置
  const intl = useIntl()
  // 路由跳转
  const history = useHistory()
  // 获取位置信息
  const location = useLocation()
  let nameIntlKey = intl.formatMessage(
    commonMessage['infirgment_common_cateogry.name_locale_key']
  )

  // 访问全局redux状态数据
  const reduxState = useSelector((state) => state)
  // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
  let reduxProps = {
    ...reduxState.user,
    ...reduxState.mainMenu,
  }

  const renderBread = () => {
    if (reduxProps.role & 2) {
      store.dispatch(setMenuActiveKey('analyst-online-dashboard'))
    } else {
      store.dispatch(setMenuActiveKey('client-online-dashboard'))
    }

    store.dispatch(setContentContainerHeaderAction([]))
    store.dispatch(
      setBreadcrumbs([
        {
          name: <FormattedMessage id="app.layout.menu.home" />,
          path: '/',
        },
        {
          name: <FormattedMessage id="app.ui.dashboard.breadcrumb.dashboard" />,
          path: '/online-dashboard',
        },
      ])
    )
  }

  // Basic state function
  useEffect(() => {
    // Obtain all url params
    onUrlRender()
    // Obtain all brand data
    if (reduxProps.role & 2) {
      reqFetchClients()
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            setClients(data.data.client)
          }
        })
        .catch((error) => { })
    }

    renderBread()

    if (reduxProps.role & 1) {
      clientId.current = reduxProps.companyInfo.client_id
      reduxProps.analystInfo.map((item, index) => {
        if (item.user_name.length > 0) {
          users.current.items.push(item.user_name)
        }
      })
    }
  }, [])

  const fetchDashboardInfo = async (clientId, fromDate, toDate, dateType) => {
    await reqFetchDashboardInfo({
      clientId: parseInt(clientId),
      fromDate: fromDate,
      toDate: toDate,
      dateType: dateType,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          let obj = {
            ...data.data,
            infringementTypeFilters: data.data.infringementFilters,
            countryDateInfringementFilters: data.data.countryFilters,
            countryInfringementFilters: data.data.countryFilters,
            countryECInfringementFilters: data.data.countryFilters,
            countryTakedownFilters: data.data.countryFilters,
            countrySellerFilters: data.data.countryFilters,
          }

          if (clients.length > 0 && clientId) {
            users.current.items = []
            clients.map((item,index) => {
              if(item.client_id === clientId) {
                item.analystInfo.map(item1 => {
                  users.current.items.push(item1.user_name)
                })
              }
            })
          }
          setRenderChartData(obj)
        }
        return {}
      })
      .catch((error) => { console.error(error) })
  }

  const wrapDataWithFromTo = (fromDate, ToDate, fn) => {
    let fromYear
    let fromMonth
    if (fromDate !== '' && fromDate !== null && fromDate !== undefined) {
      fromYear = parseInt(fromDate.split('-')[0])
      fromMonth = parseInt(fromDate.split('-')[1])
    } else {
      return
    }

    let result = []
    const toYear = parseInt(ToDate.split('-')[0])
    const toMonth = parseInt(ToDate.split('-')[1])
    for (let year = fromYear; year <= toYear; year++) {
      let start = fromMonth
      let end = toMonth
      if (year === fromYear) {
        if (year === toYear) {
          start = fromMonth
          end = toMonth
        } else {
          start = fromMonth
          end = 12
        }
      } else {
        if (year === toYear) {
          start = 1
          end = toMonth
        } else {
          start = 1
          end = 12
        }
      }
      for (let month = start; month <= end; month++) {
        let strMonth = month < 10 ? `0${month}` : `${month}`
        result.push(fn(`${year}-${strMonth}`))
      }
    }
    return result
  }

  const getTrakemark = () => {
    let obj = { categorizedCount: 0, infringementCount: 0, 'infringementCount%': '' }
    let keys = Object.keys(renderChartData.dateInfringementCountDict)
    keys.map((item, index) => {
      if (renderChartData.dateInfringementCountDict[item].categorizedCount) {
        obj.categorizedCount += Number(renderChartData.dateInfringementCountDict[item].categorizedCount)
      }
      if (renderChartData.dateInfringementCountDict[item].infringementCount) {
        obj.infringementCount += Number(renderChartData.dateInfringementCountDict[item].infringementCount)
      }
    })
    if (obj.categorizedCount !== 0 && obj.infringementCount !== 0) {
      let num = obj.infringementCount / obj.categorizedCount * 100
      obj['infringementCount%'] = num.toFixed(1) + '%'
    } else {
      obj['infringementCount%'] = 0 + '%'
    }
    chartBallData.current = obj
  }

  // data analysis
  const getOverallCategorization = () => {
    const searchParams = new URLSearchParams(location.search)
    const startDate = searchParams.get('fromDate')
    const endDate = searchParams.get('toDate')
    let temp = {}
    let xAxisData = []
    let series = []
    let legend = []
    if (renderChartData.dateCountryInfringementTypeDict) {
      xAxisData = wrapDataWithFromTo(
        startDate,
        endDate,
        (monthStr) => {
          renderChartData.infringementTypeFilters.forEach((infringement) => {
            if (!temp[infringement]) {
              temp[infringement] = []
            }
            if (renderChartData.dateCountryInfringementTypeDict[monthStr]) {
              let infringementCount = 0
              Object.keys(
                renderChartData.dateCountryInfringementTypeDict[monthStr]
              ).forEach((countryName) => {
                if (
                  renderChartData.dateCountryInfringementTypeDict[monthStr][
                  countryName
                  ][infringement]
                ) {
                  infringementCount =
                    infringementCount +
                    renderChartData.dateCountryInfringementTypeDict[monthStr][
                    countryName
                    ][infringement]
                }
              })
              temp[infringement].push(infringementCount)
            } else {
              temp[infringement].push(0)
            }
          })
          return monthStr
        }
      )
    }
    series = renderChartData.infringementTypeFilters
      .sort((a, b) => {
        return parseInt(a) > parseInt(b) ? 1 : -1
      })
      .map((infringement) => {
        let infringementInfo = InfringementCommonCategories.find((info) => {
          return info.id === parseInt(infringement)
        })
        legend.push(infringementInfo.name)
        return {
          type: 'line',
          showSymbol: false,
          smooth: true,
          name: infringementInfo.name,
          data: temp[infringement],
        }
      })
    chartAnnularData.current = series
  }

  const getInfringementImpact = () => {
    chartRateData.current = [
      { value: renderChartData.impactRevenueUSD },
      { value: renderChartData.JPYRate },
      { value: renderChartData.impactRevenueJPY }
    ]
  }

  const getContountryFilter = (countryFilter) => {
    if (countryFilter && countryFilter.length > 0) {
      let localeDict = getCountryConfigDict()
      return countryFilter.map((countryCode) => {
        if (localeDict[countryCode]) {
          return {
            name: localeDict[countryCode].name,
            value: countryCode,
          }
        } else {
          return {
            name: countryCode,
            value: countryCode,
          }
        }
      })
    } else {
      return []
    }
  }

  const getCountryData = () => {
    chartMapData.current = renderChartData.countryInfringementCountDict
    let country = getContountryFilter(Object.keys(renderChartData.countryInfringementCountDict))
    let keys = Object.keys(renderChartData.countryInfringementCountDict)
    keys.map((item, index) => {
      let obj = country.find(item1 => item1.value === item)
      if (obj) {
        chartMapData.current[item].name = obj.name
      }
    })
  }

  const getWebSite = () => {
    // let { echarts, title, xAxisData, yAxisName, yAxisData,
    //     seriesBarName, seriesBarData, seriesPieName, seriesPieData, elementId } = config
    if (renderChartData.countrySiteInfringementTypeDict) {
      let siteInfringementCountDict = {}
      let countryInfringementCountList = []
      Object.keys(renderChartData.countrySiteInfringementTypeDict).forEach(
        (countryName) => {
          let countryInfringementCount = 0
          Object.keys(
            renderChartData.countrySiteInfringementTypeDict[countryName]
          ).forEach((siteName) => {
            let siteCount = 0
            Object.keys(
              renderChartData.countrySiteInfringementTypeDict[countryName][siteName]
            ).forEach((infringementName) => {
              if (infringementName !== '1') {
                countryInfringementCount =
                  countryInfringementCount +
                  renderChartData.countrySiteInfringementTypeDict[countryName][
                  siteName
                  ][infringementName]
                siteCount =
                  siteCount +
                  renderChartData.countrySiteInfringementTypeDict[countryName][
                  siteName
                  ][infringementName]
              }
            })
            if (siteInfringementCountDict[siteName]) {
              siteInfringementCountDict[siteName] =
                siteInfringementCountDict[siteName] + siteCount
            } else {
              siteInfringementCountDict[siteName] = siteCount
            }
          })
          countryInfringementCountList.push({
            name: countryName,
            value: countryInfringementCount,
          })
        }
      )
      let siteInfringementCountList = Object.keys(siteInfringementCountDict)
        .map((siteName) => {
          return {
            name: siteName,
            value: siteInfringementCountDict[siteName],
          }
        })
        .sort((siteA, siteB) => {
          return siteA.value < siteB.value ? 1 : -1
        })
        .filter((site) => {
          return site.value > 0
        })
      countryInfringementCountList = countryInfringementCountList.sort(
        (a, b) => {
          return a.value > b.value ? 1 : -1
        }
      )
      chartHbarData.current = siteInfringementCountList
    }
  }

  const getInfringementCountPercent = () => {
    if (renderChartData.dateCountryInfringementTypeDict) {
      let leftYAxisData = []
      let rightYAxisData = []
      let infringementPercent = []
      let categorizedCountList = []
      let infringementCountList = []

      const searchParams = new URLSearchParams(location.search)
      const startDate = searchParams.get('fromDate')
      const endDate = searchParams.get('toDate')

      let xAxisData = wrapDataWithFromTo(
        startDate,
        endDate,
        (monthStr) => {
          if (renderChartData.dateCountryInfringementTypeDict[monthStr]) {
            let categorizedCount = 0
            let infringementCount = 0
            Object.keys(
              renderChartData.dateCountryInfringementTypeDict[monthStr]
            ).forEach((countryName) => {
              Object.keys(
                renderChartData.dateCountryInfringementTypeDict[monthStr][
                countryName
                ]
              ).forEach((categorizedName) => {
                categorizedCount =
                  categorizedCount +
                  renderChartData.dateCountryInfringementTypeDict[monthStr][
                  countryName
                  ][categorizedName]
                if (categorizedName !== '1' && categorizedName !== '10') {
                  infringementCount =
                    infringementCount +
                    renderChartData.dateCountryInfringementTypeDict[monthStr][
                    countryName
                    ][categorizedName]
                }
              })
            })
            leftYAxisData.push(categorizedCount)
            categorizedCountList.push(categorizedCount)
            infringementCountList.push(infringementCount)
            if (categorizedCount > 0) {
              infringementPercent.push(
                parseInt(((infringementCount * 1.0) / categorizedCount) * 100) /
                100.0
              )
              rightYAxisData.push((infringementCount * 1.0) / categorizedCount)
            } else {
              infringementPercent.push(0)
              rightYAxisData.push(0)
            }
          } else {
            leftYAxisData.push(0)
            rightYAxisData.push(0)
            infringementPercent.push(0)
            categorizedCountList.push(0)
            infringementCountList.push(0)
          }
          return monthStr
        }
      )

      return {
        leftYAxisData,
        rightYAxisData,
        xAxisData,
        infringementPercent,
        categorizedCountList,
        infringementCountList,
      }
    } else {
      return {}
    }
  }

  const getOverallInfringement = () => {
    if (renderChartData.dateCountryInfringementTypeDict) {
      let {
        leftYAxisData,
        rightYAxisData,
        infringementPercent,
        categorizedCountList,
        infringementCountList,
      } = getInfringementCountPercent()

      const searchParams = new URLSearchParams(location.search)
      const startDate = searchParams.get('fromDate')
      const endDate = searchParams.get('toDate')

      let xAxisData = wrapDataWithFromTo(
        startDate,
        endDate,
        (monthStr) => {
          if (renderChartData.dateCountryInfringementTypeDict[monthStr]) {
            let categorizedCount = 0
            let infringementCount = 0
            Object.keys(
              renderChartData.dateCountryInfringementTypeDict[monthStr]
            ).forEach((countryName) => {
              Object.keys(
                renderChartData.dateCountryInfringementTypeDict[monthStr][
                countryName
                ]
              ).forEach((categorizedName) => {
                categorizedCount =
                  categorizedCount +
                  renderChartData.dateCountryInfringementTypeDict[monthStr][
                  countryName
                  ][categorizedName]
                if (categorizedName !== '1' && categorizedName !== '10') {
                  infringementCount =
                    infringementCount +
                    renderChartData.dateCountryInfringementTypeDict[monthStr][
                    countryName
                    ][categorizedName]
                }
              })
            })
            leftYAxisData.push(categorizedCount)
            categorizedCountList.push(categorizedCount)
            infringementCountList.push(infringementCount)
            if (categorizedCount > 0) {
              infringementPercent.push(
                parseInt(((infringementCount * 1.0) / categorizedCount) * 100) /
                100.0
              )
              rightYAxisData.push((infringementCount * 1.0) / categorizedCount)
            } else {
              infringementPercent.push(0)
              rightYAxisData.push(0)
            }
          } else {
            leftYAxisData.push(0)
            rightYAxisData.push(0)
            infringementPercent.push(0)
            categorizedCountList.push(0)
            infringementCountList.push(0)
          }
          return monthStr
        }
      )
      chartOverallData.current['xAxis'] = xAxisData
      let dateValue = []
      xAxisData.map((item, index) => {
        if (renderChartData.dateInfringementCountDict[item]) {
          dateValue[item] = { categorizedCount: renderChartData.dateInfringementCountDict[item].categorizedCount, infringementCount: renderChartData.dateInfringementCountDict[item].infringementCount }
        } else {
          dateValue[item] = { categorizedCount: 0, infringementCount: 0 }
        }
      })
      chartOverallData.current['yAxis'] = dateValue
    }
  }

  const getCategorization = () => {
    let temp = {}
    let xAxisData = []
    let series = []
    let legend = []

    const searchParams = new URLSearchParams(location.search)
    const startDate = searchParams.get('fromDate')
    const endDate = searchParams.get('toDate')

    if (renderChartData.dateCountryInfringementTypeDict) {
      xAxisData = wrapDataWithFromTo(
        startDate,
        endDate,
        (monthStr) => {
          renderChartData.infringementTypeFilters.forEach((infringement) => {
            if (!temp[infringement]) {
              temp[infringement] = []
            }
            if (renderChartData.dateCountryInfringementTypeDict[monthStr]) {
              let infringementCount = 0
              Object.keys(
                renderChartData.dateCountryInfringementTypeDict[monthStr]
              ).forEach((countryName) => {
                if (
                  renderChartData.dateCountryInfringementTypeDict[monthStr][
                  countryName
                  ][infringement]
                ) {
                  infringementCount =
                    infringementCount +
                    renderChartData.dateCountryInfringementTypeDict[monthStr][
                    countryName
                    ][infringement]
                }
              })
              temp[infringement].push(infringementCount)
            } else {
              temp[infringement].push(0)
            }
          })
          return monthStr
        }
      )

      series = renderChartData.infringementTypeFilters
        .sort((a, b) => {
          return parseInt(a) > parseInt(b) ? 1 : -1
        })
        .map((infringement) => {
          let infringementInfo = InfringementCommonCategories.find((info) => {
            return info.id === parseInt(infringement)
          })
          let nameIntlKey = intl.formatMessage(
            commonMessage['infirgment_common_cateogry.name_locale_key']
          )
          legend.push(infringementInfo.name)
          return {
            type: 'line',
            showSymbol: false,
            smooth: true,
            name: infringementInfo.name,
            data: temp[infringement],
          }
        })
    }

    const summary = renderChartData.dateInfringementCountDict
    const countryData = renderChartData.dateCountryInfringementTypeDict
    const websiteData = []

    // all type
    let type = renderChartData.infringementTypeFilters
    // all country
    let country = []
    if (renderChartData.countrySiteInfringementTypeDict) {
      country = Object.keys(renderChartData.countrySiteInfringementTypeDict)
    }

    const allData = []
    for (let i = 0; i < legend.length; i++) {
      let dataValue = []
      xAxisData.map((item, index) => {
        let obj = {}

        // summary
        if (summary[item]) {
          obj['summary'] = summary[item][type[i]]
        } else {
          obj['summary'] = 0
        }

        // summary responding to country
        if (countryData[item]) {
          obj['country'] = countryData[item][type[i]]
        } else {
          obj['country'] = 0
        }

        // summary responding to website
        if (websiteData[item]) {
          obj['website'] = websiteData[item][type[i]]
        } else {
          obj['website'] = 0
        }
        dataValue.push(obj)
      })
      allData.push(dataValue)
    }
    chartStackingData.current['xAxis'] = xAxisData
    chartStackingData.current['yAxis'] = renderChartData.countrySiteDateInfringementTypeDict
    chartStackingData.current['zAxis'] = renderChartData.infringementTypeFilters
    let countryFliter = getContountryFilter(Object.keys(renderChartData.countrySiteDateInfringementTypeDict))
    chartStackingData.current['selectName'] = countryFliter
  }

  const getWsTakedownChart = () => {
    if (renderChartData.ws && renderChartData.ws.wsTakedownByFilterRegion) {
      let resultDict = {}
      const dict = renderChartData.ws.wsTakedownByFilterRegion
      Object.keys(dict).forEach((countryName) => {
        // condictions
        if (renderChartData.ws.regionFilters.some((name) => name === countryName)) {
          Object.keys(dict[countryName]).forEach((resultName) => {
            if (resultDict[resultName]) {
              resultDict[resultName] =
                dict[countryName][resultName] + resultDict[resultName]
            } else {
              resultDict[resultName] = dict[countryName][resultName]
            }
          })
        }
      })
      // console.log(resultDict)
      let seriesPieData = Object.keys(resultDict).map((resultName) => {
        const result = WSTakedownResult.find((result) => {
          return result.value === parseInt(resultName)
        })
        if (result) {
          return {
            name: result.name,
            value: resultDict[resultName],
          }
        } else {
          return {
            name: 'Others',
            value: resultDict[resultName],
          }
        }
      })
      chartWebTakedownData.current = seriesPieData
    }
  }

  const getWsCountryFilteringPie = () => {
    if (renderChartData.ws && renderChartData.ws.wsCategoryByFilterRegion) {
      let InfringementCategoryTypeCountTemp = {}
      const regionsCateogries = renderChartData.ws.wsCategoryByFilterRegion
      Object.keys(regionsCateogries).forEach((regionName) => {
        if (renderChartData.ws.regionFilters.some((name) => name === regionName)) {
          Object.keys(regionsCateogries[regionName]).forEach(
            (infringementName) => {
              if (InfringementCategoryTypeCountTemp[infringementName]) {
                InfringementCategoryTypeCountTemp[infringementName] =
                  InfringementCategoryTypeCountTemp[infringementName] +
                  regionsCateogries[regionName][infringementName]
              } else {
                InfringementCategoryTypeCountTemp[infringementName] =
                  regionsCateogries[regionName][infringementName]
              }
            }
          )
        }
      })
      let seriesData = Object.keys(InfringementCategoryTypeCountTemp).map(
        (infringement) => {
          let infringementInfo = WSCategoryType.find((info) => {
            return info.value === parseInt(infringement)
          })
          if (infringementInfo) {
            return {
              name: infringementInfo.name,
              value: InfringementCategoryTypeCountTemp[infringement],
            }
          } else {
            return {
              name: 'Others',
              value: InfringementCategoryTypeCountTemp[infringement],
            }
          }
        }
      )
      chartWebCategoryData.current = seriesData
    } else {
      return []
    }
  }

  // transfer station
  const getAllDate = () => {
    if (searchTrigger === 1) {
      // overall Categorization Status
      getOverallCategorization()
      // Trademark Registration
      getTrakemark()
      // Infringement Impact Revence
      getInfringementImpact()
      // CountryDate
      getCountryData()
      // webSite
      getWebSite()
      // Overall Categorization Status
      getOverallInfringement()
      // Categorization Status
      getCategorization()
      // Web Site TakeDown
      getWsTakedownChart()
      // Web Site Categorization
      getWsCountryFilteringPie()

      chartTakedownSelect.current = []
      let countryTakedown = getContountryFilter(renderChartData.countryTakedownFilters)
      if (countryTakedown.length > 0) {
        renderChartData.countryTakedownFilters.map((item, index) => {
          let obj = countryTakedown.find(item1 => item1.value === item)
          if (obj) {
            chartTakedownSelect.current.push(obj)
          }
        })
      }

      chartRankSelect.current = []
      let countryRank = getContountryFilter(renderChartData.countrySellerFilters)
      if (countryRank.length > 0) {
        renderChartData.countrySellerFilters.map((item, index) => {
          let obj = countryRank.find(item1 => item1.value === item)
          if (obj) {
            chartRankSelect.current.push(obj)
          }
        })
      }
      setSearchTrigger(0)
    }
  }

  getAllDate()

  const getRankSite = () => {
    const searchParams = new URLSearchParams(location.search)
    const startDate = searchParams.get('fromDate')
    const endDate = searchParams.get('toDate')
    const dateType = parseInt(searchParams.get('dateType'))
    const clientId = searchParams.get('clientId')

    if (renderChartData.countrySellerDict && selectRankItem && selectRankItem.length > 0) {
      let sellers = {}
      selectRankItem.forEach((countryName) => {
        if (renderChartData.countrySellerDict[countryName]) {
          Object.keys(renderChartData.countrySellerDict[countryName]).forEach(
            (sellerName) => {
              if (sellers[sellerName]) {
                sellers[sellerName].total =
                  sellers[sellerName].total +
                  renderChartData.countrySellerDict[countryName][sellerName].total
                Object.keys(
                  renderChartData.countrySellerDict[countryName][sellerName].sites
                ).forEach((siteName) => {
                  let siteValue =
                    renderChartData.countrySellerDict[countryName][sellerName].sites[
                    siteName
                    ]
                  sellers[sellerName].sites[siteName] = sellers[sellerName]
                    .sites[siteName]
                    ? sellers[sellerName].sites[siteName] + siteValue
                    : siteValue
                })
              } else {
                sellers[sellerName] = {}
                sellers[sellerName].total =
                  renderChartData.countrySellerDict[countryName][sellerName].total
                sellers[sellerName].sites =
                  renderChartData.countrySellerDict[countryName][sellerName].sites
              }
            }
          )
        }
      })

      let obj = Object.keys(sellers)
        .map((sellerName) => {
          let infringementFilters = {}
          infringementFilters.key = 'ec_category_common_type_id'
          infringementFilters.values = InfringementCommonCategories.filter(
            (category) => category.id !== 1 && category.id !== 10
          ).map((category) => category.id)

          let regionFilters = {}
          regionFilters.key = 'ec_product_region'
          regionFilters.values = renderChartData.countrySellerFilters

          let searchParams = {}
          searchParams.wordFields = ['ec_product_seller']
          searchParams.keyword = sellerName
          searchParams.searchType = 4 // 完全一致
          searchParams.dateFields =
            dateType === 1 ? ['ec_scan_date'] : ['ec_categorize_date']
          searchParams.startDate = moment(startDate).format('YYYY-MM-DD')
          searchParams.endDate = moment(endDate).format('YYYY-MM-DD')
          return {
            name: sellerName,
            value: sellers[sellerName].total,
            total: sellers[sellerName].total,
            type: 'a',
            link: `/ec-list?clientId=${clientId}&${ECListCommon.ParamsKey.search
              }=${encodeURIComponent(
                JSON.stringify(searchParams)
              )}&filters=${encodeURIComponent(
                JSON.stringify([infringementFilters, regionFilters])
              )}`,
            key: sellerName,
            children: Object.keys(sellers[sellerName].sites)
              .map((siteName) => {
                let filters = {}
                filters.key = 'ec_site'
                filters.values = [siteName]
                return {
                  name: siteName,
                  key: siteName,
                  value: sellers[sellerName].sites[siteName],
                  type: 'c',
                  link: `/ec-list?clientId=${clientId}&${ECListCommon.ParamsKey.search
                    }=${encodeURIComponent(
                      JSON.stringify(searchParams)
                    )}&filters=${encodeURIComponent(
                      JSON.stringify([
                        filters,
                        infringementFilters,
                        regionFilters,
                      ])
                    )}`,
                }
              })
              .sort((a, b) => {
                return a.value > b.value ? -1 : 1
              }),
          }
        })
        .sort((a, b) => {
          return a.value > b.value ? -1 : 1
        })

      const result = obj.reduce((acc, obj) => {
        acc[obj.name] = { link: obj.link }
        acc[obj.name]['site'] = {}
        obj.children.map((item, index) => {
          acc[obj.name]['site'][item.name] = {}
          acc[obj.name]['site'][item.name].link = item.link
        })
        return acc
      }, {})
      return { items: renderChartData.countrySellerDict, links: result }
    } else {
      return []
    }

  }

  const getTakeDown = () => {
    if (renderChartData.countryResultDict && selectTakedownItem && selectTakedownItem.length > 0) {
      let resultDict = {}
      Object.keys(renderChartData.countryResultDict).forEach((countryName) => {
        // condictions
        if (
          selectTakedownItem.some((name) => name === countryName)
        ) {
          Object.keys(renderChartData.countryResultDict[countryName]).forEach(
            (resultName) => {
              if (resultDict[resultName]) {
                resultDict[resultName] =
                  renderChartData.countryResultDict[countryName][resultName] +
                  resultDict[resultName]
              } else {
                resultDict[resultName] =
                  renderChartData.countryResultDict[countryName][resultName]
              }
            }
          )
        }
      })
      let nameIntlKey = intl.formatMessage(
        commonMessage['infirgment_common_cateogry.name_locale_key']
      )
      let seriesPieData = Object.keys(resultDict).map((resultName) => {
        let item = TakedownResult.find((result) => {
          return result.value === parseInt(resultName)
        })
        if (item === undefined) {
          return {}
        }
        return {
          name: item.name[nameIntlKey],
          value: resultDict[resultName],
        }
      })
      return seriesPieData
    } else {
      return []
    }
  }

  const onSearchClick = async (values) => {
    // 获取查询参数
    const searchParams = new URLSearchParams(location.search)
    // 获取多个参数

    const params = {}
    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }

    let client = clients.find((item) => item.client_id === Number(params.clientId))
    if (client) {
      analyst.current = client.analystInfo
        .map((analyst) => analyst.user_name)
        .join('、')
    }

    if (reduxProps.role & 1) {
      params.clientId = clientId.current
    }
    await fetchDashboardInfo(
      Number(params.clientId),
      params.fromDate,
      params.toDate,
      Number(params.dateType)
    )
    setSearchTrigger(1)
  }

  // Memorize here, son component only call once when initial rendering when you push secondly,the url not the same as before
  const onHandleChange = (params) => {
    if (reduxProps.role & 1) {
      let client = reduxProps.companyInfo.client_id
      params['clientId'] = client
    }
    const keys = Object.keys(params)

    // 获取当前 URL
    const currentUrl = location.pathname

    // 解析已存在的参数
    const searchParams = new URLSearchParams(location.search)

    // 新参数
    let newParams = {}
    for (let a = 0; a < keys.length; a++) {
      if (keys[a] === 'clientId') {
        if (params[keys[a]] === undefined) {
          searchParams.delete('clientId')
          continue
        }
        newParams[keys[a]] = params[keys[a]]
      } else if (keys[a] === 'dateType') {
        if (params[keys[a]] === 'dateType') {
          searchParams.delete('dateType')
          continue
        }
        newParams[keys[a]] = params[keys[a]]
      } else if (keys[a] === 'date') {
        if (params[keys[a]] === null) {
          searchParams.delete('fromDate')
          searchParams.delete('toDate')
          continue
        }
        newParams['fromDate'] = params[keys[a]][0]
        newParams['toDate'] = params[keys[a]][1]
      }
    }

    const existingParams = Object.fromEntries(searchParams.entries())
    // 合并已存在的参数和新参数
    const combinedParams = { ...existingParams, ...newParams }

    // 构建新的查询字符串
    const newQueryString = new URLSearchParams(combinedParams).toString()

    // 构建新的 URL 并跳转
    const newUrl = `${currentUrl}?${newQueryString}`
    history.push(newUrl)
  }

  const onReset = () => {
    const searchParams = new URLSearchParams(location.search)
    history.push(searchParams)
  }

  const onUrlRender = () => {
    const searchParams = new URLSearchParams(location.search)
    // 获取多个参数
    const params = {}
    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    return params
  }

  const onTabClick = (index) => {
    setPage(index)
  }

  const onUserClick = () => {

  }

  const renderContent = () => {
    if (page === 0) {
      return (
        <div className='dashboard-container-content'>
          <Row gutter={[24, 16]}>
            <Col span={8}>
              <EFCChartRate
                height={312}
                chartTitle={chartRateTitle}
                chartDesc={chartRateDesc}
                chartData={chartRateData.current}
              ></EFCChartRate>
            </Col>
            <Col span={8}>
              <EFCChartBall
                height={312}
                chartTitle={chartBallTitle}
                chartDesc={chartBallDesc}
                chartData={chartBallData.current}
              ></EFCChartBall>
            </Col>
            <Col span={8}>
              <EFCChartAnnular
                height={312}
                chartTitle={chartAnnularTitle}
                chartDesc={chartAnnularDesc}
                chartData={chartAnnularData.current}
              ></EFCChartAnnular>
            </Col>
            <Col span={12}>
              <EFCChartMap
                height={391}
                chartTitle={chartMapTitle}
                chartDesc={chartMapDesc}
                chartData={chartMapData.current}
                onTabClick={(index) => { }}
              ></EFCChartMap>
            </Col>
            <Col span={12}>
              <EFCChartHbar
                height={391}
                chartTitle={chartHbarTitle}
                chartDesc={chartHbarDesc}
                chartData={chartHbarData.current}>
              </EFCChartHbar>
            </Col>
            <Col span={24}>
              <EFCChartOverall
                height={491}
                chartTitle={chartOverallTitle}
                chartDesc={chartOverallDesc}
                chartData={chartOverallData.current}>
              </EFCChartOverall>
            </Col>
            <Col span={24}>
              <EFCChartStacking
                height={491}
                chartTitle={chartStackingTitle}
                chartDesc={chartStackingDesc}
                chartData={chartStackingData.current}
                onTabClick={(index) => { }}
                onSelectClick={(index) => { }}
                selectDesc={selectData.current}>
              </EFCChartStacking>
            </Col>
            <Col span={12}>
              <EFCChartRank
                height={391}
                chartTitle={chartRankTitle}
                chartDesc={chartRankDesc}
                onSelectClick={(index) => { setSelectRankItem(index) }}
                chartData={getRankSite()}
                selectDesc={chartRankSelect.current}
                num={7}>
              </EFCChartRank>
            </Col>
            <Col span={12}>
              <EFCChartTakedown
                height={391}
                chartTitle={chartTakedownTitle}
                chartDesc={chartTakedownDesc}
                onSelectClick={(index) => { setSelectTakedownItem(index) }}
                chartData={getTakeDown()}
                selectDesc={chartTakedownSelect.current}>
              </EFCChartTakedown>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (<div className='dashboard-container-content'>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <EFCChartTakedownBar
              height={391}
              chartTitle={chartWebTakedownTitle}
              chartDesc={chartWebTakedownDesc}
              chartData={chartWebTakedownData.current}
            ></EFCChartTakedownBar>
          </Col>
          <Col span={12}>
            <EFCChartTakedownBar
              height={391}
              chartTitle={chartWebCategoryTitle}
              chartDesc={chartWebCategoryDesc}
              chartData={chartWebCategoryData.current}
            ></EFCChartTakedownBar>
          </Col>
        </Row>
      </div>)
    }
  }

  document.title = intl.formatMessage(messages['dashboard.title'])
  return (
    <div>
      <EFCContainer style={{ padding: '16px 32px 16px 32px' }}>
        <EFCConditionBar
          role={reduxProps.role}
          onInit={onUrlRender}
          onSearch={onSearchClick}
          onReset={onReset}
          onHandleChange={(value, params) => { onHandleChange(value, params) }}
          describe={describe}
          date={date}
          clients={clients}>
        </EFCConditionBar>
      </EFCContainer>
      <EFCContainer
        style={{ marginTop: '20px', padding: '16px 32px 32px 32px' }}
        renderTab={tabs.current}
        user={users.current}
        onTabClick={(index) => {
          onTabClick(index)
        }}
        onUserClick={(index) => {
          onUserClick(index)
        }}
      >
        {renderContent()}
      </EFCContainer>
    </div>
  )
}
export default EFDashboardTest
