// common intl messages
import { defineMessages } from 'react-intl'

const common_messages = defineMessages({
    'infirgment_common_cateogry.name_locale_key': {
        id: "app.config.infirgment_common_cateogry.name_locale_key",
        defaultMessage: "en",
    },
    'locale.product_locale_key': {
        id: "app.config.locale.product_locale_key",
        defaultMessage: "name",
    },
    'locale.infringement_locale_key': {
        id: "app.config.locale.infringement_locale_key",
        defaultMessage: "name",
    },
    'locale.subinfringement_locale_key': {
        id: "app.config.locale.subinfringement_locale_key",
        defaultMessage: "name",
    },
    'locale.name_locale_key': {
        id: "app.config.locale.name_locale_key",
        defaultMessage: "en",
    },

})

export default common_messages