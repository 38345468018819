import * as types from "../action-types";

const initUserInfo = {
    id:"",
    mail:"",
    name: "",
    role: "",
    tel:"",
    remarks:"",
    companyInfo:{},
    analystInfo:[],
    serviceInfo:{}
};

export default function user(state = initUserInfo, action) {
    switch (action.type) {
        case types.USER_SET_USER_INFO:
            return {
                ...state,
                id:action.user_id,
                mail:action.user_mail,
                name:action.user_name,
                role: action.user_role,  
                tel:action.user_tel,
                remarks:action.user_remarks,
                companyInfo:action.companyInfo,
                analystInfo:action.analystInfo,
                serviceInfo:action.serviceInfo,
                // settings:action.settings,
            }
        case types.AUTH_LOGOUT:
            return {...initUserInfo};
        default:
            return state;
    }
}
