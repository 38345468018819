import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import { Image } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { FormattedMessage } from 'react-intl'
const EFCChartHbar = (props) => {
    const title = props.chartTitle
    const desc = props.chartDesc

    const chartData = props.chartData
    const renderContent = () => {
        if (chartData && chartData.length > 0) {
            let start,end
            if (chartData.length >= 7) {
                start = chartData[0].name
                end = chartData[6].name
            } else {
                start = chartData[0].name
                end = chartData[chartData.length - 1].name
            }
            const option = {
                // grid自适应设置 + 外部网格线
                grid: {
                    top: '0%',
                    left: '0%',
                    right: '8%',
                    bottom: '2%',
                    containLabel: true,
                    borderColor: '#EBEDF2',
                    show: false, // 是否显示网格线，默认值为 false
                },              
                tooltip: {
                    trigger: 'item',
                    textStyle:{
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    extraCssText: 'text-align: center;',
                    formatter: function (params) {
                        const data = params;
                        return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
                                <div style="font-weight: normal;font-size: 16px;color:#000000;font-family:'Bebas';letter-spacing: 1px;">${data['value']}</div>`;
                      },
                },
                // x轴设置
                xAxis: [
                    {
                        type: "value",
                        show: true,
                        // 内部格线
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#EBEDF2',
                                type: 'dotted',
                                width: 2
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#EBEDF2'
                            }
                        },
                        axisLabel: {
                            rotate: 0,
                            margin: 20,
                            color: '#595C72'
                        },
                        // boundaryGap: ['10%',0 ], // 设置留白为 5px
                        // scale :true
                        // minInterval: 1
                    },
                ],
                // y轴设置
                yAxis: [
                    {
                        type: "category",
                        // 排序方式
                        inverse: true,  
                        data: chartData.map((item, index) => item = item.name),
                        // 刻度线
                        axisTick: {
                            show: false,
                            alignWithLabel: false,
                            inside: true,
                            length: 5,
                            lineStyle: {
                                color: '#EBEDF2',
                                width: 1,
                                type: 'solid'
                            }
                        },
                        offset: 0,
                        borderType: 'dotted',
                        textBorderDashOffset: '12',
                        nameRotate: 300,
                        // y轴标签
                        axisLabel: {
                            rotate: 0,
                            margin: 20,
                            color: '#595C72'
                        },
                        // y轴轴线
                        axisLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#EBEDF2'
                            }
                        }
                    },{
                        type: "category",
                        // 排序方式
                        inverse: true,  
                        position: 'left',
                        data: chartData.map((item, index) => item = item.name),
                        axisTick: {
                            show: true,
                            alignWithLabel: false,
                            inside: true,
                            length: 5,
                            lineStyle: {
                                color: '#EBEDF2',
                                width: 1,
                                type: 'solid'
                            }
                        },
                        offset: 10,
                        borderType: 'dotted',
                        textBorderDashOffset: '12',
                        nameRotate: 300,
                        // y轴标签
                        axisLabel: {
                            show: false,
                            rotate: 0,
                            margin: 20,
                            color: '#595C72'
                        },
                        // y轴轴线
                        axisLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid',
                                color: '#EBEDF2'
                            }
                        }
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        minValueSpan: 6,
                        start: 0,
                        end: 6, // 最多显示7条数据，索引从0开始
                        left: '95%',
                        orient: 'vertical',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                series: [
                    {
                        name: "number",
                        type: "bar",
                        data: chartData.map((item, index) => item = item.value),
                        // 条形上面文字
                        label: {
                            show: true,
                            position: "right",
                            color: "#333",
                        },
                        barCategoryGap: '20%',
                        borderType: 'dashed',
                        barWidth: 15,
                        // 设置柱子的颜色
                        itemStyle: {
                            color: "#1880DE"
                        },
                        // 鼠标置上条形
                        emphasis: {
                            itemStyle: {
                                borderColor: '#333', // 柱形顶部横线颜色
                                borderWidth: 1, // 柱形顶部横线宽度
                                borderType: 'solid', // 柱形顶部横线类型
                            },
                        },
                    },
                ],
            }
            return <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }}></ReactEcharts>
        } else {
            return <div></div>
        }
    }

    return (
        <div className='ef-chart-horizontal-bar'
            style={{
                height: props.height ? props.height + 'px' : '391px',
            }}>
            <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFCChartHbar