import "./index.less";
import { FormattedMessage } from 'react-intl';
import React, { Component } from "react";

class HintContainer extends Component {
    render() {
        let borderClass = "";
        if (this.props.borderType === "yellow") {
            borderClass = "home-yellow";
        } else if (this.props.borderType === "blue") {
            borderClass = "home-blue";
        } else if (this.props.borderType === "green") {
            borderClass = "home-green";
        } else if (this.props.borderType === "pink") {
            borderClass = "home-pink";
        }
        let className = borderClass ? `${borderClass} hint-container` : "hint-container"

        return (
            <div className={className}>
                <div className="right-container">
                    <p>{this.props.title}</p>
                    <p>{this.props.subtitle}</p>
                    <p><span className="hint-contain">{this.props.number}</span> <span><FormattedMessage id="app.ui.home.section.unit" /></span></p>
                </div>
            </div>
        )
    }
}

export default HintContainer