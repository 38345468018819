import "./index.less"
import React, { Component } from "react";
import { Drawer, Table } from "antd";
import { reqGetECRecordHistory } from "@/api/ec.js"
import moment from 'moment';
import { Spin, Space, Button, Image } from "antd";
import { TakedownRequest, TakedownResult } from '../../../config/ec-report-select-items'
import { InfringementCommonCategories } from '../../../config/infringement-common-category'
import { FormattedMessage, injectIntl } from "react-intl"
import common_messages from "../../../locales/messages";

class ECRecordHistory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: []
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        return props
    }

    getBasicColumns = (config) => {

        let { currentSearchClientId, allProductsInfo,
            categorysInfo, allTrademarksInfo, currentSelectClientId, } = config;
        let columns = [
            {
                title: <FormattedMessage id="app.ui.eclist_history.table.edited_by" />,
                defaultShow: true,
                dataIndex: 'updateByUserName',
                key: 'updateByUserName',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
            },
            {
                title: <FormattedMessage id="app.ui.eclist_history.table.edited_date" />,
                defaultShow: true,
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_common" />,
                defaultShow: true,
                dataIndex: 'ec_category_common_type_id',
                key: 'ec_category_common_type_id',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,

                render: (value, record) => {
                    let commonCategory = InfringementCommonCategories.find((commonCategory) => {
                        return commonCategory.id === value;
                    })
                    return commonCategory ? commonCategory.name.ja : ""
                }
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
                dataIndex: 'ec_categorize_remarks',
                key: 'ec_categorize_remarks',
                width: 150,
                ellipsis: true,
                sorter: true,
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_response_action" />,
                defaultShow: true,
                dataIndex: 'ec_response_action',
                key: 'ec_response_action',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    let flg = TakedownRequest.find((result) => {
                        return result.value === value
                    })
                    let content = flg ? flg.name[localeKey] : "";
                    return content
                }
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_response_reason" />,
                dataIndex: 'ec_response_reason',
                key: 'ec_response_reason',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_response_trademark" />,
                dataIndex: 'ec_response_trademark',
                key: 'ec_response_trademark',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let trademarksInfo = allTrademarksInfo["trademarksInfo_" + currentSelectClientId];
                    if (trademarksInfo) {
                        let trademark = trademarksInfo.find((trademark) => {
                            return trademark.trademark_id === value
                        })
                        return trademark ? trademark.trademark_no : value;
                    }
                    return value;
                }
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_response_date" />,
                dataIndex: 'ec_response_date',
                key: 'ec_response_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process1_date" />,
                dataIndex: 'ec_process1_date',
                key: 'ec_process1_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process1_result" />,
                dataIndex: 'ec_process1_result',
                key: 'ec_process1_result',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    let flg = TakedownResult.find((result) => {
                        return result.value === value
                    })
                    let content = flg ? flg.name[localeKey] : "";
                    return content
                }
            },

            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process1_confirm_date" />,
                dataIndex: 'ec_process1_confirm_date',
                key: 'ec_process1_confirm_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process2_date" />,
                dataIndex: 'ec_process2_date',
                key: 'ec_process2_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process2_result" />,
                dataIndex: 'ec_process2_result',
                key: 'ec_process2_result',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    let flg = TakedownResult.find((result) => {
                        return result.value === value
                    })
                    let content = flg ? flg.name[localeKey] : "";
                    return content
                }
            },

            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process2_confirm_date" />,
                dataIndex: 'ec_process2_confirm_date',
                key: 'ec_process2_confirm_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },

            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process3_date" />,
                dataIndex: 'ec_process3_date',
                key: 'ec_process3_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process3_result" />,
                dataIndex: 'ec_process3_result',
                key: 'ec_process3_result',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    let flg = TakedownResult.find((result) => {
                        return result.value === value
                    })
                    let content = flg ? flg.name[localeKey] : "";
                    return content
                }
            },

            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process3_confirm_date" />,
                dataIndex: 'ec_process3_confirm_date',
                key: 'ec_process3_confirm_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.declaration_status" />,
                dataIndex: 'declarationStatus',
                key: 'declarationStatus',
                width: 150,
                ellipsis: true,
                sorter: false,
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process_result" />,
                defaultShow: true,
                dataIndex: 'ec_process_result',
                key: 'ec_process_result',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    let flg = TakedownResult.find((result) => {
                        return result.value === value
                    })
                    let content = flg ? flg.name[localeKey] : "";
                    return content
                }
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process_confirm_date" />,
                defaultShow: true,
                dataIndex: 'ec_process_confirm_date',
                key: 'ec_process_confirm_date',
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                render: (value, record) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.eclist.table.ec_process_remarks" />,
                dataIndex: 'ec_process_remarks',
                key: 'ec_process_remarks',
                width: 150,
                ellipsis: true,
                sorter: true,
            },
        ]
        return columns
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }
    render() {
        return (
            <Drawer
                width={640}
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.props.visible}
            >
                <Table
                    columns={this.getBasicColumns(this.props.config)}
                    rowKey={record => record.id}
                    dataSource={this.props.history}
                    loading={this.props.historyLoading}
                    pagination={false}
                >
                </Table>
            </Drawer>
        )
    }
}

export default injectIntl(ECRecordHistory);
