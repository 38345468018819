import { getCurrencyName } from "@/utils/currency.js"
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
export const getCompanyInformation = () => {
    return [
        {
            title: <FormattedMessage id="app.ui.profile.analyst.client_id" />,
            dataIndex: 'client_id',
            key: 'client_id',
            width: 80,
            fixed: true,
            sorter: (a, b) => compareNumber(a.client_id, b.client_id),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.client_name" />,
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => compareString(a.client_name, b.client_name),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.client_tel" />,
            dataIndex: 'client_tel',
            key: 'client_tel',
            sorter: (a, b) => compareString(a.client_tel, b.client_tel),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.client_address" />,
            dataIndex: 'client_address',
            key: 'client_address',
            sorter: (a, b) => compareString(a.client_address, b.client_address),
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.client_remarks" />,
            dataIndex: 'client_remarks',
            key: 'client_remarks',
            sorter: (a, b) => compareString(a.client_remarks, b.client_remarks),
            showSorterTooltip: false,
            render: (text) => <div className="custom-table-cell">{text}</div>,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ec_startDate" />,
            dataIndex: 'ec_startDate',
            key: 'ec_startDate',
            render: (text, record) => {
                if (record.service_ec_start_date) {
                    return moment(record.service_ec_start_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_ec_start_date, b.service_ec_start_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ec_endDate" />,
            dataIndex: 'ec_endDate',
            key: 'ec_endDate',
            render: (text, record) => {
                if (record.service_ec_end_date) {
                    return moment(record.service_ec_end_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_ec_end_date, b.service_ec_end_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ec_fee" />,
            dataIndex: 'ec_fee',
            key: 'ec_fee',
            render: (text, record) => {
                if (record.service_ec_fee) {
                    return record.service_ec_fee + getCurrencyName(record.service_ec_currency)
                }
                return ""
            },
            sorter: (a, b) => {
                return compareNumber(a.service_ec_fee, b.service_ec_fee)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.sns_startDate" />,
            dataIndex: 'sns_startDate',
            key: 'sns_startDate',
            render: (text, record) => {
                if (record.service_sns_start_date) {
                    return moment(record.service_sns_start_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_sns_start_date, b.service_sns_start_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.sns_endDate" />,
            dataIndex: 'sns_endDate',
            key: 'sns_endDate',
            render: (text, record) => {
                if (record.service_sns_end_date) {
                    return moment(record.service_sns_end_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_sns_end_date, b.service_sns_end_date)

            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.sns_fee" />,
            dataIndex: 'sns_fee',
            key: 'sns_fee',
            render: (text, record) => {
                if (record.service_sns_fee) {
                    return record.service_sns_fee + getCurrencyName(record.service_sns_currency)
                }
                return ""

            },
            sorter: (a, b) => {
                return compareNumber(a.service_sns_fee, b.service_sns_fee)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ws_startDate" />,
            dataIndex: 'ws_startDate',
            key: 'ws_startDate',
            render: (text, record) => {
                if (record.service_ws_start_date) {
                    return moment(record.service_ws_start_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_ws_start_date, b.service_ws_start_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ws_endDate" />,
            dataIndex: 'ws_endDate',
            key: 'ws_endDate',
            render: (text, record) => {
                if (record.service_ws_end_date) {
                    return moment(record.service_ws_end_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_ws_end_date, b.service_ws_end_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.ws_fee" />,
            dataIndex: 'ws_fee',
            key: 'ws_fee',
            render: (text, record) => {
                if (record.service_ws_fee) {
                    return record.service_ws_fee + getCurrencyName(record.service_ws_currency)
                }
                return ""
            },
            sorter: (a, b) => {
                return compareNumber(a.service_ws_fee, b.service_ws_fee)

            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.other_startDate" />,
            dataIndex: 'other_startDate',
            key: 'other_startDate',
            render: (text, record) => {
                if (record.service_other_start_date) {
                    return moment(record.service_other_start_date).format("YYYY-MM-DD")
                }
                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_other_start_date, b.service_other_start_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.other_endDate" />,
            dataIndex: 'other_endDate',
            key: 'other_endDate',
            render: (text, record) => {
                if (record.service_other_end_date) {
                    return moment(record.service_other_end_date).format("YYYY-MM-DD")
                }

                return ""
            },
            sorter: (a, b) => {
                return compareDate(a.service_other_end_date, b.service_other_end_date)
            },
            showSorterTooltip: false,
        },
        {
            title: <FormattedMessage id="app.ui.profile.analyst.other_fee" />,
            dataIndex: 'other_fee',
            key: 'other_fee',
            render: (text, record) => {
                if (record.service_other_fee) {
                    return record.service_other_fee + getCurrencyName(record.service_other_currency)
                }
                return ""
            },
            sorter: (a, b) => {
                return compareNumber(a.service_other_fee, b.service_other_fee)
            },
            showSorterTooltip: false,
        },
    ]
}
