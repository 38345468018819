import { Button, Form, message, Table, Input, Space } from "antd"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { PlusOutlined } from '@ant-design/icons'
import { ModalForm } from '@ant-design/pro-form'
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table"
import { ProFormText, ProFormTextArea } from "@ant-design/pro-form"
import { reqUpdateECSite, reqGetECSite } from '@/api/analyst-site'
import { reqCreateSiteAccount, reqRemoveSiteAccount, reqFetchSiteAccounts, reqUpdateSiteAccount } from '@/api/site-account'
import { CountryConfig } from "@/config/country.js"
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'
import { reqCreateECSite } from "../../../../api/analyst-site"

import commonMessage from '../../../../locales/messages'
import messages from './messages'
import { FormattedMessage, useIntl } from 'react-intl'

// component reference dependency
import EFCContainer from '../../../../components/ef-c-container'
import EFBox from '../../../../components/ef-c-box'

const EFCreateECSiteView = (props) => {

    // 国际化配置
    const intl = useIntl()
    const nameIntlKey = useRef()
    nameIntlKey.current = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )

    const fontFamily = useRef()
    fontFamily.current = nameIntlKey.current === 'en' ? 'ef-fontFamily-en' : 'ef-fontFamily-ja'

    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const [currentState, setCurrentState] = useState()
    const updateTrigger = useRef(false)
    const boxData = useRef([])

    const formRef = useRef()
    const modalFormRef = useRef()
    const formField = useRef([])

    const site = useRef({})
    const addAccountIndex = useRef(0)
    const accounts = useRef([])
    const items = useRef({})
    const categories = useRef([])

    const updateState = (data) => {
        boxData.current = [
            {
                name: 'siteName',
                value: '',
                label: 'app.ui.ec_site_detail.form.name',
                type: 'input',
                permission: true
            }, {
                name: 'siteURL',
                value: '',
                label: 'app.ui.ec_site_detail.form.url',
                type: 'input',
                permission: true
            }, {
                name: 'region',
                value: null,
                label: 'app.ui.ec_site_detail.form.region',
                type: 'select',
                permission: true,
                params: CountryConfig.map((item, index) => {
                    return { value: item.code, label: item.name[nameIntlKey.current] }
                })
            }, {
                name: 'categoryId',
                value: null,
                label: 'app.ui.ec_site_detail.form.category',
                type: 'select',
                permission: true,
                params: categories.current.map((item, index) => {
                    return { value: item.id, label: item.name }
                })
            }, {
                name: 'hasCrawler',
                value: null,
                label: 'app.ui.ec_site_detail.form.scrape_possible',
                type: 'select',
                permission: true,
                params: [
                    {
                        value: 1,
                        label: <FormattedMessage id="app.common.option.yes" />,
                    }, {
                        value: 0,
                        label: <FormattedMessage id="app.common.option.no" />,
                    }
                ]
            }, {
                name: 'remarks',
                value: '',
                label: 'app.ui.ec_site_detail.form.remarks',
                type: 'textarea',
                permission: true
            }
        ]
        toggleLoading()
    }

    const onHandleChange = (values) => {
        const params = { ...values, accounts: accounts.current }
        reqCreateECSite(params).then((response) => {
            message.success(intl.formatMessage(
                messages['message.info.update_account_success']
            ))
            props.history.push("/analyst/ec-site")
        }).catch((e) => {
            message.error(intl.formatMessage(
                messages['message.error.update_account_failed']
            ))
        })
    }

    const onHandleState = (value) => {
        setCurrentState(value)
        if (value === false) {
            renderData()
        }
    }

    const onHandleUpdate = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const onHandleRemove = (record) => {
        const index = accounts.current.findIndex(obj => {
            return obj.id === record.id
        })
        if (index !== -1) {
            let array = accounts.current
            array.splice(index, 1)
            accounts.current = []
            items.current[record.id] = false
        }
        toggleLoading()
    }

    const onSave = (record, values) => {
        const index = accounts.current.findIndex(obj => {
            return obj.id === record.id
        })
        if (index !== -1) {
            const newAccounts = [...accounts.current]
            const newAccount = { ...record }
            newAccount["account"] = values["account" + record.id]
            newAccount["password"] = values["password" + record.id]
            newAccount["phone"] = values["phone" + record.id]
            newAccount["headers"] = values["headers" + record.id]
            newAccount["cookies"] = values["cookies" + record.id]
            newAccount["remarks"] = values["remarks" + record.id]
            newAccounts[index] = newAccount
            accounts.current = newAccounts
        }
        items.current[record.id] = false
        toggleLoading()
    }

    const onHandleCreateAccount = (values) => {
        addAccountIndex.current = addAccountIndex.current + 1
        const id = addAccountIndex.current
        const newAccount = { id: id, ...values }
        accounts.current = [...accounts.current, newAccount]
        toggleLoading()
    }

    const onHandleSave = (record) => {
        formRef.current.validateFields().then((values) => {
            onSave(record, values)
        }).catch(errorInfo => { message.error(errorInfo.errorFields[0].errors[0]) })
    }

    const onHandleCancel = (record) => {
        items.current[record.id] = false
        toggleLoading()
    }

    const onHandleEdit = (record) => {
        let obj = {}
        formField.current.map((item, index) => {
            if (record[item] !== undefined) {
                obj[item + record.id] = record[item]
            }
        })
        if (formRef.current) {
            formRef.current.setFieldsValue(obj)
        }
        items.current[record.id] = true
        toggleLoading()
    }

    const renderTrigger = () => {
        return <div>
            <Button className='ef-button-color-small' onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.common.action.add' /></Button>
        </div>
    }

    const renderBread = () => {
        document.title = intl.formatMessage({ id: "app.ec_site_detail.title" })
        store.dispatch(setMenuActiveKey("analyst-ec-site"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.register" />,
                path: "/analyst/ec-site",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.detail" />,
            },
        ]))
    }

    const renderData = async () => {
        await reqFetchInfringementCategories().then((response) => {
            const { data } = response
            if (data.code === 0) {
                categories.current = data.data.categories
            }
        }).catch((error) => {
        })
        updateState()
    }

    const createdModalItems = () => {
        return (
            <>
                <ProFormText className="item" width="100%" name="account" label={<FormattedMessage id="app.ui.ec_site_detail.table.account" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(
                            messages['message.error.account_empty']
                        ),
                    },
                ]}
                />
                <ProFormText className="item" width="100%" name="password" label={<FormattedMessage id="app.ui.ec_site_detail.table.password" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(
                            messages['message.error.password_empty']
                        ),
                    },
                ]}
                />
                <ProFormText className="item" width="100%" name="phone" label={<FormattedMessage id="app.ui.ec_site_detail.table.phone" />} rules={[
                    {
                        required: false,
                        message: intl.formatMessage(
                            messages['message.error.tel_empty']
                        ),
                    },
                ]}
                />
                <ProFormTextArea className="item" width="100%" name="headers" label={<FormattedMessage id="app.ui.ec_site_detail.table.headers" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(
                            messages['message.error.headers_empty']
                        ),
                    },
                ]}
                />
                <ProFormTextArea className="item" width="100%" name="cookies" label={<FormattedMessage id="app.ui.ec_site_detail.table.cookies" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(
                            messages['message.error.cookies_empty']
                        ),
                    },
                ]}
                />
                <ProFormTextArea className="item" width="100%" name="remarks" label={<FormattedMessage id="app.ui.ec_site_detail.table.remarks" />} rules={[
                    {
                        required: false,
                        message: intl.formatMessage(
                            messages['message.error.remarks_empty']
                        ),
                    },
                ]}
                />
            </>
        )
    }

    const createAccountColumns = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.account" />,
                dataIndex: 'account',
                key: 'account',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"account" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.account_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.password" />,
                dataIndex: 'password',
                key: 'password',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"password" + record.id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(
                                            messages['message.error.password_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.phone" />,
                dataIndex: 'phone',
                key: 'phone',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"phone" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.tel_empty']
                                        ),
                                    }
                                ]}>
                                <Input></Input>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.headers" />,
                dataIndex: 'headers',
                key: 'headers',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"headers" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.headers_empty']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.cookies" />,
                dataIndex: 'cookies',
                key: 'cookies',
                width: 250,
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"cookies" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.cookies_empty']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.ec_site_detail.table.remarks" />,
                dataIndex: 'remarks',
                key: 'remarks',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"remarks" + record.id}
                                rules={[
                                    {
                                        required: false,
                                        message: intl.formatMessage(
                                            messages['message.error.remarks_tip']
                                        ),
                                    }
                                ]}>
                                <Input.TextArea ></Input.TextArea>
                            </ Form.Item >
                        )
                    } else {
                        return <p>{text}</p>
                    }
                }
            }, {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (items.current[record.id]) {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleSave(record)} >{<FormattedMessage id="app.common.action.save" />}</Button>
                                <Button type="link" onClick={() => onHandleRemove(record)}>{<FormattedMessage id="app.common.action.delete" />}</Button>
                                <Button type="link" onClick={() => onHandleCancel(record)}>{<FormattedMessage id="app.common.action.cancel" />}</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleEdit(record)} >{<FormattedMessage id="app.common.action.edit" />}</Button>
                            </Space>
                        )
                    }
                },
            }
        ]
        columns.push({
            title: (
                <ModalForm
                    className="ef-modalform-twin-col ec-site-create-modal"
                    formRef={modalFormRef}
                    title={<div className="ef-text-bold"><FormattedMessage id="app.ui.ec_site_detail.modal.url_add" /></div>}
                    trigger={
                        <Button icon={<PlusOutlined />}></Button>
                    }
                    modalProps={{
                        onCancel: () => {
                            modalFormRef.current?.resetFields()
                        },
                    }}
                    onFinish={(values) => {
                        onHandleCreateAccount(values)
                        return true
                    }}
                    submitter={{
                        searchConfig: {
                            resetText: <FormattedMessage id="app.components.modal.reset" />,
                            submitText: <FormattedMessage id="app.components.modal.submit" />,
                        },
                    }}
                >
                    {createdModalItems()}
                </ModalForm >
            ),
            key: 'new',
            width: '100px',
        })
        formField.current = columns.map((item, index) => {
            return item.key
        })
        return columns
    }

    const renderTable = () => {
        return <Form
            ref={formRef}
            component={false}>
            <Table
                className="ef-table ef-ec-site-table"
                columns={createAccountColumns()}
                dataSource={accounts.current}
                scroll={{
                    x: "max-content"
                }}
                pagination={false}
            >
            </Table>
        </Form>
    }

    useEffect(() => {
        renderBread()
        renderData()
        onHandleState(true)
    }, [])

    return <EFCContainer
        overspread={0}
        style={{ padding: '32px 32px 16px 32px' }}
        className={fontFamily.current}>
        <EFBox
            title={<FormattedMessage id="app.ui.ec_site_detail.box_title" />}
            titleBarColor={'#1880DE'}
            data={boxData.current}
            itemWidth={300}
            itemClass={'ef-form-element-block'}
            state={currentState}
            updateTrigger={updateTrigger.current}
            onHandleChange={(value) => { onHandleChange(value) }}
        ></EFBox>
        {renderTrigger()}
        {renderTable()}
    </EFCContainer>
}

export default EFCreateECSiteView