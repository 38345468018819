import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqGetUser, reqUpdateUser, reqRemoveUser } from '../../../api/admin-user';
import { reqSearchClients } from '../../../api/admin-client'
import EFAdminResetPassword from "../reset-password"
import moment from 'moment';
import { isValidTelNo } from "../../../utils/ef-re";


const { Option } = Select;

class UserDetail extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.analystId = parseInt(this.props.location.pathname.split("/")[3]);
        this.state = {
            companies: [],
            analyst: {},
            selectCompany: {},
            isloading: false
        }
        reqGetUser({ userId: this.analystId, roles: [1] }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                if (data.data.user) {
                    this.setFormFields(data.data.user)
                    this.setState({ analyst: data.data.user, selectCompany: data.data.user.companyInfo })
                }
            } else {

            }
        }).catch((error) => {

        })

        reqSearchClients().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ companies: data.data.client });
            }
        }).catch((error) => {
            
        })
    }

    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("manage-user-list"));
        store.dispatch(setContentContainerHeaderAction([
            {
                name: "削除",
                onClick: () => {
                    reqRemoveUser({
                        userId: this.analystId
                    }).then((response) => {
                        this.props.history.push("/manage/user")
                    }).catch((e) => [

                    ])
                }
            },
            {
                item:<EFAdminResetPassword userId={this.analystId}/>
            }
        ]))
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "ユーザー登録一覧",
                path: "/manage/user",
            },
            {
                name: "詳細",
            }
        ]));
    }

    setFormFields = (userInfo) => {
        this.formRef.current.setFieldsValue({
            name: userInfo.user_name,
            tel: userInfo.user_tel,
            department: userInfo.user_department,
            remarks: userInfo.user_remarks,
        });
        if (userInfo.companyInfo) {
            this.formRef.current.setFieldsValue({
                company: userInfo.companyInfo.client_id
            });
        }
    }
    // form
    onFinish = (values) => {
        const { name, tel, remarks, department, company } = values;
        
        if (tel && !isValidTelNo(tel)) {
            message.error("正しい電話番号を入力してください。")
            return
        }
        let body = {
            userId: this.analystId,
            name,
            tel,
            department,
            remarks,
        }
        if (company) {
            body.companyId = parseInt(company)
        }
        this.setState({ isloading: true })
        reqUpdateUser(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                message.success("ユーザー情報を更新しました。")
            }
            this.setState({ isloading: false })
        }).catch((error) => {
            message.error("ユーザー情報の更新は失敗しました。")
            this.setState({ isloading: false })
        })
    };

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }
    
    render() {
        document.title = "ユーザー詳細"
        return (
            <div className="user-detail-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title="基本情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>ID</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_id}</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>名前（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: "名前を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>メール（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_mail}</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>電話番号</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                message: "正しい電話番号を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>部署</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="department" rules={[
                                            {
                                                type: "string",
                                                message: "正しい部署を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>備考</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="remarks" rules={[
                                            {
                                                type: "string",
                                                max: 500,
                                                message: "500字以内の備考を入力ください。",
                                            },
                                        ]}>
                                            <Input.TextArea className="remarks-text" />
                                        </Form.Item>
                                    </div>
                                    <br />

                                </div>
                            </EFSimpleContainer>
                        </Col>
                        <Col span={12}>
                            <EFSimpleContainer title="会社情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>会社名</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="company" >
                                            <Select className="company-select" onChange={(value) => {
                                                
                                                for (const company of this.state.companies) {
                                                    if (company.client_id === value) {
                                                        this.setState({ selectCompany: company })
                                                    }
                                                }
                                            }}>
                                                {
                                                    this.state.companies.map((company) => {
                                                        return <Option key={company.client_id.toString()} value={company.client_id}>{company.client_name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <br />

                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>連絡先</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.selectCompany ? this.state.selectCompany.client_tel : ""}</p>
                                    </div>
                                    <br />

                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>住所</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.selectCompany ? this.state.selectCompany.client_address : ""}</p>
                                    </div>
                                    <br />

                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>備考</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.selectCompany ? this.state.selectCompany.client_remarks : ""}</p>
                                    </div>
                                    <br />

                                </div>
                            </EFSimpleContainer>

                            <EFSimpleContainer title="登録情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>最終ログイン日</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_last_used_date ? moment(this.state.analyst.user_last_used_date).format("YYYY-MM-DD") : ""}</p>
                                    </div>
                                    <br />

                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>新規日付</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_create_at ? moment(this.state.analyst.user_create_at).format("YYYY-MM-DD") : ""}</p>
                                        <br />
                                    </div>
                                </div>
                            </EFSimpleContainer>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <div className="acition-container">
                                <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>更新</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(UserDetail)
