import React, { Component } from "react"
import { Badge, Layout, Menu, Image } from "antd"
import { Link, withRouter } from "react-router-dom"
import menuList from "@/config/menu-config.js"
import { connect } from "react-redux"
import "./index.less"
import { reqUnviewedNoticeCount } from "../../../api/notice-center"
import commonMessage from '../../../locales/messages'
import { setMenuCollapsed } from "@/store/actions/menu.js"
import store from "@/store"

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
import { FormattedMessage } from "react-intl"
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Sider } = Layout

const keySpritor = "***"

class EFSider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Exportcollapsed: null,
            collapsed: !this.props.collapsed,
        }
    }

    state = {
        menuTreeNode: null,
        openKey: [],
        path: "",
        unviewed: 0,
        collapsed: false,
        logoClass: "logo-img",
        logoImage: "/ipf-logo.png"
    };

    setCollapsed (collapsed, type) {
        const logoClass = collapsed ? "logo-img-small" : "logo-img"
        const logoImage = collapsed ? "/ipf-logo-circle.png" : "/ipf-logo.png"

        store.dispatch(setMenuCollapsed(collapsed))

        this.setState({
            collapsed: collapsed,
            logoClass,
            logoImage
        })
    }

    doGetUnviewedNoticeCount () {
        reqUnviewedNoticeCount().then((resp) => {
            if (resp.data.code === 0) {
                // console.log(resp)
                this.setState({
                    unviewed: resp.data.data.unviewed
                })
            }
        })
    }

    componentDidMount () {
        this.doGetUnviewedNoticeCount()
        this.timer = setInterval(this.doGetUnviewedNoticeCount.bind(this), 1000 * 30)
    }

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    handleClick = e => {
        let path = e.key.split(keySpritor)[1]
        this.setState({ path })
    };

    handleSelect = e => {
    };
    handleOpenChange = e => {

    };

    onSubmenuClick = (values) => {
        let submenu = menuList.find(submenu => {
            return submenu.id === values.key
        })
        if (submenu && submenu.path) {
            this.props.history.push(submenu.path)
        }
    }

    filterMenuItem = (item, role) => {
        const { roles } = item
        if (roles & role) {
            return true
        } else if (item.children) {
            return !!item.children.find((child) => child.roles & role)
        }
        return false
    }

    getMenuNodes = (menuList, role) => {

        let submenu = menuList.find(submenu => {
            return submenu.path === this.props.location.pathname && (role & submenu.roles)
        })
        return menuList.reduce((pre, item) => {
            if (this.filterMenuItem(item, role)) {
                if (!item.children) {
                    if (item.id === "notice") {
                        pre.push(
                            {
                                // "label": item.title,
                                "label": <> <Link to={item.path}>
                                    <span>
                                        {item.title}
                                    </span>
                                </Link>
                                    <Badge count={this.state.unviewed} className="ef-notice-badge">
                                    </Badge>
                                </>,
                                "icon": item.icon,
                                "key": item.id
                            }
                        )
                    } else {
                        pre.push(
                            {
                                "label": <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>,
                                // "label": item.title,
                                "icon": item.icon,
                                "key": item.id
                            }
                            // < Menu.Item key={item.id}
                            //     icon={item.icon}
                            // >
                            //     <Link to={item.path}>
                            //         <span>{item.title}</span>
                            //     </Link>
                            // </Menu.Item >
                        )
                    }
                } else {
                    let menuClass = ""
                    let title = item.title
                    pre.push(
                        {
                            "label": title,
                            "icon": item.icon,
                            "key": item.id,
                            onTitleClick: this.onSubmenuClick,
                            popupClassName: menuClass,
                            children: this.getMenuNodes(item.children, role)
                        }
                    )
                }
            } else {

            }
            return pre
        }, [])
    }

    getOpenSubMenuAndSelectItem (pathname) {
        if (pathname) {
            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.re && pathname.match(subMenu.re)) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.path && pathname === child.path) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }


            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.re && pathname.match(subMenu.re)) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.re && pathname.match(child.re)) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { openMenu: "", selectItem: "" }
    }

    getActiveMenuAndSelectItem () {
        if (this.props.activeKey) {
            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.id === this.props.activeKey) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.id === this.props.activeKey) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { openMenu: "", selectItem: "" }
    }

    renderExportCollapse = () => {
        if (!this.state.collapsed) {
            if (this.props.collapsed === true) {
                this.setCollapsed(true)
                this.setState({ Exportcollapsed: true })
            }
        } else {
            this.setCollapsed(false)
            this.setState({ Exportcollapsed: false })
        }
    }

    getSiderFooter () {
        if (!this.state.collapsed) {
            return <div
                className="ef-footer-shrink"
            >
                <div
                    className="ef-footer-flex"
                    onClick={(collapsed, type) => { this.setCollapsed(!this.state.collapsed) }}>
                    {/* <Image preview={false} width={20} height={20} src="/images/shrink.png"></Image><p style={{ color: '#888B9A', display: 'inline-block', fontSize: '14px', marginLeft: '8px', height: '20px', lineHeight: '26px' }}>Folding</p> */}
                    <IconFont className="menu-collapse" type="icon-zhankai"/><p className="collapse-desc"><FormattedMessage id="app.layout.menu.fold"/></p>
                </div>
            </div>
        } else {
            return <div
                className="ef-footer-grow"
            >
                <div
                    className="ef-footer-flex"
                    onClick={(collapsed, type) => { this.setCollapsed(!this.state.collapsed) }}>
                    {/* <Image preview={false} width={20} height={20} src="/images/grow.png"></Image> */}
                    <IconFont className="menu-collapse" type="icon-shouqi"/>
                </div>
            </div>
        }
    }

    render () {
        if (this.props.collapsed !== this.state.Exportcollapsed) {
            this.renderExportCollapse()
        }
        let { openMenu, selectItem } = this.getOpenSubMenuAndSelectItem(this.props.location.pathname)
        return (
            <Sider
                className='ef-side-container'
                width="288"
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    marginTop: '64px',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                       // open一下
                    // background: 'linear-gradient(to bottom, #DEF0FF, #f5f7fa)',
                }}
                trigger={null}
                collapsed={this.state.collapsed}
                collapsible={true}
                onCollapse={(collapsed, type) => this.setCollapsed(collapsed, type)}
            >
                <div className="ef-menu-container">
                    {/* {console.log(this.state.logoImage)}
                    {console.log(this.state.logoClass)} */}
                    {/* <div
                        className="logo-wrapper"
                    // style={{ 'background-image': this.state.logoImage }}
                    >
                        <img src={this.state.logoImage} alt="logo" className={this.state.logoClass} ></img>
                    </div> */}
                    <Menu
                        style={{
                            background: 'transparent',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#121736',
                            border: 'none',
                            marginTop: '40px'
                        }}
                        onClick={this.handleClick}
                        onSelect={this.handleSelect}
                        onOpenChange={this.handleOpenChange}
                        defaultOpenKeys={[openMenu]}
                        selectedKeys={[selectItem]}
                        mode="inline"
                        items={this.getMenuNodes(menuList, this.props.role)}
                    >
                    </Menu>
                </div>
                {!this.state.Exportcollapsed ? this.getSiderFooter() : ''}
            </Sider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.mainMenu,
        ...state.user
    }
}


export default connect(mapStateToProps)(withRouter(EFSider))