import React, { Component } from "react";
import { Divider } from "antd";
import "./index.less"

class EFSimpleContainer extends Component {
    render() {
        return (
            <div className="ef-simple-container">
                <div>
                    <div className="container-title">{this.props.title}</div>
                    <Divider plain className="divider"></Divider>
                </div>
                <div>{this.props.children}</div>
            </div>
        )
    }
}

export default EFSimpleContainer;