export const CountryConfig = [
    {
        "region": "欧州",
        "code": "Ukraine",
        "name": {
            "en": "Ukraine",
            "ja": "ウクライナ"
        }
    },
    {
        "region": "アフリカ",
        "code": "The Democratic Republic of the Congo",
        "name": {
            "en": "The Democratic Republic of the Congo",
            "ja": "コンゴ民主共和国"
        }
    },
    {
        "region": "欧州",
        "code": "Spain",
        "name": {
            "en": "Spain",
            "ja": "スペイン"
        }
    },
    {
        "region": "欧州",
        "code": "Bulgaria",
        "name": {
            "en": "Bulgaria",
            "ja": "ブルガリア"
        }
    },
    {
        "region": "欧州",
        "code": "Bosnia and Herzegovina",
        "name": {
            "en": "Bosnia and Herzegovina",
            "ja": "ボスニア・ヘルツェゴビナ"
        }
    },
    {
        "region": "アジア",
        "code": "Philippines",
        "name": {
            "en": "Philippines",
            "ja": "フィリピン"
        }
    },
    {
        "region": "欧州",
        "code": "Andorra",
        "name": {
            "en": "Andorra",
            "ja": "アンドラ"
        }
    },
    {
        "region": "中東",
        "code": "Iraq",
        "name": {
            "en": "Iraq",
            "ja": "イラク"
        }
    },
    {
        "region": "中南米",
        "code": "Bahamas",
        "name": {
            "en": "Bahamas",
            "ja": "バハマ"
        }
    },
    {
        "region": "欧州",
        "code": "Albania",
        "name": {
            "en": "Albania",
            "ja": "アルバニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Gabon",
        "name": {
            "en": "Gabon",
            "ja": "ガボン"
        }
    },
    {
        "region": "アジア",
        "code": "Brunei",
        "name": {
            "en": "Brunei",
            "ja": "ブルネイ"
        }
    },
    {
        "region": "欧州",
        "code": "Switzerland",
        "name": {
            "en": "Switzerland",
            "ja": "スイス"
        }
    },
    {
        "region": "アフリカ",
        "code": "Zambia",
        "name": {
            "en": "Zambia",
            "ja": "ザンビア"
        }
    },
    {
        "region": "中南米",
        "code": "El Salvador",
        "name": {
            "en": "El Salvador",
            "ja": "エルサルバドル"
        }
    },
    {
        "region": "アフリカ",
        "code": "Cameroon",
        "name": {
            "en": "Cameroon",
            "ja": "カメルーン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Central African Republic",
        "name": {
            "en": "Central African Republic",
            "ja": "中央アフリカ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Mozambique",
        "name": {
            "en": "Mozambique",
            "ja": "モザンビーク"
        }
    },
    {
        "region": "欧州",
        "code": "Finland",
        "name": {
            "en": "Finland",
            "ja": "フィンランド"
        }
    },
    {
        "region": "欧州",
        "code": "Kazakhstan",
        "name": {
            "en": "Kazakhstan",
            "ja": "カザフスタン"
        }
    },
    {
        "region": "欧州",
        "code": "Slovenia",
        "name": {
            "en": "Slovenia",
            "ja": "スロベニア"
        }
    },
    {
        "region": "北米",
        "code": "US",
        "name": {
            "en": "US",
            "ja": "米国"
        }
    },
    {
        "region": "アフリカ",
        "code": "Kenya",
        "name": {
            "en": "Kenya",
            "ja": "ケニア"
        }
    },
    {
        "region": "中南米",
        "code": "Argentina",
        "name": {
            "en": "Argentina",
            "ja": "アルゼンチン"
        }
    },
    {
        "region": "欧州",
        "code": "Latvia",
        "name": {
            "en": "Latvia",
            "ja": "ラトビア"
        }
    },
    {
        "region": "中南米",
        "code": "Paraguay",
        "name": {
            "en": "Paraguay",
            "ja": "パラグアイ"
        }
    },
    {
        "region": "大洋州",
        "code": "Fiji",
        "name": {
            "en": "Fiji",
            "ja": "フィジー"
        }
    },
    {
        "region": "アフリカ",
        "code": "Ethiopia",
        "name": {
            "en": "Ethiopia",
            "ja": "エチオピア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Eritrea",
        "name": {
            "en": "Eritrea",
            "ja": "エリトリア"
        }
    },
    {
        "region": "中東",
        "code": "Lebanon",
        "name": {
            "en": "Lebanon",
            "ja": "レバノン"
        }
    },
    {
        "region": "欧州",
        "code": "Iceland",
        "name": {
            "en": "Iceland",
            "ja": "アイスランド"
        }
    },
    {
        "region": "中東",
        "code": "Israel",
        "name": {
            "en": "Israel",
            "ja": "イスラエル"
        }
    },
    {
        "region": "中南米",
        "code": "Honduras",
        "name": {
            "en": "Honduras",
            "ja": "ホンジュラス"
        }
    },
    {
        "region": "アフリカ",
        "code": "Sao Tome and Principe",
        "name": {
            "en": "Sao Tome and Principe",
            "ja": "サントメ・プリンシペ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Equatorial Guinea",
        "name": {
            "en": "Equatorial Guinea",
            "ja": "赤道ギニア"
        }
    },
    {
        "region": "アジア",
        "code": "India",
        "name": {
            "en": "India",
            "ja": "インド"
        }
    },
    {
        "region": "アフリカ",
        "code": "Nigeria",
        "name": {
            "en": "Nigeria",
            "ja": "ナイジェリア"
        }
    },
    {
        "region": "欧州",
        "code": "Ireland",
        "name": {
            "en": "Ireland",
            "ja": "アイルランド"
        }
    },
    {
        "region": "アジア",
        "code": "North Korea",
        "name": {
            "en": "North Korea",
            "ja": "北朝鮮"
        }
    },
    {
        "region": "大洋州",
        "code": "Palau",
        "name": {
            "en": "Palau",
            "ja": "パラオ"
        }
    },
    {
        "region": "中南米",
        "code": "Barbados",
        "name": {
            "en": "Barbados",
            "ja": "バルバドス"
        }
    },
    {
        "region": "中東",
        "code": "Jordan",
        "name": {
            "en": "Jordan",
            "ja": "ヨルダン"
        }
    },
    {
        "region": "北米",
        "code": "Canada",
        "name": {
            "en": "Canada",
            "ja": "カナダ"
        }
    },
    {
        "region": "中南米",
        "code": "Dominican Republic",
        "name": {
            "en": "Dominican Republic",
            "ja": "ドミニカ共和国"
        }
    },
    {
        "region": "欧州",
        "code": "Russia",
        "name": {
            "en": "Russia",
            "ja": "ロシア"
        }
    },
    {
        "region": "欧州",
        "code": "Monaco",
        "name": {
            "en": "Monaco",
            "ja": "モナコ"
        }
    },
    {
        "region": "中東",
        "code": "Turkey",
        "name": {
            "en": "Turkey",
            "ja": "トルコ"
        }
    },
    {
        "region": "中南米",
        "code": "Saint Lucia",
        "name": {
            "en": "Saint Lucia",
            "ja": "セントルシア"
        }
    },
    {
        "region": "欧州",
        "code": "Montenegro",
        "name": {
            "en": "Montenegro",
            "ja": "モンテネグロ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Mauritania",
        "name": {
            "en": "Mauritania",
            "ja": "モーリタニア"
        }
    },
    {
        "region": "中東",
        "code": "Kuwait",
        "name": {
            "en": "Kuwait",
            "ja": "クウェート"
        }
    },
    {
        "region": "大洋州",
        "code": "Tonga",
        "name": {
            "en": "Tonga",
            "ja": "トンガ"
        }
    },
    {
        "region": "欧州",
        "code": "Czech",
        "name": {
            "en": "Czech",
            "ja": "チェコ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Morocco",
        "name": {
            "en": "Morocco",
            "ja": "モロッコ"
        }
    },
    {
        "region": "欧州",
        "code": "Kosovo",
        "name": {
            "en": "Kosovo",
            "ja": "コソボ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Mali",
        "name": {
            "en": "Mali",
            "ja": "マリ"
        }
    },
    {
        "region": "中東",
        "code": "Bahrain",
        "name": {
            "en": "Bahrain",
            "ja": "バーレーン"
        }
    },
    {
        "region": "欧州",
        "code": "Poland",
        "name": {
            "en": "Poland",
            "ja": "ポーランド"
        }
    },
    {
        "region": "アフリカ",
        "code": "South Africa",
        "name": {
            "en": "South Africa",
            "ja": "南アフリカ"
        }
    },
    {
        "region": "中南米",
        "code": "Costa Rica",
        "name": {
            "en": "Costa Rica",
            "ja": "コスタリカ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Angola",
        "name": {
            "en": "Angola",
            "ja": "アンゴラ"
        }
    },
    {
        "region": "中南米",
        "code": "Saint Vincent and the Grenadines",
        "name": {
            "en": "Saint Vincent and the Grenadines",
            "ja": "セントビンセントおよびグレナディーン諸島"
        }
    },
    {
        "region": "欧州",
        "code": "Sweden",
        "name": {
            "en": "Sweden",
            "ja": "スウェーデン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Zimbabwe",
        "name": {
            "en": "Zimbabwe",
            "ja": "ジンバブエ"
        }
    },
    {
        "region": "中東",
        "code": "Iran",
        "name": {
            "en": "Iran",
            "ja": "イラン"
        }
    },
    {
        "region": "アジア",
        "code": "Pakistan",
        "name": {
            "en": "Pakistan",
            "ja": "パキスタン"
        }
    },
    {
        "region": "大洋州",
        "code": "Niue",
        "name": {
            "en": "Niue",
            "ja": "ニウエ"
        }
    },
    {
        "region": "中南米",
        "code": "Mexico",
        "name": {
            "en": "Mexico",
            "ja": "メキシコ"
        }
    },
    {
        "region": "欧州",
        "code": "Denmark",
        "name": {
            "en": "Denmark",
            "ja": "デンマーク"
        }
    },
    {
        "region": "中南米",
        "code": "Bolivia",
        "name": {
            "en": "Bolivia",
            "ja": "ボリビア"
        }
    },
    {
        "region": "中南米",
        "code": "Nicaragua",
        "name": {
            "en": "Nicaragua",
            "ja": "ニカラグア"
        }
    },
    {
        "region": "欧州",
        "code": "Malta",
        "name": {
            "en": "Malta",
            "ja": "マルタ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Congo",
        "name": {
            "en": "Congo",
            "ja": "コンゴ共和国"
        }
    },
    {
        "region": "アジア",
        "code": "Timor-Leste",
        "name": {
            "en": "Timor-Leste",
            "ja": "東ティモール"
        }
    },
    {
        "region": "大洋州",
        "code": "New Zealand",
        "name": {
            "en": "New Zealand",
            "ja": "ニュージーランド"
        }
    },
    {
        "region": "アフリカ",
        "code": "Lesotho",
        "name": {
            "en": "Lesotho",
            "ja": "レソト"
        }
    },
    {
        "region": "欧州",
        "code": "UK",
        "name": {
            "en": "UK",
            "ja": "英国"
        }
    },
    {
        "region": "欧州",
        "code": "North Macedonia",
        "name": {
            "en": "North Macedonia",
            "ja": "北マケドニア"
        }
    },
    {
        "region": "中南米",
        "code": "Saint Kitts and Nevis",
        "name": {
            "en": "Saint Kitts and Nevis",
            "ja": "セントクリストファー・ネービス"
        }
    },
    {
        "region": "中東",
        "code": "Oman",
        "name": {
            "en": "Oman",
            "ja": "オマーン"
        }
    },
    {
        "region": "中南米",
        "code": "Dominica",
        "name": {
            "en": "Dominica",
            "ja": "ドミニカ国"
        }
    },
    {
        "region": "中東",
        "code": "Yemen",
        "name": {
            "en": "Yemen",
            "ja": "イエメン"
        }
    },
    {
        "region": "欧州",
        "code": "Portugal",
        "name": {
            "en": "Portugal",
            "ja": "ポルトガル"
        }
    },
    {
        "region": "アジア",
        "code": "Japan",
        "name": {
            "en": "Japan",
            "ja": "日本"
        }
    },
    {
        "region": "中南米",
        "code": "Cuba",
        "name": {
            "en": "Cuba",
            "ja": "キューバ"
        }
    },
    {
        "region": "大洋州",
        "code": "Solomon Islands",
        "name": {
            "en": "Solomon Islands",
            "ja": "ソロモン諸島"
        }
    },
    {
        "region": "欧州",
        "code": "Liechtenstein",
        "name": {
            "en": "Liechtenstein",
            "ja": "リヒテンシュタイン"
        }
    },
    {
        "region": "中南米",
        "code": "Peru",
        "name": {
            "en": "Peru",
            "ja": "ペルー"
        }
    },
    {
        "region": "アジア",
        "code": "Hong Kong",
        "name": {
            "en": "Hong Kong",
            "ja": "香港"
        }
    },
    {
        "region": "アフリカ",
        "code": "Seychelles",
        "name": {
            "en": "Seychelles",
            "ja": "セーシェル"
        }
    },
    {
        "region": "欧州",
        "code": "Serbia",
        "name": {
            "en": "Serbia",
            "ja": "セルビア"
        }
    },
    {
        "region": "アジア",
        "code": "Korea",
        "name": {
            "en": "Korea",
            "ja": "韓国"
        }
    },
    {
        "region": "中南米",
        "code": "Ecuador",
        "name": {
            "en": "Ecuador",
            "ja": "エクアドル"
        }
    },
    {
        "region": "大洋州",
        "code": "Kiribati",
        "name": {
            "en": "Kiribati",
            "ja": "キリバス"
        }
    },
    {
        "region": "アフリカ",
        "code": "Tunisia",
        "name": {
            "en": "Tunisia",
            "ja": "チュニジア"
        }
    },
    {
        "region": "欧州",
        "code": "Luxembourg",
        "name": {
            "en": "Luxembourg",
            "ja": "ルクセンブルク"
        }
    },
    {
        "region": "アフリカ",
        "code": "Côte d'Ivoire",
        "name": {
            "en": "Côte d'Ivoire",
            "ja": "コートジボワール"
        }
    },
    {
        "region": "欧州",
        "code": "Italy",
        "name": {
            "en": "Italy",
            "ja": "イタリア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Burkina Faso",
        "name": {
            "en": "Burkina Faso",
            "ja": "ブルキナファソ"
        }
    },
    {
        "region": "アジア",
        "code": "Sri Lanka",
        "name": {
            "en": "Sri Lanka",
            "ja": "スリランカ"
        }
    },
    {
        "region": "アジア",
        "code": "Nepal",
        "name": {
            "en": "Nepal",
            "ja": "ネパール"
        }
    },
    {
        "region": "欧州",
        "code": "Vatican City",
        "name": {
            "en": "Vatican City",
            "ja": "バチカン"
        }
    },
    {
        "region": "中南米",
        "code": "Uruguay",
        "name": {
            "en": "Uruguay",
            "ja": "ウルグアイ"
        }
    },
    {
        "region": "欧州",
        "code": "Belarus",
        "name": {
            "en": "Belarus",
            "ja": "ベラルーシ"
        }
    },
    {
        "region": "中東",
        "code": "Qatar",
        "name": {
            "en": "Qatar",
            "ja": "カタール"
        }
    },
    {
        "region": "欧州",
        "code": "Lithuania",
        "name": {
            "en": "Lithuania",
            "ja": "リトアニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Djibouti",
        "name": {
            "en": "Djibouti",
            "ja": "ジブチ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Benin",
        "name": {
            "en": "Benin",
            "ja": "ベナン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Guinea-Bissau",
        "name": {
            "en": "Guinea-Bissau",
            "ja": "ギニアビサウ"
        }
    },
    {
        "region": "大洋州",
        "code": "Australia",
        "name": {
            "en": "Australia",
            "ja": "オーストラリア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Algeria",
        "name": {
            "en": "Algeria",
            "ja": "アルジェリア"
        }
    },
    {
        "region": "中東",
        "code": "UAE",
        "name": {
            "en": "UAE",
            "ja": "アラブ首長国連邦"
        }
    },
    {
        "region": "中東",
        "code": "Syria",
        "name": {
            "en": "Syria",
            "ja": "シリア"
        }
    },
    {
        "region": "中南米",
        "code": "Grenada",
        "name": {
            "en": "Grenada",
            "ja": "グレナダ"
        }
    },
    {
        "region": "アジア",
        "code": "Bangladesh",
        "name": {
            "en": "Bangladesh",
            "ja": "バングラデシュ"
        }
    },
    {
        "region": "アジア",
        "code": "Thailand",
        "name": {
            "en": "Thailand",
            "ja": "タイ"
        }
    },
    {
        "region": "中南米",
        "code": "Suriname",
        "name": {
            "en": "Suriname",
            "ja": "スリナム"
        }
    },
    {
        "region": "欧州",
        "code": "Estonia",
        "name": {
            "en": "Estonia",
            "ja": "エストニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Mauritius",
        "name": {
            "en": "Mauritius",
            "ja": "モーリシャス"
        }
    },
    {
        "region": "アジア",
        "code": "Maldives",
        "name": {
            "en": "Maldives",
            "ja": "モルディブ"
        }
    },
    {
        "region": "中南米",
        "code": "Brazil",
        "name": {
            "en": "Brazil",
            "ja": "ブラジル"
        }
    },
    {
        "region": "アジア",
        "code": "Indonesia",
        "name": {
            "en": "Indonesia",
            "ja": "インドネシア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Tanzania",
        "name": {
            "en": "Tanzania",
            "ja": "タンザニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Niger",
        "name": {
            "en": "Niger",
            "ja": "ニジェール"
        }
    },
    {
        "region": "欧州",
        "code": "Croatia",
        "name": {
            "en": "Croatia",
            "ja": "クロアチア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Somaglia",
        "name": {
            "en": "Somaglia",
            "ja": "ソマリア"
        }
    },
    {
        "region": "欧州",
        "code": "Tajikistan",
        "name": {
            "en": "Tajikistan",
            "ja": "タジキスタン"
        }
    },
    {
        "region": "大洋州",
        "code": "Nauru",
        "name": {
            "en": "Nauru",
            "ja": "ナウル"
        }
    },
    {
        "region": "中南米",
        "code": "Venezuela",
        "name": {
            "en": "Venezuela",
            "ja": "ベネズエラ"
        }
    },
    {
        "region": "欧州",
        "code": "Norway",
        "name": {
            "en": "Norway",
            "ja": "ノルウェー"
        }
    },
    {
        "region": "欧州",
        "code": "Azerbaijan",
        "name": {
            "en": "Azerbaijan",
            "ja": "アゼルバイジャン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Gambia",
        "name": {
            "en": "Gambia",
            "ja": "ガンビア"
        }
    },
    {
        "region": "欧州",
        "code": "Belgium",
        "name": {
            "en": "Belgium",
            "ja": "ベルギー"
        }
    },
    {
        "region": "アフリカ",
        "code": "Comoros",
        "name": {
            "en": "Comoros",
            "ja": "コモロ"
        }
    },
    {
        "region": "アジア",
        "code": "Malaysia",
        "name": {
            "en": "Malaysia",
            "ja": "マレーシア"
        }
    },
    {
        "region": "中南米",
        "code": "Chile",
        "name": {
            "en": "Chile",
            "ja": "チリ"
        }
    },
    {
        "region": "欧州",
        "code": "Armenia",
        "name": {
            "en": "Armenia",
            "ja": "アルメニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Egypt",
        "name": {
            "en": "Egypt",
            "ja": "エジプト"
        }
    },
    {
        "region": "欧州",
        "code": "Cyprus",
        "name": {
            "en": "Cyprus",
            "ja": "キプロス"
        }
    },
    {
        "region": "アフリカ",
        "code": "Swaziland",
        "name": {
            "en": "Swaziland",
            "ja": "エスワティニ"
        }
    },
    {
        "region": "大洋州",
        "code": "Vanuatu",
        "name": {
            "en": "Vanuatu",
            "ja": "バヌアツ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Cape Verde",
        "name": {
            "en": "Cape Verde",
            "ja": "カーボベルデ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Chad",
        "name": {
            "en": "Chad",
            "ja": "チャド"
        }
    },
    {
        "region": "中東",
        "code": "Saudi Arabia",
        "name": {
            "en": "Saudi Arabia",
            "ja": "サウジアラビア"
        }
    },
    {
        "region": "欧州",
        "code": "Netherlands",
        "name": {
            "en": "Netherlands",
            "ja": "オランダ"
        }
    },
    {
        "region": "欧州",
        "code": "Romania",
        "name": {
            "en": "Romania",
            "ja": "ルーマニア"
        }
    },
    {
        "region": "欧州",
        "code": "Slovakia",
        "name": {
            "en": "Slovakia",
            "ja": "スロバキア"
        }
    },
    {
        "region": "欧州",
        "code": "France",
        "name": {
            "en": "France",
            "ja": "フランス"
        }
    },
    {
        "region": "アジア",
        "code": "Cambodia",
        "name": {
            "en": "Cambodia",
            "ja": "カンボジア"
        }
    },
    {
        "region": "欧州",
        "code": "Greece",
        "name": {
            "en": "Greece",
            "ja": "ギリシャ"
        }
    },
    {
        "region": "中南米",
        "code": "Guyana",
        "name": {
            "en": "Guyana",
            "ja": "ガイアナ"
        }
    },
    {
        "region": "大洋州",
        "code": "Marshall Islands",
        "name": {
            "en": "Marshall Islands",
            "ja": "マーシャル"
        }
    },
    {
        "region": "大洋州",
        "code": "Cook Islands",
        "name": {
            "en": "Cook Islands",
            "ja": "クック諸島"
        }
    },
    {
        "region": "中南米",
        "code": "Panama",
        "name": {
            "en": "Panama",
            "ja": "パナマ"
        }
    },
    {
        "region": "アジア",
        "code": "Mongolia",
        "name": {
            "en": "Mongolia",
            "ja": "モンゴル"
        }
    },
    {
        "region": "アフリカ",
        "code": "Uganda",
        "name": {
            "en": "Uganda",
            "ja": "ウガンダ"
        }
    },
    {
        "region": "欧州",
        "code": "Turkmenistan",
        "name": {
            "en": "Turkmenistan",
            "ja": "トルクメニスタン"
        }
    },
    {
        "region": "欧州",
        "code": "Germany",
        "name": {
            "en": "Germany",
            "ja": "ドイツ"
        }
    },
    {
        "region": "中南米",
        "code": "Guatemala",
        "name": {
            "en": "Guatemala",
            "ja": "グアテマラ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Guinea",
        "name": {
            "en": "Guinea",
            "ja": "ギニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Senegal",
        "name": {
            "en": "Senegal",
            "ja": "セネガル"
        }
    },
    {
        "region": "中南米",
        "code": "Colombia",
        "name": {
            "en": "Colombia",
            "ja": "コロンビア"
        }
    },
    {
        "region": "アジア",
        "code": "Laos",
        "name": {
            "en": "Laos",
            "ja": "ラオス"
        }
    },
    {
        "region": "アジア",
        "code": "Bhutan",
        "name": {
            "en": "Bhutan",
            "ja": "ブータン"
        }
    },
    {
        "region": "大洋州",
        "code": "Samoa",
        "name": {
            "en": "Samoa",
            "ja": "サモア"
        }
    },
    {
        "region": "欧州",
        "code": "Austria",
        "name": {
            "en": "Austria",
            "ja": "オーストリア"
        }
    },
    {
        "region": "中南米",
        "code": "Belize",
        "name": {
            "en": "Belize",
            "ja": "ベリーズ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Liberia",
        "name": {
            "en": "Liberia",
            "ja": "リベリア"
        }
    },
    {
        "region": "大洋州",
        "code": "Tuvalu",
        "name": {
            "en": "Tuvalu",
            "ja": "ツバル"
        }
    },
    {
        "region": "アジア",
        "code": "Taiwan",
        "name": {
            "en": "Taiwan",
            "ja": "台湾"
        }
    },
    {
        "region": "中南米",
        "code": "Jamaica",
        "name": {
            "en": "Jamaica",
            "ja": "ジャマイカ"
        }
    },
    {
        "region": "欧州",
        "code": "Moldova",
        "name": {
            "en": "Moldova",
            "ja": "モルドバ"
        }
    },
    {
        "region": "中南米",
        "code": "Haiti",
        "name": {
            "en": "Haiti",
            "ja": "ハイチ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Malawi",
        "name": {
            "en": "Malawi",
            "ja": "マラウイ"
        }
    },
    {
        "region": "欧州",
        "code": "Kyrgyzstan",
        "name": {
            "en": "Kyrgyzstan",
            "ja": "キルギス"
        }
    },
    {
        "region": "アジア",
        "code": "Myanmar",
        "name": {
            "en": "Myanmar",
            "ja": "ミャンマー"
        }
    },
    {
        "region": "大洋州",
        "code": "Micronesia",
        "name": {
            "en": "Micronesia",
            "ja": "ミクロネシア"
        }
    },
    {
        "region": "中東",
        "code": "Afghanistan",
        "name": {
            "en": "Afghanistan",
            "ja": "アフガニスタン"
        }
    },
    {
        "region": "大洋州",
        "code": "Papua New Guinea",
        "name": {
            "en": "Papua New Guinea",
            "ja": "パプアニューギニア"
        }
    },
    {
        "region": "アフリカ",
        "code": "Botswana",
        "name": {
            "en": "Botswana",
            "ja": "ボツワナ"
        }
    },
    {
        "region": "中南米",
        "code": "Antigua and Barbuda",
        "name": {
            "en": "Antigua and Barbuda",
            "ja": "アンティグア・バーブーダ"
        }
    },
    {
        "region": "欧州",
        "code": "Uzbekistan",
        "name": {
            "en": "Uzbekistan",
            "ja": "ウズベキスタン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Madagascar",
        "name": {
            "en": "Madagascar",
            "ja": "マダガスカル"
        }
    },
    {
        "region": "アジア",
        "code": "Singapore",
        "name": {
            "en": "Singapore",
            "ja": "シンガポール"
        }
    },
    {
        "region": "アフリカ",
        "code": "Burundi",
        "name": {
            "en": "Burundi",
            "ja": "ブルンジ"
        }
    },
    {
        "region": "欧州",
        "code": "Georgia",
        "name": {
            "en": "Georgia",
            "ja": "ジョージア"
        }
    },
    {
        "region": "アジア",
        "code": "Macao",
        "name": {
            "en": "Macao",
            "ja": "マカオ"
        }
    },
    {
        "region": "アフリカ",
        "code": "South Sudan",
        "name": {
            "en": "South Sudan",
            "ja": "南スーダン"
        }
    },
    {
        "region": "アフリカ",
        "code": "Namibia",
        "name": {
            "en": "Namibia",
            "ja": "ナミビア"
        }
    },
    {
        "region": "アジア",
        "code": "China",
        "name": {
            "en": "China",
            "ja": "中国"
        }
    },
    {
        "region": "欧州",
        "code": "San Marino",
        "name": {
            "en": "San Marino",
            "ja": "サンマリノ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Togo",
        "name": {
            "en": "Togo",
            "ja": "トーゴ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Sierra Leone",
        "name": {
            "en": "Sierra Leone",
            "ja": "シエラレオネ"
        }
    },
    {
        "region": "欧州",
        "code": "Hungary",
        "name": {
            "en": "Hungary",
            "ja": "ハンガリー"
        }
    },
    {
        "region": "アフリカ",
        "code": "Rwanda",
        "name": {
            "en": "Rwanda",
            "ja": "ルワンダ"
        }
    },
    {
        "region": "アジア",
        "code": "Vietnam",
        "name": {
            "en": "Vietnam",
            "ja": "ベトナム"
        }
    },
    {
        "region": "アフリカ",
        "code": "Ghana",
        "name": {
            "en": "Ghana",
            "ja": "ガーナ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Sudan",
        "name": {
            "en": "Sudan",
            "ja": "スーダン"
        }
    },
    {
        "region": "中南米",
        "code": "Trinidad and Tobago",
        "name": {
            "en": "Trinidad and Tobago",
            "ja": "トリニダード・トバゴ"
        }
    },
    {
        "region": "アフリカ",
        "code": "Libya",
        "name": {
            "en": "Libya",
            "ja": "リビア"
        }
    }
]

let _CountryConfigDict;

export const getCountryConfigDict = () => {
    if (_CountryConfigDict) {
        return _CountryConfigDict
    } else {

        _CountryConfigDict = CountryConfig.reduce((preiousValue, currentValue) => {
            preiousValue[currentValue.code] = currentValue
            return preiousValue;
        }, {})
        return _CountryConfigDict;
    }
}