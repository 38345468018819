import * as types from "../action-types";

export const setBreadcrumbs = (breadcrumbs) => {
    return {
        type: types.ContentContainerHeader_SetBreadcrumb,
        breadcrumbs
    };
};

export const setContentContainerHeaderAction = (actions) => {
    return {
        type: types.ContentContainerHeader_SetActionButton,
        actions,
    }
}