import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store"
import React, { useEffect, useRef, useState, useCallback } from "react"
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Select, message } from "antd"
import { connect } from "react-redux"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqCreateUser } from '../../../api/admin-user'
import { reqSearchClients } from '../../../api/admin-client'
import { isValidMail, isValidPassword, isValidTelNo } from "../../../utils/ef-re"

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFBoxList from '../../../components/ef-c-messageBox'

// react语言处理
import { FormattedMessage,useIntl } from 'react-intl'
import messages from './messages'
const UserNew = () => {

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.auth,
    }
    const intl = useIntl()

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()

    const companies = useRef([])
    const selectCompony = useRef({})

    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])
    const [currentState, setCurrentState] = useState()
    const updateTrigger = useRef()

    const basicInformationRef = useRef({})
    const basicTypeRef = useRef([])
    const companyInformationRef = useRef({})
    const companyTypeRef = useRef([])
    const queueList = useRef({ basic: false, company: false })
    const condition = useRef({})

    const initData = () => {
        basicInformationRef.current = {
            name: null,
            mail: null,
            password: null,
            password2: null,
            tel: null,
            department: null,
            remarks: null,
        }

        basicTypeRef.current = [
            {
                label: 'app.ui.user_list_detail.name',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.user_mail',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.user_password',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.user_password_confirm',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.tel',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.department',
                type: 'input',
                permission: true
            }, {
                label: 'app.ui.user_list_detail.remarks',
                type: 'textarea',
                permission: true
            }
        ]

        companyInformationRef.current = {
            company: null,
            client_tel: null,
            client_address: null,
            client_remarks: null
        }

        let company = companies.current.map((item, index) => {
            return { label: item.client_name, value: item.client_id }
        })
        companyTypeRef.current = [
            {
                label: 'app.ui.profile.section.company.name',
                type: 'select',
                params: company,
                permission: true
            }, {
                label: 'app.ui.profile.section.company.tel',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.profile.section.company.address',
                type: 'input',
                permission: false
            }, {
                label: 'app.ui.profile.section.company.remarks',
                type: 'textarea',
                permission: false
            }
        ]
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-user-list"));
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.ui.user_list_new.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.user_list_new.userlist" />,
                path: "/manage/user",
            },
            {
                name: <FormattedMessage id="app.ui.user_list_new.new_user" />,
            }
        ]));
    }

    const renderClientsData = () => {
        reqSearchClients().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                companies.current = data.data.client
                initData()
                toggleLoading()
                onHandleState(true)
            }
        }).catch((error) => {
            
        })
    }
 
    useEffect(() => {
        renderClientsData()
        renderBread()
    },[])

    const reClear = () => {
        Object.keys(queueList.current).map((item, index) => {
            queueList.current[item] = false
        })
        condition.current = {}
    }

    const onHandleParams = (value, item) => {
        if (item === 'company') {
            companyInformationRef.current['company'] = value
            selectCompony.current = companies.current.find((item) => value === item.client_id)
            companyInformationRef.current['client_tel'] = selectCompony.current ? selectCompony.current.client_tel : ''
            companyInformationRef.current['client_address'] = selectCompony.current ? selectCompony.current.client_address : ''
            companyInformationRef.current['client_remarks'] = selectCompony.current ? selectCompony.current.client_remarks : ''
            toggleLoading()
        }
    }

    const onRuleControl = (name,password, password2, mail, tel, department , remarks) => {
        if (!name) {
            message.error(intl.formatMessage(
                messages['message.error.name_empty']
            ))
            return true
        }
        if (!isValidMail(mail)){
            message.error(intl.formatMessage(
                messages['message.error.email_failed']
            ))
            return true
        }
        if (!isValidPassword(password)) {
            message.error(intl.formatMessage(
                messages['message.error.password_failed']
            ))
            return true
        }
        if (password !== password2) {
            message.error(intl.formatMessage(
                messages['message.error.password2_failed']
            ))  
            return true
        }
        if (tel && !isValidTelNo(tel)) {
            message.error(intl.formatMessage(
                messages['message.error.phone_failed']
            ))
            return true
        }
        if (!department) {
            message.error(intl.formatMessage(
                messages['message.error.section_failed']
            ))
            return true
        }
        if (remarks && remarks.length > 500) {
            message.error(intl.formatMessage(
                messages['message.error.remarks_failed']
            ))
            return true
        }
        return false
    }

    const onHandleChange = (value, order) => {
        queueList.current[order] = !queueList.current[order]
        condition.current = { ...value, ...condition.current }
        for (let key in queueList.current) {
            if (queueList.current.hasOwnProperty(key)) {
                if (queueList.current[key] === false) {
                    return
                }
            }
        }

        const { name, mail, tel, department, remarks, password, password2, company } = condition.current

        if (onRuleControl(name,password, password2, mail, tel, department , remarks)) {
            reClear()
            return
        }

        let body = {
            name,
            tel,
            role: 1,
            department,
            remarks,
            mail,
            password2,
            password
        }
        if (company) {
            body.companyId = parseInt(company);
        }

        reqCreateUser(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                history.push("/manage/user")
                reClear()
                toggleLoading()
            }

        }).catch((error) => {
            toggleLoading()
            message.error(intl.formatMessage(
                messages['message.error.new_failed']
            ))
        })
    }

    const onHandleState = (value) => {
        setCurrentState(value)
    }

    const onHandleUpdate = () => {
        if (updateTrigger.current === undefined) {
            updateTrigger.current = false
        }
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderTrigger = () => {
        return <div className='ef-box-footer'>
            <Form.Item>
                <Button className='ef-button-color-small' onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.ui.user_list_new.new_user'/></Button>
            </Form.Item>
            </div>
    }

    const isUpdate = () => {
        return updateTrigger.current
    }

    document.title = intl.formatMessage({id:'app.ui.user_list_new.title'})
    return <EFCContainer
        overspread={0}
        style={{ padding: '32px' }}>
        <Row gutter={[20, 0]}>
            <Col span={12}>
                <EFBoxList
                    mode={true}
                    // height={'100%'}
                    title={<FormattedMessage id="app.ui.profile.section.basicinfo.title" />}
                    titleBarColor={'#1880DE'}
                    basicInfo={basicInformationRef.current}
                    infoType={basicTypeRef.current}
                    lineWidth={300}
                    onHandleChange={(value) => { onHandleChange(value, 'basic') }}
                    readOnly
                    state={currentState}
                    updateTrigger={isUpdate()}
                ></EFBoxList>
                {renderTrigger()}
            </Col>
            <Col span={12}>
                <EFBoxList
                    mode={true}
                    // height={'100%'}
                    title={<FormattedMessage id="app.ui.profile.section.company.title" />}
                    titleBarColor={'#1880DE'}
                    basicInfo={companyInformationRef.current}
                    infoType={companyTypeRef.current}
                    lineWidth={300}
                    onHandleParams={(value, item) => { onHandleParams(value, item) }}
                    onHandleChange={(value) => { onHandleChange(value, 'company') }}
                    readOnly
                    state={currentState}
                    updateTrigger={isUpdate()}
                ></EFBoxList>
            </Col>
        </Row>
    </EFCContainer>
}
export default UserNew