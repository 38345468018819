import Cookies from 'js-cookie'

const TokenKey = 'EFToken'
const CookieLocaleKey = 'CookieLocaleKey'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getCookieLocale() {
    return Cookies.get(CookieLocaleKey)
}

export function setCookieLocale(locale) {
    return Cookies.set(CookieLocaleKey,locale)
}