// PieData:   [{name: 'xxxxx', value:xxxxx}],

const genOverallInfringementType = (config) => {
    let { echarts, xAxisData, elementId, series, legend } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let chart = echarts.init(document.getElementById(elementId));

    let option = {
        // visualMap: [{
        //     show: false,
        //     type: 'continuous',
        //     seriesIndex: 0,
        //     min: 0,
        //     max: 2000
        // }],
        legend: {
            data: legend
        },
        title: {
            text: ""
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            axisLabel: { rotate: 30 },
            data: xAxisData
        },
        yAxis: {
            type: 'value',
        },
        series: series
    };

    chart.setOption(option);
    chart.resize();
    window.addEventListener("resize", function () {
        chart.resize();
    });
}

export default genOverallInfringementType;


