import "./index.less"
import React, { Component } from "react";
import {  Row, Col,  Input, Tabs, Card, Form } from "antd";

import { reqCreateBrand, reqCreateBrandKeyword, reqCreateBrandModel, reqFetchBrands, reqRemoveBrand, reqRemoveBrandKeyword, reqRemoveBrandModel, reqUpdateBrand, reqUpdateBrandKeyword, reqUpdateBrandModel } from '../../../api/analyst-brand'
import EFCMasterTable from "@/components/ef-c-master-table";

import { ProFormText, } from '@ant-design/pro-form';


class AnalystClientBrandBackup extends Component {

    onRowClick = (record) => {
        let action = (event) => {
            this.setState({
                keywords: this.keywordDict[record.brand_id],
                models: this.modelDict[record.brand_id],
                selectedBrandRowKeys: [record.brand_id]
            })
        }
        return action
    }

    constructor(props) {
        super(props)
        this.state = {
            brands: [],
            keywords: [],
            models: [],
            selectedBrandRowKeys: [],
            file: ""
        }
        this.keywordDict = {}
        this.modelDict = {}
        if (this.props.clientId) {
            reqFetchBrands({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    data.data.brands.map((brand) => {
                        this.keywordDict[brand.brand_id] = brand.keywords;
                        this.modelDict[brand.brand_id] = brand.models
                        return brand;
                    });
                  
                    this.setState({ brands: data.data.brands });
                }
            }).catch((error) => {

            })
        }

        this.brandColumns = this.createBrandColumns()
    }

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedBrandRowKeys: selectedRowKeys })
        },
    };
    draggerProps = () => {
        let _this = this;
        return {
            name: "file",
            multiple: false,
            accept: ".xlsx, .xls",
            onChange(info) {

            },
            beforeUpload(file, fileList) {
                return false;
            },
            customRequest(e) {
                _this.readerData(e.file).then(() => {
                    e.onSuccess();
                });
            }
        };
    };

    // brand

    createBrandColumns = (record) => {
        return [
            {
                title: '番号',
                dataIndex: 'brand_id',
                key: 'brand_id',
            },
            {
                title: 'ブランド',
                dataIndex: 'brand_name',
                key: 'brand_name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"brand_name_" + record.brand_id}
                            rules={[
                                {
                                    required: true,
                                    message: "ブランドを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
        ];
    }

    createBrandModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="brand_name" label="ブランド" rules={[
                    {
                        required: true,
                        message: 'ブランドを入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditBrandFieldsValue = (record) => {
        let row = {}
        row["brand_name_" + record.brand_id] = record.brand_name;
        return row
    }

    getBrandTableProps = () => {
        return {
            pagination: false,
            onRow: record => {
                return {
                    onClick: this.onRowClick(record), // 点击行
                };
            },
            rowSelection: {
                type: "radio",
                selectedRowKeys: this.state.selectedBrandRowKeys,
                ...this.rowSelection,
            },
            rowKey: (record) => {
                return record.brand_id
            },
        }

    }

    updateBrand = (response) => {
        let { data } = response;
        if (data.code === 0) {
            data.data.brands.map((brand) => {
                this.keywordDict[brand.brand_id] = brand.keywords
                this.modelDict[brand.brand_id] = brand.models
                return brand;
            });
            this.setState({ brands: data.data.brands });
        }
    }

    onRemoveBrandClick = (record) => {
        reqRemoveBrand({
            brandId: record.brand_id,
            clientId: this.props.clientId
        }).then((response) => {
            this.updateBrand(response);
        }).catch((e) => {

        })
    }

    onUpdateBrandClick = (record, values) => {
        reqUpdateBrand({
            brandId: record.brand_id,
            brand: values["brand_name_" + record.brand_id],
            clientId: this.props.clientId
        }).then((response) => {
            this.updateBrand(response);
        }).catch((e) => {

        })
    }

    onCreateBrandClick = (values) => {
        reqCreateBrand({
            brand: values["brand_name"],
            clientId: this.props.clientId
        }).then((response) => {
            this.updateBrand(response);
        }).catch((e) => {

        })
    }
    // brand key 
    createBrandKeyColumns = (record) => {
        return [
            {
                title: '番号',
                dataIndex: 'keyword_id',
                key: 'keyword_id',
            },
            {
                title: 'ブランド表記',
                dataIndex: 'keyword_name',
                key: 'keyword_name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"keyword_name_" + record.keyword_id}
                            rules={[
                                {
                                    required: true,
                                    message: "ブランド表記を入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }
            },
        ];
    }

    createBrandKeyModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="keyword_name" label="ブランド表記" rules={[
                    {
                        required: true,
                        message: 'ブランド表記を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditBrandKeyFieldsValue = (record) => {
        let row = {}
        row["keyword_name_" + record.keyword_id] = record.keyword_name;
        return row
    }

    updateBrandKeyDict = (response) => {
        let { data } = response;
        if (data.code === 0) {
            this.keywordDict[data.data.brandId] = data.data.brandKeywords
            if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
                if (this.state.selectedBrandRowKeys[0] === data.data.brandId) {
                    this.setState({
                        keywords: data.data.brandKeywords
                    })
                }
            }
        }
    }

    onRemoveBrandKeyClick = (record) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            reqRemoveBrandKeyword({
                keywordId: record.keyword_id,
                brandId: this.state.selectedBrandRowKeys[0],
                clientId: this.props.clientId
            }).then((response) => {
                this.updateBrandKeyDict(response);
            }).catch((e) => {

            })
        }
    }

    onUpdateBrandKeyClick = (record, values) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            reqUpdateBrandKeyword({
                keywordId: record.keyword_id,
                keywordName: values["keyword_name_" + record.keyword_id],
                brandId: this.state.selectedBrandRowKeys[0],
                clientId: this.props.clientId
            }).then((response) => {
                this.updateBrandKeyDict(response);
            }).catch((e) => {

            })
        }
    }

    onCreateBrandKeyClick = (values) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            console.log(this.state.selectedBrandRowKeys)
            reqCreateBrandKeyword({
                clientId: this.props.clientId,
                brandId: this.state.selectedBrandRowKeys[0],
                keywordName: values["keyword_name"],
            }).then((response) => {
                this.updateBrandKeyDict(response);
            }).catch((e) => {

            })
        }
    }

    // brand model 

    createBrandModelColumns = (record) => {
        return [
            {
                title: '番号',
                dataIndex: 'model_id',
                key: 'model_id',
            },
            {
                title: 'Model',
                dataIndex: 'model_name',
                key: 'model_name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"model_name_" + record.model_id}
                            rules={[
                                {
                                    required: true,
                                    message: "モデルを入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: '定価（＄）',
                dataIndex: 'model_price',
                key: 'model_price',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"model_price_" + record.model_id}
                            rules={[
                                {
                                    required: true,
                                    message: "定価を入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }
            },
        ]
    }

    createBrandModelModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="model_name" label="モデル" rules={[
                    {
                        required: true,
                        message: 'モデルを入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="model_price" label="定価" rules={[
                    {
                        required: true,
                        message: '定価を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditBrandModelFieldsValue = (record) => {
        let row = {}
        row["model_name_" + record.model_id] = record.model_name;
        row["model_price_" + record.model_id] = record.model_price;
        return row
    }

    updateBrandModelDict = (response) => {
        let { data } = response;
        if (data.code === 0) {
            this.modelDict[data.data.brandId] = data.data.brandModels;
            if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
                if (this.state.selectedBrandRowKeys[0] === data.data.brandId) {
                    this.setState({
                        models: data.data.brandModels
                    })
                }
            }
        }
    }

    onRemoveBrandModelClick = (record) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            reqRemoveBrandModel({
                brandModelId: record.model_id,
                brandId: this.state.selectedBrandRowKeys[0],
                clientId: this.props.clientId
            }).then((response) => {
                this.updateBrandModelDict(response);
            }).catch((e) => {

            })
        }
    }

    onUpdateBrandModelClick = (record, values) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            reqUpdateBrandModel({
                brandModelId: record.model_id,
                brandModelName: values["model_name_" + record.model_id],
                brandModelPrice: parseFloat(values["model_price_" + record.model_id]),
                brandId: this.state.selectedBrandRowKeys[0],
                clientId: this.props.clientId
            }).then((response) => {
                this.updateBrandModelDict(response);
            }).catch((e) => {

            })
        }
    }

    onCreateBrandModelClick = (values) => {
        if (this.state.selectedBrandRowKeys && this.state.selectedBrandRowKeys.length > 0) {
            reqCreateBrandModel({
                clientId: this.props.clientId,
                brandId: this.state.selectedBrandRowKeys[0],
                brandModelName: values["model_name"],
                brandModelPrice: parseFloat(values["model_price"]),
            }).then((response) => {
                this.updateBrandModelDict(response);
            }).catch((e) => {

            })
        }
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }
    render() {
        return (
            <div className="master-container ec-master-brand">
                <Row style={{width:'100%'}}>
                    <Col span={12}>
                        <Card bordered={false}>
                            <EFCMasterTable
                                modalTitle="ブランド追加"
                                rowkey="brand_id"
                                columns={this.createBrandColumns()}
                                dataSource={this.state.brands}
                                tableProps={this.getBrandTableProps()}
                                getEditFieldsValue={this.getEditBrandFieldsValue}
                                modalItems={this.createBrandModalItems()}
                                onRemoveClick={this.onRemoveBrandClick}
                                onSaveClick={this.onUpdateBrandClick}
                                onModalFinished={this.onCreateBrandClick}
                            />
                        </Card>
                        <div className="ec-master-brand-menu">
                            {/* {this.renderList()} */}
                        </div>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false}>
                            <Tabs type="line" defaultActiveKey="1" >
                                <Tabs.TabPane tab="表記" key="1">
                                    <EFCMasterTable
                                        modalTitle="表記追加"
                                        rowkey="keyword_id"
                                        columns={this.createBrandKeyColumns()}
                                        dataSource={this.state.keywords}
                                        getEditFieldsValue={this.getEditBrandKeyFieldsValue}
                                        modalItems={this.createBrandKeyModalItems()}
                                        onRemoveClick={this.onRemoveBrandKeyClick}
                                        onSaveClick={this.onUpdateBrandKeyClick}
                                        onModalFinished={this.onCreateBrandKeyClick}
                                        tableProps={this.getTableProps()}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="モデル" key="2">
                                    <EFCMasterTable
                                        modalTitle="モデル追加"
                                        rowkey="model_id"
                                        columns={this.createBrandModelColumns()}
                                        dataSource={this.state.models}
                                        getEditFieldsValue={this.getEditBrandModelFieldsValue}
                                        modalItems={this.createBrandModelModalItems()}
                                        onRemoveClick={this.onRemoveBrandModelClick}
                                        onSaveClick={this.onUpdateBrandModelClick}
                                        onModalFinished={this.onCreateBrandModelClick}
                                        tableProps={this.getTableProps()}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>

            </div>
        )
    }

}

export default AnalystClientBrandBackup