import './index.less'
import { Image } from 'antd'
import React, { useEffect, useRef, useState, useMemo } from 'react'

import Map, { NavigationControl, Source, Layer, Popup } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import mapDate from './world.json'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import commonMessage from '../../../../locales/messages'
const EFCChartMap = (props) => {    // 国际化配置
    const intl = useIntl()
    let nameIntlKey = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const [activeItem, setActiveItem] = useState(0)
    const chartRef = useRef(null)
    const slideRef = useRef()
    const slideLeft = useRef([])

    const mapStyle = useRef({
        style: 'mapbox://styles/mapbox/light-v10',
        zoom: 2,
        center: [105, 35],
    })
    const matchExpression = [
        { name: [], color: 'rgb(168,196,215)' },
        { name: [], color: 'rgb(187,213,232)' },
        { name: [], color: 'rgb(202,228,247)' },
        { name: [], color: 'rgb(225,240,255)' },
        { name: [], color: 'rgb(255,255,255)' }
    ]

    const mode = useRef([
        {
            wait: '/images/map.png',
            active: '/images/map-active.png'
        }, {
            wait: '/images/bar.png',
            active: '/images/bar-active.png'
        }, {
            wait: '/images/time.png',
            active: '/images/time-active.png'
        }])

    const desc = props.chartDesc
    const title = props.chartTitle

    const convertData = (language) => {
        if (props.chartData) {
            let current = []
            let country = Object.keys(props.chartData)
            current = country.map((item, index) => {
                return { name: props.chartData[item].name[language], value: props.chartData[item].infringement, coordinates: props.chartData[item].coordinates }
            })
            return current
        } else {
            return []
        }
    }

    const renderTab = () => {
        return (
            <div className='ef-chart-title-select'>
                <div className='ef-chart-title-select-slide' ref={slideRef}></div>
                {mode.current.map((item, index) => {
                    return (
                        <div className='ef-chart-title-select-item' onClick={() => { onTabClick(index) }}>
                            <Image className='ef-chart-select-item-image' preview={false} src={index === activeItem ? item.active : item.wait} width={20}></Image>
                        </div>
                    )
                })}
            </div>)
    }

    const renderContent = () => {
        switch (activeItem) {
            case 0:
                return renderMap()
            case 1:
                return renderHorizontal()
            case 2:
                return renderAnnular()
            case null:
                return renderMap()
        }
    }

    const unmountChart = () => {
        if (chartRef.current) {
            let chartInstance = chartRef.current.getEchartsInstance()
            chartInstance.clear() // 卸载前清除画布数据
        }
    }

    const renderMap = () => {
        const chartData = convertData('en')
        if (chartData && chartData.length > 0) {
            let upperLimit = chartData[0].value
            let lowerLimit = chartData[chartData.length - 1].value
            let sum = 0
            chartData.map((item, index) => {
                sum += item.value
            })
            echarts.registerMap('WORLD', mapDate)

            console.log(chartData)

            // const option = {
            //     tooltip: {
            //         trigger: 'item',
            //         textStyle: {
            //             fontWeight: 'bold',
            //             fontSize: 14
            //         },
            //         extraCssText: 'text-align: center;',
            //         // formatter: function (params) {
            //         //     if (!params.value) {
            //         //         params.value = 0
            //         //     }
            //         //     return params.name + '<br>' + params.value + `(${(params.value / sum * 100).toFixed(2)}%)`
            //         // },
            //         formatter: function (params) {
            //             const data = params
            //             let value = 0
            //             if (isNaN(data['value']) === true) {
            //                 value = 0
            //             } else {
            //                 value = data['value']
            //             }
            //             let percent = value / sum * 100
            //             return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
            //                     <div style="font-weight: normal;font-size: 16px;color:#000000;font-family: 'Bebas';letter-spacing: 1px;">${value}(${percent.toFixed(2)}%)</div>`
            //         },
            //     },
            //     grid: {
            //         top: '0%',
            //         left: '0%',
            //         right: '0%',
            //         bottom: '0%',
            //         containLabel: true,
            //         borderColor: '#EBEDF2',
            //         show: false, // 是否显示网格线，默认值为 false
            //     },
            //     visualMap: {
            //         // type: 'piecewise', // 分段型视觉映射
            //         selectedMode: 'single', // 单选模式
            //         min: lowerLimit,
            //         max: upperLimit,
            //         text: ['High', 'Low'],
            //         realtime: false,
            //         calculable: true,
            //         show: false,
            //         inRange: {
            //             color: ['#CAE4F7', '#BBD5E8', '#A8C4D7']
            //         },
            //         zoomOnMouseWheel: true
            //     },
            //     series: [
            //         {
            //             type: 'map',
            //             map: 'WORLD',
            //             roam: true, // 开启拖拽和缩放
            //             center: [-0.1278, 51.5074], // 设置地图中心为中国的经纬度坐标
            //             zoom: 2,
            //             silent: false, 
            //             // 鼠标置上高亮颜色
            //             emphasis: {
            //                 itemStyle: {
            //                     areaColor: '#b7d5e7'
            //                 }
            //             },
            //             // 鼠标单击事件
            //             select: false,
            //             // 其他地图系列配置项
            //             itemStyle: {
            //                 // 普通状态下地图区域的样式
            //                 areaColor: '#ffffff',
            //                 borderColor: '#c5c5c5',
            //                 borderWidth: 0.5
            //             },
            //             // 关闭地图上的文本标签
            //             label: {
            //                 show: false
            //             },
            //             data: chartData.map((item, index) => {
            //                 return { name: item.name, value: item.value }
            //             }),
            //         }
            //     ]
            // }

            let sortData = chartData.sort((a, b) => {
                return b.value - a.value
            })
            let maxData = sortData ? sortData[0] : 1
            // matchExpression
            console.log(maxData)

            for (const row of chartData) {
                // Convert the range of data values to a suitable color
                if (row.value >= maxData.value * 0.9) {
                    if (row['name'].length > 0) {
                        matchExpression[0].name.push(row['name'])
                    }
                } else if (row.value >= maxData.value * 0.6) {
                    if (row['name'].length > 0) {
                        matchExpression[1].name.push(row['name'])
                    }
                } else if (row.value >= maxData.value * 0.3) {
                    if (row['name'].length > 0) {
                        matchExpression[2].name.push(row['name'])
                    }
                } else if (row.value >= 0) {
                    if (row['name'].length > 0) {
                        matchExpression[3].name.push(row['name'])
                    }
                } else {
                    if (row['name'].length > 0) {
                        matchExpression[4].name.push(row['name'])
                    }
                }
            }
            matchExpression[0].name.push("CHN")

            console.log(chartData)

            const features = Object.keys(chartData).filter((key) => chartData[key].value && chartData[key].coordinates)
                .map((name) => ({
                    type: 'Feature',
                    properties: {
                        count: chartData[name].value,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: chartData[name].coordinates,
                    },
                }))

            const sourceData = {
                type: 'FeatureCollection',
                features: features,
            }

            return (
                // <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }} lazyUpdate={true} notMerge={true} ref={chartRef}></ReactEcharts>
                <Map
                    mapboxAccessToken='pk.eyJ1IjoiY29uZ2NoaWNhZ28iLCJhIjoiY2t4c3V2b2NvNTBoajJ1cHpyb2l0bHZ3MiJ9.2Jkc9_TADo_YzhHZxm46Lw'
                    initialViewState={{
                        longitude: mapStyle.current.center[0],
                        latitude: mapStyle.current.center[1],
                        zoom: mapStyle.current.zoom
                    }}
                    style={{ width: '100%', height: 315 }}
                    mapStyle={mapStyle.current.style}
                >
                    <Source id="country-point" type='geojson' data={sourceData}>
                        <Layer
                            id="circle-layer"
                            type="circle"
                            // source-layer='country-point'
                            source='country-point'
                            paint={{
                                'circle-color': [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'count'],
                                    100,
                                    '#f39b90',
                                    200,
                                    '#ff1e00',
                                ],
                                'circle-opacity': 0.75,
                                'circle-radius': [
                                    'interpolate',
                                    ['linear'],
                                    ['get', 'count'],
                                    100,
                                    10,
                                    200,
                                    15,
                                ],
                            }}
                        />
                        <Layer
                            id='label-layer'
                            type='symbol'
                            source='country-point'
                            layout={{
                                'text-field': ['get', 'count'],
                                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                                'text-size': 12,
                            }}
                            paint={{
                                'text-color': 'rgba(0,0,0,0.5)',
                            }}
                        />
                    </Source>
                    {/* <Source id='countries' type='vector' url='mapbox://mapbox.country-boundaries-v1'>
                        {Array.isArray(matchExpression) && matchExpression.length > 0 ?
                            matchExpression.map((item, index) => {
                                console.log(item)
                                return <Layer
                                    id={'countries-join' + index}
                                    source-layer='country_boundaries'
                                    source='country_boundaries'
                                    type='fill'
                                    paint={{
                                        'fill-color': item.color,
                                        'fill-outline-color': '#ffffff',
                                    }}
                                    filter={
                                        ['in',
                                            "name",
                                            ...item.name]
                                        // [
                                        //     'match',
                                        //     ['get', 'country_name'],
                                        // ]
                                    }
                                />
                            })
                            : ''}
                    </Source> */}
                    <NavigationControl />
                </Map>
            )
        } else {
            return <div></div>
        }
    }

    const renderHorizontal = () => {
        const chartData = convertData(nameIntlKey)
        if (chartData && chartData.length > 0) {
            let start, end
            if (chartData.length >= 7) {
                start = chartData[0].name
                end = chartData[6].name
            } else {
                start = chartData[0].name
                end = chartData[chartData.length - 1].name
            }
            const option = {
                // grid自适应设置 + 外部网格线
                grid: {
                    top: '0%',
                    left: '0%',
                    right: '8%',
                    bottom: '2%',
                    containLabel: true,
                    borderColor: '#EBEDF2',
                    show: false, // 是否显示网格线，默认值为 false
                },
                tooltip: {
                    title: 'country',
                    trigger: 'item',
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    extraCssText: 'text-align: center;',
                    formatter: function (params) {
                        const data = params
                        return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
                                <div style="font-weight: normal;font-size: 16px;color:#000000;font-family: 'Bebas';letter-spacing: 1px;">${data['value']}</div>`
                    },
                },
                // x轴设置
                xAxis: [
                    {
                        type: "value",
                        show: true,
                        // 内部格线
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#EBEDF2',
                                type: 'dotted',
                                width: 2
                            }
                        },
                        // boundaryGap: ['10%',0 ], // 设置留白为 5px
                        // scale :true
                        // minInterval: 1
                    },
                ],
                // y轴设置
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        data: chartData.map(item => item.name),
                        // 刻度线
                        axisTick: {
                            show: false,
                            alignWithLabel: false,
                            inside: true,
                            length: 5,
                            lineStyle: {
                                color: '#EBEDF2',
                                width: 1,
                                type: 'solid'
                            }
                        },
                        offset: 0,
                        borderType: 'dotted',
                        textBorderDashOffset: '12',
                        nameRotate: 300,
                        // y轴标签
                        axisLabel: {
                            rotate: 0,
                            margin: 20,
                            color: '#595C72'
                        },
                        // y轴轴线
                        axisLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#EBEDF2'
                            }
                        }
                    }, {
                        type: "category",
                        // 排序方式
                        inverse: true,
                        position: 'left',
                        data: chartData.map((item, index) => item = item.name),
                        axisTick: {
                            show: true,
                            alignWithLabel: false,
                            inside: true,
                            length: 5,
                            lineStyle: {
                                color: '#EBEDF2',
                                width: 1,
                                type: 'solid'
                            }
                        },
                        offset: 10,
                        borderType: 'dotted',
                        textBorderDashOffset: '12',
                        nameRotate: 300,
                        // y轴标签
                        axisLabel: {
                            show: false,
                            rotate: 0,
                            margin: 20,
                            color: '#595C72'
                        },
                        // y轴轴线
                        axisLine: {
                            show: true,
                            lineStyle: {
                                type: 'solid',
                                color: '#EBEDF2'
                            }
                        }
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        minValueSpan: 6,
                        start: 0,
                        end: 6, // 最多显示7条数据，索引从0开始
                        left: '95%',
                        orient: 'vertical',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                series: [
                    {
                        name: "No Action",
                        type: "bar",
                        data: chartData.map(item => item.value),
                        // 条形上面文字
                        label: {
                            show: true,
                            position: "right",
                            color: "#333",
                        },
                        barCategoryGap: '20%',
                        borderType: 'dashed',
                        barWidth: 15,
                        // 设置柱子的颜色
                        itemStyle: {
                            color: "#1880DE"
                        },
                        // 鼠标置上条形
                        emphasis: {
                            itemStyle: {
                                borderColor: '#333', // 柱形顶部横线颜色
                                borderWidth: 1, // 柱形顶部横线宽度
                                borderType: 'solid', // 柱形顶部横线类型
                            },
                        },
                    },
                ],
            }
            return (
                <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%', padding: '0 24px 10px 24px' }} lazyUpdate={true} notMerge={true} ref={chartRef}></ReactEcharts>
            )
        } else {
        }
    }

    const renderAnnular = () => {
        const chartData = convertData(nameIntlKey)
        if (chartData && chartData.length > 0) {
            var option1 = {
                color: [
                    "#1880DE", '#2F8CE1', '#4699E5', '#5DA6E7', '#74B3EB', '#8BBFEE', '#A3CCF2', '#B9D8F5', '#D1E6F8', '#E7F2FB'
                ],
                title: {
                    left: 'center',
                    textStyle: {
                        color: '#999',
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                grid: {
                    top: '0%',
                    left: '0%',
                    bottom: '5%',
                    containLabel: true,
                    borderColor: '#EBEDF2',
                    show: false, // 是否显示网格线，默认值为 false
                },
                tooltip: {
                    title: 'country',
                    trigger: 'item',
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    extraCssText: 'text-align: center;',
                    formatter: function (params) {
                        const data = params
                        return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
                                <div style="font-weight: normal;font-size: 16px;color:#000000;font-family: 'Bebas';letter-spacing: 1px;">${data['value']}(${data['percent']}%)</div>`
                    },
                },
                series: [chartData].map(function (data, idx) {
                    var top = idx * 33.3
                    return {
                        name: 'No Action',

                        type: 'pie',
                        radius: [38, 72],
                        top: top + '%',
                        height: '100%',
                        left: 'center',
                        width: '100%',
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1,
                        },
                        label: {
                            // alignTo: 'edge',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 10,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            }
                        },
                        labelLine: {
                            length: 20,
                            length2: 25,
                            maxSurfaceAngle: 80
                        },
                        data: chartData
                    }
                })
            }
            return <ReactEcharts option={option1} echarts={echarts} style={{ width: '100%', height: '100%', paddingBottom: '20px' }} ref={chartRef} lazyUpdate={true} notMerge={true} ></ReactEcharts>
        } else {
            <div></div>
        }
    }

    const onTabClick = (index) => {
        props.onTabClick(index)
        if (index !== activeItem) {
            setActiveItem(index)
            slideRef.current.style.left = slideLeft.current[index] + 'px'
        }
    }

    useEffect(() => {
        /**
         * Tab
         */
        for (let i = 0; i < mode.current.length; i++) {
            slideLeft.current[i] = i * 56 + (i + 1) * 3
        }
        if (slideRef.current) {
            slideRef.current.style.left = slideLeft.current[0] + 'px'
        }
        return (() => {
            unmountChart()
        })
    }, [])

    return (
        <div
            className="ef-chart-map"
            style={{
                height: props.height ? props.height + 'px' : '391px',
            }}>
            <div className="ef-title-regular ef-chart-title">
                <p className='ef-chart-title-text'>{title && <FormattedMessage id={title} />}</p>
                {renderTab()}
            </div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 72 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFCChartMap
