import './index.less'
import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { FormattedMessage } from 'react-intl'
const EFCChartHbar = (props) => {
    const title = props.chartTitle
    const desc = props.chartDesc
    const chartData = props.chartData
    const color = ["#0069EB", "#7DCDD2", "#FFAA8E", "#949AFF", "#FBC48A", "#4C96F1", "#99C3F7"]
    let array = {}

    const getData = (index) => {
        let keys = Object.keys(chartData)
        let value = []
        let sites = Object.keys(array)

        keys.map((item) => {
            let indx = chartData[item].find(site => {
                return site.name === sites[index]
            })  
            if(indx) {
                value.push(indx.value)
            }else {
                value.push(0)
            }
        })
        return value
    }

    const renderContent = () => {
        if (chartData && Object.keys(chartData).length > 0) {
            let xAxis = Object.keys(chartData)
            Object.keys(chartData).map((item,index) => {
                chartData[item].map((item1,index1) =>{
                    if(!array[item1.name]){
                        array[item1.name] = index
                    }
                })
            })
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    fontFamily: 'Bebas', // 设置字体为Bebas
                    formatter: function (params) {
                        var res = `${params[0].name} <br/>`
                        for (const item of params) {
                            if (item.value !== 0) {
                                res += `<div><span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> <span>${item.seriesName} ：</span><span style="font-family:'Bebas';font-weight:600;letter-spacing: 1px;font-size:14px;">${item.value}</span></div>`
                            }
                        }
                        return res
                    }
                },
                legend: {
                    show: false,
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#595C72',
                    },
                    bottom: 4,
                    itemGap: 15,
                    itemWidth: 13,
                    itemheight: 7
                },
                grid: {
                    top: '7%',
                    left: '2%',
                    right: '2%',
                    bottom: '16%',
                    containLabel: true
                },
                dataZoom: [
                    {
                        start: 0,
                        end: 6, // 最多显示7条数据，索引从0开始
                        type: 'slider',
                        show: true,
                        minValueSpan: 6,
                        top: '88%',
                        height: 27,
                        orient: 'horizontal',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                xAxis: {
                    type: 'category',
                    data: xAxis,
                    offset: 3,
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#EBEDF2',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDCE0',
                            width: 1.5
                        }
                    },
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 12
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 10
                    },
                    offset: 10,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#EBEDF2',
                            type: 'dotted',
                            width: 2
                        }
                    },
                },
                series:
                    Object.keys(array).map((item, index) => {
                        return {
                            name: item,
                            type: 'bar',
                            stack: 'total',
                            // 不在柱条内部显示数据
                            label: {
                                show: false,
                            },
                            barCategoryGap: '64%',
                            emphasis: {
                                focus: 'series'
                            },
                            data: getData(index),
                            itemStyle: {
                                color: color[index]
                            },
                        }
                    }),
                    
            }
            return <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }}></ReactEcharts>
        } else if(typeof chartData === 'object') {
            let array = { current: { value: 0,name: null } }
            let xAxis = [0,1,2,3,4]
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    fontFamily: 'Bebas', // 设置字体为Bebas
                    formatter: function (params) {
                        var res = `${params[0].name} <br/>`
                        for (const item of params) {
                            if (item.value !== 0) {
                                res += `<div><span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> <span>${item.seriesName} ：</span><span style="font-family:'Bebas';font-weight:600;letter-spacing: 1px;font-size:14px;">${item.value}</span></div>`
                            }
                        }
                        return res
                    }
                },
                legend: {
                    show: false,
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#595C72',
                    },
                    bottom: 4,
                    itemGap: 15,
                    itemWidth: 13,
                    itemheight: 7
                },
                grid: {
                    top: '7%',
                    left: '2%',
                    right: '2%',
                    bottom: '16%',
                    containLabel: true
                },
                dataZoom: [
                    {
                        start: 0,
                        end: 6, // 最多显示7条数据，索引从0开始
                        type: 'slider',
                        show: true,
                        minValueSpan: 6,
                        top: '88%',
                        height: 27,
                        orient: 'horizontal',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                xAxis: {
                    type: 'category',
                    data: xAxis,
                    offset: 3,
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#EBEDF2',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDCE0',
                            width: 1.5
                        }
                    },
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 12
                    },
                },
                yAxis: {
                    type: 'value',
                    data: [0,10,20,30,40],
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 10
                    },
                    offset: 10,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#EBEDF2',
                            type: 'dotted',
                            width: 2
                        }
                    },
                },
                series:
                    Object.keys(array).map((item, index) => {
                        return {
                            name: item,
                            type: 'bar',
                            stack: 'total',
                            // 不在柱条内部显示数据
                            label: {
                                show: false,
                            },
                            barCategoryGap: '64%',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [0],
                            itemStyle: {
                                color: color[index]
                            },
                        }
                    }),
                    
            }
            return <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }}></ReactEcharts>
        }
    }

    return (
        <div className='ef-chart-horizontal-bar'
            style={{
                height: props.height ? props.height + 'px' : '391px',
            }}>
            <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFCChartHbar