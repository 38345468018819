import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import { Button, Dropdown, Image } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { render } from 'less'
import { FormattedMessage, useIntl } from "react-intl"
const EFCContainer = (props) => {
  const slideRef = useRef()
  const containerRef = useRef()
  const slideLeft = useRef([])
  const [tabActive, setTabActive] = useState(props.tabActiveInit !== undefined ? props.tabActiveInit : 0)
  const tabInit = useRef()
  const [userActive, setUserActive] = useState(0)
  const gap = useRef()

  // 监听父组件掉过来的tab位置初始化,即是初始化便只能监听一次,目前既支持静态设置tab初始化位置,也支持动态接收
  useEffect(() => {
    if (tabInit.current === undefined && props.tabActiveInit !== undefined) {
      tabInit.current = props.tabActiveInit
      setTabActive(tabInit.current)
    }
  })

  const tabCondition = () => {
    return !props.renderTab || (props.renderTab.item && props.renderTab.item.length == 0)
  }

  const onTabClick = (index) => {
    if (index !== tabActive) {
      setTabActive(index)
      slideRef.current.style.left = slideLeft.current[index] + 'px'
      if (props.onTabClick) {
        props.onTabClick(index)
      }
    }
  }

  const onUserClick = (index) => {
    if (index !== userActive) {
      setUserActive(index)
      if (props.onUserClick) {
        props.onUserClick()
      }
    }
  }

  const renderTab = () => {
    if (props.renderTab && props.renderTab.item && props.renderTab.item.length > 0) {
      // mode -> card:line
      const renderAlternative = () => {
        if (props.renderTab.alternative && props.renderTab.alternative !== 0) {
          if (nameIntlKey === 'en') {
            return <div className="ef-tabs-cover ef-text-regular">
              <p>{<FormattedMessage id="app.ui.eclist.table.batch_choose" />}</p><p className='ef-active-color'>{'\u00A0'}{props.renderTab.alternative}{'\u00A0'}</p><p><FormattedMessage id="app.us.eclist.table.batch_items" />{','}</p><p style={{ cursor: 'pointer' }} className='ef-active-color' onClick={() => props.renderTab.alterClick()}>{'\u00A0'}{'\u00A0'}<FormattedMessage id="app.ui.eclist.table.modify" /></p>
            </div>
          } else if (nameIntlKey === 'ja') {
            return <div className="ef-tabs-cover ef-text-regular">
              <p className='ef-active-color'>{props.renderTab.alternative}{'\u00A0'}</p><p><FormattedMessage id="app.us.eclist.table.batch_items" /></p><p>{<FormattedMessage id="app.ui.eclist.table.batch_choose" />}{','}</p><p className='ef-active-color' style={{ cursor: 'pointer' }} onClick={() => props.renderTab.alterClick()}>{'\u00A0'}{'\u00A0'}<FormattedMessage id="app.ui.eclist.table.modify" /></p>
            </div>
          }
        } else {
          return <div></div>
        }
      }

      const className = `${props.renderTab.mode === 'card' ? 'ef-tabs-card' : 'ef-tabs'} ${props.renderTab.mode === 'line' ? 'ef-tabs-line' : 'ef-tabs'}`
      return (
        <div className={className}>
          <div
            className="ef-tabs-container">
            {renderAlternative()}
            {props.renderTab.item.map((Item, index) => {
              let classAddition = null
              if(props.renderTab.disable && props.renderTab.disable.includes(index)) {
                classAddition = 'ef-tabs-item-disable'
              }
              return (
                <div
                  className={`ef-tabs-item ${index === tabActive
                    ? 'ef-active-color ef-tabs-item-active'
                    : 'ef-tabs-item-deactive'
                    } ${classAddition}`}
                  style={{
                    opacity: (props.renderTab.alternative && props.renderTab.alternative !== 0) ? '0' : '1',
                    pointerEvents: (props.renderTab.alternative && props.renderTab.alternative !== 0) ? 'none' : 'auto'
                  }}
                  onClick={() => {
                    if(!classAddition) {
                      onTabClick(index)
                    }
                  }}>
                  {Item}
                </div>
              )
            })
            }
            <div className={`${props.renderTab.alternative && props.renderTab.alternative > 0 ? 'ef-tabs-slide-wait' : 'ef-tabs-slide'}`} ref={slideRef}></div>
            {renderUser()}
            {renderSideFun()}
          </div >
        </div >
      )
    } else {
      return <div></div>
    }
  }

  const renderDecorationLine = () => {
    if (props.renderTab && props.renderTab.mode === 'card') {
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current)
        const paddingLeft = computedStyle.getPropertyValue('padding-left')
        const paddingRight = computedStyle.getPropertyValue('padding-right')
        let width = containerRef.current.clientWidth
        return <div
          className='ef-tabs-card-line'
          style={{
            width: width + 'px',
            marginLeft: parseInt(paddingLeft) * -1 + 'px',
            marginRight: parseInt(paddingRight) * -1 + 'px',
          }}>
        </div>
      }
    } else {
      return ''
    }
  }

  let isEmpty = useMemo(() => {
    let obj = { state: false, content: null }
    if (props.hasOwnProperty('isEmpty')) {
      obj['state'] = props.isEmpty
    }
    if (props.hasOwnProperty('localeEmpty')) {
      obj['content'] = props.localeEmpty
    }
    return obj
  })

  let nameIntlKey = useMemo(() => {
    if (props.hasOwnProperty('nameIntlKey')) {
      return props.nameIntlKey
    } else {
      return
    }
  })

  let items = useMemo(() => {
    if (props.user && props.user.items && props.user.items.length > 0) {
      return props.user.items.map((item, index) => ({
        key: String(index),
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              item === '1st menu item'
                ? 'https://www.antgroup.com'
                : 'https://www.aliyun.com'
            }
            onClick={() => {
              onUserClick(index)
            }}>
            {item}
          </a>
        ),
      }))
    } else {
      return [
        {
          // key: '1',
          // label: (
          //   <a
          //     target="_blank"
          //     rel="noopener noreferrer"
          //     href="https://www.antgroup.com">
          //     1st menu item
          //   </a>
          // ),
        },
      ]
    }
  }, [props])

  const renderUser = () => {
    if (props.user && props.user.label) {
      return (
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          arrow={{
            pointAtCenter: true,
          }}>
          <div className="ef-users ef-text-normal ef-active-color">
            <UserOutlined className="ef-user-img" style={{ marginRight: '5px' }}/>
            <div className="ef-user-text">{props.user.label}</div>
          </div>
        </Dropdown>
      )
    }
  }

  const renderSideFun = () => {
    if (props.side && props.side.length > 0) {
      return (
        <div className='ef-side'>
          {props.side.map((item, index) => {
            return (<div style={{ display: item.show === true ? 'block' : 'none' }}><Button className="ef-header-button" icon={item.state !== -1 ? item.icon[item.state] : ''} size="large" type="text" onClick={() => { props.side[index].fun() }}></Button></div>)
          })}
        </div>
      )
    }
  }

  const renderContent = () => {
    if (tabCondition()) {
      return <div className="ef-content">{props.children}</div>
    } else {
      return (
        <div
          className="ef-content">
          {props.children}
        </div>
      )
    }
  }

  useEffect(() => {
    if (props.renderTab && props.renderTab.item && props.renderTab.item.length > 0) {
      if (props.renderTab.mode === 'card') {
        if (slideRef.current) {
          const siblings = Array.from(slideRef.current.parentNode.children)
            .filter(element => element.classList.contains('ef-tabs-item'))
          for (let a = 0; a < props.renderTab.item.length; a++) {
            if( a > 0) {
              slideLeft.current[a] = (siblings[a-1].offsetWidth/2 + 8) + siblings[a].offsetWidth/2 + slideLeft.current[a-1]
            } else {
              slideLeft.current[a] = siblings[a].offsetWidth/2 - 16
            }
          }
          slideRef.current.style.left = slideLeft.current[tabActive] + 'px'
        }
      } else if (props.renderTab.mode === 'line') {
        if (slideRef.current) {
          const siblings = Array.from(slideRef.current.parentNode.children)
            .filter(element => element.classList.contains('ef-tabs-item'))
          let now = 0 - slideRef.current.offsetWidth / 2
          for (let a = 0; a < props.renderTab.item.length; a++) {
            const style = window.getComputedStyle(siblings[a])
            slideLeft.current[a] = now + (siblings[a].offsetWidth) / 2
            now += (siblings[a].offsetWidth) + parseInt(style.getPropertyValue('margin-right'))
          }
          slideRef.current.style.left = slideLeft.current[tabActive] + 'px'
        }
      }
    }
  }, [props])

  const renderUi = () => {
    return (
      <div className='ef-container-content-wrap'>
        {renderTab()}
        {renderContent()}
      </div>
    )
  }

  const renderIsEmpty = () => {
    if (isEmpty['content']) {
      return <div className='ef-container-empty'>
        {isEmpty['content']}
      </div>
    } else {
      return (
        <div className='ef-container-empty'>
          <Image className='ef-container-empty-image' width={136} height={106} src={'/images/content-empty.png'} preview={false} />
          <p className='ef-container-empty-title' ><FormattedMessage id="app.emptycontent.title" /></p>
          <text className='ef-container-empty-text' ><FormattedMessage id="app.emptycontent.text" /></text>
        </div>
      )
    }
  }

  return (
    <div
      className={`ef-container ${props.className ? props.className : ''}`}
      ref={containerRef}
      style={{
        // 56 是breadCrumbs的高度
        minHeight: (props.overspread !== undefined) ? `calc(100% - ${56 + props.overspread}px)` : '0px',
        height: (props.overspread === undefined && props.height) ? props.height + 'px' : 'auto',
        ...props.style
      }}>
      {renderDecorationLine()}
      {isEmpty.state === false ?
        renderUi()
        : renderIsEmpty()}
    </div>
  )
}
export default EFCContainer
