// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.info.reset_password_success": {
        id: "app.ui.user_list_detail.info.password_success",
        defaultMessage: "パスワードをリセットしました。",
    },
    "message.error.reset_password_failed": {
        id: "app.ui.user_list_detail.error.password_failed",
        defaultMessage: "パスワードのリセットを失敗しました。",
    },
    "message.error.password_failed": {
        id: "app.ui.user_list_new.error.password_failed",
        defaultMessage: "パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。",
    },
    "message.error.password2_failed": {
        id: "app.ui.user_list_new.error.password2_failed",
        defaultMessage: "新パスワードと新パスワード（確認）は同じではなく、確認してください。",
    },
    "message.error.password_empty": {
        id: "app.ui.user_list_reset_password.error.password_empty",
        defaultMessage: "新パスワードを入力ください。",
    },
    "message.error.password2_empty": {
        id: "app.ui.user_list_reset_password.error.password2_empty",
        defaultMessage: "新パスワード（確認）を入力ください。",
    },
})

export default messages