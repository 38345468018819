import { WSCategoryType ,WSTakedownRequest } from "../../../master-data/ws-master-data";
import common_messages from '../../../locales/messages';

export const getBatchUpdateItems = (isAnalyste,intl) => {
    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])

    if (isAnalyste) {
        return WSCategoryType.map((item) => {
            return {
                label: item.name[localeKey],
                value: item.value,
                key: item.value
            }
        })
    } else {
        return WSTakedownRequest.map((item) => {
            return {
                label: item.name[localeKey],
                value: item.value,
                key: item.value
            }
        })
    }
}
