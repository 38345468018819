import request from '@/utils/request'

// {
// 	"name":"会社N",
//     "tel":"1232123",
//      "managerIds":[1],

//     "address":"xxxxxaddress",
//     "remarks":"xxxxxremarks",
// 	"ec":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "sns":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "ws":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "other":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	}
// }
export function reqCreateClient(data) {
    return request({
        url: '/api/admin/create-client',
        method: 'post',
        data: data
    })
}

// {
// 	"clientId":14
// }
export function reqRemoveClient(data) {
    return request({
        url: '/api/admin/remove-client',
        method: 'post',
        data: data
    })
}

// {
    // "clientId":14,
// 	"name":"会社N",
//     "tel":"1232123",
//      "managerIds":[1],

//     "address":"xxxxxaddress",
//     "remarks":"xxxxxremarks",
// 	"ec":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "sns":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "ws":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	},
//     "other":{
// 		"startDate":"1990-09-11",
// 		"endDate":"2022-02-03",
// 		"fee":2222
// 	}
// }

export function reqUpdateClient(data){
    return request({
        url: '/api/admin/update-client',
        method: 'post',
        data: data
    })
}

export function reqSearchClients(data){
    return request({
        url: '/api/admin/search-clients',
        method: 'post',
        data: data
    })
}

// {
// 	"clientId":16
// }
export function reqGetClient(data){
    return request({
        url: '/api/admin/get-client',
        method: 'post',
        data: data
    })
}

// {
// 	"clientId":16
// }
export function reqFetchServiceHistories(data){
    return request({
        url: '/api/admin/fetch-service-histories',
        method: 'post',
        data: data
    })
}