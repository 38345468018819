import "./index.less"
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Image, Button, Drawer, Typography, Spin, Collapse, Tooltip, Space } from "antd"

import { setBreadcrumbs, setContentContainerHeaderAction } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { reqSearchNotices, reqGetNoticeFilters, reqViewNotices } from "@/api/notice-center.js"

import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages.js"
import ECListCommon from "../ec/common/ec-list-common.js"
import { NoticeType, UrlParams } from "./notice-config.js"

// component reference dependency
import EFCContainer from '../../components/ef-c-container/index.jsx'
import EFCSearchBar from '../../components/ef-c-search-condition/index.jsx'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Panel } = Collapse
const NoticeCenterView = (props) => {

    const intl = useIntl()

    const defaultPageSize = useRef(10)
    const [pageSize, setPageSize] = useState(10)
    const [pageCurrent, setPageCurrent] = useState(1)

    const [filter, setFilter] = useState(false)
    const filterColumns = useRef([
        {
            title: <FormattedMessage id="app.ui.notification_center.table.column.type" />,
            key: 'notice_type',
            items: NoticeType.map((item) => {
                return {
                    name: item.name[intl.formatMessage(messages["notice_type.locale_key"])],
                    value: item.value
                }
            })
        }, {
            title: <FormattedMessage id="app.ui.notification_center.table.column.viewed" />,
            key: 'notice_viewed',
            items: [{
                name: intl.formatMessage({ id: 'app.common.label.yes' }),
                value: true
            }, {
                name: intl.formatMessage({ id: 'app.common.label.no' }),
                value: false
            }]
        }
    ])
    const filterItems = useRef(filterColumns.current.map((item, index) => {
        let array = []
        item.items.map((item, index) => {
            array[index] = false
        })
        return array
    }))
    const lastSorter = useRef({ order: undefined, field: undefined })

    const preFilterItems = useRef([])
    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(preState => !preState)
    }, [])

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()
    const locationSearch = useRef()
    useEffect(() => {
        locationSearch.current = location.search
    }, [location])

    const [dataSource, setDataSource] = useState([])
    const total = useRef(0)
    const isLoading = useRef(false)
    const unviewed = useRef(0)
    const [collapse, setcollapse] = useState(false)

    const searchBarDescribe = useRef([
        'app.components.ef_search_bar.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])

    const deepCopy = (obj) => {
        if (obj === null || typeof obj !== 'object') {
            return obj
        }
        const copy = Array.isArray(obj) ? [] : {}
        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                copy[key] = deepCopy(obj[key])
            }
        }
        return copy
    }

    const getDateSearchItems = () => {
        return [
            {
                name: intl.formatMessage({ id: 'app.ui.notification_center.table.column.send_date' }),
                value: "createdAt"
            },
        ]
    }

    const getKeywordSearchItems = () => {
        return [
            {
                name: intl.formatMessage({ id: 'app.ui.notification_center.table.column.title' }),
                value: "title"
            },
            {
                name: intl.formatMessage({ id: 'app.ui.notification_center.table.column.content' }),
                value: "content"
            },
        ]
    }

    const onHandleDetail = (record) => {
        if (record.viewed === false) {
            reqViewNotices({ noticeId: record.id })
            onHandleSearch()
        }
        window.open(record.target_url, 'target')
    }

    const onHandlePageChange = (page, pageSize) => {
        setPageCurrent(page)
        setPageSize(pageSize)
        let pagination = {
            offset: 0,
            limit: 0
        }
        pagination['offset'] = (page - 1) * pageSize
        pagination['limit'] = pageSize
        let searchParams = new URLSearchParams(locationSearch.current)
        searchParams.set('offset', encodeURIComponent(JSON.stringify(pagination.offset)))
        searchParams.set('limit', encodeURIComponent(JSON.stringify(pagination.limit)))
        history.push({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`
        })
        let params = UrlParams()
        params['offset'] = pagination.offset
        params['limit'] = pagination.limit
        searchBySearchParams(params)
    }

    const onHandleFilterChange = () => {
        let filter = []
        filterItems.current.map((item, index) => {
            let array = []
            let active = false
            item.map((current, index1) => {
                if (current === true) {
                    array.push(filterColumns.current[index].items[index1].value)
                    active = true
                }
            })
            if (active) {
                filter.push({ key: filterColumns.current[index].key, values: array })
            }
        })
        if (filter.length > 0) {
            let searchParams = new URLSearchParams(locationSearch.current)
            searchParams.set('whereFields', encodeURIComponent(JSON.stringify(filter)))
            history.push({
                pathname: location.pathname,
                search: `?${searchParams.toString()}`
            })
            let params = UrlParams()
            params['whereFields'] = filter
            searchBySearchParams(params)
        } else {
            let searchParams = new URLSearchParams(locationSearch.current)
            searchParams.delete('whereFields')
            history.push({
                pathname: location.pathname,
                search: `?${searchParams.toString()}`
            })
            let params = UrlParams()
            params['whereFields'] = null
            searchBySearchParams(params)
        }
    }

    const onHandleTableChange = (pagination, filters, sorter, extraInfo) => {
        let searchParams = new URLSearchParams(locationSearch.current)
        if (sorter && sorter.field) {
            let params = UrlParams()
            if (sorter.order !== lastSorter.current.order || sorter.field !== lastSorter.current.field) {
                if (sorter.order) {
                    let sortInfo = {}
                    sortInfo["key"] = sorter.field
                    sortInfo.value = sorter.order === "ascend" ? 1 : 2
                    searchParams.set("orderFields", encodeURIComponent(JSON.stringify([sortInfo])))
                    params['orderFields'] = [sortInfo]
                    searchBySearchParams(params)
                } else {
                    searchParams.delete("orderFields")
                    params['orderFields'] = null
                    searchBySearchParams(params)
                }
                history.push({
                    pathname: location.pathname,
                    search: `?${searchParams.toString()}`
                })
                lastSorter.current.order = sorter.order
                lastSorter.current.field = sorter.field
            }
        }
    }

    const searchBySearchParams = (params) => {
        let searchParams = new URLSearchParams(locationSearch.current)
        let condition = {}
        Object.keys(params).map((item, index) => {
            if (params[item] === undefined) {
                let current = {}
                current[item] = JSON.parse(decodeURIComponent(searchParams.get(item)))
                condition = { ...condition, ...current }
            } else {
                condition[item] = params[item]
            }
        })
        onHandleSearch(condition)
    }

    const onHandleFilterCancel = () => {
        filterItems.current = deepCopy(preFilterItems.current)
        preFilterItems.current = []
        setFilter(false)
    }

    const onHandleFliterOpen = () => {
        setFilter(true)
    }

    const onHandleFilterClick = (index1, index2) => {
        filterItems.current[index1][index2] = !filterItems.current[index1][index2]
        toggleLoading()
    }

    const onHandleFilterCheck = () => {
        preFilterItems.current = []
        setFilter(false)
        onHandleFilterChange()
        toggleLoading()
    }

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(location.search)
        history.push(searchParams)
    }

    const onHandleSearchBarClick = (value) => {
        let params = UrlParams()
        Object.keys(params).map((item, index) => {
            if (value[item]) {
                params[item] = value[item]
            }
        })
        params['offset'] = 0
        params['limit'] = pageSize
        let searchParams = new URLSearchParams(locationSearch.current)
        searchParams.set('offset', encodeURIComponent(JSON.stringify(0)))
        searchParams.set('offset', encodeURIComponent(JSON.stringify(pageSize)))
        history.push({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`
        })
        searchBySearchParams(params)
    }

    const onHandleSearch = (values, type) => {
        isLoading.current = true
        toggleLoading()
        let conditions = {}
        Object.keys(values).map((item, index) => {
            if (values[item] !== undefined && values[item] !== null) {
                conditions[item] = values[item]
            }
        })
        reqSearchNotices({ ...conditions }).then((resp) => {
            isLoading.current = false
            total.current = resp.data.data.total
            unviewed.current = resp.data.data.unviewed
            setDataSource(resp.data.data.notices)
        }).catch((e) => {
            isLoading.current = false
            toggleLoading()
        })
    }

    const onUrlRender = () => {
        const searchParams = new URLSearchParams(location.search)
        const params = {}
        for (const [key, value] of searchParams.entries()) {
            params[key] = JSON.parse(decodeURIComponent(value))
        }
        return params
    }

    const onHandleChange = (value) => {
        let searchParams = new URLSearchParams(locationSearch.current)
        Object.keys(value).map((item, index) => {
            if (!value[item] || (Array.isArray(value[item]) && value[item].length === 0)) {
                searchParams.delete(item)
            } else {
                searchParams.set(item, encodeURIComponent(JSON.stringify(value[item])))
            }
        })
        history.push({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`
        })
    }

    const renderFilterSection = () => {
        return filterColumns.current.map((item, index1) => {
            return <div className="ef-filter-flat">
                <p className="ef-filter-title ef-title-describe">{item.title}</p>
                <div>
                    {item.items.map((params, index2) => {
                        return <div className={`ef-filter-item ef-text-normal ${filterItems.current[index1][index2] ? 'ef-filter-item-active' : ''}`}
                            onClick={() => { onHandleFilterClick(index1, index2) }}>{params.name}</div>
                    })}
                </div>
            </div>
        })
    }

    const renderDrawerFliter = () => {
        if (preFilterItems.current.length === 0) {
            preFilterItems.current = deepCopy(filterItems.current)
        }
        return (
            <div>
                <Drawer
                    className="ef-drawer"
                    placement={'right'}
                    closable={false}
                    title={<Typography.Title level={4} className='ef-title-regular'>
                        <FormattedMessage id="app.ui.eclist.table.result_filter" />
                    </Typography.Title>}
                    width={784}
                    onClose={() => { onHandleFilterCancel() }}
                    open={filter}
                    key={'right'}
                    footer={
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Button className='ef-button-linear' style={{ display: 'inline-block' }} onClick={onHandleFilterCancel}><FormattedMessage id="app.common.action.cancel"/></Button>
                            <Button className='ef-button-color' style={{ marginLeft: '16px', display: 'inline-block' }} onClick={onHandleFilterCheck}><FormattedMessage id="app.common.action.filter"/></Button>
                        </div>
                    }
                >
                    {renderFilterSection()}
                </Drawer>
            </div>
        )
    }

    const renderTableColumns = (onDetailClick) => {
        return [
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.id" />,
                dataIndex: "id",
                key: "notice_id",
                width: 80,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.title" />,
                dataIndex: "title",
                key: "notice_title",
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.content" />,
                dataIndex: "content",
                key: "notice_content",
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.type" />,
                dataIndex: "type",
                key: "notice_type",
                filters: NoticeType.map((item) => {
                    return {
                        text: item.name[intl.formatMessage(messages["notice_type.locale_key"])],
                        value: item.value
                    }
                }),
                onFilter: (value, record) => {
                    return value === record.type
                },
                filterMultiple: true,
                render: (value, record) => {
                    let item = NoticeType.find((notice) => {
                        return notice.value === value
                    })
                    return item.name[intl.formatMessage(messages["notice_type.locale_key"])]
                }
            },
            // {
            //     title: <FormattedMessage id="app.ui.notification_center.table.column.from" />,
            //     dataIndex: "from",
            //     key: "notice_from",
            // },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.send_date" />,
                dataIndex: "createdAt",
                key: "notice_send_date",
                sorter: true,
                render: (value, record) => {
                    return moment(value).format("YYYY-MM-DD")
                },
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.viewed" />,
                dataIndex: "viewed",
                key: "notice_viewed",
                sorter: true,
                width: 180,
                render: (value, record) => {
                    return value ? intl.formatMessage({ id: 'app.common.label.yes' }) : intl.formatMessage({ id: 'app.common.label.no' })
                },
                filters: [
                    {
                        text: intl.formatMessage({ id: 'app.common.label.yes' }),
                        value: true
                    },
                    {
                        text: intl.formatMessage({ id: 'app.common.label.no' }),
                        value: false
                    }
                ],
                onFilter: (value, record) => {
                    return value === record.viewed
                },
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                defaultShow: true,
                key: 'actions',
                fixed: "right",
                align: 'center',
                width: 150,
                render: (value, record) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                            <Button className="ef-sidefun"
                                onClick={() => {
                                    if (onDetailClick) {
                                        onDetailClick(record)
                                    }
                                }}
                                icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />}
                            >
                            </Button>
                        </Tooltip>
                    </Space>


                ),
            },
        ]
    }

    const renderBread = () => {
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setMenuActiveKey("notice"))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.notice" />,
                path: "/notice",
            },
        ]))
    }

    const renderNoticeData = async () => {
        isLoading.current = true
        toggleLoading()
        await reqSearchNotices({
            "offset": 0,
            "limit": defaultPageSize.current,
        }).then((resp) => {
            isLoading.current = false
            total.current = resp.data.data.total
            unviewed.current = resp.data.data.unviewed
            setDataSource(resp.data.data.notices)
        })
    }

    const renderInitUrl = () => {
        let searchParams = new URLSearchParams(locationSearch.current)
        if (searchParams.has("orderFields")) {
            searchParams.delete("orderFields")
        }
        if (searchParams.has("whereFields")) {
            searchParams.delete("whereFields")
        }
        searchParams.set('offset', encodeURIComponent(JSON.stringify(0)))
        searchParams.set('limit', encodeURIComponent(JSON.stringify(pageSize)))
        history.push({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`
        })
    }

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    useEffect(() => {
        renderInitUrl()
        renderBread()
        renderNoticeData()
    }, [])

    document.title = intl.formatMessage(messages["notice.title"])
    return <div className="notice-center ef-container-father">
        <EFCContainer>
            <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                <Panel header={<FormattedMessage id="app.ui.wslist.search.collapse" />}
                    key="1">
                    <EFCSearchBar
                        height={140}
                        date={getDateSearchItems()}
                        search={getKeywordSearchItems()}
                        locale={searchBarDescribe.current}
                        showItem={['date', 'search']}
                        onUrlRender={onUrlRender}
                        onHandleChange={(value) => { onHandleChange(value) }}
                        onHandleReset={onHandleReset}
                        onHandleSearch={(value) => { onHandleSearchBarClick(value) }}
                    >
                    </EFCSearchBar>
                </Panel>
            </Collapse>
        </EFCContainer>

        <EFCContainer
            overspread={collapse ? 42 : 202}
            style={{ padding: '21px 24px 0px 24px', marginTop: '20px' }}>
            <div className="notice-table-header">
                <div>
                    <span className="ef-text-regular">{"Total:" + total.current} </span>
                    <span className="ef-text-regular">{"Unviewed:" + unviewed.current} </span>
                </div>
                <Tooltip placement="top" title={<FormattedMessage id="app.ui.eclist.tooltip.filter" />} arrow={true}>
                    <Button
                        className="ef-sidefun"
                        icon={<IconFont className="iconfont iconfont-wait" type="icon-shaixuan" />}
                        onClick={() => { onHandleFliterOpen() }} />
                </Tooltip>
            </div>
            <Table
                className="ef-table"
                key="noticeTable"
                tableLayout="fixed"
                rowKey={(record) => {
                    return "notice_row_" + record.id
                }}
                columns={renderTableColumns(onHandleDetail)}
                dataSource={dataSource}
                onChange={onHandleTableChange}
                size="small"
                loading={isLoading.current}
                rowClassName={(record) => {
                    if (record.viewed) {
                        return ""
                    } else {
                        return "notice-unviewd-row"
                    }
                }}
                scroll={{
                    x: 'max-content',
                    y: 600
                }}
                pagination={{
                    size: 'default',
                    total: total.current,
                    current: pageCurrent,
                    pageSize: pageSize,
                    showQuickJumper: true,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    showSizeChanger: true,
                    locale: customLocale,
                    onChange: onHandlePageChange,
                    showTotal: (total) => customLocale.showTotal(total, pageSize)
                }}
            />
            {renderDrawerFliter()}
        </EFCContainer>
    </div>
}
export default NoticeCenterView