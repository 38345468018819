
export const onError = (values, role) => {
    if (role & 2) {
        if (!values['ec_categorize_date']) {
            return 'レポート提出日を入力してください。'
        }

        if (values.ec_process1_result && !values.ec_process1_date) {
            return '申告実施月(1回目)を入力してください。'
        }

        if (!values.ec_process1_result && values.ec_process1_date) {
            return '1回目申告結果を入力してください。'
        }

        if (values.ec_process2_result && !values.ec_process2_date) {
            return '申告実施月(2回目)を入力してください。'
        }

        if (!values.ec_process2_result && values.ec_process2_date) {
            return '2回目申告結果を入力してください。'
        }

        if (
            values.ec_process2_result &&
            values.ec_process2_date &&
            !values.ec_process1_result &&
            !values.ec_process1_date
        ) {
            return '申告情報(1回目)を入力してください。'
        }

        if (values.ec_process3_result && !values.ec_process3_date) {
            return '申告実施月(3回目)を入力してください。'
        }

        if (!values.ec_process3_result && values.ec_process3_date) {
            return '3回目申告結果を入力してください。'
        }

        if (values.ec_process3_result &&
            values.ec_process3_date &&
            !values.ec_process2_result &&
            !values.ec_process2_date
        ) {
            return '申告情報(2回目)を入力してください。'
        }

        if (values.ec_process_result && !values.ec_process_confirm_date) {
            return '最終結果確認日を入力してください。'
        }

        if (!values.ec_process_result && values.ec_process_confirm_date) {
            return '最終結果を入力してください。'
        }

        if (
            values.ec_process_result &&
            values.ec_process_confirm_date &&
            !values.ec_process3_result &&
            !values.ec_process3_date &&
            !values.ec_process2_result &&
            !values.ec_process2_date &&
            !values.ec_process1_result &&
            !values.ec_process1_date
        ) {
            return '申告情報(2回目)を入力してください。'
        }
        return 
    }
}