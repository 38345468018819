import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.info.create_success": {
        id: "app.ui.ec_task.message.info.create_success",
        defaultMessage: "成功しました。",
    },
    "message.error.create_fail": {
        id: "app.ui.ec_task.message.error.create_fail",
        defaultMessage: "失敗しました。",
    },
    
    "message.error.site_empty": {
        id: "app.ui.ec_task.message.error.site_empty",
        defaultMessage: "サイト名を入力ください。",
    },
    "message.error.keyword_empty": {
        id: "app.ui.ec_task.message.error.keyword_empty",
        defaultMessage: "キーワードを入力ください。",
    },
    "message.error.start_url_empty": {
        id: "app.ui.ec_task.message.error.start_url_empty",
        defaultMessage: "開始URLを入力ください。",
    },
    "message.error.target_count_empty": {
        id: "app.ui.ec_task.message.error.target_count_empty",
        defaultMessage: "希望件数を入力ください。",
    },
})  

export default messages