import "./index.less"
import React, { Component } from "react";
import { Form, Input, message } from "antd";
import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'

import { reqFetchProductCategories, reqCreateProductCategory, reqRemoveProductCategory, reqUpdateProductCategory } from '../../../api/analyst-product-category'
import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';

import { setProductCategoryAction } from "@/store/actions/ec-master.js"
import store from "@/store";

class AnalystClientProductCategory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchProductCategories({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.categories });
                    store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
                }
            }).catch((error) => {

            })

        }
        this.columns = this.createColumns()
    }

    createColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.id"}),
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.name"}),
                dataIndex: 'name',
                key: 'name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage(messages["messages.error.product_category.category_empty"]),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.name_en"}),
                dataIndex: 'name_en',
                key: 'name_en',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage(messages["messages.error.product_category.category_en_empty"]),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }

            },

            {
                title: this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.code"}),
                dataIndex: 'code',
                key: 'code',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"code_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: this.props.intl.formatMessage(messages["messages.error.product_category.code_empty"]),   
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText name="name" label={this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.name"})} rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(messages["messages.error.product_category.category_empty"]),
                    },
                ]}
                />
                <ProFormText name="name_en" label={this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.name_en"})} rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(messages["messages.error.product_category.category_en_empty"]),
                    },
                ]}
                />
                <ProFormText name="code" label={this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.code"})} rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(messages["messages.error.product_category.code_empty"]),
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["name_" + record.id] = record.name;
        row["name_en_" + record.id] = record.name_en;
        row["code_" + record.id] = record.code;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveProductCategory({
            categoryId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {
        reqUpdateProductCategory({
            categoryId: record.id,
            name: values["name_" + record.id],
            nameEn: values["name_en_" + record.id],
            code: values["code_" + record.id],
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateProductCategory({
            name: values["name"],
            nameEn: values["name_en"],
            code: values["code"],
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }
    
    render() {
        return (
            <div className="ef-master-container">
                <EFCMasterTable
                    modalTitle={this.props.intl.formatMessage({id:"app.ui.brand_reg_detail.product_category.modal.title"})}
                    rowkey="id"
                    columns={this.createColumns()}
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default injectIntl(AnalystClientProductCategory)