import './index.less'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import commonMessage from '../../../../locales/messages'
const EFCChartTakedownBar = (props) => {
    const title = props.chartTitle
    const desc = props.chartDesc
    // 国际化配置
    const intl = useIntl()
    let nameIntlKey = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )

    const chartData = useMemo(() => {
        if (props.chartData !== undefined && props.chartData.length > 0) {
            let array = props.chartData.map((item, index) => {
                return { value: item.value, name: item.name[nameIntlKey] }
            })
            return array
        }
    }, [props])

    const renderContent = () => {
        if (chartData && chartData.length > 0) {
            let option = {
                color: [
                    "#1880DE", '#2F8CE1', '#4699E5', '#5DA6E7', '#74B3EB', '#8BBFEE', '#A3CCF2', '#B9D8F5', '#D1E6F8', '#E7F2FB'
                ],
                title: {
                    left: 'center',
                    textStyle: {
                        color: '#999',
                        fontWeight: 'normal',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item',
                    textStyle: {
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    extraCssText: 'text-align: center;',
                    formatter: function (params) {
                        const data = params
                        return `<div style="font-weight: normal;font-size: 12px;">${data['name']}</div>
                                <div style="font-weight: normal;font-size: 16px;color:#000000;font-family:'Bebas';letter-spacing: 1px;">${data['value']}(${data['percent']}%)</div>`
                    },
                },
                series: [chartData].map(function (data, idx) {
                    var top = idx * 33.3
                    return {
                        name: 'No Action',
                        type: 'pie',
                        radius: [38, 72],
                        top: top + '%',
                        height: '100%',
                        left: 'center',
                        width: '100%',
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        avoidLabelOverlap: true,
                        label: {
                            // alignTo: 'edge',
                            // minMargin: 5,
                            // edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            },
                            show:true
                        },
                        labelLine: {
                            length: 20,
                            length2: 25,
                            maxSurfaceAngle: 80
                        },
                        data: data
                    }
                })
            }
            return <ReactEcharts option={option} echarts={echarts} style={{ width: '100%', height: '100%' }}></ReactEcharts>
        } else {
            return <div></div>
        }
    }

    return (
        <div
            className="ef-chart-takedownBar"
            style={{
                height: props.height ? props.height + 'px' : '312px',
            }}>
            <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFCChartTakedownBar