import * as types from "../action-types";

const initMenuState = {
    activeKey: "none key",
    isCollapsed: false,
};
export default function activeKey(state = initMenuState, action) {
    switch (action.type) {
        case types.Menu_ACTIVE_KEY: {
            let newState = Object.assign({}, state)
            newState.activeKey = action.activeKey;
            return newState;
        }
        case types.Menu_Collapsed: {
            let newState = Object.assign({}, state)
            newState.isCollapsed = action.isCollapsed;
            return newState;
        }
        default:
            return state;
    }
}