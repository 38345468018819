import "./index.less"
import store from "@/store"
import React, { useEffect, useRef, useState, useCallback } from "react"
import { Table, Space, Button, Image, Collapse, Tooltip } from "antd"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqSearchUsers, reqRemoveUser } from '../../../api/admin-user'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    DeleteOutlined,
    PicLeftOutlined,
} from '@ant-design/icons'

import { getUserlist } from './ec-userlist'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container'
import EFCSearchBar from '../../../components/ef-c-search-condition'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})

const { Panel } = Collapse
const UserListBackup = () => {

    // 国际化配置
    const intl = useIntl()

    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])

    const dateSearchItems = useRef([
        {
            name: "最終ログイン日付",
            value: "user_last_used_date"
        },
        {
            name: "新規日付",
            value: "user_create_at"
        }
    ])

    const keywordSearchItems = useRef([
        {
            name: "名前",
            value: "user_name"
        },
        {
            name: "メール",
            value: "user_mail"
        },
        {
            name: "備考",
            value: "user_remarks"
        },
    ])

    const [loading, setLoading] = useState()
    const toggleLoading = useCallback(() => {
        setLoading(prevState => !prevState)
    }, [])

    const isEmpty = useRef(true)
    const [dataSource, setDataSource] = useState([])
    const columns = useRef(getUserlist())
    const currentCondition = useRef({})
    const [collapse, setcollapse] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onHandleDelete = (record) => {
        reqRemoveUser({
            userId: record.user_id
        }).then((response) => {
            onHandleSearch()
        }).catch((e) => {

        })
    }

    const onHandleActiveContent = () => {
        if (dataSource.length > 0) {
            isEmpty.current = false
            return isEmpty.current
        } else {
            isEmpty.current = true
            return isEmpty.current
        }
    }

    const onHandleChange = (value) => {
        Object.keys(value).map((item, index) => {
            currentCondition.current[item] = value[item]
        })
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-user-list"))
        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.common.action.add" />,
            path: "/manage/user/new",
        }]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.user_list.userlist" />,
                path: "/manage/user",
            }
        ]))
    }

    useEffect(() => {
        renderBread()
        columns.current.push({
            title: <FormattedMessage id="app.common.action" />,
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip placement="top" title={<FormattedMessage id="app.common.action.detail" />} arrow={true}>
                        <Button type="link" className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />} size="middle" href={"/manage/user/" + record.user_id}></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={<FormattedMessage id="app.common.action.delete" />} arrow={true}>
                        <Button type="link" className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-shanchu" />} size="middle" onClick={() => { onHandleDelete(record) }}></Button>
                    </Tooltip>
                </Space>
            ),
            fixed: "right",
            width: 120,
        })
        onHandleSearch()
        toggleLoading()
    }, [])

    const onHandleReset = () => {
        currentCondition.current = {}
    }

    const onUrlRender = () => {
        return currentCondition.current
    }

    const onHandleSearch = (value) => {
        let condition = {}
        if (value) {
            Object.keys(value).map((item, index) => {
                if (item !== 'dateRange') {
                    if (value[item]) {
                        if (Array.isArray(value[item]) && value[item].length === 0) {

                        } else {
                            condition[item] = value[item]
                        }
                    }
                } else {
                    if (value[item]) {
                        condition['startDate'] = value[item][0].format('YYYY-MM-DD')
                        condition['endDate'] = value[item][1].format('YYYY-MM-DD')
                    }
                }
            })
            currentCondition.current = condition
        } else {
            condition = currentCondition.current
        }
        setIsLoading(true)
        reqSearchUsers({ role: 1, ...condition }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                let dataSource = data.data.users.map((user) => {
                    let userInfo = { key: user.user_id, ...user }
                    return userInfo
                })
                setDataSource(dataSource)
                setIsLoading(false)
            }
        }).catch((error) => {
            setIsLoading(false)
        })
    }

    document.title = intl.formatMessage({ id: 'app.ui.user_list.title' })
    return (
        <div className="ef-user-list ef-container-father">
            <EFCContainer>
                <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                    <Panel header={<FormattedMessage id="app.collapse.title" />} key="1">
                        <EFCSearchBar
                            // clients={clients.current}
                            date={dateSearchItems.current}
                            search={keywordSearchItems.current}
                            locale={searchBarDescribe.current}
                            showItem={['date', 'search']}
                            onUrlRender={onUrlRender}
                            onHandleChange={(value) => { onHandleChange(value) }}
                            onHandleReset={onHandleReset}
                            onHandleSearch={(value) => { onHandleSearch(value) }}
                            height={140}
                        >
                        </EFCSearchBar>
                    </Panel>
                </Collapse>
            </EFCContainer>

            <EFCContainer
                overspread={collapse ? 42 : 202}
                style={{ marginTop: '20px', padding: '24px 24px 24px 24px' }}
                isEmpty={onHandleActiveContent()}
            >
                <Table
                    className='ef-userlist-table ef-table'
                    columns={columns.current}
                    dataSource={dataSource}
                    size="small"
                    scroll={{
                        x: 'max-content',
                    }}
                    pagination={false}
                />
            </EFCContainer>
        </div >
    )
}
export default UserListBackup