import "./index.less"
import React, { Component } from 'react'
import { ExportOutlined, MenuOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Button, Badge, Select } from "antd";
import { FormattedMessage } from "react-intl";


class EFCTableHeader extends Component {

    onBatchUpdateClick = () => {
        if (this.props.onBatchUpdate) {
            this.props.onBatchUpdate(this.selectOption)
        }
    }

    onSelectOptionChanged = (value) => {
        this.selectOption = value
    }

    onSaveClick = () => {
        if (this.props.onSaveClick) {
            this.props.onSaveClick()
        }
    }

    onShowColumnListClick = () => {
        if (this.props.onShowColumnListClick) {
            this.props.onShowColumnListClick()
        }
    }

    onCheckButtonClick = () => {
        if (this.props.onFilterCheckedRecord) {
            this.props.onFilterCheckedRecord()
        }
    }

    showErrorCount = () => {
        if (this.props.errorCount !== null && this.props.errorCount !== undefined) {
            let errorText = <FormattedMessage
                id="app.components.ec_table_header.error_count"
                values={
                    {
                        count: this.props.errorCount ? this.props.errorCount : 0,
                    }
                }
            />

            return (<span className="total-item"><Badge color="#F8DFDF" text={errorText} /></span>)
        }
    }

    showBatchContainer = (flg) => {
        if (flg) {
            return (
                <div className="ef-table-left-container">
                    <Select className="infringement-option" options={this.props.batchValueOptions} allowClear loading={this.props.batchValueOptions ? false : true} onChange={this.onSelectOptionChanged}></Select>
                    <Button onClick={this.onBatchUpdateClick}>
                        <FormattedMessage id="app.components.ec_table_header.update" />
                    </Button>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="ef-table-header-container">
                {this.showBatchContainer(this.props.showBatchContainer)}
                <div className="ef-table-action-container">
                    {this.showErrorCount()}
                    <span className="total-item">
                        <FormattedMessage
                            id="app.components.ec_table_header.total_count"
                            values={
                                {
                                    count: this.props.total ? this.props.total : 0
                                }
                            }
                        />
                    </span>
                    <Button className="header-button" icon={<ExportOutlined />} size="large" type="link" onClick={this.onSaveClick} ></Button>
                    <Button className="header-button" icon={<MenuOutlined />} size="large" type="link" onClick={this.onShowColumnListClick} ></Button>
                    {/* <Button className="header-button" icon={<CheckSquareOutlined />} size="large" type="link" onClick={this.onCheckButtonClick} ></Button> */}
                </div>
            </div>
        )
    }
}

export default EFCTableHeader