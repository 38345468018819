import "./index.less"
import React, { Component } from "react";
import { Form, Input } from "antd";

import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';
import { reqCreateSpecialWord, reqFetchSpecialWords, reqRemoveSpecialWord, reqUpdateSpecialWord } from '../../../api/analyst-special-word'

import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'

class AnalystClientProductCategory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchSpecialWords({
                clientId: this.props.clientId,
                type: this.props.wordType
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.words });
                }

            }).catch((error) => {

            })

        }
    }

    createColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_word.id'}),   
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_word.word'}),
                dataIndex: 'word',
                key: 'word',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"word_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message:  this.props.intl.formatMessage(messages['messages.error.special_url.word_empty']),   
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }

            },
        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText name="word" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_word.word'})} rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.word_empty']), 
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["word_" + record.id] = record.word;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveSpecialWord({
            wordId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {
        reqUpdateSpecialWord({
            wordId: record.id,
            word: values["word_" + record.id],
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateSpecialWord({
            word: values["word"],
            type: this.props.wordType,
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="ef-master-container">
                <EFCMasterTable  
                    modalTitle={this.props.wordType === 1 ? this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.modal.title_2'}) : this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.modal.title_1'})}   
                    rowkey="id"
                    columns={this.createColumns()}
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default injectIntl(AnalystClientProductCategory)