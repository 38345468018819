// Scrape:1,
//     Categorize:2,
//     Review:3,
//     Result1:4,
//     Result2:5,
//     Result3:6,
//     Result4:6,

import { matchInt, matchFloat, matchDate, matchURL, matchDomain } from "../utils/ef-re"
import { ECColumnInfo } from "./column"
import { CURRENCY_NAMES } from "../utils/currency"

export const ColumnCategory = [
    {
        id: 1,
        name: "Scrape情報",
        items: [
            {
                title: 'ID',
                name: ECColumnInfo.ec_id.name,
                dataIndex: ECColumnInfo.ec_id.dataIndex,
                key: ECColumnInfo.ec_id.dataIndex,
                primeKey: true,
                checkCell: (str) => {
                    if (str) {
                        return (matchInt(String(str)))
                    } else {
                        return true
                    }
                }
            },
            {
                title: 'No.',
                name: ECColumnInfo.ec_no.name,
                dataIndex: ECColumnInfo.ec_no.dataIndex,
                key: ECColumnInfo.ec_no.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return (matchInt(String(str)))
                    } else {
                        return true
                    }
                }
            },
            {
                title: 'ECサイト',
                name: ECColumnInfo.ec_site.name,
                dataIndex: ECColumnInfo.ec_site.dataIndex,
                key: ECColumnInfo.ec_site.dataIndex,
                necessary: true,
                checkCell: (str) => {
                    if (!str || String(str).trim().length === 0) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            // todo:need to check
            {
                title: 'スキャン月',
                name: ECColumnInfo.ec_scan_month.name,
                dataIndex: ECColumnInfo.ec_scan_month.dataIndex,
                key: 'ec_scan_date_month',
                necessary: true,
                checkCell: (str) => {
                    if (!str || String(str).trim().length === 0) {
                        return false;
                    } else {
                        return matchDate(str, "YYYY-MM")
                    }
                    // if (!str || String(str).length === 0) {
                    //     return false;
                    // } else {

                    // }
                }
            },
            // todo:need to check
            {
                title: 'スキャン月日',
                name: ECColumnInfo.ec_scan_date.name,
                dataIndex: ECColumnInfo.ec_scan_date.dataIndex,
                key: ECColumnInfo.ec_scan_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true;
                    }
                },
            },
            {
                title: '検索KW',
                name: ECColumnInfo.ec_search_keyword.name,
                dataIndex: ECColumnInfo.ec_search_keyword.dataIndex,
                key: ECColumnInfo.ec_search_keyword.dataIndex,
                necessary: true,
                checkCell: (str) => {
                    if (str) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                title: 'Listing #',
                name: ECColumnInfo.ec_product_no.name,
                dataIndex: ECColumnInfo.ec_product_no.dataIndex,
                key: ECColumnInfo.ec_product_no.dataIndex,
            },
            {
                title: '出品タイトル',
                name: ECColumnInfo.ec_product_title.name,
                dataIndex: ECColumnInfo.ec_product_title.dataIndex,
                key: ECColumnInfo.ec_product_title.dataIndex,
                necessary: true,
            },
            {
                title: '出品URL',
                name: ECColumnInfo.ec_product_url.name,
                dataIndex: ECColumnInfo.ec_product_url.dataIndex,
                key: ECColumnInfo.ec_product_url.dataIndex,
                necessary: true,
                checkCell: (str) => {
                    if (str) {
                        return matchURL(str)
                    } else {
                        return false;
                    }
                }
            },
            {
                title: 'ブランド欄',
                name: ECColumnInfo.ec_product_brand.name,
                dataIndex: ECColumnInfo.ec_product_brand.dataIndex,
                key: ECColumnInfo.ec_product_brand.dataIndex,
            },
            {
                title: '出品者名',
                name: ECColumnInfo.ec_product_seller.name,
                dataIndex: ECColumnInfo.ec_product_seller.dataIndex,
                key: ECColumnInfo.ec_product_seller.dataIndex,
            },
            {
                title: '出品者URL',
                name: ECColumnInfo.ec_product_seller_url.name,
                dataIndex: ECColumnInfo.ec_product_seller_url.dataIndex,
                key: ECColumnInfo.ec_product_seller_url.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchURL(str)
                    } else {
                        return true;
                    }
                }
            },
            {
                title: '価格',
                name: ECColumnInfo.ec_product_price.name,
                dataIndex: ECColumnInfo.ec_product_price.dataIndex,
                key: ECColumnInfo.ec_product_price.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchFloat(String(str))
                    } else {
                        return true;
                    }
                }
            },
            {
                title: '通貨',
                name: ECColumnInfo.ec_product_currency.name,
                dataIndex: ECColumnInfo.ec_product_currency.dataIndex,
                key: ECColumnInfo.ec_product_currency.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        const index = CURRENCY_NAMES.findIndex((currency_name) => {
                            if (currency_name === str) {
                                return true
                            } else {
                                return false
                            }
                        })
                        if (index > -1) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                }
            },
            {
                title: '画像URL',
                name: ECColumnInfo.ec_product_image_url.name,
                dataIndex: ECColumnInfo.ec_product_image_url.dataIndex,
                key: ECColumnInfo.ec_product_image_url.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchURL(str)
                    } else {
                        return true
                    }
                }
            },
            {
                title: '在庫数',
                name: ECColumnInfo.ec_product_onsell_count.name,
                dataIndex: ECColumnInfo.ec_product_onsell_count.dataIndex,
                key: ECColumnInfo.ec_product_onsell_count.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchInt(String(str))
                    } else {
                        return true;
                    }
                    // if (str && typeof (str) === 'string') {
                    //     return (matchInt(str))
                    // } else {
                    //     if (typeof (str) === "number") {
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // }
                }
            },
            {
                title: '販売件数',
                name: ECColumnInfo.ec_product_sold_count.name,
                dataIndex: ECColumnInfo.ec_product_sold_count.dataIndex,
                key: ECColumnInfo.ec_product_sold_count.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchInt(String(str))
                    } else {
                        return true;
                    }
                    // if (str && typeof (str) === 'string') {
                    //     return (matchInt(str))
                    // } else {
                    //     if (typeof (str) === "number") {
                    //         return true;
                    //     } else {
                    //         return false;
                    //     }
                    // }
                }
            },
            {
                title: '出品国',
                name: ECColumnInfo.ec_product_region.name,
                dataIndex: ECColumnInfo.ec_product_region.dataIndex,
                key: ECColumnInfo.ec_product_region.dataIndex,
            },
            {
                title: '出品地域',
                name: ECColumnInfo.ec_product_city.name,
                dataIndex: ECColumnInfo.ec_product_city.dataIndex,
                key: ECColumnInfo.ec_product_city.dataIndex,
            },
        ]
    },
    {
        id: 2,
        name: "カテゴライズ情報",
        items: [
            {
                title: 'レポート提出日',
                name: ECColumnInfo.ec_categorize_date.name,
                dataIndex: ECColumnInfo.ec_categorize_date.dataIndex,
                key: ECColumnInfo.ec_categorize_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        if (typeof (str) === 'string') {
                            return matchDate(str, "YYYY-MMDD")
                        } else {
                            return false;
                        }
                    } else {
                        return true
                    }
                }
            },
            {
                title: 'ブラックリスト対象',
                name: ECColumnInfo.ec_blacklist_object.name,
                dataIndex: ECColumnInfo.ec_blacklist_object.dataIndex,
                key: ECColumnInfo.ec_blacklist_object.dataIndex,
            },
            {
                title: 'ホワイトリスト対象',
                name: ECColumnInfo.ec_whitelist_object.name,
                dataIndex: ECColumnInfo.ec_whitelist_object.dataIndex,
                key: ECColumnInfo.ec_whitelist_object.dataIndex,
            },
            {
                title: '簡易診断',
                name: ECColumnInfo.ec_is_need_to_check.name,
                dataIndex: ECColumnInfo.ec_is_need_to_check.dataIndex,
                key: ECColumnInfo.ec_is_need_to_check.dataIndex,
            },
            {
                title: 'ブランド表記判定（タイトル）',
                name: ECColumnInfo.ec_title_hint_word.name,
                dataIndex: ECColumnInfo.ec_title_hint_word.dataIndex,
                key: ECColumnInfo.ec_title_hint_word.dataIndex,
            },
            {
                title: 'ブランド表記判定（ブランド欄）',
                name: ECColumnInfo.ec_brand_hint_word.name,
                dataIndex: ECColumnInfo.ec_brand_hint_word.dataIndex,
                key: ECColumnInfo.ec_brand_hint_word.dataIndex,
            },
            {
                title: '製品判定入力欄',
                name: ECColumnInfo.ec_product_category_id_code.name,
                dataIndex: ECColumnInfo.ec_product_category_id_code.dataIndex,
                key: 'ec_product_category_id_1',
            },
            {
                title: '製品判定参照結果',
                name: ECColumnInfo.ec_product_category_id_word.name,
                dataIndex: ECColumnInfo.ec_product_category_id_word.dataIndex,
                key: 'ec_product_category_id_2',
            },
            {
                title: '採用基準表',
                name: ECColumnInfo.ec_category_rule_id.name,
                dataIndex: ECColumnInfo.ec_category_rule_id.dataIndex,
                key: ECColumnInfo.ec_category_rule_id.dataIndex,
            },
            {
                title: '入力欄',
                name: ECColumnInfo.ec_category_id_code.name,
                dataIndex: ECColumnInfo.ec_category_id_code.dataIndex,
                key: 'ec_category_Id_1',
            },
            {
                title: 'カテゴリ',
                name: ECColumnInfo.ec_category_id_word.name,
                dataIndex: ECColumnInfo.ec_category_id_word.dataIndex,
                key: 'ec_category_Id_2',
            },
            {
                title: '集計',
                name: ECColumnInfo.ec_category_id_common.name,
                dataIndex: ECColumnInfo.ec_category_id_common.dataIndex,
                key: 'ec_category_Id_3',
            },
            {
                title: 'IPF Comment',
                name: ECColumnInfo.ec_categorize_remarks.name,
                dataIndex: ECColumnInfo.ec_categorize_remarks.dataIndex,
                key: ECColumnInfo.ec_categorize_remarks.dataIndex,
            },
        ]
    },
    {
        id: 3,
        name: "回答情報",
        items: [
            {
                title: '申告実施フラグ',
                name: ECColumnInfo.ec_response_action.name,
                dataIndex: ECColumnInfo.ec_response_action.dataIndex,
                key: ECColumnInfo.ec_response_action.dataIndex,
            },
            {
                title: '貴社判定コメント',
                name: ECColumnInfo.ec_response_reason.name,
                dataIndex: ECColumnInfo.ec_response_reason.dataIndex,
                key: ECColumnInfo.ec_response_reason.dataIndex,
            },
            {
                title: '商標・著作権登録番号',
                name: ECColumnInfo.ec_response_trademark.name,
                dataIndex: ECColumnInfo.ec_response_trademark.dataIndex,
                key: ECColumnInfo.ec_response_trademark.dataIndex,
            },
            {
                title: '回答日',
                name: ECColumnInfo.ec_response_date.name,
                dataIndex: ECColumnInfo.ec_response_date.dataIndex,
                key: ECColumnInfo.ec_response_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            // {
            //     title: '回答備考',
            //     dataIndex: 'ec_response_remarks',
            //     key: 'ec_response_remarks',
            // },
        ]
    },
    {
        id: 4,
        name: "1回目結果",
        items: [
            {
                title: '1回目申告日',
                name: ECColumnInfo.ec_process1_date.name,
                dataIndex: ECColumnInfo.ec_process1_date.dataIndex,
                key: ECColumnInfo.ec_process1_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            {
                title: '1回目申告結果',
                name: ECColumnInfo.ec_process1_result.name,
                dataIndex: ECColumnInfo.ec_process1_result.dataIndex,
                key: ECColumnInfo.ec_process1_result.dataIndex,
            },

            // {
            //     title: '申告内容(1回目)',
            //     dataIndex: 'ec_process1_content',
            //     key: 'ec_process1_content',
            // },
            {
                title: '1回目確認日',
                name: ECColumnInfo.ec_process1_confirm_date.name,
                dataIndex: ECColumnInfo.ec_process1_confirm_date.dataIndex,
                key: ECColumnInfo.ec_process1_confirm_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            // {
            //     title: '1回目備考',
            //     name: {
            //         en: "1回目備考",
            //         ja: "1回目備考",
            //     },
            //     dataIndex: 'ec_process1_remarks',
            //     key: 'ec_process1_remarks',
            // },
        ]
    },
    {
        id: 5,
        name: "２回目結果",
        items: [
            {
                title: '2回目申告日',
                name: ECColumnInfo.ec_process2_date.name,
                dataIndex: ECColumnInfo.ec_process2_date.dataIndex,
                key: ECColumnInfo.ec_process2_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            {
                title: '2回目申告結果',
                name: ECColumnInfo.ec_process2_result.name,
                dataIndex: ECColumnInfo.ec_process2_result.dataIndex,
                key: ECColumnInfo.ec_process2_result.dataIndex,
            },
            // {
            //     title: '申告内容(2回目)',
            //     dataIndex: 'ec_process2_content',
            //     key: 'ec_process2_content',
            // },
            {
                title: '2回目確認日',
                name: ECColumnInfo.ec_process2_confirm_date.name,
                dataIndex: ECColumnInfo.ec_process2_confirm_date.dataIndex,
                key: ECColumnInfo.ec_process2_confirm_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            // {
            //     title: '2回目備考',
            //     name: {
            //         en: "2回目備考",
            //         ja: "2回目備考",
            //     },
            //     dataIndex: 'ec_process2_remarks',
            //     key: 'ec_process2_remarks',
            // },
        ]
    },
    {
        id: 6,
        name: "３回目結果",
        items: [
            {
                title: '3回目申告日',
                name: ECColumnInfo.ec_process3_date.name,
                dataIndex: ECColumnInfo.ec_process3_date.dataIndex,
                key: ECColumnInfo.ec_process3_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            {
                title: '3回目申告結果',
                name: ECColumnInfo.ec_process3_result.name,
                dataIndex: ECColumnInfo.ec_process3_result.dataIndex,
                key: ECColumnInfo.ec_process3_result.dataIndex,
            },
            // {
            //     title: '申告内容(3回目)',
            //     dataIndex: 'ec_process3_content',
            //     key: 'ec_process3_content',
            // },
            {
                title: '3回目確認日',
                name: ECColumnInfo.ec_process3_confirm_date.name,
                dataIndex: ECColumnInfo.ec_process3_confirm_date.dataIndex,
                key: ECColumnInfo.ec_process3_confirm_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            // {
            //     title: '3回目備考',
            //     name: {
            //         en: "3回目備考",
            //         ja: "3回目備考",
            //     },
            //     dataIndex: 'ec_process3_remarks',
            //     key: 'ec_process3_remarks',
            // },
        ]
    },
    {
        id: 7,
        name: "最終結果",
        items: [
            {
                title: '申告状況',
                name: ECColumnInfo.declarationStatus.name,
                dataIndex: ECColumnInfo.declarationStatus.dataIndex,
                key: ECColumnInfo.declarationStatus.dataIndex,
            },
            {
                title: '最終結果',
                name: ECColumnInfo.ec_process_result.name,
                dataIndex: ECColumnInfo.ec_process_result.dataIndex,
                key: ECColumnInfo.ec_process_result.dataIndex,
            },
            // {
            //     title: '最終結果月確認日',
            //     dataIndex: 'ec_process_date',
            //     key: 'ec_process_date',
            //     checkCell: (str) => {
            //         if (str) {
            //             return matchDate(str)
            //         } else {
            //             return true
            //         }
            //     }
            // },
            {
                title: '最終結果確認日',
                name: ECColumnInfo.ec_process_confirm_date.name,
                dataIndex: ECColumnInfo.ec_process_confirm_date.dataIndex,
                key: ECColumnInfo.ec_process_confirm_date.dataIndex,
                checkCell: (str) => {
                    if (str) {
                        return matchDate(str, "YYYY-MMDD")
                    } else {
                        return true
                    }
                }
            },
            {
                title: '備考',
                name: ECColumnInfo.ec_process_remarks.name,
                dataIndex: ECColumnInfo.ec_process_remarks.dataIndex,
                key: ECColumnInfo.ec_process_remarks.dataIndex,
            },
        ]
    },
]

// export const AllListItems = ColumnCategory.flatMap((category) => {
//     return category.items
// }).reduce((pre, item) => {
//     pre[item.title] = item;
//     return pre
// }, {})

