// PieData:   [{name: 'xxxxx', value:xxxxx}],

export const genIPRCountryChartConfig = (config) => {
    let { echarts, seriesPieData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let chart = echarts.init(document.getElementById(elementId));

    let option = {
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'result',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: true,
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series'
                },
                labelLine: {
                    show: true
                },
                data: seriesPieData
            }
        ]
    };
    chart.setOption(option);
    chart.resize();
    window.addEventListener("resize", function () {
        chart.resize();
    });
}

