import { ECColumnInfo } from '../../../config/column'
import { FormattedMessage } from 'react-intl'

import { InfringementCommonCategories } from '../../../config/infringement-common-category.js'
import { TakedownRequest, TakedownResult } from '../../../config/ec-report-select-items'
import { getCountryConfigDict } from '../../../config/country'
import { reqGetECFilters } from "@/api/ec.js"

import commonMessage from '../../../locales/messages'
import messages from './ec-list-messages'

export const getBasicColumns = (clientId, role) => {

    return [
        {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.id" />,
            key: 'ec_id',
            dataIndex: ECColumnInfo.ec_id.dataIndex,
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),
                showSorterTooltip: false,
                ellipsis: true,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'

            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_image_url" />,
            key: 'ec_product_image_url',
            dataIndex: ECColumnInfo.ec_product_image_url.dataIndex,
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,
                sorter: true,
                type: 'image',
                isRender: true,
                renderParams: []
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            title: <FormattedMessage id="app.ui.eclist.table.report_id" />,
            key: 'report_id',
            dataIndex: ECColumnInfo.report_id.dataIndex,
            columnParams: {
                width: 150,
                ellipsis: true,
                textWrap: 'word-break',

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_no" />,
            dataIndex: ECColumnInfo.ec_no.dataIndex,
            key: 'ec_no',
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_site" />,
            dataIndex: ECColumnInfo.ec_site.dataIndex,
            key: 'ec_site',
            filter: {
                mode: true,
                state: [],
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    array = params.map((item, index) => {
                        return { text: item, value: item }
                    })
                    return array
                },
                value: async () => {
                    return new Promise((resolve) => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_site.dataIndex,
                        })
                        resolve(response)
                    })
                }
            },
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_month" />,
            dataIndex: ECColumnInfo.ec_scan_month.dataIndex,
            key: 'ec_scan_month',
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_date" />,
            dataIndex: ECColumnInfo.ec_scan_date.dataIndex,
            key: 'ec_scan_date',
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                sorter: true,
                isRender: true,
                showSorterTooltip: false,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_search_keyword" />,
            dataIndex: ECColumnInfo.ec_search_keyword.dataIndex,
            key: 'ec_search_keyword',
            filter: {
                mode: true,
                state: [],
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    array = params.map((item, index) => {
                        return { text: item, value: item }
                    })
                    return array
                },
                value: async () => {
                    return new Promise(resolve => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_search_keyword.dataIndex,
                        })
                        resolve(response)
                    })
                }
            },
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_no" />,
            dataIndex: ECColumnInfo.ec_product_no.dataIndex,
            key: 'ec_product_no',
            columnParams: {
                width: 150,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_title" />,
            dataIndex: ECColumnInfo.ec_product_title.dataIndex,
            key: 'ec_product_title',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_url" />,
            dataIndex: ECColumnInfo.ec_product_url.dataIndex,
            key: 'ec_product_url',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_brand" />,
            dataIndex: ECColumnInfo.ec_product_brand.dataIndex,
            key: 'ec_product_brand',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_seller" />,
            dataIndex: ECColumnInfo.ec_product_seller.dataIndex,
            key: 'ec_product_seller',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_seller_url" />,
            dataIndex: ECColumnInfo.ec_product_seller_url.dataIndex,
            key: 'ec_product_seller_url',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_price" />,
            dataIndex: ECColumnInfo.ec_product_price.dataIndex,
            key: 'ec_product_price',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_currency" />,
            dataIndex: ECColumnInfo.ec_product_currency.dataIndex,
            key: 'ec_product_currency',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_onsell_count" />,
            dataIndex: ECColumnInfo.ec_product_onsell_count.dataIndex,
            key: 'ec_product_onsell_count',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_sold_count" />,
            dataIndex: ECColumnInfo.ec_product_sold_count.dataIndex,
            key: 'ec_product_sold_count',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_region" />,
            dataIndex: ECColumnInfo.ec_product_region.dataIndex,
            key: 'ec_product_region',
            filter: {
                mode: true,
                state: [],
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    let country = getCountryConfigDict()
                    array = params.map((item, index) => {
                        if (item === null) {
                            return { text: intl.formatMessage(messages["label.blank"]), value: null }
                        } else {
                            return { text: country[item].name[nameIntlKey], value: country[item].code }
                        }
                    })
                    return array
                },
                value: async () => {
                    return new Promise(resolve => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_product_region.dataIndex,
                        })
                        resolve(response)
                    })
                }
            },
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 0,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_city" />,
            dataIndex: ECColumnInfo.ec_product_city.dataIndex,
            key: 'ec_product_city',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_date" />,
            dataIndex: ECColumnInfo.ec_categorize_date.dataIndex,
            key: 'ec_categorize_date',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
                // sorter: (a, b) => a.name.localeCompare(b.name),
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            },
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_blacklist_object" />,
            dataIndex: ECColumnInfo.ec_blacklist_object.dataIndex,
            key: 'ec_blacklist_object',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'convert'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_whitelist_object" />,
            dataIndex: ECColumnInfo.ec_whitelist_object.dataIndex,
            key: ECColumnInfo.ec_whitelist_object.dataIndex,
            columnParams: {
                width: 150,
                ellipsis: false,
                showSorterTooltip: false,

                isRender: true,
                renderParams: [],
                type: 'convert'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_is_need_to_check" />,
            dataIndex: ECColumnInfo.ec_is_need_to_check.dataIndex,
            key: ECColumnInfo.ec_is_need_to_check.dataIndex,
            filter: {
                mode: true,
                dataProcess: (params, intl) => {
                    let array = []
                    array = params.map((item, index) => {
                        return { text: intl.formatMessage(messages[item.text]), value: item.value }
                    })
                    return array
                },
                value: [{ text: "flag.yes", value: 1 }, { text: "flag.no", value: 0 }],
                state: []
            },
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'convert'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_title_hint_word" />,
            dataIndex: ECColumnInfo.ec_title_hint_word.dataIndex,
            key: ECColumnInfo.ec_title_hint_word.dataIndex,
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_brand_hint_word" />,
            dataIndex: ECColumnInfo.ec_brand_hint_word.dataIndex,
            key: 'ec_brand_hint_word',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_category_id_word" />,
            dataIndex: ECColumnInfo.ec_product_category_id_word.dataIndex,
            key: 'ec_product_category_id_word',
            columnParams: {
                width: 300,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: 'importProducts',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importProducts',
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_rule_id" />,
            dataIndex: ECColumnInfo.ec_category_rule_id.dataIndex,
            key: 'ec_category_rule_id',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: 'importProductType',
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'input',
                width: 340,
                height: 40
            }
        }, {
            category: 1,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_word" />,
            dataIndex: ECColumnInfo.ec_category_id_word.dataIndex,
            key: 'ec_category_id_word',
            columnParams: {
                width: 300,
                ellipsis: true,
                showSorterTooltip: false,
                filterIcon: false,
                sorter: true,
                isRender: true,
                renderParams: 'importCategory',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importCategory'
            }
        }, {
            category: 1,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_common" />,
            dataIndex: 'ec_category_common_type_id',
            key: 'ec_category_common_type_id',
            filter: {
                mode: true,
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    array.push({ text: intl.formatMessage(messages["label.blank"]), value: null })
                    InfringementCommonCategories.map((item, index) => {
                        array.push({ value: item.id, text: item.name[nameIntlKey] })
                    })
                    return array
                },
                value: async () => {
                    return new Promise(resolve => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_category_id_common.dataIndex,
                        })
                        resolve(response)
                    })
                }
            },
            columnParams: {
                width: 300,
                ellipsis: true,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                showSorterTooltip: false,
                sorter: true,
                type: 'text',
                isRender: true,
                renderParams: 'importCommonCategory'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
            }
        }, {
            category: 1,
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
            dataIndex: ECColumnInfo.ec_categorize_remarks.dataIndex,
            key: 'ec_categorize_remarks',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'textarea',
                width: 340,
                height: 80
            }
        }, {
            category: 2,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_action" />,
            dataIndex: ECColumnInfo.ec_response_action.dataIndex,
            key: 'ec_response_action',
            filter: {
                mode: true,
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    array.push({ text: intl.formatMessage(messages["label.blank"]), value: null })
                    params.map((item, index) => {
                        let obj = TakedownRequest.find((item1) => {
                            return item1.value === item
                        })
                        if (obj) {
                            array.push({ text: obj.name[nameIntlKey], value: obj.value, index: index })
                        }
                    })
                    return array
                },
                state: [],
                value: async () => {
                    return new Promise((resolve) => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_response_action.dataIndex,
                        })
                        resolve(response)
                    })

                }
            },
            columnParams: {
                width: 200,
                ellipsis: true,
                // sorter: (a, b) => a.name.localeCompare(b.name),

                sorter: true,
                showSorterTooltip: false,
                type: 'edit',
                isRender: true,
                renderParams: 'importResponseAction'
            },
            modifyParams: {
                state: true,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importResponseAction'
            }
        }, {
            category: 2,
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_reason" />,
            dataIndex: ECColumnInfo.ec_response_reason.dataIndex,
            key: 'ec_response_reason',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: true,
                type: 'textarea',
                width: 340,
                height: 80
            }
        }, {
            category: 2,
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_trademark" />,
            dataIndex: ECColumnInfo.ec_response_trademark.dataIndex,
            key: 'ec_response_trademark',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                type: 'edit',
                renderParams: 'importTrademarks',
            },
            modifyParams: {
                state: true,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importTrademarks',
            }
        }, {
            category: 2,
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_date" />,
            dataIndex: ECColumnInfo.ec_response_date.dataIndex,
            key: 'ec_response_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: true,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_date" />,
            dataIndex: ECColumnInfo.ec_process1_date.dataIndex,
            key: 'ec_process1_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_result" />,
            dataIndex: ECColumnInfo.ec_process1_result.dataIndex,
            key: 'ec_process1_result',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: 'importOutput1',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importOutput1',
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process1_confirm_date.dataIndex,
            key: 'ec_process1_confirm_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_date" />,
            dataIndex: ECColumnInfo.ec_process2_date.dataIndex,
            key: 'ec_process2_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_result" />,
            dataIndex: ECColumnInfo.ec_process2_result.dataIndex,
            key: 'ec_process2_result',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: 'importOutput2',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importOutput2',
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process2_confirm_date.dataIndex,
            key: 'ec_process2_confirm_date',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_date" />,
            dataIndex: ECColumnInfo.ec_process3_date.dataIndex,
            key: 'ec_process3_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_result" />,
            dataIndex: ECColumnInfo.ec_process3_result.dataIndex,
            key: 'ec_process3_result',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: 'importOutput3',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importOutput3',
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process3_confirm_date.dataIndex,
            key: 'ec_process3_confirm_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.declaration_status" />,
            dataIndex: ECColumnInfo.declarationStatus.dataIndex,
            key: 'declarationStatus',
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'textarea',
                width: 340,
                height: 80
            }
        }, {
            category: 3,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_result" />,
            dataIndex: ECColumnInfo.ec_process_result.dataIndex,
            key: 'ec_process_result',
            filter: {
                mode: true,
                state: [],
                dataProcess: (params, intl, nameIntlKey) => {
                    let array = []
                    array.push({ text: intl.formatMessage(messages["label.blank"]), value: null })
                    params.map((item, index) => {
                        let obj = TakedownResult.find((item1) => {
                            return item1.value === item
                        })
                        if (obj) {
                            array.push({ text: obj.name[nameIntlKey], value: obj.value })
                        }
                    })
                    return array
                },
                value: async () => {
                    return new Promise((resolve) => {
                        const response = reqGetECFilters({
                            clientId: clientId,
                            "fieldName": ECColumnInfo.ec_process_result.dataIndex,
                        })
                        resolve(response)
                    })
                }
            },
            columnParams: {
                width: 150,
                ellipsis: true,
                showSorterTooltip: false,

                sorter: true,
                isRender: true,
                renderParams: 'importFinalResult',
                type: (role & 2) ? 'edit' : 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'select',
                width: 340,
                height: 40,
                renderParams: 'importFinalResult',
            }
        }, {
            category: 3,
            defaultShow: true,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process_confirm_date.dataIndex,
            key: 'ec_process_confirm_date',
            columnParams: {
                width: 150,
                ellipsis: true,

                sorter: true,
                showSorterTooltip: false,
                isRender: true,
                renderParams: [],
                type: 'time'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'time',
                width: 340,
                height: 40
            }
        }, {
            category: 3,
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_remarks" />,
            dataIndex: ECColumnInfo.ec_process_remarks.dataIndex,
            key: 'ec_process_remarks',
            columnParams: {
                width: 150,
                ellipsis: true,

                showSorterTooltip: false,
                sorter: true,
                isRender: true,
                renderParams: [],
                type: 'text'
            },
            modifyParams: {
                state: (role & 2) ? true : false,
                type: 'textarea',
                width: 340,
                height: 80
            }
        }, {
            category: 4,
            defaultShow: true,
            title: <FormattedMessage id="app.common.action" />,
            key: 'actions',
            columnParams: {
                width: 150,
                ellipsis: true,
                fixed: "right",
                align: 'center',
                type: 'actions',
                isRender: true,
                renderParams: []
            }
        }
    ]
}

export const getBasicCategories = (role) => {
    return [
        {
            category: 0,
            state: (role & 2) ? true : false,
            title: <FormattedMessage id="app.ui.ec_detail.tab.scrape" />
        }, {
            category: 1,
            state: (role & 2) ? true : false,
            title: <FormattedMessage id="app.ui.ec_detail.tab.categorized_info" />,
        }, {
            category: 2,
            state: true,
            title: <FormattedMessage id="app.ui.ec_detail.tab.client_response" />
        }, {
            category: 3,
            state: (role & 2) ? true : false,
            title: <FormattedMessage id="app.ui.ec_detail.tab.ipf_report_info" />
        }
    ]
}

export const getGridBasic = () => {
    return [
        {
            key: 'ec_id',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_image_url',
            defaultValue: 'error',
            params: []
        }, {
            key: 'ec_product_url',
            mark: true,
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_title',
            mark: true,
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_currency',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_price',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_site',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_region',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_seller',
            mark: true,
            defaultValue: '',
            params: []
        }, {
            key: 'ec_product_seller_url',
            defaultValue: '',
            params: []
        }, {
            key: 'ec_category_id',
            defaultValue: [],
            params: InfringementCommonCategories
        }, {
            key: 'ec_response_action',
            defaultValue: [],
            params: TakedownRequest
        }, {
            key: 'ec_categorize_remarks',
            mark: true,
            defaultValue: '',
            params: []
        }, {
            key: 'ec_response_remarks',
            defaultValue: '',
            params: []
        }
    ]

}

// 键转化,取值与存值的api对应键不同
export const keyConversion = () => {
    return {
        ec_id: 'recordId',
        ec_categorize_remarks: 'categoryRemarks',
        ec_response_remarks: 'responseRemarks',
        ec_category_id: 'categoryId',
        ec_response_action: 'action',
        ec_response_trademark: 'trademarkId',
        ec_product_category_id: 'productCategoryId',
        ec_process_result: 'processResult',
        ec_process1_result: 'process1Result',
        ec_process2_result: 'process2Result',
        ec_process3_result: 'process3Result',
    }
}