import "./index.less"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Space, Row, Col, Input, Tabs, Card, Form, Table, message } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import { ModalForm } from '@ant-design/pro-form'

import { reqCreateBrand, reqCreateBrandKeyword, reqCreateBrandModel, reqFetchBrands, reqRemoveBrand, reqRemoveBrandKeyword, reqRemoveBrandModel, reqUpdateBrand, reqUpdateBrandKeyword, reqUpdateBrandModel } from '../../../api/analyst-brand'
import EFCMasterTable from "@/components/ef-c-master-table"

import { ProFormText, } from '@ant-design/pro-form'
import { FormattedMessage, useIntl } from "react-intl"

import messages from "./messages"

const AnalystClientBrand = (props) => {

    const intl = useIntl()

    const brands = useRef([])
    const keywords = useRef([])
    const models = useRef([])

    const [selectRow, setSelectRow] = useState()
    const brandListState = useRef({})
    const brandEditValue = useRef('')

    const keywordDict = useRef({})
    const keywordSelect = useRef({})
    const keywordValue = useRef({})

    const file = useRef('')

    const modelDict = useRef({})
    const modelSelect = useRef({})
    const modelValue = useRef({})

    const tabCurrent = useRef(0)

    const [isLoading, setIsLoading] = useState()

    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const brandFormRef = useRef()
    const keywordFormRef = useRef()
    const modelFormRef = useRef()
    
    const keywordModalRef = useRef()
    const modelModalRef = useRef()

    const createBrandKeyColumns = () => {
        return [
            {
                title: intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.table.keyword_id' }),
                dataIndex: 'keyword_id',
                key: 'keyword_id',
            },
            {
                title: intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.table.keyword_name' }),
                dataIndex: 'keyword_name',
                key: 'keyword_name',
                render: (text, record) => {
                    if (keywordSelect.current[record.keyword_id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"keyword_name_" + record.keyword_id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages['message.error.brand.keyword_empty']),
                                    }
                                ]}>
                                <Input className="ef-form-element-normal"></Input>
                            </ Form.Item >
                        )
                    } else {
                        return record.keyword_name
                    }
                }
            }, {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                align: 'center',
                render: (text, record) => {
                    if (keywordSelect.current[record.keyword_id]) {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleUpdateKeyword(record)}>{<FormattedMessage id="app.common.action.save" />}</Button>
                                <Button type="link" onClick={() => onHandleRemoveKeyword(record)}>{<FormattedMessage id="app.common.action.delete" />}</Button>
                                <Button type="link" onClick={() => onHandleCancelKeyword(record)}>{<FormattedMessage id="app.common.action.cancel" />}</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleEditKeyword(record)}>{<FormattedMessage id="app.common.action.edit" />}</Button>
                            </Space>
                        )
                    }
                },
            },
        ]
    }

    const createBrandModalItems = (record) => {
        return (
            <>
                <ProFormText name="brand_name" label={<FormattedMessage id="app.ui.brand_reg_detail.brand.table.brand_name"/>} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.brand.brand_empty'])
                    },
                ]}
                />
            </>
        )
    }

    const createBrandModelColumns = () => {
        return [
            {
                title: intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.table.model_id' }),
                dataIndex: 'model_id',
                key: 'model_id',
            },
            {
                title: intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.table.model_name' }),
                dataIndex: 'model_name',
                key: 'model_name',
                render: (text, record) => {
                    if (modelSelect.current[record.model_id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"model_name_" + record.model_id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages['message.error.brand.model_empty']),
                                    }
                                ]}>
                                <Input className="ef-form-element-normal"></Input>
                            </ Form.Item >
                        )
                    } else {
                        return record.model_name
                    }
                }

            },
            {
                title: intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.table.model_price' }),
                dataIndex: 'model_price',
                key: 'model_price',
                render: (text, record) => {
                    if (modelSelect.current[record.model_id]) {
                        return (
                            <Form.Item
                                noStyle={true}
                                name={"model_price_" + record.model_id}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages['message.error.brand.price_empty']),
                                    }
                                ]}>
                                <Input className="ef-form-element-normal"></Input>
                            </ Form.Item >
                        )
                    } else {
                        return record.model_price
                    }
                }
            }, {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                align: 'center',
                render: (text, record) => {
                    if (modelSelect.current[record.model_id]) {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleUpdateModel(record)}>{<FormattedMessage id="app.common.action.save" />}</Button>
                                <Button type="link" onClick={() => onHandleRemoveModel(record)}>{<FormattedMessage id="app.common.action.delete" />}</Button>
                                <Button type="link" onClick={() => onHandleCancelModel(record)}> {<FormattedMessage id="app.common.action.cancel" />}</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="small">
                                <Button type="link" onClick={() => onHandleEditModel(record)} >{<FormattedMessage id="app.common.action.edit" />}</Button>
                            </Space>
                        )
                    }
                }
            }
        ]
    }

    const onHandleTabClick = (index) => {
        tabCurrent.current = index
        toggleLoading()
    }

    const updateBrandModelDict = (response) => {
        let { data } = response
        if (data.code === 0) {
            modelDict.current[data.data.brandId] = data.data.brandModels
            if (selectRow !== undefined && selectRow !== null) {
                if (selectRow === data.data.brandId) {
                    models.current = data.data.brandModels
                }
            }
            toggleLoading()
        }
    }

    const onHandleUpdateModel = (record) => {
        let modelName = ''
        let modelPrice = ''
        if (modelFormRef.current) {
            modelName = modelFormRef.current.getFieldValue("model_name_" + record.model_id)
            modelPrice = modelFormRef.current.getFieldValue("model_price_" + record.model_id)
        }
        modelSelect.current[record.model_id] = !modelSelect.current[record.model_id]
        if (selectRow !== undefined && selectRow !== null) {
            reqUpdateBrandModel({
                brandModelId: record.model_id,
                brandModelName: modelName,
                brandModelPrice: parseFloat(modelPrice),
                brandId: selectRow,
                clientId: props.clientId
            }).then((response) => {
                updateBrandModelDict(response)
            }).catch((e) => {

            })
        }
    }

    const onHandleRemoveModel = (record) => {
        if (selectRow !== undefined && selectRow !== null) {
            reqRemoveBrandModel({
                brandModelId: record.model_id,
                brandId: selectRow,
                clientId: props.clientId
            }).then((response) => {
                updateBrandModelDict(response)
            }).catch((e) => {

            })
        }
    }

    const onHandleCancelModel = (record) => {
        modelSelect.current[record.model_id] = false
        toggleLoading()
    }

    const onHandleEditModel = (record) => {
        modelSelect.current[record.model_id] = !modelSelect.current[record.model_id]
        if (modelSelect.current) {
            let obj = {}
            obj['model_name_' + record.model_id] = record.model_name
            obj['model_price_' + record.model_id] = record.model_price
            modelFormRef.current.setFieldsValue(obj)
        }
        toggleLoading()
    }

    const onHandleCreateBrandModel = (values) => {
        if (selectRow !== undefined && selectRow !== null) {
            reqCreateBrandModel({
                clientId: props.clientId,
                brandId: selectRow,
                brandModelName: values["model_name"],
                brandModelPrice: parseFloat(values["model_price"]),
            }).then((response) => {
                updateBrandModelDict(response)
            }).catch((e) => {

            })
        }
    }

    const updateBrandKeyDict = (response) => {
        let { data } = response
        if (data.code === 0) {
            keywordDict.current[data.data.brandId] = data.data.brandKeywords
            if (selectRow !== undefined && selectRow !== null) {
                if (selectRow === data.data.brandId) {
                    keywords.current = data.data.brandKeywords
                }
            }
            toggleLoading()
        }
    }

    const onHandleUpdateKeyword = (record) => {
        let value = ''
        if (keywordFormRef.current) {
            value = keywordFormRef.current.getFieldValue("keyword_name_" + record.keyword_id)
        }
        keywordSelect.current[record.keyword_id] = !keywordSelect.current[record.keyword_id]
        if (selectRow !== undefined && selectRow !== null) {
            reqUpdateBrandKeyword({
                keywordId: record.keyword_id,
                keywordName: value,
                brandId: selectRow,
                clientId: props.clientId
            }).then((response) => {
                updateBrandKeyDict(response)
            }).catch((e) => {
                toggleLoading()
            })
        }
    }

    const onHandleEditKeyword = (record) => {
        keywordSelect.current[record.keyword_id] = !keywordSelect.current[record.keyword_id]
        if (keywordFormRef.current) {
            let obj = {}
            obj['keyword_name_' + record.keyword_id] = record.keyword_name
            keywordFormRef.current.setFieldsValue(obj)
        }
        toggleLoading()
    }

    const onHandleRemoveKeyword = (record) => {
        if (selectRow !== undefined && selectRow !== null) {
            reqRemoveBrandKeyword({
                keywordId: record.keyword_id,
                brandId: selectRow,
                clientId: props.clientId
            }).then((response) => {
                updateBrandKeyDict(response)
            }).catch((e) => {
                toggleLoading()
            })
        }
    }

    const onHandleCancelKeyword = (record) => {
        keywordSelect.current[record.keyword_id] = false
        toggleLoading()
    }

    const onHandleCreateBrandKey = (values) => {
        if (selectRow !== undefined && selectRow !== null) {
            reqCreateBrandKeyword({
                clientId: props.clientId,
                brandId: selectRow,
                keywordName: values["keyword_name"],
            }).then((response) => {
                updateBrandKeyDict(response)
            }).catch((e) => {

            })
        }
    }

    const updateBrand = (response) => {
        let { data } = response
        if (data.code === 0) {
            data.data.brands.map((brand) => {
                keywordDict.current[brand.brand_id] = brand.keywords
                modelDict.current[brand.brand_id] = brand.models
                return brand
            })
            brands.current = data.data.brands
        }
        toggleLoading()
    }

    const onHandleCreateBrand = (values) => {
        reqCreateBrand({
            brand: values["brand_name"],
            clientId: props.clientId
        }).then((response) => {
            updateBrand(response)
        }).catch((e) => {
            toggleLoading()
        })
    }

    const onHandleRemoveBrand = (record) => {
        reqRemoveBrand({
            brandId: record.brand_id,
            clientId: props.clientId
        }).then((response) => {
            updateBrand(response)
        }).catch((e) => {
            toggleLoading()
        })
    }

    const onHandleUpdateBrand = (record, values) => {
        reqUpdateBrand({
            brandId: record.brand_id,
            brand: values,
            clientId: props.clientId
        }).then((response) => {
            updateBrand(response)
            brands.current.map((item) => {
                brandListState.current[item.brand_id] = false
            })
            toggleLoading()
        }).catch((e) => {
            toggleLoading()
        })
    }

    const onHandleEditBrand = (item) => {
        brandListState.current[item.brand_id] = !brandListState.current[item.brand_id]
        toggleLoading()
    }

    const onHandleCancelBrand = (item) => {
        brandListState.current[item.brand_id] = !brandListState.current[item.brand_id]
        toggleLoading()
    }

    const onHandleBrand = (item) => {
        keywords.current = keywordDict.current[item.brand_id]
        models.current = modelDict.current[item.brand_id]
        if (item.brand_id !== selectRow) {
            brands.current.map((item) => {
                brandListState.current[item.brand_id] = false
            })

            keywordSelect.current = {}
            if (keywords.current) {
                keywords.current.map((item, index) => {
                    keywordSelect.current[item.keyword_id] = false
                })
            }

            modelSelect.current = {}
            if (models.current) {
                models.current.map((item, index) => {
                    modelSelect.current[item.model_id] = false
                })
            }

            setSelectRow(item.brand_id)
        }
    }

    const renderModalKeyword = () => {
        return <ModalForm
            className="ef-modalform-single-col"
            formRef={keywordModalRef}
            title={<p className="ef-text-bold"><FormattedMessage id="app.ui.brand_reg_detail.brand.keyword_addition" /></p>}
            trigger={
                <Button icon={<PlusOutlined />}></Button>
            }
            modalProps={{
                onCancel: () => {
                    keywordModalRef.current?.resetFields()
                },
            }}
            onFinish={(values) => {
                onHandleCreateBrandKey(values)
                return true
            }}
            submitter={{
                // 配置按钮文本
                searchConfig: {
                    resetText: intl.formatMessage({ id: 'app.common.action.cancel' }),
                    submitText: intl.formatMessage({ id: 'app.common.action.confirm' }),
                },
            }}
        >
            <div>
                <ProFormText name="keyword_name" label={<FormattedMessage id="app.ui.brand_reg_detail.brand.table.keyword_name" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.brand.keyword_empty']),
                    },
                ]}
                />
            </div>
        </ModalForm >
    }

    const renderModalModel = () => {
        return <ModalForm
            className="ef-modalform-single-col"
            formRef={modelModalRef}
            title={<p className="ef-text-bold"><FormattedMessage id="app.ui.brand_reg_detail.brand.model_addition" /></p>}
            trigger={
                <Button icon={<PlusOutlined />}></Button>
            }
            modalProps={{
                onCancel: () => {
                    modelModalRef.current?.resetFields()
                },
            }}
            onFinish={(values) => {
                onHandleCreateBrandModel(values)
                return true
            }}
            submitter={{
                // 配置按钮文本
                searchConfig: {
                    resetText: intl.formatMessage({ id: 'app.common.action.cancel' }),
                    submitText: intl.formatMessage({ id: 'app.common.action.confirm' }),
                },
            }}
        >
            <div>
                <ProFormText name="model_name" label={<FormattedMessage id="app.ui.brand_reg_detail.brand.model" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.brand.model_empty']),
                    },
                ]}
                />
                <ProFormText name="model_price" label={<FormattedMessage id="app.ui.brand_reg_detail.brand.table.model_price" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.brand.price_empty']),
                    },
                ]}
                />
            </div>
        </ModalForm >
    }

    const renderExtraContent = () => {
        if (parseInt(tabCurrent.current) === 1) {
            return renderModalModel()
        } else {
            return renderModalKeyword()
        }
    }

    const renderTable = () => {
        return <Tabs className="ef-tabs-line" tabBarExtraContent={renderExtraContent()} onChange={(activeKey) => { onHandleTabClick(activeKey) }}>
            <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.keyword' })} key="0">
                <Form ref={keywordFormRef}>
                    <Table
                        className="ef-table"
                        rowkey="keyword_id"
                        columns={createBrandKeyColumns()}
                        dataSource={keywords.current}
                        pagination={false}
                    />
                </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage({ id: 'app.ui.brand_reg_detail.brand.model' })} key="1" >
                <Form ref={modelFormRef}>
                    <Table
                        className="ef-table"
                        rowkey="model_id"
                        columns={createBrandModelColumns()}
                        dataSource={models.current}
                        pagination={false}
                    />
                </Form>
            </Tabs.TabPane>
        </Tabs>
    }

    const renderKeywordTable = () => {
        return <div className="master-brand-content" style={{ width: '100%' }}>
            <div className="master-brand-wrap-content">
                {renderTable()}
            </div>
        </div>
    }

    const renderBrandAdd = () => {
        return <div className="master-brand-menu-add">
            <ModalForm
                className="ef-modalform-single-col"
                formRef={brandFormRef}   
                title={<p className="ef-text-bold"><FormattedMessage id="app.ui.brand_reg_detail.brand.brand_addition" /></p>}
                trigger={
                    <p><FormattedMessage id="app.common.action.add" /></p>
                }
                modalProps={{
                    onCancel: () => {
                        brandFormRef.current?.resetFields()
                    },
                }}
                onFinish={(values) => {
                    onHandleCreateBrand(values)
                    return true
                }}
                submitter={{
                    // 配置按钮文本
                    searchConfig: {
                        resetText: intl.formatMessage({ id: 'app.common.action.cancel' }),
                        submitText: intl.formatMessage({ id: 'app.common.action.confirm' }),
                    },
                }}
            >
                {createBrandModalItems()}
            </ModalForm >
        </div>
    }

    const renderBrandMenu = () => {
        return brands.current.map((item, index) => {
            if (item.brand_id === selectRow) {
                if (!brandListState.current[item.brand_id]) {
                    return <div className="master-brand-menu-item active">
                        <p>{item.brand_id} {item.brand_name}</p>
                        <div className="master-brand-item-trigger">
                            <p className="trigger-item" onClick={() => { onHandleEditBrand(item) }}><FormattedMessage id="app.common.action.edit" /></p>
                        </div>
                    </div>
                } else {
                    brandEditValue.current = item.brand_name
                    return <div className="master-brand-item-edit">
                        <p className="ef-text-normal edit-item"><FormattedMessage id="app.ui.brand_reg_detail.brand.table.brand_name"/></p>
                        <Input defaultValue={item.brand_name} onChange={(e) => { brandEditValue.current = e.target.value }} className="ef-form-element-normal edit-input-item" />
                        <div className="master-brand-item-trigger">
                            <p className="trigger-item" onClick={() => { onHandleUpdateBrand(item, brandEditValue.current) }}><FormattedMessage id="app.common.action.save" /></p>
                            <p className="trigger-item" onClick={() => { onHandleRemoveBrand(item) }}><FormattedMessage id="app.common.action.delete" /></p>
                            <p className="trigger-item" onClick={() => { onHandleCancelBrand(item) }}><FormattedMessage id="app.common.action.cancel" /></p>
                        </div>
                    </div>
                }
            } else {
                return <div className="master-brand-menu-item" onClick={() => { onHandleBrand(item) }}>
                    <p>{item.brand_id} {item.brand_name}</p>
                </div>
            }
        })
    }

    const renderBrands = () => {
        if (props.clientId) {
            reqFetchBrands({
                clientId: props.clientId
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    data.data.brands.map((brand) => {
                        keywordDict.current[brand.brand_id] = brand.keywords
                        modelDict.current[brand.brand_id] = brand.models
                        brandListState.current[brand.brand_id] = false
                        return brand
                    })
                    brands.current = data.data.brands
                    setSelectRow(brands.current[0].brand_id)
                    onHandleBrand(brands.current[0])
                    toggleLoading()
                }
            }).catch((error) => {
                toggleLoading()
            })
        }
    }

    useEffect(() => {
        renderBrands()
    }, [])

    return <div className="ec-master-brand">
        <div className="ec-master-brand-menu ef-text-normal">
            {renderBrandMenu()}
            {renderBrandAdd()}
        </div>
        <div className="ec-master-brand-content">
            {renderKeywordTable()}
        </div>
    </div>
}
export default AnalystClientBrand