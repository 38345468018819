import React, { useRef, useEffect, useCallback, useState } from 'react'
import Map, { NavigationControl, Source, Layer,Popup } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { FormattedMessage } from 'react-intl'

const EFMapCanvas = (props) => {

    let drawParams = props.drawParams
    const mapStyle = useRef({})
    let title = props.chartTitle

    const renderMap = () => {
        if (props.readOnly === false || props.readOnly === undefined) {
            mapStyle.current = {
                style: 'mapbox://styles/mapbox/light-v10',
                zoom: 2,
                center: [105, 35],
            }
        } else {
            mapStyle.current = {
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [105, 35],
                zoom: 2,
            }
        }
    }

    renderMap()

    return <div className='ef-chart-map'
        style={{
            height: props.height ? props.height + 'px' : '391px',
        }}>
        <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}</div>
        <div className="ef-chart-content">
            <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px', position: 'relative' }} >
                <Map
                    mapboxAccessToken='pk.eyJ1IjoiY29uZ2NoaWNhZ28iLCJhIjoiY2t4c3V2b2NvNTBoajJ1cHpyb2l0bHZ3MiJ9.2Jkc9_TADo_YzhHZxm46Lw'
                    initialViewState={{
                        longitude: mapStyle.current.center[0],
                        latitude: mapStyle.current.center[1],
                        zoom: mapStyle.current.zoom
                    }}
                    style={{ width: '100%', height: 315 }}
                    mapStyle={mapStyle.current.style}
                >
                    {props.readOnly === false || props.readOnly === undefined ? <Source id='countries' type='vector' url='mapbox://mapbox.country-boundaries-v1'>
                        {Array.isArray(drawParams) && drawParams.length > 0 ?
                            drawParams.map((item, index) => {
                                console.log(item)
                                return <Layer
                                    id={'countries-join' + index}
                                    source-layer='country_boundaries'
                                    source='country_boundaries'
                                    type='fill'
                                    paint={{
                                        'fill-color': item.color,
                                        'fill-outline-color': '#ffffff',
                                    }}
                                    filter={
                                        ['in',
                                            "iso_3166_1_alpha_3",
                                            ...item.name]
                                    }
                                />
                            })
                            : ''}
                        {/* <Layer id='countries-join'
                            type='fill'
                            source-layer='country_boundaries'
                            source='country_boundaries'
                            paint={{
                                'fill-color': 'rgba(200,100,251, 0.3)',
                                'fill-outline-color': '#ffffff',
                            }}
                            filter={
                                ["==",
                                    [
                                        "get",
                                        "iso_3166_1_alpha_3"
                                    ],
                                    "CHN"]
                            }
                        /> */}
                    </Source> : ''}
                    <NavigationControl />
                </Map>
            </div>
        </div>
    </div>

}
export default EFMapCanvas