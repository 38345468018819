import * as types from "../action-types";
import { getToken } from "@/utils/cookie";

const initAuthInfo = {
    token: getToken(),
    userid:""
};
export default function auth(state = initAuthInfo, action) {
    switch (action.type) {
        case types.AUTH_LOGIN:
            return {
                ...state,
                token: action.token,
                userid:action.userid,
            };
        case types.AUTH_LOGOUT:
            return {};
        default:
            return state;
    }
}