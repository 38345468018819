import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.delete_failed": {
        id: "app.ui.brand_reg_detail.message.error.delete_failed",
        defaultMessage: "削除に失敗しました。",
    },
    "message.error.client_fetch_failed": {
        id: "app.ui.brand_reg_detail.message.error.client_fetch_failed",
        defaultMessage: "エージェント情報の取得。",
    },
    "message.error.user_fetch_failed": {
        id: "app.ui.brand_reg_detail.message.error.user_fetch_failed",
        defaultMessage: "アナリストの取得に失敗しました。",
    },

    "message.info.client_update": {
        id: "app.ui.brand_reg_detail.message.info.client_update",
        defaultMessage: "クライアント情報を更新しました。",
    },
    "message.error.client_failed": {
        id: "app.ui.brand_reg_detail.message.error.client_failed",
        defaultMessage: "クライアント情報の更新は失敗しました。",
    },

    "message.error.tel_error": {
        id: "app.ui.brand_reg_detail.message.error.tel_error",
        defaultMessage: "正しい電話番号を入力してください。",
    },
    "message.error.name_error": {
        id: "app.ui.brand_reg_detail.message.error.name_error",
        defaultMessage: "会社名は30文字以内。",
    },
    "message.error.address_error": {
        id: "app.ui.brand_reg_detail.message.error.address_error",
        defaultMessage: "アドレスは100文字以内。",
    },
    "message.error.remarks_error": {
        id: "app.ui.brand_reg_detail.message.error.remarks_error",
        defaultMessage: "正しいアナリストを入力ください，500文字以内。",
    },
    "message.error.delete_warning": {
        id: "app.ui.brand_reg_detail.message.warning.delete_warning",
        defaultMessage: "クライアントを削除しますか？",
    }
})

export default messages
