import { message, Table, Button, Image, Drawer, Typography, Pagination, Collapse, Tooltip } from "antd"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { FormattedMessage, useIntl } from 'react-intl'
import messages from "./messages.js"
import { getDateSearchItems, getKeywordSearchItems } from "../util/search-bar-config.js"
// import { getColumns } from "../util/data-table-config"
import './index.less'
import store from "@/store"
import { reqFetchECCrawlerTask, reqRemoveECCrawlerTask, reqStartTask, reqDownloadTask, reqStopTask, reqCloneECCrawlerTask } from "../../../api/analyst-ec-task.js"
import { updateSearchParamWithTableOnChangeInfo, updateSearchParamWithSearchInfo, getAllFiltersFromSearchParam } from "../../common/list-search-param-helper.js"
import { useLocation, useHistory } from 'react-router-dom'
import { reqFetchClients } from '@/api/analyst-client'
import { ECCrawlerStatus } from "../common/master-data.js"
import ECCrawlerSubtasksList from "./task-subtasks.jsx"
import { deepCopy } from '@/utils/deepClone'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container/index.jsx'
import EFCSearchBar from '../../../components/ef-c-search-condition/index.jsx'
import { urlParamsConvert, initUrl } from './list-view-url.js'
import { getColumns } from './list-view-table-config.js'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})


const { Panel } = Collapse
const ECCrawlerTaskList = () => {
    const intl = useIntl()
    const location = useLocation()
    const history = useHistory()

    const clients = useRef([])
    const tasks = useRef([])
    const [subTaskVisible, setSubTaskVisible] = useState(false)
    const subTasks = useRef([])
    const total = useRef(0)
    const pageSize = useRef(20)
    const pageCurrent = useRef(1)
    const [collapse, setcollapse] = useState(false)

    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const filterList = useRef([])
    const filterListPre = useRef([])
    const itemsFilter = useRef([])
    const [openFilter, setOpenFilter] = useState(false)

    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        'app.components.ef_search_bar.date_item',
        'app.components.ef_search_bar.search_item',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])

    const customLocale = {
        items_per_page: intl.formatMessage({ id: 'app.pagination.per_page' }),
        jump_to: intl.formatMessage({ id: 'app.pagination.jump_to' }),
        page: intl.formatMessage({ id: 'app.pagination.page' }),
        showTotal: (total, current) => {
            return <FormattedMessage
                id="app.pagination.show_total"
                values={
                    {
                        count: Math.ceil(total / current)
                    }
                }
            />
        }
    }

    const onHandleDetail = (record) => {
        history.push({
            pathname: "/task/ec-scraping-task-detail/" + record.id,
        })
    }

    const onHandleStart = (record) => {
        // console.log(record)
        // if (record.status === ECCrawlerStatus.new.value) {
        const subtaskIds = record.subtasks.map(subtask => {
            return subtask.id
        })
        reqStartTask({
            taskId: record.id,
            subtaskIds: subtaskIds
        }).then((response) => {
            if (response.data.code === 0) {
                onHandleSearch()
                message.success(intl.formatMessage(messages['message.info.scraping_success']))
            } else {
                message.error(intl.formatMessage(messages['message.error.scraping_fail']))
            }
        }).catch(e => {
            message.error(intl.formatMessage(messages['message.error.scraping_fail']))
        })
        // }
    }

    const onHandleDelete = (record) => {
        if (record.status != ECCrawlerStatus.scraying.value) {
            reqRemoveECCrawlerTask({
                taskId: record.id
            }).then((response) => {
                if (response.data.code === 0) {
                    onHandleSearch()
                    message.success(intl.formatMessage(messages['message.info.delete_success']))
                } else {
                    message.error(intl.formatMessage(messages['message.error.delete_fail']))
                }
            }).catch(e => {
                message.error(intl.formatMessage(messages['message.error.delete_fail']))
            })
        }
    }

    const onHandleStop = (record) => {
        if (record.subtasks) {
            const subtaskIds = record.subtasks.map(subtask => {
                return subtask.id
            })
            reqStopTask({
                taskId: record.id,
                subtaskIds: subtaskIds
            }).then((response) => {
                if (response.data.code === 0) {
                    onHandleSearch()
                    message.success(intl.formatMessage(messages['message.info.stop_success']))
                } else {
                    message.error(intl.formatMessage(messages['message.error.stop_fail']))
                }
            }).catch(e => {
                message.error(intl.formatMessage(messages['message.error.stop_fail']))
            })
        }
    }

    const onHandleDownload = (record, type) => {
        if (record.status === ECCrawlerStatus.finished.value) {
            const subtaskIds = record.subtasks.map(subtask => {
                return subtask.id
            })

            const params = subtaskIds.reduce((pre, item) => {
                return pre + "subtaskIds=" + item + "&"
            }, "&")

            window.open(`${process.env.REACT_APP_BASE_API}/api/ec-crawler-command/download?exportType=${type}&taskId=${record.id}${params}`)
            // reqDownloadTask({
            //     taskId: record.id,
            //     subtaskIds: subtaskIds
            // }).then((response) => {
            //     // to new window
            // })
        }
    }

    const onHandleClone = (record) => {
        reqCloneECCrawlerTask({
            taskId: record.id,
        }).then((response) => {
            if (response.data.code === 0) {
                onHandleSearch()
                message.success(intl.formatMessage(messages['message.info.new_success']))
            } else {
                message.error(intl.formatMessage(messages['message.error.new_fail']))
            }
        }).catch(e => {
            message.error(intl.formatMessage(messages['message.error.new_fail']))
        })
    }

    const onHandleSubtasks = (record) => {
        subTasks.current = record
        setSubTaskVisible(!subTaskVisible)
    }

    const onHandleSubtasksListClose = () => {
        setSubTaskVisible(false)
    }

    const onUrlRender = () => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        return params
    }

    const onHandleChange = (value) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        Object.keys(value).map((item, index) => {
            let operation = convertObj.find(current => current.key === item)
            if (operation) {
                operation.excute(value[item])
            }
        })
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    const onHandleFilterItem = (index, index1) => {
        filterList.current[index][index1] = !filterList.current[index][index1]
        onHandleFilterTable(filterList.current)
        toggleLoading()
    }

    const onHandleFilterCancel = () => {
        filterList.current = deepCopy(filterListPre.current)
        filterListPre.current = []
        onHandleFilterTable(filterList.current)
        setOpenFilter(false)
    }

    const onHandleFilterCheck = () => {
        filterListPre.current = []
        onHandleSearch()
        setOpenFilter(false)
    }

    const onHandleSearch = (extraParams) => {
        const searchParams = new URLSearchParams(location.search)
        let convertObj = urlParamsConvert(searchParams)
        let params = {}
        for (const [key, value] of searchParams.entries()) {
            let operation = convertObj.find(current => current.key === key)
            if (operation) {
                params[key] = operation.parse(value)
            }
        }
        if (extraParams && Object.keys(extraParams).length > 0) {
            Object.keys(extraParams).forEach(key => {
                params[key] = extraParams[key]
            })
        }
        let { dateFields, startDate, endDate, wordFields, keyword, searchType, orderFields, current, pageSize, filters } = params
        reqFetchECCrawlerTask({
            dateFields, startDate, endDate, wordFields, keyword, searchType, orderFields, current, pageSize, filters
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                tasks.current = data.data.tasks
                total.current = data.data.total
                toggleLoading()
                // message.success(intl.formatMessage(messages['message.info.search_success']))
            }
        }).catch((error) => {
            // message.error(intl.formatMessage(messages['message.error.search_fail']))
        })
    }

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(location.search)
        history.push(searchParams)
    }

    const onHandleFilterTable = (filter) => {
        let filters = []
        filter.map((item, index) => {
            if (item.includes(true)) {
                let obj = {}
                obj.key = itemsFilter.current[index].key
                let array = []
                item.map((item1, index1) => {
                    if (item1) {
                        array.push(itemsFilter.current[index].items[index1].value)
                    }
                })
                obj.values = array
                filters.push(obj)
            }
        })
        onHandleChange({ filters })
    }

    const onHandleTable = (pagination, filters, sorter, extra) => {
        pageSize.current = pagination.pageSize
        pageCurrent.current = pagination.current
        onHandleChange({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order }] : null
        })
        onHandleSearch({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            orderFields: (sorter.field && sorter.order) ? [{ key: sorter.field, value: sorter.order === "ascend" ? 1 : 2 }] : null
        })
    }

    const renderFilterItem = (current, index) => {
        return <div>
            {current.items.map((item, index1) => {
                return <div className={`ef-filter-item ${filterList.current[index][index1] === true ? 'ef-filter-item-active' : ''}`} onClick={() => { onHandleFilterItem(index, index1) }}>{item.text}</div>
            })}
        </div>
    }

    const renderFilterSection = () => {
        if (itemsFilter.current.length > 0) {
            return (
                <div className='ef-filter-section'>
                    {itemsFilter.current.map((item, index) => {
                        return (
                            <div className='ef-filter-section-one'>
                                <p className='ef-filter-title ef-title-describe'>
                                    {item.title}
                                </p>
                                <div className='ef-filter-content'>
                                    {renderFilterItem(item, index)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return <div></div>
        }
    }

    const renderFilter = () => {
        if (filterListPre.current.length === 0) {
            filterListPre.current = deepCopy(filterList.current)
        }
        return (
            <div>
                <Tooltip placement="top" title={<FormattedMessage id="app.ui.eclist.tooltip.filter" />} arrow={true}>
                    <Button
                        className="ef-sidefun ef-task-filter"  
                        icon={<IconFont className="iconfont iconfont-wait" type="icon-shaixuan" />}
                        onClick={() => { setOpenFilter(true) }}></Button>
                </Tooltip>
                <Drawer
                    className="ef-filter-flat"
                    placement={'right'}
                    closable={false}
                    title={<Typography.Title level={4} className='ef-title-regular'>
                        <FormattedMessage id="app.ui.eclist.table.result_filter" />
                    </Typography.Title>}
                    width={784}
                    onClose={() => { onHandleFilterCancel() }}
                    open={openFilter}
                    key={'right'}
                    footer={
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Button className='ef-button-linear' style={{ display: 'inline-block' }} onClick={onHandleFilterCancel}><FormattedMessage id="app.common.action.cancel" /></Button>
                            <Button className='ef-button-color' style={{ marginLeft: '16px', display: 'inline-block' }} onClick={onHandleFilterCheck}><FormattedMessage id="app.common.action.filter" /></Button>
                        </div>
                    }
                >
                    {renderFilterSection()}
                </Drawer>
            </div>
        )
    }

    const renderInitUrl = () => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.has('current')) {
            pageCurrent.current = parseInt(decodeURIComponent(searchParams.get('current')))
        } else {
            pageCurrent.current = 1
        }
        if (searchParams.has('pageSize')) {
            pageSize.current = parseInt(decodeURIComponent(searchParams.get('pageSize')))
        } else {
            pageSize.current = 20
        }
        initUrl(searchParams)
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        history.push(newUrl)
    }

    const renderBread = () => {
        // store.dispatch(setMenuActiveKey("analyst-ec-list"));
        store.dispatch(setContentContainerHeaderAction([{
            name: <FormattedMessage id="app.common.action.add" />,
            // onClick: _handleNew
            path: "/task/new-ec-scraping-task"
        }]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.ec_crawler_task" />,
                path: "/task/ec-crawler-task",
            },
        ]))
    }

    const renderClients = () => {
        reqFetchClients({ all: true }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                const clientsInfo = data.data.client.map((client) => {
                    return {
                        value: client.client_id,
                        text: client.client_name,
                    }
                })
                clients.current = clientsInfo
                renderFilterColumns()
                onHandleSearch()
                toggleLoading()
            }
        }).catch(e => {
            message.error(intl.formatMessage(messages['message.error.clients_fetch_failed']))
        })
    }

    const renderFilterColumns = () => {
        let columns = getColumns({ clients: clients.current })
        columns.map((item, index) => {
            if (item.filterRender) {
                itemsFilter.current.push({ title: item.title, key: item.key, items: item.filterRender() })
                let array = []
                item.filterRender().map((item1, index1) => {
                    array.push(false)
                })
                filterList.current.push(array)
            }
        })
    }

    useEffect(() => {
        renderInitUrl()
        renderBread()
        renderClients()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.ec_task.title' })
    return <div className="ef-container-father">
        <EFCContainer>
            <Collapse defaultActiveKey={['1']} ghost onChange={(a) => { setcollapse(a.length > 0 ? false : true) }}>
                <Panel header={<FormattedMessage id="app.collapse.title" />} key="1">
                    <EFCSearchBar
                        date={getDateSearchItems()}
                        search={getKeywordSearchItems()}
                        locale={searchBarDescribe.current}
                        showItem={['date', 'search']}
                        onUrlRender={onUrlRender}
                        onHandleChange={(value) => { onHandleChange(value) }}
                        onHandleReset={onHandleReset}
                        onHandleSearch={() => { onHandleSearch() }}
                        height={140}>
                    </EFCSearchBar>
                </Panel>
            </Collapse>
        </EFCContainer>
        <EFCContainer overspread={collapse ? 42 : 202} style={{ marginTop: '20px', padding: '18px 24px' }} className="ef-task-container">
            {renderFilter()}
            <ECCrawlerSubtasksList task={subTasks.current} visible={subTaskVisible} onClose={onHandleSubtasksListClose}>
            </ECCrawlerSubtasksList>
            <Table
                className="ef-table"
                key="ec_task_ListTable"
                rowKey={(record) => {
                    return record.id
                }}
                columns={getColumns({
                    clients: clients.current,
                    onHandleDetail,
                    onHandleSubtasks,
                    onHandleDelete,
                    onHandleStart,
                    onHandleStop,
                    onHandleDownload,
                    onHandleClone,
                    intl: intl
                })}
                dataSource={tasks.current}
                size="default"
                onChange={onHandleTable}
                pagination={{
                    showQuickJumper: true,
                    total: total.current,
                    // defaultCurrent: pageCurrent.current,
                    current: pageCurrent.current,
                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    // defaultPageSize: 20,
                    pageSize: pageSize.current,
                    showSizeChanger: true,
                    locale: customLocale,
                    showTotal: (total) => customLocale.showTotal(total, pageSize.current)
                }}
                scroll={{
                    x: 'max-content',
                }}
            />
        </EFCContainer>
    </div>
}

export default ECCrawlerTaskList