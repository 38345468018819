import request from '@/utils/request'

export async function reqGetNoticeFilters(data) {
    return request({
        url: '/api/notice/filters',
        method: 'post',
        data: data
    })
}

export function reqSearchNotices(data) {
    return request({
        url: '/api/notication/search-notification',
        method: 'post',
        data: data
    })
}

export function reqViewNotices(data) {
    return request({
        url: '/api/notication/view-notification',
        method: 'post',
        data: data
    })
}

export function reqUnviewedNoticeCount(data) {
    return request({
        url: '/api/notication/unviewed-notice-count',
        method: 'post',
        data: data
    })
}