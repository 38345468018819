export const getKeywordSearchItems = (intl) => {
    return [
        {
            name: "タスクタイトル",
            value: "title"
        },
        {
            name: "備考",
            value: "remarks"
        },
    ]
}


export const getDateSearchItems = (intl) => {
    return [
        {
            name: "開始日",
            value: "planStartDate"
        },
    ]
}
