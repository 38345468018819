import request from '@/utils/request'

export function reqCreateECSite(data){
    return request({
        url: '/api/analyst/create-ec-site',
        method: 'post',
        data: data
    })
}

export function reqRemoveECSite(data){
    return request({
        url: '/api/analyst/remove-ec-site',
        method: 'post',
        data: data
    })
}

export function reqUpdateECSite(data){
    return request({
        url: '/api/analyst/update-ec-site',
        method: 'post',
        data: data
    })
}

export function reqFetchECSites(data){
    return request({
        url: '/api/analyst/fetch-ec-sites',
        method: 'post',
        data: data
    })
}

export function reqGetECSite(data){
    return request({
        url: '/api/analyst/get-ec-site',
        method: 'post',
        data: data
    })
}