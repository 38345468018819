import request from '@/utils/request'

export function reqCreateSpecialWord(data){
    return request({
        url: '/api/analyst/create-special-word',
        method: 'post',
        data: data
    })
}

export function reqRemoveSpecialWord(data){
    return request({
        url: '/api/analyst/remove-special-word',
        method: 'post',
        data: data
    })
}

export function reqUpdateSpecialWord(data){
    return request({
        url: '/api/analyst/update-special-word',
        method: 'post',
        data: data
    })
}

export function reqFetchSpecialWords(data){
    return request({
        url: '/api/analyst/fetch-special-words',
        method: 'post',
        data: data
    })
}