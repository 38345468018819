
import React, { Component } from "react";

import { Button, Upload, Modal, Image } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { reqUploadTrademarkImage, reqRemoveTrademarkImage } from "@/api/trademark.js"
import '../index.less'

const KEY_DELETED = "_deleted"

class TradeMarkPictureUploader extends Component {
    constructor(props) {
        super(props);
        if (props.imageName) {
            this.state = {
                imageName: props.imageName
            }
        } else {
            this.state = {}
        }
    }
    handleChange = (values) => {
        if (values.file.status === "removed") {
            // values.file.uid
            this.setState({ imageName: KEY_DELETED })

            let parameter = {}
            if (this.props.trademarkId) {
                parameter.trademarkId = this.props.trademarkId
            }
            if (this.props.clientId) {
                parameter.clientId = this.props.clientId
            }
            reqRemoveTrademarkImage({
                name: values.file.uid,
                ...parameter
            }).then((response) => {
                let { data } = response;
                if (data.code === 0) {
                    if (this.props.onRemove) {
                        this.props.onRemove(data.data)
                    }
                }
            }).catch((e) => {
            })
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            return
        }

        this.setState({
            previewEvidencePicture: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    closeModel = () => {
        this.setState({ previewVisible: false })
    }

    downloadPic = () => {
        window.open(this.state.previewEvidencePicture)
        this.setState({ previewVisible: false })
    }

    uploadImage = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {
        if (this.props.beforeUpload) {
            this.props.beforeUpload()
        }
        const formData = new FormData();
        if (this.props.clientId) {
            formData.append("clientId", this.props.clientId)
        }
        if (this.props.trademarkId) {
            formData.append("trademarkId", this.props.trademarkId)
        }
        formData.append("file", file)

        reqUploadTrademarkImage(
            formData,
            (total, loaded) => {
                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    onSuccess(response, file);
                    this.setState({ imageName: data.data.name });
                }
                if (this.props.onFinishUpload) {
                    this.props.onFinishUpload(data.data)
                }
            }).catch((error) => {
                onError(error)
                if (this.props.onFinishUpload) {
                    this.props.onFinishUpload({})
                }
            })
    }

    render() {
        let fileList = []

        let imageName = this.state.imageName ? this.state.imageName : this.props.imageName
        if (imageName && imageName !== KEY_DELETED) {
            let url = `${process.env.REACT_APP_BASE_API}/api/trademark/get-trademark-image?name=${imageName}`
            if (this.props.clientId) {
                url = `${process.env.REACT_APP_BASE_API}/api/trademark/get-trademark-image?clientId=${this.props.clientId}&name=${imageName}`
            }
            fileList = [
                {
                    uid: imageName,
                    name: imageName,
                    status: 'done',
                    url: url
                }
            ]
        }

        return (
            <>
                <Upload
                    className="avatar-uploader"
                    accept=".png,.jpg,.jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: true }}
                    customRequest={this.uploadImage}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    maxCount={1}
                >
                    <div>
                        <PlusOutlined />
                    </div>
                </Upload>
                <Modal
                    visible={this.state.previewVisible}
                    title={this.state.previewTitle}
                    onCancel={this.closeModel}
                    onOk={this.closeModel}
                    destroyOnClose={true}
                    footer={[
                        <Button key="downloadimg" onClick={this.downloadPic}>
                            ダウンロード
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.closeModel}>
                            閉じる
                        </Button>,
                    ]}
                >
                    <Image style={{ width: '100%' }} src={this.state.previewEvidencePicture} />
                </Modal>
            </>
        )
    }

}

export default TradeMarkPictureUploader;