import './index.less'

import React, { Component } from 'react'
import { Checkbox, Divider, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'
class ChartDataFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedList: this.props.selectList,
      indeterminate: false,
      checkedAll: true,
    }
    this.colCount = this.props.colCount ? this.props.colCount : 4
  }

  static getDerivedStateFromProps(props, current_state) {
    if (props.selectList && props.options) {
      current_state.checkedList = props.selectList
      current_state.indeterminate =
        props.selectList.length === 0 ||
        props.selectList.length === props.options.length
          ? false
          : true
      current_state.checkedAll =
        props.selectList.length === props.options.length
    }
    return props
  }

  onChange = (values) => {
    this.setState({
      checkedList: values,
      indeterminate:
        values.length > 0 && values.length < this.props.options.length,
      checkedAll: values.length === this.props.options.length,
    })
    if (this.props.onFilterChange) {
      this.props.onFilterChange(values)
    }
  }

  onCheckAllChange = (e) => {
    let checkedList = e.target.checked
      ? this.props.options.map((option) => option.value)
      : []
    this.setState({
      checkedList: checkedList,
      indeterminate: false,
      checkedAll: e.target.checked,
    })
    if (this.props.onFilterChange) {
      this.props.onFilterChange(checkedList)
    }
  }

  genCheckBox = (options) => {
    let rows = []
    for (let i = 0; i < options.length; i = i + this.colCount) {
      let row = []
      for (let j = i; j < i + this.colCount && j < options.length; j++) {
        row.push(options[j])
      }
      rows.push(row)
    }
    return rows.map((row) => {
      return this.genRow(row)
    })
  }

  genRow = (row) => {
    return (
      <Row>
        {row.map((option) => {
          return this.genCol(option)
        })}
      </Row>
    )
  }

  genCol = (option) => {
    return (
      <Col span={24 / this.colCount}>
        <Checkbox value={option.value}>{option.name}</Checkbox>
      </Col>
    )
  }

  render() {
    return (
      <div className="filter-container">
        <div className="filter-container-header">
          <p className="title-name">{this.props.title}</p>
          <div className="check-all-container">
            <Checkbox
              indeterminate={this.state.indeterminate}
              onChange={this.onCheckAllChange}
              checked={this.state.checkedAll}>
              <FormattedMessage id="app.components.data_filter.check_all" />
            </Checkbox>
          </div>
        </div>
        <Divider className="devider" />
        <div className="filter-container-body">
          <Checkbox.Group
            className="check-group"
            onChange={this.onChange}
            value={this.state.checkedList}>
            {this.genCheckBox(this.props.options)}
          </Checkbox.Group>
        </div>
      </div>
    )
  }
}

export default ChartDataFilter
