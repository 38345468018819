export const genTestPurchartBarConfig = (config) => {
    // seriesNameData = [{name:xxxx,data:[]}]
    let { echarts, xAxisData, seriesData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    let myChart = echarts.init(document.getElementById(elementId));

    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                var res = `${params[0].name} <br/>`
                for (const item of params) {
                    if (item.value !== 0) {
                        res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}<br/>`
                    }
                }
                return res
            }
        },

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                axisLabel: { width: 30 },
                data: xAxisData,
                overflow: 'truncate',
                ellipsis: '...'
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: seriesData.map((item) => {

            return {
                name: item.name,
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
                data: item.data
            }
        }),

    };
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", function () {
        myChart.resize();
    });
}