import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store"
import React, { Component, useCallback, useEffect, useRef, useState } from "react"
import { Form, Input, Button, Row, Col, Select, message } from "antd"
import { useHistory } from 'react-router-dom'
import messages from './messages'
import { FormattedMessage, useIntl } from "react-intl"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqCreateUser } from '../../../api/admin-user'
import { isValidMail, isValidPassword, isValidTelNo } from "../../../utils/ef-re"

import EFCContainer from '../../../components/ef-c-container'
import EFBox from '../../../components/ef-c-box'
const ManagerNew = (props) => {

    const intl = useIntl()
    const analyst = useRef()
    const basicInfo = useRef()

    // 路由跳转
    const history = useHistory()
    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const updateTrigger = useRef()

    const onValidate = (values) => {
        if (!values.name) {
            message.error(intl.formatMessage(messages['message.error.name_empty']))
            return false
        }
        if (!isValidMail(values.mail)) {
            message.error(intl.formatMessage(messages['message.error.email_fail']))
            return false
        }
        if (!isValidPassword(values.password)) {
            message.error(intl.formatMessage(messages['message.error.password_fail']))
            return false
        }
        if (values.password !== values.password2) {
            message.error(intl.formatMessage(messages['message.error.password_unequal']))
            return false
        }
        if (!values.role || values.role.length === 0) {
            message.error(intl.formatMessage(messages['message.error.role_empty']))
            return false
        }
        if (values.tel && !isValidTelNo(values.tel)) {
            message.error(intl.formatMessage(messages['message.error.phone_fail']))
            return false
        }
        return true
    }

    const onHandleBasicChange = (values) => {
        const { name, mail, tel, role, remarks, password, password2 } = values
        let newRole
        if (role && role.length > 0) {
            newRole = role.reduce((preRoleType, roletype) => {
                return parseInt(roletype) + parseInt(preRoleType)
            })
        }

        let body = {
            name,
            tel,
            role: parseInt(newRole),
            remarks,
            mail,
            password2,
            password
        }

        if (onValidate(values)) {
            reqCreateUser(body).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    history.push("/manage/analyst")
                }
            }).catch((error) => {
                message.error(intl.formatMessage(messages['message.error.create_fail']))
            })
        } else {

        }
    }

    const onHandleUpdate = (order) => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const renderTrigger = () => {
        return <div className='client-detail-container-footer'>
            <Button className='ef-button-color-small' onClick={() => { onHandleUpdate() }}><FormattedMessage id='app.common.action.add' /></Button>
        </div>
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("manage-analyst-list"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.breadcrumbs.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.manager_list" />,
                path: "/manage/analyst",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.new" />,
            }
        ]))
    }

    const renderColumns = () => {
        basicInfo.current = [
            {
                name: 'name',
                value: '',
                label: 'app.ui.manager_analyst_new.box.name',
                type: 'input',
                permission: true,
            }, {
                name: 'mail',
                value: '',
                label: 'app.ui.manager_analyst_new.box.email',
                type: 'input',
                permission: true,
            }, {
                name: 'password',
                value: '',
                label: 'app.ui.manager_analyst_new.box.password',
                type: 'input',
                mode: 'password',
                permission: true,
            }, {
                name: 'password2',
                value: '',
                label: 'app.ui.manager_analyst_new.box.password2',
                type: 'input',
                mode: 'password',
                permission: true,
            }, {
                name: 'role',
                value: undefined,
                label: 'app.ui.manager_analyst_new.box.role',
                type: 'select',
                mode: 'multiple',
                permission: true,
                params: [
                    {
                        label: intl.formatMessage({ id: 'app.user.manager' }),
                        value: 4,
                    }, {
                        label: intl.formatMessage({ id: 'app.user.analyst' }),
                        value: 2
                    }
                ]
            }, {
                name: 'tel',
                value: '',
                label: 'app.ui.manager_analyst_new.box.tel',
                type: 'input',
                permission: true,
            }, {
                name: 'remarks',
                value: '',
                label: 'app.ui.manager_analyst_new.box.remarks',
                type: 'textarea',
                permission: true,
            }
        ]
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderColumns()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.manager_analyst_new.title' })
    return <EFCContainer overspread={true} style={{ padding: '32px' }}>
        <EFBox
            titleBarColor={'#1880DE'}
            title={<FormattedMessage id="app.ui.manager_analyst_new.box.title" />}
            mode={'single'}
            data={basicInfo.current}
            itemClass={'ef-form-element-block'}
            state={true}
            updateTrigger={updateTrigger.current}
            onHandleChange={(value) => { onHandleBasicChange(value) }}>
        </EFBox>
        {renderTrigger()}
    </EFCContainer>
}
export default ManagerNew