import * as types from "../action-types";

const initContentContainerHeader = {
    breadcrumbs: [{
        name: "Home",
        path: "/"
    }],
    actions: [] //{name: path: click: item:}
};
export default function contentContainerHeader(state = initContentContainerHeader, action) {
    switch (action.type) {
        case types.ContentContainerHeader_SetBreadcrumb:{
            let newState = Object.assign({}, state)
            newState.breadcrumbs = action.breadcrumbs;
            return newState;
        }
        case types.ContentContainerHeader_SetActionButton:{
            let newState = Object.assign({}, state)
            newState.actions = action.actions;
            return newState;
        }
        default:
            return state;
    }
}