import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Select, Tag, Popover, Button } from 'antd'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import commonMessage from '../../../../locales/messages'
import messages from '../../messages'
const EFCChartRank = (props) => {
    // 国际化配置
    const intl = useIntl()
    let nameIntlKey = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const tableRef = useRef()
    const tableHeight = useRef()
    const tableWidth = useRef()
    const [selectItems, setSelectItems] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const chartData = props.chartData.items
    const chartLink = props.chartData.links

    const Item = useRef()
    const [floatItem, setFloatItem] = useState(-1)

    Item.current = useMemo(() => {
        if (props.selectDesc && props.selectDesc.length > 0) {
            let arr = []
            let str = ''
            props.selectDesc.map((item, index) => {
                arr.push({ value: item.value, label: item.name[nameIntlKey] })
                str += item.value
            })
            setSelectItems(str)
            return arr
        } else {
            setSelectItems('')
        }
    }, [props])

    const title = props.chartTitle
    const desc = props.chartDesc

    let filteredOptions
    if (Item.current && Item.current.length > 0) {
        filteredOptions = Item.current.filter((o) => !selectedItems.includes(o))
    }

    const handleChange = (value) => {
        setSelectedItems(value)
        props.onSelectClick(value)
    }

    const onHandlefloat = (value) => {
        if (value === floatItem) {
            setFloatItem(-1)
        } else {
            setFloatItem(value)
        }
    }

    const renderSelect = () => {
        return <div className='ef-chart-title-dropdown'>
            <div>
                <p className='ef-title-tip ef-chart-dropdown-describe'>{intl.formatMessage(
                    messages['filter.locale_region_filtering.title']
                )}</p>
                <Select
                    maxTagCount='responsive'
                    className='ef-chart-dropdown-items'
                    mode="tags"
                    value={selectedItems}
                    onChange={(value) => { handleChange(value) }}
                    style={{
                        width: 240,
                    }}
                    options={(filteredOptions && filteredOptions.length > 0) ? filteredOptions.map((item) => ({
                        value: item.value,
                        label: item.label,
                    })) : []}
                />
            </div>
        </div>
    }

    const mergeArr = (array1, array2) => {
        let mergedArray = [...array1, ...array2].reduce((acc, obj) => {
            const found = acc.find(item => item.name === obj.name)
            if (found) {
                found.total += obj.total
            } else {
                acc.push({ ...obj })
            }
            return acc
        }, [])
        return mergedArray
    }

    const getPopContent = (name,site) => {
        if (site !== undefined && site.length > 0) {
            {
                let content = site.map((item1, index1) => {
                    return (<div className='ef-chart-table-float-item'>
                        <p className='ef-chart-table-float-num'>{item1.total}</p>
                        <a className='ef-chart-table-float-link' href={chartLink[name].site[item1.name].link} target="_blank" rel="noreferrer">{item1.name}</a>
                    </div>
                    )
                })
                return content
            }
        } else {
            <div></div>
        }
    }

    const renderContent = () => {
        let selectContent = []
        selectedItems.map((item, index) => {
            if (!chartData[item]) {
                return
            }
            let keys = Object.keys(chartData[item])
            keys.map((item1, index1) => {
                let site = []
                let keysite = Object.keys(chartData[item][item1].sites)
                site = keysite.map((site) => (
                    { name: site, total: chartData[item][item1].sites[site] })
                )
                site.sort((site1, site2) => {
                    return site2.total - site1.total
                })
                let isOld = selectContent.findIndex((findOne) => findOne.name === item1)
                if (isOld > -1) {
                    selectContent[isOld].total += chartData[item][item1].total
                    selectContent[isOld].sites = mergeArr(selectContent[isOld].sites, site)
                    selectContent[isOld].sites.sort((site1, site2) => {
                        return site2.total - site1.total
                    })
                } else {
                    selectContent.push({ name: item1, total: chartData[item][item1].total, sites: site })
                }
            })
        })
        selectContent.sort((item1, item2) => {
            return item2.total - item1.total
        })
        let keys = Object.keys(selectContent)
        if (keys.length > 0) {
            return <div className='ef-chart-table' ref={tableRef}>
                {keys.map((item, index) => {
                    let site = selectContent[item].sites
                    return <div className='ef-chart-table-item' style={{ width: '100%', height: `${100 / props.num}` + '%', position: 'relative' }}>
                        <div className={`ef-chart-table-rank ${index === 0 ? 'ef-chart-rank-1' : ''} ${index === 1 ? 'ef-chart-rank-2' : ''} ${index === 2 ? 'ef-chart-rank-3' : ''} ${index > 3 ? 'ef-chart-rank-regular' : ''}`}
                        >{index < 3 ? 'Top' + (index + 1) : (index + 1)}</div>
                        <a className='ef-chart-table-name' target='_blank' href={chartLink[selectContent[item].name].link} rel="noreferrer">{selectContent[item].name}</a>
                        <div className='ef-chart-table-num'>{selectContent[item].total}</div>
                        <div className='ef-chart-table-web' onClick={() => { onHandlefloat(index) }}>
                            <Popover className='ef-chart-table-web-pop' placement="leftTop" content={getPopContent(selectContent[item].name,site)}>
                                <div>{selectContent[item].sites.length}</div>
                            </Popover>
                        </div>
                    </div>
                })}
            </div>
        } else {
            return <div></div>
        }
    }

    useEffect(() => {
        if (props.selectDesc && props.selectDesc.length > 0) {
            let array = props.selectDesc.map((item, index) => {
                return item.value
            })
            handleChange(array)
        } else {
            handleChange([])
        }
    }, [selectItems])


    useEffect(() => {
        if (tableRef.current) {
            tableWidth.current = tableRef.current.offsetWidth
            tableHeight.current = tableRef.current.offsetHeight
        }
    }, [tableRef])

    return (<div
        className='ef-chart-rank'
        style={{
            height: props.height ? props.height + 'px' : '391px',
        }}>
        <div className="ef-title-regular ef-chart-title">
            <p className='ef-chart-title-text'>{title && <FormattedMessage id={title} />}</p>
            <div className='ef-chart-title-right'>
                {renderSelect()}
            </div>
        </div>
        <div className="ef-chart-content">
            <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                {renderContent()}
            </div>
        </div>
    </div>)
}
export default EFCChartRank