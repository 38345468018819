import "./index.less"
import React, { Component } from "react";
import { Button, Form, Input, Radio, message } from "antd";

import { reqGetImpactSettings, reqSetImpactSettings } from '../../../api/analyst-other-setting'

class AnalystClientOtherSettingBackup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: {},
            isloading: false,
        }
        this.formRef = React.createRef();

        if (this.props.clientId) {
            reqGetImpactSettings({
                clientId: this.props.clientId,
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.formRef.current.setFieldsValue({
                        ...data.data.settings
                    })
                }
            }).catch((error) => {

            })
        }
    }

    onFinish = (values) => {
        this.setState({ isloading: true })
        reqSetImpactSettings({
            clientId: this.props.clientId,
            count: parseInt(values.count),
            countType: values.countType,
            price: parseFloat(values.price),
            priceType: values.priceType,
            radio: parseFloat(values.radio)
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.formRef.current.setFieldsValue({ ...data.data.settings })
                this.setState({ isloading: false })
                message.success("更新しました。")
            }
        }).catch((e) => {
            this.setState({ isloading: false })
            message.success("更新は失敗しました。")
        })
    }

    onFinishFailed = (values) => {
        message.success("更新は失敗しました。")
    }


    render() {
        return (
            <div className="master-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <div className="setting-title-wrapper">インパクト金額計算式：A＊B＊C</div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p>単価(A)：</p>
                        </div>
                        <div>
                            <Form.Item noStyle={true} name="priceType">
                                <Radio.Group onChange={this.onPriceTypeChange}>
                                    <Radio value={1}>表示単価</Radio>
                                    <Radio value={2}>一律単価</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item noStyle={true} name="price">
                                <Input className="input-text" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p>係数(B)：</p>
                        </div>
                        <div >
                            <Form.Item noStyle={true} name="countType">
                                <Radio.Group onChange={this.onCountTypeChange} >
                                    <Radio value={1}>在庫数</Radio>
                                    <Radio value={2}>販売（Sold)数</Radio>
                                    <Radio value={3}>一律係数</Radio>
                                </Radio.Group>
                            </Form.Item >
                            <Form.Item noStyle={true} name="count">
                                <Input className="input-text" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p>その他(C)：</p>
                        </div>
                        <div>
                            <Form.Item noStyle={true} name="radio">
                                <Input className="input-text" />
                            </Form.Item>%
                        </div>
                    </div>

                    <div className="acition-container">
                        <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>保存</Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default AnalystClientOtherSettingBackup