export const compareString = (a, b) => {
    if (typeof (a) === "string") {
        if (typeof (b) === "string") {
            return a.localeCompare(b)
        } else {
            return 1
        }
    } else {
        if (typeof (b) === "string") {
            return -1
        } else {
            return 0
        }
    }
}

export const compareNumber = (a, b) => {
    if (typeof (a) === "number") {
        if (typeof (b) === "number") {
            return a-b
        } else {
            return 1
        }
    } else {
        if (typeof (b) === "number") {
            return -1
        } else {
            return 0
        }
    }
}

export const compareDate = (a,b)=>{
    return compareString(a,b)
}