import { defineMessages } from 'react-intl'

const messages = defineMessages({
    search_hint: {
        id: "app.components.ef_search_bar.search_hint",
        defaultMessage: "keyword",

    },
})

export default messages