import mapboxgl from '!mapbox-gl'// eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import "./index.less"

import { Row, Col, message } from "antd"
import React, { Component, useCallback, useRef, useState, useEffect } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { reqFetchHomeInfo } from "@/api/ec"
import { reqFetchIPFNews } from '@/api/ipf-news.js'
import { reqFetchClients } from '@/api/analyst-client'

import { FormattedMessage, useIntl } from 'react-intl'

import EFCContainer from '../../components/ef-c-container/index.jsx'
import EFCSearchBar from '../../components/ef-c-search-condition/index.jsx'
import EFChartBox from './chart/ef-chart-box/index.jsx'
import EFCChartTakedownBar from './chart/ef-chart-takedown/index.jsx'
import EFCChartHbar from './chart/ef-chart-horizontal-bar/index.jsx'
import EFMapCanvas from './chart/ef-chart-map/index.jsx'
import EFChartNews from './chart/ef-chart-news/index.jsx'

const EFHome = (props) => {
     // 国际化配置
     const intl = useIntl()

    const [dataSource, setDataSource] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setIsLoading(prevState => !prevState)
    }, [])   

    const clients = useRef([])
    const news = useRef([])

    // 路由跳转
    const history = useHistory()
    // 获取位置信息
    const location = useLocation()
    const locationSearch = useRef()
    const clientId = useRef()

    // 访问全局redux状态数据
    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
        ...reduxState.mainMenu
    }

    const searchBarDescribe = useRef([
        'app.ui.dashboard.search.brand',
        '',
        '',
        'app.components.ef_search_bar.search_button',
        'app.components.ef_search_bar.reset_button'
    ])

    const onlineProtectionCount = useRef(0)
    const iprRegistrationCount = useRef(0)
    const testPurchaseCount = useRef(0)
    const trademarkRef = useRef()
    const testPurchaseRef = useRef()
    const mapCanvasRef = useRef([])

    // 得到当前页面可渲染数据(一整套可选的配套数据)
    const getInitData = async () => {
        let clients = []
        if (reduxProps.role & 2) {
            await reqFetchClients()
                .then((response) => {
                    const { data } = response
                    if (data.code === 0) {
                        clients = data.data.client
                    }
                })
                .catch((error) => { })
        }
        let news = []
        await reqFetchIPFNews({ page: 1, pageSize: 10 }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                news = data.data.news
            } else {
                news = []
            }
        }).catch(e => {
            news = []
        })
        return { clients, news }
    }

    useEffect(() => {
        locationSearch.current = location.search
        const urlParams = new URLSearchParams(locationSearch.current)
        if (reduxProps.role & 2) {
            clientId.current = urlParams.get('clientId')
        } else {
            if (!urlParams.has('clientId')) {
                urlParams.append('clientId', reduxProps.companyInfo.client_id)
                const newUrl = `${location.pathname}?${urlParams.toString()}`
                history.push(newUrl)
            }
            clientId.current = reduxProps.companyInfo.client_id
        }
    }, [location])

    useEffect(() => {
        getInitData().then((data) => {
            clients.current = data.clients
            news.current = data.news
            toggleLoading()
        })
        if (reduxProps.role & 2) {

        } else {
            onHandleSearchClick()
        }
    }, [])

    const onHandleReset = () => {
        const searchParams = new URLSearchParams(location.search)
        history.push(searchParams)
    }

    const onHandleChange = (params) => {
        const keys = Object.keys(params)
        let newParams = {}
        keys.map((item, index) => {
            if (params[item] !== undefined && params[item] !== null) {
                newParams[item] = params[item]
            }
        })
        // 获取当前 URL
        const currentUrl = location.pathname
        // 构建新的查询字符串
        const newQueryString = new URLSearchParams(newParams).toString()
        // 构建新的 URL 并跳转
        const newUrl = `${currentUrl}?${newQueryString}`
        history.push(newUrl)
    }

    const onCanvasMap = (data) => {
        let sortData = data.sort((a, b) => {
            return  b.sellerCount - a.sellerCount
        })
        const matchExpression = [
            { name: [], color: 'rgb(168,196,215)' },
            { name: [], color: 'rgb(187,213,232)' },
            { name: [], color: 'rgb(202,228,247)' },
            { name: [], color: 'rgb(225,240,255)' },
            { name: [], color: 'rgb(255,255,255)' }
        ]
        let maxData = sortData ? sortData[0] : 1
        for (const row of data) {
            // Convert the range of data values to a suitable color
            if (row.sellerCount >= maxData.sellerCount * 0.9) {
                if (row['regionCode'].length > 0) {
                    matchExpression[0].name.push(row['regionCode'])
                }
            } else if (row.sellerCount >= maxData.sellerCount * 0.6) {
                if (row['regionCode'].length > 0) {
                    matchExpression[1].name.push(row['regionCode'])
                }
            } else if (row.sellerCount >= maxData.sellerCount * 0.3) {
                if (row['regionCode'].length > 0) {
                    matchExpression[2].name.push(row['regionCode'])
                }
            } else if (row.sellerCount >= 0) {
                if (row['regionCode'].length > 0) {
                    matchExpression[3].name.push(row['regionCode'])
                }
            } else {
                if (row['regionCode'].length > 0) {
                    matchExpression[4].name.push(row['regionCode'])
                }
            }
        }
        mapCanvasRef.current = matchExpression
    }

    const onHandleSearchClick = () => {
        if (clientId.current) {
            reqFetchHomeInfo({ clientId: clientId.current }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    onlineProtectionCount.current = data.data.onlineProtectionCount
                    iprRegistrationCount.current = data.data.iprRegistrationCount
                    testPurchaseCount.current = 0
                    if (Array.isArray(data.data.testPurchaseData)) {
                        data.data.testPurchaseData.map((item, index) => {
                            testPurchaseCount.current += item.count
                        })
                    }
                    trademarkRef.current = data.data.iprByCountry
                    trademarkRef.current.forEach((item, index) => {
                        trademarkRef.current[index].name = { en: item.name, ja: item.name }
                    })
                    testPurchaseRef.current = {}
                    data.data.testPurchaseData.map((item, index) => {
                        if (testPurchaseRef.current[item.region]) {
                            testPurchaseRef.current[item.region].push({ name: item.site, value: item.count })
                        } else {
                            testPurchaseRef.current[item.region] = []
                            testPurchaseRef.current[item.region].push({ name: item.site, value: item.count })
                        }
                    })
                    onCanvasMap(data.data.countrySellerInfo)
                    setDataSource(data.data)
                }
            }).catch(e => {

            })
        } else {
            message.info(intl.formatMessage({id:'app.components_ef-search_bar.error.brand_empty'}))
        }
    }

    const onOpenMore = () => {
        // moreLink.current = window.location.host + '/news'
        // window.open(moreLink.current, "_blank");
        history.push('/news')
    }

    const onUrlRender = () => {
        const queryParams = new URLSearchParams(location.search)
        let params = {}
        if (queryParams.has('clientId')) {
            params['clientId'] = queryParams.get('clientId')
        }
        return params
    }

    const renderSearchBar = () => {
        return (
            <EFCContainer style={{ padding:'16px 32px 16px 10px',margin:'20px 20px 0px 0px' }}>
                <EFCSearchBar
                    clients={clients.current}
                    onUrlRender={onUrlRender}
                    locale={searchBarDescribe.current}
                    showItem={['clients']}
                    onHandleChange={(value) => { onHandleChange(value) }}
                    onHandleReset={onHandleReset}
                    onHandleSearch={() => { onHandleSearchClick() }}
                >
                </EFCSearchBar>
            </EFCContainer>
        )
    }

    document.title = intl.formatMessage({id:'app.ui.home.title'})
    return (
        <div>
            {reduxProps.role & 2 ? renderSearchBar() : ''}
            <EFCContainer style={{ marginTop: '20px', padding:'32px' }}>
                <Row gutter={[16, 20]}>
                    <Col span={6}>
                        <EFChartBox
                            chartTitle={'Online Protection'}
                            height={226}
                            number={onlineProtectionCount.current}
                            chartDesc={{
                                unit: 'app.ui.home.section.unit',
                                desc: 'app.ui.home.online.cases'
                            }}
                            chartColor='#6FDD9A' />
                    </Col>
                    <Col span={6}>
                        <EFChartBox
                            chartTitle={'Offline Protection'}
                            height={226}
                            number={0}
                            chartDesc={{
                                unit: 'app.ui.home.section.unit',
                                desc: 'app.ui.home.offline.cases'
                            }}
                            chartColor='#73B3EB' />
                    </Col>
                    <Col span={6}>
                        <EFChartBox
                            chartTitle={'IPR Registration'}
                            height={226}
                            number={iprRegistrationCount.current}
                            chartDesc={{
                                unit: 'app.ui.home.section.unit',
                                desc: 'app.ui.home.registration.cases'
                            }}
                            chartColor='#FF6A6A' />
                    </Col>
                    <Col span={6}>
                        <EFChartBox
                            chartTitle={'Test Purchase'}
                            height={226}
                            number={testPurchaseCount.current}
                            chartDesc={{
                                unit: 'app.ui.home.section.unit',
                                desc: 'app.ui.home.test_purchase.cases'
                            }}
                            chartColor='#FAAC58' />
                    </Col>

                    <Col span={12}>
                        <EFCChartTakedownBar
                            height={391}
                            chartTitle={'app.ui.home.section.tm_register_region'}
                            chartData={trademarkRef.current}
                        >
                        </EFCChartTakedownBar>
                    </Col>

                    <Col span={12}>
                        <EFCChartHbar
                            height={391}
                            chartTitle={'app.ui.home.section.test_purchase_cases_number'}
                            chartData={testPurchaseRef.current}
                        >
                        </EFCChartHbar>
                    </Col>

                    <Col span={12}>
                        <EFMapCanvas
                            height={391}
                            drawParams={mapCanvasRef.current}
                            readOnly={false}
                            chartTitle={'app.ui.home.section.online_bad_seller'}
                        ></EFMapCanvas>
                    </Col>

                    <Col span={12}>
                        <EFMapCanvas
                            height={391}
                            readOnly={true}
                            chartTitle={'app.ui.home.section.offline_protection'}
                        ></EFMapCanvas>
                    </Col>

                    <Col span={24}>
                        <EFChartNews
                            height={332}
                            chartTitle={'app.ui.home.section.ipf_news'}
                            chartData={news.current}
                            showItem={10}
                            moreLink={() => { onOpenMore() }}
                        ></EFChartNews>
                    </Col>
                </Row>
            </EFCContainer>
        </div>
    )
}
export default EFHome