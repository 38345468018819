import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import './index.less';
import { Row, Col, Badge, Table } from 'antd';
import { VariableSizeGrid as Grid } from 'react-window';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import ResizeObserver from 'rc-resize-observer';
import { Link } from 'react-router-dom';

class VirtualizedExample extends React.Component {
    colors = ["gold", "blue", "cyan"]
    renderItem = ({ index, key, style }) => {
        const { data } = this.props;
        const item = data[index];
        if (index < this.colors.length) {
            let hintText = `Top ${index + 1}`
            return (
                <div key={key} className="seller-item" style={style}>
                    <Badge.Ribbon text={hintText} color={this.colors[index]}>
                        <Row>
                            <Col span={2}>
                                <p className="item-value">{item.value}</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-name">{item.name}</p>
                            </Col>
                        </Row>
                    </Badge.Ribbon>
                </div>
            );
        } else {
            return (
                <div key={key} className="seller-item" style={style}>
                    <Row>
                        <Col span={2}>
                            <p className="item-value">{item.value}</p>
                        </Col>

                        <Col span={12}>
                            <p className="item-name">{item.name}</p>
                        </Col>
                    </Row>
                </div>
            );
        }
    };

    render() {
        return (
            <div className="seller-list">
                <AutoSizer>
                    {({ width, height }) =>
                        <VList
                            height={height}
                            overscanRowCount={20}
                            rowCount={this.props.data.length}
                            rowHeight={50}
                            rowRenderer={this.renderItem}
                            width={width}
                        />
                    }
                </AutoSizer>
            </div>
        );
    }
}


function VirtualTable(props) {

    const columns = [
        {
            title: 'A',
            width: 100,
            dataIndex: 'value',
        },
        {
            title: 'B',
            dataIndex: 'name',
        },
    ];
    const scroll = {
        y: 200,
    }

    const [tableWidth, setTableWidth] = useState(0);
    const widthColumnCount = columns.filter(({ width }) => !width).length;
    const mergedColumns = columns.map((column) => {
        if (column.width) {
            return column;
        }

        return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });
    const gridRef = useRef();
    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    });
                }
            },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        // gridRef.current.resetAfterIndices({
        //     columnIndex: 0,
        //     shouldForceUpdate: true,
        // });
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * 54;
        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index) => {
                    const { width } = mergedColumns[index];
                    return totalHeight > scroll.y && index === mergedColumns.length - 1
                        ? width - scrollbarSize - 1
                        : width;
                }}
                height={scroll.y}
                rowCount={rawData.length}
                rowHeight={() => 54}
                width={tableWidth}
                onScroll={({ scrollLeft }) => {
                    onScroll({
                        scrollLeft,
                    });
                }}
            >
                {({ columnIndex, rowIndex, style }) => (
                    <div
                        className={classNames('virtual-table-cell', {
                            'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                        })}
                        style={style}
                    >
                        {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
                    </div>
                )}
            </Grid>
        );
    };

    return (
        <ResizeObserver
            onResize={({ width }) => {
                setTableWidth(width);
            }}
        >
            <Table
                {...props}
                scroll={scroll}
                dataSource={props.data.map(item => {
                    let newItem = { ...item }
                    newItem.children = [{ ...item }]
                    return newItem
                })}
                className="virtual-table"
                columns={mergedColumns}
                pagination={false}
                components={{
                    body: renderVirtualList,
                }}
            />
        </ResizeObserver>
    );
}

class SellerList extends React.Component {
    constructor(props) {
        super(props)
        this.colors = ["gold", "blue", "cyan"]
        this.columns = [
            {
                title: 'Count',
                width: 100,
                dataIndex: 'value',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                render:(value,record)=>{
                    return <Link to={record.link} target="_blank">{value}</Link>
                }
            },
            {
                title: 'mark',
                dataIndex: 'name',
                width: 50,
                render: (value, record, index) => {
                    if (index < this.colors.length && record.type==="a") {
                        let hintText = `Top ${index + 1}`
                        return (
                            <div className="seller-ribbon">
                                <Badge.Ribbon text={hintText} color={this.colors[index]} offset={[30, 30]} >
                                </Badge.Ribbon>
                            </div>
                        );
                    } else {
                        return
                    }

                },

            },
        ];
    }

    render() {
        console.log(this.props.data)
        return (
            <Table
                scroll={{
                    y: 240,
                }}
                dataSource={
                    this.props.data
                }
                className="virtual-table"
                columns={this.columns}
                showHeader={false}
                pagination={false}
            />

        )
    }
}


export default SellerList