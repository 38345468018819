import moment from 'moment';
import { Spin, Space, Button, Image } from "antd";
import { reqGetECFilters } from "@/api/ec.js"
import Highlighter from 'react-highlight-words';
import EFCFilterView from "../../../components/ef-c-tablefilter";
import { ECColumnInfo } from '../../../config/column';
import { FormattedMessage } from 'react-intl';
import common_messages from '../../../locales/messages';
import messages from '../ws-list/message.js';
import { WSCategoryType ,WSTakedownRequest,WSTakedownResult } from "../../../master-data/ws-master-data";

const getTableColumnFilters = (dataIndex, key, getDatasource) => {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return <EFCFilterView
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                getDatasource={getDatasource}
                key={key}
            />
        },
    }
}

const wrapperRenderResult = (result) => {
    return (
        <div className='cell-wapper'>
            {result}
        </div>
    )
}

const renderSearchResultHighLight = (value, record, dataIndex, searchWords, searchColumns) => {
    let content = searchColumns && searchColumns.some(column => column === dataIndex) ? (
        <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={searchWords}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
        />
    ) : (
        value
    )
    return wrapperRenderResult(content)
}

const getBasicColumns = (config) => {

    let { currentSearchClientId, allReportsInfo,
        currentSearchConditions,
        allTrademarksInfo, currentSelectClientId,
        onDetailClick, onHistoryClick, searchWords, searchColumns, allSearchableCoulumns, intl } = config;
    let columns = [
        {
            title: <FormattedMessage id="app.ui.eclist.table.id" />,
            dataIndex: "id",
            key: 'id',
            width: 80,
            fixed: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_no" />,
            defaultShow: true,
            dataIndex: 'no',
            key: 'no',
            width: 150,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        // todo : no report id
        {
            title: <FormattedMessage id="app.ui.eclist.table.report_id" />,
            dataIndex: 'reportId',
            key: 'reportId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let reportsInfo = allReportsInfo["reportsInfo_" + currentSearchClientId];
                    if (reportsInfo) {
                        let info = reportsInfo.find((report) => {
                            return report.id === value;
                        })
                        let content = info ? info.reportName : value;
                        return wrapperRenderResult(content)
                    }
                }
                return wrapperRenderResult(value)
            },
            // ...getTableColumnFilters('report_id', 'report_id' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //         let reportsInfo = allReportsInfo["reportsInfo_" + currentSearchClientId];

            //         if (reportsInfo) {
            //             let whereFields = currentSearchConditions["whereFields"]
            //             let filterInfo = whereFields ? whereFields.find(field => field.key === "report_id") : {}
            //             let filters = reportsInfo.map((item) => {
            //                 let isChecked = filterInfo && filterInfo.values ? filterInfo.values.some((value) => { return value === item.report_id }) : false
            //                 return {
            //                     text: item.report_name,
            //                     value: item.report_id,
            //                     checked: isChecked
            //                 }
            //             })
            //             resolve({ filters })
            //         }
            //     })
            //     return promise
            // }),
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.search_engine" />,
            dataIndex: 'searchEngine',
            key: 'searchEngine',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_month" />,
            dataIndex: 'scanDate',
            key: 'scanDate',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_date" />,
            dataIndex: 'scanDate',
            key: 'scanDate',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_search_keyword" />,
            defaultShow: true,
            dataIndex: 'searchKeyword',
            key: 'searchKeyword',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
            // ...getTableColumnFilters(ECColumnInfo.ec_search_keyword.dataIndex, 'ec_search_keyword' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //         reqGetECFilters({
            //             clientId: currentSearchClientId,
            //             "fieldName": ECColumnInfo.ec_search_keyword.dataIndex,
            //         }).then((response) => {
            //             const { data } = response;
            //             if (data.code === 0) {
            //                 let filters = data.data.filters.map((item) => {
            //                     let isChecked = false;
            //                     return {
            //                         text: item,
            //                         value: item,
            //                         checked: isChecked
            //                     }
            //                 })
            //                 resolve({ filters, total: data.data.total, offset: data.data.offset })
            //             }
            //         })
            //     })
            //     return promise
            // }),
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.page_domain" />,
            dataIndex: 'pageDomain',
            key: 'pageDomain',
            width: 150,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.page_url" />,
            dataIndex: 'pageURL',
            key: 'pageURL',
            width: 150,
            ellipsis: true,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_product_url.dataIndex, 'ec_product_url' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //         reqGetECFilters({
            //             clientId: currentSearchClientId,
            //             "fieldName": ECColumnInfo.ec_product_url.dataIndex,
            //         }).then((response) => {
            //             const { data } = response;
            //             if (data.code === 0) {
            //                 let filters = data.data.filters.map((item) => {
            //                     let isChecked = false;
            //                     return {
            //                         text: item,
            //                         value: item,
            //                         checked: isChecked
            //                     }
            //                 })
            //                 resolve({ filters, total: data.data.total, offset: data.data.offset })
            //             }
            //         })
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_region" />,
            dataIndex: 'domainRegion',
            key: 'domainRegion',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.tld" />,
            dataIndex: 'tld',
            key: 'tld',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_contact" />,
            dataIndex: 'domainContact',
            key: 'domainContact',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_date" />,
            dataIndex: 'categorizeDate',
            key: "categorizeDate",
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_word" />,
            dataIndex: 'categoryId',
            key: 'categoryId',
            defaultShow: true,
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            shouldCellUpdate:(record,prevRecord)=>{
                return true;
            },
            // ...getTableColumnFilters(ECColumnInfo.ec_category_id_word.dataIndex, 'ec_category_id' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //     })
            //     return promise
            // }),
            render: (value, record,index) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                const result = WSCategoryType.find(item=>{
                    return item.value === value
                })
                return result.name[localeKey];
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
            dataIndex: 'remarks',
            key: 'remarks',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.redirect_url" />,
            dataIndex: 'redirectURL',
            key: 'redirectURL',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_categorize_remarks.name, intl),
            title: <FormattedMessage id="app.ui.wslist.table.whois" />,
            dataIndex: 'whois',
            key: 'whois',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_created_at" />,
            dataIndex: 'domainCreatedAt',
            key: 'domainCreatedAt',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_expire_at" />,
            dataIndex: 'domainExpireAt',
            key: 'domainExpireAt',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_updated_at" />,
            dataIndex: 'domainUpdatedAt',
            key: 'domainUpdatedAt',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_action" />,
            defaultShow: true,
            dataIndex: 'action',
            key: 'clientAction',
            width: 250,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_response_action.dataIndex, 'ec_response_action' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //         let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            //         let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //         let takedownRequestFilters = TakedownRequest.map((action) => {
            //             return {
            //                 text: action.name[localeKey],
            //                 value: action.value
            //             }
            //         })
            //         let filters = blank.concat(takedownRequestFilters)
            //         resolve({ filters })
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = WSTakedownRequest.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_reason" />,
            dataIndex: 'responseRemarks',
            key: 'responseRemarks',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_trademark" />,
            dataIndex: 'trademarkId',
            key: 'trademarkId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_response_trademark.dataIndex, 'ec_response_trademark' + currentSearchClientId, () => {
            //     let promise = new Promise((resolve, reject) => {
            //         let trademarksInfo = allTrademarksInfo["trademarksInfo_" + currentSelectClientId];
            //         if (trademarksInfo) {
            //             let filters = trademarksInfo.map((trademark) => {
            //                 return {
            //                     text: trademark.trademark_no,
            //                     value: trademark.trademark_id
            //                 }
            //             })
            //             let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //             filters = blank.concat(filters)
            //             resolve({ filters })
            //         }
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                let trademarksInfo = allTrademarksInfo["trademarksInfo_" + currentSelectClientId];
                if (trademarksInfo) {
                    let trademark = trademarksInfo.find((trademark) => {
                        return trademark.trademark_id === value
                    })
                    let content = trademark ? trademark.trademark_no : value;
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult(value)
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_date" />,
            dataIndex: 'responseDate',
            key: 'responseDate',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return ""
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_date" />,
            dataIndex: 'process1Date',
            key: 'process1Date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_result" />,
            dataIndex: 'process1ResultId',
            key: 'process1ResultId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_process1_result.dataIndex, 'ec_process1_result' + currentSearchClientId, () => {
            //     let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            //     let promise = new Promise((resolve, reject) => {
            //         let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //         let takedownResultFilters = TakedownResult.map((result) => {
            //             return {
            //                 text: result.name[localeKey],
            //                 value: result.value
            //             }
            //         })
            //         let filters = blank.concat(takedownResultFilters)
            //         resolve({ filters })
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = WSTakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_confirm_date" />,
            dataIndex: 'process1ConfirmDate',
            key: 'process1ConfirmDate',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD");
                    return wrapperRenderResult(content)

                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_date" />,
            dataIndex: 'process2Date',
            key: 'process2Date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_result" />,
            dataIndex: 'process2ResultId',
            key: 'process2ResultId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_process2_result.dataIndex, 'ec_process2_result' + currentSearchClientId, () => {
            //     let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            //     let promise = new Promise((resolve, reject) => {
            //         let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //         let takedownResultFilters = TakedownResult.map((result) => {
            //             return {
            //                 text: result.name[localeKey],
            //                 value: result.value
            //             }
            //         })
            //         let filters = blank.concat(takedownResultFilters)
            //         resolve({ filters })
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = WSTakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)

            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_confirm_date" />,
            dataIndex: 'process2ConfirmDate',
            key: 'process2ConfirmDate',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_date" />,
            dataIndex: 'process3Date',
            key: 'process3Date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_result" />,
            dataIndex: 'process3ResultId',
            key: 'process3ResultId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_process3_result.dataIndex, 'ec_process3_result' + currentSearchClientId, () => {
            //     let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            //     let promise = new Promise((resolve, reject) => {
            //         let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //         let takedownResultFilters = TakedownResult.map((result) => {
            //             return {
            //                 text: result.name[localeKey],
            //                 value: result.value
            //             }
            //         })
            //         let filters = blank.concat(takedownResultFilters)
            //         resolve({ filters })
            //     })
            //     return promise
            // }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = WSTakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_confirm_date" />,
            dataIndex: 'process3ConfirmDate',
            key: 'process3ConfirmDate',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("");
            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_result" />,
            defaultShow: true,
            dataIndex: 'processResultId',
            key: 'processResultId',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            // ...getTableColumnFilters(ECColumnInfo.ec_process_result.dataIndex, 'ec_process_result' + currentSearchClientId, () => {
            //     let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
            //     let promise = new Promise((resolve, reject) => {
            //         let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
            //         let takedownResultFilters = TakedownResult.map((result) => {
            //             return {
            //                 text: result.name[localeKey],
            //                 value: result.value
            //             }
            //         })
            //         let filters = blank.concat(takedownResultFilters)
            //         resolve({ filters })
            //     })
            //     return promise

            // }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = WSTakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_confirm_date" />,
            defaultShow: true,
            dataIndex: 'processConfirmDate',
            key: 'processConfirmDate',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)

                }
                return wrapperRenderResult("")

            },
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_remarks" />,
            dataIndex: "processRemarks",
            key: 'processRemarks',
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.common.action" />,
            defaultShow: true,
            key: 'actions',
            fixed: "right",
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {
                        if (onDetailClick) {
                            onDetailClick(record)
                        }
                    }
                    }>
                        <FormattedMessage id="app.common.action.detail" />
                    </Button>
                    {/* <Button type="link" onClick={() => {
                        if (onHistoryClick) {
                            onHistoryClick(record)
                        }
                    }}>
                        <FormattedMessage id="app.ui.eclist.table.action_history" />
                    </Button> */}
                </Space>
            ),
        },
    ]

    return columns.map(column => {
        if (allSearchableCoulumns.some(searchItem => searchItem.value === column.dataIndex)) {
            return {
                ...column,
                render: (value, record) => {
                    return renderSearchResultHighLight(value, record, column.dataIndex, searchWords, searchColumns)
                },
            }
        } else {
            return column
        }
    })
}

export default getBasicColumns;