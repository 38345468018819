import { ProFormSelect } from '@ant-design/pro-form'
import './index.less'
import { Image, Select, Table } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'react-intl'
import commonMessage from '../../../../locales/messages'
import messages from '../../messages'
import { FormattedMessage } from 'react-intl'
import { LngLat } from 'mapbox-gl'
import Item from 'antd/lib/list/Item'
import { InfringementCommonCategories } from '../../../../config/infringement-common-category.js'
const EFCChartStacking = (props) => {
    const [activeItem, setActiveItem] = useState(0)
    const [selectItems, setSelectItems] = useState(0)
    const [loading, setLoading] = useState(false)
    // 国际化配置
    const intl = useIntl()    // 国际化配置
    let nameIntlKey = intl.formatMessage(
        commonMessage['locale.name_locale_key']
    )
    const color = ["#0069EB", "#7DCDD2", "#FFAA8E", "#949AFF", "#FBC48A", "#4C96F1", "#99C3F7"]
    const slideRef = useRef()
    const slideLeft = useRef([])
    const title = props.chartTitle
    const desc = props.chartDesc
    const mode = useRef([
        {
            wait: '/images/bar.png',
            active: '/images/bar-active.png'
        }, {
            wait: '/images/line.png',
            active: '/images/line-active.png'
        }, {
            wait: '/images/list.png',
            active: '/images/list-active.png'
        }])
    const xAxis = useRef([])
    const yAxis = useRef([])
    const zAxis = useRef([])
    const firstLabel = useRef([])
    const [selectedFirstItems, SetSelectedFirstItems] = useState([])
    const filteredFirstOptions = firstLabel.current.filter((o) => !selectedFirstItems.includes(o.name))
    const secondLabel = useRef([])
    const [selectedSecondItems, SetSelectedSecondItems] = useState([])
    const filteredSecondOptions = secondLabel.current.filter((o) => !selectedSecondItems.includes(o))

    // 得到label值
    useEffect(() => {
        if (Object.keys(props.chartData).length > 0) {
            xAxis.current = props.chartData.xAxis
            yAxis.current = props.chartData.yAxis
            zAxis.current = props.chartData.zAxis
            if (Object.keys(yAxis.current).length > 0) {
                let keys = Object.keys(yAxis.current)
                firstLabel.current = props.chartData.selectName.map((item, index) => {
                    return { value: item.value, name: item.name }
                })
                secondLabel.current = Object.keys(yAxis.current[keys[0]])
                SetSelectedFirstItems(firstLabel.current.map((item, index) => {
                    return item.value
                }))
                SetSelectedSecondItems(secondLabel.current)
            } else {
                firstLabel.current = []
                secondLabel.current = []
                SetSelectedFirstItems(firstLabel.current)
                SetSelectedSecondItems(secondLabel.current)
            }
        }
    }, [props])

    const chartData = props.chartData

    // const selectActive = useRef(Array(fields.length).fill(0))
    const onTabClick = (index) => {
        props.onTabClick(index)
        if (index !== activeItem) {
            setActiveItem(index)
            slideRef.current.style.left = slideLeft.current[index] + 'px'
        }
    }

    const initData = () => {
        let currentObj = {}
        zAxis.current.map((item, index) => {
            currentObj[item] = {}
            xAxis.current.map((item1, index1) => {
                currentObj[item][item1] = 0
            })
        })
        return currentObj
    }

    const getData = useMemo(() => {
        let obj = initData()
        selectedFirstItems.map((item, index) => {
            selectedSecondItems.map((item1, index1) => {
                let currentObj = yAxis.current[item]
                if (currentObj && currentObj[item1]) {
                    for (let key1 in currentObj[item1]) {
                        for (let key2 in currentObj[item1][key1]) {
                            if (currentObj[item1][key1][key2] != undefined && currentObj[item1][key1][key2] !== null) {
                                obj[key2][key1] += currentObj[item1][key1][key2]
                            }
                        }
                    }
                } else {

                }
            })
        })
        return obj
    }, [selectedFirstItems, selectedSecondItems])

    const handleChange = (value, index) => {
        console.log(value)
        if (index === 1) {
            SetSelectedFirstItems(value)
            if (value && value.length > 0) {
                secondLabel.current = []
                value.map((item) => {
                    let newArr = Object.keys(yAxis.current[item])
                    const filteredArray = newArr.filter(item1 => !secondLabel.current.includes(item1))
                    secondLabel.current.push(...filteredArray)
                })
                let array = []
                secondLabel.current.map((item, index) => {
                    let element = selectedSecondItems.find((item1) => {
                        return item1 === item
                    })
                    if (element) {
                        array.push(element)
                    }
                })
                SetSelectedSecondItems(array)
            } else {
                SetSelectedFirstItems([])
                secondLabel.current = []
                SetSelectedSecondItems([])
            }
        }
        if (index === 2) {
            SetSelectedSecondItems(value)
        }
    }

    const renderTab = () => {
        return (
            <div className='ef-chart-slide-section'>
                <div className='ef-chart-title-select'>
                    <div className='ef-chart-title-select-slide' ref={slideRef}></div>
                    {mode.current.map((item, index) => {
                        return (
                            <div className='ef-chart-title-select-item' onClick={() => { onTabClick(index) }}>
                                <Image className='ef-chart-select-item-image' preview={false} src={index === activeItem ? item.active : item.wait} width={20}></Image>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderSelect = () => {
        return <div className='ef-chart-title-dropdown'>
            <div>
                <p className='ef-title-tip ef-chart-dropdown-describe'>{intl.formatMessage(
                    messages['filter.locale_region_filtering.title']
                )}</p>
                <Select
                    maxTagCount='responsive'
                    mode="tags"
                    className='ef-chart-dropdown-items'
                    style={{
                        width: 240,
                    }}
                    value={selectedFirstItems}
                    onChange={(value) => handleChange(value, 1)}
                    options={filteredFirstOptions.map((item) => ({
                        value: item.value,
                        label: item.name[nameIntlKey],
                    }))}
                />
            </div>
            <div>
                <p className='ef-title-tip ef-chart-dropdown-describe'>{intl.formatMessage(messages['filter.website_filtering.title'])}</p>
                <Select
                    maxTagCount='responsive'
                    mode="tags"
                    className='ef-chart-dropdown-items'
                    style={{
                        width: 240,
                    }}
                    value={selectedSecondItems}
                    onChange={(value) => handleChange(value, 2)}
                    options={filteredSecondOptions.map((item) => ({
                        value: item,
                        label: item,
                    }))}
                />
            </div>
        </div>
    }

    const renderContent = () => {
        switch (activeItem) {
            case 0:
                return renderCrenellation()
            case 1:
                return renderLine()
            case 2:
                return renderTable()
            case null:
                return renderCrenellation()
        }
    }

    const renderCrenellation = () => {
        let array = []
        zAxis.current.map((item, index) => {
            let currentItem = InfringementCommonCategories.find(item1 => item1.id === item)
            if (currentItem) {
                array.push({ name: currentItem.name[nameIntlKey], id: currentItem.id })
            }
        })
        if (xAxis.current.length > 0 && array.length > 0) {
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    fontFamily: 'Bebas', // 设置字体为Bebas
                },
                legend: {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#595C72',
                    },
                    bottom: 4,
                    itemGap: 15,
                    itemWidth: 13,
                    itemheight: 7
                },
                grid: {
                    top: '7%',
                    left: '2%',
                    right: '2%',
                    bottom: '25%',
                    containLabel: true
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        minValueSpan: 0,
                        top: '77.5%',
                        height: 27,
                        orient: 'horizontal',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                xAxis: {
                    type: 'category',
                    data: xAxis.current,
                    offset: 3,
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#EBEDF2',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDCE0',
                            width: 1.5
                        }
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            var date = new Date(value)
                            var month = date.getMonth() + 1
                            if (index === 0 || month === 1) {
                                return value
                            } else {
                                return month.toString().padStart(2, '0')
                            }
                        },
                        color: '#595C72',
                        fontSize: 10
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 10
                    },
                    offset: 10,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#EBEDF2',
                            type: 'dotted',
                            width: 2
                        }
                    },
                },
                series:
                    array.map((item, index) => {
                        return {
                            name: item.name,
                            type: 'bar',
                            stack: 'total',
                            // 不在柱条内部显示数据
                            label: {
                                show: false,
                            },
                            barCategoryGap: '64%',
                            emphasis: {
                                focus: 'series'
                            },
                            data: Object.values(getData[item.id]),
                            itemStyle: {
                                color: color[index]
                            },
                        }
                    }),
            }
            return <ReactEcharts option={option} echarts={echarts} notMerge={true} lazyUpdate={true} style={{ width: '100%', height: '100%' }}
                onChartReady={chart => {
                    // 在图表准备好后回调函数中可以执行一些操作，例如重绘图表
                    chart.resize() // 重绘图表
                }}>
            </ReactEcharts>
        } else {
            return <div></div>
        }
    }

    const renderLine = () => {
        let array = []
        zAxis.current.map((item, index) => {
            let currentItem = InfringementCommonCategories.find(item1 => item1.id === item)
            if (currentItem) {
                array.push({ name: currentItem.name[nameIntlKey], id: currentItem.id })
            }
        })
        if (xAxis.current.length > 0 && array.length > 0) {
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                },
                legend: {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#595C72',
                    },
                    bottom: 4,
                    itemGap: 15,
                },
                grid: {
                    top: '10%',
                    left: '2%',
                    right: '2%',
                    bottom: '25%',
                    containLabel: true
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        minValueSpan: 0,
                        height: 27,
                        top: '77.5%',
                        orient: 'horizontal',
                        filterMode: 'filter' // 设置为filter模式，使得在拖动滑块时可以连续滑动
                    }
                ],
                xAxis: {
                    type: 'category',
                    data: xAxis.current,
                    axisLabel: {
                        formatter: function (value, index) {
                            var date = new Date(value)
                            var month = date.getMonth() + 1
                            if (index === 0 || month === 1) {
                                return value
                            } else {
                                return month.toString().padStart(2, '0')
                            }
                        },
                        color: '#595C72',
                        fontSize: 10
                    },
                    offset: 3,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDCE0',
                            width: 1.5
                        }
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#EBEDF2',
                            width: 2,
                            type: 'solid'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    offset: 10,
                    axisLabel: {
                        color: '#595C72',
                        fontSize: 10
                    },
                },
                series: array.map((item, index) => {
                    return {
                        name: item.name,
                        smooth: true,
                        type: 'line',
                        // 不在柱条内部显示数据
                        data: Object.values(getData[item.id]),
                    }
                }),
            }
            return <ReactEcharts option={option} echarts={echarts} notMerge={true} 和 lazyUpdate={true} style={{ width: '100%', height: '100%' }} onChartReady={chart => {
                // 在图表准备好后回调函数中可以执行一些操作，例如重绘图表
                chart.resize() // 重绘图表
            }}></ReactEcharts>
        } else {
            return <div></div>
        }
    }

    const renderTable = () => {
        let array = []
        zAxis.current.map((item, index) => {
            let currentItem = InfringementCommonCategories.find(item1 => item1.id === item)
            if (currentItem) {
                array.push({ name: currentItem.name[nameIntlKey], id: currentItem.id })
            }
        })
        if (xAxis.current.length > 0 && array.length > 0) {
            const columns = [
                {
                    title: '#',
                    width: 300,
                    dataIndex: '#',
                    key: '#',
                    fixed: 'left', // 将第一列固定
                }
            ]
            for (let i = 0; i < xAxis.current.length; i++) {
                columns.push({
                    title: xAxis.current[i],
                    width: 130,
                    dataIndex: xAxis.current[i],
                    key: xAxis.current[i]
                })
            }
            const dataSource = []
            for (let i = 0; i < array.length; i++) {
                let obj = {}
                obj = { id: i, '#': array[i]['name'] }

                let keys = Object.keys(getData[array[i]['id']])
                console.log(keys)
                for (let j = 0; j < keys.length; j++) {
                    obj[keys[j]] = getData[array[i]['id']][keys[j]]
                }
                dataSource.push(obj)
            }
            return (
                <Table
                    className='ef-chart-stacking-table'
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    style={{ width: '100%', height: '100%' }}
                    scroll={{ x: 'max-content', y: 355 }}
                    loading={loading}
                    bordered={false}
                    rowClassName={(record, index) => index % 2 === 0 ? 'even' : 'odd'}
                >
                </Table>
            )
        } else {
            return <div ></div>
        }
    }

    useEffect(() => {
        for (let i = 0; i < mode.current.length; i++) {
            slideLeft.current[i] = i * 56 + (i + 1) * 3
        }
        if (slideRef.current) {
            slideRef.current.style.left = slideLeft.current[0] + 'px'
        }
        // checkData()
    }, [])

    return (
        <div
            className="ef-chart-stacking"
            style={{
                height: props.height ? props.height + 'px' : '391px',
            }}>
            <div className="ef-title-regular ef-chart-title">
                <p className='ef-chart-title-text'>{title && <FormattedMessage id={title} />}</p>
                <div className='ef-chart-title-right'>
                    {renderSelect()}
                    {renderTab()}
                </div>
            </div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 10 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFCChartStacking