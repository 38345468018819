import { defineMessages } from 'react-intl'

const messages = defineMessages({
    'message.error.name_empty': {
        id: 'app.ui.manager_analyst_new.message.error.name_empty',
        defaultMessage: '名前を入力ください。'
    },
    "message.error.password_fail": {
        id: "app.ui.manager_analyst_new.message.error.password_fail",
        defaultMessage: "パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。",
    },
    "message.error.password_unequal": {
        id: "app.ui.manager_analyst_new.message.error.password_unequal",
        defaultMessage: "新パスワードと新パスワード（確認）は同じではなく、確認してください。",
    },
    "message.error.email_fail": {
        id: "app.ui.manager_analyst_new.message.error.email_fail",
        defaultMessage: "正しいメールを入力してください。",
    },
    "message.error.role_empty": {
        id: "app.ui.manager_analyst_new.message.error.role_empty",
        defaultMessage: "ロールを選んでください。",
    },
    "message.error.phone_fail": {
        id: "app.ui.manager_analyst_new.message.error.phone_fail",
        defaultMessage: "正しい電話番号を入力してください。",
    },

    "message.error.create_fail": {
        id: "app.ui.manager_analyst_new.message.error.create_fail",
        defaultMessage: "新規登録失敗しました。",
    },
})

export default messages