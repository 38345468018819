import { Button, Image, Space, Tag, Dropdown, Modal, Tooltip } from "antd"
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import { MoreOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import messages from "./messages.js"

import { ECCrawlerPurpose, ECCrawlerStatus } from "../common/master-data.js"
import moment from 'moment'

// import { Icon } from 'antd'
import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
    scriptUrl: '/iconfont/iconfont.js',
})


export const getColumns = (config) => {
    const genItemsWithRecord = (record) => {
        const items = [
            {
                key: '1',
                label: <a><FormattedMessage id="app.common.action.delete" /></a>,
                disabled: record.status === ECCrawlerStatus.scraying.value,
                onClick: () => {
                    Modal.confirm({
                        className: 'ef-modal',
                        title: config.intl.formatMessage({ id: 'app.common.warning.delete' }),
                        okText: config.intl.formatMessage({ id: 'app.common.confirm.delete' }),
                        cancelText: config.intl.formatMessage({ id: 'app.common.cancel.delete' }),
                        onOk: (close) => {
                            if (config && config.onHandleDelete) {
                                config.onHandleDelete(record)
                            }
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })
                }
            },
            {
                key: '2',
                label: <a> <FormattedMessage id="app.common.action.start" /></a>,
                disabled: record.status !== ECCrawlerStatus.new.value && record.status !== ECCrawlerStatus.finished.value,
                onClick: () => {
                    if (config && config.onHandleStart) {
                        config.onHandleStart(record)
                    }
                }
            },
            {
                key: '3',
                label: <a><FormattedMessage id="app.common.action.stop" /></a>,
                onClick: () => {
                    Modal.confirm({
                        className: 'ef-modal',
                        title: config.intl.formatMessage({ id: 'app.common.warning.stop' }),
                        okText: config.intl.formatMessage({ id: 'app.common.confirm.stop' }),
                        cancelText: config.intl.formatMessage({ id: 'app.common.cancel.stop' }),
                        onOk: (close) => {
                            if (config && config.onHandleStop) {
                                config.onHandleStop(record)
                            }
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })

                }
            },
            {
                key: '4',
                label: <a><FormattedMessage id="app.common.action.download" /></a>,
                disabled: record.status !== ECCrawlerStatus.finished.value,
                children: Object.keys(ECCrawlerPurpose).map(key => {
                    return {
                        key: key,
                        label: ECCrawlerPurpose[key].text,
                        value: ECCrawlerPurpose[key].value,
                        onClick: () => {
                            if (config && config.onHandleDownload) {
                                config.onHandleDownload(record, ECCrawlerPurpose[key].value)
                            }
                        }
                    }
                }),
            },
            {
                key: '5', label: <a><FormattedMessage id="app.common.action.clone" /></a>,
                onClick: () => {
                    if (config && config.onHandleClone) {
                        config.onHandleClone(record)
                    }
                }

            }
        ]
        return items
    }

    return [
        {
            title: <FormattedMessage id="app.ui.ec_task.table.status" />,
            dataIndex: "status",
            key: "status",
            width: 100,
            fixed: true,
            filterRender: () => {
                return [
                    {
                        value: ECCrawlerStatus.scraying.value,
                        text: ECCrawlerStatus.scraying.text
                    }, {
                        value: ECCrawlerStatus.finished.value,
                        text: ECCrawlerStatus.finished.text
                    }, {
                        value: ECCrawlerStatus.error.value,
                        text: ECCrawlerStatus.error.text
                    }, {
                        value: ECCrawlerStatus.new.value,
                        text: ECCrawlerStatus.new.text
                    }
                ]
            },
            render: (text, record) => {
                if (text === ECCrawlerStatus.scraying.value) {
                    return (<Tag className="ef-tab" icon={<SyncOutlined spin />} color="processing">{ECCrawlerStatus.scraying.text}</Tag>)
                } else if (text === ECCrawlerStatus.finished.value) {
                    return (<Tag className="ef-tab" icon={<CheckCircleOutlined />} color="success">{ECCrawlerStatus.finished.text}</Tag>)
                } else if (text === ECCrawlerStatus.error.value) {
                    return (<Tag className="ef-tab" icon={<CheckCircleOutlined />} color="success">{ECCrawlerStatus.error.text}</Tag>)
                } else if (text === ECCrawlerStatus.pendding.value) {
                    return (<Tag className="ef-tab" icon={<ClockCircleOutlined />} color="geekblue">{ECCrawlerStatus.pendding.text}</Tag>)
                } else {
                    return (<Tag className="ef-tab" color="default">{ECCrawlerStatus.new.text}</Tag>)
                }
            }
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.id" />,
            dataIndex: "id",
            key: "taskid",
            width: 140,
            showSorterTooltip: false,
            sorter: true,
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.clientId" />,
            dataIndex: "clientId",
            key: "clientId",
            width: 180,
            showSorterTooltip: false,
            sorter: true,
            // filters: config && config["clients"] ? config["clients"] : null,
            // filterMultiple: true,
            filterRender: () => {
                return config && config["clients"] ? config["clients"] : null
            },
            render: (text, record) => {
                const clients = config["clients"]
                if (clients) {
                    const obj = clients.find((client) => {
                        return client.value === text
                    })
                    return obj ? obj.text : text
                } else {
                    return text
                }
            }
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.title" />,
            dataIndex: "title",
            key: "title",
            width: 220,
            showSorterTooltip: false,
            sorter: true,
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.type" />,
            dataIndex: "type",
            key: "type",
            // filters: Object.keys(ECCrawlerPurpose).map(key => {
            //     return {
            //         text: ECCrawlerPurpose[key].text,
            //         value: ECCrawlerPurpose[key].value,
            //     }
            // }),
            filterRender: () => {
                return Object.keys(ECCrawlerPurpose).map(key => {
                    return {
                        text: ECCrawlerPurpose[key].text,
                        value: ECCrawlerPurpose[key].value,
                    }
                })
            },
            // filterMultiple: true,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text === ECCrawlerPurpose.dashboard.value) {
                    return <FormattedMessage id="app.ui.ec_task.table.info.extraction.dashboard" />
                } else if (text === ECCrawlerPurpose.monitor.value) {
                    return <FormattedMessage id="app.ui.ec_task.table.info.extraction.price_monitoring_format" />
                } else {
                    return <FormattedMessage id="app.ui.ec_task.table.info.extraction.download" />
                }
            }
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.planStartDate" />,
            dataIndex: "planStartDate",
            key: "planStartDate",
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text) {
                    return moment(text).format("YYYY-MM-DD")
                } else {
                    return ""
                }
            }
        },
        // {
        //     title: "完了日",
        //     dataIndex: "finishDate",
        //     key: "finishDate",
        //     width: 160,
        //     fixed: false,
        //     showSorterTooltip: false,
        //     sorter: true,
        //     render: (text, record) => {
        //         if (text) {
        //             return moment(text).format("YYYY-MM-DD")
        //         } else {
        //             return ""
        //         }
        //     }
        // },
        {
            title: <FormattedMessage id="app.ui.ec_task.table.total" />,
            dataIndex: "total",
            key: "total",
            width: 140,
            showSorterTooltip: false,
            sorter: true,
            render: (text, record) => {
                if (text) {
                    return text
                } else {
                    if (record.subtasks) {
                        return record.subtasks.reduce((pre, subtask) => {
                            return pre + subtask.recordCount
                        }, 0)
                    } else {
                        return 0
                    }
                }
            }
        }, {
            title: <FormattedMessage id="app.ui.ec_task.table.remarks" />,
            dataIndex: "remarks",
            key: "remarks",
            width: 300,
            showSorterTooltip: false,
        }, {
            title: <FormattedMessage id="app.common.action" />,
            dataIndex: "action",
            key: "action",
            align: 'center',
            width: 190,
            fixed: "right",
            showSorterTooltip: true,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title={config.intl.formatMessage({ id: 'app.common.action.detail' })}>
                        <Button
                            type="link"
                            className="ef-sidefun"
                            icon={<IconFont className="iconfont iconfont-active" type="icon-xiangqing" />}
                            onClick={() => {
                                if (config && config.onHandleDetail) {
                                    config.onHandleDetail(record)
                                }
                            }
                            }>
                        </Button>
                    </Tooltip>
                    <Tooltip title={config.intl.formatMessage({ id: 'app.common.action.submenu' })}>
                        <Button
                            type="link"
                            className="ef-sidefun"
                            icon={<IconFont className="iconfont iconfont-active" type="icon-zhuangtai" />}
                            onClick={() => {
                                if (config && config.onHandleSubtasks) {
                                    config.onHandleSubtasks(record)
                                }
                            }}
                        >
                        </Button>
                    </Tooltip>
                    <Dropdown style={{ padding: '10px' }} placement={'bottomRight'} arrow={true} menu={{ items: genItemsWithRecord(record) }}>
                        <Space>
                            <Button className="ef-sidefun" icon={<IconFont className="iconfont iconfont-active" type="icon-gengduo" />}></Button>
                        </Space>
                    </Dropdown>
                </Space>
            ),
        }
    ]
}

export const getAction = () => {

}