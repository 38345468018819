import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import efstore from "./store";
import { Provider } from 'react-redux';

ReactDOM.render(
    (
        <Provider store={efstore}>
            <App />
        </Provider>
    ),
    document.getElementById("root")
);