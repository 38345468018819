import * as types from "../action-types";
import { reqUpdateUserSettings } from "../../api/user";

export const changeLocale = (locale, userid) => {
    if(userid){
        reqUpdateUserSettings({ userid, locale }).then((response)=>{
        })
    }
    return {
        type: types.LOCALE_KEY,
        locale: locale
    };
};

export const changeDefalutDisplayEcColomns = (comlums, userid) => {
    reqUpdateUserSettings({ userid, ec_columns: comlums })
    return {
        type: types.DEFALUT_DISPLAY_EC_COLUMNS,
        comlums: comlums
    };
};
