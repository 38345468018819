import mapboxgl from '!mapbox-gl';// eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import "./index.less";

import { Row, Col, Select, Button, Table, List, Skeleton } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";

import { reqFetchHomeInfo } from "@/api/ec";
import { reqFetchIPFNews } from '@/api/ipf-news.js'
import { reqFetchClients } from '@/api/analyst-client'
import { genIPRCountryChartConfig } from './chart-options/ipr-country.js'
import { genTestPurchartBarConfig } from './chart-options/test-purchase-options.js'

import HintContainer from './hint-container/index.jsx'
import ECChartContainer from "../common/chart-container/index.jsx"

import * as echarts from 'echarts/core';
import { BarChart, PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, VisualMapComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { FormattedMessage } from 'react-intl';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, VisualMapComponent, BarChart, CanvasRenderer, PieChart]);
mapboxgl.accessToken = 'pk.eyJ1IjoiY29uZ2NoaWNhZ28iLCJhIjoiY2t4c3V2b2NvNTBoajJ1cHpyb2l0bHZ3MiJ9.2Jkc9_TADo_YzhHZxm46Lw';

class EFHomeBackup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: {},
            news: [],
            newsLoading: true
        }
        // seller
        this.onlineSellermapContainer = React.createRef();
        this.onlineSellermap = React.createRef();

        // online takedown
        this.onlineActionMapContainer = React.createRef();
        this.onlineActionMap = React.createRef();

    }
    componentDidMount() {

        if (this.props.role & 1) {
            this.clientId = this.props.companyInfo.client_id;
            setTimeout(() => {
                this.updateUI()
            }, 1 * 1000);
        }

        if (this.props.role & 2) {
            reqFetchClients().then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({
                        clients: data.data.client
                    });
                }
            }).catch((error) => {
            })
        }

        reqFetchIPFNews({ page: 1, pageSize: 5 }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({
                    news: data.data.news,
                    newsLoading: false
                })
            } else {
                this.setState({
                    newsLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                newsLoading: false
            })
        })

        // initialize map only once
        if (!this.onlineSellermap.current) {
            this.onlineSellermap.current = new mapboxgl.Map({
                container: this.onlineSellermapContainer.current,
                style: 'mapbox://styles/mapbox/light-v10',
                center: [120.930229378541, 23.7779779950014],//Taiwan 
                zoom: 0
            });
            this.onlineSellermap.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
        }

        if (!this.onlineActionMap.current) {
            this.onlineActionMap.current = new mapboxgl.Map({
                container: this.onlineActionMapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [120.930229378541, 23.7779779950014],//Taiwan 
                zoom: 3
            });
            this.onlineActionMap.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
        }
    }

    componentWillUnmount() {
        if (this.onlineSellermap.current) {
            this.onlineSellermap.current.remove()
        }
        if (this.onlineActionMap.current) {
            this.onlineActionMap.current.remove()
        }
    }

    setupIPRCountry = (data) => {
        genIPRCountryChartConfig({
            echarts: echarts,
            elementId: "ip-request",
            seriesPieData: data,
        })
    }


    setupOnlineSellerMap = (data) => {
        if (this.onlineSellermap.current && data) {
            if (this.onlineSellermap.current.getSource && this.onlineSellermap.current.getSource('countries')) {

            } else {
                this.onlineSellermap.current.addSource('countries', {
                    type: 'vector',
                    url: 'mapbox://mapbox.country-boundaries-v1'
                });
            }

            const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

            // Calculate color values for each country based on 'hdi' value
            let sortData = data.sort((a, b) => {
                return a.code > b.code ? -1 : 1
            })
            let maxData = sortData ? sortData[0] : 1;
            for (const row of data) {
                // Convert the range of data values to a suitable color
                let distance = row.sellerCount * 1.0 / maxData.sellerCount * 255
                distance = distance < 30 ? 30 : distance;
                const red = 255;
                const green = 255 - distance;
                const blue = 255 - distance;

                const color = `rgb(${red},${green},${blue})`;
                if (row['regionCode'].length > 0) {
                    matchExpression.push(row['regionCode'], color);
                }
            }


            // Last value is the default, used where there is no data
            matchExpression.push('rgba(255, 255, 255, 0)');

            if (this.onlineSellermap.current.getLayer && this.onlineSellermap.current.getLayer('countries-join')) {
                this.onlineSellermap.current.removeLayer('countries-join')
            }

            console.log(matchExpression)
            this.onlineSellermap.current.addLayer({
                'id': 'countries-join',
                'type': 'fill',
                'source': 'countries',
                'source-layer': 'country_boundaries',
                'paint': {
                    'fill-color': matchExpression
                }
            });
        }
    }

    setupTestPurchase = (data) => {
        // let { echarts, xAxisData, seriesData, elementId } = config
        if (data) {
            // site region count
            const allSites = data.map(item => {
                return item.site
            })

            const allRegions = data.map(item => {
                return item.region
            })
            const sites = Array.from(new Set(allSites))
            const regions = Array.from(new Set(allRegions))
            const tempData = {}
            for (const region of regions) {
                tempData[region] = sites.reduce((pre, site) => {
                    const newObj = { ...pre }
                    newObj[site] = 0
                    return newObj
                }, {})
            }

            for (const item of data) {
                tempData[item.region][item.site] = parseInt(item.count) + tempData[item.region][item.site]
            }

            const xAxis = Object.keys(tempData)
            const seriesData = []
            for (const site of sites) {
                const item = { name: site, data: [] }
                for (const region of regions) {
                    item.data.push(tempData[region][site])
                }
                seriesData.push(item)
            }
            genTestPurchartBarConfig({
                echarts: echarts,
                xAxisData: xAxis,
                seriesData: seriesData,
                elementId: "test-purchase",
            });

        }
    }

    setupOnlineActionMap = (data) => {
        if (this.onlineActionMap.current && data) {
            if (this.onlineActionMap.current.getLayer && this.onlineActionMap.current.getLayer('circle-layer')) {
                this.onlineActionMap.current.removeLayer('circle-layer')
            }
            if (this.onlineActionMap.current.getLayer && this.onlineActionMap.current.getLayer('label-layer')) {
                this.onlineActionMap.current.removeLayer('label-layer')
            }
            if (this.onlineActionMap.current.getSource && this.onlineActionMap.current.getSource('country-point')) {
                this.onlineActionMap.current.removeSource('country-point')
            }

            this.onlineActionMap.current.addSource('country-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    "features": Object.keys(data).map(name => {
                        return {
                            'type': 'Feature',
                            'properties': {
                                count: data[name].infringement
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': data[name].coordinates
                            }
                        }
                    })
                }
            });

            this.onlineActionMap.current.addLayer({
                'id': 'circle-layer',
                'type': 'circle',
                'source': 'country-point',
                'paint': {
                    'circle-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'count'],
                        100,
                        '#f39b90',
                        200,
                        '#ff1e00'
                    ],
                    'circle-opacity': 0.75,
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'count'],
                        100,
                        10,
                        200,
                        15
                    ]
                }
            });

            this.onlineActionMap.current.addLayer({
                'id': 'label-layer',
                'type': 'symbol',
                'source': 'country-point',
                'layout': {
                    'text-field': ['get', 'count'],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    'text-size': 12
                },
                'paint': {
                    'text-color': 'rgba(0,0,0,0.5)'
                }
            });
        }
    }

    updateUI = () => {
        reqFetchHomeInfo({ clientId: this.clientId }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ dataSource: data.data })
                setTimeout(() => {
                }, 1 * 1000);

                this.setupIPRCountry(data.data.iprByCountry)
                this.setupTestPurchase(data.data.testPurchaseData)
                this.setupOnlineSellerMap(data.data.countrySellerInfo ? data.data.countrySellerInfo : []);

            }
        }).catch(e => {

        });
    }

    onClientChange = (value) => {
        this.clientId = value;
    }

    onRenewHome = () => {
        this.updateUI()
    }

    showClientSelector = () => {
        if (this.props.role & 2) {
            if (this.state.clients) {
                return (
                    <div >
                        <span className="margin-left-gap">
                            <FormattedMessage id="app.ui.dashboard.search.brand" />
                        </span>
                        <Select allowClear style={{ width: 200 }} onChange={this.onClientChange} className="margin-left-gap">
                            {
                                this.state.clients.map((client) => {
                                    return <Select.Option key={`${client.client_id}${client.client_name}`} value={client.client_id}>{client.client_name}</Select.Option>
                                })
                            }
                        </Select>
                        <Button type="primary" onClick={this.onRenewHome}>
                            <FormattedMessage id="app.ui.dashboard.search.find" />
                        </Button>
                    </div>
                )
            }
            return (
                <div >
                    <span className="margin-left-gap">
                        <FormattedMessage id="app.ui.dashboard.search.brand" />
                    </span>
                    <Select allowClear style={{ width: 200 }} className="margin-left-gap">
                    </Select>
                    <Button type="primary" onClick={this.onRenewHome} disabled>
                        <FormattedMessage id="app.ui.dashboard.search.find" />
                    </Button>
                </div>
            )
        }
    }
    getTestPurchaseTotalCount = () => {
        if (this.state.dataSource && this.state.dataSource.testPurchaseData) {
            return this.state.dataSource.testPurchaseData.reduce((pre, item) => {
                const total = pre + parseInt(item.count)
                return total
            }, 0)
        } else {
            return 0
        }
    }

    componentDidUpdate() {
        this.setupIPRCountry()
        this.setupTestPurchase()
    }

    render() {
        return (
            <div className="home-container">
                {this.showClientSelector()}
                <Row>
                    <Col span={6} className="header-number-container">
                        <HintContainer title="Online Protection" subtitle={<FormattedMessage id="app.ui.home.online.cases" />} number={String(this.state.dataSource.onlineProtectionCount ? this.state.dataSource.onlineProtectionCount : 0)} borderType="yellow"></HintContainer>
                    </Col>
                    <Col span={6} className="header-number-container">
                        <HintContainer title="Offline Protection" subtitle={<FormattedMessage id="app.ui.home.offline.cases" />} number="0" borderType="blue"></HintContainer>
                    </Col>
                    <Col span={6} className="header-number-container">
                        <HintContainer title="IPR Registration" subtitle={<FormattedMessage id="app.ui.home.registration.cases" />} number={String(this.state.dataSource.iprRegistrationCount ? this.state.dataSource.iprRegistrationCount : 0)} borderType="green"></HintContainer>
                    </Col>
                    <Col span={6} className="header-number-container">
                        <HintContainer title="Test Purchase" subtitle={<FormattedMessage id="app.ui.home.test_purchase.cases" />} number={String(this.getTestPurchaseTotalCount())} borderType="pink"></HintContainer>
                    </Col>
                </Row>

                <Row className="row">
                    <Col span={12}>
                        <ECChartContainer title={<FormattedMessage id="app.ui.home.section.tm_register_region" />} >
                            <div className="chart-element" id="ip-request"></div>
                        </ECChartContainer>
                    </Col>
                    <Col span={12}>
                        <ECChartContainer title={<FormattedMessage id="app.ui.home.section.online_bad_seller" />} >
                            <div className="chart-element" id="infringement-seller" ref={this.onlineSellermapContainer}>
                            </div>
                        </ECChartContainer>
                    </Col>
                </Row>

                <Row className="row">
                    <Col span={12}>
                        <ECChartContainer title={<FormattedMessage id="app.ui.home.section.test_purchase_cases_number" />}>
                            <div className="chart-element" id="test-purchase"></div>
                        </ECChartContainer>
                    </Col>
                    <Col span={12}>
                        <ECChartContainer title={<FormattedMessage id="app.ui.home.section.offline_protection" />} >
                            <div className="chart-element" id="online-action" ref={this.onlineActionMapContainer}></div>
                        </ECChartContainer>
                    </Col>
                </Row>
                <Row className="row">
                    <Col span={24}>
                        <ECChartContainer title={<FormattedMessage id="app.ui.home.section.ipf_news" />} >
                            <div className="news-element" id="ipf-news">
                                <List
                                    className="demo-loadmore-list"
                                    loading={this.state.newsLoading}
                                    itemLayout="horizontal"
                                    dataSource={this.state.news}
                                    renderItem={item => (
                                        <List.Item>
                                            <div><a class="news-item" href={item.url} target="_blank" rel="noreferrer">{item.title}</a></div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </ECChartContainer>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.mainMenu,
    };
};


export default connect(mapStateToProps)(EFHomeBackup)