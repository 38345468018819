import './index.less'
import React, { useRef, useState, useEffect } from 'react'
import { Select, DatePicker, Input, Button, Form } from 'antd'
import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'
import messages from './message'
import { useIntl } from 'react-intl'
const { Option } = Select

// onSearch onReset --- External function
const EFCSearchBar1 = (props) => {
  // 国际化配置
  const intl = useIntl()
  const [error, setError] = useState()
  const matchTypes = useRef([
    {
      intlKey: 'app.components.ef_search_bar.search_tpye_fuzzy_match',
      name: '曖昧検索',
      value: 3,
    },
    {
      intlKey: 'app.components.ef_search_bar.search_tpye_exactly_match',
      name: '完全一致',
      value: 4,
    },
    {
      intlKey: 'app.components.ef_search_bar.search_tpye_previous_match',
      name: '前一致',
      value: 1,
    },
    {
      intlKey: 'app.components.ef_search_bar.search_tpye_post_match',
      name: '後一致',
      value: 2,
    },
  ])
  const matchItems = useRef(
    matchTypes.current.map((type) => {
      return type.name
    })
  )
  const formRef = useRef()

  // define six major fields, this includes the behavior of accepting props values
  const setupDefalutValues = () => {
    if (props.defaultValues && formRef.current) {
      if (props.defaultValues.wordFields) {
        formRef.current.setFieldsValue({
          keywordItems: props.defaultValues.wordFields,
        })
      } else {
        formRef.current.resetFields(['keywordItems'])
      }
      if (props.defaultValues.keyword) {
        formRef.current.setFieldsValue({
          searchWord: props.defaultValues.keyword,
        })
      } else {
        formRef.current.resetFields(['searchWord'])
      }

      if (props.defaultValues.searchType) {
        formRef.current.setFieldsValue({
          searchType: props.defaultValues.searchType,
        })
      } else {
        formRef.current.resetFields(['searchType'])
      }
      if (props.defaultValues.dateFields) {
        formRef.current.setFieldsValue({
          dateItems: props.defaultValues.dateFields,
        })
      } else {
        formRef.current.resetFields(['dateItems'])
      }
      if (props.defaultValues.startDate) {
        formRef.current.setFieldsValue({
          startDate: moment(props.defaultValues.startDate),
        })
      } else {
        formRef.current.resetFields(['startDate'])
      }
      if (props.defaultValues.endDate) {
        formRef.current.setFieldsValue({
          endDate: moment(props.defaultValues.endDate),
        })
      } else {
        formRef.current.resetFields(['endDate'])
      }
    }
  }

  // Execute custom behavior
  const handleSearchButtonClick = () => {
    props.onSearch()
  }

  // Execute custom behavior
  const handleResetButtonClick = () => {
    formRef.current.resetFields()
  }

  // render Select option
  const createSelectItems = (items) => {
    return items.map((item) => {
      return (
        <Option value={item.value} key={item.value}>
          {item.intlKey ? <FormattedMessage id={item.intlKey} /> : item.name}
        </Option>
      )
    })
  }

  const onFinish = (values) => {
    const {
      keywordItems,
      searchWord,
      searchType,
      dateItems,
      startDate,
      endDate,
    } = values
    let body = {}
    if (dateItems) {
      body.dateFields = dateItems
      if (startDate && endDate) {
        if (endDate < startDate) {
          return
        }
      }

      if (startDate) {
        body.startDate = moment(startDate).format('YYYY-MM-DD')
      }

      if (endDate) {
        body.endDate = moment(endDate).format('YYYY-MM-DD')
      }
    }

    if (keywordItems && searchWord && searchWord.trim().length > 0) {
      body.wordFields = keywordItems
      body.keyword = searchWord
      body.searchType = searchType
      if (searchType) {
        body.searchType = searchType
      }
    }
    props.onSearch(body)
  }

  const onFinishFailed = (values) => {
    const { errorFields } = values
    const { errors } = errorFields[0]
    setError(errors[0])
  }

  const onStartDateChange = () => {}

  const onEndDateChange = () => {}

  // create UI about Date section
  const createDateSelect = (items) => {
    if (items) {
      return (
        <div className="date-container">
          <div className="item-group">
            <p>
              <FormattedMessage id="app.components.ef_search_bar.date_item" />
            </p>
            <Form.Item name="dateItems" noStyle={true}>
              <Select
                mode="multiple"
                showArrow={true}
                allowClear
                className="date-items">
                {createSelectItems(items)}
              </Select>
            </Form.Item>
          </div>
          <div className="item-group">
            <p>
              <FormattedMessage id="app.components.ef_search_bar.date_from" />
            </p>
            <Form.Item name="startDate" noStyle={true}>
              <DatePicker onChange={onStartDateChange()} />
            </Form.Item>
          </div>
          <div className="item-group">
            <p>
              <FormattedMessage id="app.components.ef_search_bar.date_to" />
            </p>
            <Form.Item name="endDate" noStyle={true}>
              <DatePicker onChange={onEndDateChange()} />
            </Form.Item>
          </div>
        </div>
      )
    }
  }

  // create UI about search conditions
  const createDateSearch = () => {
    return (
      <div className="keyword-container">
        <div className="item-group">
          <p>
            <FormattedMessage id="app.components.ef_search_bar.search_item" />
          </p>
          <Form.Item name="keywordItems" noStyle={true}>
            <Select
              mode="multiple"
              showArrow={true}
              allowClear
              className="keyword-items">
              {createSelectItems(props.keywordItems)}
            </Select>
          </Form.Item>
          <Form.Item name="searchWord" noStyle={true}>
            <Input
              className="search-word"
              placeholder={intl.formatMessage(messages['search_hint'])}
            />
          </Form.Item>
          <Form.Item name="searchType" noStyle={true}>
            <Select allowClear className="search-type">
              {createSelectItems(matchTypes.current)}
            </Select>
          </Form.Item>
        </div>
        <div className="action-container">
          <Button type="primary" htmlType="submit" className="search-button">
            <FormattedMessage id="app.components.ef_search_bar.search_button" />
          </Button>
          <Button onClick={handleResetButtonClick} className="reset-button">
            <FormattedMessage id="app.components.ef_search_bar.reset_button" />
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setupDefalutValues()
  }, [])

  // Rendering UI function
  const renderUi = () => {
    setupDefalutValues()
    let mainUi = (
      <Form
        noStyle={true}
        key="search-bar"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        ref={formRef}>
        <div className="ef-c-searchbar-container">
          {createDateSelect(props.dateItems)}
          {createDateSearch()}
        </div>
      </Form>
    )
    return mainUi
  }

  return renderUi()
}
export default EFCSearchBar1
