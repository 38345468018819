import "./index.less"
import React, { Component } from "react";
import { Form, Input } from "antd";

import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';
import { reqCreateSpecialUrl, reqFetchSpecialUrls, reqRemoveSpecialUrl, reqUpdateSpecialUrl } from '../../../api/analyst-special-url'

import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'

class AnalystClientProductCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchSpecialUrls({
                clientId: this.props.clientId,
                type: this.props.urlType
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.urls });
                }

            }).catch((error) => {

            })

        }
    }

    createColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.id'}),
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.url'}),
                dataIndex: 'url',
                key: 'url',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"url_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: this.props.intl.formatMessage(messages['messages.error.special_url.url_empty']),
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },

            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.seller'}),  
                dataIndex: 'seller',
                key: 'seller',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"seller_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: this.props.intl.formatMessage(messages['messages.error.special_url.seller_name_empty']),  
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.sellerid'}),
                dataIndex: 'sellerId',
                key: 'sellerId',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"sellerId_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: this.props.intl.formatMessage(messages['messages.error.special_url.seller_id_empty']),
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.company'}),
                dataIndex: 'company',
                key: 'company',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"company_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: this.props.intl.formatMessage(messages['messages.error.special_url.company_empty']),
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.remarks'}),
                dataIndex: 'remarks',
                key: 'remarks',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"remarks_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: this.props.intl.formatMessage(messages['messages.error.special_url.remarks_empty']), 
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },

        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText name="url" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.url'})} rules={[
                    {
                        required: false,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.url_empty']),
                    },
                ]}
                />
                <ProFormText name="seller" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.seller'})} rules={[
                    {
                        required: false,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.seller_name_empty']),
                    },
                ]}
                />
                <ProFormText name="sellerId" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.sellerid'})} rules={[
                    {
                        required: false,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.seller_id_empty']),
                    },
                ]}
                />
                <ProFormText name="company" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.company'})} rules={[
                    {
                        required: false,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.company_empty']),
                    },
                ]}
                />
                <ProFormText name="remarks" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.remarks'})} rules={[
                    {
                        required: false,
                        message: this.props.intl.formatMessage(messages['messages.error.special_url.remarks_empty']), 
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["url_" + record.id] = record.url;
        row["seller_" + record.id] = record.seller;
        row["sellerId_" + record.id] = record.sellerId;
        row["company_" + record.id] = record.company;
        row["remarks_" + record.id] = record.remarks;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveSpecialUrl({
            urlId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }


    onUpdateClick = (record, values) => {
        reqUpdateSpecialUrl({
            urlId: record.id,
            url: values["url_" + record.id],
            seller: values["seller_" + record.id],
            sellerId: values["sellerId_" + record.id],
            company: values["company_" + record.id],
            remarks: values["remarks_" + record.id],
            type: this.props.urlType,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateSpecialUrl({
            url: values["url"],
            seller: values["seller"],
            sellerId: values["sellerId"],
            company: values["company"],
            remarks: values["remarks"],
            type: this.props.urlType,
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="ef-master-container">
                <EFCMasterTable
                    modalTitle={this.props.urlType === 1 ? this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.modal.title_black'}) : this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.special_url.modal.title_white'})}    
                    rowkey="id"      
                    columns={this.createColumns()} 
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default injectIntl(AnalystClientProductCategory)