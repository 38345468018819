// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.name_empty": {
        id: "app.ui.user_list_new.error.name_empty",
        defaultMessage: "名前を入力ください。",
    },
    "message.error.email_failed": {
        id: "app.ui.user_list_new.error.email_failed",
        defaultMessage: "正しいメールを入力してください。",
    },
    "message.error.password_failed": {
        id: "app.ui.user_list_new.error.password_failed",
        defaultMessage: "パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。",
    },
    "message.error.password2_failed": {
        id: "app.ui.user_list_new.error.password2_failed",
        defaultMessage: "新パスワードと新パスワード（確認）は同じではなく、確認してください。",
    },
    "message.error.phone_failed": {
        id: "app.ui.user_list_new.error.phone_failed",
        defaultMessage: "正しい電話番号を入力してください。",
    },
    "message.error.section_failed": {
        id: "app.ui.user_list_new.error.section_failed",
        defaultMessage: "正しい部署を入力ください。",
    },
    "message.error.remarks_failed": {
        id: "app.ui.user_list_new.error.remarks_failed",
        defaultMessage: "500字以内の備考を入力ください。",
    },
    "message.error.new_failed": {
        id: "app.ui.user_list_new.error.new_failed",
        defaultMessage: "ユーザー新規登録失敗しました。",
    },
})

export default messages