import "./index.less"
import React, { Component } from "react";
import { Form, Input, Select } from "antd";
import { reqCreateCustomColumn, reqFetchCustomColumns, reqRemoveCustomColumn, reqUpdateCustomColumn } from '../../../api/analyst-custom-column'
import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText, ProFormSelect } from '@ant-design/pro-form';
import { ColumnCategory } from "@/config/column-category.js";
import { setCustomColumnAction } from "@/store/actions/ec-master.js"

import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'

import store from "@/store";

class AnalystClientCustomColumn extends Component {

    constructor(props) {
        super(props)
        this.state = {
            datesource: []
        }
        if (this.props.clientId) {
            reqFetchCustomColumns({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.columns });
                    store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns));
                }
            }).catch((error) => {

            })
        }
    }

    createColumns = () => {
        return [{
            title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_id'}),   
            dataIndex: 'column_id',
            key: 'column_id',
        },
        {
            title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_name'}),
            dataIndex: 'column_name',
            key: 'column_name',
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"column_name_" + record.column_id}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_empty']), 
                            }
                        ]}>
                        <Input className="ef-form-element-normal"></Input>
                    </ Form.Item >
                )
            }
        },
        {
            title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_category'}),
            dataIndex: 'column_category',
            key: 'column_category',
            renderText: (record) => {
                for (let category of ColumnCategory) {
                    if (record.column_category === category.id) {
                        return category.name;
                    } 
                }
            },
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"column_category_" + record.column_id}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_category_empty']),  
                            }
                        ]}>
                        <Select className="ef-form-element-normal" style={{ width: 160 }} onChange={(value) => { this.onCategoryChange(value, record) }}>
                            {
                                ColumnCategory.map((category) => {
                                    return <Select.Option value={category.id}>{category.name}</Select.Option>
                                })
                            }
                        </Select>
                    </ Form.Item >
                )
            }
        },

        {
            title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_position'}),
            dataIndex: 'column_position',
            key: 'column_position',
            renderText: (record) => {
                for (let category of ColumnCategory) {
                    if (record.column_category === category.id) {
                        let result = category.items.find((item) => {
                            return item.key === record.column_position
                        })

                        return result ? result.title : "";
                    }
                }
            },
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"column_position_" + record.column_id}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_position_empty']),
                            }
                        ]}>
                        <Select className="ef-form-element-normal" style={{ width: 160 }}>
                            {
                                ColumnCategory.filter((category) => {
                                    let key = "edit_" + record.column_id + "_category"
                                    if (this.state[key]) {
                                        return this.state[key] === category.id
                                    } else {
                                        return record.column_category === category.id
                                    }
                                }).flatMap((category) => {
                                    return category.items;
                                }).map((item) => {
                                    return <Select.Option key={`${item.dataIndex}${item.title}`} value={item.dataIndex}>{item.title}</Select.Option>
                                })
                            }
                        </Select>

                    </ Form.Item >
                )
            }
        },

        {
            title: this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_order'}),
            dataIndex: 'column_order',
            key: 'column_order',
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"column_order_" + record.column_id}
                        rules={[
                            {
                                required: true,
                                message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_order_empty']),
                            }
                        ]}>
                        <Input className="ef-form-element-normal"></Input>
                    </ Form.Item >
                )
            }
        },]
    }

    onCategoryChange = (value, record) => {
        let key = "edit_" + record.column_id + "_category"
        let newState = {}
        newState[key] = value
        this.setState(newState)
    }

    onModelCategoryChange = (value) => {
        this.setState({ modelSelectCategory: value })
    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText name="column_name" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_name'})} rules={[
                    { 
                        required: true,
                        message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_empty']),
                    },
                ]}
                />
                <ProFormSelect
                    showSearch
                    options={
                        ColumnCategory.map((category) => {
                            return {
                                value: category.id,
                                label: category.name,
                                key: category.name
                            }
                        })
                    }
                    name="column_category"
                    label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_category'})}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_category_empty'])
                        },
                    ]}
                    fieldProps={{
                        onChange: this.onModelCategoryChange,
                        optionFilterProp: "children",
                        filterOption: (inputValue, options) => {
                            return options.key.indexOf(inputValue) > -1 ? true : false;
                        }
                    }}
                />

                <ProFormSelect
                    showSearch
                    fieldProps={{
                        optionFilterProp: "children",
                        filterOption: (inputValue, option) => {
                            return option.key.indexOf(inputValue) > -1 ? true : false;
                        }
                    }}
                    options={
                        ColumnCategory.filter((category) => {
                            return category.id === this.state.modelSelectCategory
                        }).flatMap((category) => {

                            return category.items.map((item) => {
                                return {
                                    value: item.key,
                                    label: item.title,
                                    key: item.title
                                }
                            })
                        })
                    }
                    name="column_position"
                    label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_position'})}
                    rules={[
                        {
                            required: true,
                            message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_position_empty']),
                        },
                    ]}
                />

                <ProFormText name="column_order" label={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.column_order'})} rules={[
                    {
                        required: true,  
                        message: this.props.intl.formatMessage(messages['messages.error.custom_column.item_order_empty']),
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["column_name_" + record.column_id] = record.column_name;
        row["column_category_" + record.column_id] = record.column_category;
        row["column_order_" + record.column_id] = record.column_order;
        row["column_position_" + record.column_id] = record.column_position;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveCustomColumn({
            columnId: record.column_id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.columns });
                store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns));
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {

        reqUpdateCustomColumn({
            clientId: this.props.clientId,
            columnId: record.column_id,
            name: values["column_name_" + record.column_id],
            category: parseInt(values["column_category_" + record.column_id]),
            position: values["column_position_" + record.column_id],
            order: parseInt(values["column_order_" + record.column_id]),
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.columns });
                store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns));
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateCustomColumn({
            clientId: this.props.clientId,
            name: values["column_name"],
            category: parseInt(values["column_category"]),
            position: values["column_position"],
            order: parseInt(values["column_order"]),
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.columns });
                store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns));
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="ef-master-container">
                <EFCMasterTable
                    modalTitle={this.props.intl.formatMessage({id:'app.ui.brand_reg_detail.custom_column.modal.title'})}
                    rowkey="column_id"
                    columns={this.createColumns()}
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}


export default injectIntl(AnalystClientCustomColumn)