import request from '@/utils/request'

export function reqFetchClients(data){
    return request({
        url: '/api/analyst/fetch-clients',
        method: 'post',
        data: data
    })
}

export function reqGetClient(data){
    return request({
        url: '/api/analyst/get-client',
        method: 'post',
        data: data
    })
}
