import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Input, Button, Alert } from 'antd'
import { connect } from 'react-redux'
import '../index.less'

import { reqCode, reqResetPassword } from '@/api/login'
import EFCCodeButton from '@/components/ef-c-code-button'
import { isValidPassword } from '../../../utils/ef-re'
import { FormattedMessage, injectIntl } from 'react-intl'
import messages from '../messages'

class ForgetPassViewBackup extends Component {
  formRef = React.createRef()
  state = {
    error: null,
    resetSuccess: false,
    resetCode: false,
  }

  onFinish = (values) => {
    const { email, password, passwordConfirm, code } = values
    if (!isValidPassword(password)) {
      this.setState({
        error: this.props.intl.formatMessage(
          messages['message.error.password_character']
        ),
        resetCode: true,
      })
      return
    }
    if (password !== passwordConfirm) {
      this.setState({
        error: this.props.intl.formatMessage(
          messages['message.error.confirm_password_same']
        ),
        resetCode: true,
      })
      return
    }
    this.setState({ error: null })
    reqResetPassword(email, password, passwordConfirm, code)
      .then((response) => {
        this.setState({ resetSuccess: true, resetCode: true })
      })
      .catch((error) => {
        this.setState({ error: error.response.data.message, resetCode: true })
      })
  }

  onFinishFailed = (values) => {
    const { errorFields } = values
    const { errors } = errorFields[0]
    this.setState({ error: errors[0] })
  }

  onFieldsChange = (changedFields, allFields) => {
    this.setState({ error: null })
  }

  showError() {
    if (this.state.error) {
      return (
        <Alert
          className="errorContainer"
          message={this.state.error}
          type="error"
          showIcon
        />
      )
    }
  }

  getMail = () => {
    let errors = this.formRef.current.getFieldsError(['email'])[0]['errors']
    if (errors && errors.length > 0) {
      this.setState({ error: errors[0] })
      return null
    } else {
      let mail = this.formRef.current.getFieldValue('email')
      return mail
    }
  }

  shouldReset = () => {
    let shouldReset = this.state.resetCode

    if (shouldReset) {
      this.setState({
        resetCode: false,
      })
    }

    return shouldReset
  }

  getCode = (event) => {
    let errors = this.formRef.current.getFieldsError(['email'])[0]['errors']
    if (errors && errors.length > 0) {
      this.setState({ error: errors[0] })
    } else {
      let mail = this.formRef.current.getFieldValue('email')
      reqCode(mail)
        .then((response) => {
          let { data } = response
          if (data.status === 0) {
          } else {
          }
        })
        .catch((error) => {})
    }
  }
  render() {
    if (this.props.token) {
      return <Redirect to="/" />
    }

    if (this.state.resetSuccess) {
      return <Redirect to="/login" />
    }
    return (
      <div className="loginContainer">
        <Form
          ref={this.formRef}
          name="control-ref"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          onFieldsChange={this.onFieldsChange}>
          <div className="lgoinBody">
            <div className="header">
              <img className="logo" src="ipf-logo.png" alt="logo" />
            </div>
            {this.showError()}
            <div className="body">
              <div className="item">
                <div className="inputTitle">
                  <FormattedMessage id="app.ui.login.label.mail" />
                </div>
                <Form.Item
                  noStyle={true}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: this.props.intl.formatMessage(
                        messages['message.error.no_mail']
                      ),
                    },
                    {
                      type: 'email',
                      message: this.props.intl.formatMessage(
                        messages['message.error.mail_format']
                      ),
                    },
                  ]}>
                  <Input className="input"></Input>
                </Form.Item>
              </div>
              <div className="item">
                <div className="inputTitle">
                  <FormattedMessage id="app.ui.login.label.new_password" />
                </div>
                <Form.Item
                  noStyle={true}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: this.props.intl.formatMessage(
                        messages['message.error.no_new_password']
                      ),
                    },
                  ]}>
                  <div className="passContainer">
                    <Input.Password></Input.Password>
                  </div>
                </Form.Item>
              </div>
              <div className="item">
                <div className="inputTitle">
                  <FormattedMessage id="app.ui.login.label.new_password_confirm" />
                </div>
                <Form.Item
                  noStyle={true}
                  name="passwordConfirm"
                  rules={[
                    {
                      required: true,
                      message: this.props.intl.formatMessage(
                        messages['message.error.no_confirm_new_password']
                      ),
                    },
                  ]}>
                  <div className="passContainer">
                    <Input.Password></Input.Password>
                  </div>
                </Form.Item>
              </div>
              <div className="item">
                <div className="inputTitle">
                  <FormattedMessage id="app.ui.login.label.verification_code" />
                </div>
                <Form.Item
                  noStyle={true}
                  className="codeItem"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: this.props.intl.formatMessage(
                        messages['message.error.no_code']
                      ),
                    },
                  ]}>
                  <Input className="input"></Input>
                </Form.Item>
                <EFCCodeButton
                  getMail={this.getMail}
                  shouldReset={this.shouldReset}
                />
              </div>
            </div>
            <Form.Item noStyle={true}>
              <div className="commit">
                <Button block="true" className="submitButton" htmlType="submit">
                  <FormattedMessage id="app.ui.login.button.reset" />
                </Button>
                <Button
                  type="link"
                  block="true"
                  className="jumpButton"
                  href="/login">
                  <FormattedMessage id="app.ui.login.button.to_login" />
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
        <div className="footer">© IP FORWARD All rights Reserved.</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.user,
    ...state.auth,
  }
}

export default connect(mapStateToProps)(injectIntl(ForgetPassViewBackup))
