import "./index.less"
import React, { Component, useCallback, useEffect, useRef, useState } from "react"
import { Button, Form, Input, Radio, Select, message } from "antd"

import { reqGetImpactSettings, reqSetImpactSettings } from '../../../api/analyst-other-setting.js'

import { FormattedMessage, useIntl } from "react-intl"
import messages from './messages.js'

const AnalystClientOtherSetting = (props) => {  

    const intl = useIntl()
    const [isloading, setIsLoading] = useState(false)
    const [editState, setEditState] = useState(false)
    const settings = useRef({})
    const formRef = useRef()
    const [loading, setLoading] = useState(false)
    const toggleLoading = useCallback(() => {
        setLoading(preState => !preState)
    }, [])

    const priceType = useRef([
        {
            value: 1,
            label: intl.formatMessage({ id: "app.ui.brand_reg_detail.other.indication_price" })
        }, {
            value: 2,
            label: intl.formatMessage({ id: "app.ui.brand_reg_detail.other.single_price" })
        }
    ])

    const countType = useRef([
        {
            value: 1,
            label: intl.formatMessage({ id: "app.ui.brand_reg_detail.other.inventory" }),
        }, {
            value: 2,
            label: intl.formatMessage({ id: "app.ui.brand_reg_detail.other.sale" }),
        }, {
            value: 3,
            label: intl.formatMessage({ id: "app.ui.brand_reg_detail.other.coefficient" }),
        }
    ])

    const onFinish = (values) => {
        setIsLoading(true)
        reqSetImpactSettings({
            clientId: props.clientId,
            count: parseInt(values.count),
            countType: values.countType,
            price: parseFloat(values.price),
            priceType: values.priceType,
            radio: parseFloat(values.radio)
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                formRef.current.setFieldsValue({ ...data.data.settings })
                settings.current = data.data.settings
                setIsLoading(false)
                message.success(intl.formatMessage(messages['message.info.other.update_success']))
            }
        }).catch((e) => {
            setIsLoading(false)
            message.error(intl.formatMessage(messages['message.error.other.update_fail']))
        })
    }

    const onFinishFailed = (value) => {

    }

    const onHandleState = (state) => {
        if (state) {
            formRef.current.setFieldsValue({ ...settings.current })
        }
        setEditState(state)
    }

    //onChange={this.onPriceTypeChange}
    const renderSelectPrice = () => {
        if (editState) {
            return <div style={{ display: 'flex' }}>
                <Form.Item noStyle={true} name="priceType">
                    <Select className="ef-form-element-normal" style={{ width: 130 }} options={priceType.current}>
                    </Select>
                </Form.Item>
                <Form.Item noStyle={true} name="price">
                    <Input className="ef-form-element-normal input-text" style={{ width: 240 }} />
                </Form.Item>
            </div>
        } else {
            let value
            priceType.current.map((item, index) => {
                if (item.value === settings.current.priceType) {
                    value = item.label + ' (' + settings.current.price + ')'
                }
            })
            return <p>{value}</p>
        }
    }

    //onChange={this.onCountTypeChange}
    const renderSelectNum = () => {
        if (editState) {
            return <div style={{ display: 'flex' }}>
                <Form.Item noStyle={true} name="countType">
                    <Select className="ef-form-element-normal" style={{ width: 130 }} options={countType.current}>
                    </Select>
                </Form.Item >
                <Form.Item noStyle={true} name="count">
                    <Input className="ef-form-element-normal input-text" style={{ width: 240 }} />
                </Form.Item>
            </div>
        } else {
            let value
            countType.current.map((item, index) => {
                if (item.value === settings.current.countType) {
                    value = item.label + ' (' + settings.current.count + ')'
                }
            })
            return <p>{value}</p>
        }
    }

    const renderSelectOthers = () => {
        if (editState) {
            return <div style={{ display: 'flex' }}>
                <Form.Item noStyle={true} name="radio">
                    <Input className="ef-form-element-normal" suffix={"%"} style={{ width: 130 }}>
                    </Input>
                </Form.Item >
            </div>
        } else {
            return <p>{settings.current.radio}</p>
        }
    }

    const renderTrigger = () => {
        if (editState === true) {
            return <div className='master-report-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.ui.profile.section.basicinfo.cancel' /></Button>
                <Button htmlType="submit" className='ef-button-color-small' loading={isloading} style={{ marginLeft: '8px' }}><FormattedMessage id='app.ui.profile.section.basicinfo.update' /></Button>
            </div>
        } else {
            return <div className='master-report-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.ui.profile.section.basicinfo.edit' /></Button>
            </div>
        }
    }

    const renderOtherData = () => {
        if (props.clientId) {
            reqGetImpactSettings({
                clientId: props.clientId,
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    formRef.current.setFieldsValue({
                        ...data.data.settings
                    })
                    settings.current = data.data.settings
                    toggleLoading()
                }
            }).catch((error) => {
                toggleLoading()
            })
        }
    }

    useEffect(() => {
        renderOtherData()
    }, [])

    return <div>
        <div className="master-report-container">
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                ref={formRef}
                className="master-report-wrap"
            >
                <div className="setting-title-wrapper ef-title-describe"><FormattedMessage id="app.ui.brand_reg_detail.other.arithmetic" /></div>
                <div className="item-wrapper">
                    <div className="item-label-container ef-text-normal">
                        <p><FormattedMessage id="app.ui.brand_reg_detail.other.unit_price" /></p>
                    </div>
                    {renderSelectPrice()}
                </div>
                <div className="item-wrapper">
                    <div className="item-label-container ef-text-normal">
                        <p><FormattedMessage id="app.ui.brand_reg_detail.other.unit_coefficient" /></p>
                    </div>
                    {renderSelectNum()}
                </div>
                <div className="item-wrapper">
                    <div className="item-label-container ef-text-normal">
                        <p><FormattedMessage id="app.ui.brand_reg_detail.other.others" /></p>
                    </div>
                    {renderSelectOthers()}
                </div>
                {renderTrigger()}
            </Form>
        </div>
    </div>
}

export default AnalystClientOtherSetting