import react from 'react'
import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// less引入
import './index.less'

// antd引入
import {
  Form,
  Checkbox,
  Table,
  Select,
  Button,
  List,
  Modal,
  message,
  Spin,
  Collapse,
} from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

// react语言处理
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import messages from './ec-list-messages'

// 工具类
import getECListBasicColumns from './ec-list-columns'
import { getKeywordSearchItems, getDateSearchItems } from './ec-list-const.js'
import ECListCommon from '../common/ec-list-common.js'
import { isErrorRecord } from './ec-list-helper'
import ECRecordHistory from './ec-history'
import { InfringementCommonCategories } from '@/config/infringement-common-category.js'
import { TakedownRequest } from '../../../config/ec-report-select-items'

// components
import EFCTableHeader from '../../../components/ef-c-tableheader'
import EFCSearchBar from '@/components/ef-c-searchbar'
import EFCSearchBar1 from '@/components/ef-c-searchbar/current'
import EFResizableTable from '../../../components/ef-c-resizable-table'

// Redux
import store from '@/store'
import {
  setContentContainerHeaderAction,
  setBreadcrumbs,
} from '@/store/actions/content-header.js'
import { setMenuActiveKey } from '@/store/actions/menu.js'

// api请求
import {
  reqSearchECRecords,
  reqFetchReports,
  reqGetECRecordHistory,
  reqBatchUpdateCategory,
  reqBatchUpdateAction,
} from '@/api/ec.js'
import { reqFetchInfringementCategories } from '../../../api/analyst-infringement-category'
import { reqFetchClients } from '@/api/analyst-client'

// ---handleClientChanged
import { reqFetchCustomColumns } from '../../../api/analyst-custom-column'
import { reqSearchTrademarks } from '../../../api/trademark'
import { reqFetchProductCategories } from '../../../api/analyst-product-category'

const { Panel } = Collapse
const TestDataList = () => {
  // 国际化配置
  const intl = useIntl()
  // 路由跳转
  const history = useHistory()
  // 获取位置信息
  const location = useLocation()

  // 访问全局redux状态数据
  const reduxState = useSelector((state) => state)
  // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
  let reduxProps = {
    ...reduxState.user,
  }

  const [isLoading, setisLoading] = useState(false)
  const [dateSource, setdateSource] = useState([])
  const [page, setpage] = useState(1)
  const [count, setcount] = useState(0)
  const [total, settotal] = useState(0)

  // Brand公司名称所有id
  const [clients, setclients] = useState([])
  const [offset, setoffset] = useState()
  const [errorRecordCount, seterrorRecordCount] = useState()

  const [shouldShowColumnModal, setshouldShowColumnModal] = useState(false)
  const [selectRowKeys, setselectRowKeys] = useState([])
  const [tableScrollY, settableScrollY] = useState(`calc(100vh - 613px)`)
  const [collapse, setcollapse] = useState(false)
  const [categorysInfo, setcategorysInfo] = useState()
  const [historyVisible, sethistoryVisible] = useState()
  const [currentHistory, setcurrentHistory] = useState()
  const [historyLoading, sethistoryLoading] = useState()

  // display settings
  const [indeterminate, setindeterminate] = useState()
  const [isCheckedAll, setisCheckedAll] = useState()

  const changeColumnFormRef = useRef()

  // ---缓存列表总条目
  let totalNum = useRef(0)
  // ---缓存列表错误总条目
  let errorRecordCountNum = useRef()
  // ---缓存所有Brand品牌的配置信息
  let customColumnDic = useRef({})
  // ---
  let trademarksInfo = useRef({})
  let productsInfo = useRef({})
  let reportsInfo = useRef({})
  let showColumnsInfo = useRef({})

  // 缓存是否开启异常检索
  let filterErrorRecord = useRef(0)

  // only for getBasicColumns
  let columnWithDic = useRef({})

  // ---当前搜索brand公司id
  let currentSearchClientId = useRef()

  // ---缓存当前的搜索条件
  let currentSearchConditions = useRef()

  // 当前选择brand公司id
  let currentSelectClientId = useRef()

  // ---缓存当前的过滤条件
  let currentFilters = useRef()
  let columns = useRef()

  // ---缓存当前的排列规则
  let currentSorter = useRef()
  let noticeId = useRef()
  let currentHistoryRecord = useRef()

  // Detail ---点击函数
  const onDetailClick = (record) => {
    let searchParams = new URLSearchParams(location.search)
    searchParams.set('num', record.num)
    searchParams.set('total', totalNum.current)
    history.push({
      pathname:
        '/ec-data/' + currentSearchClientId.current + '/' + record.ec_id,
      search: `?${searchParams.toString()}`,
    })
  }

  // Table ---History点击函数
  const onHistoryClick = (record) => {
    sethistoryVisible(true)
    setcurrentHistory([])
    sethistoryLoading(true)
    currentHistoryRecord.current = record
    reqGetECRecordHistory({
      clientId: currentSearchClientId.current,
      recordId: record.ec_id,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          console.log(data.data.history)
          setcurrentHistory(data.data.history)
          sethistoryLoading(false)
        }
      })
      .catch((e) => {
        setcurrentHistory([])
        sethistoryLoading(false)
      })
  }

  // 返回一个基本的列表项配置(Table)
  const getBasicColumns = () => {
    let columns = getECListBasicColumns({
      currentSearchClientId: currentSearchClientId.current,
      allReportsInfo: reportsInfo.current,
      currentSearchConditions: currentSearchConditions.current,
      allProductsInfo: productsInfo.current,
      categorysInfo: categorysInfo ? categorysInfo : null,
      allTrademarksInfo: trademarksInfo.current,
      currentSelectClientId: currentSelectClientId.current,
      searchWords: currentSearchConditions.current
        ? [currentSearchConditions.current.keyword]
        : [],
      searchColumns: currentSearchConditions.current
        ? currentSearchConditions.current.wordFields
        : [],
      allSearchableCoulumns: getKeywordSearchItems(intl),
      onDetailClick: onDetailClick,
      onHistoryClick: onHistoryClick,
      filters: currentFilters.current,
      intl: intl,
    })
    return columns.map((column, index) => {
      let width = column.width
      if (columnWithDic.current && columnWithDic.current[column.key]) {
        width = columnWithDic.current[column.key]
      } else {
      }
      return {
        ...column,
        // width: width,
        onHeaderCell: (column) => ({
          width: column.width,
        }),
      }
    })
  }

  const [showColumns, setshowColumns] = useState(
    getBasicColumns()
      .filter((column) => column.defaultShow === true)
      .map((column) => column.key)
  )

  // 重制列表数据(由切换Brand时调用)
  const resetColumns = (clientId) => {
    if (clientId) {
      let customItems = customColumnDic.current['customItemsInfo_' + clientId]
      if (customItems) {
        customItems = customItems.sort((a, b) => {
          if (a.column_order < b.column_order) {
            return 1
          } else {
            return -1
          }
        })
        let newColumns = customItems.reduce((pre, item) => {
          let newColumn = {
            title: item.column_name,
            dataIndex: 'custom_info',
            key: 'custom_info_' + item.column_name,
            width: 150,
            render: (value, record) => {
              try {
                let obj = JSON.parse(value)
                return obj[item.column_name]
              } catch (e) {
                return value
              }
            },
          }
          let index = pre.findIndex((column) => {
            return item.column_position === column.key
          })
          let newColumns = pre.slice(0, index + 1).concat([newColumn])

          if (newColumns.length <= pre.length) {
            newColumns = newColumns.concat(pre.slice(index + 1))
          }
          return newColumns
        }, getBasicColumns())

        if (showColumnsInfo.current[clientId]) {
          setshowColumns(showColumnsInfo.current[clientId])
        } else {
          showColumnsInfo.current[clientId] = newColumns
            .filter((column) => column.defaultShow)
            .map((column) => column.key)
          setshowColumns(showColumnsInfo.current[clientId])
        }
      } else {
        if (showColumnsInfo.current[clientId]) {
          setshowColumns(showColumnsInfo.current[clientId])
        } else {
          showColumnsInfo.current[clientId] = getBasicColumns()
            .filter((column) => column.defaultShow)
            .map((column) => column.key)
          setshowColumns(showColumnsInfo.current[clientId])
        }
      }
    }
  }

  // Table ---外导列表excel表格
  const onExportTable = () => {
    if (!currentSearchClientId.current) {
      return
    }
    let dateFields = currentSearchConditions.current.dateFields
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.dateFields)
        )
      : null //["xxxx","xxxx"]
    let startDate = currentSearchConditions.current.startDate
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.startDate)
        )
      : null // yyyy-MM-dd
    let endDate = currentSearchConditions.current.endDate
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.endDate)
        )
      : null // yyyy-MM-dd
    let wordFields = currentSearchConditions.current.wordFields
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.wordFields)
        )
      : null //["xxx","xxxx"]
    let keyword = currentSearchConditions.current.keyword
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.keyword)
        )
      : null // xxxx
    let searchType = currentSearchConditions.current.searchType
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.searchType)
        )
      : null // 1,2,3
    let whereFields = currentSearchConditions.current.whereFields
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.whereFields)
        )
      : null //[{key:xxx,values:[xxxx]}]
    let orderFields = currentSearchConditions.current.orderFields
      ? encodeURIComponent(
          JSON.stringify(currentSearchConditions.current.orderFields)
        )
      : null //[{key:xxx,values:xxx}]

    var searchParams = new URLSearchParams('?')
    if (dateFields) {
      searchParams.set('dateFields', dateFields)
    }

    if (startDate) {
      searchParams.set('startDate', startDate)
    }

    if (endDate) {
      searchParams.set('endDate', endDate)
    }

    if (wordFields) {
      searchParams.set('wordFields', wordFields)
    }

    if (keyword) {
      searchParams.set('keyword', keyword)
    }

    if (searchType) {
      searchParams.set('searchType', searchType)
    }

    if (whereFields) {
      searchParams.set('whereFields', whereFields)
    }

    if (orderFields) {
      searchParams.set('orderFields', orderFields)
    }

    if (currentSearchClientId.current) {
      searchParams.set('clientId', currentSearchClientId.current)
    }
    if (currentSearchConditions.current.noticeId) {
      searchParams.set('noticeId', currentSearchConditions.current.noticeId)
    }
    // 打开一个新的窗口
    window.open(
      `${
        process.env.REACT_APP_BASE_API
      }/api/ec-data/export-search-result?${searchParams.toString()}`
    )
  }

  // 获得基本列表项配置+定制部分(Table)
  const getBasicColumnsWithCustom = (clientId) => {
    if (clientId) {
      let customItems = customColumnDic.current['customItemsInfo_' + clientId]

      if (customItems) {
        customItems = customItems.sort((a, b) => {
          if (a.column_order < b.column_order) {
            return 1
          } else {
            return -1
          }
        })
        let newColumns = customItems.reduce((pre, item) => {
          let newColumn = {
            title: item.column_name,
            dataIndex: 'custom_info',
            key: 'custom_info_' + item.column_name,
            width: 150,
            render: (value, record) => {
              try {
                let obj = JSON.parse(value)
                return obj[item.column_name]
              } catch (e) {
                return value
              }
            },
          }
          let index = pre.findIndex((column) => {
            return item.column_position === column.key
          })
          let newColumns = pre.slice(0, index + 1).concat([newColumn])
          if (newColumns.length <= pre.length) {
            newColumns = newColumns.concat(pre.slice(index + 1))
          }
          return newColumns
        }, getBasicColumns())
        return newColumns
      } else {
        return getBasicColumns()
      }
    } else {
      return getBasicColumns()
    }
  }

  // 修改公司brand
  const handleClientChanged = (value) => {
    currentSelectClientId.current = value
    let searchParams = new URLSearchParams(location.search)
    searchParams.set(ECListCommon.ParamsKey.clientId, value)
    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })
    if (
      !customColumnDic.current[
        'customItemsInfo_' + currentSelectClientId.current
      ]
    ) {
      reqFetchCustomColumns({
        clientId: currentSelectClientId.current,
      })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            data.data.columns.sort((item1, item2) => {
              if (item1.column_position === item2.column_position) {
                return item1.column_order > item2.column_order
              } else {
                return item1.column_position > item2.column_position
              }
            })
            customColumnDic.current['customItemsInfo_' + value] =
              data.data.columns
            resetColumns(currentSearchClientId.current)
          }
        })
        .catch((error) => {})
    }

    if (
      !trademarksInfo.current['trademarksInfo_' + currentSelectClientId.current]
    ) {
      reqSearchTrademarks({ clientId: currentSelectClientId.current })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            trademarksInfo.current['trademarksInfo_' + value] =
              data.data.trademarks
          }
        })
        .catch((error) => {})
    }

    if (
      !productsInfo.current['productsInfo_' + currentSelectClientId.current]
    ) {
      reqFetchProductCategories({
        clientId: currentSelectClientId.current,
      })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            productsInfo.current['productsInfo_' + value] = data.data.categories
          }
        })
        .catch((error) => {})
    }

    if (!reportsInfo.current['reportsInfo_' + currentSelectClientId.current]) {
      reqFetchReports({ clientId: currentSelectClientId.current })
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            reportsInfo.current['reportsInfo_' + value] = data.data.reports
          }
        })
        .catch((error) => {})
    }
  }

  // checkbox点击函数
  const handleSearchErrorRecordChanged = (e) => {
    filterErrorRecord.current = e.target.checked
    let searchParams = new URLSearchParams(location.search)
    searchParams.set(
      ECListCommon.ParamsKey.filterErrorRecord,
      filterErrorRecord.current ? 1 : 0
    )
    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }

  // 初始函数(也相当于每次更新都会调用一次,会把所有的url值缓存下来)
  const initSearchComditionsByURLSearchParams = (searchParams) => {
    try {
      currentSearchConditions.current = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.search))
      )
    } catch (e) {}
    try {
      currentSorter.current = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.sorter))
      )
    } catch (e) {}
    try {
      currentFilters.current = JSON.parse(
        decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.filters))
      )
    } catch (e) {}

    try {
      noticeId.current = JSON.parse(
        decodeURIComponent(searchParams.get('notice_id'))
      )
    } catch (e) {}
    if (reduxProps.role & 2) {
      currentSelectClientId.current = searchParams.get('clientId')
      if (currentSelectClientId.current) {
        handleClientChanged(parseInt(currentSelectClientId.current))
      }
    } else {
      currentSelectClientId.current = reduxProps.companyInfo.client_id

      if (currentSelectClientId.current) {
        handleClientChanged(parseInt(currentSelectClientId.current))
      }
    }
  }

  // change display columns
  const commitChangeShowColumns = () => {
    let keys = changeColumnFormRef.current.getFieldValue('showColumns')
    showColumnsInfo.current[currentSearchClientId.current] = keys
    setshowColumns(keys)
    setshouldShowColumnModal(false)
  }

  // 手动搜索函数(初始化调用一次)
  const handleSearch = (conditions) => {
    let searchConditions = { ...conditions }
    let clientId = searchConditions.clientId
    let noticeId = searchConditions.noticeId
    currentSearchClientId.current = clientId
      ? clientId
      : currentSelectClientId.current
    currentSearchConditions.current = searchConditions
    if (!currentSearchClientId.current) {
      return
    }
    if (
      currentSearchConditions.current[
        ECListCommon.ParamsKey.filterErrorRecord
      ] === null ||
      currentSearchConditions.current[
        ECListCommon.ParamsKey.filterErrorRecord
      ] === undefined
    ) {
      let searchParams = new URLSearchParams(location.search)
      searchParams.set(
        ECListCommon.ParamsKey.filterErrorRecord,
        filterErrorRecord.current ? 1 : 0
      )
      history.push({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      })
    }
    console.log(searchConditions)
    reqSearchECRecords({
      clientId: currentSearchClientId.current,
      filterErrorRecord: filterErrorRecord.current,
      ...searchConditions,
      noticeId,
    })
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          totalNum.current = data.data.total
          errorRecordCountNum.current = data.data.errorRecordCount
          setdateSource(data.data.records)
          settotal(data.data.total)
          setoffset(data.data.offset)
          seterrorRecordCount(data.data.errorRecordCount)
        }
      })
      .catch((e) => {})
    resetColumns(currentSearchClientId.current)
  }

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      updateSelectedShowColumns(
        getBasicColumnsWithCustom(currentSearchClientId.current).map(
          (item) => item.key
        )
      )
    } else {
      updateSelectedShowColumns([])
    }
  }

  // Search ---点击Find函数(搜索激活函数)
  const onSearchClick = (conditions) => {
    let searchParams = new URLSearchParams({})
    if (conditions) {
      searchParams.set(
        ECListCommon.ParamsKey.search,
        encodeURIComponent(JSON.stringify(conditions))
      )
    }
    let finalConditions =
      ECListCommon.converSearchParmasToConditions(searchParams)
    handleSearch(finalConditions)
    setselectRowKeys([])
  }

  // 得到当前页面可渲染数据(一整套可选的配套数据)
  const getInitData = async () => {
    let clients = null
    if (reduxProps.role & 2) {
      await reqFetchClients()
        .then((response) => {
          const { data } = response
          if (data.code === 0) {
            clients = data.data.client
          }
        })
        .catch((error) => {})
    }
    let categorysInfo = null
    await reqFetchInfringementCategories()
      .then((response) => {
        const { data } = response
        if (data.code === 0) {
          categorysInfo = data.data.categories
        }
      })
      .catch((error) => {})
    return { clients, categorysInfo }
  }

  // Search ---section 伸展函数
  const onCollapseChange = (value) => {
    if (value.length > 0) {
      settableScrollY(`calc(100vh - 613px)`)
    } else {
      settableScrollY(`calc(100vh - 353px)`)
    }
  }

  // Search ---点击reset函数
  const handleReset = () => {
    let searchParams = new URLSearchParams(location.search)
    searchParams.delete('noticeId')
    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })
  }

  // Table ---更新display setting函数
  const updateSelectedShowColumns = (showColumns) => {
    if (changeColumnFormRef.current && showColumns) {
      changeColumnFormRef.current.setFieldsValue({
        showColumns: showColumns,
      })
      updateCheckAllBoxStateBySelectColumns(showColumns)
    }
  }

  // Table ---更新display setting的CheckBox点击函数
  const onCheckBoxChange = (values) => {
    updateCheckAllBoxStateBySelectColumns(values)
  }

  // Table ---取消display setting点击函数
  const cancelChangeShowColumns = () => {
    setshouldShowColumnModal(false)
  }

  // Table ---更新display setting所有CheckBox状态的函数
  const updateCheckAllBoxStateBySelectColumns = (values) => {
    if (values) {
      if (
        values.length ===
        getBasicColumnsWithCustom(currentSearchClientId.current).length
      ) {
        setindeterminate(false)
        setisCheckedAll(true)
      } else if (values.length === 0) {
        setindeterminate(false)
        setisCheckedAll(false)
      } else {
        setindeterminate(true)
        setisCheckedAll(false)
      }
    }
  }

  // Table ---点击display seting函数进行展开
  const showColumnList = () => {
    updateSelectedShowColumns(
      showColumnsInfo.current[currentSearchClientId.current]
    )
    setshouldShowColumnModal(true)
  }

  const onFilterCheckedRecord = () => {
    // if (this.state.selectRowKeys && this.state.selectRowKeys.length > 0) {
    //     let searchParams = new URLSearchParams(this.props.location.search);
    //     let currentFilters = JSON.parse(decodeURIComponent(searchParams.get(ECListCommon.ParamsKey.filters)))
    //     if (currentFilters) {
    //         currentFilters.push({
    //             key: "ec_id",
    //             values: this.state.selectRowKeys
    //         })
    //     } else {
    //         currentFilters = [{
    //             key: "ec_id",
    //             values: this.state.selectRowKeys
    //         }]
    //     }
    //     searchParams.set(ECListCommon.ParamsKey.filters, encodeURIComponent(JSON.stringify(currentFilters)));
    //     window.open(`${this.props.location.pathname}?${searchParams.toString()}`);
    // }
  }

  //
  const onHistoryClose = () => {
    sethistoryVisible(false)
  }

  // Table ---Update点击函数
  const onBatchUpdate = (value) => {
    if (!selectRowKeys || selectRowKeys.length === 0) {
      message.error('選択されたレコードがありません。')
    } else {
      if (value !== undefined && value !== null) {
        const hide = message.loading('更新中', 0)
        if (reduxProps.role & 2) {
          console.log('dsdsds')
          console.log(selectRowKeys)
          console.log(value)
          // reqBatchUpdateCategory({
          //   clientId: currentSearchClientId.current,
          //   recordIds: selectRowKeys,
          //   commoncategoryId: value,
          // })
          //   .then((response) => {
          //     const { data } = response
          //     console.log(1)
          //     console.log(data)
          //     // console.log(data)
          //     if (data.code === 0) {
          //       if (hide) {
          //         hide()
          //       }
          //       message.success(
          //         `${data.data.updateCount}アイテム情報を更新しました。`
          //       )
          //       let searchParams = new URLSearchParams(location.search)
          //       let conditions =
          //         ECListCommon.converSearchParmasToConditions(searchParams)
          //       handleSearch(conditions)
          //       setselectRowKeys([])
          //     } else {
          //       message.error('更新失敗しました。')
          //     }
          //   })
          //   .catch((e) => {
          //     if (hide) {
          //       hide()
          //     }
          //     message.error('更新失敗しました。')
          //   })
        } else {
          reqBatchUpdateAction({
            clientId: currentSearchClientId.current,
            recordIds: selectRowKeys,
            action: value,
          })
            .then((response) => {
              const { data } = response
              console.log(2)
              console.log(data)
              // console.log(data)
              if (data.code === 0) {
                if (hide) {
                  hide()
                }
                message.success(
                  `${data.data.updateCount}アイテム情報を更新しました。`
                )
                let searchParams = new URLSearchParams(location.search)
                let conditions =
                  ECListCommon.converSearchParmasToConditions(searchParams)
                handleSearch(conditions)
                setselectRowKeys([])
              } else {
                message.error('更新失敗しました。')
              }
            })
            .catch((e) => {
              if (hide) {
                hide()
              }
              message.error('更新失敗しました。')
            })
        }
        // Dismiss manually and asynchronouslÏy
        setTimeout(hide, 10 * 1000)
      } else {
        message.error('アップデータ内容を選択してください。')
      }
    }
  }

  // Table ---分页问题
  const onTableValueChange = (pagination, filters, sorter, extraInfo) => {
    let searchParams = new URLSearchParams(location.search)
    if (pagination) {
      searchParams.set(
        ECListCommon.ParamsKey.pagination,
        encodeURIComponent(JSON.stringify(pagination))
      )
    }
    if (filters && extraInfo.action === 'filter') {
      let effectFilterKeys = Object.keys(filters).filter((key) => {
        return filters[key]
      })
      let effectFilters = effectFilterKeys.map((key) => {
        let result = {}
        result.key = key
        result.values = filters[key]
        return result
      })
      if (effectFilters && effectFilters.length > 0) {
        searchParams.set(
          ECListCommon.ParamsKey.filters,
          encodeURIComponent(JSON.stringify(effectFilters))
        )
      } else {
        searchParams.set(ECListCommon.ParamsKey.filters, null)
      }
    }

    if (sorter && sorter.field) {
      if (sorter.order) {
        let sortInfo = {}
        sortInfo['key'] = sorter.field
        sortInfo.value = sorter.order === 'ascend' ? 1 : 2
        searchParams.set(
          ECListCommon.ParamsKey.sorter,
          encodeURIComponent(JSON.stringify([sortInfo]))
        )
      } else {
        searchParams.delete(ECListCommon.ParamsKey.sorter)
      }
    }

    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    })

    let conditions = ECListCommon.converSearchParmasToConditions(searchParams)
    handleSearch(conditions)
  }

  // Table
  const getBatchUpdateItems = () => {
    if (reduxProps.role & 2) {
      return InfringementCommonCategories.map((item) => {
        return {
          label: item.name.ja,
          value: item.id,
          key: item.id,
        }
      })
    } else {
      return TakedownRequest.map((item) => {
        return {
          label: item.name.en,
          value: item.value,
          key: item.value,
        }
      })
    }
  }

  const handleRowSelection = () => {}

  // Table ---Row select
  const onRowSelect = (record, selected, selectedRows, nativeEvent) => {
    // console.log(record, selected)
    let newSelectRowKeys = selectRowKeys ? [...selectRowKeys] : []
    if (selected) {
      newSelectRowKeys.push(record.ec_id)
      setselectRowKeys(newSelectRowKeys)
    } else {
      let unselectIndex = newSelectRowKeys.findIndex(
        (item) => item === record.ec_id
      )
      newSelectRowKeys = newSelectRowKeys
        .slice(0, unselectIndex)
        .concat(newSelectRowKeys.slice(unselectIndex + 1))
      setselectRowKeys(newSelectRowKeys)
    }
  }

  // Table ---Row all select
  const onRowSelectAll = (selected, selectedRows, changeRows) => {
    let ec_ids = changeRows.map((record) => {
      return record.ec_id
    })
    let newSelectRowKeys = selectRowKeys ? [...selectRowKeys] : []
    if (selected) {
      newSelectRowKeys = [...new Set(newSelectRowKeys.concat(ec_ids))]
      setselectRowKeys(newSelectRowKeys)
    } else {
      newSelectRowKeys = newSelectRowKeys.filter((ecId) => {
        return ec_ids.findIndex((id) => id === ecId) === -1
      })
      setselectRowKeys(newSelectRowKeys)
    }
  }

  // 获得列表配置值
  const getColumnContent = () => {
    document.title = intl.formatMessage(messages['eclist.title'])
    columns.current = getBasicColumnsWithCustom(
      currentSearchClientId.current
    ).filter((column) => {
      return showColumns.some((key) => {
        return column.key === key
      })
    })
  }

  // UI渲染函数(展示下拉框)
  const showClientSelector = () => {
    if (reduxProps.role & 2) {
      let searchParams = new URLSearchParams(location.search)
      let filterErrorRecord = searchParams.get(
        ECListCommon.ParamsKey.filterErrorRecord
      )
      return (
        <>
          <div className="client-selector-container">
            <p className="client-title">
              <FormattedMessage id="app.ui.dashboard.search.brand" />
            </p>
            <Select
              allowClear
              style={{ width: 200 }}
              onChange={handleClientChanged}
              defaultValue={currentSelectClientId.current}>
              {clients.map((client) => {
                return (
                  <Select.Option
                    value={client.client_id}
                    key={client.client_id}>
                    {client.client_name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <div className="client-selector-container">
            <Checkbox
              onChange={handleSearchErrorRecordChanged}
              defaultChecked={filterErrorRecord === '1' ? true : false}>
              異常レコード検索
            </Checkbox>
          </div>
        </>
      )
    } else {
    }
  }

  // UI渲染函数(整体UI)
  const renderUi = () => {
    getColumnContent()
    let mainUI = (
      // Search conditions
      <>
        <div className="ec-list-container">
          <div className="collapse-container">
            <Collapse
              bordered={true}
              defaultActiveKey={1}
              onChange={(value) => onCollapseChange(value)}>
              <Panel
                header={<FormattedMessage id="app.ui.wslist.search.collapse" />}
                key="1">
                {showClientSelector()}
                <EFCSearchBar1
                  onSearch={(conditions) => onSearchClick(conditions)}
                  onReset={handleReset}
                  dateItems={getDateSearchItems(intl)}
                  keywordItems={getKeywordSearchItems(intl)}
                  defaultValues={currentSearchConditions.current}
                />
              </Panel>
            </Collapse>
          </div>
          <div className="result-table" id="ecTableHeader">
            <EFCTableHeader
              key="ecTableHeader"
              onSaveClick={onExportTable}
              onShowColumnListClick={showColumnList}
              total={total}
              errorCount={
                reduxProps.role & 2 ? errorRecordCountNum.current : null
              }
              onFilterCheckedRecord={onFilterCheckedRecord}
              showBatchContainer={true}
              onBatchUpdate={onBatchUpdate}
              batchValueOptions={getBatchUpdateItems()}
            />
            <EFResizableTable
              key="ecListTable"
              bordered
              tableLayout="fixed"
              rowSelection={{
                type: 'checkbox',
                onChange: handleRowSelection,
                onSelect: onRowSelect,
                onSelectAll: onRowSelectAll,
                getCheckboxProps: (record) => ({
                  name: record.ec_id,
                }),
                selectedRowKeys: selectRowKeys,
              }}
              rowKey={(record) => {
                return record.ec_id
              }}
              rowClassName={(record) => {
                if (reduxProps.role & 2 && isErrorRecord(record)) {
                  return 'ec-list-error'
                } else {
                  return ''
                }
              }}
              columns={columns.current}
              dataSource={dateSource}
              onChange={onTableValueChange}
              size="small"
              pagination={{
                showQuickJumper: true,
                total: total,
                pageSizeOptions: [10, 20, 50, 100, 200, 500],
                defaultPageSize: 20,
                showSizeChanger: true,
                itemRender: (page, type, originalElement) => {
                  if (type === 'prev') {
                    // eslint-disable-next-line jsx-a11y/no-access-key
                    return (
                      <Button
                        type="button"
                        className="ant-pagination-item-link"
                        icon={<LeftOutlined />}
                        accesskey="P"></Button>
                    )
                  } else if (type === 'next') {
                    // eslint-disable-next-line jsx-a11y/no-access-key
                    return (
                      <Button
                        type="link"
                        className="ant-pagination-item-link"
                        icon={<RightOutlined />}
                        accesskey="N"></Button>
                    )
                  } else {
                    return originalElement
                  }
                },
              }}
              scroll={{
                x: 'max-content',
                y: tableScrollY,
              }}
            />
          </div>
          <Form noStyle={true} ref={changeColumnFormRef}>
            <Modal
              title={<FormattedMessage id="app.ui.eclist.column_modal.title" />}
              visible={shouldShowColumnModal}
              onOk={commitChangeShowColumns}
              onCancel={cancelChangeShowColumns}
              footer={
                <div>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={isCheckedAll}>
                    <FormattedMessage id="app.components.data_filter.check_all" />
                  </Checkbox>
                  <Button key="back" onClick={cancelChangeShowColumns}>
                    <FormattedMessage id="app.components.data_filter.cancel" />
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={commitChangeShowColumns}>
                    <FormattedMessage id="app.components.data_filter.submit" />
                  </Button>
                </div>
              }>
              <div className="ec-list-view-listContainer">
                <Form.Item name="showColumns" noStyle={true}>
                  <Checkbox.Group>
                    <List
                      dataSource={getBasicColumnsWithCustom(
                        currentSearchClientId.current
                      )}
                      split={false}
                      size="small"
                      onChange={onCheckBoxChange}
                      renderItem={(item) => {
                        return (
                          <List.Item key={item.key} className="list-item">
                            <Checkbox value={item.key}>{item.title}</Checkbox>
                          </List.Item>
                        )
                      }}></List>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Modal>
          </Form>
        </div>
        <ECRecordHistory
          onClose={onHistoryClose}
          visible={historyVisible}
          history={currentHistory}
          historyLoading={historyLoading}
          clientId={currentSearchClientId.current}
          recordId={
            currentHistoryRecord.current
              ? currentHistoryRecord.current.ec_id
              : null
          }
          config={{
            currentSearchClientId: currentSearchClientId.current,
            allProductsInfo: productsInfo.current,
            categorysInfo: categorysInfo ? categorysInfo : null,
            allTrademarksInfo: trademarksInfo.current,
            currentSelectClientId: currentSelectClientId.current,
          }}></ECRecordHistory>
      </>
    )

    if (isLoading) {
      return <Spin>{mainUI}</Spin>
    } else {
      return mainUI
    }
  }

  // 全局状态管理
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search)
    initSearchComditionsByURLSearchParams(searchParams)
    let conditions = ECListCommon.converSearchParmasToConditions(searchParams)
    handleSearch(conditions)

    getInitData().then((data) => {
      setcategorysInfo(data.categorysInfo)
      setclients(data.clients)
      setisLoading(false)
    })

    if (reduxProps.role & 2) {
      store.dispatch(setMenuActiveKey('analyst-ec-list'))
    } else {
      store.dispatch(setMenuActiveKey('client-ec-list'))
    }
    // 开启trademark可选项
    store.dispatch(
      setContentContainerHeaderAction([
        {
          name: <FormattedMessage id="app.ui.eclist.to_trademark_list" />,
          path: '/trademark',
        },
      ])
    )
    store.dispatch(
      setBreadcrumbs([
        {
          name: <FormattedMessage id="app.layout.menu.home" />,
          path: '/',
        },
        {
          name: (
            <FormattedMessage id="app.layout.menu.online_protection.ec_record_list" />
          ),
          path: '/ec-list',
        },
      ])
    )
  }, [])

  return renderUi()
}
export default TestDataList
