import request from '@/utils/request'

export function reqFetchBrands(data){
    return request({
        url: '/api/analyst/fetch-brands',
        method: 'post',
        data: data
    })
}

export function reqCreateBrand(data){
    return request({
        url: '/api/analyst/create-brand',
        method: 'post',
        data: data
    })
}

export function reqRemoveBrand(data){
    return request({
        url: '/api/analyst/remove-brand',
        method: 'post',
        data: data
    })
}

export function reqUpdateBrand(data){
    return request({
        url: '/api/analyst/update-brand',
        method: 'post',
        data: data
    })
}

export function reqCreateBrandKeyword(data){
    return request({
        url: '/api/analyst/create-brand-keyword',
        method: 'post',
        data: data
    })
}

export function reqRemoveBrandKeyword(data){
    return request({
        url: '/api/analyst/remove-brand-keyword',
        method: 'post',
        data: data
    })
}

export function reqUpdateBrandKeyword(data){
    return request({
        url: '/api/analyst/update-brand-keyword',
        method: 'post',
        data: data
    })
}

export function reqCreateBrandModel(data){
    return request({
        url: '/api/analyst/create-brand-model',
        method: 'post',
        data: data
    })
}

export function reqRemoveBrandModel(data){
    return request({
        url: '/api/analyst/remove-brand-model',
        method: 'post',
        data: data
    })
}

export function reqUpdateBrandModel(data){
    return request({
        url: '/api/analyst/update-brand-model',
        method: 'post',
        data: data
    })
}