import request from '@/utils/request'

export function reqCreateInfringementCategory(data){
    return request({
        url: '/api/analyst/create-infringement-category',
        method: 'post',
        data: data
    })
}

export function reqRemoveInfringementCategory(data){
    return request({
        url: '/api/analyst/remove-infringement-category',
        method: 'post',
        data: data
    })
}

export function reqUpdateInfringementCategory(data){
    return request({
        url: '/api/analyst/update-infringement-category',
        method: 'post',
        data: data
    })
}

export function reqFetchInfringementCategories(data){
    return request({
        url: '/api/analyst/fetch-infringement-categories',
        method: 'post',
        data: data
    })
}

export function reqCreateInfringementSubcategory(data){
    return request({
        url: '/api/analyst/create-infringement-subcategory',
        method: 'post',
        data: data
    })
}

export function reqRemoveInfringementSubcategory(data){
    return request({
        url: '/api/analyst/remove-infringement-subcategory',
        method: 'post',
        data: data
    })
}

export function reqUpdateInfringementSubcategory(data){
    return request({
        url: '/api/analyst/update-infringement-subcategory',
        method: 'post',
        data: data
    })
}

export function reqFetchInfringementSubcategories(data){
    return request({
        url: '/api/analyst/fetch-infringement-subcategories',
        method: 'post',
        data: data
    })
}
