import "./index.less"

import React, { Component } from "react";
import { Button, message } from "antd";
import { reqUpdateUser } from '@/api/admin-user'
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import { isValidPassword } from "../../../utils/ef-re";
// react语言处理
import { injectIntl,FormattedMessage } from 'react-intl'
import messages from './messages'
class EFAdminResetPassword extends Component {

    constructor(props) {
        super(props)
        this.modalFormRef = React.createRef();
        this.userId = this.props.userId;
        this.state = {
            isloading: false
        }
    }

    onFinish = (values) => {
        const { password, password2 } = values;
        if (!isValidPassword(password)) {
            message.error(this.props.intl.formatMessage(
                messages['message.error.password_failed']
              ))
            return
        }
        if (password !== password2) {
            message.error(this.props.intl.formatMessage(
                messages['message.error.password2_failed']
              ))
            return
        }
        let body = {
            userId: this.userId,
            password,
            password2,
        }
        this.setState({ isloading: true })
        reqUpdateUser(body).then((response) => {
            let { data } = response
            if (data.code === 0) {
                this.setState({ resetCode: true, isloading: false })
                message.success(this.props.intl.formatMessage(
                    messages['message.info.reset_password_success']
                  ))
            }

        }).catch((error) => {
            this.setState({ resetCode: true, isloading: false })
            message.error(this.props.intl.formatMessage(
                messages['message.error.reset_password_failed']
              ))
        })
        return true
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }

    render() {
        return (
            <ModalForm
                formRef={this.modalFormRef}
                title={this.props.modalTitle}
                trigger={
                    <Button type="primary">{<FormattedMessage id='app.ui.user_list_reset_password.reset_password'/>}</Button>
                }
                modalProps={{
                    onCancel: () => {
                        this.modalFormRef.current?.resetFields();
                    },
                }}
                onFinish={this.onFinish}
            >
                <ProFormText.Password label={<FormattedMessage id="app.ui.user_list_reset_password.new_password" />} name="password" rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(
                            messages['message.error.password_empty']
                        ),
                    }
                ]}
                />
                <ProFormText.Password label={<FormattedMessage id="app.ui.user_list_reset_password.new_password_confirm" />} name="password2" rules={[
                    {
                        required: true,
                        message: this.props.intl.formatMessage(
                            messages['message.error.password2_empty']
                        ),
                    }
                ]}
                />
            </ModalForm >
        );
    }
}

export default injectIntl(EFAdminResetPassword)