import ec_crawler_task_ja_lang from './ec-crawler-task/ja.js'

const common_ja_lang = {
    // infrigment common categories name key
    'app.config.infirgment_common_cateogry.name_locale_key': 'ja',

    // locale name key
    'app.config.locale.name_locale_key': 'ja',

    // product type
    'app.config.locale.product_locale_key': "name",

    // infringement
    'app.config.locale.infringement_locale_key': "name",

    // subinfringement
    'app.config.locale.subinfringement_locale_key': "name",

    // login
    'app.layout.login.language': '日本語',

    // header
    'app.layout.header.language': '日本語',
    'app.layout.header.logout': 'ログアウト',
    'app.layout.header.usename': 'ユーザー名',

    // sider menu
    'app.layout.menu.home': 'ホーム',

    // common label
    'app.common.label.blank': '空白',
    'app.common.label.no': 'なし',
    'app.common.label.yes': 'あり',

    'app.common.flag.yes': 'はい',
    'app.common.flag.no': 'いいえ',

    'app.common.option.yes': '可能',
    'app.common.option.no': '不可能',

    // common column name
    'app.common.action': '動作',
    'app.common.action.detail': '詳細',
    'app.common.action.delete': '削除',
    'app.common.action.add': '新規',
    'app.common.action.update': '更新',
    'app.common.action.back': '戻る',
    'app.common.action.inherit': '引継',
    'app.common.action.start': 'スタート',
    'app.common.action.stop': '停止',
    'app.common.action.submenu': 'タスク状態',
    'app.common.action.open': '公開',
    'app.common.action.new_upload': '新規アップロード',
    'app.common.action.update_upload': '更新アップロード',
    'app.common.action.remove_duplicate': '重複削除',
    'app.common.action.download': 'ダウンロード',
    'app.common.action.clone': 'クローン',
    'app.common.action.upload': "アップロード",
    'app.common.action.save': "保存",
    'app.common.action.cancel': "キャンセル",
    'app.common.action.edit': "編集",
    'app.common.action.confirm': '確認',
    'app.common.action.filter': 'フィルタ',
    'app.common.action.history': '歴史',
    'app.common.action.search': '検索',
    'app.common.action.check_all': '全て',

    // common action description
    'app.common.warning.delete': '削除しますか？',
    'app.common.confirm.delete': 'はい',
    'app.common.cancel.delete': 'いいえ',
    'app.common.warning.stop': '停止しますか？',
    'app.common.confirm.stop': 'はい',
    'app.common.cancel.stop': 'いいえ',

    // breadcrumbs
    'app.breadcrumbs.home': 'ホーム',
    'app.breadcrumbs.register': 'EC登録',
    'app.breadcrumbs.client_list': 'ブランド登録一覧',
    'app.breadcrumbs.detail': '詳細',
    'app.breadcrumbs.404': '404',
    'app.breadcrumbs.test_purchase': 'Test Purchase',
    'app.breadcrumbs.news': 'News',
    'app.breadcrumbs.notice': 'Notice',
    'app.breadcrumbs.new': '新規',
    'app.breadcrumbs.ec_crawler_task' : 'ECスクレイピング',
    'app.breadcrumbs.category_standard': 'カテゴライズ基準',
    'app.breadcrumbs.manager_list': '管理者登録一覧',

    // empty-content
    'app.emptycontent.title': 'データなし',
    'app.emptycontent.text': '該当データはありません。',
    'app.serverError.title': 'サーバーエラー',
    'app.serverError.text': 'しばらくしてからもう一度お試しください',
    'app.networdError.title': 'ネット状況',
    'app.networdError.text': 'ネット状況にお確認ください。',
    'app.permissionLimit.title': '権限なし',
    'app.permissionLimit.text': '該当IPF担当者にご連絡くささい。',

    // pagination
    'app.pagination.per_page': '/ ページ',
    'app.pagination.jump_to': '移動',
    'app.pagination.page': 'ページ',
    'app.pagination.show_total': '合計 {count} ページ',

    // collapse
    'app.collapse.title': '検索条件',

    // user
    'app.user.manager': '管理者',
    'app.user.analyst': 'アナリスト',
    'app.user.client': 'クライアント',

    // 404
    'app.ui.404.description1': 'こちらのページは年間サービス会員様専用ページとなります。',
    'app.ui.404.description2': '御覧になるには、弊所より発行いたします専用アカウントとパスワードが必要です。',
    'app.ui.404.contact': '連絡先：',

    // efc tableheader
    'app.components.ec_table_header.total_count': '総件数:{count}',
    'app.components.ec_table_header.error_count': '異常件数:{count}',
    'app.components.ec_table_header.update': '更新',

    // efc searchbar
    'app.components.ef_search_bar.brand': 'クライアント名',
    'app.components.ef_search_bar.date_item': '日付項目',
    'app.components.ef_search_bar.date_from': '開始日',
    'app.components.ef_search_bar.date_to': '終了日',
    'app.components.ef_search_bar.search_item': '検索項目',
    'app.components.ef_search_bar.search_hint': '検索',
    'app.components.ef_search_bar.search_button': '検索',
    'app.components.ef_search_bar.reset_button': 'リセット',
    'app.components.ef_search_bar.search_tpye_fuzzy_match': '曖昧検索',
    'app.components.ef_search_bar.search_tpye_exactly_match': '完全一致',
    'app.components.ef_search_bar.search_tpye_previous_match': '前一致',
    'app.components.ef_search_bar.search_tpye_post_match': '後一致',
    'app.components_ef-search_bar.error.brand_empty': 'ブランド名が必須です。',

    // efc searchbar
    'app.components.ef_code_button.title': '取得する',
    'app.components.ef_code_button.count_down': '後{countDown}秒、再取得できます。',

    // data filter and modal
    'app.components.data_filter.check_all': '全部',
    'app.components.data_filter.cancel': 'キャンセル',
    'app.components.data_filter.submit': '確認',
    'app.components.data_filter.confirm': '確認',
    'app.components.modal.submit': '確認',
    'app.components.modal.reset': 'キャンセル',

    'app.layout.menu.online_protection': 'Online Protection',
    'app.layout.menu.online_protection.ec_record_list': 'EC詳細データ一覧',
    'app.layout.menu.online_protection.sns_record_list': 'SNS詳細データ一覧',
    'app.layout.menu.online_protection.ws_record_list': 'WS詳細データ一覧',
    'app.layout.menu.online_protection.report_list': 'ECレポート',

    'app.layout.menu.offline_protection': 'Offilne Protection',

    'app.layout.menu.legal_services': 'Legal Services',
    'app.layout.menu.legal_services.tm_search': '商標検索',
    'app.layout.menu.legal_services.tm_register': '商標出願',
    'app.layout.menu.legal_services.tm_application_status': '進行状況確認',
    'app.layout.menu.legal_services.tm_update_status': '商標更新手続き',
    'app.layout.menu.legal_services.tm_rejection_reason': '拒絶理由レポート',
    'app.layout.menu.legal_services.tm_transfer': '商標変更・移転',
    'app.layout.menu.legal_services.tm_monitoring': '商標監視',
    'app.layout.menu.legal_services.tm_correction_invaildation_trail': '登録無効審判',
    'app.layout.menu.legal_services.tm_case_management': 'ケース管理',
    'app.layout.menu.legal_services.tm_registration': '商標登録状況',

    'app.layout.menu.biz_supprot': 'Biz Support',
    'app.layout.menu.news': 'News',
    'app.layout.menu.notice': 'Notice',

    'app.layout.menu.other_support': 'Other',
    'app.layout.menu.test_purfchase': 'Test Purchase',

    'app.layout.menu.task': 'Task',
    'app.layout.menu.task.ec_crawler_task': 'EC Task',

    'app.layout.menu.setting': 'Setting',
    'app.layout.menu.setting.profile': 'プロフィール設定',
    'app.layout.menu.setting.user_list': 'ユーザー登録一覧',
    'app.layout.menu.setting.brand_list': 'ブランド登録一覧',
    'app.layout.menu.setting.manager_list': '管理者登録一覧',
    'app.layout.menu.setting.ec_list': 'EC登録',
    'app.layout.menu.setting.infringement_category': 'カテゴライズ基準',

    'app.layout.menu.fold': "Folding",

    // ec-dashboard
    'app.ui.dashboard.title': 'ダッシュボード',
    'app.ui.dashboard.breadcrumb.dashboard': 'ダッシュボード',
    'app.ui.dashboard.search.brand': 'クライアント名',
    'app.ui.dashboard.search.date_column': '日付コラム',
    'app.ui.dashboard.search.date_from': '期間',
    'app.ui.dashboard.search.date_to': '~',
    'app.ui.dashboard.status.title': '基本ステータス',
    'app.ui.dashboard.search.find': '作成',
    'app.ui.dashboard.search.reset': 'リセット',

    'app.ui.dashboard.status.impact_revenue': 'Infringement Impact Revenue',
    'app.ui.dashboard.status.impact_revenue.usd': 'USD',
    'app.ui.dashboard.status.impact_revenue.rate': 'Rate',
    'app.ui.dashboard.status.impact_revenue.jpy': 'JPY',
    'app.ui.dashboard.status.annual_date': 'Annual Status - Date',
    'app.ui.dashboard.status.analyst': '担当者',

    'app.ui.dashboard.status.impact_trademark': 'Trademark Registration',
    'app.ui.dashboard.status.impact_trademark.categorizedcount': 'Categorized Count',
    'app.ui.dashboard.status.impact_trademark.infringementcount': 'Infringement Count',
    'app.ui.dashboard.status.impact_trademark.infringementcount_percent': 'Infringement%',

    'app.ui.dashboard.chart.overall_categorization_status.title': 'Overall Categorization Status',
    'app.ui.dashboard.chart.overall_categorization_status.Non_infringement' : 'Non Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.Copyright_infringement' : 'Copyright Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.Trademark_infringement_ad' : 'Trademark Infringement(ad)',
    'app.ui.dashboard.chart.overall_categorization_status.Trademark_infringement_counterfeit' : 'Trademark Infringement(counterfeit)',
    'app.ui.dashboard.chart.overall_categorization_status.Need_Sold_ended' : 'Need Sold(Ended)',
    'app.ui.dashboard.chart.overall_categorization_status.Need_confrmation' : 'Need confrmation',
    'app.ui.dashboard.chart.overall_categorization_status.other_law_infringement': 'Other Law Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment_percent': 'Infringement%',
    'app.ui.dashboard.chart.overall_categorization_status.left_y_axis_name': '件数',
    'app.ui.dashboard.chart.overall_categorization_status.right_y_axis_name': '%',

    'app.ui.dashboard.chart.online_infringing_seller.title' : 'Online Infringement Seller',
    'app.ui.dashboard.chart.overall_infringement.title': 'Overall Infringement',
    'app.ui.dashboard.chart.overall_infringement.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment_percent': 'Infringement%',

    'app.ui.dashboard.chart.overall_infringement_type.title': 'Overall Infringement Type',
    'app.ui.dashboard.map.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.chart.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.table.categorization_status.title': 'Categorization Status',
    'app.ui.dashboard.chart.locale_filtering.title': 'Region Filtering',
    'app.ui.dashboard.filter.locale_filtering.title': '国・地区',
    'app.ui.dashboard.filter.website_filtering.title': 'Webサイト',

    'app.ui.dashboard.chart.ec_infringement_categorization.title': 'EC Infringement Categorization',
    'app.ui.dashboard.list.top_infringers.title': 'Top Infringers',
    'app.ui.dashboard.chart.takedown.title': 'Takedown',

    'app.ui.dashboard.chart.ws_takedown.title': 'Web Site Takedown',
    'app.ui.dashboard.chart.ws_category.title': 'Web Site Categorization',

    // ec-list
    'app.ui.eclist.title': 'ECデータ一覧',
    'app.ui.eclist.to_trademark_list': '商標登録リスト',
    'app.ui.eclist.table.action_history': '編集履歴',

    'app.ui.eclist.column_modal.title': "コラム表示設定",

    'app.ui.eclist.table.id': "ID",
    'app.ui.eclist.table.ec_no': "管理番号",
    'app.ui.eclist.table.ec_site': "ECサイト",
    'app.ui.eclist.table.ec_scan_month': "スキャン月",
    'app.ui.eclist.table.ec_scan_date': "スキャン月日",
    'app.ui.eclist.table.ec_search_keyword': "検索KW",
    'app.ui.eclist.table.ec_product_no': "Listing #",
    'app.ui.eclist.table.ec_product_title': "出品タイトル",
    'app.ui.eclist.table.ec_product_url': "出品URL",
    'app.ui.eclist.table.ec_product_brand': "ブランド欄",
    'app.ui.eclist.table.ec_product_seller': "出品者名",
    'app.ui.eclist.table.ec_product_seller_url': "出品者URL",
    'app.ui.eclist.table.ec_product_price': "価格",
    'app.ui.eclist.table.ec_product_currency': "通貨",
    'app.ui.eclist.table.ec_product_image_url': "画像URL",
    'app.ui.eclist.table.ec_product_onsell_count': "在庫数",
    'app.ui.eclist.table.ec_product_sold_count': "販売件数",
    'app.ui.eclist.table.ec_product_region': "出品国",
    'app.ui.eclist.table.ec_product_city': "出品地域",
    'app.ui.eclist.table.ec_categorize_date': "レポート提出日",
    'app.ui.eclist.table.ec_auto_check_flg': "フラグ",
    'app.ui.eclist.table.ec_blacklist_object': "ブラックリスト対象",
    'app.ui.eclist.table.ec_whitelist_object': "ホワイトリスト対象",
    'app.ui.eclist.table.ec_is_need_to_check': "簡易診断",
    'app.ui.eclist.table.ec_title_hint_word': "ブランド表記判定（タイトル）",
    'app.ui.eclist.table.ec_brand_hint_word': "ブランド表記判定",
    'app.ui.eclist.table.ec_product_category_id_code': "製品判定入力欄",
    'app.ui.eclist.table.ec_product_category_id_word': "製品判定参照結果",
    'app.ui.eclist.table.ec_category_rule_id': "採用基準表",
    'app.ui.eclist.table.ec_category_id_code': "入力欄",
    'app.ui.eclist.table.ec_category_id_word': "カテゴリ",
    'app.ui.eclist.table.ec_category_id_common': "集計",
    'app.ui.eclist.table.ec_categorize_remarks': "IPFコメント",
    'app.ui.eclist.table.ec_response_action': "申告実施フラグ",
    'app.ui.eclist.table.ec_response_reason': "貴社判定コメント",
    'app.ui.eclist.table.ec_response_trademark': "商標・著作権登録番号",
    'app.ui.eclist.table.ec_response_date': "回答日",
    'app.ui.eclist.table.ec_process1_date': "1回目申告日",
    'app.ui.eclist.table.ec_process1_result': "1回目申告結果",
    'app.ui.eclist.table.ec_process1_confirm_date': "1回目確認日",
    'app.ui.eclist.table.ec_process2_date': "2回目申告日",
    'app.ui.eclist.table.ec_process2_result': "2回目申告結果",
    'app.ui.eclist.table.ec_process2_confirm_date': "2回目確認日",
    'app.ui.eclist.table.ec_process3_date': "3回目申告日",
    'app.ui.eclist.table.ec_process3_result': "3回目申告結果",
    'app.ui.eclist.table.ec_process3_confirm_date': "3回目確認日",
    'app.ui.eclist.table.declaration_status': "申告状況",
    'app.ui.eclist.table.ec_process_result': "最終結果",
    'app.ui.eclist.table.ec_process_confirm_date': "最終結果確認日",
    'app.ui.eclist.table.ec_process_remarks': "備考",
    'app.ui.eclist.table.report_id': "レポート名",
    'app.ui.eclist.table.batch_choose': 'を選んだ',
    'app.us.eclist.table.batch_items': '件',
    'app.ui.eclist.table.modify': 'バッチ更新',

    'app.ui.eclist.table.column_display': '表示項目',
    'app.ui.eclist.table.result_filter': 'フィルター',
    'app.ui.eclist.table.history_records': '編集歴史',
    'app.ui.eclist.table.orginal_url': 'ORGINAL URL ',

    'app.ui.eclist.tooltip.increase_fontsize': '大サイズ',
    'app.ui.eclist.tooltip.decrease_fontsize': '小サイズ',
    'app.ui.eclist.tooltip.filter': 'フィルター',
    'app.ui.eclist.tooltip.mode_grid': 'グリッドモード',
    'app.ui.eclist.tooltip.mode_table': 'リストモード',
    'app.ui.eclist.tooltip.export': 'エクスポート',
    'app.ui.eclist.tooltip.columns': '項目設定',
    'app.ui.eclist.tooltip.ec_categorize_remarks': '備考',
    'app.ui.eclist.tooltip.ec_response_remarks': '備考',

    'app.ui.eclist.message.warning.brand_empty': 'ブランドが必須項目です。',
    'app.ui.eclist.message.warning.query_empty': '検索条件を入力ください。',
    'app.ui.eclist.message.info.loading': '更新中',
    'app.ui.eclist.message.info.update_success': 'アイテム情報を更新しました。',
    'app.ui.eclist.message.error.update_error': '更新失敗しました。',

    // ec list history
    'app.ui.eclist_history.table.edited_by': "編集者",
    'app.ui.eclist_history.table.edited_date': "編集日付",

    // profile
    'app.ui.profile.title': 'プロフィール設定',
    'app.ui.profile.section.basicinfo.title': '基本情報',
    'app.ui.profile.section.basicinfo.name': '名前（必須）',
    'app.ui.profile.section.basicinfo.mail': 'メール',
    'app.ui.profile.section.basicinfo.role': 'ロール',
    'app.ui.profile.section.basicinfo.tel': '電話番号',
    'app.ui.profile.section.basicinfo.cancel': 'キャンセル',
    'app.ui.profile.section.basicinfo.edit': '編集',
    'app.ui.profile.section.basicinfo.name.error': '名前を入力ください。',
    'app.ui.profile.section.basicinfo.tel.error': '正しい電話番号を入力ください。',
    'app.ui.profile.section.basicinfo.update': '更新',

    'app.ui.profile.section.company.title': 'クライアント情報',
    'app.ui.profile.section.company.name': '会社名',
    'app.ui.profile.section.company.address': '住所',
    'app.ui.profile.section.company.tel': '連絡先',
    'app.ui.profile.section.company.remarks': '備考',

    'app.ui.profile.section.moment.title' : '登録情報',
    'app.ui.profile.section.moment.lastlogintime' : '最終ログイン日',
    'app.ui.profile.section.moment.setuptime' : '新規日付',

    'app.ui.profile.section.analyst.title': 'アナリスト情報',
    'app.ui.profile.section.analyst.table.name': '名前',
    'app.ui.profile.section.analyst.table.mail': 'メール',
    'app.ui.profile.section.analyst.table.tel': '電話番号',

    'app.ui.profile.section.contract.title': '契約情報',
    'app.ui.profile.section.contract.table.name': 'サービス',
    'app.ui.profile.section.contract.table.duration_from': '開始日付',
    'app.ui.profile.section.contract.table.duration_to': '終了日付',
    'app.ui.profile.section.contract.table.fee': '金額',

    'app.ui.profile.action.reset_password': 'パスワードリセット',
    'app.ui.profile.breadcrumb.password': 'パスワード',
    'app.ui.profile.breadcrumb.profile': 'プロフィール設定',
    'app.ui.profile.breadcrumb.home': 'ホーム',

    'app.ui.profile.message.success.update_success': 'アカウント情報を更新しました。',
    'app.ui.profile.message.error.update_failed': 'エラーが発生しました。',
    'app.ui.profile.message.error.password_character': 'パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。',
    'app.ui.profile.message.error.confirm_password_same': '新パスワードと新パスワード（確認）は同じではなく、確認してください。',
    'app.ui.profile.message.success.password_reset_success': 'パスワードをリセットしました。',
    'app.ui.profile.message.error.password_reset_failed': 'パスワードのリセットを失敗しました。',

    'app.ui.profile.password_reset.title': 'パスワードリセット情報',
    'app.ui.profile.password_reset.old_password': '旧パスワード（必須）',
    'app.ui.profile.password_reset.new_password': '新パスワード（必須）',
    'app.ui.profile.password_reset.new_password_confirm': '新パスワード（確認）（必須）',
    'app.ui.profile.password_reset.code': '確認コード（必須）',
    'app.ui.profile.password_reset.reset_button': 'リセット',

    'app.ui.profile.password_reset.message.error.no_current_password': '旧パスワードを入力ください。',
    'app.ui.profile.password_reset.message.error.no_new_password': '新パスワードを入力ください。',
    'app.ui.profile.password_reset.message.error.no_confirm_new_password': '新パスワード（確認）を入力ください。',
    'app.ui.profile.password_reset.message.error.no_code': '確認コードを入力ください。',

    'app.ui.profile.analyst.client_id': 'ID',
    'app.ui.profile.analyst.client_name': '会社名',
    'app.ui.profile.analyst.client_tel': '連絡先',
    'app.ui.profile.analyst.client_address': '住所',
    'app.ui.profile.analyst.client_remarks': '備考',
    'app.ui.profile.analyst.ec_startDate': 'EC開始期間',
    'app.ui.profile.analyst.ec_endDate': 'EC終了期間',
    'app.ui.profile.analyst.ec_fee': 'EC金額',
    'app.ui.profile.analyst.sns_startDate': 'SNS開始期間',
    'app.ui.profile.analyst.sns_endDate': 'SNS終了期間',
    'app.ui.profile.analyst.sns_fee': 'SNS金額',
    'app.ui.profile.analyst.ws_startDate': 'WS開始期間',
    'app.ui.profile.analyst.ws_endDate': 'WS終了期間',
    'app.ui.profile.analyst.ws_fee': 'WS金額',
    'app.ui.profile.analyst.other_startDate': 'Other開始期間',
    'app.ui.profile.analyst.other_endDate': 'Other終了期間',
    'app.ui.profile.analyst.other_fee': 'Other金額',
    'app.ui.profile.analyst.action': '動作',

    // user list
    'app.ui.user_list.title': 'ユーザー登録一覧',
    'app.ui.user_list.new_user': '新規',
    'app.ui.user_list.home': 'ホーム',
    'app.ui.user_list.userlist': 'ユーザー登録一覧',
    'app.ui.user_list.user_id': 'ID',
    'app.ui.user_list.user_name': '名前',
    'app.ui.user_list.user_mail': 'メール',
    'app.ui.user_list.user_tel': '電話番号',
    'app.ui.user_list.user_department': '部署',
    'app.ui.user_list.user_last_used_date': '最終ログイン日付',
    'app.ui.user_list.user_create_at': '新規日付',
    'app.ui.user_list.user_remarks': '備考',
    'app.ui.user_list.action': '動作',

    // user list detail
    'app.ui.user_list_detail.title': 'ユーザー詳細',
    'app.ui.user_list_detail.user_id': 'ID',
    'app.ui.user_list_detail.name': '名前（必須）',
    'app.ui.user_list_detail.user_mail': 'メール（必須）',
    'app.ui.user_list_detail.user_password': 'パスワード（必須）',
    'app.ui.user_list_detail.user_password_confirm': 'パスワード(確認)（必須）',
    'app.ui.user_list_detail.tel': '電話番号',
    'app.ui.user_list_detail.department': '部署',
    'app.ui.user_list_detail.remarks': '備考',
    'app.ui.user_list_detail.delete': '削除',

    'app.ui.user_list_detail.home': 'ホーム',
    'app.ui.user_list_detail.userlist': 'ユーザー登録一覧',
    'app.ui.user_list_detail.detail': '詳細',

    'app.ui.user_list_detail.error.name_empty': '名前を入力ください。',
    'app.ui.user_list_detail.error.phone_failed': '正しい電話番号を入力してください。',
    'app.ui.user_list_detail.error.section_failed': '正しい部署を入力ください。',
    'app.ui.user_list_detail.error.remarks_failed': '500字以内の備考を入力ください。',
    'app.ui.user_list_detail.info.update_success': 'ユーザー情報を更新しました。',
    'app.ui.user_list_detail.error.update_failed': 'ユーザー情報の更新は失敗しました。',

    // user list detail resetPassword
    'app.ui.user_list_reset_password.reset_password': 'パスワードリセット',
    'app.ui.user_list_reset_password.new_password': '新パスワード',
    'app.ui.user_list_reset_password.new_password_confirm': '新パスワード（確認）',
    'app.ui.user_list_reset_password.error.password_empty': '新パスワードを入力ください。',
    'app.ui.user_list_reset_password.error.password2_empty': '新パスワード（確認）を入力ください。',

    // user list new
    'app.ui.user_list_new.title': 'ユーザー新規',
    'app.ui.user_list_new.new_user': '新規',
    'app.ui.user_list_new.home': 'ホーム',
    'app.ui.user_list_new.userlist': 'ユーザー登録一覧',
    'app.ui.user_list_new.error.name_empty': '名前を入力ください。',
    'app.ui.user_list_new.error.email_failed': '正しいメールを入力してください。',
    'app.ui.user_list_new.error.password_failed': 'パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。',
    'app.ui.user_list_new.error.password2_failed': '新パスワードと新パスワード（確認）は同じではなく、確認してください。',
    'app.ui.user_list_new.error.phone_failed': '正しい電話番号を入力してください。',
    'app.ui.user_list_new.error.section_failed': '正しい部署を入力ください。',
    'app.ui.user_list_new.error.remarks_failed': '500字以内の備考を入力ください。',
    'app.ui.user_list_new.error.new_failed': 'ユーザー新規登録失敗しました。',

    // ec-site
    'app.ui.ec_site.table.title': 'EC登録',
    'app.ui.ec_site.table.id': 'ID',
    'app.ui.ec_site.table.name': 'サイト名',
    'app.ui.ec_site.table.url': 'URL',
    'app.ui.ec_site.table.region': '国・地区',
    'app.ui.ec_site.table.infrigementcategoryId': '採用カテゴリ',
    'app.ui.ec_site.table.hascrawler': 'スクレイピング可能',
    'app.ui.ec_site.table.action': '動作',
    'app.ui.ec_site.drawer.title': 'フィルター',

    'app.ui.ec_site.modal.title': '削除しますか？',
    'app.ui.ec_site.modal.ok': 'はい',
    'app.ui.ec_site.modal.no': 'いいえ',

    // ec-site-detail
    'app.ec_site_detail.title': 'ECサイト詳細',
    'app.ui.ec_site_detail.box_title': '詳細',
    'app.ui.ec_site_detail.form.name': 'サイト名',
    'app.ui.ec_site_detail.form.url': 'URL',
    'app.ui.ec_site_detail.form.region': '国・地区',
    'app.ui.ec_site_detail.form.category': '採用カテゴル',
    'app.ui.ec_site_detail.form.scrape_possible': 'スクレイピング可能',
    'app.ui.ec_site_detail.form.remarks': '備考',

    'app.ui.ec_site_detail.table.account': 'アカウント',
    'app.ui.ec_site_detail.table.password': 'パスワード',
    'app.ui.ec_site_detail.table.phone': '携帯電話',
    'app.ui.ec_site_detail.table.freeze_seconds': '冷却時間(秒)',
    'app.ui.ec_site_detail.table.headers': 'ヘッダー',
    'app.ui.ec_site_detail.table.cookies': 'クッキー',
    'app.ui.ec_site_detail.table.remarks': '備考',

    'app.ui.ec_site_detail.modal.url_add': 'URL追加',

    'app.ui.ec_site_detail.error.account_empty': 'アカウントを入力ください。',
    'app.ui.ec_site_detail.error.password_empty': 'パスワードを入力ください。',
    'app.ui.ec_site_detail.error.tel_empty': '携帯電話を入力ください。',
    'app.ui.ec_site_detail.error.free_time_empty': '冷却時間(秒)を入力ください。',
    'app.ui.ec_site_detail.error.headers_empty': 'ヘッダーを入力ください。',
    'app.ui.ec_site_detail.error.cookies_empty': 'クッキーを入力ください。',
    'app.ui.ec_site_detail.error.remarks_empty': '備考を入力ください。',
    'app.ui.ec_site_detail.error.remarks_tip': '希望件数を入力ください。',
    'app.ui.ec_site_detail.info.update_account_success': '成功しました。',
    'app.ui.ec_site_detail.error.update_account_failed': '失敗しました。',

    // news
    'app.ui.news.title': 'News',
    'app.ui.news.error.fetch_failed': 'ニュース情報の取得が失敗になりました。',

    // manage/client(Brand Reg.)
    'app.ui.brand_reg.title': 'クブランド登録一覧',

    'app.ui.brand_reg.client_id': 'ID',
    'app.ui.brand_reg.client_name': '会社名',
    'app.ui.brand_reg.client_tel': '連絡先',
    'app.ui.brand_reg.client_address': '住所',
    'app.ui.brand_reg.client_analyst': 'アナリスト',
    'app.ui.brand_reg.client_remarks': '備考',
    'app.ui.brand_reg.ec_startDate': 'EC開始期間',
    'app.ui.brand_reg.ec_endDate': 'EC終了期間',
    'app.ui.brand_reg.ec_fee': 'EC金額',
    'app.ui.brand_reg.sns_startDate': 'SNS開始期間',
    'app.ui.brand_reg.sns_endDate': 'SNS終了期間',
    'app.ui.brand_reg.sns_fee': 'SNS金額',
    'app.ui.brand_reg.ws_startDate': 'WS開始期間',
    'app.ui.brand_reg.ws_endDate': 'WS終了期間',
    'app.ui.brand_reg.ws_fee': 'WS金額',
    'app.ui.brand_reg.other_startDate': 'Other開始期間',
    'app.ui.brand_reg.other_endDate': 'Other終了期間',
    'app.ui.brand_reg.other_fee': 'Other金額',
    'app.ui.brand_reg.company_name': '会社名',
    'app.ui.brand_reg.address': '住所',
    'app.ui.brand_reg.remarks': '備考',

    'app.ui.brand_reg.message.info.fetch_success': 'ブランド情報が取得しました。',
    'app.ui.brand_reg.message.error.fetch_failed': 'ブランド情報が取得失敗になりました。',
    'app.ui.brand_reg.message.error.delete_failed': 'ブランド情報の削除が失敗しました。',

    // manage/client/new (Brand Reg.)
    'app.ui.brand_reg_new.title': 'アナリスト新規',
    'app.ui.brand_reg_new.basicinfo.title': '基本情報',
    'app.ui.brand_reg_new.contract.title': '契約情報',
    'app.ui.brand_reg_new.name': '会社名（必須）',
    'app.ui.brand_reg_new.tel': '連絡先',
    'app.ui.brand_reg_new.address': '住所',
    'app.ui.brand_reg_new.managers': 'Online Protection 担当者',
    'app.ui.brand_reg_new.remarks': '備考',

    'app.ui.brand_reg_new.ec_protection': 'EC Protection',
    'app.ui.brand_reg_new.sns_protection': 'SNS Protection',
    'app.ui.brand_reg_new.ws_protection': 'WS Protection',
    'app.ui.brand_reg_new.other_protection': 'Other Protection',
    'app.ui.brand_reg_new.contract_time': '契約期間',
    'app.ui.brand_reg_new.fee': '金額',
    'app.ui.brand_reg_new.mail': 'リマインドメール',
    'app.ui.brand_reg_new.tips': '失効日の30、60、90日前に通知',

    'app.ui.brand_reg_new.message.error.fetch_user_fail': 'ユーザー情報が取得失敗になりました。',
    'app.ui.brand_reg_new.message.error.create_fail': '作成に失敗しました。',
    'app.ui.brand_reg_new.message.error.tel_error': '正しい電話番号を入力してください。',
    'app.ui.brand_reg_new.message.error.name_error': '30文字以内の会社名を入力してください。',
    'app.ui.brand_reg_new.message.error.address_error': '100文字以内の住所を入力してください。',
    'app.ui.brand_reg_new.message.error.remarks_error': '500文字以内の備考を入力してください。',

    // manage/client/xxx (Brand Reg.)
    'app.ui.brand_reg_detail.title': 'クライアント詳細',
    'app.ui.brand_reg_detail.basicinfo.title': '基本情報',
    'app.ui.brand_reg_detail.contract.title': '契約情報',
    'app.ui.brand_reg_detail.setting.title': 'データ設定',
    'app.ui.brand_reg_detail.setting.ec_report': 'ECレポート',
    'app.ui.brand_reg_detail.setting.ec_master_information': 'ECマスター情報',
    'app.ui.brand_reg_detail.setting.ws_report': 'WSレポート',
    'app.ui.brand_reg_detail.setting.proxy': '委任状',
    'app.ui.brand_reg_detail.setting.trademark_registration': '商標登録国・地区',
    'app.ui.brand_reg_detail.setting.other': 'その他',

    'app.ui.brand_reg_detail.message.error.delete_failed': '削除に失敗しました。',
    'app.ui.brand_reg_detail.message.error.client_fetch_failed': 'クライアントの情報が取得失敗になりました。',
    'app.ui.brand_reg_detail.message.error.user_fetch_failed': 'アナリストの取得に失敗しました。',
    'app.ui.brand_reg_detail.message.error.tel_error': '正しい電話番号を入力してください。',
    'app.ui.brand_reg_detail.message.error.name_error': '30文字以内の会社名を入力してください。',
    'app.ui.brand_reg_detail.message.error.address_error': '100文字以内の住所を入力してください。',
    'app.ui.brand_reg_detail.message.error.remarks_error': '500文字以内の備考を入力してください。',
    'app.ui.brand_reg_detail.message.warning.delete_warning':'クライアントを削除しますか？',

    // ec-master-report(Brand Reg detail)
    'app.ui.brand_reg_detail.report.list.report_id': '番号',
    'app.ui.brand_reg_detail.report.list.report_name': 'レポート名',
    'app.ui.brand_reg_detail.report.list.report_upload_date': 'アップロード日',
    'app.ui.brand_reg_detail.report.list.report_count': '総数',
    'app.ui.brand_reg_detail.report.list.report_scrape_count': 'Scrape総数',
    'app.ui.brand_reg_detail.report.list.report_repeat_count': '重複数',
    'app.ui.brand_reg_detail.report.list.report_send_date': '公開日',
    'app.ui.brand_reg_detail.report.list.report_uncategrize_count': 'カテゴライズ数',
    'app.ui.brand_reg_detail.report.list.report_uncategrize_count1': '未カテゴライズ数',
    'app.ui.brand_reg_detail.report.list.report_response_date': '回答日',
    'app.ui.brand_reg_detail.report.list.report_response_count': '回答数',

    'app.ui.brand_reg_detail.warn.delete_warn':'削除しますか？',
    'app.ui.brand_reg_detail.info.confirm':'はい',
    'app.ui.brand_reg_detail.info.cancel':'いいえ',
    'app.ui.brand_reg_detail.warn.inherit_warn':'引継しますか？',
    'app.ui.brand_reg_detail.warn.delete_repeat_record_warn':'重複のレコードを削除しますか？',
    'app.ui.brand_reg_detail.warn.show_warn':'カテゴライズなしのレコードをクライアントに表示します。',
    'app.ui.brand_reg_detail.info.open_confirm':'公開する',
    'app.ui.brand_reg_detail.info.open_cancel':'キャンセル',
    'app.ui.brand_reg_detail.messages.info.delete_success':'削除しました。',
    'app.ui.brand_reg_detail.messages.error.delete_fail':'削除は失敗しました。',
    'app.ui.brand_reg_detail.messages.info.renewal_of_contract_success':'引続は完了しました。',
    'app.ui.brand_reg_detail.messages.error.renewal_of_contract_fail':'引続は失敗しました。',
    'app.ui.brand_reg_detail.messages.info.remove_duplicate_success':'重複削除は完了しました。',
    'app.ui.brand_reg_detail.messages.info.open_success':'公開は完了しました。',
    'app.ui.brand_reg_detail.messages.error.open_fail':'公開は失敗しました。',

    // ec-master-product-category(Brand Reg detail)
    'app.ui.brand_reg_detail.product_category.id': '番号',
    'app.ui.brand_reg_detail.product_category.name': '製品区分',
    'app.ui.brand_reg_detail.product_category.name_en': '製品区分(英文)',
    'app.ui.brand_reg_detail.product_category.code': 'コード',
    'app.ui.brand_reg_detail.product_category.modal.title': '製品区分を追加',
    'app.ui.brand_reg_detail.product_category.brand.title': 'ブランド',
    'app.ui.brand_reg_detail.product_category.product.title': '製品区分',
    'app.ui.brand_reg_detail.product_category.additional_item.title': '追加項目',
    'app.ui.brand_reg_detail.product_category.list_black.title': 'ブラックリスト',
    'app.ui.brand_reg_detail.product_category.list_white.title': 'ホワイトリスト',
    'app.ui.brand_reg_detail.product_category.compatible_notation.title': '互換表記',
    'app.ui.brand_reg_detail.product_category.genuine_notation.title': '純正表記',

    'app.ui.brand_reg_detail.product_category.messages.error.category_empty':'製品区分を入力ください。',
    'app.ui.brand_reg_detail.product_category.messages.error.category_en_empty':'製品区分(英文)を入力ください。',
    'app.ui.brand_reg_detail.product_category.messages.error.code_empty':'コードを入力ください。',

    // ec-master-custom-column(Brand Reg detail)
    'app.ui.brand_reg_detail.custom_column.modal.title': '項目を追加',
    'app.ui.brand_reg_detail.custom_column.column_id': '番号',
    'app.ui.brand_reg_detail.custom_column.column_name': '項目名',
    'app.ui.brand_reg_detail.custom_column.column_category': '項目区分',
    'app.ui.brand_reg_detail.custom_column.column_position': '場所（項目の後）',
    'app.ui.brand_reg_detail.custom_column.column_order': '順番',

    'app.ui.brand_reg_detail.custom_column.messages.error.item_empty':'項目名を入力ください。',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_category_empty':'項目区分を入力ください。',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_position_empty':'場所（項目の後）を入力ください。',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_order_empty':'順番を入力ください。',

    // ec-master-special-url(Brand Reg detail)
    'app.ui.brand_reg_detail.special_url.id': '番号',
    'app.ui.brand_reg_detail.special_url.url': 'URL',
    'app.ui.brand_reg_detail.special_url.seller': '出品者名',
    'app.ui.brand_reg_detail.special_url.sellerid': '出品者ID',
    'app.ui.brand_reg_detail.special_url.company': '企業名',
    'app.ui.brand_reg_detail.special_url.remarks': '備考',
    'app.ui.brand_reg_detail.special_url.modal.title_black': 'ブラックリスト',
    'app.ui.brand_reg_detail.special_url.modal.title_white': 'ホワイトリスト',

    'app.ui.brand_reg_detail.special_url.messages.error.url_empty':'URLを入力ください。',
    'app.ui.brand_reg_detail.special_url.messages.error.seller_name_empty':'出品者名を入力ください。',
    'app.ui.brand_reg_detail.special_url.messages.error.seller_id_empty':'出品者名を入力ください。',
    'app.ui.brand_reg_detail.special_url.messages.error.company_empty':'企業名を入力ください。',
    'app.ui.brand_reg_detail.special_url.messages.error.remarks_empty':'備考を入力ください。',

    // ec-master-special-word(Brand Reg detail)
    'app.ui.brand_reg_detail.special_word.id': '番号',
    'app.ui.brand_reg_detail.special_word.word': '表記',
    'app.ui.brand_reg_detail.special_url.modal.title_1': '純正表記',
    'app.ui.brand_reg_detail.special_url.modal.title_2': '互換表記',

    'app.ui.brand_reg_detail.special_url.messages.error.word_empty': '表記を入力ください。',

    // master-attorney-letter(Brand Reg detail)
    'app.ui.brand_reg_detail.attorney_letter.id': '番号',
    'app.ui.brand_reg_detail.attorney_letter.region': '国・地区',
    'app.ui.brand_reg_detail.attorney_letter.startDate': '開始日',
    'app.ui.brand_reg_detail.attorney_letter.endDate': '終了日',
    'app.ui.brand_reg_detail.attorney_letter.remindFlag': 'リマインドメール',
    'app.ui.brand_reg_detail_attorney_letter.modal.title': '委任状',

    'app.ui.brand_reg_detail.attorney_letter.message.error.region_empty': '国・地区を入力ください。',
    'app.ui.brand_reg_detail.attorney_letter.message.error.start_date_empty': '開始日を入力ください。',
    'app.ui.brand_reg_detail.attorney_letter.message.error.end_date_empty': '終了日を入力ください。',
    'app.ui.brand_reg_detail.attorney_letter.message.info.remindFlag_success': 'リマインドメールを入力ください。',

    // ws-master-report
    'app.ui.brand_reg_detail.ws_report.info.open_success': '公開しました。',
    'app.ui.brand_reg_detail.ws_report.error.open_fail': '公開は失敗しました。',

    // master-trademark-country(Brand Reg detail)
    'app.ui.brand_reg_detail.trademark_country.status': '商標登録状況',
    'app.ui.brand_reg_detail.trademark_country.modal.title': '商標情報',
    'app.ui.brand_reg_detail.trademark_country.trademark_id': 'ID',
    'app.ui.brand_reg_detail.trademark_country.trademark_client_id': 'クライアント',
    'app.ui.brand_reg_detail.trademark_country.trademark_no': '商標番号',
    'app.ui.brand_reg_detail.trademark_country.trademark_region': '国・地区',
    'app.ui.brand_reg_detail.trademark_country.trademark_type': '製品ジャンル',
    'app.ui.brand_reg_detail.trademark_country.trademark_text': '文字・ロゴ',
    'app.ui.brand_reg_detail.trademark_country.trademark_register_date': '登録日',
    'app.ui.brand_reg_detail.trademark_country.trademark_invalid_date': '失効日',
    'app.ui.brand_reg_detail.trademark_country.trademark_belongsto': '権利者',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_ja': '指定製品・役務（和文）',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_en': '指定製品・役務（英文）',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_cn': '指定製品・役務（中文）',
    'app.ui.brand_reg_detail.trademark_country.trademark_status': 'ステータス',
    'app.ui.brand_reg_detail.trademark_country.trademark_remarks': '備考',

    'app.ui.brand_reg_detail.trademark_country.message.info.update_success': '更新しました。',
    'app.ui.brand_reg_detail.trademark_country.message.error.update_fail': '更新は失敗しました。',

    // ec-master-other(Brand Reg detail)
    'app.ui.brand_reg_detail.other.indication_price': '表示単価',
    'app.ui.brand_reg_detail.other.single_price': '一律単価',
    'app.ui.brand_reg_detail.other.inventory': '在庫数',
    'app.ui.brand_reg_detail.other.sale': '販売（Sold)数',
    'app.ui.brand_reg_detail.other.coefficient': '一律係数',
    'app.ui.brand_reg_detail.other.arithmetic': 'インパクト金額計算式：A＊B＊C',
    'app.ui.brand_reg_detail.other.unit_price': '単価(A)：',
    'app.ui.brand_reg_detail.other.unit_coefficient': '係数(B)：',
    'app.ui.brand_reg_detail.other.others': 'その他(C)：',

    'app.ui.brand_reg_detail.other.message.info.update_success': '更新しました。',
    'app.ui.brand_reg_detail.other.message.error.update_fail': '更新は失敗しました。',

    // ec-master-brand(Brand Reg detail)
    'app.ui.brand_reg_detail.brand.keyword': '表記',
    'app.ui.brand_reg_detail.brand.model': 'モデル',
    'app.ui.brand_reg_detail.brand.brand_addition': 'ブランド追加',
    'app.ui.brand_reg_detail.brand.model_addition': 'モデル追加',
    'app.ui.brand_reg_detail.brand.keyword_addition': '表記追加',

    'app.ui.brand_reg_detail.brand.table.brand_name': 'ブランド',
    'app.ui.brand_reg_detail.brand.table.keyword_id': '番号',
    'app.ui.brand_reg_detail.brand.table.keyword_name': 'ブランド表記',
    'app.ui.brand_reg_detail.brand.table.model_id': '番号',
    'app.ui.brand_reg_detail.brand.table.model_name': 'モデル',
    'app.ui.brand_reg_detail.brand.table.model_price': '定価（＄）',

    'app.ui.brand_reg_detail.brand.message.error.brand_empty': 'ブランドを入力ください。',
    'app.ui.brand_reg_detail.brand.message.error.keyword_empty': 'ブランド表記を入力ください。',
    'app.ui.brand_reg_detail.brand.message.error.model_empty': 'モデルを入力ください。',
    'app.ui.brand_reg_detail.brand.message.error.price_empty': '定価を入力ください。',

    // ec-task
    'app.ui.ec_task.title': 'ECスクレイピングタスク',
    'app.ui.ec_task.table.status': '状態',
    'app.ui.ec_task.table.id': 'タスクID',
    'app.ui.ec_task.table.clientId': 'クライアント',
    'app.ui.ec_task.table.title': 'タイトル',
    'app.ui.ec_task.table.type': 'タイプ',
    'app.ui.ec_task.table.planStartDate': '開始日',
    'app.ui.ec_task.table.total': '取得件数',
    'app.ui.ec_task.table.remarks': '備考',
    'app.ui.ec_task.table.site': 'サイト',
    'app.ui.ec_task.table.keyword': 'キーワード',
    'app.ui.ec_task.table.startUrl': '開始URL',
    'app.ui.ec_task.table.targetCount': '希望件数',
    'app.ui.ec_task.table.recordCount': '取得件数',
    'app.ui.ec_task.table.modal_title': 'URL追加',
    'app.ui.ec_task_detail.title': '詳細ECスクレイピング',
    'app.ui.ec_task_new.title': '新規ECスクレイピング',

    'app.ui.ec_task.table.info.extraction.dashboard': '情報抽出(ダッシュボード表示型)',
    'app.ui.ec_task.table.info.extraction.price_monitoring_format': '情報抽出(プライスモニタリング形式)',
    'app.ui.ec_task.table.info.extraction.download': '情報抽出(ダウンロード型)',

    'app.ui.ec_task.message.error.clients_fetch_failed': 'クライアント情報が取得失敗になりました。ネットワークを確認してください。',
    'app.ui.ec_task.message.error.task_fetch_failed': 'タスク検索が失敗しました。',
    // task list message
    'app.ui.ec_task.message.info.scraping_success':'スクレイピングを開始しました。',
    'app.ui.ec_task.message.error.scraping_fail':'スクレイピングスタートが失敗になりました。',
    'app.ui.ec_task.message.info.delete_success':'削除が成功しました。',
    'app.ui.ec_task.message.error.delete_fail':'削除が失敗しました。',
    'app.ui.ec_task.message.info.stop_success':'スクレイピングを停止しました。',
    'app.ui.ec_task.message.error.stop_fail':'失敗になりました。',
    'app.ui.ec_task.message.info.new_success':'新しいタスクを作りました。',
    'app.ui.ec_task.message.error.new_fail':'失敗になりました。',
    'app.ui.ec_task.message.info.search_success':'データ検索に成功しました。',
    'app.ui.ec_task.message.error.search_fail':'データ検索に失敗しました。',
    // task detail update create message
    'app.ui.ec_task.message.error.site_empty':'サイト名を入力ください。',
    'app.ui.ec_task.message.error.keyword_empty':'キーワードを入力ください。',
    'app.ui.ec_task.message.error.start_url_empty':'開始URLを入力ください。',
    'app.ui.ec_task.message.error.target_count_empty':'希望件数を入力ください。',
    // task create message
    'app.ui.ec_task.message.info.create_success':'成功しました。',
    'app.ui.ec_task.message.error.create_fail':'失敗しました。',

    'app.ui.ec_task.detail.title': '详细',
    'app.ui.ec_task.add.title': '新規',

    // Marketplace Reason code
    'app.ui.infringement_category.title' :'カテゴライズ基準',
    'app.ui.infringement_category.modal.title' : 'カテゴリ基準',
    'app.ui.infringement_category.content_empty': '基準が登録されていません',
    'app.ui.infringement_category.subcate.modal.title': 'カテゴリ・集計',

    'app.ui.infringement_category.name': 'カテゴリ基準',
    'app.ui.infringement_category.name_en': 'カテゴリ基準(英文)',
    'app.ui.infringement_category.subname': 'カテゴリ',
    'app.ui.infringement_category.subcate.name': 'カテゴリ',
    'app.ui.infringement_category.subcate.name_en': 'カテゴリ(英文)',
    'app.ui.infringement_category.subcate.code': 'コード',
    'app.ui.infringement_category.subcate.commonCategory': '集計',

    'app.ui.main_category.message.info.delete_success':'カテゴライズ基準を削除しました。',
    'app.ui.main_category.message.error.delete_fail':'カテゴライズ基準の削除に失敗しました。',
    'app.ui.main_category.message.info.update_success':'カテゴライズ基準に更新しました。',
    'app.ui.main_category.message.error.update_fail':'カテゴライズ基準の更新に失敗しました。',
    'app.ui.main_category.message.info.create_success':'新しいカテゴライズ基準を作成しました。',
    'app.ui.main_category.message.error.create_fail':'カテゴライズ基準の作成が失敗しました。',
    'app.ui.main_category.message.error.name_empty':'カテゴリ基準を入力ください。',
    'app.ui.main_category.message.error.name_en_empty':'カテゴリ基準(英文)を入力ください。',

    'app.ui.sub_category.message.info.update_success': 'カテゴリに更新されました。',
    'app.ui.sub_category.message.error.update_fail': 'カテゴリの更新に失敗しました。',
    'app.ui.sub_category.message.info.delete_success': 'カテゴリの削除に成功しました。',
    'app.ui.sub_category.message.error.delete_fail': 'カテゴリの削除に失敗しました。',
    'app.ui.sub_category.message.info.create_success': '新しいカテゴリを作成しました。',
    'app.ui.sub_category.message.error.create_fail': 'カテゴリの作成が失敗しました。',
    'app.ui.sub_category.message.error.name_empty': 'カテゴリを入力ください。',
    'app.ui.sub_category.message.error.name_en_empty': 'カテゴリ(英文)を入力ください。',
    'app.ui.sub_category.message.error.code_empty': 'コードを入力ください。',
    'app.ui.sub_category.message.error.commoncategory_empty': '集計を入力ください。',

    // Manager reg
    'app.ui.manager_analyst.title': '管理者登録一覧',
    'app.ui.manager_analyst.table.user_id': 'ID',
    'app.ui.manager_analyst.table.user_name': '名前',
    'app.ui.manager_analyst.table.user_role': 'ロール',
    'app.ui.manager_analyst.table.user_mail': 'メール',
    'app.ui.manager_analyst.table.user_tel': '電話番号',
    'app.ui.manager_analyst.table.user_remarks': '備考',
    'app.ui.manager_analyst.table.user_last_used_date': '最終ログイン日付',
    'app.ui.manager_analyst.table.user_create_at': '新規日付',

    'app.ui.manager_analyst.message.info.delete_success': '削除成功しました。',
    'app.ui.manager_analyst.message.error.delete_fail': '削除失敗しました。',

    'app.ui.manager_analyst_new.title': 'アナリスト新規',
    'app.ui.manager_analyst_new.box.title': '基本情報',
    'app.ui.manager_analyst_new.box.name': '名前（必須）',
    'app.ui.manager_analyst_new.box.email': 'メール（必須）',
    'app.ui.manager_analyst_new.box.password': 'パスワード（必須）',
    'app.ui.manager_analyst_new.box.password2': 'パスワード(確認)（必須）',
    'app.ui.manager_analyst_new.box.role': 'ロール（必須）',
    'app.ui.manager_analyst_new.box.tel': '電話番号',
    'app.ui.manager_analyst_new.box.remarks': '備考',

    'app.ui.manager_analyst_new.message.error.name_empty': '名前を入力ください。',
    'app.ui.manager_analyst_new.message.error.password_fail': 'パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。',
    'app.ui.manager_analyst_new.message.error.password_unequal': '新パスワードと新パスワード（確認）は同じではなく、確認してください。',
    'app.ui.manager_analyst_new.message.error.email_fail': '正しいメールを入力してください。',
    'app.ui.manager_analyst_new.message.error.role_empty': 'ロールを選んでください。',
    'app.ui.manager_analyst_new.message.error.phone_fail': '正しい電話番号を入力してください。',
    'app.ui.manager_analyst_new.message.error.create_fail': '新規登録失敗しました。',

    'app.ui.manager_analyst_detail.title': 'アナリスト詳細',
    'app.ui.manager_analyst_detail.box.basic_title': '基本情報',
    'app.ui.manager_analyst_detail.box.login_record_title': '登録情報',
    'app.ui.manager_analyst_detail.box.basic_id': 'ID',
    'app.ui.manager_analyst_detail.box.basic_name': '名前（必須）',
    'app.ui.manager_analyst_detail.box.basic_mail': 'メール',
    'app.ui.manager_analyst_detail.box.basic_role': 'ロール（必須）',
    'app.ui.manager_analyst_detail.box.basic_tel': '電話番号',
    'app.ui.manager_analyst_detail.box.basic_remarks': '備考',
    'app.ui.manager_analyst_detail.box.login_record_startDate': '最終ログイン日',
    'app.ui.manager_analyst_detail.box.login_record_endDate': '新規日付',

    // login
    'app.ui.login.label.mail': 'メール：',
    'app.ui.login.label.password': 'パスワード：',
    'app.ui.login.label.next': '次へ',
    'app.ui.login.label.password_confirm': 'パスワード（確認）：',
    'app.ui.login.label.name': '名前：',
    'app.ui.login.label.verification_code': '確認コード：',
    'app.ui.login.button.login': 'ログイン',
    'app.ui.login.button.setup': '新規',
    'app.ui.login.button.to_setup': '新規登録',
    'app.ui.login.button.forget_password': 'パスワードを忘れた方はこちら',
    'app.ui.login.button.to_login': 'ログイン画面へ',
    'app.ui.login.button.get_verification': '受け取る',

    'app.ui.login.label.new_password': '新パスワード：',
    'app.ui.login.label.new_password_confirm': '新パスワード（確認）：',
    'app.ui.login.button.reset': 'リセット',

    'app.ui.login.message.error.no_mail': 'メールを入力ください。',
    'app.ui.login.message.error.mail_format': '正しいメールを入力ください。',
    'app.ui.login.message.error.no_password': 'パスワードを入力ください。',
    'app.ui.login.message.error.no_verification_code': '認証コードを入力してください。',
    'app.ui.login.message.error.no_confirm_password': 'パスワード（確認）を入力ください。',
    'app.ui.message.error.login_failed':'メール、パスワードまた認証コードを確認してください。',

    // test purchase
    "app.ui.test_purchase.title": 'テスト購入',
    "app.ui.test_purchase.table.id": 'ID',
    "app.ui.test_purchase.table.client": 'クライアント',
    "app.ui.test_purchase.table.region": '国・地区',
    "app.ui.test_purchase.table.site": 'サイト名',
    "app.ui.test_purchase.table.count": '購入点数',
    'app.ui.test_purchase.modal.site_add': 'サイトを追加',

    'app.ui.test_purchase.error.client_empty': 'クライアントを入力ください。',
    'app.ui.test_purchase.error.region_empty': '国・地区を入力ください。',
    'app.ui.test_purchase.error.site_empty': 'サイト名を入力ください。',
    'app.ui.test_purchase.error.count_empty': '件数を入力ください。',
    // TODO
    'app.ui.test_purchase.info.create_client_success': '成功しました。',
    'app.ui.test_purchase.error.create_client_failed': '失敗しました。',
    'app.ui.test_purchase.info.update_client_success': '成功しました。',
    'app.ui.test_purchase.error.update_client_failed': '失敗しました。',

    // T/M trademark
    'app.ui.trademark.register_title': '商標出願',
    'app.ui.trademark.container.trademark_info.title': '商標情報',
    'app.ui.trademark.label.trademark_word': '商標文字',
    'app.ui.trademark.label.trademark_word_description': '商標が文字だけの場合は、こちらに直接ご記入ください。ただし、デザイン文字の場合はこちらに記入せずに、その画像を添付してください。',
    'app.ui.trademark.label.trademark_image': '商標画像',
    'app.ui.trademark.label.trademark_image_description': 'ロゴでの登録をご希望の場合は、画像を添付してください。(10MB以内)',
    'app.ui.trademark.label.trademark_detail': '商標詳細',
    'app.ui.trademark.label.trademark_detail_description': 'その商標をどうのような商品・サービスに使用しますか？具代的にご記入ください。',
    'app.ui.trademark.label.trademark_owner': '出願人名',
    'app.ui.trademark.label.trademark_owner_description': '法人の場合は、登記上の法人名をご入力ください。',
    'app.ui.trademark.container.contact_info.title': 'ご連絡情報',
    'app.ui.trademark.label.contact_name': 'ご担当者',
    'app.ui.trademark.label.contact_mail': 'メール',
    'app.ui.trademark.label.contact_tel': 'Tel',
    'app.ui.trademark.label.contact_fax': 'Fax',
    'app.ui.trademark.label.remarks': '通信欄',
    'app.ui.trademark.label.remarks_description': 'ご要望など',

    'app.ui.trademark.imageupload.download': 'ダウンロード',
    'app.ui.trademark.imageupload.submit': '閉じる',

    'app.ui.trademark.message.info.remove_success': 'Delete successful',
    'app.ui.trademark.message.error.remove_error': 'Delete failed',
    'app.ui.trademark.message.error.no_word_or_image_mark': '商標文字あるいは商標画像を入力してください。',
    'app.ui.trademark.message.error.tel_format': '正しい電話番号を入力してください。',
    'app.ui.trademark.message.success.mail_success': '送信しました。',
    'app.ui.trademark.message.error.no_trademark_discraption': '商標詳細を入力ください。',
    'app.ui.trademark.message.error.no_owner': '出願人名を入力ください。',
    'app.ui.trademark.message.error.no_contact_person': 'ご担当者を入力ください。',
    'app.ui.trademark.message.error.no_contact_mail': 'ご担当者のメールを入力ください。',
    'app.ui.trademark.message.error.contact_mail_format': '正しいメールを入力ください。',
    'app.ui.trademark.message.error.no_contact_tel': 'ご担当者の電話番号を入力ください。',

    'app.ui.trademark.button.send_mail': '送信',
    'app.ui.trademark.button.to_registration': '新規',
    'app.ui.trademark.button.to_request_trademark': '商標出願希望',
    'app.ui.trademark.button.to_trademark_list': 'リスト登録',

    'app.ui.trademark.list.title': '商標',

    'app.ui.trademark.table.id': 'ID',
    'app.ui.trademark.table.client': 'クライアント',
    'app.ui.trademark.table.trademark_no': '商標番号',
    'app.ui.trademark.table.trademark_region': '国・地区',
    'app.ui.trademark.table.trademark_product_genre': '製品ジャンル',
    'app.ui.trademark.table.trademark_word': '文字・ロゴ',
    'app.ui.trademark.table.trademark_registration_date': '登録日',
    'app.ui.trademark.table.trademark_expire_date': '失効日',
    'app.ui.trademark.table.remind_mail_flg': 'リマインドメール',
    'app.ui.trademark.table.trademark_right_holder': '権利者',
    'app.ui.trademark.table.description_ja': '指定製品・役務（和文）',
    'app.ui.trademark.table.description_en': '指定製品・役務（英文）',
    'app.ui.trademark.table.description_cn': '指定製品・役務（中文）',
    'app.ui.trademark.table.trademark_registration_status': 'ステータス',
    'app.ui.trademark.table.trademark_remarks': '備考',

    'app.trademark_registration_status.register': '登録',
    'app.trademark_registration_status.applying': '申請中',
    'app.trademark_registration_status.rejection': '拒否',

    'app.ui.trademark.add.title': '商標新規',
    'app.ui.trademark.add.container_search.title': '商標情報検索',
    'app.ui.trademark.add.container_search.button': '外部検索',
    'app.ui.trademark.add.container_body.title': '商標情報',
    'app.ui.trademark.add.product_genre_prefix': "第{number}類",
    'app.ui.trademark.add.remind_message': "失効日の30、60、90日前に通知",

    'app.ui.trademark.update.title': '商標詳細',

    'app.ui.trademark.message.error.no_search_db': "外部データベースを選択してください。",
    'app.ui.trademark.message.info.image_uploading': "商標画像はまだアップロード中です。少々お待ちください。",
    'app.ui.trademark.message.info.trademark_updated': "更新しました。",
    'app.ui.trademark.message.error.no_region': "国・地区を入力ください。",
    'app.ui.trademark.message.error.no_trademark_no': "商標番号を入力ください。",
    'app.ui.trademark.message.error.no_product_genre': "製品ジャンルを入力ください。",
    'app.ui.trademark.message.error.no_word_mark': "文字・ロゴを入力ください。",

    'app.ui.trademark.message.info.trademark_added': "完了しました。",
    'app.ui.trademark.message.error.no_client': "クライアントを入力ください。",

    // reports
    'app.ui.ec_reports.table.id': "ID",
    'app.ui.ec_reports.table.report_name': "レポート名",
    'app.ui.ec_reports.table.upload_date': "アップロード日",
    'app.ui.ec_reports.table.scrape_total': "取得総数",
    'app.ui.ec_reports.table.send_date': "公開日",
    'ap.ui.ec_reports.table.categorized_count': "カテゴライズ数",
    'app.ui.ec_reports.table.not_categorize_date': "未カテゴライズ数",
    'app.ui.ec_reports.table.response_date': "回答日",
    'app.ui.ec_reports.table.response_total': "回答数",
    'app.ui.ec_reports.action.response': "回答",

    'app.ui.ec_reports.message.info.had_notify_analyst': "アナリストに知らせました。",
    'app.ui.ec_reports.message.error.nofity_failed': "エラーが発生しました。",
    'app.ui.ec_reports.message.error.unknown': "エラーが発生しました。",

    // ec detail
    'app.ui.ec_detail.title': "EC詳細データ",
    'app.ui.ec_detail.tab.web': "Web",
    'app.ui.ec_detail.tab.thumbnail': "サムネイル",
    'app.ui.ec_detail.tab.screenshot': "違反画像",
    'app.ui.ec_detail.tab.scrape': "Scrape情報",
    'app.ui.ec_detail.tab.categorized_info': "カテゴライズ情報",
    'app.ui.ec_detail.tab.client_response': "クライアント回答情報",
    'app.ui.ec_detail.tab.ipf_report_info': "IPF申告情報",
    'app.ui.ec_detail.button.back': "一覧に戻る",
    'app.ui.ec_detail.step.1': "申告結果(1回目)",
    'app.ui.ec_detail.step.2': "申告結果(2回目)",
    'app.ui.ec_detail.step.3': "申告結果(3回目)",
    'app.ui.ec_detail.step.4': "最終結果",
    'app.ui.ec_detail.orginal_url': 'ORGINAL URL',
    'app.ui.ec_detail.image_url': 'IMAGE URL',
    'app.ui.ec_detail.message.info.update_success': "更新成功しました。",
    'app.ui.ec_detail.message.error.update_failed': "更新失敗しました。",
    'app.ui.ec_detail.message.error.update_rejected': "権限がありません.",

    // home
    'app.ui.home.title': 'Home',
    'app.ui.home.online.cases': "ブランド保護件数",
    'app.ui.home.offline.cases': "法的措置",
    'app.ui.home.registration.cases': "出願件数",
    'app.ui.home.test_purchase.cases': "テスト購入数",

    'app.ui.home.section.tm_register_region': "権利出願国",
    'app.ui.home.section.online_bad_seller': "オンライン権利侵害出品者",
    'app.ui.home.section.test_purchase_cases_number': "テスト購入件数",
    'app.ui.home.section.offline_protection': "オンライン法的措置",
    'app.ui.home.section.ipf_news': "IP FORWARD NEWS",

    'app.ui.home.section.unit': "件",

    // notification center
    'app.ui.notification_center.title': 'お知らせ',
    'app.ui.notification_center.table.column.id': "ID",
    'app.ui.notification_center.table.column.title': "タイトル",
    'app.ui.notification_center.table.column.content': "内容",
    'app.ui.notification_center.table.column.type': "種類",
    'app.ui.notification_center.table.column.from': "発信元",
    'app.ui.notification_center.table.column.send_date': "発信日付",
    'app.ui.notification_center.table.column.viewed': "既読",

    // ws
    'app.ui.wslist.table.search_engine': '検索エンジン',
    'app.ui.wslist.table.page_domain': 'ドメイン',
    'app.ui.wslist.table.page_url': 'URL',
    'app.ui.wslist.table.domain_region': 'ドメイン範囲',
    'app.ui.wslist.table.tld': 'TLD',
    'app.ui.wslist.table.domain_contact': '連絡先',
    'app.ui.wslist.table.redirect_url': 'リダイレクト先',
    'app.ui.wslist.table.whois': 'ドメイン情報',
    'app.ui.wslist.table.domain_created_at': 'ドメイン作成日付',
    'app.ui.wslist.table.domain_expire_at': 'ドメイン失効日付',
    'app.ui.wslist.table.domain_updated_at': 'ドメイン更新日付',
    'app.ui.wslist.search.collapse': "検索条件",

    "app.ui.ws_detail.title": "WS詳細データ",
    'app.ui.ws_detail.section.scrape_info': "基本情報",
}

const combine_ja_lang = {
    ...common_ja_lang,
    ...ec_crawler_task_ja_lang
}

export default combine_ja_lang


