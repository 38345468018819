import { ECColumnInfo } from '../../../config/column';
import common_messages from "../../../locales/messages";

export const getKeywordSearchItems = (intl) => {
    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
    // localKey mean language environment
    return [
        {
            name: ECColumnInfo.ec_id.name[localeKey],
            value: "ec_id"
        },
        {
            name: ECColumnInfo.ec_no.name[localeKey],
            value: "ec_no"
        },
        {
            name: ECColumnInfo.ec_product_title.name[localeKey],
            value: "ec_product_title"
        },
        {
            name: ECColumnInfo.ec_product_seller.name[localeKey],
            value: "ec_product_seller"
        },
        {
            name: ECColumnInfo.ec_product_url.name[localeKey],
            value: "ec_product_url"
        },
        {
            name: ECColumnInfo.ec_search_keyword.name[localeKey],
            value: "ec_search_keyword"
        },
        {
            name: ECColumnInfo.ec_product_brand.name[localeKey],
            value: 'ec_product_brand',
        },
        {
            name: ECColumnInfo.ec_categorize_remarks.name[localeKey],
            value: "ec_categorize_remarks"
        },
        {
            name: ECColumnInfo.ec_response_reason.name[localeKey],
            value: "ec_response_reason"
        },
        // {
        //     name: "1回目備考",
        //     value: "ec_process1_remarks"
        // },
        // {
        //     name: "2回目備考",
        //     value: "ec_process2_remarks"
        // },
        // {
        //     name: "3回目備考",
        //     value: "ec_process3_remarks"
        // },
        {
            name: ECColumnInfo.ec_process_remarks.name[localeKey],
            value: "ec_process_remarks"
        },
    ]
}


export const getDateSearchItems = (intl) => {
    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
    return [
        {
            name: ECColumnInfo.ec_scan_date.name[localeKey],
            value: "ec_scan_date"
        },
        {
            name: ECColumnInfo.ec_categorize_date.name[localeKey],
            value: "ec_categorize_date"
        },
        {
            name: ECColumnInfo.ec_response_date.name[localeKey],
            value: "ec_response_date"
        },
        {
            name: ECColumnInfo.ec_process1_date.name[localeKey],
            value: "ec_process1_date"
        },
        
        {
            name: ECColumnInfo.ec_process1_confirm_date.name[localeKey],
            value: "ec_process1_confirm_date"
        },
        {
            name: ECColumnInfo.ec_process2_date.name[localeKey],
            value: "ec_process2_date"
        },
        {
            name: ECColumnInfo.ec_process2_confirm_date.name[localeKey],
            value: "ec_process2_confirm_date"
        },
        {
            name: ECColumnInfo.ec_process3_date.name[localeKey],
            value: "ec_process3_date"
        },
        {
            name: ECColumnInfo.ec_process3_confirm_date.name[localeKey],
            value: "ec_process3_confirm_date"
        },
        {
            name: ECColumnInfo.ec_process_confirm_date.name[localeKey],
            value: "ec_process_confirm_date"
        },
    ]
}
