const genECInfringementCategorizationChart = (config) => {
    let { echarts, legend, title, xAxisData, seriesData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let myChart = echarts.init(document.getElementById(elementId));
    window.addEventListener("resize", function () {
        myChart.resize();
    });

    let option = {
        title: {
            text: title
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: legend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    rotate: 30,
                    width: 70,
                    overflow: "truncate",
                    ellipsis: "..."
                },
                data: xAxisData,
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: seriesData.map((item) => {
            return {
                name: item.name,
                type: 'line',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                data: item.data
            }
        }),
    };
    myChart.setOption(option);
    myChart.resize();

}

export default genECInfringementCategorizationChart;