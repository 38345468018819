import request from '@/utils/request'


export function reqUserInfo(userid) {
    return request({
      url: '/api/user/userinfo',
      method: 'post',
      data:{
        userid:userid
      }
    })
  }

export function reqUpdateUserInfo(data){
  return request({
    url:'/api/user/update-userinfo',
    method:'post',
    data:data
  });
}

export function reqResetPassword(data){
  return request({
    url:'/api/user/reset-password',
    method:'post',
    data:data
  })
}

export function reqUpdateUserSettings(data){
  return request({
    url:'/api/user/update-settings',
    method:'post',
    data:data
  })
}