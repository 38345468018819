import "./index.less"

import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker, message, Checkbox } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import { reqCreateClient } from '../../../api/admin-client'
import { reqSearchUsers } from '../../../api/admin-user'
const { Option } = Select;

class ClientNewBackup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientInfo: {},
            analysts: []
        }
        this.formRef = React.createRef();

        reqSearchUsers({
            "role": 4
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let analysts = data.data.users;
                this.setState({ analysts })
            }
        }).catch((e) => {

        })
    }

    checkService = (startDate, endDate, fee, currency) => {
        if (startDate && endDate && fee && currency) {
            return true;
        } else {
            return false;
        }
    }
    // form
    onFinish = (values) => {
        const { name, tel, address, managers, remarks,
            ec_start_date, ec_end_date, ec_fee, ec_currency, ec_mail,
            sns_start_date, sns_end_date, sns_fee, sns_currency, sns_mail,
            ws_start_date, ws_end_date, ws_fee, ws_currency, ws_mail,
            other_start_date, other_end_date, other_fee, other_currency, other_mail } = values;
        let intMangerIds = managers ? managers.map((managerId) => {
            return parseInt(managerId);
        }) : []
        let body = {
            name,
            tel,
            address,
            managerIds: intMangerIds,
            remarks,
        }   

        if (this.checkService(ec_start_date, ec_end_date, ec_fee, ec_currency)) {
            body["ec"] = {
                startDate: ec_start_date.format("YYYY-MM-DD"),
                endDate: ec_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(ec_fee),
                currency: parseInt(ec_currency),
                remindMail: ec_mail && ec_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(sns_start_date, sns_end_date, sns_fee, sns_currency)) {
            body["sns"] = {
                startDate: sns_start_date.format("YYYY-MM-DD"),
                endDate: sns_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(sns_fee),
                currency: parseInt(sns_currency),
                remindMail: sns_mail && sns_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(ws_start_date, ws_end_date, ws_fee, ws_currency)) {
            body["ws"] = {
                startDate: ws_start_date.format("YYYY-MM-DD"),
                endDate: ws_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(ws_fee),
                currency: parseInt(ws_currency),
                remindMail: ws_mail && ws_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(other_start_date, other_end_date, other_fee, other_currency)) {
            body["other"] = {
                startDate: other_start_date.format("YYYY-MM-DD"),
                endDate: other_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(other_fee),
                currency: parseInt(other_currency),
                remindMail: other_mail && other_mail[0] === "1" ? true : false,
            }
        }
        
        reqCreateClient(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.props.history.push("/manage/client")
            }
        }).catch((error) => {
            message.error("")
        })
    };

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }

    // life circle
    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("manage-client-list"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "ブランド登録一覧",
                path: "/manage/client",
            },
            {
                name: "新規",
            }
        ]));
    }

    createCurrencySelect = () => {
        return (
            <Select className="analyst-select">
                <Option key="USD" value={1}>USD</Option>
                <Option key="JPY" value={2}>JPY</Option>
                <Option key="CNY" value={3}>CNY</Option>
            </Select>
        )
    }

    render() {
        document.title = "アナリスト新規"
        return (
            <div className="client-new-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title="基本情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>会社名（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string",
                                                max: 30,
                                                required: true,
                                                message: "会社名を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>連絡先</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                pattern: "",
                                                message: "正しい連絡先を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>住所</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="address" rules={[
                                            {
                                                type: "string",
                                                max: 100,
                                                message: "正しい住所を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>Online Protection 担当者</p>
                                    </div>
                                    <div>
                                        <Form.Item noStyle={true} name="managers" >
                                            <Select mode="multiple" showArrow={true} className="analyst-select">
                                                {
                                                    this.state.analysts.map((analysts) => {
                                                        return <Option value={analysts.user_id.toString()}>{analysts.user_name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>備考</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="remarks" rules={[
                                            {
                                                type: "string",
                                                max: 500,
                                                message: "正しいアナリストを入力ください。",
                                            },
                                        ]}>
                                            <Input.TextArea className="remarks-text" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </EFSimpleContainer>
                        </Col>
                        <Col span={12}>
                            <EFSimpleContainer title="契約情報">
                                <div className="item-wrapper">
                                    <Row className="date-row">
                                        <Col span={4}>
                                            EC Protection
                                        </Col>
                                        <Col span={4}>契約期間</Col>
                                        <Col span={12}>
                                            <Form.Item noStyle={true} name="ec_start_date">
                                                <DatePicker />
                                            </Form.Item>
                                            〜
                                            <Form.Item noStyle={true} name="ec_end_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>金額</Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="ec_fee">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="ec_currency">
                                                {this.createCurrencySelect()}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>リマインドメール</Col>
                                        <Col span={16}>
                                            <Form.Item noStyle={true} name="ec_mail" initialValue={["1"]}>
                                                <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="item-wrapper">
                                    <Row className="date-row">
                                        <Col span={4}>
                                            SNS Protection
                                        </Col>
                                        <Col span={4}>契約期間</Col>
                                        <Col span={12}>
                                            <Form.Item noStyle={true} name="sns_start_date">
                                                <DatePicker />
                                            </Form.Item>
                                            〜
                                            <Form.Item noStyle={true} name="sns_end_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>金額</Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="sns_fee">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="sns_currency">
                                                {this.createCurrencySelect()}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>リマインドメール</Col>
                                        <Col span={16}>
                                            <Form.Item noStyle={true} name="sns_mail" initialValue={["1"]}>
                                                <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="item-wrapper">
                                    <Row className="date-row">
                                        <Col span={4}>
                                            WS Protection
                                        </Col>
                                        <Col span={4}>契約期間</Col>
                                        <Col span={12}>
                                            <Form.Item noStyle={true} name="ws_start_date">
                                                <DatePicker />
                                            </Form.Item>
                                            〜
                                            <Form.Item noStyle={true} name="ws_end_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>金額</Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="ws_fee">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="ws_currency">
                                                {this.createCurrencySelect()}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>リマインドメール</Col>
                                        <Col span={16}>
                                            <Form.Item noStyle={true} name="ws_mail" initialValue={["1"]}>
                                                <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="item-wrapper">
                                    <Row className="date-row">
                                        <Col span={4}>
                                            Other Protection
                                        </Col>
                                        <Col span={4}>契約期間</Col>
                                        <Col span={12}>
                                            <Form.Item noStyle={true} name="other_start_date">
                                                <DatePicker />
                                            </Form.Item>
                                            〜
                                            <Form.Item noStyle={true} name="other_end_date">
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>金額</Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="other_fee">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item noStyle={true} name="other_currency">
                                                {this.createCurrencySelect()}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                        </Col>
                                        <Col span={4}>リマインドメール</Col>
                                        <Col span={16}>
                                            <Form.Item noStyle={true} name="other_mail" initialValue={["1"]}>
                                                <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item>
                                <div className="acition-container">
                                    <Button htmlType="submit" type="primary" className="update-button">新規</Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(ClientNewBackup)
