// ec-list intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    to_register_trademark: {
        id: "app.ui.eclist.to_trademark_list",
        defaultMessage: "Your Trademark List",
        description: 'Message to greet the user.',

    },
    'eclist.title':{
        id: "app.ui.eclist.title",
        defaultMessage: "Marketplace Database",
    },
    'table.action_history':{
        id: "app.ui.eclist.table.action_history",
        defaultMessage: "Show edit history",
    },
    "label.blank": {
        id: "app.common.label.blank",
        defaultMessage: "Blank",
    },
    "flag.yes": {
        id: "app.common.flag.yes",
        defaultMessage: "Yes",
    },
    "flag.no": {
        id: "app.common.flag.no",
        defaultMessage: "No",
    },

    "message.info.update_success": {
        id: "app.ui.ec_detail.message.info.update_success",
        defaultMessage: "Update successful.",
    },
    "message.error.update_failed": {
        id: "app.ui.ec_detail.message.error.update_failed",
        defaultMessage: "Failed to update.",
    },
    "message.warn.update_rejected": {
        id: 'app.ui.ec_detail.message.error.update_rejected',
        defaultMessage: "Failed to update.",
    }
})

export default messages