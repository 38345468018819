// ws intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    to_register_trademark: {
        id: "app.ui.eclist.to_trademark_list",
        defaultMessage: "Your Trademark List",
        description: 'Message to greet the user.',

    },
    'wslist.title':{
        id: "app.layout.menu.online_protection.ws_record_list",
        defaultMessage: "Marketplace Database",
    },
    'table.action_history':{
        id: "app.ui.eclist.table.action_history",
        defaultMessage: "Show edit history",
    },
    "label.blank": {
        id: "app.common.label.blank",
        defaultMessage: "Blank",
    },
    "flag.yes": {
        id: "app.common.flag.yes",
        defaultMessage: "Yes",
    },
    "flag.no": {
        id: "app.common.flag.no",
        defaultMessage: "No",
    },


})

export default messages