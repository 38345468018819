import moment from 'moment';
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { Form, Input, DatePicker, Select } from "antd";

import { WSTakedownRequest, WSTakedownResult, WSCategoryType } from "../../../master-data/ws-master-data.js";
import common_messages from "../../../locales/messages";

const getBaseScrapeColumns = () => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.id" />,
            dataIndex: 'id',
            key: 'id',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_no" />,
            dataIndex: 'no',
            key: 'no',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.search_engine" />,
            dataIndex: 'searchEngine',
            key: 'searchEngine',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_month" />,
            dataIndex: 'scanDate',
            key: 'scanDate',
            renderValue: (value) => {
                if (value) {
                    let formatValue = moment(value).format("YYYY-MM")
                    return <p>{formatValue}</p>
                } else {
                    return <p>{value}</p>
                }
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_search_keyword" />,
            dataIndex: 'searchKeyword',
            key: 'searchKeyword',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.page_domain" />,
            dataIndex: 'pageDomain',
            key: 'pageDomain',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.page_url" />,
            dataIndex: 'pageURL',
            key: 'pageURL',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_region" />,
            dataIndex: 'domainRegion',
            key: 'domainRegion',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.tld" />,
            dataIndex: 'tld',
            key: 'tld',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_contact" />,
            dataIndex: 'domainContact',
            key: 'domainContact',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },
    ]
}

const getBaseCategorizeColumns = (role, intl) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_date" />,
            dataIndex: 'categorizeDate',
            key: "categorizeDate",
            renderValue: _createDateRenderNode("categorizeDate", role & 2, intl)
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_word" />,
            dataIndex: 'categoryId',
            key: 'categoryId',
            renderValue: (value) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                if (role & 2) {
                    return (
                        <Form.Item name="categoryId" noStyle={true}>
                            <Select className="select-box">
                                {WSCategoryType.map((item) => {
                                    return (
                                        <Select.Option value={item.value} key={item.value}>{item.name[localeKey]}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>)
                } else {
                    const item = WSCategoryType.find((item) => {
                        return item.value === value
                    })
                    return (<p>{item ? item.name[localeKey] : ""}</p>)
                }
            }
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
            dataIndex: 'remarks',
            key: 'remarks',
            renderValue: (value) => {
                if (role & 2) {
                    return (
                        <Form.Item name="remarks" noStyle={true}>
                            <Input.TextArea className="input-textarea" />
                        </Form.Item>
                    )
                } else {
                    return (<p>{value}</p>)
                }
            }
        },

        {
            title: <FormattedMessage id="app.ui.wslist.table.redirect_url" />,
            dataIndex: 'redirectURL',
            key: 'redirectURL',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        },

        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_created_at" />,
            dataIndex: 'domainCreatedAt',
            key: 'domainCreatedAt',
            renderValue: (value) => {
                if (value) {
                    let formatValue = moment(value).format("YYYY-MM-DD")
                    return <p>{formatValue}</p>
                } else {
                    return <p>{value}</p>
                }
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_expire_at" />,
            dataIndex: 'domainExpireAt',
            key: 'domainExpireAt',
            renderValue: (value) => {
                if (value) {
                    let formatValue = moment(value).format("YYYY-MM-DD")
                    return <p>{formatValue}</p>
                } else {
                    return <p>{value}</p>
                }
            }
        },
        {
            title: <FormattedMessage id="app.ui.wslist.table.domain_updated_at" />,
            dataIndex: 'domainUpdatedAt',
            key: 'domainUpdatedAt',
            renderValue: (value) => {
                if (value) {
                    let formatValue = moment(value).format("YYYY-MM-DD")
                    return <p>{formatValue}</p>
                } else {
                    return <p>{value}</p>
                }
            }
        },
    ]
}

const getWhoisColumn = () => {
    return (
        {
            title: <FormattedMessage id="app.ui.wslist.table.whois" />,
            dataIndex: 'whois',
            key: 'whois',
            renderValue: (value) => {
                return <p>{value}</p>
            }
        }
    )

}

const getBaseResponseColumns = (intl, trademarks) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_action" />,
            dataIndex: 'action',
            key: 'clientAction',
            renderValue: (value) => {
                return (
                    <Form.Item name="action" noStyle={true}>
                        <Select className="select-box">
                            {WSTakedownRequest.map((item) => {
                                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                                return (
                                    <Select.Option value={item.value}>{item.name[localeKey]}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                )
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_reason" />,
            dataIndex: 'responseRemarks',
            key: 'responseRemarks',
            renderValue: (value) => {
                return (
                    <Form.Item name="ec_response_reason" noStyle={true}>
                        <Input.TextArea className="input-textarea" />
                    </Form.Item>
                )
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_trademark" />,
            dataIndex: 'trademarkId',
            key: 'trademarkId',
            renderValue: (value) => {
                return (
                    <Form.Item name="trademarkId" noStyle={true}>
                        <Select className="select-box">
                            {trademarks ? trademarks.map((item) => {
                                return (
                                    <Select.Option value={item.trademark_id}>{item.trademark_no}</Select.Option>
                                )
                            }) : null}
                        </Select>
                    </Form.Item>
                )
            }
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_date" />,
            dataIndex: 'responseDate',
            key: 'responseDate',
            renderValue: _createDateRenderNode("responseDate", true, intl)
        },
    ]
}

const getBaseResult1Columns = (role, intl) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_date" />,
            dataIndex: 'process1Date',
            key: 'process1Date',
            renderValue: _createDateRenderNode("process1Date", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_result" />,
            dataIndex: 'process1ResultId',
            key: 'process1ResultId',
            renderValue: _createProgressResultRenderNode("process1ResultId", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_confirm_date" />,
            dataIndex: 'process1ConfirmDate',
            key: 'process1ConfirmDate',
            renderValue: _createDateRenderNode("process1ConfirmDate", role & 2, intl)
        },
    ]
}

const getBaseResult2Columns = (role, intl) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_date" />,
            dataIndex: 'process2Date',
            key: 'process2Date',
            renderValue: _createDateRenderNode("process2Date", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_result" />,
            dataIndex: 'process2ResultId',
            key: 'process2ResultId',
            renderValue: _createProgressResultRenderNode("process2ResultId", role & 2, intl)
        },

        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_confirm_date" />,
            dataIndex: 'process2ConfirmDate',
            key: 'process2ConfirmDate',
            renderValue: _createDateRenderNode("process2ConfirmDate", role & 2, intl)
        },
    ]
}

const getBaseResult3Columns = (role, intl) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_date" />,
            dataIndex: 'process3Date',
            key: 'process3Date',
            renderValue: _createDateRenderNode("process3Date", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_result" />,
            dataIndex: 'process3ResultId',
            key: 'process3ResultId',
            renderValue: _createProgressResultRenderNode("process3ResultId", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_confirm_date" />,
            dataIndex: 'process3ConfirmDate',
            key: 'process3ConfirmDate',
            renderValue: _createDateRenderNode("process3ConfirmDate", role & 2, intl)
        },
    ]
}

const getBaseResultColumns = (role, intl) => {
    return [
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_result" />,
            dataIndex: 'processResultId',
            key: 'processResultId',
            renderValue: _createProgressResultRenderNode("processResultId", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_confirm_date" />,
            dataIndex: 'processConfirmDate',
            key: 'processConfirmDate',
            renderValue: _createDateRenderNode("processConfirmDate", role & 2, intl)
        },
        {
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_remarks" />,
            dataIndex: "processRemarks",
            key: 'processRemarks',
            renderValue: (value) => {
                if (role & 2) {
                    return (
                        <Form.Item name="processRemarks" noStyle={true}>
                            <Input.TextArea className="input-textarea" />
                        </Form.Item>
                    )
                } else {
                    return (<p>{value}</p>)
                }
            }
        },
    ]
}

const _createProgressResultRenderNode = (dataIndex, isEditable, intl) => {
    return (
        (value) => {
            if (isEditable) {
                return (
                    <Form.Item name={dataIndex} noStyle={true}>
                        <Select className="select-box">
                            {WSTakedownResult.map((item) => {
                                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                                return (
                                    <Select.Option value={item.value}>{item.name[localeKey]}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                )
            } else {
                let item = WSTakedownResult.find((item) => {
                    return item.value === value;
                })
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                return (<p>{item ? item.name[localeKey] : ""}</p>)
            }
        }
    )
}


const _createDateRenderNode = (dateIndex, isEditable, fnKey = null) => {
    return (
        (value) => {
            if (isEditable) {
                return (
                    <Form.Item name={dateIndex} noStyle={true}>
                        <DatePicker accesskey={fnKey} />
                    </Form.Item>
                )
            } else {
                if (value) {
                    return (<p>{moment(value).format("YYYY-MM-DD")}</p>)
                }
                return value
            }
        }
    )
}

export default {
    getBaseScrapeColumns,
    getBaseCategorizeColumns,
    getBaseResponseColumns,
    getBaseResult1Columns,
    getBaseResult2Columns,
    getBaseResult3Columns,
    getBaseResultColumns,
    getWhoisColumn
}