import './index.less'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { RightOutlined } from '@ant-design/icons';
const EFChartNews = (props) => {

    let chartData = []
    if(Array.isArray(props.chartData)){
        chartData = props.chartData
    }
    let title = props.chartTitle

    let showItem = 10
    if(typeof showItem === "number"){
        showItem = props.showItem ? props.showItem : 10
    }

    const renderContent = () => {
        if(chartData.length > 0){
            return <div className='ef-chart-section-item'>
                <div className='ef-chart-item-column'>
                    {chartData.map((item,index) => {
                        if(index < parseInt(showItem/2)){
                            return (<div className='ef-chart-item-link'><span className='dots'></span><a href={item.url ? item.url : ''} target='_blank' rel="noreferrer">{item.title ? item.title : '' }</a></div>)
                        }
                    })}
                </div>
                <div className='ef-chart-item-column'>
                {chartData.map((item,index) => {
                        if((index >= parseInt(showItem/2)) && (index < showItem)){
                            return (<div className='ef-chart-item-link'><span className='dots'></span><a href={item.url ? item.url : ''} target='_blank' rel="noreferrer">{item.title ? item.title : '' }</a></div>)
                        }
                    })}
                </div>
            </div>
        }else {
            return <div></div>
        }
    }

    const onHandleTarget = () => {
        props.moreLink()
    }
  
    return (
        <div className='ef-chart-news'
            style={{
                height: props.height ? props.height + 'px' : '391px',
            }}>
            <div className="ef-title-regular ef-chart-title">{title && <FormattedMessage id={title} />}<a onClick={() => {onHandleTarget()}} target='_blank'><RightOutlined /></a></div>
            <div className="ef-chart-content">
                <div className='ef-chart-section' style={{ height: props.height ? props.height - 88 + 'px' : '240px' }} >
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
export default EFChartNews

