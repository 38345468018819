import { Button, Input, Select, Row, Col, Form, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table";
import {  ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { reqUpdateECSite, reqGetECSite } from '@/api/analyst-site';
import { reqCreateSiteAccount, reqRemoveSiteAccount, reqFetchSiteAccounts, reqUpdateSiteAccount } from '@/api/site-account';
import { CountryConfig } from "@/config/country.js"
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'

import "./index.less"

function EFECSiteDetailViewBackup(props) {
    const formRef = useRef()
    const [site, setSite] = useState({})
    const [accounts, setAccounts] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(() => {
        document.title = "ECサイト詳細"
        store.dispatch(setMenuActiveKey("analyst-ec-site"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: "EC登録",
                path: "/analyst/ec-site",
            },
            {
                name: "詳細",
            },
        ]));
    }, [])

    useEffect(() => {
        const id = props.match.params.id
        reqGetECSite({
            siteId: id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // Todo
                updateState(data.data)
            }
        })
        reqFetchInfringementCategories().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setCategories(data.data.categories)
            }
        }).catch((error) => {
        })
    }, [])

    const createAccountColumns = () => {
        return [
            {
                title: 'アカウント',
                dataIndex: 'account',
                key: 'account',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"account" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "アカウントを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'パスワード',
                dataIndex: 'password',
                key: 'password',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"password" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "パスワードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '携帯電話',
                dataIndex: 'phone',
                key: 'phone',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"phone" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "パスワードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '冷却時間(秒)',
                dataIndex: 'freeze_seconds',
                key: 'freeze_seconds',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"freeze_seconds" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "冷却時間を入力ください",
                                    type: "number"
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'ヘッダー',
                dataIndex: 'headers',
                key: 'headers',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"headers" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "ヘッダーを入力ください",
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'クッキー',
                dataIndex: 'cookies',
                key: 'cookies',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"cookies" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "クッキーを入力ください",
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '備考',
                dataIndex: 'remarks',
                key: 'remarks',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"remarks" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "希望件数を入力ください",
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
        ];
    }

    const onUpdateSite = (values) => {
        const params = {}
        if (values["name"] && site.name !== values["name"]) {
            params["siteName"] = values["name"]
        }
        if (values["url"] && site.url !== values["url"]) {
            params["siteURL"] = values["url"]
        }
        if (values["region"] && site.region !== values["region"]) {
            params["region"] = values["region"]
        }
        if (values["infrigementCategoryId"] && site.infrigementCategoryId !== values["infrigementCategoryId"]) {
            params["categoryId"] = values["infrigementCategoryId"]
        }
        if (values["hasCrawler"] && site.hasCrawler !== values["hasCrawler"]) {
            params["hasCrawler"] = values["hasCrawler"]
        }

        if (values["remarks"] && site.remarks !== values["remarks"]) {
            params["remarks"] = values["remarks"]
        }
        if (Object.keys(params).length > 0) {
            reqUpdateECSite({ ...params, siteId: site.id }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    reqGetECSite({
                        siteId: site.id
                    }).then((response) => {
                        const { data } = response
                        if (data.code === 0) {
                            updateState(data.data)
                        }
                    })
                }
                // props.history.push("/task/ec-crawler-task")
            }).catch((e) => {
                message.error("失敗しました。")
            })
        }
    }

    const updateState = (data) => {
        formRef.current.setFieldsValue({
            name: data.site.name,
            url: data.site.url,
            region: data.site.region,
            infrigementCategoryId: data.site.infrigementCategoryId,
            hasCrawler: data.site.hasCrawler ? 1 : 0,
            remarks:data.site.remarks?data.site.remarks:""
        })
        setSite(data.site)
        setAccounts(data.accounts)
    }

    const onUpdateSiteFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        // this.setState({ error: errors[0] })
    }

    const onRemoveAccountClick = (record) => {
        if (record) {
            reqRemoveSiteAccount({
                id: record.id
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    reqFetchSiteAccounts({
                        siteId:props.match.params.id
                    }).then((response)=>{
                        setAccounts(response.data.data.account)
                    })
                }
            }).catch(e => {
                // console.log(e)
            })
        }
    }

    const onUpdateAccountClick = (record, values) => {
        const detail = {}
        if (record.account !== values["account" + record.id]) {
            detail["account"] = values["account" + record.id]
        }
        if (record.password !== values["password" + record.id]) {
            detail["password"] = values["password" + record.id]
        }
        if (record.phone !== values["phone" + record.id]) {
            detail["phone"] = values["phone" + record.id]
        }
        if (record.freeze_seconds !== values["freeze_seconds" + record.id]) {
            detail["freeze_seconds"] = values["freeze_seconds" + record.id]
        }
        if (record.headers !== values["headers" + record.id]) {
            detail["headers"] = values["headers" + record.id]
        }
        if (record.cookies !== values["cookies" + record.id]) {
            detail["cookies"] = values["cookies" + record.id]
        }
        if (record.remarks !== values["remarks" + record.id]) {
            detail["remarks"] = values["remarks" + record.id]
        }
        if (Object.keys(detail).length > 0) {
            reqUpdateSiteAccount({
                id: record.id,
                ...detail
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    reqFetchSiteAccounts({
                        siteId:props.match.params.id
                    }).then((response)=>{
                        setAccounts(response.data.data.account)
                    })
                }
            })
        }
    }

    const onCreateAccountClick = (values) => {
        reqCreateSiteAccount({
            siteId:props.match.params.id,
            ...values
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success("成功しました。")
                // updateState(data.data.task)
                reqFetchSiteAccounts({
                    siteId:props.match.params.id
                }).then((response)=>{
                    setAccounts(response.data.data.account)
                })
            }
        })
    }

    const createdModalItems = () => {
        return (
            <>
                <ProFormText width="100%" name="account" label="アカウント" rules={[
                    {
                        required: true,
                        message: 'アカウントを入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="password" label="パスワード" rules={[
                    {
                        required: true,
                        message: 'パスワードを入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="phone" label="携帯電話" rules={[
                    {
                        required: false,
                        message: '携帯電話を入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="freeze_seconds" label="冷却時間(秒)" rules={[
                    {
                        required: false,
                        message: '冷却時間(秒)を入力ください。',
                    },
                ]}
                />
                 <ProFormTextArea width="100%" name="headers" label="ヘッダー" rules={[
                    {
                        required: false,
                        message: 'ヘッダーを入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="cookies" label="クッキー" rules={[
                    {
                        required: false,
                        message: 'クッキーを入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="remarks" label="備考" rules={[
                    {
                        required: false,
                        message: '備考を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    const getEditAccountFieldsValue = (record, index) => {
        const editInfo = {}
        editInfo["account" + record.id] = record["account"]
        editInfo["password" + record.id] = record["password"]
        editInfo["phone" + record.id] = record["phone"]
        editInfo["headers" + record.id] = record["headers"]
        editInfo["cookies" + record.id] = record["cookies"]
        editInfo["remarks" + record.id] = record["remarks"]
        return editInfo
    }

    return (
        <Form
            onFinish={onUpdateSite}
            onFinishFailed={onUpdateSiteFailed}
            ref={formRef}
        >
            <div className="ec-site-detail-container">
                <Row justify="start" className="row">
                    <Col span={4}>サイト名</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="name" rules={[
                            {
                                required: true,
                                message: "サイトを入力ください。",
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="start" className="row">
                    <Col span={4}>URL</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="url" rules={[
                            {
                                required: true,
                                message: "URLを入力ください。",
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>国・地区</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="region" rules={[
                            {
                                required: true,
                                message: "国・地区を入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {CountryConfig.map(country => {
                                    return (
                                        <Select.Option key={`${country.name.ja}${country.name.en}`} value={country.code}>
                                            {country.name.ja}
                                        </Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>採用カテゴル</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="infrigementCategoryId" default="" rules={[
                            {
                                required: true,
                                message: "採用カテゴルを入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {categories.map((category) => {
                                    return <Select.Option value={category.id}>{category.name}</Select.Option>
                                })}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>スクレイピング可能</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="hasCrawler" default="" rules={[
                            {
                                required: false,
                                message: "スクレイピング可能を入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                <Select.Option key="kano" value={1}>
                                    {"可能"}
                                </Select.Option>
                                <Select.Option key="hukano" value={0}>
                                    {"不可能"}
                                </Select.Option>

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>備考</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="remarks" default="" rules={[
                            {
                                max: 1000
                            },
                        ]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}>
                        <EFCMasterTable
                            modalTitle="URL追加"
                            rowkey="id"
                            columns={createAccountColumns()}
                            dataSource={accounts}
                            getEditFieldsValue={getEditAccountFieldsValue}
                            modalItems={createdModalItems()}
                            onRemoveClick={onRemoveAccountClick}
                            onSaveClick={onUpdateAccountClick}
                            onModalFinished={onCreateAccountClick}
                        >
                        </EFCMasterTable>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}><Button htmlType="submit">更新</Button></Col>
                </Row>
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(EFECSiteDetailViewBackup));
