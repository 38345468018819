import { Button, Input, Select, Table, DatePicker, Form, message } from "antd"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { useSelector } from 'react-redux'
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store"
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table"
import { ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form"
import { FormattedMessage, useIntl } from "react-intl"
import messages from "./messages.js"
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { reqCreateECCrawlerSubtask, reqGetECCrawlerTask, reqRemoveECCrawlerSubtask, reqUpdateECCrawlerTask, reqUpdateECCrawlerSubtask } from "../../../api/analyst-ec-task.js"
import { reqFetchClients } from '@/api/analyst-client'
import { reqFetchECSites } from "../../../api/analyst-site.js"
import { ECCrawlerPurpose, ECCrawlerStatus } from '../common/master-data.js'
import "./index.less"
import moment from 'moment'

// component reference dependency
import EFCContainer from '../../../components/ef-c-container/index.jsx'
import EFBox from '../../../components/ef-c-box/index.jsx'

const EFECScrapyingTaskDetailView = () => {

    const intl = useIntl()
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()

    const reduxState = useSelector((state) => state)
    // 可以通过store.getState()方法来获取整个状态对象,但是在React组件中直接使用store.getState()并不方便,而且每当状态发生变化时,组件也无法自动更新
    let reduxProps = {
        ...reduxState.user,
    }

    const tasks = useRef({})
    const subTasks = useRef([])
    const sites = useRef([])
    const clients = useRef([])

    const basicInfo = useRef([])
    const currentState = useRef(false)
    const updateTrigger = useRef()

    const updateState = (task) => {
        tasks.current = task
        subTasks.current = task.subtasks
    }
    const [isLoading, setIsLoading] = useState()
    const toggleLoading = useCallback(() => {
        setIsLoading(preState => !preState)
    }, [])

    const getEditSubtaskFieldsValue = (record, index) => {
        const editInfo = {}
        editInfo["site" + record.id] = record["site"]
        editInfo["keyword" + record.id] = record["keyword"]
        editInfo["startUrl" + record.id] = record["startUrl"]
        editInfo["targetCount" + record.id] = record["targetCount"]
        return editInfo
    }

    const validate = (params) => {
        if (!params['planStartDate']) {
            message.error(intl.formatMessage(messages['message.error.planstartdate_empty']))
            return false
        }
        if (!params['clientId']) {
            message.error(intl.formatMessage(messages['message.error.client_empty']))
            return false
        }
        if (!params['title']) {
            message.error(intl.formatMessage(messages['message.error.title_empty']))
            return false
        }
        if (!params['type']) {
            message.error(intl.formatMessage(messages['message.error.type_empty']))
            return false
        }
        if (params['remarks'] && params['remarks'].length > 1000) {
            message.error(intl.formatMessage(messages['message.error.remarks_limit']))
            return false
        }
        return true
    }

    const onHandleBasicChange = (values) => {
        const params = {}
        if (values["planStartDate"] && tasks.current.planStartDate !== values["planStartDate"]) {
            params["planStartDate"] = values["planStartDate"]
        }
        if (values["clientId"] && tasks.current.clientId !== values["clientId"]) {
            params["clientId"] = values["clientId"]
        }
        if (values["title"] && tasks.current.title !== values["title"]) {
            params["title"] = values["title"]
        }
        if (values["type"] && tasks.current.type !== values["type"]) {
            params["type"] = values["type"]
        }
        if (values["remarks"] && tasks.current.remarks !== values["remarks"]) {
            params["remarks"] = values["remarks"]
        }
        if (Object.keys(params).length > 0) {
            reqUpdateECCrawlerTask({ ...params, taskId: tasks.current.id }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(messages['message.info.update_success']))
                    updateState(data.data.task)
                    renderLatestTask()
                    toggleLoading()
                }
                // props.history.push("/task/ec-crawler-task")
            }).catch((e) => {
                message.error(intl.formatMessage(messages['message.info.update_fail']))
            })
        }
    }

    const onHandleRemove = (record) => {
        if (record) {
            reqRemoveECCrawlerSubtask({
                subtaskId: record.id
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(messages['message.info.remove_success']))
                    tasks.current = data.data.task
                    subTasks.current = data.data.task.subtasks
                    toggleLoading()
                }
            }).catch(e => {
                // console.log(e)
            })
        }
    }

    const onHandleUpdate = (record, values) => {
        if (tasks.current.status !== ECCrawlerStatus.new.value) {
            return
        }
        const detail = {}
        if (record.site !== values["site" + record.id]) {
            detail["site"] = values["site" + record.id]
        }
        if (record.startUrl !== values["startUrl" + record.id]) {
            detail["startUrl"] = values["startUrl" + record.id]
        }
        if (record.targetCount !== values["targetCount" + record.id]) {
            detail["targetCount"] = values["targetCount" + record.id]
        }
        if (record.keyword !== values["targetCount" + record.id]) {
            detail["keyword"] = values["keyword" + record.id]
        }
        if (Object.keys(detail).length > 0) {
            reqUpdateECCrawlerSubtask({
                taskId: tasks.current.id,
                subtaskId: record.id,
                ...detail
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success(intl.formatMessage(messages['message.info.update_success']))
                    updateState(data.data.task)
                    toggleLoading()
                }
            })
        }
    }

    const onHandleCreate = (values) => {
        if (tasks.current.status !== ECCrawlerStatus.new.value) {
            return
        }
        reqCreateECCrawlerSubtask({
            detail: {
                ...values
            },
            taskId: tasks.current.id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success(intl.formatMessage(messages['message.info.create_success']))
                updateState(data.data.task)
                toggleLoading()
            }
        }).catch(e => {
            // console.log(e)
        })
    }

    const onHandleUpdateForm = () => {
        updateTrigger.current = !updateTrigger.current
        toggleLoading()
    }

    const onHandleState = (order) => {
        currentState.current = order
        toggleLoading()
    }

    const renderLatestTask = () => {
        basicInfo.current = [
            {
                name: 'planStartDate',
                value: moment(tasks.current.planStartDate),
                label: 'app.ui.ec_task.table.planStartDate',
                type: 'date',
                permission: true,
            }, {
                name: 'clientId',
                value: tasks.current.clientId,
                label: 'app.ui.ec_task.table.clientId',
                type: 'select',
                permission: true,
                params: clients.current.map((item, index) => {
                    return { label: item.client_name, value: item.client_id }
                })
            }, {
                name: 'title',
                value: tasks.current.title,
                label: 'app.ui.ec_task.table.title',
                type: 'input',
                permission: true,
            }, {
                name: 'type',
                value: tasks.current.type,
                label: 'app.ui.ec_task.table.type',
                type: 'select',
                permission: true,
                params: [
                    {
                        label: ECCrawlerPurpose.normal.text,
                        value: ECCrawlerPurpose.normal.value
                    }, {
                        label: ECCrawlerPurpose.dashboard.text,
                        value: ECCrawlerPurpose.dashboard.value
                    }, {
                        label: ECCrawlerPurpose.monitor.text,
                        value: ECCrawlerPurpose.monitor.value
                    }
                ]
            }, {
                name: 'remarks',
                value: tasks.current.remarks,
                label: 'app.ui.ec_task.table.remarks',
                type: 'textarea',
                permission: true,
            }
        ]
    }

    const renderTrigger = () => {
        if (currentState.current === true) {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(false) }}><FormattedMessage id='app.common.action.cancel' /></Button>
                <Button className='ef-button-color-small' style={{ marginLeft: '8px' }} onClick={() => { onHandleUpdateForm() }}><FormattedMessage id='app.common.action.update' /></Button>
            </div>
        } else {
            return <div className='client-detail-container-footer'>
                <Button className='ef-button-linear-small' onClick={() => { onHandleState(true) }}><FormattedMessage id='app.common.action.edit' /></Button>
            </div>
        }
    }

    const renderColumns = () => {
        return [
            {
                title: <FormattedMessage id="app.ui.ec_task.table.site" />,
                dataIndex: 'site',
                key: 'site',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"site" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.site_empty']),
                                }
                            ]}>
                            <Select className="ef-form-element-normal" style={{ width: 200 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false
                                }}
                            >
                                {sites.current.map((site) => {
                                    return <Select.Option value={site.name} key={site.url} >{site.name}</Select.Option>
                                })}
                            </Select>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.keyword" />,
                dataIndex: 'keyword',
                key: 'keyword',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"keyword" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.keyword_empty']),
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.startUrl" />,
                dataIndex: 'startUrl',
                key: 'startUrl',
                width: 500,
                renderText: (record) => {
                    return <div className="task-start-url">{record.startUrl}</div>
                },

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"startUrl" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages['message.error.start_url_empty']),
                                }
                            ]}>
                            <Input.TextArea className="ef-form-element-normal"></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: <FormattedMessage id="app.ui.ec_task.table.targetCount" />,
                dataIndex: 'targetCount',
                key: 'targetCount',
                width: 100,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"targetCount" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: intl.formatMessage(messages['message.error.target_count_empty']),
                                    type: "number"
                                }
                            ]}>
                            <Input className="ef-form-element-normal"></Input>
                        </ Form.Item >
                    )
                }
            },
        ]
    }

    const renderModalItems = () => {
        return (
            <>
                <ProFormSelect name="site" label={<FormattedMessage id="app.ui.ec_task.table.site" />} showSearch rules={[

                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.site_empty']),
                    },
                ]}
                    options={
                        sites.current.map(site => {
                            return {
                                value: site.name,
                                label: site.name,
                                key: site.name
                            }
                        })
                    }
                />
                <ProFormText name="keyword" label={<FormattedMessage id="app.ui.ec_task.table.keyword" />} rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages['message.error.keyword_empty']),
                    },
                ]}
                />
                <ProFormTextArea name="startUrl" label={<FormattedMessage id="app.ui.ec_task.table.startUrl" />} rules={[
                    {
                        required: true,
                        type: "url",
                        message: intl.formatMessage(messages['message.error.start_url_empty']),
                    },
                ]}
                />
                <ProFormText name="targetCount" label={<FormattedMessage id="app.ui.ec_task.table.targetCount" />} rules={[
                    {
                        required: false,
                        message: intl.formatMessage(messages['message.error.target_count_empty']),
                    },
                ]}
                />
            </>
        )
    }

    const renderBread = () => {
        store.dispatch(setMenuActiveKey("task-ec"))
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.ec_crawler_task" />,
                path: "/task/ec-crawler-task",
            },
            {
                name: <FormattedMessage id="app.breadcrumbs.detail" />,
                // path: "/ec-list",
            },
        ]))
    }

    const renderTask = async () => {
        const taskId = match.params.id
        await reqGetECCrawlerTask({
            taskId: taskId
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                updateState(data.data.task)
            }
        })

        await reqFetchECSites().then((response) => {
            const { data } = response
            if (data.code === 0) {
                sites.current = data.data.sites.filter((site) => {
                    return site.hasCrawler
                })
            }
        }).catch(e => {

        })

        await reqFetchClients({ all: true }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                clients.current = data.data.client
            }
        }).catch(e => {

        })
        renderLatestTask()
        toggleLoading()
    }

    useEffect(() => {
        renderBread()
        renderTask()
    }, [])

    document.title = intl.formatMessage({ id: 'app.ui.ec_task_detail.title' })
    return <EFCContainer
        overspread={0}
        style={{ padding: '32px' }}>
        <EFBox
            titleBarColor={'#1880DE'}
            title={<FormattedMessage id={'app.ui.ec_task.detail.title'} />}
            mode={'single'}
            data={basicInfo.current}
            lineWidth={300}
            itemClass={'ef-form-element-block'}
            state={currentState.current}
            updateTrigger={updateTrigger.current}
            onHandleChange={(value) => { onHandleBasicChange(value) }}
        ></EFBox>
        {renderTrigger()}
        <EFCMasterTable
            modalTitle={intl.formatMessage({ id: 'app.ui.ec_task.table.modal_title' })}
            rowkey="id"
            columns={renderColumns()}
            dataSource={subTasks.current}
            getEditFieldsValue={getEditSubtaskFieldsValue}
            modalItems={renderModalItems()}
            onRemoveClick={onHandleRemove}
            onSaveClick={onHandleUpdate}
            onModalFinished={onHandleCreate}
            noAction={tasks.current && tasks.current.status !== ECCrawlerStatus.new.value}
        >
        </EFCMasterTable>
    </EFCContainer>
}
export default EFECScrapyingTaskDetailView