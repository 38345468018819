import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.site_empty": {
        id: "app.ui.ec_task.message.error.site_empty",
        defaultMessage: "サイト名を入力ください。",
    },
    "message.error.keyword_empty": {
        id: "app.ui.ec_task.message.error.keyword_empty",
        defaultMessage: "キーワードを入力ください。",
    },
    "message.error.start_url_empty": {
        id: "app.ui.ec_task.message.error.start_url_empty",
        defaultMessage: "開始URLを入力ください。",
    },
    "message.error.target_count_empty": {
        id: "app.ui.ec_task.message.error.target_count_empty",
        defaultMessage: "希望件数を入力ください。",
    },

    // "message.error.planstartdate_empty": {
    //     id: "app.ui.ec_task.message.error.planstartdate_empty",
    //     defaultMessage: "開始日を入力ください。",
    // },
    // "message.error.client_empty": {
    //     id: "app.ui.ec_task.message.error.client_empty",
    //     defaultMessage: "お客様を入力してください。",
    // },
    // "message.error.title_empty": {
    //     id: "app.ui.ec_task.message.error.title_empty",
    //     defaultMessage: "タイトルを入力してください。",
    // },
    // "message.error.type_empty": {
    //     id: "app.ui.ec_task.message.error.type_empty",
    //     defaultMessage: "タイプを入力してください。",
    // },
    // "message.error.remarks_limit": {
    //     id: "app.ui.ec_task.message.error.remarks_limit",
    //     defaultMessage: "メモ1000文字以内。",
    // },
    
    'message.info.create_success': {
        id: "app.ui.ec_task.message.info.create_success",        
        defaultMessage: '成功しました。'
    },
    'message.info.update_success': {
        id: "app.ui.ec_task.message.info.update_success",        
        defaultMessage: '成功しました。'
    },
    'message.info.update_fail': {
        id: "app.ui.ec_task.message.info.update_fail",        
        defaultMessage: '失敗しました。'
    },
    'message.info.remove_success': {
        id: "app.ui.ec_task.message.info.remove_success",        
        defaultMessage: '成功しました。'
    }
})  

export default messages