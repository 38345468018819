import "./index.less"
import React, { Component } from "react"
import { Button, Upload, Table, Checkbox, Space, message, Popconfirm } from "antd"
import { UploadOutlined } from '@ant-design/icons'
import { reqUploadWSReport, reqFetchReports, reqRemoveWSReport, reqPostReport, reqBatchUpdateWSReport } from '../../../api/ws'
import ConvertWorker from "../../../worker/covertECExceltoJson.worker.js"
import { reqSearchTrademarks } from '../../../api/trademark'
import moment from 'moment'
import { withRouter } from "react-router-dom"
import { injectIntl, FormattedMessage } from "react-intl"
import messages from './messages.js'
import { connect } from "react-redux"
import store from "@/store"

const FormData = require('form-data')

class AnalystClientWSReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            createUploadFileList: [],
            updateUploadFileList: [],
            createState: true,
            createErrorMessage: '',
        }
        this.sendUncategorizRecords = false
    }

    componentDidMount = () => {
        if (this.props.clientId) {
            reqFetchReports({ clientId: this.props.clientId }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    this.setState({ reports: data.data.reports })
                }
            }).catch((e) => {

            })
        }
        this.covertWorker = new ConvertWorker()
        reqSearchTrademarks({ clientId: this.props.clientId }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ trademarksInfo: data.data.trademarks })
            }
        }).catch((error) => {

        })
    }

    componentWillUnmount = () => {
        if (this.covertWorker) {
            this.covertWorker.terminate()
        }
    }

    onDetail = (record) => {
        // let effectFilters = effectFilterKeys.map((key) => {
        //     let result = {}
        //     result.key = key;
        //     result.values = filters[key];
        //     return result;
        // })
        // if (effectFilters && effectFilters.length > 0) {
        //     
        //     searchParams.set("filters", encodeURIComponent(JSON.stringify(effectFilters)))
        // } else {
        //     searchParams.set("filters", null)
        // }

        let result = {}
        result.key = "report_id"
        result.values = [record.report_id]
        this.props.history.push(`/ec-list?clientId=${this.props.clientId}&filters=${encodeURIComponent(JSON.stringify([result]))}`)
    }

    onDeleteReport = (record) => {
        reqRemoveWSReport({ clientId: this.props.clientId, reportId: record.id }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ reports: data.data.reports })
            }
            message.success(record.reportName + this.props.intl.formatMessage(messages['messages.info.delete_success']))
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages['messages.error.delete_fail']))
        })
    }

    onDownloadReport = (record) => {
        window.open(`${process.env.REACT_APP_BASE_API}/api/ws-report/download-report?clientId=${this.props.clientId}&reportId=${record.id}`)
    }

    onPostReport = (record) => {
        // sendUncategorizRecords
        reqPostReport({ clientId: this.props.clientId, reportId: record.id, sendUncategorizRecords: this.sendUncategorizRecords ? true : false }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                this.setState({ reports: data.data.reports })
                message.success(record.reportName + this.props.intl.formatMessage(messages['operation.info.ws_report.open_success']))
            }
        }).catch((e) => {
            message.error(this.props.intl.formatMessage(messages['operation.error.ws_report.open_fail']))
        })
    }

    createColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_id' }),
                dataIndex: 'id',
                key: 'id',
                fixed: "left",
                width: 80,
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_name' }),
                dataIndex: 'reportName',
                key: 'reportName',
            },

            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_upload_date' }),
                dataIndex: 'uploadDate',
                key: 'uploadDate',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_count' }),
                dataIndex: 'recordCount',
                key: 'recordCount',
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_send_date' }),
                dataIndex: 'sendDate',
                key: 'sendDate',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_uncategrize_count' }),
                dataIndex: "categrizeCount",
                key: "categrizeCount",
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_uncategrize_count1' }),
                dataIndex: "uncategrizeCount",
                key: "uncategrizeCount"
            },
            {
                title: this.props.intl.formatMessage({ id: 'app.ui.brand_reg_detail.report.list.report_response_count' }),
                dataIndex: 'responseCount',
                key: 'responseCount',
            },

            {
                title: this.props.intl.formatMessage({ id: 'app.common.action' }),
                key: 'action',
                fixed: "right",
                align: 'center',
                render: (text, record, index) => (
                    <Space size="middle" key="actions">
                        <Button key="detail" type="link" onClick={() => { this.onDetail(record) }}><FormattedMessage id="app.common.action.detail" /></Button>
                        <Popconfirm
                            key="delete"
                            title={this.props.intl.formatMessage(messages['operation.warn.delete_warn'])}
                            onConfirm={() => { this.onDeleteReport(record) }}
                            okText={this.props.intl.formatMessage(messages['operation.info.confirm'])}
                            cancelText={this.props.intl.formatMessage(messages['operation.info.cancel'])}
                        >
                            <Button key="delete" type="link" ><FormattedMessage id="app.common.action.delete" /></Button>
                        </Popconfirm>
                        <Button type="link" onClick={() => { this.onDownloadReport(record) }}><FormattedMessage id="app.common.action.download" /></Button>
                        {/* <Popconfirm
                            key="inherit"
                            title="引継しますか？"
                            onConfirm={() => { this.onInheritReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >

                            <Button key="inherit" type="link">引継</Button>
                        </Popconfirm> */}
                        {/* <Popconfirm
                            key="removeDuplicate"
                            title="重複のレコードを削除しますか？"
                            onConfirm={() => { this.onDeleteRepeatReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <Button key="removeDuplicate" type="link">重複削除</Button>
                        </Popconfirm> */}
                        <Popconfirm
                            key="send"
                            title={<Checkbox onClick={(value) => this.sendUncategorizRecords = value}>{this.props.intl.formatMessage({ id: 'operation.warn.show_warn' })}</Checkbox>}
                            onConfirm={() => { this.onPostReport(record) }}
                            onCancel={() => this.sendUncategorizRecords = false}
                            okText={this.props.intl.formatMessage({ id: 'operation.info.open_confirm' })}
                            cancelText={this.props.intl.formatMessage({ id: 'operation.info.open_cancel' })}
                        >
                            <Button key="send" type="link"><FormattedMessage id="app.common.action.open" /></Button>
                        </Popconfirm>

                    </Space>
                ),
            },
        ]
    }

    onBatchUpdateUpload = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

        const formData = new FormData()
        formData.append('clientId', this.props.clientId)
        formData.append('report', file)

        reqBatchUpdateWSReport(
            formData,
            (total, loaded) => {
                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    onSuccess(response, file)
                    this.setState({ reports: data.data.reports })
                } else {
                    onError(data.message)
                }
            }).catch((error) => {
                onError(error)
            })

        // this.covertWorker.postMessage({
        //     records: file,
        //     sitesInfo: this.state.sitesInfo,
        //     productsInfo: this.state.productsInfo,
        //     categorysInfo: this.state.categorysInfo,
        //     trademarksInfo: this.state.trademarksInfo,
        //     customItemsInfo: this.state.customItemsInfo,
        //     isUpdate: true
        // });
        // this.covertWorker.onmessage = (event) => {
        //     if (event.data.errorMsg) {
        //         onError(event.data.errorMsg)
        //         message.error(event.data.errorMsg);
        //         return
        //     }

        //     const formData = new FormData();
        //     formData.append('clientId', this.props.clientId);
        //     formData.append('report', event.data.records);

        //     reqBatchUpdateWSReport(
        //         formData,
        //         (total, loaded) => {
        //             onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        //         }).then((response) => {
        //             const { data } = response;
        //             if (data.code === 0) {
        //                 onSuccess(response, file);
        //                 this.setState({ reports: data.data.reports });
        //             } else {
        //                 onError(data.message)
        //             }
        //         }).catch((error) => {
        //             onError(error)
        //         })
        // }
    }

    onUploadECRecord = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

        const formData = new FormData()
        formData.append('clientId', this.props.clientId)
        formData.append('report', file)
        this.setState({ createState: true })
        reqUploadWSReport(
            formData,
            (total, loaded) => {
                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file)
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    onSuccess(response, file)
                    this.setState({ reports: data.data.reports })
                } else {
                    onError(data.message)
                    this.setState({ createState: false, createErrorMessage: data.message })
                }
            }).catch((error) => {
                onError(error)
                this.setState({ createState: false, createErrorMessage: error })
            })

        // this.covertWorker.postMessage({
        //     records: file,
        //     sitesInfo: this.state.sitesInfo,
        //     productsInfo: this.state.productsInfo,
        //     categorysInfo: this.state.categorysInfo,
        //     trademarksInfo: this.state.trademarksInfo,
        //     customItemsInfo: this.state.customItemsInfo
        // });
        // this.covertWorker.onmessage = (event) => {
        //     if (event.data.errorMsg) {
        //         onError(event.data.errorMsg)
        //         message.error(event.data.errorMsg);
        //         return
        //     }

        //     const formData = new FormData();
        //     formData.append('clientId', this.props.clientId);
        //     formData.append('report', event.data.records);

        //     reqUploadWSReport(
        //         formData,
        //         (total, loaded) => {
        //             onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        //         }).then((response) => {
        //             const { data } = response;
        //             if (data.code === 0) {
        //                 onSuccess(response, file);
        //                 this.setState({ reports: data.data.reports });
        //             } else {
        //                 onError(data.message)
        //             }
        //         }).catch((error) => {
        //             onError(error)
        //         })
        // }
    }

    handleCreateFileListChange = (info) => {
        let fileList = [...info.fileList]

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2)

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error
            return file
        })
        this.setState({ createUploadFileList: fileList })
    }

    handleUpdateFileListChange = (info) => {
        let fileList = [...info.fileList]

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2)

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error
            return file
        })
        this.setState({ updateUploadFileList: fileList })
    }

    onHandleRemoveNew = () => {
        this.setState({
            createState: true,
            createErrorMessage: ''
        })
    }

    renderNewErrorMessage = () => {
        if (!this.state.createState) {
            return <p className="upload-error">{this.state.createErrorMessage}</p>
        }
    }

    render () {
        let isUploadable = !!this.state.trademarksInfo

        return (
            <div className="master-report-container">
                <div>
                    <div className="report-upload-wrapper">
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onUploadECRecord}
                                disabled={!isUploadable}
                                onChange={this.handleCreateFileListChange}
                                fileList={this.state.createUploadFileList}
                                onRemove={this.onHandleRemoveNew}
                            >
                                <Button className="upload-wrapper-button ef-text-normal">{<FormattedMessage id="app.common.action.new_upload" />}</Button>
                            </Upload>
                            {this.renderNewErrorMessage()}
                        </div>
                        {/* <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onBatchUpdateUpload}
                                disabled={!isUploadable}
                                onChange={this.handleUpdateFileListChange}
                                fileList={this.state.updateUploadFileList}
                            >
                                <Button icon={<UploadOutlined />}>更新アップロード</Button>
                            </Upload>
                        </div> */}
                    </div>
                </div>
                <Table
                    className="ef-table"
                    key="table"
                    columns={this.createColumns()}
                    dataSource={this.state.reports}
                    scroll={{
                        x: "max-content"
                    }}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.user,
        ecmaster: state.ecMasterData
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(AnalystClientWSReport)))
