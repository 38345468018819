import request from '@/utils/request'

export function reqFetchECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task',
        method: 'post',
        data: data
    })
}

export function reqGetECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task/detail',
        method: 'post',
        data: data
    })
}

export function reqCreateECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task/create',
        method: 'post',
        data: data
    })
}

export function reqUpdateECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task/update',
        method: 'post',
        data: data
    })
}

export function reqRemoveECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task/remove',
        method: 'post',
        data: data
    })
}

export function reqCloneECCrawlerTask(data) {
    return request({
        url: '/api/ec-crawler-task/clone',
        method: 'post',
        data: data
    })
}

export function reqUpdateECCrawlerSubtask(data) {
    return request({
        url: '/api/ec-crawler-subtask/update',
        method: 'post',
        data: data
    })
}

export function reqCreateECCrawlerSubtask(data) {
    return request({
        url: '/api/ec-crawler-subtask/create',
        method: 'post',
        data: data
    })
}

export function reqRemoveECCrawlerSubtask(data) {
    return request({
        url: '/api/ec-crawler-subtask/remove',
        method: 'post',
        data: data
    })
}

export function reqStartTask(data){
    return request({
        url:'/api/ec-crawler-command/start',
        method:'post',
        data:data
    })
}

export function reqStopTask(data){
    return request({
        url:'/api/ec-crawler-command/stop',
        method:'post',
        data:data
    })
}

export function reqDownloadTask(data){
    return request({
        url:'/api/ec-crawler-command/download',
        method:'post',
        data:data
    })
}