import request from '@/utils/request'

export function reqSetImpactSettings(data){
    return request({
        url: '/api/analyst/set-impact-settings',
        method: 'post',
        data: data
    })
}

export function reqGetImpactSettings(data){
    return request({
        url: '/api/analyst/get-impact-settings',
        method: 'post',
        data: data
    })
}
