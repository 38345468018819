
const genOverallCategorizationStatus = (config) => {
    let { echarts, legend, title, xAxisData, leftYAxisName, leftYAxisData, rightYAxisName, rightYAxisData,
        seriesLineName, seriesLineData, seriesBar1Name, seriesBar1Data, seriesBar2Name, seriesBar2Data, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let myChart = echarts.init(document.getElementById(elementId));

    let option = {
        visualMap: [{
            show: false,
            type: 'continuous',
            seriesIndex: 0,
            min: 0,
            max: 0.8
        }],
        legend: {
            data: legend
        },
        title: {
            text: title
        },
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            axisLabel: { rotate: 30 },
            data: xAxisData
        },
        yAxis: [{
            type: 'value',
            name: rightYAxisName,
            position: "right",
            data: rightYAxisData,
            axisLabel: {
                formatter: function (value) {
                    return `${value * 100}%`;
                }
            }
        }, {
            type: 'value',
            name: leftYAxisName,
            position: "left",
            data: leftYAxisData,
            axisLabel: {
                formatter: "{value}"
            }
        }],
        series: [{
            name: seriesLineName,
            type: 'line',
            label: {
                show: true,
                position: 'top',
                formatter: function (obj) {
                    return `${parseInt(parseFloat(obj.value) * 1000) / 10.0}%`;
                }
            },
            showSymbol: true,
            smooth: true,
            data: seriesLineData
        }, {
            name: seriesBar1Name,
            type: "bar",
            barGap: 0,
            visualMap:false,
            yAxisIndex: 1,
            data: seriesBar1Data
        }, {
            name: seriesBar2Name,
            type: "bar",
            barGap: 0,
            yAxisIndex: 1,
            visualMap:false,
            data: seriesBar2Data
        }]
    };
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", function () {
        myChart.resize();
    });
}

export default genOverallCategorizationStatus;