import request from '@/utils/request'

const FormData = require('form-data');

export function reqCreateTrademark(data) {
    return request({
        url: '/api/trademark/create-trademark',
        method: 'post',
        data: data
    })
}

export function reqRemoveTrademark(data) {
    return request({
        url: '/api/trademark/remove-trademark',
        method: 'post',
        data: data
    })
}

export function reqUpdateTrademark(data) {
    return request({
        url: '/api/trademark/update-trademark',
        method: 'post',
        data: data
    })
}

export function reqGetTrademark(data) {
    return request({
        url: '/api/trademark/get-trademark',
        method: 'post',
        data: data
    })
}

export function reqSearchTrademarks(data) {
    return request({
        url: '/api/trademark/search-trademarks',
        method: 'post',
        data: data
    })
}


export function reqUploadTrademarkImage(data, onProgress) {
    return request({
        url: `/api/trademark/upload-image`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            onProgress(total, loaded);
        },
    })
}

export function reqRemoveTrademarkImage(data) {
    return request({
        url: '/api/trademark/remove-image',
        method: 'post',
        data: data
    })
}

export function reqTrademarkRegistration(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    })
    return request({
        url: `/api/trademark/registration`,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}