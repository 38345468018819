export const genCountryFilteringBar = (config) => {
    // seriesNameData = [{name:xxxx,data:[]}]
    let { echarts, legend, title, xAxisData, seriesData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }
    echarts.dispose(document.getElementById(elementId))
    let myChart = echarts.init(document.getElementById(elementId));

    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        title: {
            text: title
        },
        legend: {
            data: legend
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                axisLabel: { 
                    rotate: 30 ,
                    
                },
                data: xAxisData
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: seriesData.map((item) => {
            return {
                name: item.name,
                type: 'bar',
                stack: 'infringmentType',
                emphasis: {
                    focus: 'series'
                },
                data: item.data
            }
        }),
    };
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", function () {
        myChart.resize();
    });
}

export const genCountryFilteringPie = (config) => {
    // seriesData = [{name:xxxx,value:xxxx}]
    let { echarts, title, seriesData, elementId } = config
    if (!document.getElementById(elementId)) {
        return
    }

    let myChart = echarts.init(document.getElementById(elementId));
    let option = {
        title: {
            text: title,
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: '50%',
                data: seriesData,
                avoidLabelOverlap: true,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    myChart.setOption(option);
    myChart.resize();
    window.addEventListener("resize", function () {
        myChart.resize();
    });
}

