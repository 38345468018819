// ec detail intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message.error.name_empty": {
        id: "app.ui.user_list_detail.error.name_empty",
        defaultMessage: "名前を入力ください。",
    },
    "message.error.phone_failed": {
        id: "app.ui.user_list_detail.error.phone_failed",
        defaultMessage: "正しい電話番号を入力してください。",
    },
    "message.error.section_failed": {
        id: "app.ui.user_list_detail.error.section_failed",
        defaultMessage: "正しい部署を入力ください。",
    },
    "message.error.remarks_failed": {
        id: "app.ui.user_list_detail.error.remarks_failed",
        defaultMessage: "500字以内の備考を入力ください。",
    },
    "message.info.update_success": {
        id: "app.ui.user_list_detail.info.update_success",
        defaultMessage: "ユーザー情報を更新しました。",
    },
    "message.error.update_failed": {
        id: "app.ui.user_list_detail.error.update_failed",
        defaultMessage: "ユーザー情報の更新は失敗しました。",
    },
})

export default messages