import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqCreateUser } from '../../../api/admin-user'
import { isValidMail, isValidPassword, isValidTelNo } from "../../../utils/ef-re";
const { Option } = Select;

class ManagerNew extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            analyst: {}
        }
    }

    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("manage-analyst-list"));
        store.dispatch(setContentContainerHeaderAction([]))
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "管理者登録一覧",
                path: "/manage/analyst",
            },
            {
                name: "新規",
            }
        ]));
    }

    onFinish = (values) => {
        const { name, mail, tel, role, remarks, password, password2 } = values;
        let newRole = role.reduce((preRoleType, roletype) => {
            return parseInt(roletype) + parseInt(preRoleType);
        })
        if (!isValidPassword(password)) {
            message.error("パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。")
            return
        }
        if (password !== password2) {
            message.error("新パスワードと新パスワード（確認）は同じではなく、確認してください。")
            return
        }
        if (!isValidMail(mail)) {
            message.error("正しいメールを入力してください。")
            return
        }
        if (tel && !isValidTelNo(tel)) {
            message.error("正しい電話番号を入力してください。")
            return
        }

        let body = {
            name,
            tel,
            role: parseInt(newRole),
            remarks,
            mail,
            password2,
            password
        }

        reqCreateUser(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.props.history.push("/manage/analyst")
            }

        }).catch((error) => {
            message.error("新規登録失敗しました。")
        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }

    render() {
        document.title = "アナリスト新規"
        return (
            <div className="manager-new-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title="基本情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>名前（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: "名前を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>メール（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="mail" rules={[
                                            {
                                                type: "email",
                                                required: true,
                                                message: "メールを入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>パスワード（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="password" rules={[
                                            {
                                                required: true,
                                                message: "パスワードを入力ください。",
                                            },
                                        ]}>
                                            <Input.Password className="password" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>パスワード(確認)（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="password2" rules={[
                                            {
                                                required: true,
                                                message: "パスワード(確認)を入力ください。",
                                            },
                                        ]}>
                                            <Input.Password className="password" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>

                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>ロール（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="role" rules={[
                                            {
                                                required: true,
                                                message: "ロールを選んでください。",
                                            },
                                        ]}>
                                            <Select mode="multiple" showArrow={true} className="role-select" >
                                                <Option value="4">管理者</Option>
                                                <Option value="2">アナリスト</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>電話番号</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                message: "正しい電話番号を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>備考</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="remarks" rules={[
                                            {
                                                type: "string",
                                                max: 500,
                                                message: "500字以内の備考を入力ください。",
                                            },
                                        ]}>
                                            <Input.TextArea className="remarks-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="acition-container">
                                <Button htmlType="submit" type="primary" className="update-button">新規</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(ManagerNew)
