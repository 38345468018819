import "./index.less"
import React, { Component } from 'react'
import { Button, Checkbox, Form } from "antd";

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';

// setSelectedKeys, selectedKeys, confirm, clearFilters

class EFCFilterView extends Component {
    // life circle
    constructor(props) {
        super(props)
        this.checkedValues = []
        this.state = {
            isloading: true,
            datasource: [],
            checkedValues: {}
        }
        this.ref = React.createRef();
    }

    componentDidMount = () => {
        this.getDatasource()
    }

    getDatasource = (page) => {
        if (this.props.getDatasource) {
            this.props.getDatasource().then((result) => {
                let { filters } = result
                let newDatasource = this.state.datasource.concat(filters)
                let checkedValues = {}
                newDatasource.forEach((item) => {
                    return checkedValues[item.value] = item.checked;
                })
                this.setState({ datasource: newDatasource, isloading: false, checkedValues })

            }).catch((e) => {
                this.setState({ error: e, isloading: false })
            })
        }
    }

    // action
    onCheckValueChange = (e) => {
        let newCheckValues = { ...this.state.checkedValues }
        newCheckValues[e.target.name] = e.target.checked;
        this.renderItem = this.renderItem.bind(this)
        this.setState({ checkedValues: newCheckValues })
        this.props.setSelectedKeys(Object.keys(newCheckValues).filter(key => newCheckValues[key]));
    }

    onResetClick = () => {
        this.props.clearFilters()
        this.renderItem = this.renderItem.bind(this)
        this.setState({ checkedValues: {} })
        if (this.props.onResetClick) {
            this.props.onResetClick()
        }
    }

    onFilterClick = () => {
        this.props.confirm();
        if (this.props.onFilterClick) {
            this.props.onFilterClick(this.checkedValues)
        }
    }

    onCancelClick = () => {
        this.props.setSelectedKeys([])
        this.props.confirm()
    }

    renderItem = ({ index, key, style }) => {
        const item = this.state.datasource[index];
        return (
            <div key={key} style={style}>
                <div className="row-container">
                    <Checkbox onChange={this.onCheckValueChange} checked={this.state.checkedValues[item.value]} name={item.value}>{item.text}</Checkbox>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="filterContainer">
                <div className="listContainer">
                    <AutoSizer>
                        {({ width, height }) =>
                            <VList
                                ref={this.ref}
                                height={height}
                                overscanRowCount={2}
                                rowCount={this.state.datasource.length}
                                rowHeight={40}
                                rowRenderer={this.renderItem}
                                width={width}
                            />
                        }
                    </AutoSizer>
                </div>


                <div className="ant-table-filter-dropdown-btns">
                    <Button onClick={this.onResetClick} type="link" size="small">Reset</Button>
                    <Button onClick={this.onFilterClick} type="primary" size="small">Filter</Button>
                </div>
            </div>
        );
    }
}

export default EFCFilterView
