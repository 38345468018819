// auth
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
// user
export const USER_SET_USER_INFO = "USER_SET_USER_INFO";
// container-header
export const ContentContainerHeader_SetBreadcrumb = "ContentContainerHeader_SetBreadcrumb";
export const ContentContainerHeader_SetActionButton = "ContentContainerHeader_SetActionButton"

// Menu Active Key

export const Menu_ACTIVE_KEY = "Menu_ACTIVE_KEY"
export const Menu_Collapsed = "Menu_Collapsed"


// EC MASTER 
export const MASTER_DATA_PRODUCT_CATEGORY_CHANGED = "MASTER_DATA_PRODUCT_CATEGORY_CHANGED"
export const MASTER_DATA_CUSTOM_COLUMN_CHANGED = "MASTER_DATA_CUSTOM_COLUMN_CHANGED"

// SETTING
export const LOCALE_KEY = "LOCALE_KEY"
export const DEFALUT_DISPLAY_EC_COLUMNS = "DEFALUT_DISPLAY_EC_COLUMNS"