import { defineMessages } from 'react-intl'

const messages = defineMessages({
    // ec-master-report
    "operation.warn.delete_warn": {
        id: "app.ui.brand_reg_detail.warn.delete_warn",
        defaultMessage: "削除しますか？",
    },
    "operation.info.confirm": {
        id: "app.ui.brand_reg_detail.info.confirm",
        defaultMessage: "はい",
    },
    "operation.info.cancel": {
        id: "app.ui.brand_reg_detail.info.cancel",
        defaultMessage: "いいえ",
    },
    "operation.warn.inherit_warn": {
        id: "app.ui.brand_reg_detail.warn.inherit_warn",
        defaultMessage: "引継しますか？",
    },
    "operation.warn.delete_repeat_record_warn": {
        id: "app.ui.brand_reg_detail.warn.delete_repeat_record_warn",
        defaultMessage: "重複のレコードを削除しますか？",
    },
    "operation.warn.show_warn": {
        id: "app.ui.brand_reg_detail.warn.show_warn",
        defaultMessage: "カテゴライズなしのレコードをクライアントに表示します。",
    },
    "operation.info.open_confirm": {
        id: "app.ui.brand_reg_detail.info.open_confirm",
        defaultMessage: "公開する",
    },
    "operation.info.open_cancel": {
        id: "app.ui.brand_reg_detail.info.open_cancel",
        defaultMessage: "キャンセル",
    },

    "messages.info.delete_success": {
        id: "app.ui.brand_reg_detail.messages.info.delete_success",
        defaultMessage: "削除しました。"
    },
    "messages.error.delete_fail": {
        id: "app.ui.brand_reg_detail.messages.error.delete_fail",
        defaultMessage: "削除は失敗しました。"
    },
    "messages.info.renewal_of_contract_success": {
        id: "app.ui.brand_reg_detail.messages.info.renewal_of_contract_success",
        defaultMessage: "引続は完了しました。"
    },
    "messages.error.renewal_of_contract_fail": {
        id: "app.ui.brand_reg_detail.messages.error.renewal_of_contract_fail",
        defaultMessage: "引続は失敗しました。"
    },
    "messages.info.remove_duplicate_success": {
        id: "app.ui.brand_reg_detail.messages.info.remove_duplicate_success",
        defaultMessage: "重複削除は完了しました。"
    },
    "messages.info.open_success": {
        id: "app.ui.brand_reg_detail.messages.info.open_success",
        defaultMessage: "公開は完了しました。"
    },
    "messages.error.open_fail": {
        id: "app.ui.brand_reg_detail.messages.error.open_fail",
        defaultMessage: "公開は失敗しました。"
    },

    // ec-master-product-category
    "messages.error.product_category.category_empty": {
        id: "app.ui.brand_reg_detail.product_category.messages.error.category_empty",
        defaultMessage: '製品区分を入力ください。'
    },
    "messages.error.product_category.category_en_empty": {
        id: "app.ui.brand_reg_detail.product_category.messages.error.category_en_empty",
        defaultMessage: '製品区分(英文)を入力ください。'
    },
    "messages.error.product_category.code_empty": {
        id: "app.ui.brand_reg_detail.product_category.messages.error.code_empty",
        defaultMessage: 'コードを入力ください。'
    },

    // ec-master-custom-column
    "messages.error.custom_column.item_empty": {
        id: "app.ui.brand_reg_detail.custom_column.messages.error.item_empty",
        defaultMessage: '項目名を入力ください。'
    },
    "messages.error.custom_column.item_category_empty": {
        id: "app.ui.brand_reg_detail.custom_column.messages.error.item_category_empty",
        defaultMessage: '項目区分を入力ください。'
    },
    "messages.error.custom_column.item_position_empty": {
        id: "app.ui.brand_reg_detail.custom_column.messages.error.item_position_empty",
        defaultMessage: '場所（項目の後）を入力ください。'
    },
    "messages.error.custom_column.item_order_empty": {
        id: "app.ui.brand_reg_detail.custom_column.messages.error.item_order_empty",
        defaultMessage: '順番を入力ください。'
    },

    // special-url
    "messages.error.special_url.url_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.url_empty",
        defaultMessage: 'URLを入力ください。'
    },
    "messages.error.special_url.seller_name_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.seller_name_empty",
        defaultMessage: '出品者名を入力ください。'
    },
    "messages.error.special_url.seller_id_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.seller_id_empty",
        defaultMessage: '出品者IDを入力ください。'
    },
    "messages.error.special_url.company_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.company_empty",
        defaultMessage: '企業名を入力ください。'
    },
    "messages.error.special_url.remarks_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.remarks_empty",
        defaultMessage: '備考を入力ください。'
    },

    // special word
    "messages.error.special_url.word_empty": {
        id: "app.ui.brand_reg_detail.special_url.messages.error.word_empty",
        defaultMessage: '表記を入力ください。'
    },

    // ws-master-report
    "operation.info.ws_report.open_success": {
        id: "app.ui.brand_reg_detail.ws_report.info.open_success",
        defaultMessage: '公開は完了しました。'
    },
    "operation.error.ws_report.open_fail": {
        id: "app.ui.brand_reg_detail.ws_report.error.open_fail",
        defaultMessage: '公開は失敗しました。'
    },

    // master-attorney-letter
    "message.error.attorney_letter.region_empty": {
        id: "app.ui.brand_reg_detail.attorney_letter.message.error.region_empty",
        defaultMessage: '国・地区を入力ください。'
    },
    "message.error.attorney_letter.start_date_empty": {
        id: "app.ui.brand_reg_detail.attorney_letter.message.error.start_date_empty",
        defaultMessage: '開始日を入力ください。'
    },
    "message.error.attorney_letter.end_date_empty": {
        id: "app.ui.brand_reg_detail.attorney_letter.message.error.end_date_empty",
        defaultMessage: '終了日を入力ください。'
    },
    "message.info.attorney_letter.remindFlag_success": {
        id: "app.ui.brand_reg_detail.attorney_letter.message.info.remindFlag_success",
        defaultMessage: 'リマインドメールを入力ください。'
    },

    // master-trademark-country
    "message.info.trademark_country.update_success": {
        id: "app.ui.brand_reg_detail.trademark_country.message.info.update_success",
        defaultMessage: '更新しました。'
    },
    "message.error.trademark_country.update_fail": {
        id: "app.ui.brand_reg_detail.trademark_country.message.error.update_fail",
        defaultMessage: '更新は失敗しました。'
    },

    // ec-master-other
    "message.info.other.update_success": {
        id: "app.ui.brand_reg_detail.other.message.info.update_success",
        defaultMessage: '更新しました。'
    },
    "message.error.other.update_fail": {
        id: "app.ui.brand_reg_detail.other.message.error.update_fail",
        defaultMessage: '更新は失敗しました。'
    },

    // ec-master-brand
    "message.error.brand.brand_empty": {
        id: "app.ui.brand_reg_detail.brand.message.error.brand_empty",
        defaultMessage: 'ブランドを入力ください。'
    },
    "message.error.brand.keyword_empty": {
        id: "app.ui.brand_reg_detail.brand.message.error.keyword_empty",
        defaultMessage: 'ブランド表記を入力ください。'
    },
    "message.error.brand.model_empty": {
        id: "app.ui.brand_reg_detail.brand.message.error.model_empty",
        defaultMessage: 'モデルを入力ください。'
    },
    "message.error.brand.price_empty": {
        id: "app.ui.brand_reg_detail.brand.message.error.price_empty",
        defaultMessage: '定価を入力ください。'
    },

    // Marketplace Reason code
    "message.info.main_category.delete_success": {
        id: 'app.ui.main_category.message.info.delete_success',
        defaultMessage: 'カテゴライズ基準を削除に成功しました。'
    },
    "message.error.main_category.delete_fail": {
        id: 'app.ui.main_category.message.error.delete_fail',
        defaultMessage: 'カテゴライズ基準の削除が失敗しました。'
    },
    "message.info.main_category.update_success": {
        id: 'app.ui.main_category.message.info.update_success',
        defaultMessage: 'カテゴライズ基準が更新されました。'
    },
    "message.error.main_category.update_fail": {
        id: 'app.ui.main_category.message.error.update_fail',
        defaultMessage: 'カテゴライズ基準の更新は失敗しました。'
    },
    "message.info.main_category.create_success": {
        id: 'app.ui.main_category.message.info.create_success',
        defaultMessage: 'Maincategoryの作成に成功しました。'
    },
    "message.error.main_category.create_fail": {
        id: 'app.ui.main_category.message.error.create_fail',
        defaultMessage: 'Maincategoryの作成に失敗しました。'
    },
    "message.error.main_category.name_empty": {
        id: 'app.ui.main_category.message.error.name_empty',
        defaultMessage: 'カテゴリ基準を入力ください。'
    },
    "message.error.main_category.name_en_empty": {
        id: 'app.ui.main_category.message.error.name_en_empty',
        defaultMessage: 'カテゴリ基準(英文)を入力ください。'
    },


    "message.info.sub_category.update_success": {
        id: 'app.ui.sub_category.message.info.update_success',
        defaultMessage: 'カテゴリの更新に成功しました。'
    },
    "message.error.sub_category.update_fail": {
        id: 'app.ui.sub_category.message.error.update_fail',
        defaultMessage: 'カテゴリの更新に失敗しました。'
    },
    "message.info.sub_category.delete_success": {
        id: 'app.ui.sub_category.message.info.delete_success',
        defaultMessage: 'カテゴリの削除に成功しました。'
    },
    "message.error.sub_category.delete_fail": {
        id: 'app.ui.sub_category.message.error.delete_fail',
        defaultMessage: 'カテゴリの削除に失敗しました。'
    },
    "message.info.sub_category.create_success": {
        id: 'app.ui.sub_category.message.info.create_success',
        defaultMessage: 'カテゴリの作成に成功しました。'
    },
    "message.error.sub_category.create_fail": {
        id: 'app.ui.sub_category.message.error.create_fail',
        defaultMessage: 'カテゴリの作成に失敗しました。'
    },
    "message.error.sub_category.name_empty": {
        id: 'app.ui.sub_category.message.error.name_empty',
        defaultMessage: 'カテゴリを入力ください。'
    },
    "message.error.sub_category.name_en_empty": {
        id: 'app.ui.sub_category.message.error.name_en_empty',
        defaultMessage: 'カテゴリ(英文)を入力ください。'
    },
    "message.error.sub_category.code_empty": {
        id: 'app.ui.sub_category.message.error.code_empty',
        defaultMessage: 'コードを入力ください。'
    },
    "message.error.sub_category.commoncategory_empty": {
        id: 'app.ui.sub_category.message.error.commoncategory_empty',
        defaultMessage: '集計を入力ください。'
    }
})

export default messages
