export const ECCrawlerStatus = {
    // 任务状态,如0,スタンバイ,1:等待,2:进行中,3:成功完成,4:失败
    "new": {
        value: 0,
        text: "スタンバイ"
    },
    "pendding": {
        value: 1,
        text: "待機中"
    },
    "scraying": {
        value: 2,
        text: "スクレイピング中"
    },
    "finished": {
        value: 3,
        text: "完了"
    },
    // 无用
    "error": {
        value: 99,
        text: "エラー"
    },
}

export const ECCrawlerPurpose = {
    "normal": {
        value: 0,
        text: "情報抽出(ダウンロード型)"
    },
    "dashboard": {
        value: 1,
        text: "情報抽出(ダッシュボード表示型)"
    },
    "monitor": {
        value: 2,
        text: "情報抽出(プライスモニタリング形式)"
    }
}