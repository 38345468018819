import React, { Component } from 'react'
import { Table } from "antd";
import "./index.less"
import { Resizable } from 'react-resizable';
import { useState } from 'react';

const ResizeableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
    const [resizeFlag, setResizeFlag] = useState(false)
    if (!width) {
        return <th {...restProps} />
    }

    const backupEvent = restProps.onClick
    restProps.onClick = (e) => {
        if (resizeFlag === true) {
        } else {
            backupEvent(e)
        }
        setResizeFlag(false);
    }

    return (
        <Resizable width={width} height={0} onResize={
            (e,p)=>{
                setResizeFlag(true);
                onResize(e,p)
            }
            
            }>
            <th {...restProps} />
        </ Resizable >
    )
}
class EFResizableTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnsSizeDict: {}
        };
    }
    components = {
        header: {
            cell: ResizeableTitle,
        },
    };

    handleResize = (index, col) => (e, { size }) => {
        let newColumnsSizeDict = { ...this.state.columnsSizeDict }
        newColumnsSizeDict[col.key] = size.width
        this.setState(({ columnsSizeDict }) => {
            return { columnsSizeDict: newColumnsSizeDict };
        });
        e.stopPropagation()
    };


    render() {
        const resizeColumns = this.props.columns.map((col, index) => {
            let width = col.width
            if (this.state.columnsSizeDict[col.key]) {
                width = this.state.columnsSizeDict[col.key]
            }
            return {
                ...col,
                width: width,
                onHeaderCell: column => ({
                    width: width,
                    onResize: this.handleResize(index, col),
                    onMouseUp: (e) => {
                    },
                    onClick: (e) => {
                    }, // 点击表头行
                }),
            }
        })
        return (
            <div className="ef-resizable-table-container">
                <Table
                    {...this.props}
                    components={this.components}
                    columns={resizeColumns}
                />
            </div>
        );
    }
}


export default EFResizableTable
