import ec_crawler_task_en_lang from './ec-crawler-task/en.js'

const common_en_lang = {
    // infrigment common categories name key
    'app.config.infirgment_common_cateogry.name_locale_key': 'en',

    // locale name key
    'app.config.locale.name_locale_key': 'en',

    // product type
    'app.config.locale.product_locale_key': "name_en",

    // infringement
    'app.config.locale.infringement_locale_key': "name_en",

    // subinfringement
    'app.config.locale.subinfringement_locale_key': "name_en",

    // login
    'app.layout.login.language': 'English',

    // header
    'app.layout.header.language': 'English',
    'app.layout.header.logout': 'Log out',
    'app.layout.header.usename': 'Username',

    // sider menu
    'app.layout.menu.home': 'Home',

    // common label
    'app.common.label.blank': 'Blank',
    'app.common.label.no': 'No',
    'app.common.label.yes': 'Yes',

    'app.common.flag.yes': 'Yes',
    'app.common.flag.no': 'No',

    'app.common.option.yes': 'Enable',
    'app.common.option.no': 'Disable',

    // common column name
    'app.common.action': 'Actions',
    'app.common.action.detail': 'Detail',
    'app.common.action.delete': 'Delete',
    'app.common.action.add': 'Add',
    'app.common.action.update': 'Update',
    'app.common.action.back': 'Back',
    'app.common.action.inherit': 'Inherit',
    'app.common.action.start': 'Start',
    'app.common.action.stop': 'Stop',
    'app.common.action.submenu': 'Task status',
    'app.common.action.open': 'Realse',
    'app.common.action.new_upload': 'New Upload',
    'app.common.action.update_upload': 'Update Upload',
    'app.common.action.remove_duplicate': 'Duplicate deletion',
    'app.common.action.download': 'Download',
    'app.common.action.clone': 'Clone',
    'app.common.action.upload': "Upload",
    'app.common.action.save': "Save",
    'app.common.action.cancel': "Cancel",
    'app.common.action.edit': "Edit",
    'app.common.action.confirm': 'Confirm',
    'app.common.action.filter': 'Filter',
    'app.common.action.history': 'History',
    'app.common.action.search': 'Search',
    'app.common.action.check_all': 'Check all',

    // common action description
    'app.common.warning.delete': 'Do you want to delete it?',
    'app.common.confirm.delete': 'Yes',
    'app.common.cancel.delete': 'No',
    'app.common.warning.stop': 'Do you want to stop it?',
    'app.common.confirm.stop': 'Yes',
    'app.common.cancel.stop': 'No',

    // breadcrumbs
    'app.breadcrumbs.home': 'Home',
    'app.breadcrumbs.register': 'EC Site Register',
    'app.breadcrumbs.client_list': 'Brand Listing',
    'app.breadcrumbs.detail': 'Detail',
    'app.breadcrumbs.404': '404',
    'app.breadcrumbs.test_purchase': 'Test Purchase',
    'app.breadcrumbs.news': 'News',
    'app.breadcrumbs.notice': 'Notice',
    'app.breadcrumbs.new': 'New',
    'app.breadcrumbs.ec_crawler_task': 'EC Data Scrapying',
    'app.breadcrumbs.category_standard': 'Complaint Type',
    'app.breadcrumbs.manager_list': 'Manager List',

    // empty-content
    'app.emptycontent.title': 'No data',
    'app.emptycontent.text': ' There is no data',
    'app.serverError.title': 'Server error',
    'app.serverError.text': 'Please try again later',
    'app.networdError.title': 'Net connection Error.',
    'app.networdError.text': 'Please check your net connection.',
    'app.permissionLimit.title': 'Authorization Error',
    'app.permissionLimit.text': 'Please contract to the administrator.',

    // pagination
    'app.pagination.per_page': '/ page',
    'app.pagination.jump_to': 'Go to',
    'app.pagination.page': 'Page',
    'app.pagination.show_total': 'Total {count} page',

    // collapse
    'app.collapse.title': 'Search conditions',

    // user
    'app.user.manager': '管理者',
    'app.user.analyst': 'アナリスト',
    'app.user.client': 'クライアント',

    // 404
    'app.ui.404.description1': 'This page is for IPF regular membership.',
    'app.ui.404.description2': 'For more information, please contact your sales.',
    'app.ui.404.contact': 'Contact:',

    // efc tableheader
    'app.components.ec_table_header.total_count': 'Total:{count}',
    'app.components.ec_table_header.error_count': 'Error:{count}',
    'app.components.ec_table_header.update': 'Update',

    // efc searchbar
    'app.components.ef_code_button.title': 'Get the verification code.',
    'app.components.ef_code_button.count_down': 'Get the new code after {countDown}s.',

    // efc searchbar
    'app.components.ef_search_bar.brand': 'Brand',
    'app.components.ef_search_bar.date_item': 'Date',
    'app.components.ef_search_bar.date_from': 'From',
    'app.components.ef_search_bar.date_to': 'To',
    'app.components.ef_search_bar.search_item': 'Search by',
    'app.components.ef_search_bar.search_hint': 'keyword',
    'app.components.ef_search_bar.search_button': 'Find',
    'app.components.ef_search_bar.reset_button': 'Reset',
    'app.components.ef_search_bar.search_tpye_fuzzy_match': 'Fuzzy match',
    'app.components.ef_search_bar.search_tpye_exactly_match': 'Exact match',
    'app.components.ef_search_bar.search_tpye_previous_match': 'Previous match',
    'app.components.ef_search_bar.search_tpye_post_match': 'Post match',
    'app.components_ef-search_bar.error.brand_empty': 'Brand is a required paramter',

    // data filter and modal
    'app.components.data_filter.check_all': 'Check All',
    'app.components.data_filter.cancel': 'Cancel',
    'app.components.data_filter.submit': 'Submit',
    'app.components.data_filter.confirm': 'Confirm',
    'app.components.modal.submit': 'Submit',
    'app.components.modal.reset': 'Cancel',

    'app.layout.menu.online_protection': 'Online Protection',
    'app.layout.menu.online_protection.ec_record_list': 'Marketplace Database',
    'app.layout.menu.online_protection.sns_record_list': 'Social Media Database',
    'app.layout.menu.online_protection.ws_record_list': 'Website Database',
    'app.layout.menu.online_protection.report_list': 'Report',

    'app.layout.menu.offline_protection': 'Offilne Protection',

    'app.layout.menu.legal_services': 'Legal Services',
    'app.layout.menu.legal_services.tm_search': 'T/M Search',
    'app.layout.menu.legal_services.tm_register': 'T/M Register',
    'app.layout.menu.legal_services.tm_application_status': 'T/M Application status',
    'app.layout.menu.legal_services.tm_update_status': 'T/M Update status',
    'app.layout.menu.legal_services.tm_rejection_reason': 'T/M Reasons for rejection',
    'app.layout.menu.legal_services.tm_transfer': 'T/M Transfer',
    'app.layout.menu.legal_services.tm_monitoring': 'T/M Monitoring',
    'app.layout.menu.legal_services.tm_correction_invaildation_trail': 'T/M Trial for invalidation of correction',
    'app.layout.menu.legal_services.tm_case_management': 'T/M Case Management',
    'app.layout.menu.legal_services.tm_registration': 'T/M Registration',

    'app.layout.menu.biz_supprot': 'Biz Support',
    'app.layout.menu.news': 'News',
    'app.layout.menu.notice': 'Notice',

    'app.layout.menu.other_support': 'Other',
    'app.layout.menu.test_purfchase': 'Test Purchase',

    'app.layout.menu.task': 'Task',
    'app.layout.menu.task.ec_crawler_task': 'EC Task',

    'app.layout.menu.setting': 'Setting',
    'app.layout.menu.setting.profile': 'Profile',
    'app.layout.menu.setting.user_list': 'User list',
    'app.layout.menu.setting.brand_list': 'Brand Reg.',
    'app.layout.menu.setting.manager_list': 'Manager Reg.',
    'app.layout.menu.setting.ec_list': 'Marketplace Reg.',
    'app.layout.menu.setting.infringement_category': 'Marketplace Reason code',

    'app.layout.menu.fold': "Folding",

    // ec-dashboard
    'app.ui.dashboard.title': 'Dashboard',
    'app.ui.dashboard.breadcrumb.dashboard': 'Dashboard',
    'app.ui.dashboard.search.brand': 'Brand',
    'app.ui.dashboard.search.date_column': 'Date column',
    'app.ui.dashboard.search.date_from': 'Period',
    'app.ui.dashboard.search.date_to': '~',
    'app.ui.dashboard.search.find': 'Find',
    'app.ui.dashboard.search.reset': 'Reset',

    'app.ui.dashboard.status.title': 'Status',
    'app.ui.dashboard.status.impact_revenue': 'Infringement Impact Revenue',
    'app.ui.dashboard.status.impact_revenue.usd': 'USD',
    'app.ui.dashboard.status.impact_revenue.rate': 'Rate',
    'app.ui.dashboard.status.impact_revenue.jpy': 'JPY',
    'app.ui.dashboard.status.annual_date': 'Annual Status - Date',
    'app.ui.dashboard.status.analyst': 'Member',

    'app.ui.dashboard.status.impact_trademark': 'Trademark Registration',
    'app.ui.dashboard.status.impact_trademark.categorizedcount': 'Categorized Count',
    'app.ui.dashboard.status.impact_trademark.infringementcount': 'Infringement Count',
    'app.ui.dashboard.status.impact_trademark.infringementcount_percent': 'Infringement%',

    'app.ui.dashboard.chart.overall_categorization_status.title': 'Overall Categorization Status',
    'app.ui.dashboard.chart.overall_categorization_status.non_infringement': 'Non Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.copyright_infringement': 'Copyright Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_ad': 'Trademark Infringement(ad)',
    'app.ui.dashboard.chart.overall_categorization_status.trademark_infringement_counterfeit': 'Trademark Infringement(counterfeit)',
    'app.ui.dashboard.chart.overall_categorization_status.need_sold_ended': 'Need Sold(Ended)',
    'app.ui.dashboard.chart.overall_categorization_status.need_confrmation': 'Need confrmation',
    'app.ui.dashboard.chart.overall_categorization_status.other_law_infringement': 'Other Law Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment_percent': 'Infringement%',
    'app.ui.dashboard.chart.overall_categorization_status.left_y_axis_name': 'Count',
    'app.ui.dashboard.chart.overall_categorization_status.right_y_axis_name': '%',

    'app.ui.dashboard.chart.online_infringing_seller.title': 'Online Infringement Seller',
    'app.ui.dashboard.chart.overall_infringement.title': 'Overall Infringement',
    'app.ui.dashboard.chart.overall_infringement.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_infringement.legend.infringment_percent': 'Infringement%',

    'app.ui.dashboard.chart.overall_infringement_type.title': 'Overall Infringement Type',
    'app.ui.dashboard.map.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.chart.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.table.categorization_status.title': 'Categorization Status',
    'app.ui.dashboard.chart.locale_filtering.title': 'Region Filtering',
    'app.ui.dashboard.filter.locale_filtering.title': 'Region',
    'app.ui.dashboard.filter.website_filtering.title': 'Website',

    'app.ui.dashboard.chart.ec_infringement_categorization.title': 'EC Infringement Categorization',
    'app.ui.dashboard.list.top_infringers.title': 'Top Infringers',
    'app.ui.dashboard.chart.takedown.title': 'Takedown',

    'app.ui.dashboard.chart.ws_takedown.title': 'Web Site Takedown',
    'app.ui.dashboard.chart.ws_category.title': 'Web Site Categorization',

    // ec-list
    'app.ui.eclist.title': 'Marketplace Database',
    'app.ui.eclist.to_trademark_list': 'Your Trademark List',
    'app.ui.eclist.table.action_history': 'Show edit history',

    'app.ui.eclist.column_modal.title': "Column display setting",

    'app.ui.eclist.table.id': "ID",
    'app.ui.eclist.table.ec_no': "No.",
    'app.ui.eclist.table.ec_site': "Marketplaces",
    'app.ui.eclist.table.ec_scan_month': "Scan Month",
    'app.ui.eclist.table.ec_scan_date': "Scan Date",
    'app.ui.eclist.table.ec_search_keyword': "Query",
    'app.ui.eclist.table.ec_product_no': "Listing #",
    'app.ui.eclist.table.ec_product_title': "Product Title",
    'app.ui.eclist.table.ec_product_url': "Listing URL",
    'app.ui.eclist.table.ec_product_brand': "Brand Field",
    'app.ui.eclist.table.ec_product_seller': "Seller Name",
    'app.ui.eclist.table.ec_product_seller_url': "Seller URL",
    'app.ui.eclist.table.ec_product_price': "Price",
    'app.ui.eclist.table.ec_product_currency': "Currency",
    'app.ui.eclist.table.ec_product_image_url': "Image URL",
    'app.ui.eclist.table.ec_product_onsell_count': "Inventory",
    'app.ui.eclist.table.ec_product_sold_count': "Sold Number",
    'app.ui.eclist.table.ec_product_region': "Ship From(Region)",
    'app.ui.eclist.table.ec_product_city': "Ship From(Area)",
    'app.ui.eclist.table.ec_categorize_date': "Report Date",
    'app.ui.eclist.table.ec_auto_check_flg': "Flag",
    'app.ui.eclist.table.ec_blacklist_object': "Black List",
    'app.ui.eclist.table.ec_whitelist_object': "White List",
    'app.ui.eclist.table.ec_is_need_to_check': "Fast Check",
    'app.ui.eclist.table.ec_title_hint_word': "Brandname in Listing Title",
    'app.ui.eclist.table.ec_brand_hint_word': "Brandname in Brand Field",
    'app.ui.eclist.table.ec_product_category_id_code': "Product Type #",
    'app.ui.eclist.table.ec_product_category_id_word': "Product Type",
    'app.ui.eclist.table.ec_category_rule_id': "Complaint Type",
    'app.ui.eclist.table.ec_category_id_code': "Input Field",
    'app.ui.eclist.table.ec_category_id_word': "Infringement Category",
    'app.ui.eclist.table.ec_category_id_common': "Infringement Type",
    'app.ui.eclist.table.ec_categorize_remarks': "IPF Comment",
    'app.ui.eclist.table.ec_response_action': "Enforcement Flag",
    'app.ui.eclist.table.ec_response_reason': "Client's Comment",
    'app.ui.eclist.table.ec_response_trademark': "Trademark/Copyright registration number",
    'app.ui.eclist.table.ec_response_date': "Response date",
    'app.ui.eclist.table.ec_process1_date': "Report Date(1st)",
    'app.ui.eclist.table.ec_process1_result': "Complaint Output(1st)",
    'app.ui.eclist.table.ec_process1_confirm_date': "Confirmation Date(1st)",
    'app.ui.eclist.table.ec_process2_date': "Report Date(2nd)",
    'app.ui.eclist.table.ec_process2_result': "Complaint Output(2nd)",
    'app.ui.eclist.table.ec_process2_confirm_date': "Confirmation Date(2nd)",
    'app.ui.eclist.table.ec_process3_date': "Report Date(3rd)",
    'app.ui.eclist.table.ec_process3_result': "Complaint Output(3rd)",
    'app.ui.eclist.table.ec_process3_confirm_date': "Confirmation Date(3rd)",
    'app.ui.eclist.table.declaration_status': "Declaration Status",
    'app.ui.eclist.table.ec_process_result': "Final Result",
    'app.ui.eclist.table.ec_process_confirm_date': "Confirmation Date(Final)",
    'app.ui.eclist.table.ec_process_remarks': "Remarks",
    'app.ui.eclist.table.report_id': "Report",
    'app.ui.eclist.table.batch_choose': 'Choose',
    'app.us.eclist.table.batch_items': 'items',
    'app.ui.eclist.table.modify': 'Batch Modify',
    'app.ui.eclist.table.column_display': 'Column display setting',
    'app.ui.eclist.table.result_filter': 'Result filter',
    'app.ui.eclist.table.history_records': 'History records',
    'app.ui.eclist.table.orginal_url': 'ORGINAL URL ',

    'app.ui.eclist.tooltip.increase_fontsize': 'Big Font Size',
    'app.ui.eclist.tooltip.decrease_fontsize': 'Small Font Size',
    'app.ui.eclist.tooltip.filter': 'Filter',
    'app.ui.eclist.tooltip.mode_grid': 'Grid Model',
    'app.ui.eclist.tooltip.mode_table': 'List Mode',
    'app.ui.eclist.tooltip.export': 'Export',
    'app.ui.eclist.tooltip.columns': 'Columns Setting',
    'app.ui.eclist.tooltip.ec_categorize_remarks': 'Remarks',
    'app.ui.eclist.tooltip.ec_response_remarks': 'Remarks',

    'app.ui.eclist.message.warning.brand_empty': 'Brand is a required paramter.',
    'app.ui.eclist.message.warning.query_empty': 'Please input query parameters.',
    'app.ui.eclist.message.info.loading': 'Loading',
    'app.ui.eclist.message.info.update_success': 'The record has been updated.',
    'app.ui.eclist.message.error.update_error': 'Failed to update the record.',

    // ec list history
    'app.ui.eclist_history.table.edited_by': "Edited by",
    'app.ui.eclist_history.table.edited_date': "Edited date",

    // profile
    'app.ui.profile.title': 'プロフィール設定',
    'app.ui.profile.section.basicinfo.title': 'Basic Information',
    'app.ui.profile.section.basicinfo.name': 'Name (Required)',
    'app.ui.profile.section.basicinfo.mail': 'Mail',
    'app.ui.profile.section.basicinfo.role': 'Role',
    'app.ui.profile.section.basicinfo.tel': 'Tel',
    'app.ui.profile.section.basicinfo.cancel': 'Cancel',
    'app.ui.profile.section.basicinfo.edit': 'Edit',
    'app.ui.profile.section.basicinfo.name.error': 'Please input user name.',
    'app.ui.profile.section.basicinfo.tel.error': 'Please check tel number.',
    'app.ui.profile.section.basicinfo.update': 'Update',

    'app.ui.profile.section.company.title': 'Client Information',
    'app.ui.profile.section.company.name': 'Name',
    'app.ui.profile.section.company.address': 'Address',
    'app.ui.profile.section.company.tel': 'Tel',
    'app.ui.profile.section.company.remarks': 'Remarks',

    'app.ui.profile.section.moment.title': 'Login Information',
    'app.ui.profile.section.moment.lastlogintime': 'Final Login Date',
    'app.ui.profile.section.moment.setuptime': 'Creation Date',

    'app.ui.profile.section.analyst.title': 'Analyst Information',
    'app.ui.profile.section.analyst.table.name': 'Name',
    'app.ui.profile.section.analyst.table.mail': 'Mail',
    'app.ui.profile.section.analyst.table.tel': 'Tel',

    'app.ui.profile.section.contract.title': 'Contract Information',
    'app.ui.profile.section.contract.table.name': 'Service name',
    'app.ui.profile.section.contract.table.duration_from': 'From',
    'app.ui.profile.section.contract.table.duration_to': 'To',
    'app.ui.profile.section.contract.table.fee': 'Fee',

    'app.ui.profile.action.reset_password': 'Reset password',
    'app.ui.profile.breadcrumb.password': 'Password',
    'app.ui.profile.breadcrumb.profile': 'Profile',
    'app.ui.profile.breadcrumb.home': 'Home',

    'app.ui.profile.message.success.update_success': 'The infomation has been updated.',
    'app.ui.profile.message.error.update_failed': 'Failed to update.',
    'app.ui.profile.message.error.password_character': 'The password contains three character categories: digits, lowercase characters and special characters and must be at least 8 characters long.',
    'app.ui.profile.message.error.confirm_password_same': 'The confirm password must be the same as the password.',
    'app.ui.profile.message.success.password_reset_success': 'Your password has been reset.',
    'app.ui.profile.message.error.password_reset_failed': 'Failed to reset password.',

    'app.ui.profile.password_reset.title': 'Reset your password',
    'app.ui.profile.password_reset.old_password': 'Current password',
    'app.ui.profile.password_reset.new_password': 'New password',
    'app.ui.profile.password_reset.new_password_confirm': 'Confirm new password',
    'app.ui.profile.password_reset.code': 'Verification code',
    'app.ui.profile.password_reset.reset_button': 'Reset',

    'app.ui.profile.password_reset.message.error.no_current_password': 'Please input the current password.',
    'app.ui.profile.password_reset.message.error.no_new_password': 'Please input the new password.',
    'app.ui.profile.password_reset.message.error.no_confirm_new_password': 'Please input the confirm password.',
    'app.ui.profile.password_reset.message.error.no_code': 'Please input the verification code.',

    'app.ui.profile.analyst.client_id': 'ID',
    'app.ui.profile.analyst.client_name': 'Company',
    'app.ui.profile.analyst.client_tel': 'Contact',
    'app.ui.profile.analyst.client_address': 'Address',
    'app.ui.profile.analyst.client_remarks': 'Remarks',
    'app.ui.profile.analyst.ec_startDate': 'EC Service Start Date',
    'app.ui.profile.analyst.ec_endDate': 'EC Service End Date',
    'app.ui.profile.analyst.ec_fee': 'EC Service Cost',
    'app.ui.profile.analyst.sns_startDate': 'SNS Service Start Date',
    'app.ui.profile.analyst.sns_endDate': 'SNS Service End Date',
    'app.ui.profile.analyst.sns_fee': 'SNS Service Cost',
    'app.ui.profile.analyst.ws_startDate': 'WS Service Start Date',
    'app.ui.profile.analyst.ws_endDate': 'WS Service End Date',
    'app.ui.profile.analyst.ws_fee': 'WS Service Cost',
    'app.ui.profile.analyst.other_startDate': 'Other Service Start Date',
    'app.ui.profile.analyst.other_endDate': 'Other Service End Date',
    'app.ui.profile.analyst.other_fee': 'Other Service Cost',
    'app.ui.profile.analyst.action': 'Actions',

    // user list
    'app.ui.user_list.title': 'User Listing',
    'app.ui.user_list.new_user': 'New',
    'app.ui.user_list.home': 'Home',
    'app.ui.user_list.userlist': 'Userlist',
    'app.ui.user_list.user_id': 'ID',
    'app.ui.user_list.user_name': 'Name',
    'app.ui.user_list.user_mail': 'Email',
    'app.ui.user_list.user_tel': 'Tel',
    'app.ui.user_list.user_department': 'Section',
    'app.ui.user_list.user_last_used_date': 'Last Login Date',
    'app.ui.user_list.user_create_at': 'Creation Date',
    'app.ui.user_list.user_remarks': 'Remarks',
    'app.ui.user_list.action': 'Action',

    // user list detail
    'app.ui.user_list_detail.title': 'Detail',
    'app.ui.user_list_detail.user_id': 'ID',
    'app.ui.user_list_detail.name': 'Name(required)',
    'app.ui.user_list_detail.user_mail': 'Email(required)',
    'app.ui.user_list_detail.user_password': 'Password(required)',
    'app.ui.user_list_detail.user_password_confirm': 'Confirm password(required)',
    'app.ui.user_list_detail.tel': 'Tel',
    'app.ui.user_list_detail.department': 'Section',
    'app.ui.user_list_detail.remarks': 'Remarks',
    'app.ui.user_list_detail.delete': 'Delete',

    'app.ui.user_list_detail.home': 'Home',
    'app.ui.user_list_detail.userlist': 'Userlist',
    'app.ui.user_list_detail.detail': 'Detail',

    'app.ui.user_list_detail.error.name_empty': 'Please input the user name.',
    'app.ui.user_list_detail.error.phone_failed': 'Plesase input the phone number.',
    'app.ui.user_list_detail.error.section_failed': 'Plesase input the correct department.',
    'app.ui.user_list_detail.error.remarks_failed': 'Please enter remarks within 500 characters.',
    'app.ui.user_list_detail.info.update_success': 'User information has been updated.',
    'app.ui.user_list_detail.error.update_failed': 'Failed to update user information.',

    // user list detail resetPassword
    'app.ui.user_list_reset_password.reset_password': 'Reset Password',
    'app.ui.user_list_reset_password.new_password': 'New Password',
    'app.ui.user_list_reset_password.new_password_confirm': 'New Password(Confirm)',
    'app.ui.user_list_reset_password.error.password_empty': 'Please input the new password.',
    'app.ui.user_list_reset_password.error.password2_empty': 'Please input the new password(Confirm).',

    // user list new
    'app.ui.user_list_new.title': 'New User',
    'app.ui.user_list_new.new_user': 'New',
    'app.ui.user_list_new.home': 'Home',
    'app.ui.user_list_new.userlist': 'User Listing',
    'app.ui.user_list_new.error.name_empty': 'Please input the user name.',
    'app.ui.user_list_new.error.email_failed': 'Please input the mail.',
    'app.ui.user_list_new.error.password_failed': 'Please set your password with at least one number, one uppercase letter, one lowercase letter, and one symbol, and ensure it is more than 8 characters in length.',
    'app.ui.user_list_new.error.password2_failed': 'The new password and the confirmation of the new password do not match. Please verify and confirm.',
    'app.ui.user_list_new.error.phone_failed': 'Please enter the correct phone number.',
    'app.ui.user_list_new.error.section_failed': 'Please enter the correct department.',
    'app.ui.user_list_new.error.remarks_failed': 'Please enter remarks within 500 characters.',
    'app.ui.user_list_new.error.new_failed': 'Failed to create user.',

    // ec-site
    'app.ui.ec_site.table.title': 'EC Site',
    'app.ui.ec_site.table.id': 'ID',
    'app.ui.ec_site.table.name': 'Site',
    'app.ui.ec_site.table.url': 'URL',
    'app.ui.ec_site.table.region': 'Region',
    'app.ui.ec_site.table.infrigementcategoryId': 'Complaint Type',
    'app.ui.ec_site.table.hascrawler': 'Scrapying Enable',
    'app.ui.ec_site.table.action': 'Action',
    'app.ui.ec_site.drawer.title': 'Result filter',

    'app.ui.ec_site.modal.title': 'Do you want to delete it?',
    'app.ui.ec_site.modal.ok': 'Yes',
    'app.ui.ec_site.modal.no': 'No',

    // ec-site-detail
    'app.ec_site_detail.title': 'EC Site Detial',
    'app.ui.ec_site_detail.box_title': 'Detail',
    'app.ui.ec_site_detail.form.name': 'Site',
    'app.ui.ec_site_detail.form.url': 'URL',
    'app.ui.ec_site_detail.form.region': 'Region',
    'app.ui.ec_site_detail.form.category': 'Complaint Type',
    'app.ui.ec_site_detail.form.scrape_possible': 'Scrapying Enable',
    'app.ui.ec_site_detail.form.remarks': 'Remarks',

    'app.ui.ec_site_detail.table.account': 'Account',
    'app.ui.ec_site_detail.table.password': 'Password',
    'app.ui.ec_site_detail.table.phone': 'Phone',
    'app.ui.ec_site_detail.table.freeze_seconds': 'Cooling Time (Seconds)',
    'app.ui.ec_site_detail.table.headers': 'Header',
    'app.ui.ec_site_detail.table.cookies': 'Cookies',
    'app.ui.ec_site_detail.table.remarks': 'Remarks',

    'app.ui.ec_site_detail.modal.url_add': 'Add Start URL',

    'app.ui.ec_site_detail.error.account_empty': 'Please input the account.',
    'app.ui.ec_site_detail.error.password_empty': 'Please input the password.',
    'app.ui.ec_site_detail.error.tel_empty': 'Please input the phone number.',
    'app.ui.ec_site_detail.error.free_time_empty': 'Cooling Time (Seconds)',
    'app.ui.ec_site_detail.error.headers_empty': 'Please input the header.',
    'app.ui.ec_site_detail.error.cookies_empty': 'Please input the cookies.',
    'app.ui.ec_site_detail.error.remarks_empty': 'Please input the remarks.',
    'app.ui.ec_site_detail.error.remarks_tip': 'Please input the amount.',
    'app.ui.ec_site_detail.info.update_account_success': 'Completed',
    'app.ui.ec_site_detail.error.update_account_failed': 'Failed',

    // news
    'app.ui.news.title': 'News',
    'app.ui.news.error.fetch_failed': 'Failed to obtain news',

    // manage/client(Brand Reg.)
    'app.ui.brand_reg.title': 'Brand Listing',

    'app.ui.brand_reg.client_id': 'ID',
    'app.ui.brand_reg.client_name': 'Company',
    'app.ui.brand_reg.client_tel': 'Contact',
    'app.ui.brand_reg.client_address': 'Address',
    'app.ui.brand_reg.client_analyst': 'Analyst',
    'app.ui.brand_reg.client_remarks': 'Remarks',
    'app.ui.brand_reg.ec_startDate': 'EC Service Start Date',
    'app.ui.brand_reg.ec_endDate': 'EC Service End Date',
    'app.ui.brand_reg.ec_fee': 'EC Service Cost',
    'app.ui.brand_reg.sns_startDate': 'SNS Service Start Date',
    'app.ui.brand_reg.sns_endDate': 'SNS Service End Date',
    'app.ui.brand_reg.sns_fee': 'SNS Service Cost',
    'app.ui.brand_reg.ws_startDate': 'WS Service Start Date',
    'app.ui.brand_reg.ws_endDate': 'WS Service End Date',
    'app.ui.brand_reg.ws_fee': 'WS Service Cost',
    'app.ui.brand_reg.other_startDate': 'Other Service Start Date',
    'app.ui.brand_reg.other_endDate': 'Other Service End Date',
    'app.ui.brand_reg.other_fee': 'Other Service Cost',
    'app.ui.brand_reg.company_name': 'Company',
    'app.ui.brand_reg.address': 'Address',
    'app.ui.brand_reg.remarks': 'Remarks',

    'app.ui.brand_reg.message.info.fetch_success': 'The brand information has been got.',
    'app.ui.brand_reg.message.error.fetch_failed': 'Failed to get the brand information.',
    'app.ui.brand_reg.message.error.delete_failed': 'Failed to delete',

    // manage/client/new (Brand Reg.)
    'app.ui.brand_reg_new.title': 'New Analyst',
    'app.ui.brand_reg_new.basicinfo.title': 'Basic Information',
    'app.ui.brand_reg_new.contract.title': 'Appoint Information',
    'app.ui.brand_reg_new.name': 'Company(required)',
    'app.ui.brand_reg_new.tel': 'Contact',
    'app.ui.brand_reg_new.address': 'Address',
    'app.ui.brand_reg_new.managers': 'Online Protection Analyst',
    'app.ui.brand_reg_new.remarks': 'Remarks',

    'app.ui.brand_reg_new.ec_protection': 'EC Protection',
    'app.ui.brand_reg_new.sns_protection': 'SNS Protection',
    'app.ui.brand_reg_new.ws_protection': 'WS Protection',
    'app.ui.brand_reg_new.other_protection': 'Other Protection',
    'app.ui.brand_reg_new.contract_time': 'Service Date Range',
    'app.ui.brand_reg_new.fee': 'Cost',
    'app.ui.brand_reg_new.mail': 'Remind Mail',
    'app.ui.brand_reg_new.tips': 'Notify 30, 60, 90 days before the expiration date.',

    'app.ui.brand_reg_new.message.error.fetch_user_fail': 'Failed to get data.',
    'app.ui.brand_reg_new.message.error.create_fail': 'Failed',
    'app.ui.brand_reg_new.message.error.tel_error': 'Please input the correct phone number',
    'app.ui.brand_reg_new.message.error.name_error': 'Please enter company name within 30 characters.',
    'app.ui.brand_reg_new.message.error.address_error': 'Please enter address within 100 characters.',
    'app.ui.brand_reg_new.message.error.remarks_error': 'Please enter remarks within 500 characters.',

    // manage/client/xxx (Brand Reg.)
    'app.ui.brand_reg_detail.title': 'Client Information',
    'app.ui.brand_reg_detail.basicinfo.title': 'Basic Information',
    'app.ui.brand_reg_detail.contract.title': 'Appoint Information',
    'app.ui.brand_reg_detail.setting.title': 'Data settings',
    'app.ui.brand_reg_detail.setting.ec_report': 'EC Report',
    'app.ui.brand_reg_detail.setting.ec_master_information': 'Protection Settings',
    'app.ui.brand_reg_detail.setting.ws_report': 'WS Report',
    'app.ui.brand_reg_detail.setting.proxy': 'Attorney',
    'app.ui.brand_reg_detail.setting.trademark_registration': 'Trademark Region',
    'app.ui.brand_reg_detail.setting.other': 'Other Setting',

    'app.ui.brand_reg_detail.message.error.delete_failed': 'Failed to delete it.',
    'app.ui.brand_reg_detail.message.error.client_fetch_failed': 'Failed to get client infomation.',
    'app.ui.brand_reg_detail.message.error.user_fetch_failed': 'Failed to get analyst information.',
    'app.ui.brand_reg_detail.message.error.tel_error': 'Please input the correct phone number.',
    'app.ui.brand_reg_detail.message.error.name_error': 'Please enter company name within 30 characters.',
    'app.ui.brand_reg_detail.message.error.address_error': 'Please enter address within 100 characters.',
    'app.ui.brand_reg_detail.message.error.remarks_error': 'Please enter remarks within 500 characters.',
    'app.ui.brand_reg_detail.message.warning.delete_warning':'Would you like to delete the client?',

    // ec-master-report(Brand Reg detail)
    'app.ui.brand_reg_detail.report.list.report_id': 'ID',
    'app.ui.brand_reg_detail.report.list.report_name': 'Report',
    'app.ui.brand_reg_detail.report.list.report_upload_date': 'Upload Date',
    'app.ui.brand_reg_detail.report.list.report_count': 'Total',
    'app.ui.brand_reg_detail.report.list.report_scrape_count': 'Scrapied Count',
    'app.ui.brand_reg_detail.report.list.report_repeat_count': 'Duplicate Count',
    'app.ui.brand_reg_detail.report.list.report_send_date': 'Release Date',
    'app.ui.brand_reg_detail.report.list.report_uncategrize_count': 'Categorized Count',
    'app.ui.brand_reg_detail.report.list.report_uncategrize_count1': 'Uncategorized Count',
    'app.ui.brand_reg_detail.report.list.report_response_date': 'Response Date',
    'app.ui.brand_reg_detail.report.list.report_response_count': 'Response Count',

    'app.ui.brand_reg_detail.warn.delete_warn':'Do you want to delete it?',
    'app.ui.brand_reg_detail.info.confirm':'Yes',
    'app.ui.brand_reg_detail.info.cancel':'No',
    'app.ui.brand_reg_detail.warn.inherit_warn':'Do you want to inherit it',
    'app.ui.brand_reg_detail.warn.delete_repeat_record_warn':'Do you want to remove the repeat records.',
    'app.ui.brand_reg_detail.warn.show_warn':'Display records without categorization to the client."',
    'app.ui.brand_reg_detail.info.open_confirm':'Realse',
    'app.ui.brand_reg_detail.info.open_cancel':'Cancel',
    'app.ui.brand_reg_detail.messages.info.delete_success':'The report has been deleted.',
    'app.ui.brand_reg_detail.messages.error.delete_fail':'Failed to delete the report',
    'app.ui.brand_reg_detail.messages.info.renewal_of_contract_success':'Cmpleted to',
    'app.ui.brand_reg_detail.messages.error.renewal_of_contract_fail':'Failed to inherit the report.',
    'app.ui.brand_reg_detail.messages.info.remove_duplicate_success':'The repeat records have been deleted.',
    'app.ui.brand_reg_detail.messages.info.open_success':'The report has been released.',
    'app.ui.brand_reg_detail.messages.error.open_fail':'Failed to release the report.',

    // ec-master-product-category(Brand Reg detail)
    'app.ui.brand_reg_detail.product_category.id': 'ID',
    'app.ui.brand_reg_detail.product_category.name': 'Product Type',
    'app.ui.brand_reg_detail.product_category.name_en': 'Product Type(EN)',
    'app.ui.brand_reg_detail.product_category.code': 'Product Type #',
    'app.ui.brand_reg_detail.product_category.modal.title': 'Add Product Type',
    'app.ui.brand_reg_detail.product_category.brand.title': 'Brand',
    'app.ui.brand_reg_detail.product_category.product.title': 'Product Type',
    'app.ui.brand_reg_detail.product_category.additional_item.title': 'Custom Column',
    'app.ui.brand_reg_detail.product_category.list_black.title': 'Blacklist',
    'app.ui.brand_reg_detail.product_category.list_white.title': 'Whitelist',
    'app.ui.brand_reg_detail.product_category.compatible_notation.title': 'Interchangeable Notation',
    'app.ui.brand_reg_detail.product_category.genuine_notation.title': 'Genuine Notation',

    'app.ui.brand_reg_detail.product_category.messages.error.category_empty':'Please input the product type.',
    'app.ui.brand_reg_detail.product_category.messages.error.category_en_empty':'Please input the product type(en).',
    'app.ui.brand_reg_detail.product_category.messages.error.code_empty':'Please input the code of product type.',

    // ec-master-custom-column(Brand Reg detail)
    'app.ui.brand_reg_detail.custom_column.modal.title': 'Add Cunstom Columns',
    'app.ui.brand_reg_detail.custom_column.column_id': 'ID',
    'app.ui.brand_reg_detail.custom_column.column_name': 'Column Name',
    'app.ui.brand_reg_detail.custom_column.column_category': 'Column Type',
    'app.ui.brand_reg_detail.custom_column.column_position': 'Position',
    'app.ui.brand_reg_detail.custom_column.column_order': 'Order',

    'app.ui.brand_reg_detail.custom_column.messages.error.item_empty':'Please input the name of the column.',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_category_empty':'Please input the region of the column.',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_position_empty':'Please input the position of the column.',
    'app.ui.brand_reg_detail.custom_column.messages.error.item_order_empty':'Please input the order of the column.',

    // ec-master-special-url(Brand Reg detail)
    'app.ui.brand_reg_detail.special_url.id': 'ID',
    'app.ui.brand_reg_detail.special_url.url': 'URL',
    'app.ui.brand_reg_detail.special_url.seller': 'Seller',
    'app.ui.brand_reg_detail.special_url.sellerid': 'SellerId',
    'app.ui.brand_reg_detail.special_url.company': 'Company',
    'app.ui.brand_reg_detail.special_url.remarks': 'Remakrs',
    'app.ui.brand_reg_detail.special_url.modal.title_black': 'Blacklist',
    'app.ui.brand_reg_detail.special_url.modal.title_white': 'Whitelist',

    'app.ui.brand_reg_detail.special_url.messages.error.url_empty':'Please input the URL.',
    'app.ui.brand_reg_detail.special_url.messages.error.seller_name_empty':'Please input the seller name.',
    'app.ui.brand_reg_detail.special_url.messages.error.seller_id_empty':'Please input the seller ID.',
    'app.ui.brand_reg_detail.special_url.messages.error.company_empty':'Please input the company name.',
    'app.ui.brand_reg_detail.special_url.messages.error.remarks_empty':'Please input the remarks.',

    // ec-master-special-word(Brand Reg detail)
    'app.ui.brand_reg_detail.special_word.id': 'ID',
    'app.ui.brand_reg_detail.special_word.word': 'Notation',
    'app.ui.brand_reg_detail.special_url.modal.title_1': 'Interchangeable Notation',
    'app.ui.brand_reg_detail.special_url.modal.title_2': 'Genuine Notation',

    'app.ui.brand_reg_detail.special_url.messages.error.word_empty': 'Please input the notation.',

    // master-attorney-letter(Brand Reg detail)
    'app.ui.brand_reg_detail.attorney_letter.id': 'ID',
    'app.ui.brand_reg_detail.attorney_letter.region': 'Region',
    'app.ui.brand_reg_detail.attorney_letter.startDate': 'Start Date',
    'app.ui.brand_reg_detail.attorney_letter.endDate': 'End Date',
    'app.ui.brand_reg_detail.attorney_letter.remindFlag': 'Remind Mail',
    'app.ui.brand_reg_detail_attorney_letter.modal.title': 'Attorney',

    'app.ui.brand_reg_detail.attorney_letter.message.error.region_empty': 'Please input the region.',
    'app.ui.brand_reg_detail.attorney_letter.message.error.start_date_empty': 'Please input the start date.',
    'app.ui.brand_reg_detail.attorney_letter.message.error.end_date_empty': 'Please input the end date.',
    'app.ui.brand_reg_detail.attorney_letter.message.info.remindFlag_success': 'Please input the remind flag.',

    // ws-master-report
    'app.ui.brand_reg_detail.ws_report.info.open_success': 'The report has been released.',
    'app.ui.brand_reg_detail.ws_report.error.open_fail': 'Failed to release the report.',

    // master-trademark-country(Brand Reg detail)
    'app.ui.brand_reg_detail.trademark_country.status': 'Trademark Status',
    'app.ui.brand_reg_detail.trademark_country.modal.title': 'Basic Modal',
    'app.ui.brand_reg_detail.trademark_country.trademark_id': 'ID',
    'app.ui.brand_reg_detail.trademark_country.trademark_client_id': 'Client',
    'app.ui.brand_reg_detail.trademark_country.trademark_no': 'Trademark Number',
    'app.ui.brand_reg_detail.trademark_country.trademark_region': 'Region',
    'app.ui.brand_reg_detail.trademark_country.trademark_type': 'Product Genre',
    'app.ui.brand_reg_detail.trademark_country.trademark_text': 'Logo',
    'app.ui.brand_reg_detail.trademark_country.trademark_register_date': 'Registration Date',
    'app.ui.brand_reg_detail.trademark_country.trademark_invalid_date': 'Expiration Date',
    'app.ui.brand_reg_detail.trademark_country.trademark_belongsto': 'Rights Holder',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_ja': 'Designated Products/Services(Japanese)',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_en': 'Designated Products/Services(English)',
    'app.ui.brand_reg_detail.trademark_country.trademark_description_cn': 'Designated Products/Services(Chinese)',
    'app.ui.brand_reg_detail.trademark_country.trademark_status': 'Status',
    'app.ui.brand_reg_detail.trademark_country.trademark_remarks': 'Remarks',

    'app.ui.brand_reg_detail.trademark_country.message.info.update_success': 'The infomation have been updated.',
    'app.ui.brand_reg_detail.trademark_country.message.error.update_fail': 'Failed to update the information.',

    // ec-master-other(Brand Reg detail)
    'app.ui.brand_reg_detail.other.indication_price': 'Displayed Price',
    'app.ui.brand_reg_detail.other.single_price': 'Flat Price',
    'app.ui.brand_reg_detail.other.inventory': 'Inventory',
    'app.ui.brand_reg_detail.other.sale': 'Sold Count',
    'app.ui.brand_reg_detail.other.coefficient': 'Flat Count',
    'app.ui.brand_reg_detail.other.arithmetic': 'Impact Amount:A*B*C',
    'app.ui.brand_reg_detail.other.unit_price': 'Factor(A):',
    'app.ui.brand_reg_detail.other.unit_coefficient': 'Factor(B):',
    'app.ui.brand_reg_detail.other.others': 'Factor(C):',

    'app.ui.brand_reg_detail.other.message.info.update_success': 'The infomation have been updated.',
    'app.ui.brand_reg_detail.other.message.error.update_fail': 'Failed to update the information.',

    // ec-master-brand(Brand Reg detail)
    'app.ui.brand_reg_detail.brand.keyword': 'Keyword',
    'app.ui.brand_reg_detail.brand.model': 'Model',
    'app.ui.brand_reg_detail.brand.brand_addition': 'Add brand',
    'app.ui.brand_reg_detail.brand.model_addition': 'Add Model',
    'app.ui.brand_reg_detail.brand.keyword_addition': 'Add keyword',

    'app.ui.brand_reg_detail.brand.table.brand_name': 'Brand',
    'app.ui.brand_reg_detail.brand.table.keyword_id': 'ID',
    'app.ui.brand_reg_detail.brand.table.keyword_name': 'Brand Notation',
    'app.ui.brand_reg_detail.brand.table.model_id': 'ID',
    'app.ui.brand_reg_detail.brand.table.model_name': 'Model',
    'app.ui.brand_reg_detail.brand.table.model_price': 'Price(＄)',

    'app.ui.brand_reg_detail.brand.message.error.brand_empty': 'Please input the brand.',
    'app.ui.brand_reg_detail.brand.message.error.keyword_empty': 'Please input the keyword of the brand.',
    'app.ui.brand_reg_detail.brand.message.error.model_empty': 'Please input the name of the model.',
    'app.ui.brand_reg_detail.brand.message.error.price_empty': 'Please input the price.',

    // ec-task
    'app.ui.ec_task.title': 'EC Scrapying Task',
    'app.ui.ec_task.table.status': 'Status',
    'app.ui.ec_task.table.id': 'ID',
    'app.ui.ec_task.table.clientId': 'Client',
    'app.ui.ec_task.table.title': 'Title',
    'app.ui.ec_task.table.type': 'Type',
    'app.ui.ec_task.table.planStartDate': 'Start Date',
    'app.ui.ec_task.table.total': 'Count',
    'app.ui.ec_task.table.remarks': 'Remarks',
    'app.ui.ec_task.table.site': 'Site',
    'app.ui.ec_task.table.keyword': 'Keyword',
    'app.ui.ec_task.table.startUrl': 'Start URL',
    'app.ui.ec_task.table.targetCount': 'Desired quantity',
    'app.ui.ec_task.table.recordCount': 'Got quantity',
    'app.ui.ec_task.table.modal_title': 'Add Start URL',
    'app.ui.ec_task_detail.title': 'EC Scrapying',
    'app.ui.ec_task_new.title': 'EC Scrapying',

    'app.ui.ec_task.table.info.extraction.dashboard': 'Information Extraction (Dashboard Display Type)',
    'app.ui.ec_task.table.info.extraction.price_monitoring_format': 'Information Extraction (Price Monitoring Format)',
    'app.ui.ec_task.table.info.extraction.download': 'Information Extraction (Normal Format)',

    'app.ui.ec_task.message.error.clients_fetch_failed': 'Failed to retrieve client information, please check the network.',
    'app.ui.ec_task.message.error.task_fetch_failed': 'Failed to retrieve task information.',
    // task list message
    'app.ui.ec_task.message.info.scraping_success':'Began to fetch data.',
    'app.ui.ec_task.message.error.scraping_fail':'Failed to start task.',
    'app.ui.ec_task.message.info.delete_success':'The task has been removed.',
    'app.ui.ec_task.message.error.delete_fail':'Failed to remove task.',
    'app.ui.ec_task.message.info.stop_success':'The task has been stopped.',
    'app.ui.ec_task.message.error.stop_fail':'Failed to stop the task.',
    'app.ui.ec_task.message.info.new_success':'The task has been created.',
    'app.ui.ec_task.message.error.new_fail':'Failed to create the task.',
    'app.ui.ec_task.message.info.search_success':'Searching finished.',
    'app.ui.ec_task.message.error.search_fail':'Failed to get tasks.',
    // task detail update create message
    'app.ui.ec_task.message.error.site_empty':'Please select a site name.',
    'app.ui.ec_task.message.error.keyword_empty':'Please input the keyword.',
    'app.ui.ec_task.message.error.start_url_empty':'Please input the start URL.',
    'app.ui.ec_task.message.error.target_count_empty':'Please input the taget amount.',
    // task create message
    'app.ui.ec_task.message.info.create_success':'The task has been created.',
    'app.ui.ec_task.message.error.create_fail':'Failed to create the task.',

    'app.ui.ec_task.detail.title': 'Detail',
    'app.ui.ec_task.add.title': 'New',

    // Marketplace Reason code
    'app.ui.infringement_category.title': 'Complaint Type',
    'app.ui.infringement_category.modal.title': 'Complaint Type',
    'app.ui.infringement_category.content_empty': 'No content, please add',
    'app.ui.infringement_category.subcate.modal.title': 'Complaint Type',

    'app.ui.infringement_category.name': 'Complaint Type(JA)',
    'app.ui.infringement_category.name_en': 'Complaint Type(EN)',
    'app.ui.infringement_category.subname': 'Infringement Category',
    'app.ui.infringement_category.subcate.name': 'Infringement Category(JP)',
    'app.ui.infringement_category.subcate.name_en': 'Infringement Category(EN)',
    'app.ui.infringement_category.subcate.code': 'Input Field',
    'app.ui.infringement_category.subcate.commonCategory': 'Infringement Type',

    'app.ui.main_category.message.info.delete_success':'The complaint type has been deleted.',
    'app.ui.main_category.message.error.delete_fail':'Failed to delete the complaint type.',
    'app.ui.main_category.message.info.update_success':'The complaint type has been updated.',
    'app.ui.main_category.message.error.update_fail':'Failed to update the complaint type.',
    'app.ui.main_category.message.info.create_success':'The new complaint type has been created.',
    'app.ui.main_category.message.error.create_fail':'Failed to craete the new complaint type.',
    'app.ui.main_category.message.error.name_empty':'Please input the complaint type.',
    'app.ui.main_category.message.error.name_en_empty':'Please input the complaint type(EN).',

    'app.ui.sub_category.message.info.update_success': 'The infringement category has been updated.',
    'app.ui.sub_category.message.error.update_fail': 'Failed to update the infringement category.',
    'app.ui.sub_category.message.info.delete_success': 'The infringement category has been deleted.',
    'app.ui.sub_category.message.error.delete_fail': 'Failed to delete the infringement category.',
    'app.ui.sub_category.message.info.create_success': 'The new infringement category has been created.',
    'app.ui.sub_category.message.error.create_fail': 'Failed to create the new infringement category.',
    'app.ui.sub_category.message.error.name_empty': 'Please input the infringement category.',
    'app.ui.sub_category.message.error.name_en_empty': 'Please input the infringement category(EN)',
    'app.ui.sub_category.message.error.code_empty': 'Please input the Input Field.',
    'app.ui.sub_category.message.error.commoncategory_empty': 'Please select a infringement type.',

    // Manager reg
    'app.ui.manager_analyst.title': 'Manager List',
    'app.ui.manager_analyst.table.user_id': 'ID',
    'app.ui.manager_analyst.table.user_name': 'Name',
    'app.ui.manager_analyst.table.user_role': 'Role',
    'app.ui.manager_analyst.table.user_mail': 'Email',
    'app.ui.manager_analyst.table.user_tel': 'Tel',
    'app.ui.manager_analyst.table.user_remarks': 'Remarks',
    'app.ui.manager_analyst.table.user_last_used_date': 'Last Login Date',
    'app.ui.manager_analyst.table.user_create_at': 'Creation Date',

    'app.ui.manager_analyst.message.info.delete_success': 'The record has been deleted.',
    'app.ui.manager_analyst.message.error.delete_fail': 'Failed to delete the record.',

    'app.ui.manager_analyst_new.title': 'Analyst List',
    'app.ui.manager_analyst_new.box.title': 'Basic Information',
    'app.ui.manager_analyst_new.box.name': 'Name(required)',
    'app.ui.manager_analyst_new.box.email': 'Email(required)',
    'app.ui.manager_analyst_new.box.password': 'Password(required)',
    'app.ui.manager_analyst_new.box.password2': 'Password(confirmation)',
    'app.ui.manager_analyst_new.box.role': 'Role(required)',
    'app.ui.manager_analyst_new.box.tel': 'Tel',
    'app.ui.manager_analyst_new.box.remarks': 'Remarks',

    'app.ui.manager_analyst_new.message.error.name_empty': 'Please input the name.',
    'app.ui.manager_analyst_new.message.error.password_fail': 'Please set your password with at least one number, one uppercase letter, one lowercase letter, and one symbol, and ensure it is more than 8 characters in length.',
    'app.ui.manager_analyst_new.message.error.password_unequal': 'The new password and the confirmation do not match.',
    'app.ui.manager_analyst_new.message.error.email_fail': 'Please input a correct mail.',
    'app.ui.manager_analyst_new.message.error.role_empty': 'Please select a role.',
    'app.ui.manager_analyst_new.message.error.phone_fail': 'Please input a correct number.',
    'app.ui.manager_analyst_new.message.error.create_fail': 'Failed to create an account.',

    'app.ui.manager_analyst_detail.title': 'Analyst List',
    'app.ui.manager_analyst_detail.box.basic_title': 'Basic Information',
    'app.ui.manager_analyst_detail.box.login_record_title': 'Login Information',
    'app.ui.manager_analyst_detail.box.basic_id': 'ID',
    'app.ui.manager_analyst_detail.box.basic_name': 'Name(required)',
    'app.ui.manager_analyst_detail.box.basic_mail': 'Email',
    'app.ui.manager_analyst_detail.box.basic_role': 'Role(required)',
    'app.ui.manager_analyst_detail.box.basic_tel': 'Tel',
    'app.ui.manager_analyst_detail.box.basic_remarks': 'Remarks',
    'app.ui.manager_analyst_detail.box.login_record_startDate': 'Last Login Date',
    'app.ui.manager_analyst_detail.box.login_record_endDate': 'Creation Date',

    // login
    'app.ui.login.label.mail': 'Mail',
    'app.ui.login.label.password': 'Password',
    'app.ui.login.label.next': 'Next',
    'app.ui.login.label.password_confirm': 'Comfirm password',
    'app.ui.login.label.name': 'User name',
    'app.ui.login.label.verification_code': 'Verification Code',
    'app.ui.login.button.login': 'Log in',
    'app.ui.login.button.setup': 'Set up',
    'app.ui.login.button.to_setup': 'Set up',
    'app.ui.login.button.forget_password': 'Reset your password',
    'app.ui.login.button.to_login': 'Log in',
    'app.ui.login.button.get_verification': 'Get',

    'app.ui.login.label.new_password': 'New password',
    'app.ui.login.label.new_password_confirm': 'Confirm password',
    'app.ui.login.button.reset': 'Reset',

    'app.ui.login.message.error.no_mail': 'Please input the mail.',
    'app.ui.login.message.error.mail_format': 'Please check the mail.',
    'app.ui.login.message.error.no_password': 'Please input the password.',
    'app.ui.login.message.error.no_verification_code': 'Please input the verifcation code.',
    'app.ui.login.message.error.no_confirm_password': 'Please input the confirm password.',
    'app.ui.message.error.login_failed':'Please check email,password and comfirm code.',

    // test purchase
    "app.ui.test_purchase.title": 'Test Purchase',
    "app.ui.test_purchase.table.id": 'ID',
    "app.ui.test_purchase.table.client": 'Client',
    "app.ui.test_purchase.table.region": 'Region',
    "app.ui.test_purchase.table.site": 'Site',
    "app.ui.test_purchase.table.count": 'Amount',
    'app.ui.test_purchase.modal.site_add': 'Add',

    'app.ui.test_purchase.error.client_empty': 'Please input the client.',
    'app.ui.test_purchase.error.region_empty': 'Please input the region.',
    'app.ui.test_purchase.error.site_empty': 'Please input the site.',
    'app.ui.test_purchase.error.count_empty': 'Please input amount.',
    // todo
    'app.ui.test_purchase.info.create_client_success': 'The account has been created.',
    'app.ui.test_purchase.error.create_client_failed': 'Failed',
    'app.ui.test_purchase.info.update_client_success': 'The account has been updated.',
    'app.ui.test_purchase.error.update_client_failed': 'Failed',

    // T/M trademark
    'app.ui.trademark.register_title': 'T/M Register',
    'app.ui.trademark.container.trademark_info.title': 'T/M Information',
    'app.ui.trademark.label.trademark_word': 'Word mark',
    'app.ui.trademark.label.trademark_word_description': 'If the trademark is text only, please enter it directly here. However, in the case of design, please attach the image instead of entering it here.',
    'app.ui.trademark.label.trademark_image': 'Design mark',
    'app.ui.trademark.label.trademark_image_description': 'If you wish to register with a logo, please attach an image. (Within 10MB)',
    'app.ui.trademark.label.trademark_detail': 'Description',
    'app.ui.trademark.label.trademark_detail_description': 'What kind of goods or services will you use the trademark for? ',
    'app.ui.trademark.label.trademark_owner': 'Applicant name',
    'app.ui.trademark.label.trademark_owner_description': 'If applicant is a corporation, please enter the registered corporation name.',
    'app.ui.trademark.container.contact_info.title': 'Contact information',
    'app.ui.trademark.label.contact_name': 'Person in charge',
    'app.ui.trademark.label.contact_mail': 'Mail',
    'app.ui.trademark.label.contact_tel': 'Tel',
    'app.ui.trademark.label.contact_fax': 'Fax',
    'app.ui.trademark.label.remarks': 'Remarks',
    'app.ui.trademark.label.remarks_description': 'Messages',

    'app.ui.trademark.imageupload.download': 'Download',
    'app.ui.trademark.imageupload.submit': 'Submit',

    'app.ui.trademark.message.info.remove_success': 'Delete successful',
    'app.ui.trademark.message.error.remove_error': 'Delete failed',
    'app.ui.trademark.message.error.no_word_or_image_mark': 'Enter the word mark or image mark.',
    'app.ui.trademark.message.error.tel_format': 'Please enter a valid phone number.',
    'app.ui.trademark.message.success.mail_success': 'Submitted trademark information to the IPF.',
    'app.ui.trademark.message.error.no_trademark_discraption': 'Please enter your trademark discraption.',
    'app.ui.trademark.message.error.no_owner': 'Please enter the applicant name.',
    'app.ui.trademark.message.error.no_contact_person': 'Please enter the person in charge.',
    'app.ui.trademark.message.error.no_contact_mail': 'Please enter the email of the person in charge.',
    'app.ui.trademark.message.error.contact_mail_format': 'Please enter a valid email.',
    'app.ui.trademark.message.error.no_contact_tel': 'Please enter the phone number of the person in charge.',

    'app.ui.trademark.button.send_mail': 'Send',
    'app.ui.trademark.button.to_registration': 'Trademark Registration',
    'app.ui.trademark.button.to_request_trademark': 'Request TM Application',
    'app.ui.trademark.button.to_trademark_list': 'Your Trademark List',

    'app.ui.trademark.list.title': 'Trademark',

    'app.ui.trademark.table.id': 'ID',
    'app.ui.trademark.table.client': 'Client',
    'app.ui.trademark.table.trademark_no': 'Trademark number',
    'app.ui.trademark.table.trademark_region': 'Region',
    'app.ui.trademark.table.trademark_product_genre': 'Product genre',
    'app.ui.trademark.table.trademark_word': 'Text/Logo',
    'app.ui.trademark.table.trademark_registration_date': 'Registration date',
    'app.ui.trademark.table.trademark_expire_date': 'Expire date',
    'app.ui.trademark.table.remind_mail_flg': 'Reminder mail',
    'app.ui.trademark.table.trademark_right_holder': 'Right holder',
    'app.ui.trademark.table.description_ja': 'Designated products and services (Japanese)',
    'app.ui.trademark.table.description_en': 'Designated products and services (English)',
    'app.ui.trademark.table.description_cn': 'Designated products and services (Chinese)',
    'app.ui.trademark.table.trademark_registration_status': 'Status',
    'app.ui.trademark.table.trademark_remarks': 'Remarks',

    'app.trademark_registration_status.register': 'Register',
    'app.trademark_registration_status.applying': 'Applying',
    'app.trademark_registration_status.rejection': 'Rejection',

    'app.ui.trademark.add.title': 'T/M Registration - Add',
    'app.ui.trademark.add.container_search.title': 'Trademark database',
    'app.ui.trademark.add.container_search.button': 'Search',
    'app.ui.trademark.add.container_body.title': 'T/M Information',
    'app.ui.trademark.add.product_genre_prefix': "{number}.",
    'app.ui.trademark.add.remind_message': "Notify when it is 30, 60, or 90 days before the expiration date.",

    'app.ui.trademark.update.title': 'T/M Registration - Update',

    'app.ui.trademark.message.error.no_search_db': "Choose a trademark database.",
    'app.ui.trademark.message.info.image_uploading': "Trademark images are still being uploaded. please wait a little bit.",
    'app.ui.trademark.message.info.trademark_updated': "Trademark information has been updated.",
    'app.ui.trademark.message.error.no_region': "Please select the trademark region.",
    'app.ui.trademark.message.error.no_trademark_no': "Please input the trademark number.",
    'app.ui.trademark.message.error.no_product_genre': "Please select the product genre.",
    'app.ui.trademark.message.error.no_word_mark': "Please input the word mark.",

    'app.ui.trademark.message.info.trademark_added': "Adding trademark is completed.",
    'app.ui.trademark.message.error.no_client': "Please select the client.",

    // reports
    'app.ui.ec_reports.table.id': "ID",
    'app.ui.ec_reports.table.report_name': "Report name",
    'app.ui.ec_reports.table.upload_date': "Upload date",
    'app.ui.ec_reports.table.scrape_total': "Record count",
    'app.ui.ec_reports.table.send_date': "Report date",
    'app.ui.ec_reports.table.categorized_count': "Categorized count",
    'app.ui.ec_reports.table.not_categorize_date': "Not categorized count",
    'app.ui.ec_reports.table.response_date': "Response date",
    'app.ui.ec_reports.table.response_total': "Response count",
    'app.ui.ec_reports.action.response': "Response",

    'app.ui.ec_reports.message.info.had_notify_analyst': "Notified to the analyst.",
    'app.ui.ec_reports.message.error.nofity_failed': "An error has occurred.Please try it again.",
    'app.ui.ec_reports.message.error.unknown': "An unknown error has occurred.",

    // ec detail
    'app.ui.ec_detail.title': "EC Record Detail",
    'app.ui.ec_detail.tab.web': "Web",
    'app.ui.ec_detail.tab.thumbnail': "Thumbnail",
    'app.ui.ec_detail.tab.screenshot': "Screenshot",
    'app.ui.ec_detail.tab.scrape': "Scrape information",
    'app.ui.ec_detail.tab.categorized_info': "Categorized Information",
    'app.ui.ec_detail.tab.client_response': "Client Response",
    'app.ui.ec_detail.tab.ipf_report_info': "IPF report information",
    'app.ui.ec_detail.button.back': "Back to list",
    'app.ui.ec_detail.step.1': "Report(1st)",
    'app.ui.ec_detail.step.2': "Report(2nd)",
    'app.ui.ec_detail.step.3': "Report(3rd)",
    'app.ui.ec_detail.step.4': "Final Result",
    'app.ui.ec_detail.orginal_url': 'ORGINAL URL',
    'app.ui.ec_detail.image_url': 'IMAGE URL',
    'app.ui.ec_detail.message.error.update_failed': "Failed to update.",
    'app.ui.ec_detail.message.error.update_rejected': "No permission.",

    // home
    'app.ui.home.title': 'Home',
    'app.ui.home.online.cases': "Number of cases",
    'app.ui.home.offline.cases': "Number of cases",
    'app.ui.home.registration.cases': "Number of cases",
    'app.ui.home.test_purchase.cases': "Number of cases",

    'app.ui.home.section.tm_register_region': "Trademark Registration",
    'app.ui.home.section.online_bad_seller': "Online Infringing Seller",
    'app.ui.home.section.test_purchase_cases_number': "Test Purchase",
    'app.ui.home.section.offline_protection': "Offline Protection",
    'app.ui.home.section.ipf_news': "IP FORWARD NEWS",

    'app.ui.home.section.unit': "cases",

    // notification center
    'app.ui.notification_center.title': 'Notice',
    'app.ui.notification_center.table.column.id': "ID",
    'app.ui.notification_center.table.column.title': "Title",
    'app.ui.notification_center.table.column.content': "Content",
    'app.ui.notification_center.table.column.type': "Type",
    'app.ui.notification_center.table.column.from': "From",
    'app.ui.notification_center.table.column.send_date': "Send Date",
    'app.ui.notification_center.table.column.viewed': "Viewed",

    // ws
    'app.ui.wslist.table.search_engine': 'Search engine',
    'app.ui.wslist.table.page_domain': 'Domain',
    'app.ui.wslist.table.page_url': 'URL',
    'app.ui.wslist.table.domain_region': 'Country/Area',
    'app.ui.wslist.table.tld': 'TLD',
    'app.ui.wslist.table.domain_contact': 'Contact',
    'app.ui.wslist.table.redirect_url': 'Redirect',
    'app.ui.wslist.table.whois': 'Whois',
    'app.ui.wslist.table.domain_created_at': 'Domain Created',
    'app.ui.wslist.table.domain_expire_at': 'Domain Expire',
    'app.ui.wslist.table.domain_updated_at': 'Domain Updated',
    'app.ui.wslist.search.collapse': "Search conditions",

    "app.ui.ws_detail.title": "WS Record Detail",
    'app.ui.ws_detail.section.scrape_info': "Basic Information"
}

const combine_en_lang = {
    ...common_en_lang,
    ...ec_crawler_task_en_lang
}

export default combine_en_lang
